import { getTheme, PrimaryButton } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const ProductContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: auto;
  height: 100%;
  padding: 20px;
  background: ${theme.palette.neutralLighterAlt};

  * {
    box-sizing: border-box;
    border-radius: 5px;
  }
`;

export const ProductContractQuestion = styled.h1<{ marginTop: string }>`
  text-align: center;
  margin-top: ${props => props.marginTop};
  color: ${theme.palette.themeDarkAlt};
`;

export const SubmitButton = styled(PrimaryButton)`
  align-self: flex-end;
  padding: 25px;
  margin: 20px 0;
`;

export const ProductContractAnswer = styled.span`
  color: ${theme.palette.themeDarker};
  padding: 5px;
`;
