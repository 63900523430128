import {
  DetailsList,
  Dropdown,
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Spinner,
  Text
} from "@fluentui/react";
import { ResetIcon } from "@fluentui/react-icons-mdl2";
import { useCallback } from "react";

import ContractProductsRenewalListOfferOption from "./ContractProductsRenewalListOfferOption";
import { SelectedItemToRenew } from "./ContractProductsRenewalModal";
import {
  ContractProductsRenewalListContainer,
  ContractProductsRenewalListOfferControls,
  ContractProductsRenewalListWrapper,
  detailsListStyles,
  dropdownStyles
} from "./styles/ContractProductsRenewalList.styles";

function formatCurrency(value: string | number) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(+value);
}

interface ContractProductsRenewalListProps {
  serviceCategoryType: "MOBILE_LINE" | "BROADBAND" | "TRACKER" | "TAG";
  items: any[];
  selectedItems: SelectedItemToRenew[];
  carriers: any[];
  isLoading: boolean;
  onOfferChange: (itemId: number, offerPriceId: number) => void;
  onOfferReset: (itemId: number) => void;
}

function ContractProductsRenewalList({
  // serviceCategoryType,
  carriers,
  items,
  selectedItems,
  isLoading,
  onOfferChange,
  onOfferReset
}: ContractProductsRenewalListProps) {
  const offersDropdownOptions = useCallback(
    (carrierId: number) => {
      const carrier = carriers.find(carrier => carrier.id === carrierId);

      if (!carrier) {
        return [];
      }

      const sortedPlans = [...carrier.plans].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      const options = sortedPlans.flatMap(plan =>
        plan.offers.flatMap(offer => ({
          key: offer.offerPrice.id, // Deverá ser enviado o ID do offerPrice.
          text: `${plan.name.trim()} (${formatCurrency(
            offer.offerPrice.price
          )})`,
          data: offer
        }))
      );

      return options;
    },
    [carriers]
  );

  const itemsWithoutCanceledStarTag = items.filter(
    item => !item.inventory[0]?.canceledAtStartag
  );

  // Atualmente, apenas está implementado as colunas para serviceCategoryType
  // === "TAG". No futuro, é possível condicionar as colunas ao
  // serviceCategoryType.

  return (
    <ContractProductsRenewalListContainer>
      <Label required>Benefícios combos</Label>

      <Text as="p" block>
        Selecione os pacotes de benefícios disponíveis para cada item que deseja migrar.
      </Text>

      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          {carriers.length === 0 && (
            <MessageBar messageBarType={MessageBarType.blocked}>
              Não há operadoras disponíveis para migração de benefícios combos.
            </MessageBar>
          )}
          <ContractProductsRenewalListWrapper data-is-scrollable="true">
            <DetailsList
              setKey="id"
              items={itemsWithoutCanceledStarTag}
              selectionMode={SelectionMode.none}
              columns={[
                {
                  key: "serialNumber",
                  name: "Número de série",
                  minWidth: 80,
                  maxWidth: 150,
                  isRowHeader: true,
                  onRender: item =>
                    item.additionalInfo.serialNumber.match(/.{1,4}/g).join(" ")
                },
                {
                  key: "vehicleLicensePlate",
                  name: "Placa",
                  minWidth: 50,
                  maxWidth: 60,
                  onRender: item =>
                    item.additionalInfo.vehicleLicensePlate.replace(
                      /(.{3})(.)/,
                      "$1-$2"
                    )
                },
                {
                  key: "offer",
                  name: "Benefício combo",
                  minWidth: 50,
                  onRender: item => {
                    const carrierId = item.offerPrice.offer.plan.carrier.id;
                    const options = offersDropdownOptions(carrierId);
                    const selectedItem = selectedItems.find(
                      selectedItem => selectedItem.itemId === item.id
                    );

                    return (
                      <ContractProductsRenewalListOfferControls>
                        <Dropdown
                          placeholder={
                            options.length === 0
                              ? "Nenhum pacote benefício disponível"
                              : "Selecione um benefício"
                          }
                          selectedKey={selectedItem?.offerPriceId ?? null}
                          onChange={(_event, option) =>
                            onOfferChange(item.id, option.key as number)
                          }
                          styles={dropdownStyles}
                          disabled={options.length === 0}
                          options={options}
                          onRenderOption={option => {
                            const discount = option.data.discount
                              ? option.data.discount
                              : "";
                            return (
                              <ContractProductsRenewalListOfferOption
                                title={option.text}
                                discount={discount}
                              />
                            );
                          }}
                        />
                        <IconButton
                          disabled={options.length === 0 || !selectedItem}
                          onClick={() => onOfferReset(item.id)}
                        >
                          <ResetIcon />
                        </IconButton>
                      </ContractProductsRenewalListOfferControls>
                    );
                  }
                }
              ]}
              styles={detailsListStyles}
            />
          </ContractProductsRenewalListWrapper>
        </>
      )}
    </ContractProductsRenewalListContainer>
  );
}

export default ContractProductsRenewalList;
