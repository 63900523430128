import styled from "styled-components";

export const MassActionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
  border: 3px solid rgba(122, 148, 200, 0.8);
  & > h2 {
    font-size: 18px;
    font-weight: bold;
    color: #3c4c6e;
    margin: 10px;
  }
`;
export const MassActionContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > div {
    width: 100%;
  }
`;
export const MassActionPassContent = styled.div`
  background-color: rgb(122, 148, 200);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 20px;
    height: 20px;
    border-right: 5px solid #fff;
    border-bottom: 5px solid #fff;
    transform: rotate(45deg);
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const MassActionFieldsContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > h3 {
    font-size: 18px;
    font-weight: bold;
    color: #3c4c6e;
    margin: 10px;
  }
  & > div {
    width: 100%;

    & > div:first-child {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 21px;
      & > * {
        margin-left: 4px;
      }
    }
    & > div:last-child {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      & > * {
        margin-right: 6px;
      }
    }
  }
`;

export const UpdateMassFilterOptionsContent = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
`;
