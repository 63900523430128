import queryString from "query-string";
import { Component } from "react";
import { Helmet } from "react-helmet";

import Step2 from "@/components/Auth/SetPassword/Step2";
import Step3 from "@/components/Auth/SetPassword/Step3";
import { AuthService } from "@/core/libs/api";
import { IMessageBar, messageBarTypes } from "@/core/libs/message-bar";

interface RecoverProps {
  location: any;
}

interface RecoverState {
  currentStep: number;
  email: string;
  queryParams?: string;
  message?: IMessageBar;
}

class SetPassword extends Component<RecoverProps, RecoverState> {
  queryParams: queryString.ParsedQuery<string>;

  constructor(props) {
    super(props);
    this.queryParams = queryString.parse(props.location.search);
    this.state = {
      currentStep: 1,
      email: "",
      message: undefined
    };

    this._changePassword = this._changePassword.bind(this);
    this._dissmissMessage = this._dissmissMessage.bind(this);
  }

  render(): JSX.Element {
    const { currentStep, message } = this.state;

    return (
      <>
        <Helmet>
          <title>Recuperar senha - Libertas</title>
        </Helmet>
        {currentStep === 1 && (
          <Step2
            changePassword={this._changePassword}
            code={this.queryParams?.code}
            message={message}
            dismissMessage={this._dissmissMessage}
          />
        )}
        {currentStep === 2 && <Step3 message={message} />}
      </>
    );
  }

  private _changePassword(values, { setSubmitting }) {
    this._dissmissMessage();

    const abortController = new AbortController();
    const { signal } = abortController;

    const service = AuthService();
    service
      .changePassword({
        params: {
          query: { code: values.code },
          body: { password: values.password }
        },
        signal
      })
      .then(response => {
        if (response.error) {
          setSubmitting(false);

          this.setState({
            message: {
              code: response.error.status,
              message:
                "Não foi possível alterar a senha com os dados informados",
              type: messageBarTypes.ERROR
            }
          });
        } else {
          setSubmitting(false);

          this.setState({
            currentStep: 2,
            message: {
              code: 200,
              message: "Sua senha foi alterada com sucesso",
              type: messageBarTypes.SUCCESS
            }
          });
        }
      });
  }

  private _dissmissMessage() {
    this.setState({ message: undefined });
  }
}

export default SetPassword;
