import {
  ListOfProductsOffers,
  ProductsBrands,
  ProductsCarriers,
  ProductsCategory
} from "./ProductsOffers.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum ProductsOffersActionsTypes {
  DISMISS_MESSAGE = "products-offers/DISMISS_MESSAGE",
  LIST_REQUEST = "products-offers/LIST_REQUEST",
  LIST_FAIL = "products-offers/LIST_FAIL",
  LIST_SUCCESS = "products-offers/LIST_SUCCESS"
}

export interface ProductsOffersState {
  isLoadingList: boolean;
  list?: ListOfProductsOffers;
  productsBrands?: ProductsBrands[];
  productsCategories?: ProductsCategory[];
  productsCarriers?: ProductsCarriers[];
  message?: IMessageBar;
}

export interface DismissMessage {
  type: ProductsOffersActionsTypes.DISMISS_MESSAGE;
}

export interface ListRequest {
  type: ProductsOffersActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: ProductsOffersActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: ProductsOffersActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfProductsOffers;
  };
}

export type ProductsOffersReducerActions =
  | DismissMessage
  | ListRequest
  | ListError
  | ListSuccess;
