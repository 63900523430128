import { getTheme, Stack } from "@fluentui/react";

import { SlideShow } from "@/components/Shared/SlideShow";
import { Banner } from "@/modules/Offers/types/BannersOffers.types";
import {
  BannerLinkOptions,
  BannerTypes
} from "@/modules/Settings/types/Banners.types";

type BannersOffersProps = {
  banners: Banner[];
};

const orderImages = (a, b) => {
  return a?.order - b?.order;
};

const windowOpenNewTab = link => {
  window.open(link, "_blank", "noopener");
};

const windowOpenOnTheSameTab = link => {
  window.open(link, "_self", "noopener");
};

const handleBannerImages = banners => {
  const orderedBanners = banners?.sort(orderImages);

  const formattedBanners = orderedBanners.map(banner => {
    banner.url = banner.image;
    banner.fileName = banner.displayText;

    if (banner.bannerType === BannerTypes.LINK) {
      banner.onClick = () =>
        banner.linkBehavior === BannerLinkOptions.BLANK
          ? windowOpenNewTab(banner.link)
          : windowOpenOnTheSameTab(banner.link);
    }

    return banner;
  });

  return formattedBanners;
};

export function BannersOffers({ banners }: BannersOffersProps): JSX.Element {
  const theme = getTheme();

  return (
    <Stack styles={{ root: { height: 200 } }} horizontalAlign="center">
      <SlideShow
        images={handleBannerImages(banners)}
        initialSlideIndex={0}
        height="auto"
        maxWidth="1920px"
        maxImageHeight="200px"
        maxImageWidth="100%"
        dotNavigationColor={theme.palette.white}
        showDotNavigator
        isDotNavigationInside
        isAutomatic
        disableMargin
      />
    </Stack>
  );
}
