export function transformToCurrencyString(value: string | number) {
  if (!value) return "R$ 0,00";

  value = value.toString();

  if (value.includes(",")) value = value.replace(",", ".");

  return parseFloat(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}
