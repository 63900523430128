import { ColumnActionsMode, IColumn, Icon } from "@fluentui/react";

import { translateMembershipStatus } from "../utils/translateMembershipStatus";

import Badge from "@/components/Badge";
import { ColoredLabel } from "@/components/ColoredLabel/ColoredLabel";
import { DrawerActions } from "@/components/Shared/CustomDrawer";
import { AccountTypeTranslator } from "@/modules/Accounts/types";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";

export function buildMembersColumns({
  hasActions
}: {
  hasActions: boolean;
  openDrawer?: (action: DrawerActions) => void;
  setSelectedMember: (member: string) => void;
}) {
  const columns: IColumn[] = [
    {
      key: "fullName",
      name: "Cooperado",
      fieldName: "fullName",
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 400,
      isPadded: true,
      onRenderHeader: () => {
        return (
          <div>
            <Icon iconName="Contact" style={{ paddingRight: "5px" }} />
            Cooperado
          </div>
        );
      }
    },
    {
      key: "accountType",
      name: "Tipo",
      fieldName: "accountType",
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 250,
      isPadded: true,
      onRender({ accountType }) {
        if (accountType === "PHYSICAL") {
          return (
            <Badge size="smallXs" type="info" isOutlined>
              <Icon iconName="contact" />
              {AccountTypeTranslator[accountType]}
            </Badge>
          );
        } else {
          return (
            <Badge size="smallXs" type="infoNeutral" isOutlined>
              <Icon iconName="AccountManagement" />
              {AccountTypeTranslator[accountType]}
            </Badge>
          );
        }
      },
      onRenderHeader: () => {
        return (
          <div>
            <Icon
              iconName="ThumbnailViewMirrored"
              style={{ paddingRight: "5px" }}
            />
            Tipo
          </div>
        );
      }
    },
    {
      key: "document",
      name: "CPF/CNPJ",
      fieldName: "document",
      isRowHeader: true,
      minWidth: 50,
      maxWidth: 250,
      onRender({ document }) {
        return cpfCnpjFormatter(document?.documentNumber);
      },
      isPadded: true,
      onRenderHeader: () => {
        return (
          <div>
            <Icon iconName="ContactCard" style={{ paddingRight: "5px" }} />
            CPF/CNPJ
          </div>
        );
      }
    },
    {
      key: "businessUnitId",
      name: "Unidade de Negocio",
      fieldName: "businessUnit",
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 350,
      isPadded: true,
      onRender({ businessUnit }) {
        return businessUnit?.name;
      },
      onRenderHeader: () => {
        return (
          <div>
            <Icon iconName="EMI" style={{ paddingRight: "5px" }} />
            Unidade de Negócio
          </div>
        );
      }
    },
    {
      key: "membershipStatus",
      name: "membershipStatus",
      fieldName: "membershipStatus",
      isRowHeader: true,
      minWidth: 300,

      isPadded: true,
      onRender: ({ membershipStatus }) => (
        <ColoredLabel {...translateMembershipStatus(membershipStatus)} />
      ),
      onRenderHeader: () => {
        return (
          <div>
            <Icon iconName="ContactInfo" style={{ paddingRight: "5px" }} />
            Status
          </div>
        );
      }
    }
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "actions",
      fieldName: "actions",
      minWidth: 40,
      maxWidth: 40,
      columnActionsMode: ColumnActionsMode.disabled,
      isIconOnly: true
    });
  }

  return columns;
}
