import { makeRequest } from "../utils/fetchData";
import { MakeRequestOptions } from "../utils/types";

import Api, { IApi } from "./api";

class Accounts extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string) {
    super(`accounts${subPaths}`);
  }

  findAccount({ params, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/search`,
      queryParams: params.query
    };

    return makeRequest(options);
  }
}

export default Accounts;
