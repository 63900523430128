import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

import { OrganizationModal } from "@/components/OrganizationModal";
import { Organization } from "@/modules/Settings/types/BusinessUnits.types";

type OrganizationProviderProps = {
  children: ReactNode;
};

type IOrganizationContextData = {
  openOrganizationSelection: (organizationsData?: Array<Organization>) => void;
  organizations: Array<Organization>;
  clearOrganization: () => void;
  organizationSelected: Organization;
  setOrganizations: (organizations: Array<Organization>) => void;
};

const OrganizationContext = createContext<IOrganizationContextData>(
  {} as IOrganizationContextData
);

export const OrganizationProvider = ({
  children
}: OrganizationProviderProps) => {
  const [organizations, setOrganizations] =
    useState<Array<Organization>>(undefined);
  const [organizationSelected, setOrganizationSelected] =
    useState<Organization>(undefined);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const openOrganizationSelection = useCallback(
    (organizationsData?: Array<Organization>) => {
      if (organizationsData?.length === 1) {
        setOrganizations(organizationsData);
        setOrganizationSelected(organizationsData[0]);
        localStorage.setItem(
          "organization",
          JSON.stringify(organizationsData[0])
        );
      }
      if (organizationsData?.length > 1 || organizations?.length > 1) {
        if (!organizationSelected) {
          setOrganizations(organizationsData ?? organizations);
          setModalIsOpen(true);
        } else {
          setOrganizations(organizationsData ?? organizations);
          const localOrganization = localStorage.getItem("organization");
          !localOrganization && setModalIsOpen(true);
        }
      }
      if (!organizationSelected && organizations?.length === 1) {
        setOrganizationSelected(organizations[0]);
        localStorage.setItem("organization", JSON.stringify(organizations[0]));
      }
      if (organizationSelected && !organizationsData) {
        if (organizations?.length === 1) {
          return;
        }
        setModalIsOpen(true);
      }
    },
    [organizationSelected, organizations]
  );

  const clearOrganization = () => {
    setOrganizationSelected(undefined);
    setOrganizations(undefined);
    localStorage.removeItem("organization");
  };

  useEffect(() => {
    const localOrganization = localStorage.getItem("organization");

    if (localOrganization) {
      const organization = JSON.parse(localOrganization);

      setOrganizationSelected(organization);
    }
  }, []);

  const saveOrganizationSelected = (organizationSelected: number) => {
    setOrganizationSelected(
      organizations?.find(org => org.id === organizationSelected)
    );

    localStorage.setItem(
      "organization",
      JSON.stringify(organizations.find(org => org.id === organizationSelected))
    );

    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/recover" &&
      window.location.pathname !== "/set-password"
    ) {
      window.location.reload();
    }
  };

  return (
    <OrganizationContext.Provider
      value={{
        openOrganizationSelection: openOrganizationSelection,
        organizations: organizations,
        clearOrganization: clearOrganization,
        organizationSelected: organizationSelected,
        setOrganizations: setOrganizations
      }}
    >
      {modalIsOpen && (
        <OrganizationModal
          organizationValue={organizationSelected}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          organizations={organizations}
          sendDataFunction={saveOrganizationSelected}
        />
      )}
      {children}
    </OrganizationContext.Provider>
  );
};
export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  return context;
};
