import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 20px;

  * {
    box-sizing: border-box;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FiltersOptions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 60px;
  margin-top: 20px;
`;

export const SignatureStandardText = styled.span`
  margin-top: 12px;
  font-weight: 600;
  color: #ee2323;
`;

export const FilterButton = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: ${theme.palette.white};
  border: ${props =>
    props.isActive ? `2px solid ${theme.palette.neutralPrimaryAlt}` : "none"};
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin-top: 15px;

  & + & {
    margin-left: 15px;
  }

  &:hover {
    background-color: ${theme.palette.neutralLight};
  }
`;

export const CashValue = styled.span`
  font-style: italic;
`;

export const CarrierLogo = styled.img`
  width: 100px;
`;
