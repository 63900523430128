import { IMessageBar } from "@/core/libs/message-bar";

export enum ElectronicSignaturesActionsTypes {
  CREATE_REQUEST = "electronicSignatures/CREATE_REQUEST",
  CREATE_FAIL = "electronicSignatures/CREATE_FAIL",
  CREATE_SUCCESS = "electronicSignatures/CREATE_SUCCESS",
  DISMISS_MESSAGE = "electronicSignatures/DISMISS_MESSAGE",
  EDIT_REQUEST = "electronicSignatures/EDIT_REQUEST",
  EDIT_FAIL = "electronicSignatures/EDIT_FAIL",
  EDIT_SUCCESS = "electronicSignatures/EDIT_SUCCESS",
  LIST_REQUEST = "electronicSignatures/LIST_REQUEST",
  LIST_FAIL = "electronicSignatures/LIST_FAIL",
  LIST_SUCCESS = "electronicSignatures/LIST_SUCCESS"
}

export interface ElectronicSignaturesState {
  isLoadingList: boolean;
  list: [];
  message?: IMessageBar;
}

export interface CreateRequest {
  type: ElectronicSignaturesActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: ElectronicSignaturesActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: ElectronicSignaturesActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: ElectronicSignaturesActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: ElectronicSignaturesActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: ElectronicSignaturesActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: ElectronicSignaturesActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: ElectronicSignaturesActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: ElectronicSignaturesActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: ElectronicSignaturesActionsTypes.LIST_SUCCESS;
  payload: {
    list: [];
  };
}

export type ElectronicSignaturesReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
