import "react-dropdown-tree-select/dist/styles.css";

import { DefaultButton } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { TreeData, TreeNode } from "react-dropdown-tree-select";

import { Dropdown, FilterContainer, Wrapper } from "./styles";

type TreeSelectProps = {
  placeholder?: string;
  options: TreeDataValue[];
  label?: string;
  onChangeSelectedItems: (selectedNodes: Array<TreeDataValue>) => void;
};

export type TreeDataValue = TreeData & {
  label: string;
  checked: boolean;
  value?: number;
  children?: TreeDataValue[];
};

const iconColor = "#395CA3";

const updateOption = (arr: TreeDataValue[], checkStatus: boolean) => {
  if (!arr) return arr;
  return arr.map(option => {
    let children;
    if (option.children) {
      children = updateOption(option.children, checkStatus);
    }
    return { ...option, checked: checkStatus, children };
  });
};

const getAllSelectedNodes = (
  data: TreeDataValue[],
  selectedValues: string[]
): TreeDataValue[] => {
  const foundObjects = [];

  function searchRecursive(data, selectedValues) {
    for (const obj of data) {
      if (selectedValues.includes(obj.value)) {
        foundObjects.push({ ...obj, checked: true });
      } else if (obj.children && obj.children.length > 0) {
        searchRecursive(obj.children, selectedValues);
      }
    }
  }

  searchRecursive(data, selectedValues);
  return foundObjects;
};

export const TreeSelectDropdownComponent = ({
  placeholder,
  options: initialOptions,
  label = "Unidades de negócio",
  onChangeSelectedItems
}: TreeSelectProps) => {
  const [options, setOptions] = useState<TreeDataValue[]>(initialOptions);

  const handleCheckboxSelect = useCallback(
    (_: TreeNode, selectedNodes: TreeNode[]) => {
      const selectedValues = selectedNodes.map(node => node.value);
      const allSelectedNodes = getAllSelectedNodes(
        initialOptions,
        selectedValues
      );
      onChangeSelectedItems(allSelectedNodes);
    },
    [initialOptions, onChangeSelectedItems]
  );

  const uncheckAll = useCallback(() => {
    setOptions(prevState => {
      const newState = updateOption(prevState, false);
      onChangeSelectedItems([]);
      return newState;
    });
  }, [onChangeSelectedItems]);

  const checkAll = useCallback(() => {
    setOptions(prevState => {
      const newState = updateOption(prevState, true);
      onChangeSelectedItems(newState);
      return newState;
    });
  }, [onChangeSelectedItems]);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  return (
    <Wrapper>
      <FilterContainer>
        <span>{label}</span>
        <div>
          <DefaultButton
            onClick={checkAll}
            iconProps={{ iconName: "FilterSolid" }}
            styles={{
              icon: { color: iconColor }
            }}
          >
            todos
          </DefaultButton>
          <DefaultButton
            onClick={uncheckAll}
            iconProps={{ iconName: "ClearFilter" }}
            styles={{
              icon: { color: iconColor }
            }}
          >
            nenhum
          </DefaultButton>
        </div>
      </FilterContainer>
      <Dropdown
        data={options}
        clearSearchOnChange={false}
        keepTreeOnSearch={true}
        keepChildrenOnSearch={true}
        keepOpenOnSelect
        onChange={handleCheckboxSelect}
        mode="multiSelect"
        texts={{
          placeholder: placeholder || "Digite ou selecione..."
        }}
      />
    </Wrapper>
  );
};
