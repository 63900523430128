import { useState, useEffect, useCallback } from "react";

import {
  FallbackApiService,
  PrimaryApiService
} from "../../../utils/locationsApi";

function useGetLocations() {
  const [states, setStates] = useState<{ key: number; text: string }[]>([]);
  const [cities, setCities] = useState<{ key: string; text: string }[]>([]);

  const primaryApiService = new PrimaryApiService();
  const fallbackApiService = new FallbackApiService();

  const transformText = (text: string) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
  };

  const fetchStates = async () => {
    try {
      const statesData = await primaryApiService.fetchStates();
      const statesMapped = statesData.map(state => ({
        key: state.key,
        text: transformText(state.text)
      }));
      setStates(statesMapped);
      return statesMapped;
    } catch (error) {
      console.error("Primary API failed for states, using fallback:", error);
      const statesData = await fallbackApiService.fetchStates();
      const statesMapped = statesData.map(state => ({
        key: state.key,
        text: transformText(state.text)
      }));
      setStates(statesMapped);
      return statesMapped;
    }
  };

  const fetchCities = useCallback(async (stateId: number) => {
    if (stateId !== null) {
      try {
        const citiesData = await primaryApiService.fetchCities(stateId);
        const citiesMapped = citiesData.map(city => ({
          key: transformText(city.text),
          text: transformText(city.text)
        }));
        setCities(citiesMapped);
      } catch (error) {
        console.error("Primary API failed for cities, using fallback:", error);
        const citiesData = await fallbackApiService.fetchCities(stateId);
        const citiesMapped = citiesData.map(city => ({
          key: transformText(city.text),
          text: transformText(city.text)
        }));
        setCities(citiesMapped);
      }
    }
  }, []);

  const onStateChange = (data: { key: number; value: string }) => {
    fetchCities(data.key);
  };

  useEffect(() => {
    fetchStates();
  }, []);

  return { states, cities, fetchStates, onStateChange };
}

export default useGetLocations;
