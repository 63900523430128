import produce from "immer";

import {
  UsersActionsTypes,
  UsersReducersActions,
  UsersState
} from "../types/Users.reducer.types";

const INITIAL_STATE: UsersState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: UsersReducersActions
): UsersState {
  return produce(state, draft => {
    switch (action.type) {
      case UsersActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case UsersActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case UsersActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case UsersActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case UsersActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case UsersActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case UsersActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case UsersActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case UsersActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case UsersActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
