export enum messageBarTypes {
  BLOCKED = "blocked",
  DEFAULT = "default",
  ERROR = "error",
  SEVERE = "severeWarning",
  SUCCESS = "success",
  WARNING = "warning"
}

export interface IMessageBar {
  code?: number;
  message?: string;
  type?: messageBarTypes;
}
