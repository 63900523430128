import { StyledDiv } from "./styles";

import { Card } from "@/components/Shared/Card";
import { MessageBar } from "@/core/libs/message-bar";

interface ShowMessageProps {
  message: any;
  cleanMessage: any;
}

export function ShowMessageCard(props: ShowMessageProps): JSX.Element {
  const { message, cleanMessage } = props;

  return (
    <>
      {message && (
        <Card>
          <StyledDiv>
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <MessageBar message={message} dismissMessage={cleanMessage} />
                </div>
              </div>
            </div>
          </StyledDiv>
        </Card>
      )}
    </>
  );
}

export function ShowMessageDiv(props: ShowMessageProps): JSX.Element {
  const { message, cleanMessage } = props;

  return (
    <>
      {message && (
        <div className="ms-Grid-row" style={{ marginBottom: "15px" }}>
          <div className="ms-Grid-col ms-sm12">
            <MessageBar message={message} dismissMessage={cleanMessage} />
          </div>
        </div>
      )}
    </>
  );
}
