import { makeRequest } from "../utils/fetchData";
import { MakeRequestOptions } from "../utils/types";

import Api, { IApi } from "./api";

class Operations extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/operations${subPaths}` : `operations${subPaths}`);
  }
  listOperations({ params, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "GET",
      baseUrl: this.baseUrl
    };

    if (params?.body) options.bodyObj = params.body;
    if (params?.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  createOperation({ params, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}`
    };

    if (params.body) {
      options.bodyObj = params.body;
    }
    return makeRequest(options);
  }

  editOperation({ id, params, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "PATCH",
      baseUrl: `${this.baseUrl}/${id}`
    };

    if (params.body) {
      options.bodyObj = params.body;
    }
    return makeRequest(options);
  }
}

export default Operations;
