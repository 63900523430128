import { OrganizationsActionsTypes } from "../types/Organizations.reducer.types";

export function createOrganizationsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: OrganizationsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createOrganizationsSuccess(message) {
  return {
    type: OrganizationsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createOrganizationsFailure(message) {
  return {
    type: OrganizationsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editOrganizationsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: OrganizationsActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editOrganizationsSuccess(message) {
  return {
    type: OrganizationsActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editOrganizationsFailure(message) {
  return {
    type: OrganizationsActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listOrganizationsRequest(filter, { isAdmin = false }) {
  return {
    type: OrganizationsActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listOrganizationsSuccess({ list }) {
  return {
    type: OrganizationsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listOrganizationsFailure() {
  return {
    type: OrganizationsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: OrganizationsActionsTypes.DISMISS_MESSAGE
  };
}
