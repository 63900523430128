import { MessageBarType } from "@fluentui/react";

export enum AlertTypes {
  API = "API",
  NOTIFICATION = "NOTIFICATION",
  DEFAULT = "DEFAULT"
}

export interface IAlertContextData {
  createAlert: (alert: AlertProps) => void;
}

export interface AlertProps {
  message: string;
  timeout?: number;
  level: MessageBarType;
  type: AlertTypes;
  code?: number;
  method?: string;
  path?: string;
}
