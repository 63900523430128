import produce from "immer";

import {
  PlansOffersActionsTypes,
  PlansOffersReducerActions,
  PlansOffersState
} from "../types/PlansOffers.reducer.types";

const INITIAL_STATE: PlansOffersState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: PlansOffersReducerActions
): PlansOffersState {
  return produce(state, draft => {
    switch (action.type) {
      case PlansOffersActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case PlansOffersActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case PlansOffersActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case PlansOffersActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
