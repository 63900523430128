import { useQuery, useQueryClient } from "@tanstack/react-query";

import { ExecuteData, useApi } from "../../useApi";

import {
  GetMembersFetchDataProps,
  GetMembersProps,
  GetMembersInfo
} from "./useGetMembers.types";

import { useAuth } from "@/core/libs/use-auth";
import { useSelectedBu } from "@/hooks/useSelectedBu";

const MEMBERS_QUERY_TOKEN = "members";

export const useGetMembers = ({ params }: GetMembersProps): GetMembersInfo => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();
  const { selectedBU } = useSelectedBu();

  const paramValues = {
    businessActionId: selectedBU?.id,
    page: params.page || 1,
    limit: params.limit || 10
  };

  const fetchData = async ({ params, body }: GetMembersFetchDataProps) => {
    const data: ExecuteData = {
      params,
      bodyObj: Object({ all: true, ...body }),
      url: "accounts/active",
      method: "POST",
      messages: {
        error: "Tivemos um problema ao carregar os membros"
      }
    };

    return execute(data);
  };

  const { data, isFetching } = useQuery({
    queryKey: [MEMBERS_QUERY_TOKEN, [paramValues, params]],
    queryFn: () => fetchData({ params: paramValues, body: params }),
    enabled: isAuthenticated,
    staleTime: 120000,
    keepPreviousData: true
  });

  const invalidateQuery = async () => {
    queryClient.invalidateQueries({ queryKey: [MEMBERS_QUERY_TOKEN] });
  };

  return {
    membersList: data?.data || [],
    isLoadingMembersList: isFetching,
    invalidateQuery
  };
};
