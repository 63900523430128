export const findMonthlyPaymentMethod = (data?: any): string => {
  const paymentMethod =
    data?.businessUnit?.paymentMethod ||
    data?.businessUnit?.parent?.paymentMethod;

  return (
    capitalizeFirstLetter(paymentMethod) ||
    "(Algo deu errado, não foi possível encontrar o método de pagamento dessa unidade de negócios)"
  );
};

function capitalizeFirstLetter(input: string): string {
  const firstLetter = input.charAt(0).toUpperCase();
  const restOfString = input.slice(1).toLowerCase();
  return firstLetter + restOfString;
}
