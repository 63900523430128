import {
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton
} from "@fluentui/react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CloseSidebarMenu } from "@/common/actions/sidebarMenu";
import { SidebarMenuPanelProps } from "@/components/Shared/Layouts/types/Sidebar.types";
import { RootState } from "@/core/store/rootReducer";

export function SidebarMenuPanel({
  theme,
  HeaderContent,
  MainContent,
  FooterContent,
  mainLinks,
  footerLinks
}: SidebarMenuPanelProps): JSX.Element {
  const { isMenuOpen } = useSelector((state: RootState) => state.sidebarMenu);
  const dispatch = useDispatch();

  const closeMenu = () => {
    dispatch(CloseSidebarMenu());
  };

  const handleResize = useCallback(() => {
    if (isMenuOpen && window.innerWidth > 639) {
      closeMenu();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <Dialog
      hidden={!isMenuOpen}
      onDismiss={closeMenu}
      dialogContentProps={{
        type: DialogType.normal,
        title: <HeaderContent theme={theme} closeMenu={closeMenu} />
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: "280px", width: "280px" } }
      }}
    >
      <MainContent
        closeMenu={closeMenu}
        theme={theme}
        links={mainLinks}
        isMenuOpen={isMenuOpen}
      />
      <DialogFooter>
        <FooterContent
          theme={theme}
          closeMenu={closeMenu}
          links={footerLinks}
          isMenuOpen={isMenuOpen}
        />
        <DefaultButton onClick={closeMenu} text="Fechar" />
      </DialogFooter>
    </Dialog>
  );
}
