import {
  DefaultButton,
  Dropdown,
  FocusTrapZone,
  FocusZone,
  IDropdownOption,
  IMessageBar,
  Label,
  Link,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack
} from "@fluentui/react";
import { Form, FormikErrors, FormikTouched } from "formik";
import { useEffect, useState } from "react";
import { ObjectSchema } from "yup";

import { ContractCarrier } from "./ContractCarrier";
import { ContractLine } from "./ContractLine";
import { ContractOffers } from "./ContractOffers";
import { ContractOperation } from "./ContractOperation";
import { PersonalizeLineOffer } from "./PersonalizeLineOffer";
import { InitialCarrierType, SelectedProductContract, StepForm } from "./types";

import { IccidInputContent } from "@/components/IccidInputContent/IccidInputContent";
import { AdditionalOfferPriceToAppend } from "@/components/Offers/AdditionalComponents/AdditionalOfferPriceToAppend";
import { IAdditionalOfferPrice } from "@/components/Settings/AdditionalComponents/types";
import { CustomDrawerFooter } from "@/components/Shared/CustomDrawer";
import { FieldsBuilder, Utils } from "@/core/libs/form-builder";
import { MessageBar } from "@/core/libs/message-bar";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { StyledStep, SubmittingDiv } from "@/modules/Contracts/styles";
import { LineStrategyType } from "@/modules/Settings/pages/Operations/enum";

interface ItemFieldsParams {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;

  selectedProductContract: SelectedProductContract;
  parentOperations: any[];
  contract: any;
  contractProducts: any;
  setSelectedProductContract: (product: SelectedProductContract) => void;

  offers: any[];
  operations: any[];
  setOffers: (offers: any) => void;
  selectedOfferPriceId: number;
  additionalOfferPrice: IAdditionalOfferPrice[];
  carriersList: any;
  carrier: string | number;
  initialCarrier: InitialCarrierType;
  setCarrier: (carrier: string | number) => void;
  setInitialCarrier: (initialCarrier: InitialCarrierType) => void;
  setSelectedOfferPriceId: (offerPriceId: number) => void;
  setIsSmartOffer: (isSmart: boolean) => void;
  isPersonalizedOffer: boolean;
  removePersonalizedOffer: () => void;
  personalizedOffer: boolean;
  setIsPersonalizedOffer: (personalizedOffer: boolean) => void;
  setStatePersonalizedOffer: (state: boolean) => void;
  isSubmitting: boolean;
  onSubmit: (additionalContractItem?: any, values?: any) => void;
  drawerContainerRef: React.MutableRefObject<HTMLDivElement>;
  closePanel: (ev?: any, refresh?: any) => void;
  parcelLimit: number;
  message: IMessageBar;
  setMessage: (message: IMessageBar) => void;
  schema: ObjectSchema;
  schemaWithLineNumber: ObjectSchema;
  schemaWithLineId: ObjectSchema;
  setCurrentSchema: (schema: ObjectSchema<any>) => void;
  questionStep: StepForm;
  setQuestionStep: (step: StepForm) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

export function InsertItemFields({
  values,
  setFieldValue,
  errors,
  touched,

  selectedProductContract,
  parentOperations,
  contract,
  contractProducts,
  setSelectedProductContract,

  offers,
  setOffers,
  operations,
  selectedOfferPriceId,
  additionalOfferPrice,
  carriersList,
  carrier,
  initialCarrier,
  setCarrier,
  setInitialCarrier,
  setSelectedOfferPriceId,
  setIsSmartOffer,
  isPersonalizedOffer,
  removePersonalizedOffer,
  personalizedOffer,
  setIsPersonalizedOffer,
  setStatePersonalizedOffer,

  isSubmitting,
  onSubmit,

  drawerContainerRef,
  closePanel,
  parcelLimit,

  message,
  setMessage,

  schema,
  setCurrentSchema,
  schemaWithLineNumber,
  schemaWithLineId,

  questionStep,
  setQuestionStep,

  isModalOpen,
  setIsModalOpen
}: ItemFieldsParams) {
  const formId = "insert-item-form";
  const { selectedBU } = useSelectedBu();

  const [carriers, setCarriers] = useState<IDropdownOption[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [allPlansAndOffers, setAllPlansAndOffers] = useState([]);
  const [isIccidValid, setIsIccidValid] = useState<boolean>(false);
  const [isConectivityOrSmart, setIsConectivityOrSmart] = useState(false);

  const currentOffer = offers.find(obj => obj.key === values.offerPrice);
  const isFinancedValueBiggerThanZero =
    Number(selectedProductContract?.productFinancedValue) > 0 ||
    Number(selectedProductContract?.saleConditions?.[0]?.financedValue) > 0;

  const operation = parentOperations.find(obj => obj.id === values.operation);
  const typedLineStrategies = [
    LineStrategyType.MANUALLY_TYPED_LINE,
    LineStrategyType.MANUALLY_TYPED_LINE_AND_SEARCH_REMOVED,
    LineStrategyType.MANUALLY_TYPED_LINE_AND_SEARCH_SOLD
  ];
  const lineStrategyWithoutSelectLine = [
    LineStrategyType.NO_LINE,
    LineStrategyType.RANDOM_LINE_SEARCH,
    LineStrategyType.VIRTUAL_LINE,
    LineStrategyType.PRE_LINE_SEARCH_FIFO
  ];
  const canSelectIccid = [
    LineStrategyType.RANDOM_LINE_SEARCH,
    LineStrategyType.MANUALLY_SELECTED_LINE_PRE,
    LineStrategyType.MANUALLY_SELECTED_LINE
  ];
  const canInsertLineNumber =
    operation && typedLineStrategies.includes(operation.lineStrategy);

  useEffect(() => {
    let newSchema = schema;
    if (
      canInsertLineNumber &&
      !lineStrategyWithoutSelectLine.includes(operation?.lineStrategy)
    ) {
      newSchema = newSchema.concat(schemaWithLineNumber);
    } else if (
      canInsertLineNumber === false &&
      !lineStrategyWithoutSelectLine.includes(operation?.lineStrategy)
    ) {
      newSchema = newSchema.concat(schemaWithLineId);
    }
    setCurrentSchema(newSchema);
  }, [
    schema,
    schemaWithLineId,
    schemaWithLineNumber,
    canInsertLineNumber,
    setCurrentSchema,
    operation
  ]);

  const parcelsOptions = (
    limit: number,
    isSecondary = false,
    cashValue: string,
    financedValue: string
  ) => {
    const parcels: IDropdownOption[] = [];
    const cashValueDescription =
      fixCashValue(Number(cashValue)) > 0
        ? `À vista por ${toLocaleString(cashValue)}`
        : "Grátis";

    for (let index = 1; index <= limit; index++) {
      parcels.push({
        key: index,
        text: isSecondary
          ? `${index}ª parcela`
          : index === 1
          ? cashValueDescription
          : `${index} parcelas de ${toLocaleString(
              String(fixCashValue(Number(financedValue) / index))
            )}`
      });
    }

    return parcels;
  };

  const formatAndSetCarriers = carriers => {
    const carriersFormatted: IDropdownOption[] = carriers?.map(carrier => ({
      key: carrier.id,
      text: carrier.name,
      data: carrier.plans
    }));

    setCarriers(carriersFormatted);
  };

  const handleSelectedPriceId = ({
    offerPriceId,
    isSmart
  }: {
    offerPriceId: number;
    isSmart: boolean;
  }) => {
    removePersonalizedOffer();
    setIsSmartOffer(isSmart);
    setQuestionStep(StepForm.PLAN_STEP);
    if (offerPriceId !== selectedOfferPriceId) {
      setSelectedOfferPriceId(offerPriceId);
    } else if (offerPriceId === undefined) {
      setSelectedOfferPriceId(undefined);
    }
  };

  const handleSelectedProductContract = product => {
    if (product?.data) {
      setSelectedProductContract(product.data);
    }
  };

  const cleanMessage = () => {
    setMessage(null);
  };

  const openPersonalizedOffer = () => {
    setIsVisible(true);
  };

  const closePesonalizedOffer = () => {
    setIsVisible(false);
    setIsPersonalizedOffer(false);
  };

  const setPersonalizedOffer = values => {
    setIsPersonalizedOffer(true);
    setStatePersonalizedOffer(values);
    setIsVisible(false);
  };

  return (
    <Form id={formId}>
      <Stack tokens={{ childrenGap: 15 }}>
        <ContractOperation
          operations={operations}
          resetCarrierAndOffers={() => {
            setCarrier("");
            setAllPlansAndOffers(undefined);
          }}
          {...{
            message,
            setMessage,
            formatAndSetCarriers,
            setFieldValue
          }}
        />
        <ContractCarrier
          selectedCarrier={carrier}
          carriers={carriers}
          setCarrier={setCarrier}
          isOperationSelected={!!values.operation}
          {...{
            message,
            setMessage,
            setFieldValue,
            setAllPlansAndOffers
          }}
        />
        {carrier && (
          <ContractOffers
            carrier={carrier}
            allPlansAndOffers={allPlansAndOffers}
            offers={offers}
            setOffers={setOffers}
            handleSelectedPriceId={handleSelectedPriceId}
            setIsConectivityOrSmart={setIsConectivityOrSmart}
            {...{ message, setMessage, setFieldValue }}
          />
        )}
        {carrier &&
          operation &&
          !lineStrategyWithoutSelectLine.includes(operation.lineStrategy) && (
            <ContractLine
              values={values}
              planId={values.plan}
              isCustomLineNumber={canInsertLineNumber}
              {...{ message, setMessage, setFieldValue }}
              operation={operation}
              carriers={carriersList}
              setInitialCarrier={setInitialCarrier}
              setQuestionStep={setQuestionStep}
              initialCarrier={initialCarrier}
            />
          )}
        {canSelectIccid.includes(operation?.lineStrategy) &&
          questionStep === StepForm.LINE_STEP && (
            <IccidInputContent
              onIccidValidate={(valid: boolean) => {
                setIsIccidValid(valid);
              }}
              setIccid={() => {
                return;
              }}
              setInventoryId={inventoryId =>
                setFieldValue("iccidInventoryId", inventoryId)
              }
              operation={operation}
              selectedBU={selectedBU}
              minimalLayout
            />
          )}
        {(questionStep === StepForm.LINE_STEP ||
          lineStrategyWithoutSelectLine.includes(operation?.lineStrategy)) &&
          values?.additionalInfo?.planIsConectivityOrSmart && (
            <Stack.Item>
              <Dropdown
                id="select-device"
                label="Selecione um aparelho"
                required
                placeholder={
                  contractProducts
                    ? selectedProductContract &&
                      !contractProducts.some(
                        product => product?.key === selectedProductContract?.id
                      )
                      ? "Benefício combo fora de estoque selecionado"
                      : "Selecione uma opção"
                    : "Nenhum aparelho encontrado"
                }
                onChange={(evt, item) => {
                  if (isPersonalizedOffer) {
                    removePersonalizedOffer();
                  }

                  handleSelectedProductContract(item);

                  if (Number(item?.data?.saleConditions[0]?.cashValue) === 0) {
                    setFieldValue("additionalInfo.installment", 24);
                  } else {
                    setFieldValue("additionalInfo.installment", undefined);
                  }

                  setFieldValue(
                    "saleCondition",
                    item?.data?.saleConditions[0]?.id
                  );

                  setFieldValue("inventory", item.data?.inventory?.id);

                  setFieldValue("product", item.data?.id);
                }}
                options={contractProducts}
                selectedKey={selectedProductContract?.id || ""}
                disabled={!contractProducts}
              />
            </Stack.Item>
          )}
        {selectedProductContract && (
          <div>
            <Label>Comodato</Label>
            <div style={{ border: "1px solid", borderRadius: "2px" }}>
              <StyledStep>
                <FieldsBuilder
                  {...{
                    fields: [
                      {
                        id: "input-device-brand",
                        label: "Marca",
                        type: "text",
                        name: "additionalInfo.brand",
                        value:
                          selectedProductContract?.brand?.name ||
                          values.additionalInfo?.brand,
                        disabled: true
                      },
                      {
                        id: "input-device-model",
                        label: "Modelo",
                        type: "text",
                        name: "additionalInfo.model",
                        value:
                          selectedProductContract?.name ||
                          values.additionalInfo?.model,
                        disabled: true
                      },
                      {
                        id: "input-device-cash-value",
                        label: "Valor à vista",
                        type: "money",
                        name: "additionalInfo.value",
                        value:
                          selectedProductContract?.productCashValue ||
                          selectedProductContract?.saleConditions?.[0]
                            ?.cashValue ||
                          values.additionalInfo?.value,
                        disabled: true
                      },
                      {
                        id: "input-device-financed-value",
                        label: "Valor à prazo",
                        type: "money",
                        name: "additionalInfo.financedValue",
                        value:
                          selectedProductContract?.productFinancedValue ||
                          selectedProductContract?.saleConditions?.[0]
                            ?.financedValue ||
                          values.additionalInfo?.financedValue,
                        disabled: true
                      }
                    ],
                    errors,
                    touched,
                    setFieldValue,
                    values
                  }}
                />

                {(selectedProductContract?.serialNumber ||
                  selectedProductContract?.inventory?.serialType ===
                    "IMEI") && (
                  <FieldsBuilder
                    {...{
                      fields: [
                        {
                          id: "input-device-serial-number",
                          label: "Num. Série",
                          type: "text",
                          name: "additionalInfo.serialNumber",
                          value:
                            selectedProductContract?.serialNumber ||
                            selectedProductContract?.inventory?.serialNumber ||
                            "",
                          mask: "******-**-******-*",
                          disabled: true
                        }
                      ],
                      errors,
                      touched,
                      setFieldValue,
                      values
                    }}
                  />
                )}
              </StyledStep>
            </div>
          </div>
        )}

        {isPersonalizedOffer && personalizedOffer ? (
          <Link
            id="button-remove-personalized-offer"
            onClick={removePersonalizedOffer}
            disabled={!values.offerPrice}
          >
            Remover
          </Link>
        ) : (
          <Link
            id="button-personalized-offer"
            onClick={openPersonalizedOffer}
            disabled={
              !values.offerPrice ||
              (values.additionalInfo.planIsConectivityOrSmart &&
                questionStep === StepForm.INITIAL_STEP)
            }
          >
            Personalizar
          </Link>
        )}

        {message && (
          <MessageBar message={message} dismissMessage={cleanMessage} />
        )}

        <CustomDrawerFooter drawerContainerRef={drawerContainerRef}>
          <DefaultButton
            id="button-cancel"
            onClick={closePanel}
            style={{ margin: "12px" }}
          >
            Cancelar
          </DefaultButton>

          <PrimaryButton
            id="submit-button"
            type="primary"
            form={formId}
            disabled={
              isSubmitting ||
              !values.operation ||
              !values.offerPrice ||
              (!values.line &&
                !values.lineNumber &&
                !lineStrategyWithoutSelectLine.includes(
                  operation.lineStrategy
                )) ||
              (values.additionalInfo.planIsConectivityOrSmart &&
                !selectedProductContract) ||
              (operation.lineStrategy ===
                LineStrategyType.MANUALLY_SELECTED_LINE &&
                !isIccidValid) ||
              (operation.name === "Portabilidade" &&
                initialCarrier === undefined)
            }
          >
            Adicionar
          </PrimaryButton>
        </CustomDrawerFooter>
        <Modal
          isOpen={isVisible}
          onDismiss={closePesonalizedOffer}
          isBlocking={true}
          containerClassName={contentStyles.container}
        >
          {isVisible && currentOffer ? (
            <PersonalizeLineOffer
              setSaleConditionValue={value =>
                setFieldValue("saleCondition", value)
              }
              setInventoryValue={value => setFieldValue("inventory", value)}
              setProductId={value => setFieldValue("product", value)}
              closeOffer={closePesonalizedOffer}
              selectedOffer={Utils.copy(
                offers.find(obj => obj.key === values.offerPrice)
              )}
              planIsConectivityOrSmart={isConectivityOrSmart}
              selectedProduct={selectedProductContract}
              setSelectedProduct={setSelectedProductContract}
              setPersonalizedOffer={setPersonalizedOffer}
              handleSelectedProductContract={handleSelectedProductContract}
              selectedOfferPriceId={selectedOfferPriceId}
              businessUnitId={contract.businessUnit.id}
              currentProducts={contractProducts}
              operation={values.operation}
              service={contract.service.id}
              setIsModalOpen={setIsVisible}
            />
          ) : (
            <DefaultButton id="button-cancel" onClick={closePesonalizedOffer}>
              Cancelar
            </DefaultButton>
          )}
        </Modal>
        <AdditionalOfferPriceToAppend
          additionalOfferPriceList={additionalOfferPrice}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          submit={onSubmit}
          submitMessage={message}
          clearSubmitMessage={cleanMessage}
          canEdit={true}
          planPrice={+currentOffer?.details?.offerPrice?.price}
        />
      </Stack>

      {isSubmitting ? (
        <FocusTrapZone disabled={!isSubmitting}>
          <FocusZone>
            <SubmittingDiv>
              <Spinner size={SpinnerSize.large} />
            </SubmittingDiv>
          </FocusZone>
        </FocusTrapZone>
      ) : null}
    </Form>
  );
}

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch"
  }
});

function toLocaleString(value: string) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}

function fixCashValue(value: number) {
  return Math.ceil(value * 10) / 10;
}
