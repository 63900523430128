import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { listFailure, listSuccess } from "../actions/plansOffers";
import { PlansOffersActionsTypes } from "../types/PlansOffers.reducer.types";

import { DashboardsService } from "@/core/libs/api";

const abortController = new AbortController();
const { signal } = abortController;
const service = DashboardsService("/plans");

export function* listPlansOffers({ payload }: AnyAction) {
  const { filter } = payload;

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listSuccess({ list: response.data }));
  } catch (error) {
    yield put(listFailure());
  }
}

export default all([
  takeLatest(PlansOffersActionsTypes.LIST_REQUEST, listPlansOffers)
]);
