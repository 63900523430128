import { Switch } from "react-router-dom";

import { useGetProfile } from "../libs/api/react-query";

import PrivateRoute from "./PrivateRoute";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import Terms from "@/modules/Terms";
import { ViewTerms } from "@/modules/Terms/pages/view-terms";

function TermsRoute(): JSX.Element {
  const userProfileQuery = useGetProfile();
  const permissions = userProfileQuery.data?.data?.role.permissions || [];
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/terms"
        permissionScope={permissions[PermissionsScope.POS]}
        module={PermissionsPosModule.TERMS}
        action={PermissionsAction.LIST}
      >
        <Terms />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/terms/:id"
        permissionScope={permissions[PermissionsScope.POS]}
        module={PermissionsPosModule.TERMS}
        action={PermissionsAction.READ}
      >
        <ViewTerms />
      </PrivateRoute>
    </Switch>
  );
}

export default TermsRoute;
