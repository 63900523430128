/**
 *
 * @param timestamp: number
 * @example convertTimestampToDateAndTime(1684867338) => {date: 23/05/2023, time: 15:42:18}
 * @returns {date: string, time: string}
 */

export const convertTimestampToDateAndTime = (timestamp: number) => {
  const dateTime = new Date(timestamp);

  const day = dateTime.getDate();
  const month = dateTime.getMonth() + 1;
  const year = dateTime.getFullYear();

  const date = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;

  const hour = dateTime.getHours();
  const minute = dateTime.getMinutes();

  const time = `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`;

  return { date, time };
};
