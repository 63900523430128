import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class BusinessUnits extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin ? `admin/business-units${subPaths}` : `business-units${subPaths}`
    );
  }

  listBusinessUnitsTree({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/tree`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }
  listOrganizationsTree({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/tree/organizations`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  createBusinessUnit({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  editBusinessUnit({ id, params, signal }: IApi) {
    const options: any = {
      signal,
      method: "PATCH",
      baseUrl: `${this.baseUrl}/${id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }
}

export default BusinessUnits;
