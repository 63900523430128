import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class ModelsGroups extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/service-model-options-group${subPaths}`
        : `service-model-options-group${subPaths}`
    );
    this.deleteModelsGroups = this.deleteModelsGroups.bind(this);
  }

  getModelsGroup({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/${params.id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  deleteModelsGroups({ params, signal }: IApi) {
    const id = params.id;

    const options: any = {
      signal,
      method: "DELETE",
      baseUrl: `${this.baseUrl}/${id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }
}

export default ModelsGroups;
