import styled from "styled-components";

export const Name = styled.p`
  margin-bottom: unset;
  font-size: 1.2rem;
  color: rgb(57, 92, 163);
  font-weight: 600;
`;

export const CustomWrapper = styled.div`
  position: relative;
  width: 20rem;
  &:before {
    content: "*";
    color: rgb(164, 38, 44);
    position: absolute;
    top: -4px;
    right: -9px;
  }
`;

export const CustomInput = styled.input`
  box-shadow: none;
  width: 100%;
  padding-left: 7px;
  box-sizing: border-box;
  border: 1px solid rgb(96, 94, 92);
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  :focus {
    outline: 2px solid rgb(57, 92, 163);
  }
`;

export const CustomSelect = styled.select`
  box-shadow: none;
  width: 100%;
  padding-left: 7px;
  padding-top: 4px;
  box-sizing: border-box;
  border: 1px solid rgb(96, 94, 92);
  border-radius: 2px;
  appearance: none;
  background-color: rgb(255, 255, 255);
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  :focus {
    outline: 2px solid rgb(57, 92, 163);
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  .chevronIcon {
    margin-right: 2.5px;
    font-size: 12px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;
