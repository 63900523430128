import { useQuery } from "@tanstack/react-query";

import { useApi } from "../../useApi";

import { transformPermissions } from "./transform-permissions";

import { useAuth } from "@/core/libs/use-auth";

export const useGetProfile = () => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();

  const context = useQuery(
    ["profile"],
    () =>
      execute({
        url: "users/profile?query=true",
        notificationMessage: "Buscando Profile"
      }),
    {
      staleTime: Infinity,
      enabled: isAuthenticated
    }
  );

  const data = context.data?.data;
  const permissions = data?.role.permissions;

  if (Array.isArray(permissions)) {
    data.role.permissions = transformPermissions(permissions);
  }

  return context;
};
