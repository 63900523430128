type AccordionListItemType = {
  label: string;
  value: string;
  isStrong: boolean;
};

function AccordionListItem({
  label,
  value,
  isStrong = false
}: AccordionListItemType) {
  return (
    <li>
      <span>{label}: </span>
      {isStrong ? (
        <>
          <strong>{value.split("=")[0]}</strong>=
          <span>{value.split("=")[1]}</span>
        </>
      ) : (
        <span style={{ fontWeight: "bold" }}>{value}</span>
      )}
    </li>
  );
}

export default AccordionListItem;
