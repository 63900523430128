import { Stack } from "@fluentui/react";
import {
  ComboBox,
  IComboBox,
  IComboBoxOption
} from "@fluentui/react/lib/ComboBox";
import { FormEvent, useEffect, useRef, useState } from "react";

import { Input } from "./types";

import { Loader } from "@/components/Shared/Loader";
import { AccountsService } from "@/core/libs/api";
import { useDebounce } from "@/hooks/index";

interface OccupationFieldProps {
  occupationConfig: Input;
  setValue: CallableFunction;
  errors: any;
  touched: any;
  defaultValue: string;
}

const abortController = new AbortController();
const { signal } = abortController;
const accountsService = AccountsService("/occupations");

const requestOccupationList = ({ name }) => {
  const params = { query: { name } };
  return accountsService.list({ params, signal });
};

function OccupationField({
  occupationConfig,
  setValue,
  errors,
  touched,
  defaultValue
}: OccupationFieldProps): JSX.Element {
  const [isFreeformEnabled, setIsFreeformEnabled] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedKey, setSelectedKey] = useState<string | number>("");
  const [selectedTerms, setSelectedTerms] = useState<string>(null);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedTerms = useDebounce(selectedTerms, 1100);
  const initialRender = useRef(true);

  useEffect(() => {
    if (defaultValue) {
      setItems([{ key: "0", text: defaultValue }]);
      setSelectedKey("0");
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (debouncedTerms) {
      setIsFetching(true);

      const response = requestOccupationList({ name: debouncedTerms });

      response
        .then(res => {
          if (res.data) {
            const formatOptions = res.data.map(option => ({
              key: option.id,
              text: option.name
            }));

            setItems(formatOptions);
          }
        })
        .finally(() => setIsFetching(false));
    }
    //eslint-disable-next-line
  }, [debouncedTerms]);

  const onChange = (
    ev: FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    if (option) {
      setSelectedKey(option?.key);
      setValue(occupationConfig.name, option?.text);
    } else if (debouncedTerms) {
      setItems([{ key: "0", text: debouncedTerms }]);
      setSelectedKey("0");
      setValue(occupationConfig.name, debouncedTerms);
    }
  };

  return (
    <Stack styles={{ root: { position: "relative" } }}>
      <ComboBox
        onChange={onChange}
        selectedKey={selectedKey}
        allowFreeform={isFreeformEnabled}
        autoComplete="on"
        options={items}
        openOnKeyboardFocus
        styles={{ optionsContainer: { maxHeight: "250px" } }}
        useComboBoxAsMenuWidth
        onPendingValueChanged={(option, __, value) => {
          if (value === "") {
            setItems([]);
            setSelectedKey("");
            setValue(occupationConfig.name, "");
            setSelectedTerms(null);
          }

          value && setSelectedTerms(value);
        }}
        errorMessage={
          errors[occupationConfig.name] && touched[occupationConfig.name]
            ? errors[occupationConfig.name]
            : null
        }
        onFocus={() => {
          if (!isFreeformEnabled) {
            setTimeout(() => {
              setIsFreeformEnabled(true);
            }, 100);
          }
        }}
        onBlur={() => isFreeformEnabled && setIsFreeformEnabled(false)}
        placeholder={occupationConfig.placeholder || occupationConfig.label}
        label={occupationConfig.label}
        disabled={occupationConfig.disabled || isFetching || false}
        required={occupationConfig.required || false}
        autofill={{
          required: occupationConfig.required || false
        }}
      />

      {isFetching && <Loader customMessage="Buscando ocupação..." />}
    </Stack>
  );
}

export default OccupationField;
