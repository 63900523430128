export const fields = {
  get witnesses() {
    return [
      {
        label: "Testemunhas",
        type: "witnesses",
        name: "witnesses",
        value: [
          {
            fullName: "",
            documentNumber: ""
          },
          {
            fullName: "",
            documentNumber: ""
          }
        ],
        fields: [
          {
            label: "Nome",
            type: "text",
            name: "fullName",
            required: true,
            value: ""
          },
          {
            label: "CPF",
            type: "text",
            name: "documentNumber",
            mask: "999.999.999-99",
            required: true,
            value: ""
          }
        ],
        config: {
          hideLabel: true
        }
      }
    ];
  }
};
