import { Icon, Stack } from "@fluentui/react";

import { PlanAppIconList } from "../PlanAppIconList";
import { CardContainer } from "../styles";

import { PlanCardWithBadge } from "./PlanCardWithBadge";
import { ImageLogo } from "./styles";

import { Plan, PlanOfferItem } from "@/modules/Offers/types/PlansOffers.types";

type CardPlanProps = {
  plan: PlanOfferItem;
  selectedPlan?: Plan;
  selectedCarrier?: PlanOfferItem;
  setSelectedPlan?: (plan: Plan) => void;
  setSelectedCarrier?: (carrier: PlanOfferItem) => void;
  openModal: () => void;
};

export function CardPlan({
  plan,
  selectedPlan,
  selectedCarrier,
  setSelectedPlan,
  setSelectedCarrier,
  openModal
}: CardPlanProps): JSX.Element {
  return (
    <CardContainer grow className="ms-depth-16 ms-sm6 ms-md4 ms-lg3 ms-xl2">
      <ImageLogo src={plan.logo} alt={plan.name} />

      <Stack tokens={{ childrenGap: 15 }}>
        {!plan.name.includes("PABX") && (
          <>
            <Stack horizontal verticalAlign="center">
              <Icon
                iconName="Phone"
                style={{
                  fontSize: 20,
                  marginRight: 15
                }}
              />
              <span className="ms-fontSize-18">Ligações ilimitadas</span>
            </Stack>
            <Stack horizontal verticalAlign="center">
              <Icon
                iconName="Globe"
                style={{
                  fontSize: 20,
                  marginRight: 15
                }}
              />
              <span className="ms-fontSize-18">Pacotes de dados</span>
            </Stack>
          </>
        )}
      </Stack>
      <PlanCardWithBadge
        plan={plan}
        selectedPlan={selectedPlan}
        selectedCarrier={selectedCarrier}
        setSelectedPlan={setSelectedPlan}
        setSelectedCarrier={setSelectedCarrier}
        openModal={openModal}
      />
      {plan.plans[0]?.freeDataApps?.length > 0 && (
        <PlanAppIconList
          appIcon={plan.plans[0].freeDataApps}
          appInfo={plan.plans[0].appsInfos}
          appTitle="Apps Ilimitados"
          verticalAlign="end"
          height="100%"
        />
      )}
    </CardContainer>
  );
}
