import { Stack } from "@fluentui/react";
import { useState } from "react";
import { Helmet } from "react-helmet";

import { Loader } from "@/components/Shared/Loader";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import { useSelectedBu } from "@/hooks/useSelectedBu";

function BusinessMaterial(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);

  const { selectedBU } = useSelectedBu();

  return (
    <Stack
      tokens={{ childrenGap: 15 }}
      verticalFill
      style={{ position: "relative" }}
    >
      <Helmet>
        <title>Material de Negócios - Libertas</title>
      </Helmet>

      {isLoading ? (
        <Loader customMessage="Carregando Material de Negócios" />
      ) : (
        <>
          <h2 className="ms-fontSize-24 ms-fontWeight-semibold">
            Material de Negócios
          </h2>

          <h3 className="ms-fontSize-20">
            Materiais gráficos de apoio para Negócios.
          </h3>
        </>
      )}

      {selectedBU?.settings?.offersLink && (
        <iframe
          title="Material de Negócios"
          src={selectedBU?.settings.offersLink}
          style={{ width: `100%`, height: `calc(100% - 10px)` }}
          onLoad={() => setIsLoading(false)}
        />
      )}
    </Stack>
  );
}

export default BusinessMaterial;
