import produce from "immer";

import { AccountActionsTypes, AccountsAction, AccountsState } from "../types";

export const INITIAL_STATE: AccountsState = {
  list: [],
  isLoadingList: false,
  message: undefined
};

export default function accountsReducer(
  state = INITIAL_STATE,
  action: AccountsAction
): AccountsState {
  return produce(state, draft => {
    switch (action.type) {
      case AccountActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }
      case AccountActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }
      case AccountActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }
      case AccountActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }
      case AccountActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }
      case AccountActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }
      case AccountActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }
      case AccountActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        // draft.list = [];
        break;
      }
      case AccountActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        // draft.list = [];
        break;
      }
      case AccountActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
