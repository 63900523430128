import { memo } from "react";

import { BadgeContainer } from "./styles/Badge.styles";

export type BadgeSize = "small" | "smallXs" | "medium" | "large";
export type BadgeType =
  | "primary"
  | "secondary"
  | "light"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "infoNeutral";
interface BadgeProps {
  /** Size of the badge. */
  size?: BadgeSize;
  /** Type of the badge. */
  type?: BadgeType;
  /** Icon to be displayed inside the badge. Be sure it's an `<svg />` element to respect sizing. */
  icon?: React.ReactNode;
  /** If true, the badge will be displayed as a pill. */
  isPill?: boolean;
  /** If true, the badge will be displayed outlined. */
  isOutlined?: boolean;
  children: React.ReactNode;
}

/** A generic badge component inspired by Fluent UI's design language. */
function Badge({
  size = "medium",
  type = "primary",
  icon,
  isPill = false,
  isOutlined = false,
  children
}: BadgeProps) {
  return (
    <BadgeContainer
      className={[size, type, isPill && "pill", isOutlined && "outlined"].join(
        " "
      )}
    >
      {icon && icon}
      {children}
    </BadgeContainer>
  );
}

export default memo(Badge);
