import { createContext, Reducer, useEffect, useReducer, useState } from "react";

import { authReducer, checkAuthentication } from "./authReducer";
import {
  AuthAction,
  AuthContextState,
  AuthProviderInterface,
  AuthState
} from "./types";

function getDefaultState(): AuthState {
  const DEFAULT_STATE = {
    isAuthenticating: false,
    isAuthenticated: false,
    isChecking: true
  };

  const storedState = {};

  return {
    ...DEFAULT_STATE,
    ...storedState
  };
}

export const AuthContext = createContext<AuthContextState>({
  state: getDefaultState(),
  dispatch: () => null
});

export function AuthProvider({ children }: AuthProviderInterface): JSX.Element {
  // Holds authentication state
  const [state, dispatch] = useReducer<Reducer<AuthState, AuthAction>>(
    authReducer,
    getDefaultState()
  );

  const [contextValue, setContextValue] = useState<AuthContextState>({
    state,
    dispatch
  });

  // Update context value and trigger re-render
  // This patterns avoids unnecessary deep renders
  // https://reactjs.org/docs/context.html#caveats
  useEffect(() => {
    setContextValue((contextValue: AuthContextState) => ({
      ...contextValue,
      state
    }));
  }, [state]);

  // Verify user is logged-in on AuthProvider mount
  // Avoids storing sensitive data in local storage
  useEffect(() => {
    checkAuthentication({ dispatch });
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}
