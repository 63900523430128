import styled from "styled-components";

export const Wrapper = styled.div`
  width: 32rem;
  min-height: 14rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: solid #335393;

  p {
    line-height: 3rem;
    font-size: 1.1rem;
    color: #201f1e;
  }
  h1 {
    font-size: 1.5rem;
    color: #335393;
    text-align: left;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;

  & > button {
    font-weight: bold;
    min-width: 12rem;
    border-radius: 0.2rem;
    color: #335393;
    border: 0.13rem solid #335393;
    transition: 0.2s linear all;
    :hover {
      background-color: #335393;
      color: #fff;
    }
  }
`;
