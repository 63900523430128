import produce from "immer";

import {
  InventoriesActionsTypes,
  InventoriesReducerActions,
  InventoriesState
} from "../types/Inventories.reducer.types";

const INITIAL_STATE: InventoriesState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: InventoriesReducerActions
): InventoriesState {
  return produce(state, draft => {
    switch (action.type) {
      case InventoriesActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case InventoriesActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case InventoriesActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case InventoriesActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case InventoriesActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case InventoriesActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case InventoriesActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case InventoriesActionsTypes.TRANSFER_REQUEST: {
        draft.message = undefined;
        break;
      }

      case InventoriesActionsTypes.TRANSFER_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case InventoriesActionsTypes.TRANSFER_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case InventoriesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case InventoriesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case InventoriesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
