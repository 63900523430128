import { ContractActionsTypes } from "../types";

export function createContractRequest(values, { setSubmitting }, closePanel) {
  return {
    type: ContractActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel }
  };
}

export function createContractSuccess(message) {
  return {
    type: ContractActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createContractFailure(message) {
  return {
    type: ContractActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editContractRequest(values, { setSubmitting }, closePanel) {
  return {
    type: ContractActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel }
  };
}

export function editContractSuccess(message) {
  return {
    type: ContractActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editContractFailure(message) {
  return {
    type: ContractActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listContractRequest(filter?) {
  return {
    type: ContractActionsTypes.LIST_REQUEST,
    payload: { filter }
  };
}

export function listContractSuccess({ list }) {
  return {
    type: ContractActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listContractFailure() {
  return {
    type: ContractActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ContractActionsTypes.DISMISS_MESSAGE
  };
}
