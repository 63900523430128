import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Icon } from "@fluentui/react/lib/Icon";
import { Label } from "@fluentui/react/lib/Label";
import { Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import { TextField } from "@fluentui/react/lib/TextField";
import { Field, FieldArray } from "formik";

import { Card } from "@/components/Shared/Card";
import StripedBackground from "@/components/Shared/StripedBackground";

interface LinkedGroupsFieldsProps {
  config;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  linkedGroups: any;
}

interface LinkedGroupsFieldProps {
  cfg: any;
  currentValue: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  index: any;
  arrayHelpers: any;
  value?: any;
}

function LinkedGroups(props: LinkedGroupsFieldProps): JSX.Element {
  const { cfg, currentValue, setFieldValue, errors, touched, index, value } =
    props;

  return (
    <StripedBackground>
      <Stack horizontal tokens={{ childrenGap: 10 }} key={currentValue}>
        <Stack tokens={{ childrenGap: 5 }} grow>
          <div className="ms-Grid-row" style={{ marginBottom: 15 }}>
            {cfg.fields.map(field => {
              switch (field.type) {
                case "select":
                  return (
                    <div
                      className={`ms-Grid-col ms-sm12${
                        field.size ? ` ms-lg${field.size}` : ""
                      }`}
                      key={`${field.name}[${index}]`}
                    >
                      <Field
                        id={`${field.name}-${index}`}
                        name={`${currentValue}.${field.name}`}
                        type={field.type}
                        as={Dropdown}
                        onChange={(evt, item) => {
                          setFieldValue(
                            `${currentValue}.${field.name}`,
                            item.key
                          );
                        }}
                        defaultSelectedKey={
                          field.name === "useDefaultDeliveryTime"
                            ? value.useDefaultDeliveryTime
                            : value.useDefaultPrice
                        }
                        placeholder={field.label}
                        key={`${currentValue}.${field.name}`}
                        label={field.label}
                        disabled={field.disabled || false}
                        styles={{ dropdown: { width: "100%" } }}
                        required={field.required || false}
                        errorMessage={
                          errors[`${currentValue}.${field.name}`] &&
                          touched[`${currentValue}.${field.name}`]
                            ? errors[`${currentValue}.${field.name}`]
                            : null
                        }
                      />
                    </div>
                  );
                case "money":
                  return (
                    <div
                      className={`ms-Grid-col ms-sm12${
                        field.size ? ` ms-lg${field.size}` : ""
                      }`}
                      key={`${field.name}[${index}]`}
                    >
                      <Field
                        name={`${currentValue}.${field.name}`}
                        type={field.type}
                        as={TextField}
                        prefix="R$"
                        placeholder={field.label}
                        key={`${currentValue}.${field.name}`}
                        label={field.label}
                        disabled={field.disabled || false}
                        required={field.required || false}
                        errorMessage={
                          errors[`${currentValue}.${field.name}`] &&
                          touched[`${currentValue}.${field.name}`]
                            ? errors[`${currentValue}.${field.name}`]
                            : null
                        }
                        onChange={(event, value) => {
                          if (value && value !== "") {
                            value = value.replace(/\D/g, "");
                            if (value.length === 3) {
                              value = value.replace(/(\d{1})(\d{2})/, "$1.$2");
                            } else if (value.length === 4) {
                              value = value.replace(/(\d{2})(\d{2})/, "$1.$2");
                            } else if (value.length === 5) {
                              value = value.replace(/(\d{3})(\d{2})/, "$1.$2");
                            } else if (value.length === 6) {
                              value = value.replace(
                                /(\d{1})(\d{3})(\d{2})/,
                                "$1.$2,$3"
                              );
                            } else if (value.length === 7) {
                              value = value.replace(
                                /(\d{2})(\d{3})(\d{2})/,
                                "$1.$2,$3"
                              );
                            } else if (value.length === 8) {
                              value = value.replace(
                                /(\d{3})(\d{3})(\d{2})/,
                                "$1.$2,$3"
                              );
                            } else if (value.length === 9) {
                              value = value.replace(
                                /(\d{1})(\d{3})(\d{3})(\d{2})/,
                                "$1.$2.$3,$4"
                              );
                            } else if (value.length === 10) {
                              value = value.replace(
                                /(\d{2})(\d{3})(\d{3})(\d{2})/,
                                "$1.$2.$3,$4"
                              );
                            } else if (value.length === 11) {
                              value = value.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                "$1.$2.$3,$4"
                              );
                            } else if (value.length === 12) {
                              value = value.replace(
                                /(\d{1})(\d{3})(\d{3})(\d{3})(\d{2})/,
                                "$1.$2.$3.$4,$5"
                              );
                            } else {
                              value = value.replace(
                                /(\d{10,})(\d{2})/,
                                "$1.$2"
                              );
                            }
                          } else {
                            value = " ";
                          }
                          setFieldValue(`${currentValue}.${field.name}`, value);
                        }}
                      />
                    </div>
                  );

                default:
                  return (
                    <div
                      className={`ms-Grid-col ms-sm12${
                        field.size ? ` ms-lg${field.size}` : ""
                      }`}
                      key={`${field.name}[${index}]`}
                    >
                      <Field
                        name={`${currentValue}.${field.name}`}
                        type={field.type}
                        as={TextField}
                        placeholder={field.label}
                        key={`${currentValue}.${field.name}`}
                        label={field.label}
                        disabled={field.disabled || false}
                        required={field.required || false}
                        errorMessage={
                          errors[`${currentValue}.${field.name}`] &&
                          touched[`${currentValue}.${field.name}`]
                            ? errors[`${currentValue}.${field.name}`]
                            : null
                        }
                      />
                    </div>
                  );
              }
            })}
          </div>
        </Stack>
      </Stack>
    </StripedBackground>
  );
}

export function LinkedGroupsFields(
  props: LinkedGroupsFieldsProps
): JSX.Element {
  const { config, values, errors, touched, setFieldValue, linkedGroups } =
    props;

  return (
    <FieldArray
      name={config.name}
      key={config.name}
      render={arrayHelpers => (
        <div style={{ marginTop: 10 }}>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Text variant={"xLarge"}>
              <Icon iconName="Group" style={{ marginRight: 10 }} />
              {config.label}
            </Text>
          </div>
          <Stack tokens={{ childrenGap: 10 }}>
            {values.shippingPricingGroup?.name && (
              <div>{values.shippingPricingGroup.name}</div>
            )}
            {values &&
              values.map((field, index) => {
                return (
                  <>
                    <Card>
                      <Label style={{ marginLeft: 3 }}>
                        {linkedGroups[index].shippingPricingGroup.name}
                      </Label>
                      <LinkedGroups
                        key={index}
                        cfg={config}
                        currentValue={`${config.name}[${index}]`}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        index={index}
                        arrayHelpers={arrayHelpers}
                        value={field}
                      />
                    </Card>
                  </>
                );
              })}
          </Stack>
        </div>
      )}
    />
  );
}
