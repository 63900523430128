export const formatMoneyValue = value => {
  const stringValue = typeof value === "number" ? value.toString() : value;

  if (!stringValue) return " ";

  const numericString = stringValue.replace(/\D/g, "");

  const intValue = parseInt(numericString, 10);

  if (isNaN(intValue)) return " ";

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  })
    .format(intValue / 100)
    .replace(/R\$\s*/, "");
};
