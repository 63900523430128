import { makeRequest } from "../utils/fetchData";
import { MakeRequestOptions } from "../utils/types";

export type IApi = {
  id?: string;
  params?: any;
  signal: AbortSignal;
};

class Api {
  public baseUrl: string;

  constructor(service: string) {
    this.baseUrl = `${import.meta.env.VITE_API_URL}/${service}`;
    this.create = this.create.bind(this);
    this.edit = this.edit.bind(this);
    this.get = this.get.bind(this);
    this.list = this.list.bind(this);
  }

  create({ params, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "POST",
      baseUrl: this.baseUrl
    };

    if (params.query) options.queryParams = params.query;
    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  edit({ params, signal }: IApi) {
    let id = null;
    if (params.body.id && !params.id) {
      id = params.body.id;
    } else {
      id = params.id;
    }
    const options: MakeRequestOptions = {
      signal,
      method: "PATCH",
      baseUrl: `${this.baseUrl}/${id}`
    };

    if (params.query) options.queryParams = params.query;
    options.bodyObj = params.body;
    return makeRequest(options);
  }

  get({ id, signal, params = {} }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/${id}`
    };
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  list({ params, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "GET",
      baseUrl: this.baseUrl
    };

    if (params?.body) options.bodyObj = params.body;
    if (params?.query) options.queryParams = params.query;

    return makeRequest(options);
  }
}

export default Api;
