import {
  DefaultButton,
  Dropdown,
  FocusZone,
  getTheme,
  IconButton,
  Label,
  MaskedTextField,
  Stack,
  TextField
} from "@fluentui/react";
import { useConstCallback } from "@fluentui/react-hooks";
import { Field, FieldArray } from "formik";
import { useEffect, useState } from "react";

import StripedBackground from "@/components/Shared/StripedBackground";

interface ContactsFieldsProps {
  contactCfg: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
}

interface ContactFieldProps {
  cfg: any;
  currentContactName: string;
  setFieldValue: any;
  errors: any;
  touched: any;
  currentContactIndex: number;
  arrayHelpers: any;
  value?: any;
}

function ContactField(props: ContactFieldProps) {
  const theme = getTheme();
  const [mask, setMask] = useState(undefined);

  const useMask = useConstCallback(mask => setMask(mask));
  const removeMask = useConstCallback(() => setMask(undefined));

  const {
    cfg,
    currentContactName,
    setFieldValue,
    errors,
    touched,
    currentContactIndex,
    arrayHelpers,
    value
  } = props;

  useEffect(() => {
    if (value) {
      cfg.fields.forEach(field => {
        if (field.name === "contactType") {
          const detectedMask = field.options.find(
            x => x.key === value.contactType
          );
          //eslint-disable-next-line
          if (detectedMask?.mask) useMask(detectedMask.mask);
        }
      });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <StripedBackground>
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
        key={`${currentContactName}${currentContactIndex}`}
      >
        <Stack tokens={{ childrenGap: 5 }} grow>
          <div className="ms-Grid-row" style={{ marginBottom: 15 }}>
            {cfg.fields.map(field => {
              switch (field.type) {
                case "select":
                  // FIXME: improve
                  if (value.contactType) {
                    const fieldMask = field.options.find(
                      x => x.key === value.contactType
                    );
                    //prettier-ignore
                    //eslint-disable-next-line
                    if (mask && mask !== fieldMask.mask) useMask(fieldMask?.mask);
                  }

                  return (
                    <div
                      className={`ms-Grid-col ms-sm12${
                        field.size ? ` ms-lg${field.size}` : ""
                      }`}
                      key={`${field.name}[${currentContactIndex}]`}
                    >
                      <FocusZone shouldFocusOnMount={field.autoFocus}>
                        <Field
                          name={`${currentContactName}[${currentContactIndex}].${field.name}`}
                          id={`${currentContactName}[${currentContactIndex}].${field.name}`}
                          key={`${currentContactName}[${currentContactIndex}].${field.name}`}
                          type={field.type}
                          as={Dropdown}
                          onChange={(evt, item) => {
                            if (item.mask) {
                              //eslint-disable-next-line
                              useMask(item.mask);
                            } else {
                              removeMask();
                            }

                            setFieldValue(
                              `${currentContactName}[${currentContactIndex}].${field.name}`,
                              item.key
                            );
                          }}
                          placeholder={field.label}
                          selectedKey={value[field.name]}
                          label={field.label}
                          disabled={field.disabled || false}
                          options={field.options}
                          styles={{ dropdown: { width: "100%" } }}
                          required={field.required || false}
                          errorMessage={
                            errors[currentContactName]?.[currentContactIndex]?.[
                              field.name
                            ] &&
                            touched[currentContactName]?.[
                              currentContactIndex
                            ]?.[field.name]
                              ? errors[currentContactName]?.[
                                  currentContactIndex
                                ]?.[field.name]
                              : null
                          }
                        />
                      </FocusZone>
                    </div>
                  );

                default: {
                  return (
                    <div
                      className={`ms-Grid-col ms-sm12${
                        field.size ? ` ms-lg${field.size}` : ""
                      }`}
                      key={`${field.name}[${currentContactIndex}]`}
                    >
                      <Field
                        name={`${currentContactName}[${currentContactIndex}].${field.name}`}
                        key={`${currentContactName}[${currentContactIndex}].${field.name}`}
                        type={field.type}
                        as={mask ? MaskedTextField : TextField}
                        placeholder={field.label}
                        label={field.label}
                        disabled={field.disabled || false}
                        mask={mask}
                        required={field.required || false}
                        errorMessage={
                          errors[currentContactName]?.[currentContactIndex]?.[
                            field.name
                          ] &&
                          touched[currentContactName]?.[currentContactIndex]?.[
                            field.name
                          ]
                            ? errors[currentContactName]?.[
                                currentContactIndex
                              ]?.[field.name]
                            : null
                        }
                        onChange={(event, value: string) => {
                          if (mask) {
                            value = value.replace(/\D/g, "");
                            setFieldValue(
                              `${currentContactName}[${currentContactIndex}].${field.name}`,
                              value
                            );
                          } else {
                            setFieldValue(
                              `${currentContactName}[${currentContactIndex}].${field.name}`,
                              value
                            );
                          }
                        }}
                        autoFocus={field.autoFocus}
                      />
                    </div>
                  );
                }
              }
            })}
          </div>
        </Stack>
        <Stack.Item align="center">
          <IconButton
            iconProps={{ iconName: "Delete" }}
            title="Remover contato"
            ariaLabel="Remover contato"
            styles={{
              root: {
                color: theme.palette.red
              },
              iconHovered: {
                color: theme.palette.redDark
              }
            }}
            onClick={() => arrayHelpers.remove(currentContactIndex)}
          />
        </Stack.Item>
      </Stack>
    </StripedBackground>
  );
}

export function ContactsFields(props: ContactsFieldsProps): JSX.Element {
  const { contactCfg, values, errors, touched, setFieldValue } = props;
  return (
    <FieldArray
      name={contactCfg.name}
      key={contactCfg.name}
      render={arrayHelpers => (
        <div style={{ marginTop: 10 }}>
          <Label required={contactCfg.required || false}>
            {contactCfg.label}
          </Label>
          <Stack tokens={{ childrenGap: 10 }}>
            {values instanceof Array &&
              values.map((contact, contactIndex) => {
                return (
                  <ContactField
                    key={contactIndex}
                    cfg={contactCfg}
                    currentContactName={contactCfg.name}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    currentContactIndex={contactIndex}
                    arrayHelpers={arrayHelpers}
                    value={contact}
                  />
                );
              })}
            <div>
              <DefaultButton
                type="button"
                onClick={() => {
                  arrayHelpers.push({ contactType: "", contact: "" });
                }}
              >
                Adicionar novo contato
              </DefaultButton>
            </div>
          </Stack>
        </div>
      )}
    />
  );
}
