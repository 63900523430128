import { mergeStyleSets } from "@fluentui/merge-styles";

export const contentStyles = mergeStyleSets({
  stackContainer: {
    selectors: {
      "@media(max-width: 1100px)": {
        flexWrap: "wrap",
        justifyContent: "center"
      },
      "@media(max-width: 800px)": {
        justifyContent: "center"
      },
      "> div": {
        selectors: {
          "@media(max-width: 1100px)": {
            marginTop: 10
          }
        }
      }
    }
  },
  badgeContainer: {
    selectors: {
      ".ms-Stack-inner": {
        selectors: {
          "@media(max-width: 1100px)": {
            justifyContent: "center"
          }
        }
      }
    }
  },
  commandBarContainer: {
    flexGrow: 1,
    selectors: {
      ".ms-CommandBar": {
        backgroundColor: "transparent",
        selectors: {
          "@media(max-width: 420px)": {
            backgroundColor: "white"
          }
        }
      },
      ".ms-CommandBar-primaryCommand": {
        justifyContent: "flex-end",
        selectors: {
          "@media(max-width: 1100px)": {
            justifyContent: "center"
          },
          "@media(max-width: 420px)": {
            justifyContent: "space-between",
            width: "100%"
          }
        }
      }
    }
  }
});
