import {
  DefaultButton,
  getTheme,
  IconButton,
  Label,
  MaskedTextField,
  Stack,
  TextField
} from "@fluentui/react";
import { Field, FieldArray } from "formik";

import { DateField } from "./date-field";
import { Utils } from "./helpers";

interface SignersFieldsProps {
  SignersCfg: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
}

interface SignersFieldProps {
  cfg: any;
  currentSigners: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  index: any;
  arrayHelpers: any;
  value?: any;
}

function SignerField(props: SignersFieldProps): JSX.Element {
  const theme = getTheme();

  const {
    cfg,
    currentSigners,
    errors,
    touched,
    index,
    arrayHelpers,
    setFieldValue,
    value
  } = props;

  return (
    <Stack horizontal tokens={{ childrenGap: 10 }} key={currentSigners}>
      <Stack.Item align="center">
        <IconButton
          iconProps={{ iconName: "Remove" }}
          title="Remover Signátario"
          ariaLabel="Remover Signátario"
          styles={{
            root: {
              color: theme.palette.red
            },
            iconHovered: {
              color: theme.palette.redDark
            }
          }}
          onClick={() => arrayHelpers.remove(index)}
        />
      </Stack.Item>
      <Stack tokens={{ childrenGap: 5 }} grow>
        <div className="ms-Grid-row" style={{ marginBottom: 15 }}>
          {cfg.fields.map(field => {
            switch (field.type) {
              default:
                return (
                  <div
                    className={`ms-Grid-col ms-sm12${
                      field.size ? ` ms-lg${field.size}` : ""
                    }`}
                    key={`${field.name}[${index}]`}
                  >
                    <Field
                      id={`input-${field.name}-${index}`}
                      name={`${currentSigners}.${field.name}`}
                      type={field.type}
                      as={field.mask ? MaskedTextField : TextField}
                      placeholder={field.label}
                      key={`${currentSigners}.${field.name}`}
                      label={field.label}
                      disabled={field.disabled || false}
                      mask={field.mask}
                      required={field.required || false}
                      errorMessage={
                        errors[`${currentSigners}.${field.name}`] &&
                        touched[`${currentSigners}.${field.name}`]
                          ? errors[`${currentSigners}.${field.name}`]
                          : null
                      }
                    />
                  </div>
                );

              case "date":
                return (
                  <Field
                    id={`input-${field.name}-${index}`}
                    name={`${currentSigners}.${field.name}`}
                    type={field.type}
                    key={`${currentSigners}.${field.name}`}
                    as={DateField}
                    label={field.label}
                    style={{ marginLeft: "1%", width: "98.2%" }}
                    placeholder={field.placeholder}
                    disabled={field.disabled || false}
                    errorMessage={
                      errors[`${currentSigners}.${field.name}`] &&
                      touched[`${currentSigners}.${field.name}`]
                        ? errors[`${currentSigners}.${field.name}`]
                        : null
                    }
                    value={Utils.formatDateValue(value[field.name])}
                    isRequired={field.required || false}
                    setFieldValue={setFieldValue}
                  />
                );
            }
          })}
        </div>
      </Stack>
    </Stack>
  );
}

export function SignersFields(props: SignersFieldsProps): JSX.Element {
  const { SignersCfg, values, errors, touched, setFieldValue } = props;
  return (
    <FieldArray
      name={SignersCfg.name}
      key={SignersCfg.name}
      render={arrayHelpers => (
        <div style={{ marginTop: 10 }}>
          <Label required={SignersCfg.required || false}>
            {SignersCfg.label}
          </Label>
          <Stack tokens={{ childrenGap: 10 }}>
            {values instanceof Array &&
              values.map((signer, signerIndex) => {
                return (
                  <SignerField
                    key={signerIndex}
                    cfg={SignersCfg}
                    currentSigners={`${SignersCfg.name}[${signerIndex}]`}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    index={signerIndex}
                    arrayHelpers={arrayHelpers}
                    value={signer}
                  />
                );
              })}
            <div>
              <DefaultButton
                type="button"
                onClick={() => {
                  arrayHelpers.push({
                    name: "",
                    email: "",
                    documentation: "",
                    birthDate: ""
                  });
                }}
              >
                Adicionar novo signatário
              </DefaultButton>
            </div>
          </Stack>
        </div>
      )}
    />
  );
}
