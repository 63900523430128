import { IOptOut } from "../interfaces/IOptOut";

export const filterOptOutByChannel = (
  optOuts: IOptOut[],
  notificationChannelId: number
) => {
  return optOuts.filter(
    ({ notificationEventChannel }) =>
      notificationEventChannel.notificationChannelId === notificationChannelId
  );
};
