import { CONTRACT_STEPS, ContractStepsEnum } from "../contractSteps";

export const calcProgressBar = (currentStep: ContractStepsEnum): number => {
  // Total de etapas no contrato, excluindo a etapa final (CONFIRMACAO)
  const totalContractSteps = CONTRACT_STEPS.length - 1;

  // Progresso de cada etapa
  const progressPerStep = 100 / totalContractSteps;

  // Calcula o progresso atual baseado na etapa atual
  const currentProgress = currentStep * progressPerStep;

  // Retorna o progresso atual, arredondado para duas casas decimais
  return Math.round(currentProgress) / 100;
};
