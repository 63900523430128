import { MessageBarType } from "@fluentui/react";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

import { useAlert } from "./useAlert";

import { AlertProps, AlertTypes } from "@/common/types/AlertTypes";
import { useGetProfile } from "@/core/libs/api/react-query";
import { BusinessUnit } from "@/modules/User/types/types";
import { destructureAllBusinessUnitsChildreen } from "@/utils/DestructureAllBussinesUnitsChildreen";

export interface SelectedBuContextData {
  handleSelectBu: (id: number) => void;
  selectedBU: BusinessUnit;
}

const SelectedBuContext = createContext<SelectedBuContextData>(
  {} as SelectedBuContextData
);

export const SelectedBuProvider = ({ children }: { children: ReactNode }) => {
  const userProfile = useGetProfile();
  const [selectedBU, setSelectedBU] = useState<BusinessUnit>(null);

  const findBuByIdInProfile = useCallback(
    (id: number) => {
      if (userProfile?.data?.data) {
        const businessUnitsDestructured = destructureAllBusinessUnitsChildreen(
          userProfile?.data?.data?.businessUnits as Array<any>
        );
        return businessUnitsDestructured.find(bu => bu.id === id);
      }
    },
    [userProfile]
  );

  const isEmpty = (stringToBeParsed?: string): boolean => {
    return !stringToBeParsed || stringToBeParsed === "undefined";
  };

  const setLocalStorage = useCallback(bu => {
    const oldSelected = localStorage.getItem("selectedBU");
    if (isEmpty(oldSelected) || JSON.parse(oldSelected).id === bu?.id) return;

    localStorage.setItem("selectedBU", JSON.stringify(bu));
  }, []);

  const { createAlert } = useAlert();

  const alert: AlertProps = {
    level: MessageBarType.error,
    type: AlertTypes.NOTIFICATION,
    message: `Sem permissão de acesso, redirecionando para unidade de negócio padrão!`
  };

  const handleSelectBu = useCallback(
    (id: number) => {
      if (id) {
        const bu = findBuByIdInProfile(id);
        if (!bu) {
          return createAlert({
            ...alert,
            level: MessageBarType.error,
            code: 403
          });
        }
        setSelectedBU(bu);
        setLocalStorage(bu);
      }
    },
    [findBuByIdInProfile, setLocalStorage]
  );

  useEffect(() => {
    const oldSelected = localStorage.getItem("selectedBU");
    const fetchedBu = userProfile?.data?.data?.businessUnit;
    if (oldSelected && !isEmpty(oldSelected)) {
      setSelectedBU(JSON.parse(oldSelected));
      return;
    }

    if (!fetchedBu) return;

    setLocalStorage(fetchedBu);
    setSelectedBU(fetchedBu);
  }, [setLocalStorage, userProfile?.data?.data]);

  return (
    <SelectedBuContext.Provider value={{ handleSelectBu, selectedBU }}>
      {children}
    </SelectedBuContext.Provider>
  );
};

export const useSelectedBu = () => {
  const context = useContext(SelectedBuContext);
  return context;
};
