import { Pivot, PivotItem } from "@fluentui/react";

type HeaderItemCountProps = {
  handleLinkClick: (keys) => void;
  filterQuery: string;
  selectedKeys: { [key: string]: string };
  filtersConfig: {
    text: string;
    queryValue: string;
    count: string;
    iconName?: string;
  }[];
};

export function HeaderItemCount({
  handleLinkClick,
  filterQuery,
  filtersConfig,
  selectedKeys
}: HeaderItemCountProps): JSX.Element {
  return (
    <Pivot
      styles={{
        root: {
          display: "flex",
          flexWrap: "wrap"
        }
      }}
      aria-label="Header item count"
      linkFormat="tabs"
      selectedKey={selectedKeys[filterQuery] || null}
      onLinkClick={item => {
        const currentKeys = Object.assign({}, selectedKeys);

        if (currentKeys[filterQuery] === item.props.itemKey) {
          delete currentKeys[filterQuery];
        } else {
          currentKeys[filterQuery] = item.props.itemKey;
        }

        handleLinkClick(currentKeys);
      }}
    >
      {filtersConfig.map(filter => (
        <PivotItem
          key={filter.queryValue}
          itemKey={filter.queryValue}
          headerText={filter.text}
          itemCount={filter.count}
          itemIcon={filter.iconName}
        />
      ))}
    </Pivot>
  );
}
