import { ReactNode } from "react";

import { TeachingBubble } from "@/components/TeachingBubble";
import { CustomStyles } from "@/components/TeachingBubble/types";

interface ResponseNotificationsRootProps {
  children: ReactNode;
  targetButtonId: string;
  onClose: () => void;
}

const ResponseNotificationsRoot = ({
  children,
  onClose,
  targetButtonId
}: ResponseNotificationsRootProps) => {
  const customStyles: CustomStyles = {
    maxHeight: "35rem",
    width: "auto",
    right: "0.5rem",
    overflowY: "hidden"
  };

  const positionAdjustment = {
    top: 50
  };

  return (
    <TeachingBubble
      onClose={onClose}
      targetButtonId={targetButtonId}
      customStyles={customStyles}
      positionAdjustment={positionAdjustment}
    >
      {children}
    </TeachingBubble>
  );
};

export default ResponseNotificationsRoot;
