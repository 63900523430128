import produce, { setAutoFreeze } from "immer";

import {
  ContractActionsTypes,
  ContractReducerActions,
  ContractsState
} from "../types";

export const INITIAL_STATE: ContractsState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

setAutoFreeze(false);

export default function contractsReducer(
  state = INITIAL_STATE,
  action: ContractReducerActions
): ContractsState {
  return produce(state, draft => {
    switch (action.type) {
      case ContractActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ContractActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ContractActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ContractActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ContractActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ContractActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ContractActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ContractActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case ContractActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case ContractActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
