import { FC, ReactNode } from "react";

import { StyledBadgeRibbonContainer } from "./styles";

export interface BadgeRibbonProps {
  text: string;
  color: string;
  children: ReactNode;
}

export const StyledBadgeRibbon: FC<BadgeRibbonProps> = ({
  text,
  color,
  children
}) => (
  <StyledBadgeRibbonContainer color={color} data-text={text}>
    {children}
  </StyledBadgeRibbonContainer>
);
