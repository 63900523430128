import { MutableRefObject } from "react";

import { IAdditionalOfferPrice } from "@/components/Settings/AdditionalComponents/types";
import { ProductContract } from "@/modules/Contracts/types";

export interface InsertItemProps {
  contract: any;
  close: (ev, refresh?) => void;
  parcelLimit: number;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
}

export interface DefaultProps {
  message: any;
  setMessage: (text) => void;
  setFieldValue: any;
}

export interface SelectedProductContract extends ProductContract {
  inventory?: {
    id?: number;
    serialNumber: string;
    serialType: string;
  };
  productCashValue?: string;
  productFinancedValue?: string;
  serialNumber?: string;
}

export enum StepForm {
  INITIAL_STEP = "initialStep",
  PLAN_STEP = "planStep",
  LINE_STEP = "lineStep"
}

export interface InitialCarrierType {
  text: string;
  key: number | string;
}

export interface FormValuesType {
  operation: number;
  offerPrice: string;
  line: string;
  additionalInfo: {
    planIsConectivityOrSmart: boolean;
    installment: string;
  };
  carrier: number | string;
  initialCarrier?: number | string;
  offerOverride: any;
  additionalContractItems?: IAdditionalOfferPrice[];
}
