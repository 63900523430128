import { Shimmer, ShimmerElementType, mergeStyles } from "@fluentui/react";

import { Container, SideBar, Wrapper } from "@/components/styles/OptOut";

export const ShimmerSection = () => {
  const shimmerContainerStyle = mergeStyles({
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: 16
  });

  const shimmerLineContainerStyle = mergeStyles({
    display: "flex",
    flexDirection: "column",
    gap: 8
  });

  const shimmerLineWrapperStyle = mergeStyles({
    display: "flex",
    alignItems: "center",
    gap: 8
  });

  const shimmerTitleStyle = mergeStyles({
    width: 130,
    height: 24,
    borderRadius: 4
  });

  const shimmerSubtitleStyle = mergeStyles({
    width: 50,
    height: 16,
    borderRadius: 4
  });

  const ShimmerTitle = () => {
    return (
      <div className={shimmerContainerStyle}>
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, width: "100%" },
            { type: ShimmerElementType.line, width: "75%" }
          ]}
        />

        <div className={shimmerContainerStyle}>
          <div className={shimmerLineWrapperStyle}>
            <Shimmer className={shimmerTitleStyle} />
          </div>
          <div className={shimmerLineWrapperStyle}>
            <Shimmer className={shimmerSubtitleStyle} />
          </div>
        </div>

        <Shimmer
          shimmerElements={[{ type: ShimmerElementType.line, width: "100%" }]}
        />
      </div>
    );
  };

  const ShimmerData = () => {
    return (
      <div className={shimmerContainerStyle}>
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, width: "100%" },
            { type: ShimmerElementType.line, width: "75%" }
          ]}
        />

        <div className={shimmerLineContainerStyle}>
          <div className={shimmerLineWrapperStyle}>
            <Shimmer className={shimmerTitleStyle} />
            <Shimmer className={shimmerSubtitleStyle} />
            <Shimmer className={shimmerTitleStyle} />
          </div>
          <div className={shimmerLineWrapperStyle}>
            <Shimmer className={shimmerSubtitleStyle} />
          </div>
        </div>

        <Shimmer
          shimmerElements={[{ type: ShimmerElementType.line, width: "100%" }]}
        />
      </div>
    );
  };

  return (
    <Wrapper>
      <SideBar>
        <ShimmerTitle />
      </SideBar>

      <Container>
        <ShimmerData />
      </Container>
    </Wrapper>
  );
};
