export enum ContractActionsTypes {
  CREATE_REQUEST = "contracts/CREATE_REQUEST",
  CREATE_FAIL = "contracts/CREATE_FAIL",
  CREATE_SUCCESS = "contracts/CREATE_SUCCESS",
  DISMISS_MESSAGE = "contracts/DISMISS_MESSAGE",
  GET_REQUEST = "contracts/GET_REQUEST",
  GET_FAIL = "contracts/GET_FAIL",
  GET_SUCCESS = "contracts/GET_SUCCESS",
  EDIT_REQUEST = "contracts/EDIT_REQUEST",
  EDIT_FAIL = "contracts/EDIT_FAIL",
  EDIT_SUCCESS = "contracts/EDIT_SUCCESS",
  LIST_REQUEST = "contracts/LIST_REQUEST",
  LIST_FAIL = "contracts/LIST_FAIL",
  LIST_SUCCESS = "contracts/LIST_SUCCESS"
}
