import { Dropdown } from "@fluentui/react/lib/Dropdown";

import { Card } from "@/components/Shared/Card";
import { PaymentMethod } from "@/modules/Contracts/enums";
import { StyledStep } from "@/modules/Contracts/styles";

interface CheckingAccountCardProps {
  paymentMethod: any;
  selectedCheckingAccount: any;
  checkingAccountsOptions: any;
  setSelectedCheckingAccount: any;
  setFieldValue: any;
}

export function CheckingAccountCard({
  paymentMethod,
  checkingAccountsOptions,
  selectedCheckingAccount,
  setSelectedCheckingAccount,
  setFieldValue
}: CheckingAccountCardProps) {
  return (
    <Card>
      <StyledStep>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            {paymentMethod === PaymentMethod.DEBITO_AUTOMATICO ? (
              <>
                <div className="ms-Grid-col ms-sm12 ms-lg3">
                  <h2 className="ms-fontSize-20">Dados Bancários</h2>
                  <p>Para débito automático</p>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg4">
                  <Dropdown
                    id="account-select"
                    placeholder="Selecione uma conta"
                    options={checkingAccountsOptions}
                    selectedKey={selectedCheckingAccount}
                    onChange={(_event, item) => {
                      setFieldValue("checkingAccount", item.key);
                      setSelectedCheckingAccount(item.key);
                    }}
                    onRenderOption={option => (
                      <button
                        onClick={() => {
                          setFieldValue("checkingAccount", option.key);
                          setSelectedCheckingAccount(option.key);
                        }}
                        data-testid={`button-custom-${option.id}`}
                        style={{
                          backgroundColor: "transparent",
                          border: "none"
                        }}
                      >
                        {option.text}
                      </button>
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="ms-Grid-col ms-sm12 ms-lg3">
                  <h2 className="ms-fontSize-20">Pagamento</h2>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg4">
                  {paymentMethod === PaymentMethod.BOLETO ? (
                    <p>
                      O pagamento ocorrerá via boleto bancário a ser enviado
                      mensalmente para o e-mail do cooperado
                    </p>
                  ) : (
                    <p>O pagamento ocorrerá em conformidade com o parceiro</p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </StyledStep>
    </Card>
  );
}
