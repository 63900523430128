import { Dispatch, ReactNode } from "react";

import { IMessageBar } from "@/core/libs/message-bar";

export enum actionTypes {
  CHECK = "auth/CHECK",
  CHECK_SUCCESS = "auth/CHECK_SUCCESS",
  CHECK_FAIL = "auth/CHECK_FAIL",
  DISMISS_MESSAGE = "auth/DISMISS_MESSAGE",
  LOGIN = "auth/LOGIN",
  LOGIN_SUCCESS = "auth/LOGIN_SUCCESS",
  LOGIN_FAIL = "auth/LOGIN_FAIL",
  LOGOUT = "auth/LOGOUT"
}

interface Check {
  type: actionTypes.CHECK;
}

interface CheckFail {
  type: actionTypes.CHECK_FAIL;
}

interface CheckSuccess {
  type: actionTypes.CHECK_SUCCESS;
}

interface DismissError {
  type: actionTypes.DISMISS_MESSAGE;
}

interface Login {
  type: actionTypes.LOGIN;
}

interface LoginFail {
  type: actionTypes.LOGIN_FAIL;
  message?: IMessageBar;
}

interface LoginSuccess {
  type: actionTypes.LOGIN_SUCCESS;
}

interface Logout {
  type: actionTypes.LOGOUT;
}

export type AuthState = {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isChecking: boolean;
  message?: IMessageBar;
};

export type AuthAction =
  | Check
  | CheckSuccess
  | CheckFail
  | DismissError
  | Login
  | LoginSuccess
  | LoginFail
  | Logout;

export interface UseAuthInterface {
  (): {
    isAuthenticating: boolean;
    isAuthenticated: boolean;
    isChecking: boolean;
    login: (values, { setSubmitting }) => void;
    logout: () => void;
    dismissMessage: any;
    message: IMessageBar;
  };
}

export type AuthDispatch = Dispatch<AuthAction>;

export type CheckAuthenticationAction = ({
  dispatch
}: {
  dispatch: AuthDispatch;
}) => Promise<boolean>;

export type IHandleLogin = ({
  dispatch,
  data
}: {
  dispatch: AuthDispatch;
  data: any;
}) => Promise<
  | {
      response: {
        ok: boolean;
      };
      data: any;
      error?: undefined;
    }
  | {
      response: {
        ok: boolean;
      };
      error: {
        message: any;
        status: any;
        statusText: any;
      };
      data?: undefined;
    }
>;

export type ILogoutAction = ({
  dispatch
}: {
  dispatch: AuthDispatch;
}) => Promise<void>;

export type AuthProviderInterface = {
  children: ReactNode;
};

export type AuthContextState = {
  state: AuthState;
  dispatch: AuthDispatch;
};
