import { PaymentMethods } from "@/modules/Settings/types/OffersPrices.types";

export function getPaymentMethodOptions(
  listOfPaymentMethods: PaymentMethods[],
  showHiddenPaymentMethods: boolean
) {
  if (!listOfPaymentMethods?.length) {
    return [];
  }

  return listOfPaymentMethods
    .filter(paymentMethod => showHiddenPaymentMethods || !paymentMethod.hidden)
    .map(paymentMethod => ({
      key: paymentMethod.id.toString(),
      text: paymentMethod.name
    }));
}
