import { IMessageBar } from "@/core/libs/message-bar";

export enum ShippingsGroupsActionsTypes {
  CREATE_REQUEST = "shippingsGroups/CREATE_REQUEST",
  CREATE_FAIL = "shippingsGroups/CREATE_FAIL",
  CREATE_SUCCESS = "shippingsGroups/CREATE_SUCCESS",
  DISMISS_MESSAGE = "shippingsGroups/DISMISS_MESSAGE",
  EDIT_REQUEST = "shippingsGroups/EDIT_REQUEST",
  EDIT_FAIL = "shippingsGroups/EDIT_FAIL",
  EDIT_SUCCESS = "shippingsGroups/EDIT_SUCCESS",
  LIST_REQUEST = "shippingsGroups/LIST_REQUEST",
  LIST_FAIL = "shippingsGroups/LIST_FAIL",
  LIST_SUCCESS = "shippingsGroups/LIST_SUCCESS"
}

export interface ShippingsGroupsState {
  isLoadingList: boolean;
  list: [];
  message?: IMessageBar;
}

export interface CreateRequest {
  type: ShippingsGroupsActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: ShippingsGroupsActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: ShippingsGroupsActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: ShippingsGroupsActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: ShippingsGroupsActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: ShippingsGroupsActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: ShippingsGroupsActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: ShippingsGroupsActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: ShippingsGroupsActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: ShippingsGroupsActionsTypes.LIST_SUCCESS;
  payload: {
    list: [];
  };
}

export type ShippingsGroupsReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
