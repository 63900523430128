import { DatePicker } from "@fluentui/react/lib/DatePicker";
import styled from "styled-components";

export const FieldTextContainer = styled.div`
  & > div > label {
    display: flex !important;
    align-items: center;
  }
  *::before {
    content: "";
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  *::before {
    content: "";
  }
`;
