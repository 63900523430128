import {
  DefaultButton,
  Icon,
  TooltipHost,
  mergeStyleSets,
  mergeStyles
} from "@fluentui/react";

import { SelectedOption } from "./enums/SelectedOption.enum";
import { NotificationsHeader, NotificationsTabHeader } from "./styles";

interface ResponseNotificationsHeaderProps {
  title: string;
  handleOptionClick: (option: SelectedOption) => void;
  selectedOption: SelectedOption;
  handleReadAllNotifications: () => Promise<void>;
  isLoading: boolean;
}

const iconClass = mergeStyles({
  fontSize: "1rem",
  height: 20,
  width: 20,
  margin: "15px 0px",
  fontWeight: 600
});

const iconStyle = mergeStyleSets({
  blue: [{ color: "#395ca3" }, iconClass]
});

const ResponseNotificationsHeader = ({
  title,
  handleOptionClick,
  selectedOption,
  handleReadAllNotifications,
  isLoading
}: ResponseNotificationsHeaderProps) => {
  const isOptionSelected = (option: SelectedOption) =>
    selectedOption === option;

  return (
    <>
      <NotificationsHeader>
        <span>{title}</span>

        <TooltipHost content={"Marcar todas como lidas"}>
          <DefaultButton onClick={handleReadAllNotifications}>
            <Icon iconName="ReceiptCheck" className={iconStyle.blue} />
          </DefaultButton>
        </TooltipHost>
      </NotificationsHeader>

      <NotificationsTabHeader>
        <DefaultButton
          onClick={() => handleOptionClick(SelectedOption.ALL)}
          aria-selected={isOptionSelected(SelectedOption.ALL)}
          disabled={isLoading}
          styles={{
            rootDisabled: { background: "transparent", cursor: "not-allowed" }
          }}
        >
          Todas
        </DefaultButton>
        <DefaultButton
          onClick={() => handleOptionClick(SelectedOption.OPENED)}
          aria-selected={isOptionSelected(SelectedOption.OPENED)}
          disabled={isLoading}
          styles={{
            rootDisabled: { background: "transparent", cursor: "not-allowed" }
          }}
        >
          Em aberto
        </DefaultButton>
      </NotificationsTabHeader>
    </>
  );
};

export default ResponseNotificationsHeader;
