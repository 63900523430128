export const compareObjects = (obj1: object, obj2: object) => {
  if (Object.keys(obj1).length != Object.keys(obj2).length) {
    return false;
  }

  for (const prop in obj1) {
    if (obj1[prop] != obj2[prop]) {
      return false;
    }
  }

  return true;
};
