import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Separator,
  Spinner,
  Stack
} from "@fluentui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { OfferOverride } from "./ContractOverride/OfferOverride";
import { ProductOverride } from "./ContractOverride/ProductOverride";
import {
  getDeviceForPersonalizationList,
  searchProductBySerialNumber
} from "./InsertItem";
import { SelectedProductContract } from "./types";

import { Loader } from "@/components/Shared/Loader";
import {
  IMessageBar,
  MessageBar,
  messageBarTypes
} from "@/core/libs/message-bar";

interface PersonalizeOfferProps {
  selectedOffer: any;
  closeOffer: () => void;
  setPersonalizedOffer: (values: any) => void;
  selectedProduct: SelectedProductContract;
  setSelectedProduct: (product: SelectedProductContract) => void;
  planIsConectivityOrSmart: boolean;
  handleSelectedProductContract: (item: any) => void;
  selectedOfferPriceId: number;
  businessUnitId: number;
  setSaleConditionValue: (value: string) => void;
  setInventoryValue: (value: string) => void;
  setProductId: (id: string) => void;
  currentProducts: { key: string; value: string }[];
  operation: number;
  service: number;
  setIsModalOpen: (state: boolean) => void;
}

export type SearchProductProps = {
  id: number;
  name: string;
  inventory: {
    id: number;
    serialType: string;
    serialNumber: string;
  };
};

const schema = Yup.object().shape({
  price: Yup.string(),
  activationFee: Yup.string(),
  discount: Yup.string(),
  discountBeginsAt: Yup.string(),
  discountNumberOfMonths: Yup.string()
});

const schemaPlanSmart = schema.concat(
  Yup.object().shape({
    productItem: Yup.object().shape({
      id: Yup.string().required("Obrigatório selecionar um benefício combo.")
    }),
    productCashValue: Yup.string().required(
      "Obrigatório informar o valor à vista."
    ),
    productFinancedValue: Yup.string().required(
      "Obrigatório informar o valor à prazo."
    ),
    serialNumber: Yup.string()
      .min(15, "O IMEI deve ter no mínimo 15 dígitos.")
      .required("Obrigatório informar um IMEI.")
  })
);

function formatProductSelect(product) {
  if (product) {
    return product.map(product => ({
      key: product.id,
      text: `${product?.name} ${
        product?.specifications?.color
          ? `- ${product.specifications.color}`
          : ""
      } ${
        product?.specifications?.memory?.ram
          ? `- ${product.specifications.memory.ram} GB RAM`
          : ""
      } ${
        product?.specifications?.memory?.storage
          ? `- ${product.specifications.memory.storage}  GB`
          : ""
      } ${
        product?.specifications?.screen?.size
          ? `- Tela de ${product.specifications.screen.size}"`
          : ""
      }`,
      data: product
    }));
  }

  return null;
}

export function PersonalizeLineOffer({
  selectedOffer,
  selectedProduct,
  setSelectedProduct,
  planIsConectivityOrSmart,
  closeOffer,
  handleSelectedProductContract,
  selectedOfferPriceId,
  businessUnitId,
  setSaleConditionValue,
  setInventoryValue,
  setProductId,
  setPersonalizedOffer,
  currentProducts,
  operation,
  service,
  setIsModalOpen
}: PersonalizeOfferProps): JSX.Element {
  const [message, setMessage] = useState(undefined);
  const [serialNumberMessage, setSerialNumberMessage] =
    useState<IMessageBar>(null);
  const [initialValues, setInitialValues] = useState(undefined);
  const [inStockProducts, setInStockProducts] = useState(undefined);
  const [outOfStockProducts, setOutOfStockProducts] = useState(undefined);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isSearchingSerialNumber, setIsSearchingSerialNumber] = useState(false);

  const cleanMessage = () => {
    setMessage(undefined);
  };

  useEffect(() => {
    const defaultValues = {
      price: toLocaleStringWithoutCurrency(
        selectedOffer?.details?.offerPrice?.price
      ),
      discount: toLocaleStringWithoutCurrency(
        selectedOffer?.details?.discount?.value
      ),
      discountBeginsAt: selectedOffer?.details?.discount?.beginsAt,
      discountNumberOfMonths: selectedOffer?.details?.discount?.numberOfMonths,
      activationFee: toLocaleStringWithoutCurrency(
        selectedOffer?.details?.activationFee
      ),
      bonusData: selectedOffer?.details?.bonusData?.value,
      bonusDataBeginsAt: selectedOffer?.details?.bonusData?.beginsAt,
      bonusDataNumberOfMonths: selectedOffer?.details?.bonusData?.numberOfMonths
    };

    if (planIsConectivityOrSmart) {
      setInitialValues({
        ...defaultValues,
        productItem: selectedProduct,
        productCashValue:
          selectedProduct?.productCashValue ||
          selectedProduct?.saleConditions?.[0]?.cashValue ||
          "",
        productFinancedValue:
          selectedProduct?.productFinancedValue ||
          selectedProduct?.saleConditions?.[0]?.financedValue ||
          "",
        serialNumber:
          selectedProduct?.serialNumber ||
          selectedProduct?.inventory?.serialNumber ||
          ""
      });
    } else {
      setInitialValues(defaultValues);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedOfferPriceId && planIsConectivityOrSmart) {
      setIsLoadingProducts(true);

      const handleProductList = async () => {
        const response = await getDeviceForPersonalizationList(businessUnitId);

        if (response?.data) {
          let allProductsInStock = formatProductSelect(
            response.data?.available
          );

          if (currentProducts?.length > 0) {
            allProductsInStock = allProductsInStock.filter(
              product => !currentProducts.some(item => item.key === product.key)
            );

            allProductsInStock = [...currentProducts, ...allProductsInStock];
          }

          const formatOutStock = formatProductSelect(response.data?.reserve);

          setInStockProducts(allProductsInStock);
          setOutOfStockProducts(formatOutStock);
          setIsLoadingProducts(false);
        } else {
          setInStockProducts(undefined);
          setOutOfStockProducts(undefined);
          setIsLoadingProducts(false);
        }
      };

      handleProductList();
    }
    //(this rule does not apply in this context)
  }, [selectedOfferPriceId, businessUnitId]);

  const compareObjects = (obj1, obj2) => {
    for (const prop in obj1) {
      if (obj1[prop] !== obj2[prop]) {
        return false;
      }
    }

    return true;
  };

  const submitPersonalizedOffer = values => {
    const convertedValues = Object.assign({}, values);
    delete convertedValues.isChecked;

    if (convertedValues && compareObjects(convertedValues, initialValues)) {
      setTimeout(() => {
        setIsModalOpen(false);
      }, 500);
    } else if (values) {
      const discountNumberOfMonths =
        values.discountNumberOfMonths - values.discountBeginsAt + 1;

      const bonusDataNumberOfMonths =
        values.bonusDataNumberOfMonths - values.bonusDataBeginsAt + 1;

      convertedValues.discountNumberOfMonths = discountNumberOfMonths;
      convertedValues.bonusDataNumberOfMonths = bonusDataNumberOfMonths;

      const convertedPrice = convertNumber(values.price);
      const convertedDiscountValue = convertNumber(values.discount);
      const convertedActivationFee = convertNumber(values.activationFee);

      if (
        isNaN(convertedPrice) ||
        isNaN(convertedDiscountValue) ||
        isNaN(convertedActivationFee)
      ) {
        setMessage({
          message: "Valor inválido, por favor tente novamente",
          type: messageBarTypes.WARNING
        });

        return;
      } else {
        convertedValues.price = convertedPrice;
        convertedValues.discount = convertedDiscountValue;
        convertedValues.activationFee = convertedActivationFee;
      }

      if (planIsConectivityOrSmart) {
        const newProductValues = Object.assign({}, values?.productItem);
        values.inventory && setInventoryValue(values.inventory);
        setProductId(values?.productItem?.id);
        delete convertedValues.productItem;
        delete convertedValues.inventory;

        if (values?.productItem?.saleConditions?.[0]?.id) {
          setSaleConditionValue(values?.productItem?.saleConditions?.[0]?.id);
          delete convertedValues.serialNumber;
        } else {
          setSaleConditionValue(undefined);
        }

        if (values.productCashValue) {
          const convertedProductCashValue = String(
            convertNumber(values.productCashValue)
          );

          newProductValues.productCashValue = convertedProductCashValue;
          convertedValues.productCashValue = convertedProductCashValue;
        }

        if (values.productFinancedValue) {
          const convertedProductFinancedValue = String(
            convertNumber(values.productFinancedValue)
          );

          newProductValues.productFinancedValue = convertedProductFinancedValue;
          convertedValues.productFinancedValue = convertedProductFinancedValue;
        }

        if (
          values.serialNumber &&
          values.serialNumber !== values.productItem.inventory?.serialNumber
        ) {
          newProductValues.serialNumber = String(values.serialNumber);
          convertedValues.serialNumber = String(values.serialNumber);
        }

        if (
          values.productCashValue ||
          values.productFinancedValue ||
          values.serialNumber
        ) {
          setSelectedProduct(newProductValues);
        } else {
          handleSelectedProductContract(values.productItem);
        }
      }

      if (values.fees) {
        const fees = values.fees.map(fee => {
          const convertedFeeValue = String(
            toLocaleStringWithoutCurrency(fee.value)
          );

          return {
            ...fee,
            value: convertedFeeValue
          };
        });

        convertedValues.fees = fees;
      }

      setPersonalizedOffer(convertedValues);
    }
  };

  async function handleChangeSerialNumber(serialNumber, operation, service) {
    setIsSearchingSerialNumber(true);

    const response = await searchProductBySerialNumber(
      businessUnitId,
      serialNumber,
      operation,
      service
    );

    setIsSearchingSerialNumber(false);
    if (response.data) {
      setSerialNumberMessage({
        message: `Número de série para ${response.data.name} encontrado.`,
        type: messageBarTypes.SUCCESS
      });

      return response.data as SearchProductProps;
    }

    setSerialNumberMessage({
      message: response.error.message,
      type: messageBarTypes.ERROR
    });
    return null;
  }

  function cleanSerialNumberMessage() {
    setSerialNumberMessage(null);
  }

  return (
    <Stack tokens={{ padding: 20 }} style={{ position: "relative" }}>
      {!initialValues || (planIsConectivityOrSmart && isLoadingProducts) ? (
        <div
          style={{
            display: "flex",
            height: "200px",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spinner label="Carregando" />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={planIsConectivityOrSmart ? schemaPlanSmart : schema}
          onSubmit={submitPersonalizedOffer}
        >
          {({ errors, touched, setFieldValue, values }: any) => {
            return (
              <Form>
                <Stack tokens={{ childrenGap: 15 }}>
                  {message && (
                    <MessageBar
                      message={message}
                      dismissMessage={cleanMessage}
                    />
                  )}

                  <Stack horizontal>
                    {planIsConectivityOrSmart && (
                      <>
                        <ProductOverride
                          values={values}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          inStockProducts={inStockProducts}
                          outOfStockProducts={outOfStockProducts}
                          serialNumberMessage={serialNumberMessage}
                          handleChangeSerialNumber={handleChangeSerialNumber}
                          cleanSerialNumberMessage={cleanSerialNumberMessage}
                          operation={operation}
                          service={service}
                        />

                        <Stack.Item styles={{ root: { height: 400 } }}>
                          <Separator vertical />
                        </Stack.Item>
                      </>
                    )}
                    <OfferOverride
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      serviceId={service}
                    />
                  </Stack>

                  <DialogFooter>
                    <PrimaryButton
                      text="Salvar Personalização"
                      type="submit"
                      disabled={!values?.isChecked}
                    />
                    <DefaultButton onClick={closeOffer} text="Cancelar" />
                  </DialogFooter>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}

      {isSearchingSerialNumber && <Loader customMessage="Buscando IMEI..." />}
    </Stack>
  );
}

function toLocaleStringWithoutCurrency(value) {
  if (!value) return 0;
  return convertNumber(value).toLocaleString("pt-BR", {
    minimumFractionDigits: 2
  });
}

function convertNumber(value) {
  return isNaN(value)
    ? Number(value?.replace(/\./g, "")?.replace(/,/g, "."))
    : Number(value);
}
