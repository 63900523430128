import { ListOfCarriers } from "./Carriers.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum CarriersActionsTypes {
  CREATE_REQUEST = "carriers/CREATE_REQUEST",
  CREATE_FAIL = "carriers/CREATE_FAIL",
  CREATE_SUCCESS = "carriers/CREATE_SUCCESS",
  DISMISS_MESSAGE = "carriers/DISMISS_MESSAGE",
  EDIT_REQUEST = "carriers/EDIT_REQUEST",
  EDIT_FAIL = "carriers/EDIT_FAIL",
  EDIT_SUCCESS = "carriers/EDIT_SUCCESS",
  LIST_REQUEST = "carriers/LIST_REQUEST",
  LIST_FAIL = "carriers/LIST_FAIL",
  LIST_SUCCESS = "carriers/LIST_SUCCESS"
}

export interface CarriersState {
  isLoadingList: boolean;
  list: ListOfCarriers;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: CarriersActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: CarriersActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: CarriersActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: CarriersActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: CarriersActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: CarriersActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: CarriersActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: CarriersActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: CarriersActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: CarriersActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfCarriers;
  };
}

export type CarriersReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
