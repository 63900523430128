import { IDropdownOption } from "@fluentui/react";

import { OperationsService } from "@/core/libs/api";

type OperationsRequest = {
  selectedBUId: number;
  setContractOperationsOptions: (options: IDropdownOption[]) => void;
};

const operationService = OperationsService();

export async function requestOperations({
  selectedBUId,
  setContractOperationsOptions
}: OperationsRequest) {
  const { signal } = new AbortController();
  const params = { query: { businessActionId: selectedBUId } };
  const response = await operationService.list({ params, signal });

  if (response?.data?.items.length > 0) {
    const formatToSelect = response.data.items.map(operation => ({
      key: operation.id,
      text: operation.name
    }));

    setContractOperationsOptions(formatToSelect);
  }
}
