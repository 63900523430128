import { ListOfServices } from "./Services.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum ServicesActionsTypes {
  CREATE_REQUEST = "services/CREATE_REQUEST",
  CREATE_FAIL = "services/CREATE_FAIL",
  CREATE_SUCCESS = "services/CREATE_SUCCESS",
  DISMISS_MESSAGE = "services/DISMISS_MESSAGE",
  EDIT_REQUEST = "services/EDIT_REQUEST",
  EDIT_FAIL = "services/EDIT_FAIL",
  EDIT_SUCCESS = "services/EDIT_SUCCESS",
  LIST_REQUEST = "services/LIST_REQUEST",
  LIST_FAIL = "services/LIST_FAIL",
  LIST_SUCCESS = "services/LIST_SUCCESS"
}

export interface ServicesState {
  isLoadingList: boolean;
  list?: ListOfServices;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: ServicesActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: ServicesActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: ServicesActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: ServicesActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: ServicesActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: ServicesActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: ServicesActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: ServicesActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: ServicesActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: ServicesActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfServices;
  };
}

export type ServicesReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
