import { IAdditionalOfferPrice } from "@/components/Settings/AdditionalComponents/types";
import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";
export const AdditionalListContent = ({
  additionals
}: {
  additionals: IAdditionalOfferPrice[];
}) => {
  const additionalBonus = additionals.filter(additional => additional?.bonus);
  const additionalService = additionals.filter(
    additional => !additional?.bonus && !additional?.additionalOffer?.product
  );
  const additionalProduct = additionals.filter(
    additional => additional?.additionalOffer?.product
  );

  return (
    <ul>
      {additionalBonus.length > 0 && (
        <>
          {additionalBonus.map(additional => (
            <li key={additional.id}>
              Bônus {additional?.additionalOffer?.name} em {additional?.bonus}{" "}
              {transformToCurrencyString(additional.activationValue)} + por{" "}
              {transformToCurrencyString(additional.monthlyFee)}/mês
            </li>
          ))}
        </>
      )}
      {additionalService.length > 0 && (
        <>
          {additionalService.map(additional => (
            <li key={additional.id}>
              Benefício prestado {additional.additionalOffer.name} por{" "}
              {transformToCurrencyString(additional.activationValue)} +{" "}
              {transformToCurrencyString(additional.monthlyFee)}/mês
            </li>
          ))}
        </>
      )}
      {additionalProduct.length > 0 && (
        <>
          {additionalProduct.map(additional => (
            <li key={additional.id}>
              {additional?.additionalOffer?.product.name} por{" "}
              {transformToCurrencyString(additional.activationValue)} +{" "}
              {transformToCurrencyString(additional.monthlyFee)}/mês
            </li>
          ))}
        </>
      )}
    </ul>
  );
};
