import produce from "immer";

import {
  ModelsAttachmentsActionsTypes,
  ModelsAttachmentsReducerActions,
  ModelsAttachmentsState
} from "../types/ModelsAttachments.reducer.types";

const INITIAL_STATE: ModelsAttachmentsState = {
  list: [],
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ModelsAttachmentsReducerActions
): ModelsAttachmentsState {
  return produce(state, draft => {
    switch (action.type) {
      case ModelsAttachmentsActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ModelsAttachmentsActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsAttachmentsActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsAttachmentsActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ModelsAttachmentsActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ModelsAttachmentsActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsAttachmentsActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsAttachmentsActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = [];
        break;
      }

      case ModelsAttachmentsActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = [];
        break;
      }

      case ModelsAttachmentsActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
