import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createFailure,
  createSuccess,
  editFailure,
  editSuccess,
  listFailure,
  listRequest,
  listSuccess
} from "../actions/services";
import { ServicesActionsTypes } from "../types/Services.reducer.types";

import { SagaCustomError } from "@/common/types";
import { ServicesService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createService({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin = false } = payload;
  const service = ServicesService(undefined, isAdmin);

  const newValues = Object.assign({}, values);

  if (newValues.plans?.length > 0) {
    newValues.plans = newValues.plans.map(plan => ({ id: plan }));
  }

  if (newValues.operations?.length > 0) {
    newValues.operations = newValues.operations.map(operation => ({
      id: operation
    }));
  }

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createSuccess({
        message: `Benefício criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, isAdmin));
  } catch (error) {
    yield put(
      createFailure({
        code: error?.statusCode,
        message: "Não foi possível criar o benefício com os dados informados.",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editService({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin = false } = payload;
  const service = ServicesService(undefined, isAdmin);

  const newValues = Object.assign({}, values);

  if (newValues.plans?.length > 0) {
    newValues.plans = newValues.plans.map(plan => ({ id: plan }));
  }

  if (newValues.operations?.length > 0) {
    newValues.operations = newValues.operations.map(operation => ({
      id: operation
    }));
  }

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editSuccess({
        message: `Benefício editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, isAdmin));
  } catch (error) {
    yield put(
      editFailure({
        code: error?.statusCode,
        message: "Não foi possível editar o benefício com os dados informados.",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listServices({ payload }: AnyAction) {
  const { filter, isAdmin = false } = payload;
  const service = ServicesService("", isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listSuccess({ list: response.data }));
  } catch (error) {
    yield put(listFailure());
  }
}

export default all([
  takeLatest(ServicesActionsTypes.CREATE_REQUEST, createService),
  takeLatest(ServicesActionsTypes.EDIT_REQUEST, editService),
  takeLatest(ServicesActionsTypes.LIST_REQUEST, listServices)
]);
