import { getTheme } from "@fluentui/react";

const theme = getTheme();

export const ContractBadgeColors = {
  DONE: theme.semanticColors.successIcon,
  CANCELED: theme.semanticColors.severeWarningIcon,
  TIMED_OUT: "#CA5010",
  REJECTED: theme.semanticColors.errorIcon,
  DEFAULT: theme.palette.themeDark,
  DISABLED: "#AAA"
};

export enum ContractFlags {
  MODIFIED_OFFER = "Contrato com pacotes de benefícios personalizadas",
  MODIFIED_TOTAL = "Contrato com pacotes de benefícios e valores personalizados",
  MODIFIED = "Contrato com valores personalizados",
  REGULAR = "REGULAR"
}

export enum IconNames {
  APPROVE_REPLACEMENT = "Completed",
  AUDIT = "SearchAndApps",
  CLIENT_SIGNED = "InsertSignatureLine",
  COMMERCIALLY_APPROVED = "Teamwork",
  CREDIT_APPROVED = "ProfileSearch",
  SETUP_PAID = "Money",
  DELIVERED = "UserFollowed",
  DOCUMENTS_APPROVED = "DocumentApproval",
  DOCUMENTS_SENT = "DocumentSearch",
  DONE = "CompletedSolid",
  OPTIONAL_SIGNATURES_COLLECTED = "Group",
  PROCESSED = "DocumentSet",
  PRODUCT_DELIVERED = "ProductVariant",
  SERVICE_DELIVERED = "WaitlistConfirm",
  REGISTER_CORRECTION = "AddToShoppingList",
  MEMBER_SIGNED = "AddFriend"
}

export enum PaymentMethod {
  BOLETO = "BOLETO",
  DEBITO_AUTOMATICO = "DEBITO_AUTOMATICO",
  OUTROS = "OUTROS"
}

export enum DeliveryType {
  STANDARD = "STANDARD",
  EXPRESS = "EXPRESS"
}

export enum PersonalizeTotalFeeFields {
  sim = "Chip",
  shipping = "Frete",
  contractActivationFee = "Valor a ser cobrado de ativação"
}

export enum SignatureType {
  STANDARD = "STANDARD",
  ELETRONIC = "ELETRONIC",
  ELETRONIC_WHATSAPP = "ELETRONIC_WHATSAPP"
}

export enum SignatureAuth {
  EMAIL = "EMAIL",
  WHATSAPP = "WHATSAPP",
  STANDARD = "STANDARD"
}

export enum ContractStatus {
  APPROVE_REPLACEMENT = "APPROVE_REPLACEMENT",
  AUDIT = "AUDIT",
  CANCELED = "CANCELED",
  CLIENT_SIGNED = "CLIENT_SIGNED",
  COMMERCIALLY_APPROVED = "COMERCIALLY_APPROVED",
  CREDIT_APPROVED = "CREDIT_APPROVED",
  DELIVERED = "DELIVERED",
  DOCUMENTS_APPROVED = "DOCUMENTS_APPROVED",
  DOCUMENTS_SENT = "DOCUMENTS_SENT",
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  OPTIONAL_SIGNATURES_COLLECTED = "OPTIONAL_SIGNATURES_COLLECTED",
  PROCESSED = "PROCESSED",
  PRODUCT_DELIVERED = "PRODUCT_DELIVERED",
  REJECTED = "REJECTED",
  SERVICE_DELIVERED = "SERVICE_DELIVERED",
  TIMED_OUT = "TIMED_OUT",
  SETUP_PAID = "SETUP_PAID"
}

export enum TranslatedContractNextStep {
  APPROVE_REPLACEMENT = "Aguardando aprovação de substituição",
  AUDIT = "Aguardando Auditoria",
  CANCELED = "Aguardando cancelamento",
  CLIENT_SIGNED = "Aguardando assinatura eletrônica do cooperado",
  COMMERCIALLY_APPROVED = "Aguardando deliberação comercial",
  CREDIT_APPROVED = "Aguardando aprovação de crédito",
  DELIVERED = "Aguardando liberação",
  DOCUMENTS_APPROVED = "Verificando documentos",
  DOCUMENTS_SENT = "Aguardando documentos",
  DONE = "Aguardando finalização",
  IN_PROGRESS = "Em andamento",
  OPTIONAL_SIGNATURES_COLLECTED = "Aguardando demais assinaturas eletrônicas",
  PROCESSED = "Aguardando processamento",
  PRODUCT_DELIVERED = "Aguardando liberação de benefícios combos",
  REJECTED = "Aguardando rejeição",
  SERVICE_DELIVERED = "Aguardando liberação de benefícios",
  TIMED_OUT = "Cancelado por inatividade",
  SETUP_PAID = "Aguardando pagamento da adesão"
}

export enum TranslatedContractCurrentStep {
  APPROVE_REPLACEMENT = "Substituição aprovada",
  AUDIT = "Auditado",
  CANCELED = "Cancelado",
  CLIENT_SIGNED = "Assinado pelo cooperado",
  COMMERCIALLY_APPROVED = "Liberado pelo comercial",
  CREDIT_APPROVED = "Crédito aprovado",
  DELIVERED = "Liberado",
  DOCUMENTS_APPROVED = "Documento(s) aprovado(s)",
  DOCUMENTS_SENT = "Documento(s) enviado(s)",
  DONE = "Concluído",
  MEMBER_SIGNED = "Aguardando Assinatura",
  IN_PROGRESS = "Em andamento",
  OPTIONAL_SIGNATURES_COLLECTED = "Assinaturas aprovadas",
  PROCESSED = "Processado",
  PRODUCT_DELIVERED = "Benefícios combos liberados",
  REJECTED = "Rejeitado",
  REGISTER_CORRECTION = "Registro Corrigido",
  SERVICE_DELIVERED = "Benefícios liberados",
  TIMED_OUT = "Cancelado por inatividade",
  SETUP_PAID = "Adesão paga"
}

export enum TranslatedMaritalStatus {
  SINGLE = "Solteiro(a)",
  MARRIED = "Casado(a)",
  DIVORCED = "Divorciado(a)",
  WIDOWED = "Viúvo(a)",
  RATHER_NOT_SAY = "Prefere não informar",
  OTHER = "Outro(a)"
}

export enum TranslatedDeliveryTypes {
  STANDARD = "Padrão",
  EXPRESS = "Expressa"
}

export enum TranslatedSignatureType {
  STANDARD = "Manual",
  ELETRONIC = "Digital via Email",
  ELETRONIC_WHATSAPP = "Digital via Whatsapp "
}
