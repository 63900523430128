import { Layout } from "@/components/Auth/Layout/index";
import AuthRoutes from "@/core/routes/AuthRoutes";

function AppAuth(): JSX.Element {
  return (
    <Layout>
      <AuthRoutes />
    </Layout>
  );
}

export default AppAuth;
