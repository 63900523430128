import {
  CompoundButton,
  Dropdown,
  getTheme,
  MaskedTextField,
  Text
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AdditionalOfferPriceToAppend } from "../../AdditionalComponents/AdditionalOfferPriceToAppend";

import { PlanContractContainer } from "./styles";

import { IccidInputContent } from "@/components/IccidInputContent/IccidInputContent";
import { SubmitButton } from "@/components/Offers/ProductOffers/ProductOfferContract/ProductContract.styles";
import { ProductContractList } from "@/components/Offers/ProductOffers/ProductOfferContract/ProductContractList";
import { FiltersOptions } from "@/components/Offers/ProductOffers/ProductOfferFilters/ProductFilter.styles";
import { Loader } from "@/components/Shared/Loader";
import { ContractsService } from "@/core/libs/api";
import {
  IMessageBar,
  MessageBar,
  messageBarTypes
} from "@/core/libs/message-bar";
import { Plan, PlanOfferItem } from "@/modules/Offers/types/PlansOffers.types";
import {
  OperationTypes,
  ProductContractOperation,
  ProductContractType
} from "@/modules/Offers/types/ProductsOffers.types";
import { LineStrategyType } from "@/modules/Settings/pages/Operations/enum";
import { BusinessUnit } from "@/modules/User/types";

type PlanContractQuestionsProps = {
  setContractType: (type: string) => void;
  setOperationType: (operation: OperationTypes) => void;
  setSelectedPhoneNumber: (phoneNumber: string) => void;
  setSelectedPhoneId: (id: number) => void;
  setSelectedPhoneAreaCode: (number: string) => void;
  setSelectedContract: (contract) => void;
  setSelectedSignatureType: (type: string) => void;
  linesError: string;
  clearSubmitMessage: () => void;
  contractType: string;
  selectedSignatureType: string;
  operation: OperationTypes;
  selectedPhoneNumber: string;
  selectedPhoneId: number;
  selectedPhoneAreaCode: string;
  isLoadingOptions: boolean;
  isSubmitButtonDisabled: boolean;
  contractOperations: { key: string; text: string }[];
  contractLines: { key: string; text: string }[];
  availableAreaCodes: { key: string; text: string }[];
  selectedBU: BusinessUnit;
  selectedPlan: Plan;
  selectedCarrier: PlanOfferItem;
  selectedContract: any;
  carriers: { key: string; text: string }[];
  initialCarrier?: { key: string; text: string };
  setInitialCarrier: (initialCarrier) => void;
};

const contractTypeOptions = [
  {
    key: ProductContractType.NEW,
    text: ProductContractType.NEW
  },
  {
    key: ProductContractType.EXISTENT,
    text: ProductContractType.EXISTENT
  }
];
export function PlanContractQuestions({
  clearSubmitMessage,
  setContractType,
  setOperationType,
  setSelectedPhoneNumber,
  setSelectedPhoneId,
  setSelectedPhoneAreaCode,
  setSelectedContract,
  linesError,
  contractOperations,
  contractLines,
  availableAreaCodes,
  contractType,
  selectedSignatureType,
  operation,
  selectedPhoneNumber,
  selectedPhoneId,
  selectedPhoneAreaCode,
  isLoadingOptions,
  isSubmitButtonDisabled,
  selectedBU,
  selectedPlan,
  selectedCarrier,
  selectedContract,
  carriers,
  initialCarrier,
  setInitialCarrier
}: PlanContractQuestionsProps): JSX.Element {
  useEffect(() => {
    const isSelectedPhoneAreaCodeValid = availableAreaCodes?.some(
      areaCode => areaCode.key === selectedPhoneAreaCode
    );

    if (!isSelectedPhoneAreaCodeValid && !!availableAreaCodes) {
      setSelectedPhoneAreaCode(availableAreaCodes?.[0].key);
    }
  }, [availableAreaCodes, selectedPhoneAreaCode, setSelectedPhoneAreaCode]);

  const theme = getTheme();

  const typedLineStrategies = [
    LineStrategyType.MANUALLY_TYPED_LINE_AND_SEARCH_REMOVED,
    LineStrategyType.MANUALLY_TYPED_LINE_AND_SEARCH_SOLD,
    LineStrategyType.MANUALLY_TYPED_LINE
  ];
  const canInsertLineNumber =
    operation && typedLineStrategies.includes(operation.lineStrategy);

  const [isValidIccid, setIsValidIccid] = useState<boolean>(false);
  const [isNumberChanged, setIsNumberChanged] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitMessage, setSubmitMessage] = useState<IMessageBar>(null);
  const [inventoryId, setInventoryId] = useState<number>(undefined);
  const [iccid, setIccid] = useState<string>();
  const [isModalOpen, setIsModalOPen] = useState(false);
  const abortController = new AbortController();
  const { signal } = abortController;

  const contractService = ContractsService();
  const history = useHistory();

  const createContractItem = (values, contractId, { businessActionId }) => {
    const params = {
      id: contractId,
      body: values,
      query: { businessActionId }
    };

    return contractService.saveItem({ params, signal });
  };

  const createItemInNewContract = (values, { businessActionId }) => {
    const params = {
      body: values,
      query: { businessActionId }
    };

    return contractService.saveItemInNewContract({ params, signal });
  };

  const openAdditionalModal = () => {
    if (selectedPlan.offer.offerPrice.additionalOfferPrice.length > 0) {
      setIsModalOPen(true);
    } else {
      onSubmit();
    }
  };

  const onSubmit = (additionalContractItems = []) => {
    setIsSubmitting(true);

    if (contractType === ProductContractType.NEW) {
      const values = {
        businessActionId: selectedBU?.id,
        line: selectedPhoneId,
        lineNumber: selectedPhoneNumber,
        offerPrice: selectedPlan.offer.offerPrice.id,
        operation: operation.id,
        signatureType: selectedSignatureType,
        carrier: undefined,
        plan: undefined,
        iccidInventoryId: inventoryId,
        initialCarrier: undefined,
        additionalContractItems
      };

      if (String(operation.id) === ProductContractOperation.PORTABILIDADE) {
        values.carrier = selectedCarrier.id;
        values.plan = selectedPlan.id;
        values.initialCarrier = initialCarrier.key;
      }

      const handleNewContract = async () => {
        const response = await createItemInNewContract(values, {
          businessActionId: selectedBU?.id
        });

        if (response.response.ok) {
          history.push(`contracts/${response?.data?.contract}/edit`, {
            message: response?.data?.message
          });
        } else {
          setIsSubmitting(false);

          setSubmitMessage({
            code: response.error?.status,
            message: response.error?.message,
            type: messageBarTypes.ERROR
          });
        }
      };

      handleNewContract();
    } else if (contractType === ProductContractType.EXISTENT) {
      const values = {
        line: selectedPhoneId,
        lineNumber: selectedPhoneNumber,
        offerPrice: selectedPlan.offer.offerPrice.id,
        operation: operation.id,
        carrier: undefined,
        plan: undefined,
        iccidInventoryId: inventoryId,
        additionalContractItems
      };

      if (String(operation.id) === ProductContractOperation.PORTABILIDADE) {
        values.carrier = selectedCarrier.id;
        values.plan = selectedPlan.id;
      }

      const handleNewItem = async () => {
        const response = await createContractItem(
          values,
          selectedContract?.id,
          { businessActionId: selectedContract?.businessUnit?.id }
        );

        if (response.response.ok) {
          history.push(
            `contracts/${selectedContract.id}/edit`,
            !selectedContract.account
              ? { message: response?.data?.message }
              : null
          );
        } else {
          setIsSubmitting(false);

          setSubmitMessage({
            code: response.error?.status,
            message: response.error?.message,
            type: messageBarTypes.ERROR
          });
        }
      };

      handleNewItem();
    }
  };
  return (
    <PlanContractContainer>
      <div style={{ width: "100%" }}>
        <div
          className="ms-depth-8"
          style={{
            background: theme.palette.white,
            padding: 30,
            marginTop: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Text variant={"xLarge"}>
            Deseja criar um novo contrato ou selecionar um já existente?
          </Text>

          <FiltersOptions>
            {contractTypeOptions.map(type => (
              <CompoundButton
                id={`contractTypeOptions-button-${type.key}`}
                key={String(type.key)}
                checked={contractType === type.key}
                style={{
                  width: "100px",
                  border: "0",
                  borderRadius: "10px"
                }}
                onClick={() => setContractType(String(type.key))}
              >
                <Text variant={"mediumPlus"} style={{ fontWeight: 600 }}>
                  {type.text}
                </Text>
              </CompoundButton>
            ))}
          </FiltersOptions>
        </div>

        {contractType === ProductContractType.EXISTENT && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Selecione um contrato</Text>

            <div
              style={{
                overflowY: "auto",
                maxHeight: 250,
                width: "100%"
              }}
            >
              <ProductContractList setSelectedContract={setSelectedContract} />
            </div>
          </div>
        )}

        {contractType !== undefined && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Selecione o tipo de operação</Text>

            <Dropdown
              id={`operationType-select`}
              required
              selectedKey={Number(operation?.id)}
              onChange={(_, option) => {
                setOperationType(option.data);
                setSelectedPhoneNumber(undefined);
                setSelectedPhoneId(undefined);
              }}
              onRenderOption={option => (
                <button
                  onClick={() => {
                    setOperationType(option.data);
                    setSelectedPhoneNumber(undefined);
                    setSelectedPhoneId(undefined);
                  }}
                  data-testid={`button-custom-${option.id}`}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    textAlign: "left"
                  }}
                >
                  {option.text}
                </button>
              )}
              placeholder="Selecione uma opção"
              options={contractOperations}
              styles={{
                root: { marginTop: 20 },
                dropdown: { width: 200 }
              }}
            />
          </div>
        )}

        {canInsertLineNumber && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            {operation.id === 2 && (
              <>
                <Text variant={"xLarge"}>Informe a operadora de origem</Text>
                <Dropdown
                  id="carriers-select"
                  required
                  onChange={(_, option) => {
                    setInitialCarrier(option);
                  }}
                  placeholder="Selecione uma opção"
                  options={carriers}
                  onRenderOption={option => (
                    <button
                      onClick={() => {
                        setInitialCarrier(option);
                      }}
                      data-testid={`button-custom-${option.id}`}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        textAlign: "left"
                      }}
                    >
                      {option.text}
                    </button>
                  )}
                  styles={{
                    root: { marginTop: 20, marginBottom: 10 },
                    dropdown: { width: 200 }
                  }}
                />
              </>
            )}

            <Text variant={"xLarge"}>Digite o número do telefone</Text>
            <MaskedTextField
              id="phoneNumber-input"
              mask="(99) 99999-9999"
              required
              styles={{
                root: { marginTop: 20 }
              }}
              onChange={(_, newValue) => {
                setSelectedPhoneNumber(newValue.replace(/\D/g, ""));

                if (operation.id === 2) {
                  setIsNumberChanged(true);
                }
              }}
              errorMessage={
                selectedPhoneNumber?.length < 11
                  ? "O número deve ter 11 dígitos"
                  : undefined
              }
            />
          </div>
        )}

        {canInsertLineNumber === false && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Selecione um número</Text>

            <div style={{ display: "flex", alignItems: "baseline" }}>
              <Dropdown
                id="ddd-select"
                placeholder="DDD"
                label="DDD"
                required
                styles={{
                  root: {
                    width: 80,
                    marginRight: 10
                  }
                }}
                options={availableAreaCodes}
                defaultSelectedKey={selectedPhoneAreaCode}
                onChange={(_, option) => {
                  setSelectedPhoneAreaCode(String(option.key));
                  setSelectedPhoneNumber(undefined);
                  setSelectedPhoneId(undefined);
                }}
                onRenderOption={option => (
                  <button
                    onClick={() => {
                      setSelectedPhoneAreaCode(String(option.key));
                      setSelectedPhoneNumber(undefined);
                      setSelectedPhoneId(undefined);
                    }}
                    data-testid={`button-custom-${option.id}`}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      textAlign: "left"
                    }}
                  >
                    {option.text}
                  </button>
                )}
                disabled={!availableAreaCodes}
              />

              <Dropdown
                id="phoneNumber-select"
                required
                label="Número"
                onChange={(_, option) => {
                  setSelectedPhoneId(Number(option?.key));
                  setIsNumberChanged(true);
                }}
                selectedKey={Number(selectedPhoneId) || ""}
                placeholder={
                  selectedPhoneAreaCode?.length !== 2
                    ? "Selecione um DDD"
                    : contractLines === undefined
                    ? "Nenhum número encontrado"
                    : "Selecione um número"
                }
                options={contractLines}
                onRenderOption={option => (
                  <button
                    onClick={() => {
                      setSelectedPhoneId(Number(option?.key));
                      setIsNumberChanged(true);
                    }}
                    data-testid={`button-custom-${option.id}`}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      textAlign: "left"
                    }}
                  >
                    {option.text}
                  </button>
                )}
                styles={{
                  root: { marginTop: 20 },
                  dropdown: { width: 200 }
                }}
                disabled={
                  selectedPhoneAreaCode?.length !== 2 ||
                  contractLines === undefined
                }
              />
            </div>
            {linesError && (
              <div style={{ marginTop: 20, width: "100%" }}>
                <MessageBar
                  message={{
                    message: linesError,
                    type: messageBarTypes.ERROR
                  }}
                  dismissMessage={() => setSubmitMessage(null)}
                />
              </div>
            )}
          </div>
        )}

        {!canInsertLineNumber &&
          [
            LineStrategyType.MANUALLY_SELECTED_LINE,
            LineStrategyType.MANUALLY_SELECTED_LINE_PRE
          ].includes(operation?.lineStrategy) &&
          isNumberChanged &&
          contractType !== ProductContractType.EXISTENT && (
            <IccidInputContent
              onIccidValidate={(valid: boolean) => {
                setIsValidIccid(valid);
              }}
              setIccid={iccid => setIccid(iccid)}
              setInventoryId={inventoryId => setInventoryId(inventoryId)}
              operation={operation}
              selectedBU={selectedBU}
            />
          )}
      </div>

      {submitMessage && (
        <div style={{ marginTop: 20, width: "100%" }}>
          <MessageBar
            message={submitMessage}
            dismissMessage={clearSubmitMessage}
          />
        </div>
      )}

      <div style={{ display: "flex", height: "100%" }}>
        <SubmitButton
          id="submit-button"
          disabled={
            isSubmitButtonDisabled ||
            (!isValidIccid &&
              operation.type === "STANDARD" &&
              contractType !== ProductContractType.EXISTENT) ||
            (operation.type === "BRING_YOUR_OWN" &&
              operation.id === 2 &&
              initialCarrier === undefined)
          }
          onClick={openAdditionalModal}
        >
          Adicionar Benefício combo
        </SubmitButton>
      </div>
      <AdditionalOfferPriceToAppend
        additionalOfferPriceList={
          selectedPlan.offer.offerPrice.additionalOfferPrice
        }
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOPen}
        submit={onSubmit}
        submitMessage={submitMessage}
        clearSubmitMessage={clearSubmitMessage}
        canEdit={false}
        planPrice={+selectedPlan.offer.offerPrice.price}
      />
      {(isSubmitting || isLoadingOptions) && <Loader />}
    </PlanContractContainer>
  );
}
