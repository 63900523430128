import {
  CompoundButton,
  Dropdown,
  getTheme,
  Icon,
  IDropdownOption,
  MaskedTextField,
  SelectableOptionMenuItemType,
  Text,
  TextField
} from "@fluentui/react";
import { useState } from "react";
import { useAsyncAbortable } from "react-async-hook";
import { useHistory } from "react-router-dom";

import { AdditionalOfferPriceToAppend } from "../../AdditionalComponents/AdditionalOfferPriceToAppend";

import { TagContractContainer } from "./styles";
import TagConfirmationDialog from "./TagDialog";

import { SubmitButton } from "@/components/Offers/ProductOffers/ProductOfferContract/ProductContract.styles";
import { ProductContractList } from "@/components/Offers/ProductOffers/ProductOfferContract/ProductContractList";
import { FiltersOptions } from "@/components/Offers/ProductOffers/ProductOfferFilters/ProductFilter.styles";
import { Loader } from "@/components/Shared/Loader";
import {
  ContractsService,
  OperationsService,
  ProductsService,
  TagsService
} from "@/core/libs/api";
import {
  IMessageBar,
  MessageBar,
  messageBarTypes
} from "@/core/libs/message-bar";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { Plan } from "@/modules/Offers/types/PlansOffers.types";
import {
  OperationTypes,
  ProductContractType
} from "@/modules/Offers/types/ProductsOffers.types";

type TagContractQuestionsProps = {
  selectedPlan: Plan;
};

const contractService = ContractsService();
const operationService = OperationsService();
const tagsService = TagsService();
const productsService = ProductsService();

const createContractItem = (values, contractId, { businessActionId }) => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const params = {
    id: contractId,
    body: values,
    query: { businessActionId }
  };

  return contractService.saveItem({ params, signal });
};

const createItemInNewContract = (values, { businessActionId }) => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const params = {
    body: values,
    query: { businessActionId }
  };

  return contractService.saveItemInNewContract({ params, signal });
};

const contractTypeOptions = [
  {
    key: ProductContractType.NEW,
    text: ProductContractType.NEW
  },
  {
    key: ProductContractType.EXISTENT,
    text: ProductContractType.EXISTENT
  }
];

export function TagContractQuestions({
  selectedPlan
}: TagContractQuestionsProps) {
  const [contractType, setContractType] = useState<string>(undefined);
  const [operationType, setOperationType] = useState<OperationTypes>(undefined);
  const [selectedContract, setSelectedContract] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitMessage, setSubmitMessage] = useState<IMessageBar>(null);

  const [inventoryId, setInventoryId] = useState<number>(undefined);

  const [serialNumberMessage, setSerialNumberMessage] =
    useState<IMessageBar>(null);
  const [isSearchingSerialNumber, setIsSearchingSerialNumber] =
    useState<boolean>(false);

  const [serialNumber, setSerialNumber] = useState<string>();
  const [vehicleLicensePlate, setVehicleLicensePlate] = useState<string>();
  const [vehicleType, setVehicleType] =
    useState<{ id: number; description: string; axles: number }>();

  const [contractOperationsOptions, setContractOperationsOptions] =
    useState<IDropdownOption[]>();
  const [vehicleTypesOptions, setVehicleTypesOptions] =
    useState<IDropdownOption[]>();
  const [isModalOpen, setIsModalOPen] = useState(false);

  const { selectedBU } = useSelectedBu();

  const history = useHistory();

  const contractOperations = useAsyncAbortable(
    (signal, businessActionId) =>
      operationService.list({
        params: {
          query: { service: selectedPlan.service.id, businessActionId }
        },
        signal
      }),
    [selectedBU.id],
    {
      onSuccess: ({ response, data, error }) => {
        if (response.ok) {
          const options = data
            .filter(operation => operation.type !== "RENEWAL") // TODO: enquanto não decidirmos sobre o fluxo de troca de plano de benefícios nos benefícios
            .map(operation => ({
              key: operation.id,
              text: operation.name,
              data: operation
            }));

          setContractOperationsOptions(options);
        }

        if (error) {
          setSubmitMessage({
            type: messageBarTypes.ERROR,
            message: error.message
          });
        }
      },
      onError: error => {
        setSubmitMessage({
          type: messageBarTypes.ERROR,
          message: error.message
        });
      }
    }
  );

  const vehicleTypes = useAsyncAbortable(
    (signal, businessActionId) =>
      tagsService.listVehiclesGroups({
        params: { query: { businessActionId } },
        signal
      }),
    [selectedBU.id],
    {
      onSuccess: ({ response, data, error }) => {
        if (response.ok) {
          const options: IDropdownOption[] = [];

          for (const group of data.items) {
            options.push({
              key: `group-${group.id}`,
              text: group.name,
              itemType: SelectableOptionMenuItemType.Header
            });

            for (const type of group.tagVehicleTypes.sort(
              (a, b) => a.axles - b.axles
            )) {
              options.push({
                key: `group-${group.id}-type-${type.id}`,
                text: type.description,
                data: type
              });
            }
          }

          setVehicleTypesOptions(options);
        }

        if (error) {
          setSubmitMessage({
            type: messageBarTypes.ERROR,
            message: error.message
          });
        }
      },
      onError: error => {
        setSubmitMessage({
          type: messageBarTypes.ERROR,
          message: error.message
        });
      }
    }
  );

  async function handleChangeSerialNumber(serialNumber, operation, service) {
    setIsSearchingSerialNumber(true);

    const abortController = new AbortController();
    const { signal } = abortController;

    const params = {
      query: {
        businessActionId: selectedBU?.id,
        serialNumber,
        operation,
        service
      }
    };

    const response = await productsService.searchProduct({ params, signal });

    setInventoryId(undefined);
    setIsSearchingSerialNumber(false);
    if (response.data) {
      setInventoryId(response.data.inventory.id);
      setSerialNumberMessage({
        message: `Número de série para ${response.data.name} encontrado.`,
        type: messageBarTypes.SUCCESS
      });
    } else {
      setSerialNumberMessage({
        message: response.error.message,
        type: messageBarTypes.ERROR
      });
    }
  }

  const openAdditionalModal = () => {
    if (selectedPlan.offer.offerPrice.additionalOfferPrice.length > 0) {
      setIsModalOPen(true);
    } else {
      onSubmit();
    }
  };

  function handleConfirmNewContractTag(additionalContractItems = []) {
    if (contractType === ProductContractType.NEW) {
      const values = {
        businessActionId: selectedBU?.id,
        offerPrice: selectedPlan.offer.offerPrice.id,
        operation: operationType.id,
        plan: selectedPlan.id,
        inventory: inventoryId,
        additionalInfo: {
          serialNumber: serialNumber,
          vehicleLicensePlate: vehicleLicensePlate,
          vehicleType: vehicleType
        },
        additionalContractItems
      };

      const handleNewContract = async () => {
        const response = await createItemInNewContract(values, {
          businessActionId: selectedBU?.id
        });

        if (response.response.ok) {
          history.push(`contracts/${response?.data?.contract}/edit`, {
            message: response?.data?.message
          });
        } else {
          setIsSubmitting(false);

          setSubmitMessage({
            code: response.error?.status,
            message: response.error?.message,
            type: messageBarTypes.ERROR
          });
        }
      };

      return handleNewContract();
    } else if (contractType === ProductContractType.EXISTENT) {
      const values = {
        operation: operationType.id,
        offerPrice: selectedPlan.offer.offerPrice.id,
        plan: selectedPlan.id,
        inventory: inventoryId,
        additionalInfo: {
          serialNumber: serialNumber,
          vehicleLicensePlate: vehicleLicensePlate,
          vehicleType: vehicleType
        },
        additionalContractItems
      };

      const handleNewItem = async () => {
        const response = await createContractItem(
          values,
          selectedContract?.id,
          {
            businessActionId: selectedContract?.businessUnit?.id
          }
        );

        if (response.response.ok) {
          history.push(
            `contracts/${selectedContract.id}/edit`,
            !selectedContract.account
              ? {
                  message: response?.data?.message
                }
              : null
          );
        } else {
          setIsSubmitting(false);

          setSubmitMessage({
            code: response.error?.status,
            message: response.error?.message,
            type: messageBarTypes.ERROR
          });
        }
      };

      return handleNewItem();
    }
  }

  const onSubmit = () => {
    setIsSubmitting(true);
  };

  const theme = getTheme();

  return (
    <TagContractContainer>
      <div style={{ width: "100%" }}>
        <div
          className="ms-depth-8"
          style={{
            background: theme.palette.white,
            padding: 30,
            marginTop: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Text variant={"xLarge"}>
            Deseja criar um novo contrato ou selecionar um já existente?
          </Text>

          <FiltersOptions>
            {contractTypeOptions.map(type => (
              <CompoundButton
                id={`contractTypeOptions-button-${type.key}`}
                key={String(type.key)}
                checked={contractType === type.key}
                style={{
                  width: "100px",
                  border: "0",
                  borderRadius: "10px"
                }}
                onClick={() => setContractType(String(type.key))}
              >
                <Text variant={"mediumPlus"} style={{ fontWeight: 600 }}>
                  {type.text}
                </Text>
              </CompoundButton>
            ))}
          </FiltersOptions>
        </div>

        {contractType === ProductContractType.EXISTENT && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Selecione um contrato</Text>

            <div
              style={{
                overflowY: "auto",
                maxHeight: 250,
                width: "100%"
              }}
            >
              <ProductContractList setSelectedContract={setSelectedContract} />
            </div>
          </div>
        )}

        {contractType !== undefined && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Selecione o tipo de operação</Text>

            <Dropdown
              required
              selectedKey={Number(operationType?.id)}
              onChange={(event, option) => {
                setOperationType(option.data);
              }}
              placeholder="Selecione uma opção"
              options={contractOperationsOptions}
              styles={{
                root: { marginTop: 20 },
                dropdown: { width: 300 }
              }}
            />
          </div>
        )}

        {operationType !== undefined && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Digite o número de série da tag</Text>

            <TextField
              placeholder="Número de série"
              required
              maxLength={16}
              value={serialNumber}
              onChange={(evt, newValue) => {
                const valueOnlyNumbers = newValue.replace(/\D/g, "");
                setSerialNumber(valueOnlyNumbers);

                if (valueOnlyNumbers.length === 16) {
                  handleChangeSerialNumber(
                    valueOnlyNumbers,
                    operationType.id,
                    selectedPlan.service.id
                  );
                }
              }}
              onPaste={evt => {
                evt.preventDefault();

                const pastedValue = evt.clipboardData.getData("Text");
                const pastedValueOnlyNumbers = pastedValue.replace(/\D/g, "");
                setSerialNumber(pastedValueOnlyNumbers);

                if (pastedValueOnlyNumbers.length === 16) {
                  handleChangeSerialNumber(
                    pastedValueOnlyNumbers,
                    operationType.id,
                    selectedPlan.service.id
                  );
                }
              }}
              errorMessage={
                serialNumber !== undefined &&
                serialNumber.length !== 16 &&
                "O número de série deve ter 16 dígitos."
              }
              styles={{
                root: { marginTop: 20 },
                field: { width: 300 }
              }}
            />

            {serialNumberMessage && (
              <div style={{ marginTop: 20, width: "100%" }}>
                <MessageBar
                  message={serialNumberMessage}
                  dismissMessage={() => setSerialNumberMessage(null)}
                />
              </div>
            )}
          </div>
        )}

        {operationType !== undefined && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Selecione o tipo de veículo</Text>

            <Dropdown
              required
              onChange={(_event, option) => {
                setVehicleType(option.data);
              }}
              placeholder="Selecione um tipo"
              options={vehicleTypesOptions}
              dropdownWidth="auto"
              styles={{
                root: { marginTop: 20 },
                dropdown: { width: 300 }
              }}
            />
          </div>
        )}

        {operationType !== undefined && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Digite a placa do veículo</Text>

            <MaskedTextField
              required
              mask="AAA-1*11"
              maskFormat={{
                "*": /[a-zA-Z0-9]/,
                A: /[a-zA-Z]/,
                "1": /[0-9]/
              }}
              maskChar="_"
              value={vehicleLicensePlate}
              onChange={(e, newValue) => {
                setVehicleLicensePlate(
                  newValue.toUpperCase().replace(/[^A-Z0-9]/g, "")
                );
              }}
              errorMessage={
                vehicleLicensePlate !== undefined &&
                !vehicleLicensePlate.match(/[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/) &&
                "O número da placa deve seguir o padrão Mercosul"
              }
              styles={{
                root: { marginTop: 20 },
                field: { width: 300 }
              }}
            />
            {isSubmitting && (
              <TagConfirmationDialog
                isVisible={true}
                onConfirm={() => handleConfirmNewContractTag()}
                onCancel={() => setIsSubmitting(false)}
                serialNumber={serialNumber}
              />
            )}
          </div>
        )}
      </div>

      {submitMessage && (
        <div style={{ marginTop: 20, width: "100%" }}>
          <MessageBar
            message={submitMessage}
            dismissMessage={() => setSubmitMessage(null)}
          />
        </div>
      )}

      <div style={{ display: "flex", height: "100%" }}>
        <SubmitButton
          disabled={
            !operationType?.id ||
            !inventoryId ||
            !serialNumber ||
            !vehicleLicensePlate ||
            !vehicleType
          }
          onClick={openAdditionalModal}
        >
          Adicionar Benefício combo
        </SubmitButton>
      </div>
      <AdditionalOfferPriceToAppend
        additionalOfferPriceList={
          selectedPlan.offer.offerPrice.additionalOfferPrice
        }
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOPen}
        submit={onSubmit}
        submitMessage={submitMessage}
        clearSubmitMessage={() => setSubmitMessage(null)}
        canEdit={false}
        planPrice={+selectedPlan.offer.offerPrice.price}
      />
      {(isSubmitting ||
        isSearchingSerialNumber ||
        contractOperations.loading ||
        vehicleTypes.loading) && <Loader />}
    </TagContractContainer>
  );
}
