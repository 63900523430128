import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createCarriersFailure,
  createCarriersSuccess,
  editCarriersFailure,
  editCarriersSuccess,
  listCarriersFailure,
  listCarriersRequest,
  listCarriersSuccess
} from "../actions/carriers";
import { CarriersActionsTypes } from "../types/Carriers.reducer.types";

import { SagaCustomError } from "@/common/types";
import { CarriersService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createCarriers({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = CarriersService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createCarriersSuccess({
        message: `Operadora criada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listCarriersRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      createCarriersFailure({
        code: error?.statusCode,
        message: "Não foi possível criar a operadora com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editCarriers({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = CarriersService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editCarriersSuccess({
        message: `Operadora editada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listCarriersRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      editCarriersFailure({
        code: error?.statusCode,
        message: "Não foi possível editar a operadora com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listCarriers({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = CarriersService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listCarriersSuccess({ list: response.data }));
  } catch (error) {
    yield put(listCarriersFailure());
  }
}

export default all([
  takeLatest(CarriersActionsTypes.CREATE_REQUEST, createCarriers),
  takeLatest(CarriersActionsTypes.EDIT_REQUEST, editCarriers),
  takeLatest(CarriersActionsTypes.LIST_REQUEST, listCarriers)
]);
