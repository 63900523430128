import { BannersOffers } from "./BannersOffers.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum BannersOffersActionsTypes {
  DISMISS_MESSAGE = "banners-offers/DISMISS_MESSAGE",
  LIST_REQUEST = "banners-offers/LIST_REQUEST",
  LIST_FAIL = "banners-offers/LIST_FAIL",
  LIST_SUCCESS = "banners-offers/LIST_SUCCESS"
}

export interface BannersOffersState {
  isLoadingList: boolean;
  list?: BannersOffers;
  message?: IMessageBar;
}

export interface DismissMessage {
  type: BannersOffersActionsTypes.DISMISS_MESSAGE;
}

export interface ListRequest {
  type: BannersOffersActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: BannersOffersActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: BannersOffersActionsTypes.LIST_SUCCESS;
  payload: {
    list: BannersOffers;
  };
}

export type BannersOffersReducerActions =
  | DismissMessage
  | ListRequest
  | ListError
  | ListSuccess;
