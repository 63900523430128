import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #edebe9;
  background-color: #ffffff;
  width: 45rem;
  padding: 1rem;
`;

export const SideBar = styled.div`
  min-width: 40%;
  display: flex;
  align-items: center;
  border-right: 1px solid #edebe9;
  justify-content: center;
  word-break: break-word;
  padding-right: 0.5rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  min-width: 60%;
  flex-direction: column;
`;

export const SelectContainer = styled.div`
  padding-left: 0.5rem;
  padding-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;
