import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  width: 80vw;
  transition: 0.6s ease;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  min-width: 200px;
  max-width: 400px;
  width: 100%;
  overflow: auto;
`;

export const ImageTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpecificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const SpecificationTitle = styled.h2`
  color: ${theme.palette.themeSecondary};
  text-transform: uppercase;
  margin-top: 10px;
`;

export const SpecificationItem = styled.p`
  color: ${theme.palette.neutralTertiary};
`;

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.palette.neutralLighterAlt};
  width: 100%;
  min-height: 100%;
`;

export const ThumbnailImage = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const ProductBrand = styled.p`
  color: ${theme.palette.neutralTertiary};
  text-transform: uppercase;
`;

export const ProductTitle = styled.p`
  color: ${theme.palette.black};
  margin: 10px 0;
`;
