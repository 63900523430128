import { Dropdown, getTheme, Text, TextField } from "@fluentui/react";
import { useState } from "react";

import { ProductsService } from "@/core/libs/api";
import {
  IMessageBar,
  MessageBar,
  messageBarTypes
} from "@/core/libs/message-bar";
import { OperationTypes } from "@/modules/Offers/types/ProductsOffers.types";
import { BusinessUnit } from "@/modules/User/types";

type IccidInputContentPros = {
  onIccidValidate: (valid: boolean) => void;
  setInventoryId: (inventoryId: number) => void;
  setIccid: (iccid: string) => void;
  selectedBU: BusinessUnit;
  operation: OperationTypes;
  minimalLayout?: boolean;
};

export const IccidInputContent = ({
  minimalLayout = false,
  onIccidValidate,
  setIccid,
  setInventoryId,
  selectedBU,
  operation
}: IccidInputContentPros) => {
  const theme = getTheme();

  const productsService = ProductsService();

  const [iccidIsAtTheAgency, setIccidIsAtTheAgency] = useState<boolean>(false);

  const [isValidIccid, setIsValidIccid] = useState<boolean>(false);
  const [iccidMessage, setIccidMessage] = useState<IMessageBar>(null);

  async function handleChangeIccid({ iccid, operation, service }) {
    const abortController = new AbortController();
    const { signal } = abortController;

    const params = {
      query: {
        businessActionId: selectedBU?.id,
        serialNumber: iccid,
        operation,
        service,
        findByIccid: true
      }
    };

    const response = await productsService.searchProduct({ params, signal });
    setInventoryId(undefined);
    if (response.data) {
      setInventoryId(response.data.inventory.id);
      setIccidMessage({
        message: `ICCID para ${response.data.name} encontrado.`,
        type: messageBarTypes.SUCCESS
      });
      setIccid(iccid);
      setIsValidIccid(true);
      onIccidValidate(true);
    } else {
      setIccidMessage({
        message: response.error.message,
        type: messageBarTypes.ERROR
      });
      setIsValidIccid(false);
      onIccidValidate(false);
    }
  }

  function removeMask(value: string) {
    return value.replace(/\D/g, "");
  }

  function inputIccidValidation() {
    if (!selectedBU?.isIccidRequired && iccidIsAtTheAgency) {
      return true;
    }
    if (selectedBU?.isIccidRequired) {
      return true;
    }
  }

  return (
    <div
      className={minimalLayout ? "" : "ms-depth-8"}
      style={
        minimalLayout
          ? { width: "100%" }
          : {
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }
      }
    >
      <div
        style={
          minimalLayout
            ? { width: "100%" }
            : {
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center"
              }
        }
      >
        {!minimalLayout && (
          <Text variant={"xLarge"}>Digite o ICCID do chip</Text>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "baseline"
          }}
        >
          {!selectedBU?.isIccidRequired && (
            <Dropdown
              id="select-iccid"
              styles={minimalLayout ? { root: { marginRight: 8 } } : {}}
              label="O Chip se encontra na agência?"
              required
              placeholder="Selecione uma opção"
              options={[
                { key: "YES", text: "Sim", id: "option-button-yes" },
                { key: "NO", text: "Não", id: "option-button-no" }
              ]}
              onChange={(evt, item) => {
                function handleIccidNegativeAnswer() {
                  setIccidIsAtTheAgency(false);
                  setIsValidIccid(true);
                  onIccidValidate(true);
                }
                if (item.key === "YES") {
                  setIccidIsAtTheAgency(true);
                  setIsValidIccid(false);
                  onIccidValidate(false);
                } else {
                  handleIccidNegativeAnswer();
                }
              }}
              onRenderOption={option => (
                <button
                  onClick={() => {
                    function handleIccidNegativeAnswer() {
                      setIccidIsAtTheAgency(false);
                      setIsValidIccid(true);
                      onIccidValidate(true);
                    }
                    if (option.key === "YES") {
                      setIccidIsAtTheAgency(true);
                      setIsValidIccid(false);
                      onIccidValidate(false);
                    } else {
                      handleIccidNegativeAnswer();
                    }
                  }}
                  data-testid={option.id}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    textAlign: "left"
                  }}
                >
                  {option.text}
                </button>
              )}
            />
          )}
          {inputIccidValidation() && (
            <TextField
              id="iccid-input"
              required={true}
              label="ICCID"
              placeholder="ICCID"
              maxLength={20}
              minLength={20}
              onChange={(event: any) => {
                let iccid = event.target.value;
                iccid = iccid.replace(/\D/g, "");
                if (iccid?.length < 20) {
                  setIsValidIccid(false);
                  onIccidValidate(false);
                } else {
                  handleChangeIccid({
                    iccid: iccid,
                    operation: operation.id,
                    service: 1
                  });
                  if (isValidIccid) {
                    setIccid(iccid);
                  }
                }
              }}
              onPaste={evt => {
                const pastedValue = evt.clipboardData.getData("Text");
                const pastedValueOnlyNumbers = pastedValue.replace(/\D/g, "");

                if (pastedValueOnlyNumbers?.length < 20) {
                  setIsValidIccid(false);
                  onIccidValidate(false);
                } else {
                  handleChangeIccid({
                    iccid: pastedValueOnlyNumbers,
                    operation: operation.id,
                    service: 1
                  });
                  setIsValidIccid(true);
                  onIccidValidate(true);
                }
              }}
              onGetErrorMessage={value => {
                return removeMask(value)?.length < 20 && value?.length !== 0
                  ? "O ICCID Deve Ter 20 Dígitos"
                  : "";
              }}
              deferredValidationTime={400}
              styles={
                minimalLayout
                  ? {}
                  : { root: { marginBottom: 15, marginLeft: 10 } }
              }
            />
          )}
        </div>
      </div>
      {iccidMessage && (
        <div style={{ marginTop: 20, width: "100%" }}>
          <MessageBar
            message={iccidMessage}
            dismissMessage={() => setIccidMessage(null)}
          />
        </div>
      )}
    </div>
  );
};
