export type InputType = "ELETRONIC" | "ELETRONIC_WHATSAPP" | "STANDARD";
export type OutputType = "EMAIL" | "WHATSAPP" | "STANDARD";

export function mapContactType(input: InputType): OutputType {
  switch (input) {
    case "ELETRONIC":
      return "EMAIL";
    case "ELETRONIC_WHATSAPP":
      return "WHATSAPP";
    case "STANDARD":
      return "STANDARD";
    default:
      throw new Error(`Tipo de contato não encontrado: ${input}`);
  }
}
