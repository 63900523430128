export function cpfCnpjFormatter(cpfOrCnpj: string): string {
  cpfOrCnpj = cpfOrCnpj?.replace(/\D/g, "");
  if (cpfOrCnpj?.length <= 11) {
    //CPF
    cpfOrCnpj = cpfOrCnpj?.replace(/(\d{3})(\d)/, "$1.$2");
    cpfOrCnpj = cpfOrCnpj?.replace(/(\d{3})(\d)/, "$1.$2");
    cpfOrCnpj = cpfOrCnpj?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    //CNPJ
    cpfOrCnpj = cpfOrCnpj?.replace(/^(\d{2})(\d)/, "$1.$2");
    cpfOrCnpj = cpfOrCnpj?.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    cpfOrCnpj = cpfOrCnpj?.replace(/\.(\d{3})(\d)/, ".$1/$2");
    cpfOrCnpj = cpfOrCnpj?.replace(/(\d{4})(\d)/, "$1-$2");
  }
  return cpfOrCnpj;
}
