import { CommandBar, getTheme, ICommandBarItemProps } from "@fluentui/react";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CommandBarButtonCustom,
  CustomMenuItem,
  MenuButton
} from "./CustomButtons";

import { CustomButton } from ".";

import {
  CloseSidebarMenu,
  OpenSidebarMenu
} from "@/common/actions/sidebarMenu";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import { useAuth } from "@/core/libs/use-auth";
import { RootState } from "@/core/store/rootReducer";
import { UseApiAlerts } from "@/hooks/useApiAlerts";
import { useNotifications } from "@/hooks/useNotifications";
import { useOrganization } from "@/hooks/useOrganization";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { dismissProfile } from "@/modules/User/actions/profile";

const theme = getTheme();

// TODO: Unfortunately, I can't make this type safe right now. I'll fix it when we properly type the entities.

export function Header() {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const { handleBubbleVisibility, openApiAlertsResponsesButtonId } =
    UseApiAlerts();

  const {
    handleNotificationBubbleVisibility,
    openNotificationsBubbleButtonId,
    countUnreadNotifications
  } = useNotifications();

  const isMenuOpen = useSelector(
    (state: RootState) => state.sidebarMenu.isMenuOpen
  );

  const { selectedBU } = useSelectedBu();
  const userProfile = useGetProfile();
  const user = userProfile.data?.data;

  const { organizationSelected, openOrganizationSelection, organizations } =
    useOrganization();

  const items: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: "menu",
        iconOnly: true,
        ariaLabel: "Menu",
        commandBarButtonAs: MenuButton,
        onClick: () => {
          dispatch(isMenuOpen ? CloseSidebarMenu() : OpenSidebarMenu());
        },
        isMenuOpen: isMenuOpen
      },
      {
        key: "home",
        iconOnly: true,
        ariaLabel: "Libertas",
        iconProps: { iconName: "libertas" },
        title: "Voltar para Página Inicial",
        to: "/",
        commandBarButtonAs: CustomButton
      },
      {
        key: "hello",
        text: `Olá, ${user?.fullName || "usuário"}!`,
        title: `Seja bem-vindo ao Libertas! Clique para editar seu perfil.`,
        commandBarButtonAs: CustomButton,
        to: "/user/profile"
      }
    ],
    [dispatch, isMenuOpen, user?.fullName]
  );

  const createBUSubMenu = useCallback((bu, all = false) => {
    const businessUnitSelector: ICommandBarItemProps = {
      id: `businessUnit-selector-item-${bu.id}`,
      key:
        `id:${bu.id}-${Math.floor(Math.random() * 10000)}` +
        (all ? "-all" : ""),
      text: bu?.name,
      onRender: (item, dismissMenu) => {
        return (
          <CommandBarButtonCustom
            item={item}
            bu={{ ...bu, all }}
            dismissMenu={dismissMenu}
          />
        );
      }
    };

    const { children, ...buWithoutChildren } = bu;

    if (bu.children?.length > 0) {
      businessUnitSelector.subMenuProps = {
        items: [
          buWithoutChildren,
          ...children.sort((a, b) => a.name.localeCompare(b.name))
        ].map(bu => createBUSubMenu(bu))
      };
    }

    return businessUnitSelector;
  }, []);

  const farItems: ICommandBarItemProps[] = useMemo(() => {
    const items: ICommandBarItemProps[] = [];

    const organizationSelector: ICommandBarItemProps = {
      id: "organization-selector",
      key: "organizationSelector",
      ariaLabel: "Selecionar Organização",
      text: "Organização selecionada: " + organizationSelected?.name,
      onClick: () => {
        openOrganizationSelection();
      }
    };

    organizations &&
      organizations.length > 1 &&
      items.push(organizationSelector);

    if (user?.businessUnits?.length > 0 || user?.businessUnit?.id) {
      const isThereOnlyOneBU =
        user?.businessUnits?.length === 1 &&
        user?.businessUnits?.[0]?.children?.length < 1;

      const businessUnitSelector: ICommandBarItemProps = {
        id: "businessUnit-selector",
        key: "BUTreeView",
        ariaLabel: "Selecionar unidade de negócio",
        text: selectedBU
          ? `${selectedBU?.name}${selectedBU?.all ? " (Todas)" : ""}`
          : user.businessUnit?.name
      };

      if (user?.businessUnits?.length > 0 && !isThereOnlyOneBU) {
        businessUnitSelector.subMenuProps = {
          items: user.businessUnits
            .map(bu => createBUSubMenu(bu, true))
            .sort((a, b) => a.text.localeCompare(b.text))
        };
      }

      items.push(businessUnitSelector);
    }

    const notificationOpenCount =
      countUnreadNotifications > 99 ? "+99" : countUnreadNotifications || "";

    return [
      ...items,
      {
        id: openNotificationsBubbleButtonId,
        key: "response-notifications",
        text: `${notificationOpenCount}`,
        onClick: () => {
          handleNotificationBubbleVisibility();
        },
        iconProps: {
          iconName: "Ringer",
          className: "notifications-icon",
          styles: {
            root: {
              backgroundColor: notificationOpenCount ? "#395ca3" : "",
              color: notificationOpenCount ? theme.palette.white : "#395ca3",
              borderRadius: "75%",
              height: "1.5rem",
              width: "1.5rem",
              lineHeight: "unset",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }
          }
        },
        buttonStyles: {
          iconHovered: {
            color: notificationOpenCount ? theme.palette.white : "#395ca3"
          },
          iconPressed: {
            color: notificationOpenCount ? theme.palette.white : "#395ca3"
          },

          textContainer: countUnreadNotifications !== 0 && {
            backgroundColor: "#B62021",
            color: theme.palette.white,
            display: "flex",
            alignItems: "center",
            borderRadius: "50%",
            fontSize: "0.75rem",
            height: "1rem",
            lineHeight: "unset",
            position: "absolute",
            top: "10%",
            right: "60%"
          }
        }
      },
      {
        key: "userConfiguration",
        ariaLabel: "Configurações de usuário",
        iconOnly: true,
        iconProps: { iconName: "PlayerSettings" },
        commandBarButtonAs: CustomButton,
        subMenuProps: {
          contextualMenuItemAs: CustomMenuItem,
          items: [
            {
              key: "userProfile",
              text: "Perfil",
              iconProps: { iconName: "ContactInfo" },
              to: "/user/profile"
            },
            {
              key: "userLogout",
              text: "Sair",
              iconProps: { iconName: "PlugDisconnected" },
              onClick: () => {
                logout();
                dispatch(dismissProfile());
                window.fcWidget?.destroy();
              }
            }
          ]
        }
      }
    ];
  }, [createBUSubMenu, dispatch, logout, selectedBU, user]);

  return (
    <CommandBar
      items={items}
      farItems={farItems}
      styles={{
        root: {
          borderBottom: "1px solid",
          borderBottomColor: theme.palette.neutralLight,
          minHeight: 48,
          paddingLeft: 10
        }
      }}
      overflowButtonProps={{
        menuProps: {
          items: [],
          contextualMenuItemAs: CustomMenuItem
        }
      }}
    />
  );
}
