import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  getTheme
} from "@fluentui/react";
import { useCallback, useEffect, useRef, useState } from "react";

import { mapContactType } from "./contractType.mapper";
import { DynamicContactInputForm } from "./DynamicContactInputForm";
import {
  ContactType,
  FormValuesType,
  GetAccountSignaturesProps,
  OverwriteContract,
  SelectSignatureProps,
  SelectedSignatureProps
} from "./signature.interface";

import { Loader } from "@/components/Shared/Loader";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { AccountType } from "@/modules/Accounts/types";
import { BusinessUnitsSignatures } from "@/modules/Settings/types/BusinessUnits.types";

export function SelectSignature({
  showSignatures,
  setShowSignaturesDialog,
  contract,
  refetchContract,
  openModalConfirmContract,
  setShowConfirmContract,
  selectedSalesTeamId,
  travelDate
}: SelectSignatureProps) {
  const [initialValues, setInitialValues] = useState<FormValuesType>({
    whatsapp: "",
    email: ""
  });
  const [selectedSignatureProps, setSelectedSignatureProps] =
    useState<SelectedSignatureProps>(null);
  const [accountType, setAccountType] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [successMessage, setSuccessMessage] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showNewContactField, setShowNewContactField] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [signatureOptions, setSignatureOptions] = useState([]);

  const { execute } = useApi();
  const theme = getTheme();

  const formId = "configure-signature-form";
  const whatsappRefs = useRef([]);
  const emailRefs = useRef([]);
  const submitButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);

  const ERROR_FETCH_SIGNATURES = "Erro ao buscar as assinaturas!";
  const ERROR_UPDATE_CONTRACT = "Erro ao atualizar contrato!";

  const fetchOptions = useCallback(async () => {
    const response = await execute({
      url: `contracts/${contract.id}/available-signatures`
    });

    const mappedOptions = response.data.map(
      (value: BusinessUnitsSignatures) => ({
        key: value.signature.type,
        text: value.signature.name,
        id: value.signature.id
      })
    );

    setSignatureOptions(mappedOptions);
  }, [execute, contract]);

  const resetFields = useCallback(() => {
    setErrorMessage(undefined);
    setSelectedSignatureProps(undefined);
    setAccountType(undefined);
    setContacts(undefined);
    setShowNewContactField(false);
    setShowConfirmContract(false);
    setSelectedContact(null);
  }, [
    setErrorMessage,
    setSelectedSignatureProps,
    setAccountType,
    setContacts,
    setShowNewContactField,
    setShowConfirmContract,
    setSelectedContact
  ]);

  useEffect(() => {
    if (showSignatures) {
      resetFields();
      fetchOptions();
    }
  }, [showSignatures, resetFields, fetchOptions]);

  const getAccountSignatures = useCallback(
    async ({
      accountId,
      contactType
    }: GetAccountSignaturesProps): Promise<void> => {
      if (contactType === ContactType.STANDARD) {
        return;
      }
      const result = await execute({
        url: `accounts/signers`,
        messages: { error: ERROR_FETCH_SIGNATURES },
        params: {
          accountId,
          contactType
        }
      });
      if (
        !result?.data.length &&
        contract.account.accountType === AccountType.PHYSICAL
      ) {
        setSelectedContact(null);
        return setContacts(null);
      }
      if (result?.data?.length > 0) {
        result?.data?.forEach(accountSignature => {
          if (accountSignature.accountType === AccountType.PHYSICAL) {
            setContacts(accountSignature?.contacts);

            setSelectedContact({
              id: accountSignature?.contacts[0]?.id,
              name: accountSignature?.contacts[0]?.contact
            });
          }
        });
      }
    },
    [contract.account.accountType, execute]
  );

  const handleChangeSignaturesCallback = useCallback(
    (_, option) => {
      const newSignatureProps = {
        id: option.id,
        name: mapContactType(option.key)
      };

      setSelectedSignatureProps(prevProps => {
        if (prevProps?.name !== ContactType.STANDARD) {
          getAccountSignatures({
            accountId: contract.account.id,
            contactType: newSignatureProps?.name
          });
        }
        return newSignatureProps;
      });
    },
    [contract.account.id, getAccountSignatures]
  );

  useEffect(() => {
    if (selectedSignatureProps?.name === "STANDARD") return;
    if (selectedSignatureProps) {
      getAccountSignatures({
        accountId: contract.account.id,
        contactType: selectedSignatureProps.name
      });
      setAccountType(contract.account.accountType);

      if (contract.account.accountType === AccountType.LEGAL) {
        return setContacts(contract.account.legalRepresentatives);
      }
    }
  }, [selectedSignatureProps, contract, getAccountSignatures]);

  useEffect(() => {
    contacts?.forEach(value => {
      if (value.contactType === "WHATSAPP" && !initialValues?.whatsapp) {
        setInitialValues(prev => ({ ...prev, whatsapp: value.contact }));
      } else if (value.contactType === "EMAIL" && !initialValues?.email) {
        setInitialValues(prev => ({ ...prev, email: value.contact }));
      }
    });
  }, [contacts, initialValues]);

  const getAllWhatsappValues = () => {
    return whatsappRefs?.current?.map(containerRef => {
      if (containerRef) {
        const inputElement = containerRef.querySelector("input");
        if (inputElement) {
          return {
            id: inputElement.id,
            contact: inputElement.value.replace(/\D/g, "")
          };
        } else {
          return {
            id: containerRef.id,
            contact: containerRef.value.replace(/\D/g, "")
          };
        }
      }
      return null;
    });
  };

  const getAllEmailsValues = () => {
    return emailRefs.current.map(containerRef => {
      if (containerRef) {
        return { id: containerRef.id, contact: containerRef.value };
      }
    });
  };

  const executeOverwrite = useCallback(
    async (overwriteContractData: OverwriteContract) => {
      const result = await execute({
        url: `contracts/overwrite/${contract.id}`,
        method: "PATCH",
        bodyObj: Object(overwriteContractData)
      });

      if (!result) {
        setErrorMessage(ERROR_UPDATE_CONTRACT);
        setShowConfirmContract(false);
        setIsSubmitting(false);
        resetFields();
      }

      if (result?.data) {
        setSuccessMessage(result.data.message);
        setShowSignaturesDialog(false);
        refetchContract();

        if (selectedSignatureProps?.name !== "STANDARD") {
          openModalConfirmContract();
          setShowConfirmContract(true);
        }
      }
    },
    [
      execute,
      contract?.id,
      openModalConfirmContract,
      selectedSignatureProps?.name,
      refetchContract,
      resetFields,
      setShowConfirmContract,
      setShowSignaturesDialog
    ]
  );

  const onSubmitCallback = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsSubmitting(true);

      const overwriteContractData: OverwriteContract = {
        accountId: contract.account.id
      };

      overwriteContractData.signatureId = selectedSignatureProps.id;
      overwriteContractData.salesTeamId = selectedSalesTeamId;
      overwriteContractData.travelDate = travelDate;

      if (selectedSignatureProps.name === "STANDARD") {
        return executeOverwrite(overwriteContractData);
      }

      const allWhatsappValues = getAllWhatsappValues();
      const allEmailsValues = getAllEmailsValues();

      const whatsappAccountContactId = allWhatsappValues[0]?.id
        ? allWhatsappValues[0]?.id
        : selectedContact?.id || null;

      const whatsappAccountContactValue = allWhatsappValues[0]?.contact
        ? allWhatsappValues[0]?.contact
        : selectedContact?.name;

      const emailAccountContactId = allEmailsValues[0]?.id
        ? allEmailsValues[0]?.id
        : selectedContact?.id || null;

      const emailAccountContactValue = allEmailsValues[0]?.contact
        ? allEmailsValues[0]?.contact
        : selectedContact?.name;

      if (accountType === AccountType.PHYSICAL) {
        if (selectedSignatureProps.name === ContactType.WHATSAPP) {
          overwriteContractData.accountContact = {
            id: whatsappAccountContactId,
            contactType: ContactType.WHATSAPP,
            contact: whatsappAccountContactValue
          };
        } else if (selectedSignatureProps.name === ContactType.EMAIL) {
          overwriteContractData.accountContact = {
            id: emailAccountContactId,
            contactType: ContactType.EMAIL,
            contact: emailAccountContactValue
          };
        }
      }

      if (accountType === AccountType.LEGAL) {
        overwriteContractData.accountLegalRepresentatives = contacts.map(
          (contact, index) => {
            return {
              id: contact.id,
              email: allEmailsValues[index]?.contact,
              whatsapp: allWhatsappValues[index]?.contact
            };
          }
        );

        overwriteContractData.accountLegalRepresentatives.filter(value => {
          if (!value.email) {
            delete value.email;
          }
          if (!value.whatsapp) {
            delete value.whatsapp;
          }
          if (!value.id) {
            delete value.id;
          }
        });
      }

      return executeOverwrite(overwriteContractData);
    },
    [
      accountType,
      contacts,
      contract,
      executeOverwrite,
      selectedContact,
      selectedSalesTeamId,
      selectedSignatureProps,
      travelDate
    ]
  );

  const renderSignatureOptions = () => {
    if (signatureOptions?.length > 0) {
      return (
        <ChoiceGroup
          options={signatureOptions}
          radioGroup="none"
          multiple={false}
          styles={{
            flexContainer: {
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-evenly"
            }
          }}
          onChange={handleChangeSignaturesCallback}
        />
      );
    }

    return (
      <p style={{ width: "100%", textAlign: "center", fontWeight: 600 }}>
        Nenhuma opção de assinatura encontrada, por favor, contate o suporte!
      </p>
    );
  };

  const renderFormHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <strong style={{ color: "#222222" }}>Cooperado</strong>
        <p style={{ color: theme.palette.accent }}>
          {contract.account.fullName}
        </p>
      </div>
    );
  };

  const renderFormFooter = () => {
    return (
      <DialogFooter styles={{ actions: { lineHeight: "0px" } }}>
        <Stack tokens={{ childrenGap: 15 }} style={{ marginBottom: "1rem" }}>
          {errorMessage && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline>
              {errorMessage}
            </MessageBar>
          )}
          {successMessage && (
            <MessageBar messageBarType={MessageBarType.success} isMultiline>
              {successMessage}
            </MessageBar>
          )}
        </Stack>
        <PrimaryButton
          id="submit-button"
          text="Salvar"
          type="submit"
          form={formId}
          ref={submitButtonRef}
          disabled={!showSignatures}
        />
        <DefaultButton
          id="button-cancel"
          onClick={() => {
            setShowSignaturesDialog(false);
            setShowConfirmContract(false);
            setSelectedSignatureProps(null);
          }}
          text="Cancelar"
          ref={cancelButtonRef}
        />
      </DialogFooter>
    );
  };

  const renderFormContent = () => {
    if (isSubmitting) {
      return <Loader />;
    }

    return (
      <>
        {renderFormHeader()}
        {renderSignatureOptions()}

        <Separator />

        {selectedSignatureProps && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <form onSubmit={onSubmitCallback} id={formId}>
              <DynamicContactInputForm
                selectedSignatureProps={selectedSignatureProps}
                accountType={accountType}
                setShowNewContactField={setShowNewContactField}
                contacts={contacts}
                showNewContactField={showNewContactField}
                emailRefs={emailRefs}
                whatsappRefs={whatsappRefs}
                initialValues={initialValues}
                setSelectedContact={setSelectedContact}
                selectedContact={selectedContact}
              />
              {selectedSignatureProps.name === "STANDARD" ? (
                <Stack
                  tokens={{ childrenGap: 15 }}
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                >
                  <Text style={{ fontSize: "1rem", marginBottom: "1rem" }}>
                    Será <strong>obrigatório</strong> o preenchimento das
                    assinaturas de forma <strong>manual</strong>
                  </Text>
                  {renderFormFooter()}
                </Stack>
              ) : (
                renderFormFooter()
              )}
            </form>
          </div>
        )}
      </>
    );
  };

  return (
    <Dialog
      maxWidth={"100rem"}
      hidden={!showSignatures}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: "Escolha por onde prefere assinar o contrato",
        styles: {
          innerContent: { position: "static" },
          title: { paddingRight: "24px", textAlign: "center" },
          content: { width: "50rem" }
        }
      }}
      onDismiss={() => {
        setShowSignaturesDialog(false);
        setShowConfirmContract(false);
        setSelectedSignatureProps(null);
      }}
    >
      {renderFormContent()}
    </Dialog>
  );
}
