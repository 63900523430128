import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class Users extends Api {
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/users${subPaths}` : `users${subPaths}`);
    this.editOwnProfile = this.editOwnProfile.bind(this);
    this.getOwnProfile = this.getOwnProfile.bind(this);
    this.resendWelcomeEmail = this.resendWelcomeEmail.bind(this);
  }

  editOwnProfile({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "PATCH",
      baseUrl: `${this.baseUrl}/profile`
    };

    options.bodyObj = params.body;
    return makeRequest(options);
  }

  getOwnProfile({ signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/profile`
    };

    return makeRequest(options);
  }

  resendWelcomeEmail({ id, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/${id}/notifications/welcome-mail`
    };

    return makeRequest(options);
  }
}

export default Users;
