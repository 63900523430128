import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  column-gap: 25px;
  padding: 0rem;
  h1 {
    color: rgb(57, 92, 163);
    font-size: 20px;
    font-weight: bold;
  }
  h2 {
    max-width: 350px;
    word-wrap: break-word;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0.85rem;
`;
