import { ComboBox } from "@fluentui/react/lib/ComboBox";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { Stack } from "@fluentui/react/lib/Stack";
import { MaskedTextField, TextField } from "@fluentui/react/lib/TextField";
import { Field } from "formik";
import { Fragment } from "react";

import { AddressesFields } from "../adresses-fields/addresses-fields";

import { ArrayField } from "./array-fields";
import { BusinessUnitSettingsFields } from "./business-unit-settings-fields";
import { CheckingAccountsFields } from "./checking-accounts-fields";
import { ContactsFields } from "./contacts-fields";
import { CreditFeePerServiceFields } from "./creditFeePerService";
import { DateField } from "./date-field";
import { DocumentsFields } from "./documents-fields";
import { Utils } from "./helpers";
import { WitnessesFields } from "./witnesses-fields";

interface FieldsBuilderProps {
  fields: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  values: any;
}

interface RenderFieldProps {
  field: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  values: any;
}

interface SwitchFieldTypeProps {
  test: any;
  children: any;
}

const getNestedObject = (nestedObj, pathArr: Array<string>) => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    nestedObj
  );
};

/**
 * Fields builder
 * @param props
 */
function FieldsBuilder(props: FieldsBuilderProps): JSX.Element {
  const { fields } = props;

  return (
    <Fragment>
      {fields instanceof Array ? (
        <RenderFields {...props} />
      ) : (
        <PivotFields {...props} />
      )}
    </Fragment>
  );
}

/**
 * Render pivot fields
 * @param props
 */
function PivotFields(props: FieldsBuilderProps): JSX.Element {
  const { fields, errors, touched, setFieldValue, values } = props;

  const createPivotItems = () => {
    const pivotItems = [];

    for (const key of Object.keys(fields)) {
      pivotItems.push(
        <PivotItem
          headerText={fields[key].title}
          style={{ marginTop: 10 }}
          key={`pivot${fields[key].title}`}
        >
          <Stack
            styles={{
              root: {
                padding: 20
              }
            }}
            tokens={{ childrenGap: 15 }}
          >
            <RenderFields
              fields={fields[key].fields}
              {...{ errors, touched, setFieldValue, values }}
            />
          </Stack>
        </PivotItem>
      );
    }

    return pivotItems;
  };

  return <Pivot>{createPivotItems()}</Pivot>;
}

function RenderField(props: RenderFieldProps): JSX.Element {
  const { values, errors, touched, setFieldValue } = props;
  const field = Utils.copy(props.field);
  const allowedFields = [
    "addresses",
    "contacts",
    "unitBusinessSettings",
    "checkingAccounts",
    "comboBox",
    "creditFeePerService",
    "documents",
    "date",
    "hidden",
    "select",
    "textarea",
    "money",
    "witnesses",
    "arrayField"
  ];

  const fieldType = allowedFields.includes(field.type) ? field.type : "default";

  return (
    <SwitchFieldType test={fieldType}>
      <Case value="addresses">
        <AddressesFields
          addressCfg={field}
          key="addressFields"
          values={values[field.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      </Case>
      <Case value="contacts">
        <ContactsFields
          contactCfg={field}
          key="contactFields"
          values={values[field.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      </Case>
      <Case value="unitBusinessSettings">
        <BusinessUnitSettingsFields
          BusinessUnitSettingCfg={field}
          key="BusinessUnitSettingFields"
          values={values[field.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      </Case>
      <Case value="checkingAccounts">
        <CheckingAccountsFields
          config={field}
          key="checkingAccountsFields"
          values={values[field.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      </Case>
      <Case value="comboBox">
        <Field
          data-testid={field.name}
          name={field.name}
          type="text"
          as={ComboBox}
          id={field.name}
          onChange={(event, option?, index?, value?) => {
            const selectedPlans = new Set(values.plans);
            if (option.selected) {
              selectedPlans.add(option.key);
            } else {
              selectedPlans.delete(option.key);
            }

            setFieldValue(field.name, [...selectedPlans]);
          }}
          placeholder={field.placeholder || field.label}
          key={field.name}
          label={field.label}
          disabled={field.disabled || false}
          errorMessage={
            errors[field.name] && touched[field.name]
              ? errors[field.name]
              : null
          }
          defaultSelectedKey={
            !Array.isArray(field.value) ? field.value : undefined
          }
          allowFreeform={false}
          autoComplete="on"
          options={field.options}
          multiSelect={field.multiSelect}
          required={field.required || false}
          description={field.description}
        />
      </Case>
      <Case value="creditFeePerService">
        <CreditFeePerServiceFields
          config={field}
          key="creditFeePerService"
          values={values[field.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      </Case>
      <Case value="documents">
        <DocumentsFields
          documentCfg={field}
          key="documentFields"
          values={values[field.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      </Case>
      <Case value="date">
        <DateField
          name={field.name}
          type={field.type}
          key={field.name}
          label={field.label}
          disabled={field.disabled || false}
          errorMessage={
            errors[field.name] && touched[field.name]
              ? errors[field.name]
              : null
          }
          value={Utils.formatDateValue(values[field.name])}
          isRequired={field.required || false}
          setFieldValue={setFieldValue}
        />
      </Case>
      <Case value="hidden">
        <Field
          name={field.name}
          type="hidden"
          key={field.name}
          id={field.name}
          value={field.value}
        />
      </Case>
      <Case value="select">
        <Field
          name={field.name}
          render={() => (
            <Dropdown
              placeholder={field.placeholder || field.label}
              selectedKey={field.value}
              onChange={(evt, item) => {
                values[field.name] === item.key
                  ? setFieldValue(field.name, null)
                  : setFieldValue(field.name, item.key);
              }}
              data-testid={field.name}
              key={field.name}
              label={field.label}
              disabled={field.disabled || false}
              errorMessage={
                getNestedObject(errors, field.name.split(".")) &&
                getNestedObject(touched, field.name.split("."))
                  ? getNestedObject(errors, field.name.split("."))
                  : null
              }
              defaultSelectedKey={field.value !== "" ? field.value : undefined}
              options={field.options}
              onRenderOption={option => (
                <button
                  onClick={() => {
                    values[field.name] === option.key
                      ? setFieldValue(field.name, null)
                      : setFieldValue(field.name, option.key);
                  }}
                  data-testid={`button-custom-${option.id}`}
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  {option.text}
                </button>
              )}
              styles={{ dropdown: { width: "100%" } }}
              required={field.required || false}
              calloutProps={{ calloutMaxHeight: 500 }}
            />
          )}
        />
      </Case>
      <Case value="textarea">
        <Field
          data-testid={field?.id ?? null}
          name={field.name}
          type={field.type}
          as={TextField}
          placeholder={field.placeholder || field.label}
          key={field.name}
          label={field.label}
          disabled={field.disabled || false}
          errorMessage={
            errors[field.name] && touched[field.name]
              ? errors[field.name]
              : null
          }
          required={field.required || false}
          description={field.description}
          multiline
          rows={3}
        />
      </Case>
      <Case value="witnesses">
        <WitnessesFields
          cfg={field}
          key="witnesses"
          values={values[field.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      </Case>
      <Case value="money">
        <Field
          data-testid={field.name}
          name={field.name}
          type={field.type}
          id={field.name}
          as={TextField}
          placeholder={field.placeholder || field.label}
          key={field.name}
          label={field.label}
          disabled={field.disabled || false}
          errorMessage={
            getNestedObject(errors, field.name.split(".")) &&
            getNestedObject(touched, field.name.split("."))
              ? getNestedObject(errors, field.name.split("."))
              : null
          }
          prefix="R$"
          required={field.required || false}
          description={field.description}
          value={
            field.value?.length > 2
              ? toLocaleStringWithoutCurrency(field.value)
              : field.value || undefined
          }
          onChange={(event, value) => {
            if (value && value !== "") {
              value = value.replace(/\D/g, "");
              if (value.length === 3) {
                value = value.replace(/(\d{1})(\d{2})/, "$1,$2");
              } else if (value.length === 4) {
                value = value.replace(/(\d{2})(\d{2})/, "$1,$2");
              } else if (value.length === 5) {
                value = value.replace(/(\d{3})(\d{2})/, "$1,$2");
              } else if (value.length === 6) {
                value = value.replace(/(\d{1})(\d{3})(\d{2})/, "$1.$2,$3");
              } else if (value.length === 7) {
                value = value.replace(/(\d{2})(\d{3})(\d{2})/, "$1.$2,$3");
              } else if (value.length === 8) {
                value = value.replace(/(\d{3})(\d{3})(\d{2})/, "$1.$2,$3");
              } else if (value.length === 9) {
                value = value.replace(
                  /(\d{1})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3,$4"
                );
              } else if (value.length === 10) {
                value = value.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3,$4"
                );
              } else if (value.length === 11) {
                value = value.replace(
                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3,$4"
                );
              } else if (value.length === 12) {
                value = value.replace(
                  /(\d{1})(\d{3})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3.$4,$5"
                );
              } else {
                value = value.replace(/(\d{10,})(\d{2})/, "$1.$2");
              }
            } else {
              value = "000";
            }
            setFieldValue(field.name, value);
          }}
        />
      </Case>
      <Case value="default">
        <Field
          data-testid={field.name}
          name={field.name}
          type={field.type}
          id={field.name}
          as={field.mask || field.maskFormat ? MaskedTextField : TextField}
          placeholder={field.placeholder || field.label}
          key={field.name}
          label={field.label}
          disabled={field.disabled || false}
          errorMessage={
            getNestedObject(errors, field.name.split(".")) &&
            getNestedObject(touched, field.name.split("."))
              ? getNestedObject(errors, field.name.split("."))
              : null
          }
          value={field.value || ""}
          onChange={(event, value: string) => {
            if (field.mask) {
              value = value.replace(/\D/g, "");
              setFieldValue(field.name, value);
            } else {
              setFieldValue(field.name, value);
            }
          }}
          mask={field.mask}
          required={field.required || false}
          description={field.description}
          maxLength={field.maxLength}
          suffix={field.suffix}
          min={field.min}
          max={field.max}
        />
      </Case>
      <Case value="arrayField">
        <ArrayField
          key={field.name}
          fieldConfig={field}
          setFieldValue={setFieldValue}
          errors={errors}
          values={values[field.name]}
          touched={touched}
        />
      </Case>
    </SwitchFieldType>
  );
}

/**
 * Just to detect if is single field or grouped fields
 * @param props
 */
function RenderFields(props: FieldsBuilderProps): JSX.Element {
  const { fields, errors, touched, setFieldValue, values } = props;

  return fields.map((field, index) => {
    return field instanceof Array ? (
      <RenderGroupedField
        fields={field}
        {...{ errors, touched, setFieldValue, values }}
        key={index}
      />
    ) : (
      <RenderField
        field={field}
        {...{ errors, touched, setFieldValue, values }}
        key={field.name}
      />
    );
  });
}

function RenderGroupedField(props: FieldsBuilderProps): JSX.Element {
  const { fields, errors, touched, setFieldValue, values } = props;

  return (
    <div className="ms-Grid-row" style={{ marginBottom: 10 }}>
      {fields.map((field, index) => {
        return (
          <div
            className={`ms-Grid-col ms-sm12${
              field.size ? ` ms-lg${field.size}` : ""
            }`}
            key={`${field.key}[${index}]`}
            style={{ marginBottom: 7 }}
          >
            <RenderField
              field={field}
              {...{ errors, touched, setFieldValue, values }}
              key={field.name}
            />
          </div>
        );
      })}
    </div>
  );
}

function SwitchFieldType(props: SwitchFieldTypeProps): JSX.Element {
  const { test, children } = props;

  return children.find(child => {
    return child.props.value === test;
  });
}

function Case({ children, value }: any) {
  return children;
}

function toLocaleStringWithoutCurrency(value) {
  return convertNumber(value).toLocaleString("pt-BR", {
    minimumFractionDigits: 2
  });
}

function convertNumber(value) {
  return isNaN(value)
    ? Number(value.replace(/\./g, "").replace(/,/g, "."))
    : Number(value);
}

export { FieldsBuilder };
