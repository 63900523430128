import produce from "immer";

import {
  RolesActionsTypes,
  RolesReducerActions,
  RolesState
} from "../types/Roles.reducer.types";

const INITIAL_STATE: RolesState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: RolesReducerActions
): RolesState {
  return produce(state, draft => {
    switch (action.type) {
      case RolesActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case RolesActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case RolesActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case RolesActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case RolesActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case RolesActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case RolesActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case RolesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case RolesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case RolesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
