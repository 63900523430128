import { ContractsService } from "@/core/libs/api";
import { IMessageBar, messageBarTypes } from "@/core/libs/message-bar";

type ChangeContractStatusRequest = {
  setIsFetching: (isFetching: boolean) => void;
  setMessage?: (message: IMessageBar) => void;
  helperFunction?: () => void;
  contractId: string | number;
  action: string;
  statusChangeReason?: string;
};

export const changeContractStatus = async ({
  setIsFetching,
  setMessage,
  helperFunction,
  contractId,
  action,
  statusChangeReason
}: ChangeContractStatusRequest) => {
  const abortController = new AbortController();
  const { signal } = abortController;
  const service = ContractsService();

  setIsFetching(true);

  try {
    const params = {
      id: contractId.toString(),
      query: { interaction: action },
      body: {}
    };
    statusChangeReason && Object.assign(params.body, { statusChangeReason });

    const response = await service.status({ params, signal });
    if (!response.response?.ok) throw response.error;

    if (helperFunction) helperFunction();
  } catch (error) {
    if (setMessage) {
      setMessage({
        message:
          error.message ?? "Não foi possível alterar o status do contrato.",
        type: messageBarTypes.ERROR
      });
    }
  } finally {
    setIsFetching(false);
  }
};
