import { IContextualMenuItem, Stack } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { buildMembersColumns } from "./fields/Members.columns";
import { buildMembersFilters } from "./fields/Members.filters";
import { MembershipStatusEnum } from "./types/MembershipPage.types";

import { FilterParamsType } from "@/common/types/ListFromApi.types";
import { PermissionsAction } from "@/common/types/permissions";
import { Card } from "@/components/Shared/Card";
import { useGetMembers, useGetProfile } from "@/core/libs/api/react-query";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { ListData } from "@/core/libs/list-data";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { useURLFilter } from "@/hooks/useUrlFIlter";

export type MembershipMigrationProps = {
  canMigrate: boolean;
  canIntegrate: boolean;
};

function MembershipMigration({
  canMigrate,
  canIntegrate
}: MembershipMigrationProps): JSX.Element {
  const [selectedMember, setSelectedMember] = useState<string>(null);
  const [accountsItems, setAccountsItems] = useState<IContextualMenuItem[]>([]);

  const userProfile = useGetProfile();
  const permissions = userProfile.data?.data?.role.permissions.pos.members;
  const hasListPermission = permissions.includes(PermissionsAction.LIST);

  const { selectedBU } = useSelectedBu();

  const { changeUrlParams, urlParams } = useURLFilter();
  const { membersList, isLoadingMembersList, invalidateQuery } = useGetMembers({
    params: urlParams.params
  });
  const { execute } = useApi();

  const history = useHistory();

  const handleAccountRequest = useCallback(
    (filterParams?: FilterParamsType) => {
      changeUrlParams(filterParams);
    },
    [changeUrlParams]
  );

  const createTermAndRedirect = useCallback(async () => {
    const bodyData = { accountId: selectedMember };

    const res = await execute({
      url: "terms",
      method: "POST",
      notificationMessage: "Criando termo de adesão à Coopercompany",
      bodyObj: Object(bodyData)
    });

    if (res.response.ok) {
      invalidateQuery();
    }

    history.push(`terms/${res.data.id}`);
  }, [execute, history, selectedMember]);

  const invoiceChargeMember = useCallback(async () => {
    await execute({
      url: `accounts/invoice-charge/${selectedMember}`,
      method: "PATCH",
      notificationMessage: "Lançado na fatura do membro!",
      showAlert: true
    });
  }, [selectedMember, execute]);

  useEffect(() => {
    const newItems: IContextualMenuItem[] = [];

    const selectedMemberInfo = membersList?.items?.find(
      account => account.id === Number(selectedMember)
    );
    const selectedMemberStatus = selectedMemberInfo?.membershipStatus;
    const disableMigrateButton = ![
      MembershipStatusEnum.NOT_ASSOCIATED,
      MembershipStatusEnum.EXPIRED,
      MembershipStatusEnum.DISSOCIATED,
      MembershipStatusEnum.REFUSED
    ].includes(selectedMemberStatus);

    if (canMigrate) {
      newItems.push({
        key: "migrateAccount",
        text: "Criar adesão à Coopercompany",
        disabled: disableMigrateButton,
        onClick: () => {
          createTermAndRedirect();
        }
      });
      //
      if (selectedMemberInfo?.terms?.length > 0) {
        //
        const currentTerm = selectedMemberInfo?.terms?.reduce((acc, current) =>
          current?.id > acc?.id ? current : acc
        );
        newItems.push({
          key: "view-term",
          text: "Ver termo de adesão atual",
          onClick: () => {
            history.push(`terms/${currentTerm.id}`);
          }
        });
      }
      if (
        selectedMemberStatus === MembershipStatusEnum.SIGNED &&
        canIntegrate
      ) {
        newItems.push({
          key: "invoice_charged",
          text: "Lançar em fatura",
          onClick: () => {
            invoiceChargeMember();
          }
        });
      }
    }
    setAccountsItems(newItems);
  }, [
    canIntegrate,
    canMigrate,
    history,
    invoiceChargeMember,
    selectedMember,
    membersList?.items,
    createTermAndRedirect
  ]);

  return (
    <Stack styles={{ root: { width: "100%" } }}>
      <Helmet>
        <title>Membros Coopercompany - Libertas</title>
      </Helmet>

      <Stack tokens={{ childrenGap: 15 }}>
        <h2 className="ms-fontSize-24">Membros Coopercompany</h2>
        <Card>
          <ListData
            massCreateActionOptions={{
              modalTitle: `Você deseja criar Termos em massa em ${selectedBU.name}`,
              modalDescription: `A criação de Termos em massa pode demorar um pouco, isso depende da quantidade de cooperados em ${selectedBU.name}.`,
              modalExecuteFunction: async () => {
                const {
                  documentNumbers,
                  fullName,
                  accountType,
                  businessUnitName
                } = urlParams.params;

                await execute({
                  url: `terms/bulk`,
                  method: "POST",
                  bodyObj: Object({
                    businessUnitId: selectedBU.id,
                    documentNumbers,
                    fullName,
                    accountType,
                    businessUnitName
                  }),
                  messages: {
                    error: "Tivemos um problema ao criar termos em massa"
                  },
                  showAlert: true
                });
              }
            }}
            headerText="Membros"
            isLoadingList={isLoadingMembersList}
            items={membersList}
            columns={buildMembersColumns({
              hasActions: canMigrate,
              setSelectedMember
            })}
            menuItems={accountsItems}
            setSelectedItem={setSelectedMember}
            _paginate={handleAccountRequest}
            filters={buildMembersFilters(!hasListPermission)}
            hasFilters
            isDefaultFilterVisible
          />
        </Card>
      </Stack>
    </Stack>
  );
}

export default MembershipMigration;
