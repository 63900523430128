import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class ContractsUploads extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/contracts/uploads${subPaths}`
        : `contracts/uploads${subPaths}`
    );
  }

  download({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "get",
      baseUrl: `${this.baseUrl}/${params.id}/download`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }
}

export default ContractsUploads;
