import { PrimaryButton } from "@fluentui/react/lib/Button";
import * as Yup from "yup";

import FormBuilder from "@/core/libs/form-builder";
import { IMessageBar } from "@/core/libs/message-bar";

const Step2Schema = Yup.object().shape({
  code: Yup.string().required("Obrigatório"),
  password: Yup.string().min(6, "Senha muito curta").required("Obrigatório"),
  confirmPassword: Yup.string()
    .required("Obrigatório")
    .oneOf([Yup.ref("password"), null], "As senhas devem corresponder")
});

interface Step2Props {
  changePassword: (
    values: any,
    { setSubmitting }: { setSubmitting: any }
  ) => void;
  message: IMessageBar | undefined;
  dismissMessage: () => void | void;
  code?: string | string[];
}

function Step2(props: Step2Props): JSX.Element {
  const fields = [
    {
      label: "Código",
      type: "text",
      name: "code",
      value: props.code ? props.code : "",
      description: "Digite o código que você recebeu no e-mail",
      disabled: props.code ? true : false
    },
    { label: "Nova senha", type: "password", name: "password", value: "" },
    {
      label: "Confirmar senha",
      type: "password",
      name: "confirmPassword",
      value: ""
    }
  ];

  const submitForm = (values, { setSubmitting }) => {
    const submitValues = Object.assign({}, values);
    delete submitValues.confirmNewPassword;

    props.changePassword(submitValues, { setSubmitting });
  };

  return (
    <FormBuilder
      fields={fields}
      onSubmit={submitForm}
      message={props.message}
      dismissMessage={props.dismissMessage}
      validationSchema={Step2Schema}
    >
      <PrimaryButton text="Alterar senha" allowDisabledFocus type="submit" />
    </FormBuilder>
  );
}

export default Step2;
