import produce from "immer";

import {
  TagOffersActionsTypes,
  TagOffersReducerActions,
  TagOffersState
} from "../types/TagOffers.reducer.types";

const INITIAL_STATE: TagOffersState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: TagOffersReducerActions
): TagOffersState {
  return produce(state, draft => {
    switch (action.type) {
      case TagOffersActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case TagOffersActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case TagOffersActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case TagOffersActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
