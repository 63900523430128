import {
  IShimmerElement,
  Pivot,
  PivotItem,
  Shimmer,
  ShimmerElementType
} from "@fluentui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { INotificationChannel } from "./interfaces";

import { useApi } from "@/core/libs/api/react-query/useApi";

export const OptOutChannelPivot = ({
  notificationEventId,
  onSelectPivotItem
}: {
  notificationEventId: number;
  onSelectPivotItem: (channel: { id: number; name: string }) => void;
}) => {
  const { execute } = useApi();

  const [notificationChannels, setNotificationChannels] = useState<
    INotificationChannel[]
  >([]);

  const { data, isFetching, isFetched } = useQuery({
    queryKey: [
      `NOTIFICATION-EVENT-${notificationEventId}`,
      { notificationEventId }
    ],
    queryFn: () => fetchData({ notificationEventId }),
    staleTime: Infinity
  });

  const fetchData = async (params: { notificationEventId: number }) => {
    return execute({
      url: "notification-channel",
      notificationMessage: "Buscando canais de notificação",
      params
    });
  };

  useEffect(() => {
    if (data?.data && isFetched) {
      setNotificationChannels(data.data);
      const firstChannel = data.data[0];
      onSelectPivotItem({ id: firstChannel.id, name: firstChannel.name });
    }
  }, [data, isFetched, setNotificationChannels, onSelectPivotItem]);

  const shimmerVerticalElement: IShimmerElement[] = [
    { type: ShimmerElementType.line, height: 20 }
  ];

  return (
    <>
      {isFetching && (
        <Shimmer
          style={{ padding: "0 0 1rem 1rem" }}
          shimmerElements={shimmerVerticalElement}
        />
      )}

      <Pivot
        onLinkClick={i => {
          const channel = { id: +i.props.itemKey, name: i.props.headerText };
          onSelectPivotItem(channel);
        }}
      >
        {notificationChannels?.map(channel => (
          <PivotItem
            key={channel.id}
            headerText={channel?.name}
            itemKey={`${channel?.id}`}
          />
        ))}
      </Pivot>
    </>
  );
};
