import { DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import { Link, useHistory } from "react-router-dom";

import { ButtonContainer, TitleContainer, Wrapper } from "./styles";

import { ITerm } from "@/modules/Terms/interfaces/ITerm.interface";

interface IMembershipValidationModal {
  term: ITerm;
  showTermModal: (status: boolean) => void;
}

export function MembershipValidationModal({
  term,
  showTermModal
}: IMembershipValidationModal): JSX.Element {
  const history = useHistory();

  return (
    <Wrapper>
      <Stack>
        <TitleContainer>
          <h1>Termo em andamento</h1>
        </TitleContainer>
        <p>Para seguir no contrato é necessário estar associado</p>

        <Link to={`/terms/${term?.id}`}>
          <p>
            Termo <strong>#{term?.id}</strong>
          </p>
        </Link>

        <ButtonContainer>
          <DefaultButton
            id="button-cancel"
            onClick={() => showTermModal(false)}
          >
            Cancelar
          </DefaultButton>
          <PrimaryButton
            id="button-cancel"
            onClick={() => history.push(`/terms/${term?.id}`)}
          >
            Ir para o termo
          </PrimaryButton>
        </ButtonContainer>
      </Stack>
    </Wrapper>
  );
}
