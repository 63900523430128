import {
  IButtonStyles,
  IconButton,
  IOverflowSetItemProps,
  Link,
  OverflowSet
} from "@fluentui/react";

export interface IItem {
  key: string;
  name: string;
  onClick: (...args: any[]) => any;
}

interface RowActionsButtonProps {
  items: (id: number) => IItem[];
  id: number;
}

export const RowActionsButton = ({ items, id }: RowActionsButtonProps) => {
  const menu = items(id);
  const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    return (
      <Link styles={{ root: { marginRight: 10 } }} onClick={item.onClick}>
        {item.name}
      </Link>
    );
  };

  const onRenderOverflowButton = (
    // Tipagem conforme documentação do FluentUI
    overflowItems: any[] | undefined
  ): JSX.Element => {
    const buttonStyles: Partial<IButtonStyles> = {
      root: {
        minWidth: 0,
        padding: "0 4px",
        alignSelf: "stretch",
        height: "auto"
      }
    };
    return (
      <IconButton
        title="Ações"
        styles={buttonStyles}
        menuIconProps={{ iconName: "MoreVertical" }}
        menuProps={{ items: overflowItems }}
      />
    );
  };

  return (
    <>
      <OverflowSet
        aria-label="Basic Menu Example"
        overflowItems={menu}
        onRenderOverflowButton={onRenderOverflowButton}
        onRenderItem={onRenderItem}
      />
    </>
  );
};
