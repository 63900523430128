import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  listReportCategoriesFailure,
  listReportCategoriesSuccess
} from "../actions/reportCategories";
import { ReportCategoriesActionsTypes } from "../types/ReportCategories.reducer.types";

import { ReportCategoriesService } from "@/core/libs/api";

const abortController = new AbortController();
const { signal } = abortController;

export function* listReportCategories({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = ReportCategoriesService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });
    if (response.error) {
      throw new Error();
    }

    yield put(listReportCategoriesSuccess({ list: response.data }));
  } catch (error) {
    yield put(listReportCategoriesFailure());
  }
}

export default all([
  takeLatest(ReportCategoriesActionsTypes.LIST_REQUEST, listReportCategories)
]);
