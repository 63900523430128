import { ReportCategoriesActionsTypes } from "../types/ReportCategories.reducer.types";

export function listReportCategoriesRequest(filter = {}, { isAdmin = false }) {
  return {
    type: ReportCategoriesActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listReportCategoriesSuccess({ list }) {
  return {
    type: ReportCategoriesActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listReportCategoriesFailure() {
  return {
    type: ReportCategoriesActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ReportCategoriesActionsTypes.DISMISS_MESSAGE
  };
}
