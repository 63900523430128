import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const NavigationButton = styled.button<{ disabled?: boolean }>`
  background: none;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: ${props =>
    props.disabled
      ? theme.palette.neutralTertiary
      : theme.palette.themeSecondary};
  margin-right: 10px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
