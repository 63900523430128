import { DetailsList, IColumn } from "@fluentui/react/lib/DetailsList";
import { Icon } from "@fluentui/react/lib/Icon";

import { IconSignature, SignatureName } from "./types";

const signatureColumns: IColumn[] = [
  {
    key: "name",
    name: "Nome",
    fieldName: "name",
    data: "string",
    minWidth: 100,
    maxWidth: 400,
    className: "ms-fontSize-16",
    onRender: item => {
      return (
        <>
          <Icon
            iconName={IconSignature[item.icon]}
            title={SignatureName[item.icon]}
            style={{
              color: "#395ca3",
              fontSize: 20,
              cursor: "default",
              marginRight: 10
            }}
          />
          <span>{item.name}</span>
        </>
      );
    }
  },
  {
    key: "contact",
    name: "Contato para assinatura",
    fieldName: "contact",
    data: "string",
    minWidth: 150,
    className: "ms-fontSize-16"
  },
  {
    key: "date",
    name: "Data",
    fieldName: "date",
    minWidth: 85,
    data: "string",
    className: "ms-fontSize-16"
  },
  {
    key: "signature",
    isIconOnly: true,
    name: "signature",
    fieldName: "signature",
    minWidth: 30,
    onRender: item =>
      item.signature === true && (
        <Icon
          iconName="CompletedSolid"
          title="Assinado"
          style={{ color: "#395ca3", fontSize: 20, cursor: "default" }}
        />
      )
  }
];

interface Signature {
  signed_at: string;
}

interface Signers {
  email: string;
  name: string;
  sign_as: string;
  signature?: Signature;
  phone_number?: string;
  auths: Array<string>;
}

interface SignaturesProps {
  document: {
    signers: Signers[];
  };
}

interface SignaturesTableProp {
  signatures: SignaturesProps;
}

const formateDate = (date: string): string => {
  const dateFormatted = new Intl.DateTimeFormat("pt-BR").format(new Date(date));

  return dateFormatted;
};

function SignatureTable({ signatures }: SignaturesTableProp): JSX.Element {
  return (
    <div
      style={{
        height: "100%"
      }}
    >
      {signatures?.document?.signers?.length > 0 ? (
        <DetailsList
          selectionMode={0}
          items={signatures.document.signers.map(signatureItem => ({
            name: signatureItem.name,
            icon: signatureItem.sign_as,
            contact:
              !signatureItem.auths.includes("email") &&
              signatureItem.phone_number
                ? signatureItem.phone_number
                : signatureItem.email,
            date: signatureItem.signature
              ? formateDate(signatureItem.signature.signed_at)
              : "",
            signature: signatureItem?.signature?.signed_at ? true : false
          }))}
          columns={signatureColumns}
          setKey="signatureItems"
          styles={{
            headerWrapper: {
              selectors: {
                "& .ms-DetailsHeader": {
                  paddingTop: 0
                }
              }
            }
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 40
          }}
        >
          <Icon
            iconName="InsertSignatureLine"
            style={{ fontSize: 70, color: "#6b6b6b" }}
          />

          <span style={{ fontSize: 20, color: "#6b6b6b", marginTop: 10 }}>
            Aguardando informações sobre assinaturas
          </span>
        </div>
      )}
    </div>
  );
}

export default SignatureTable;
