import { ProductsCategoriesActionsTypes } from "../types/ProductsCategories.reducer.types";

export function createRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ProductsCategoriesActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createSuccess(message) {
  return {
    type: ProductsCategoriesActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createFailure(message) {
  return {
    type: ProductsCategoriesActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ProductsCategoriesActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editSuccess(message) {
  return {
    type: ProductsCategoriesActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editFailure(message) {
  return {
    type: ProductsCategoriesActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listRequest(filter?, isAdmin?) {
  return {
    type: ProductsCategoriesActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listSuccess({ list }) {
  return {
    type: ProductsCategoriesActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: ProductsCategoriesActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ProductsCategoriesActionsTypes.DISMISS_MESSAGE
  };
}
