import { Label } from "@fluentui/react/lib/Label";
import { Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import { Field, FieldArray } from "formik";

interface FieldsProps {
  config: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  value?: any;
}

export function CreditFeePerServiceFields(props: FieldsProps): JSX.Element {
  const { config, values } = props;

  return (
    <FieldArray
      name={config.name}
      key={config.name}
      render={arrayHelpers => (
        <div style={{ marginTop: 10 }}>
          <Label required={config.required || false}>{config.label}</Label>
          <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
            {values.map((value, index) => {
              return (
                <Stack
                  horizontal
                  tokens={{ childrenGap: 10 }}
                  key={`creditFeePerService[${index}]`}
                >
                  <Field
                    name={`creditFeePerService[${index}].creditFeeValue`}
                    type="number"
                    as={TextField}
                    placeholder="R$"
                    key={`creditFeePerService[${index}].creditFeeValue`}
                    label={value.description}
                  />
                </Stack>
              );
            })}
          </Stack>
        </div>
      )}
    />
  );
}
