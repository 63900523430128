import { Stack } from "@fluentui/react";

import { TagContractQuestions } from "./TagContractQuestion";
import { TagOffer } from "./TagOffer";

import { Plan, PlanOfferItem } from "@/modules/Offers/types/PlansOffers.types";

type PlanOfferContractProps = {
  selectedPlan: Plan;
  selectedCarrier: PlanOfferItem;
};

export function TagOfferContract({
  selectedPlan,
  selectedCarrier
}: PlanOfferContractProps): JSX.Element {
  return (
    <Stack horizontal>
      <TagOffer selectedPlan={selectedPlan} selectedCarrier={selectedCarrier} />
      <TagContractQuestions selectedPlan={selectedPlan} />
    </Stack>
  );
}
