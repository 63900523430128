import { ProductsActionsTypes } from "../types/Products.reducer.types";

export function createRequest(values, { setSubmitting }, closePanel, isAdmin?) {
  return {
    type: ProductsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createSuccess(message) {
  return {
    type: ProductsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createFailure(message) {
  return {
    type: ProductsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editRequest(values, { setSubmitting }, closePanel, isAdmin?) {
  return {
    type: ProductsActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editSuccess(message) {
  return {
    type: ProductsActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editFailure(message) {
  return {
    type: ProductsActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function deleteRequest(values, { setSubmitting }, closePanel, isAdmin?) {
  return {
    type: ProductsActionsTypes.DELETE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function deleteSuccess(message) {
  return {
    type: ProductsActionsTypes.DELETE_SUCCESS,
    payload: { message }
  };
}

export function deleteFailure(message) {
  return {
    type: ProductsActionsTypes.DELETE_FAIL,
    payload: { message }
  };
}

export function listRequest(filter = {}, hasFilter = true, isAdmin?) {
  return {
    type: ProductsActionsTypes.LIST_REQUEST,
    payload: { filter, hasFilter, isAdmin }
  };
}

export function listSuccess({ list }) {
  return {
    type: ProductsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: ProductsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ProductsActionsTypes.DISMISS_MESSAGE
  };
}
