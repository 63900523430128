import { PrimaryButton, TooltipHost } from "@fluentui/react";
import { useMemo } from "react";

import { ActionTypes } from "./enums/ResponseNotifications.enum";
import { Content, NotificationIconContent } from "./styles";

import checkIcon from "@/assets/images/notifications/check.svg";
import chipIcon from "@/assets/images/notifications/chip.svg";
import contractIcon from "@/assets/images/notifications/contract.svg";
import exclamationIcon from "@/assets/images/notifications/exclamation.svg";
import marketingIcon from "@/assets/images/notifications/marketing.svg";
import tagIcon from "@/assets/images/notifications/tag.svg";
import userIcon from "@/assets/images/notifications/user.svg";

interface ResponseNotificationsInternalContentProps {
  notificationEventChannelName: string;
  notificationEventChannelMessage: string;
  notificationCreatedAt: { message: string; date: string } | string;
  notificationId?: number;
  notificationBusinessUnitName: string;
  keyIndex: number;
  iconName: ActionTypes;
  isRead: boolean;
  isResolved: boolean;
  notificationRecipientId: number;
  handleReadNotification: (notificationRecipientId: number) => void;
  serialNumber: string;
  notificationMessage?: string;
}

const ResponseNotificationsInternalContent = ({
  notificationEventChannelName,
  notificationEventChannelMessage,
  notificationCreatedAt,
  notificationId,
  notificationBusinessUnitName,
  keyIndex,
  iconName,
  isRead,
  isResolved,
  notificationRecipientId,
  handleReadNotification,
  serialNumber,
  notificationMessage
}: ResponseNotificationsInternalContentProps) => {
  const icon = useMemo(() => {
    switch (iconName) {
      case ActionTypes.TAG:
        return tagIcon;

      case ActionTypes.CHIP:
        return chipIcon;

      case ActionTypes.USER:
        return userIcon;

      case ActionTypes.MARKETING:
        return marketingIcon;

      case ActionTypes.CONTRACT_FLOW:
        return contractIcon;

      case ActionTypes.TERM_FLOW:
        return contractIcon;

      default:
        return exclamationIcon;
    }
  }, [iconName]);

  const isTerm = useMemo(() => {
    return iconName === ActionTypes.TERM_FLOW;
  }, [iconName]);

  const handleWithEventChannelMessage = (message: string) => {
    const replacements = {
      "{{contractId}}": notificationId?.toString(),
      "{{serialNumber}}": serialNumber,
      "{{termId}}": notificationId?.toString(),
      "{{message}}": notificationMessage
    };

    const regex = new RegExp(Object.keys(replacements).join("|"), "gi");
    const updatedMessage = message.replace(regex, match => replacements[match]);

    return updatedMessage;
  };

  const tooltipContent = useMemo(() => {
    if (typeof notificationCreatedAt === "string") {
      return null;
    }
    return notificationCreatedAt.date;
  }, [notificationCreatedAt]);

  const createdAtMessage = useMemo(() => {
    if (typeof notificationCreatedAt === "object") {
      return notificationCreatedAt.message;
    }
    return notificationCreatedAt;
  }, [notificationCreatedAt]);

  const url = useMemo(() => {
    if (isTerm) {
      return `/terms/${notificationId}`;
    }
    return `/contracts/${notificationId}`;
  }, [isTerm, notificationId]);

  const buttonText = useMemo(() => {
    if (isTerm) {
      return "Ir para termo";
    }
    return "Ir para contrato";
  }, [isTerm]);

  return (
    <Content key={`notification-${keyIndex}`} isResolved={isResolved}>
      <NotificationIconContent isResolved={isResolved}>
        {!isRead && <span className="notification-status-icon" />}
        {isResolved && (
          <img
            className="notification-status-check-icon"
            src={checkIcon}
            alt="isRead"
          />
        )}
        <img
          src={icon}
          alt="icon"
          className="notification-icon"
          title={`Identificador da notificação: ${notificationRecipientId}`}
        />
      </NotificationIconContent>

      <div className="notification-message">
        <div className="response">
          <span>{notificationEventChannelName}</span>
          <span>
            {handleWithEventChannelMessage(notificationEventChannelMessage)}
          </span>
        </div>
        <div className="response-date">
          <TooltipHost content={tooltipContent}>
            <small>{createdAtMessage}</small>
          </TooltipHost>
        </div>
      </div>

      {notificationId && (
        <div
          className="secondary-content"
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center"
          }}
        >
          <span style={{ width: "150px" }}>
            Contrato <strong>{notificationId}</strong> em{" "}
            <span className="secondary-content-bu">
              {notificationBusinessUnitName}
            </span>
          </span>
          <PrimaryButton
            target="_blank"
            href={url}
            onClick={() => {
              if (!isRead) {
                handleReadNotification(notificationRecipientId);
              }
            }}
            style={{
              height: "30px",
              minHeight: "30px",
              width: "80%",
              marginTop: "8px"
            }}
          >
            {buttonText}
          </PrimaryButton>
        </div>
      )}
    </Content>
  );
};

export default ResponseNotificationsInternalContent;
