import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class Contracts extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/contracts${subPaths}` : `contracts${subPaths}`);
  }

  downloadContract({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/${params.id}/pdf`
    };

    return makeRequest(options);
  }

  createLine({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/lines`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  deleteItem({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "DELETE",
      baseUrl: `${this.baseUrl}/item/${params.id}`
    };

    return makeRequest(options);
  }

  replaceItem({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/items/${params.id}/replacements`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  listInventoryByProductCategory({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/items/inventory`
    };
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  statusItem({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "post",
      baseUrl: `${this.baseUrl}/items/${params.id}/status`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  listCarriers({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/carriers`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  listCategories({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "get",
      baseUrl: `${this.baseUrl}/categories`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  listLines({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "get",
      baseUrl: `${this.baseUrl}/lines`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  listServices({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "get",
      baseUrl: `${this.baseUrl}/services`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  listOffers({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "get",
      baseUrl: `${this.baseUrl}/offers`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  process({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "post",
      baseUrl: `${this.baseUrl}/${params.id}/process`
    };

    return makeRequest(options);
  }

  saveItem({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "post",
      baseUrl: `${this.baseUrl}/${params.id}/item`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  saveItemInNewContract({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "post",
      baseUrl: `${this.baseUrl}/item`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  status({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "post",
      baseUrl: `${this.baseUrl}/${params.id}/status`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  upload({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/${params.id}/uploads`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  listShippings({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "get",
      baseUrl: `${this.baseUrl}/${params.id}/shippings`
    };

    return makeRequest(options);
  }

  getModel({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/models/${params.id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  getProductsList({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/products`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  getOccurrences({ id, signal }: IApi) {
    const options: any = {
      signal,
      method: "get",
      baseUrl: `${this.baseUrl}/${id}/ocurrences`
    };

    return makeRequest(options);
  }

  resendSignatures({ id, signal, params }: IApi) {
    const options: any = {
      signal,
      method: "post",
      baseUrl: `${this.baseUrl}/${id}/resend-signatures`
    };

    if (params?.body) options.bodyObj = params.body;

    return makeRequest(options);
  }

  renewalItems({ id, signal, params }: IApi) {
    const options: any = {
      signal,
      method: "post",
      baseUrl: `${this.baseUrl}/${id}/renewal`
    };

    if (params?.body) options.bodyObj = params.body;

    return makeRequest(options);
  }

  getContractsThatCannotBeCanceled({ signal }: IApi) {
    const options: any = {
      signal,
      method: "get",
      baseUrl: `${this.baseUrl}/cannot-be-canceled`
    };

    return makeRequest(options);
  }
}

export default Contracts;
