export enum TranslatedMaritalStatus {
  SINGLE = "Solteiro(a)",
  MARRIED = "Casado(a)",
  DIVORCED = "Divorciado(a)",
  WIDOWED = "Viúvo(a)",
  RATHER_NOT_SAY = "Prefere não informar",
  OTHER = "Outro(a)"
}

export enum TranslatedContactType {
  EMAIL = "Email",
  PHONE_NUMBER = "Telefone",
  WHATSAPP = "Whatsapp"
}

export enum AccountType {
  PHYSICAL = "PHYSICAL",
  LEGAL = "LEGAL"
}
