import produce from "immer";

import {
  LinesOccurrencesActionsTypes,
  LinesOccurrencesReducerActions,
  LinesOccurrencesState
} from "../types/LinesOccurrences.reducer.types";

const INITIAL_STATE: LinesOccurrencesState = {
  list: undefined,
  isLoadingList: false
};

export default function reducer(
  state = INITIAL_STATE,
  action: LinesOccurrencesReducerActions
): LinesOccurrencesState {
  return produce(state, draft => {
    switch (action.type) {
      case LinesOccurrencesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case LinesOccurrencesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case LinesOccurrencesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
