import { User } from "../../types/Users.types";

import {
  BusinessUnitsService,
  ElectronicSignaturesService,
  EmailTemplateGroupService,
  ModelsAttachmentService,
  ModelsGroupsService,
  OrganizationsService,
  PricingGroupsService,
  SalesTeamsService,
  ServiceProvidersService,
  ShippingsGroupsService,
  UsersService
} from "@/core/libs/api";

const serviceEmailTemplateGroup = EmailTemplateGroupService(undefined, true);
const serviceOrganizations = OrganizationsService(undefined, true);
const serviceSalesTeams = SalesTeamsService(undefined, true);
const serviceServiceProviders = ServiceProvidersService(undefined, true);
const serviceElectronicSignatures = ElectronicSignaturesService(
  undefined,
  true
);
const serviceShippingsGroups = ShippingsGroupsService(undefined, true);
const serviceModelsGroups = ModelsGroupsService(undefined, true);
const serviceBusinessUnit = BusinessUnitsService(undefined, true);
const servicePricingGroups = PricingGroupsService(undefined, true);
const serviceModelAttachmentService = ModelsAttachmentService(undefined, true);
const serviceUsers = UsersService("", false);

const { signal } = new AbortController();

export const getAndSetEmailTemplateGroups = async ({
  setEmailTemplateGroupOptions
}) => {
  const params = {};
  const result = await serviceEmailTemplateGroup.list({ params, signal });

  if (result.data.items) {
    const formattedOrganization = result.data.items.map(emailGroup => {
      return { key: emailGroup.id, text: emailGroup.name };
    });
    setEmailTemplateGroupOptions(formattedOrganization);
  }
};

export const getAndSetOrganizations = async ({ setOrganizationOptions }) => {
  const params = {};
  const result = await serviceOrganizations.list({ params, signal });

  if (result.data.items) {
    const formattedOrganization = result.data.items.map(org => {
      return { key: org.id, text: org.name };
    });

    setOrganizationOptions(formattedOrganization);
  }
};

export const getAndSetSalesTeams = async ({ setSalesTeamsOptions }) => {
  const params = {};
  const result = await serviceSalesTeams.list({ params, signal });

  if (result.data) {
    const formattedSalesTeams = result.data.items.map(org => {
      return { key: org.id, text: org.name };
    });

    setSalesTeamsOptions(formattedSalesTeams);
  }
};

export const getAndSetServiceProviders = async ({
  setServiceProvidersOptions
}) => {
  const params = {};
  const result = await serviceServiceProviders.list({ params, signal });

  if (result.data) {
    const formattedServiceProviders = result.data.items.map(org => {
      return { key: org.id, text: org.name };
    });

    setServiceProvidersOptions(formattedServiceProviders);
  }
};

export const getAndSetElectronicSignatures = async ({
  setElectronicSignaturesOptions
}) => {
  const params = {};
  const result = await serviceElectronicSignatures.list({ params, signal });

  if (result.data) {
    const formattedElectronicSignatures = result.data.items.map(org => {
      return { key: org.id, text: org.name };
    });

    setElectronicSignaturesOptions(formattedElectronicSignatures);
  }
};

export const getAndSetShippingsGroups = async ({
  setShippingsGroupsOptions
}) => {
  const params = {};
  const result = await serviceShippingsGroups.list({ params, signal });
  if (result.data) {
    const formattedShippingsGroups = result.data.items.map(group => {
      return { key: group.id, text: group.name };
    });

    setShippingsGroupsOptions(formattedShippingsGroups);
  }
};

export const getAndSetModelGroups = async ({
  setModelServiceGroupsOptions
}) => {
  const params = { query: { limit: 100 } };
  const result = await serviceModelsGroups.list({ params, signal });
  if (result.data) {
    const formattedModelGroups = result.data.items.map(group => {
      return { key: group.id, text: group.name };
    });

    setModelServiceGroupsOptions(formattedModelGroups);
  }
};

export const getAndSetPricingGroups = async ({ setPricingGroupsOptions }) => {
  const params = { query: { limit: 100 } };
  const result = await servicePricingGroups.list({
    params,
    signal
  });
  if (result.data) {
    const formattedPricingGroups = result.data.items.map(group => {
      return { key: group.id, text: group.name };
    });

    setPricingGroupsOptions(formattedPricingGroups);
  }
};

export const getAndSetModelAttachmentService = async ({
  setModelAttachmentServiceOptions
}) => {
  const params = { query: { limit: 100 } };
  const result = await serviceModelAttachmentService.list({
    params,
    signal
  });
  if (result.data) {
    const formattedModelAttachmentService = result.data.items.map(group => {
      return { key: group.id, text: group.name };
    });

    setModelAttachmentServiceOptions(formattedModelAttachmentService);
  }
};

export const getAndSetParents = async ({
  organizationId,
  isAutonomous,
  setParentsOptions,
  setParentsMetaProps,
  setIsLoadingParentsOptions,
  query
}) => {
  setIsLoadingParentsOptions(true);
  const result = await serviceBusinessUnit.list({
    params: {
      query: {
        limit: 999,
        organization: organizationId,
        isAutonomous,
        ...query
      }
    },
    signal
  });

  if (result.data) {
    const options = result.data.items.map(option => {
      return {
        key: option.id,
        text: `${option.name} ${
          option.parent?.name ? `(${option.parent.name})` : ""
        }`
      };
    });

    setParentsMetaProps(result.data.meta);
    if (setParentsOptions) setParentsOptions(options);
  }
  setIsLoadingParentsOptions(false);
};

export const getAndSetBusinessUnit = async ({ buID, setBusinessUnit }) => {
  const response = await serviceBusinessUnit.get({ id: buID, signal });

  if (response.data) {
    if (response.data.settings !== null) {
      response.data.settings = Object.entries(response.data.settings).map(
        ([key, value]) => {
          if (typeof value !== "string" && typeof value !== "number") {
            return { configName: key, value: JSON.stringify(value) };
          }
          return { configName: key, value: value };
        }
      );
    }

    if (response.data.primaryAddress !== null) {
      response.data.primaryAddress = [response.data.primaryAddress];
    } else {
      response.data.primaryAddress = [];
    }

    setBusinessUnit(response.data);
  }
};

export const getAndSetUsers = async ({ setUserFilterOptions }) => {
  const response = await serviceUsers.list({
    signal
  });

  const formattedUser = response?.data.map((user: User) => {
    return {
      key: user.id,
      text: user.fullName
    };
  });

  setUserFilterOptions(formattedUser);
};
