import { FocusTrapZone } from "@fluentui/react/lib/FocusTrapZone";
import { createContext, ReactNode, useState } from "react";

import { Header } from "./components";
import { StyledContentWrapper, StyledMainLayout } from "./styles";

export const MainLayoutContext = createContext(undefined);

type LayoutProps = {
  children: ReactNode;
};

function Layout({ children }: LayoutProps): JSX.Element {
  const [isFocusZoneDisabled, setIsFocusZoneDisabled] = useState(false);

  const toggleFocusZoneState = () =>
    setIsFocusZoneDisabled(
      previousFocusZoneDisabled => !previousFocusZoneDisabled
    );

  return (
    <FocusTrapZone disabled={isFocusZoneDisabled} style={{ width: "100%" }}>
      <StyledMainLayout>
        <Header />
        <StyledContentWrapper>
          <MainLayoutContext.Provider
            value={{ toggleFocusZoneState, isFocusZoneDisabled }}
          >
            {children}
          </MainLayoutContext.Provider>
        </StyledContentWrapper>
      </StyledMainLayout>
    </FocusTrapZone>
  );
}

export default Layout;
