import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StringifiableRecord } from "query-string";

import { ExecuteData, useApi } from "../../useApi";

import { IUseGetAccountsProps } from "./useGetAccounts.types";

import { useAuth } from "@/core/libs/use-auth";
import { useSelectedBu } from "@/hooks/useSelectedBu";

const ACCOUNTS_QUERY_TOKEN = "accounts";

export const useGetAccounts = ({ params }: IUseGetAccountsProps) => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const accountsQueryClient = useQueryClient();

  const { selectedBU } = useSelectedBu();

  const paramValues = {
    businessActionId: selectedBU.id,
    all: true,
    page: 1,
    ...params
  };

  const fetchData = async (params: StringifiableRecord) => {
    const data: ExecuteData = {
      params,
      url: "accounts",
      messages: {
        error: "Tivemos um problema ao carregar os cooperados"
      }
    };

    return execute(data);
  };

  const { data, isFetching } = useQuery({
    queryKey: [ACCOUNTS_QUERY_TOKEN, paramValues],
    queryFn: () => fetchData(paramValues),
    enabled: isAuthenticated,
    staleTime: 120000,
    keepPreviousData: true
  });

  const invalidateQuery = async () => {
    accountsQueryClient.invalidateQueries({ queryKey: [ACCOUNTS_QUERY_TOKEN] });
  };

  return {
    accountsList: data?.data,
    isLoadingAccountsList: isFetching,
    invalidateQuery
  };
};
