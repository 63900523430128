import Api from "./api";

class EmailTemplateGroup extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/email-template-group${subPaths}`
        : `email-template-group${subPaths}`
    );
  }
}

export default EmailTemplateGroup;
