import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import { FetchResponse } from "../../../utils/fetchData";
import { useApi } from "../../useApi";

import { SelectedOption } from "@/components/Shared/Layouts/components/ResponseNotifications/enums/SelectedOption.enum";
import { IResponseNotifications } from "@/components/Shared/Layouts/components/ResponseNotifications/ResponseNotifications.interface";
import { useAuth } from "@/core/libs/use-auth";

const NOTIFICATION_RECIPIENTS_QUERY_TOKEN = Symbol("notification-recipients");

export const useGetNotifications = () => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const notificationsQueryClient = useQueryClient();

  const [needRefetch, setNeedRefetch] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectedOption>(
    SelectedOption.OPENED
  );

  const isNotificationOpen = selectedOption === SelectedOption.OPENED;

  const fetchData = async ({ pageParam: page = 1, notificationOpen }) => {
    if (isAuthenticated) {
      return execute({
        url: `notification-recipients`,
        params: { page, notificationOpen },
        messages: { error: "Tivemos um problema ao carregar notificações!" }
      });
    } else {
      return null;
    }
  };

  const {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetched
  } = useInfiniteQuery({
    queryKey: [NOTIFICATION_RECIPIENTS_QUERY_TOKEN],
    queryFn: ({ pageParam }) => {
      return fetchData({ pageParam, notificationOpen: isNotificationOpen });
    },
    enabled: isAuthenticated,
    staleTime: Infinity,
    getNextPageParam: (lastPage: FetchResponse) => {
      const totalPages = lastPage?.data?.meta.totalPages;
      const currentPage = lastPage?.data?.meta.currentPage;

      if (currentPage >= totalPages) return undefined;

      return currentPage + 1;
    }
  });

  const resetQueryData = useCallback(() => {
    notificationsQueryClient.setQueryData(
      [NOTIFICATION_RECIPIENTS_QUERY_TOKEN],
      { pages: [] }
    );
  }, [notificationsQueryClient]);

  useEffect(() => {
    // Abaixo, evitando ser executado no primeiro carregamento
    if (isFetched) {
      if (data.pages.length > 1) {
        notificationsQueryClient.setQueryData(
          [NOTIFICATION_RECIPIENTS_QUERY_TOKEN],
          { pages: [] }
        );
      } else {
        notificationsQueryClient.fetchInfiniteQuery({
          queryKey: [NOTIFICATION_RECIPIENTS_QUERY_TOKEN],
          queryFn: ({ pageParam }) => {
            return fetchData({
              pageParam,
              notificationOpen: isNotificationOpen
            });
          }
        });
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (needRefetch === true) {
      resetQueryData();
    }
  }, [needRefetch, resetQueryData]);

  const invalidateQuery = () => {
    resetQueryData();

    notificationsQueryClient.refetchQueries({
      queryKey: [NOTIFICATION_RECIPIENTS_QUERY_TOKEN]
    });
  };

  let items = [];
  let meta = {};

  if (data?.pages.length > 0 && data?.pages[0] !== undefined) {
    items = data?.pages.flatMap(page => page?.data?.items);
    meta = data?.pages[0]?.data?.meta;
  }

  const object = { items, meta };

  return {
    listOfNotifications: items ? (object as IResponseNotifications) : null,
    fetchData: fetchNextPage,
    isLoading: isFetching,
    isLoadingNextPage: isFetchingNextPage,
    invalidateQuery,
    selectedOption,
    setSelectedOption,
    hasNextPage,

    setNeedRefetch,
    needRefetch
  };
};
