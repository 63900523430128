import { Icon, Stack } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";

import { SidebarMenuExpandableProps } from "../../types/Sidebar.types";
import { StyledGrowSidebar, StyledSidebar } from "../Sidebar.styles";

import {
  ExpandSidebarMenu,
  RetractSidebarMenu
} from "@/common/actions/sidebarMenu";
import { RootState } from "@/core/store/rootReducer";

export function SidebarMenuExpandable({
  theme,
  MainContent,
  FooterContent,
  mainLinks,
  footerLinks,
  maxPathNameRange
}: SidebarMenuExpandableProps): JSX.Element {
  const { isMenuExpanded } = useSelector(
    (state: RootState) => state.sidebarMenu
  );
  const dispatch = useDispatch();

  return (
    <div className="ms-hiddenMdDown">
      <StyledSidebar
        borderColor={theme.palette.neutralLight}
        isMenuExpanded={isMenuExpanded}
        isDev={import.meta.env.VITE_IS_SANDBOX === "true"}
      >
        <StyledGrowSidebar>
          <MainContent
            theme={theme}
            links={mainLinks}
            isMenuExpanded={isMenuExpanded}
            maxPathNameRange={maxPathNameRange}
          />
        </StyledGrowSidebar>

        <Stack horizontal={isMenuExpanded} verticalAlign="center">
          <Stack.Item grow order={isMenuExpanded ? 0 : 1} align="center">
            <FooterContent
              theme={theme}
              links={footerLinks}
              isMenuExpanded={isMenuExpanded}
            />
          </Stack.Item>

          <Stack.Item align="center">
            {isMenuExpanded && (
              <Icon
                iconName="DoubleChevronLeftMed"
                styles={{
                  root: {
                    fontSize: 16,
                    cursor: "pointer",
                    padding: 12,
                    color: "#335393"
                  }
                }}
                onClick={() => dispatch(RetractSidebarMenu())}
              />
            )}

            {!isMenuExpanded && (
              <Icon
                iconName="DoubleChevronLeftMedMirrored"
                styles={{
                  root: {
                    fontSize: 16,
                    cursor: "pointer",
                    padding: 12,
                    color: "#335393"
                  }
                }}
                onClick={() => dispatch(ExpandSidebarMenu())}
              />
            )}
          </Stack.Item>
        </Stack>
      </StyledSidebar>
    </div>
  );
}
