import { ListOfPricingGroups } from "./PricingGroups.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum PricingGroupsActionsTypes {
  CREATE_REQUEST = "pricing-groups/CREATE_REQUEST",
  CREATE_FAIL = "pricing-groups/CREATE_FAIL",
  CREATE_SUCCESS = "pricing-groups/CREATE_SUCCESS",
  DISMISS_MESSAGE = "pricing-groups/DISMISS_MESSAGE",
  EDIT_REQUEST = "pricing-groups/EDIT_REQUEST",
  EDIT_FAIL = "pricing-groups/EDIT_FAIL",
  EDIT_SUCCESS = "pricing-groups/EDIT_SUCCESS",
  LIST_REQUEST = "pricing-groups/LIST_REQUEST",
  LIST_FAIL = "pricing-groups/LIST_FAIL",
  LIST_SUCCESS = "pricing-groups/LIST_SUCCESS"
}

export interface PricingGroupsState {
  isLoadingList: boolean;
  list?: ListOfPricingGroups;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: PricingGroupsActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: PricingGroupsActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: PricingGroupsActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: PricingGroupsActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: PricingGroupsActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: PricingGroupsActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: PricingGroupsActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: PricingGroupsActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: PricingGroupsActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: PricingGroupsActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfPricingGroups;
  };
}

export type PricingGroupsReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
