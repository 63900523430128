import { ProgressIndicator, Spinner } from "@fluentui/react";
import * as queryString from "query-string";
import { Suspense, lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { useGetProfile } from "../libs/api/react-query";

import AccountsRoutes from "./AccountsRoutes";
import ContractsRoutes from "./ContractsRoutes";
import MembershipRoute from "./MembershipRoute";
import TermsRoute from "./TermsRoutes";

import { PermissionsScope } from "@/common/types/permissions";
import Layout from "@/components/Shared/Layouts/Layout";
import MainLayout from "@/components/Shared/Layouts/MainLayout";
import { useOrganization } from "@/hooks/useOrganization";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { UrlFilterProvider } from "@/hooks/useUrlFIlter";
import BusinessMaterial from "@/modules/BusinessMaterial";
import News from "@/modules/News";
import Offers from "@/modules/Offers";
import Promotions from "@/modules/Promotions";
import ResultsPanel from "@/modules/ResultsPanel";
import UserProfile from "@/modules/User";
import { getProfileRequest } from "@/modules/User/actions/profile";

const Settings = lazy(() => import("@/modules/Settings"));

export default function RoutesLayout(): JSX.Element {
  const redirect = queryString.parseUrl(window.location.href).query;
  const { selectedBU } = useSelectedBu();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfileRequest());
  }, [dispatch]);

  const {
    setOrganizations,
    organizations,
    openOrganizationSelection,
    organizationSelected
  } = useOrganization();

  const userProfileQuery = useGetProfile();
  const user = userProfileQuery.data?.data;

  useEffect(() => {
    if (user && !organizations) {
      setOrganizations(user?.organizations);
    }
  }, [user, setOrganizations, organizations]);

  useEffect(() => {
    const localOrganization = localStorage.getItem("organization");
    const loginLocations = ["/login", "/recover", "/set-password"];

    if (!localOrganization && !loginLocations.includes(location.pathname)) {
      if (organizations) openOrganizationSelection();
      if (!organizations && user?.organizations)
        openOrganizationSelection(user?.organizations);

      if (!organizationSelected && organizations) {
        openOrganizationSelection();
      }
    }
  }, [organizations, openOrganizationSelection, user, organizationSelected]);

  const progressElement = (
    <ProgressIndicator
      styles={{
        itemProgress: {
          padding: 0,
          position: "absolute",
          left: 0,
          right: 0,
          zIndex: 999
        }
      }}
    />
  );

  const progressSpinner = (
    <Spinner
      label="Carregando o Usuário..."
      styles={{
        root: {
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 999
        },
        label: {
          fontSize: "1rem",
          fontWeight: 600
        }
      }}
    />
  );

  return (
    <Layout>
      <UrlFilterProvider>
        <Suspense fallback={progressElement}>
          {!user || !user.role?.permissions ? (
            progressSpinner
          ) : (
            <Switch>
              <Redirect
                from="/login"
                to={`${redirect.origin ? redirect.origin : ""}`}
                exact
              />
              {user.role.permissions?.[PermissionsScope.ADMIN] && (
                <Route path="/settings">
                  <Settings permissions={user.role.permissions} />
                </Route>
              )}

              <Route>
                <MainLayout>
                  <Switch>
                    <Route path="/accounts" component={AccountsRoutes} />
                    <Route path="/members" component={MembershipRoute} />
                    <Route path="/terms" component={TermsRoute} />
                    <Route path="/contracts" component={ContractsRoutes} />
                    <Route path="/user/profile" component={UserProfile} />
                    <Route path="/benefits" component={Offers} />
                    <Route exact path="/promotions" component={Promotions} />
                    <Route exact path="/news" component={News} />
                    <Route
                      exact
                      path="/business-material"
                      component={BusinessMaterial}
                    />
                    <Route
                      exact
                      path="/results-panel"
                      component={ResultsPanel}
                    />

                    <Route path="*">
                      <Redirect
                        to={{
                          pathname: "/benefits"
                        }}
                      />
                    </Route>
                  </Switch>
                </MainLayout>
              </Route>
            </Switch>
          )}
        </Suspense>
      </UrlFilterProvider>
    </Layout>
  );
}
