import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class Products extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/products${subPaths}` : `products${subPaths}`);

    this.createProduct = this.createProduct.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.searchProduct = this.searchProduct.bind(this);
  }

  searchProduct({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/search`
    };

    if (params?.body) options.bodyObj = params.body;
    if (params?.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  createProduct({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: this.baseUrl
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  editProduct({ params, signal }: IApi) {
    let id = null;
    if (params.body.id && !params.id) {
      id = params.body.id;
    } else {
      id = params.id;
    }
    const options: any = {
      signal,
      method: "PATCH",
      baseUrl: `${this.baseUrl}/${id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  deleteProduct({ params, signal }: IApi) {
    let id = null;
    if (params.body.id && !params.id) {
      id = params.body.id;
    } else {
      id = params.id;
    }
    const options: any = {
      signal,
      method: "DELETE",
      baseUrl: `${this.baseUrl}/${id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  getProducts({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}`
    };

    if (params?.body) options.bodyObj = params.body;
    if (params?.query) options.queryParams = params.query;

    return makeRequest(options);
  }
}

export default Products;
