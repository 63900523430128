import Api from "./api";

class ModelsAttachment extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/contracts/model/attachments${subPaths}`
        : `contracts/model/attachments${subPaths}`
    );
  }
}

export default ModelsAttachment;
