import { Dropdown, IDropdownOption } from "@fluentui/react";

import { DefaultProps } from "./types";

interface ContractCarrierProps extends DefaultProps {
  setCarrier: (value) => void;
  setAllPlansAndOffers: (allPlans) => void;
  carriers: IDropdownOption[];
  isOperationSelected: boolean;
  selectedCarrier: string | number;
}

export function ContractCarrier({
  carriers,
  selectedCarrier,
  isOperationSelected,
  setCarrier,
  setAllPlansAndOffers,
  setFieldValue
}: ContractCarrierProps): JSX.Element {
  return (
    <Dropdown
      id="select-carriers"
      label="Selecione uma operadora"
      placeholder={
        !isOperationSelected
          ? "Por favor selecione uma operação"
          : carriers?.length > 0
          ? "Selecione uma operadora"
          : "Nenhuma operadora disponível"
      }
      options={carriers}
      selectedKey={selectedCarrier}
      disabled={!isOperationSelected || carriers?.length < 1}
      required
      onChange={(_, item) => {
        setFieldValue("offerPrice", "");
        setFieldValue("line", "");
        setFieldValue("plan", undefined);
        setFieldValue("additionalInfo", undefined);
        setCarrier(item.key);
        setAllPlansAndOffers(item.data);
      }}
    />
  );
}
