import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { useApi } from "../../useApi";

import { formatProductOffers } from "./format-products";

import { useAuth } from "@/core/libs/use-auth";
import { useSelectedBu } from "@/hooks/useSelectedBu";

export const useGetDashboardProducts = (filters?: object) => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const { selectedBU } = useSelectedBu();
  const productsOffersQueryClient = useQueryClient();

  useEffect(() => {
    productsOffersQueryClient.invalidateQueries(["products"]);
  }, [selectedBU]);

  const context = useQuery(
    ["products"],
    () => {
      const selectedBu = JSON.parse(localStorage.getItem("selectedBU"));
      const bu = selectedBu.id;

      return execute({
        url: `dashboards/products?query=true`,
        notificationMessage: "Buscando products dashboard na página inicial",
        params: {
          businessActionId: bu,
          limit: 10,
          page: 1,
          ...filters
        }
      });
    },
    {
      staleTime: Infinity,
      enabled: isAuthenticated
    }
  );

  const isLoadingProductsOffersList = context.isLoading;
  const formatData = formatProductOffers(context.data?.data);

  return { ...formatData, isLoadingProductsOffersList };
};
