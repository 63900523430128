import { IContact } from "../interfaces/ITerm.interface";

import { validateDocument } from "./validateDocument";
import { validateFieldData } from "./validateFieldDate";

import { Account } from "@/components/Contracts/contract.interface";
import { ContactType } from "@/components/Contracts/Step5/Signature/signature.interface";
import {
  AccountDocumentType,
  AccountType,
  Document
} from "@/modules/Accounts/types";
import { TranslatedMaritalStatus } from "@/modules/Contracts/enums";

const birthDateFormat = /^\d{4}-\d{2}-\d{2}$/;

const buildContactsFields = (
  contacts: IContact[]
): { value: string; title: string }[] => {
  const contactFields = contacts.map(doc => {
    return {
      value: doc.contact,
      title: doc.contactType
    };
  });

  const whatsAppContact = contacts.find(
    ({ contactType }) => contactType === ContactType.WHATSAPP
  );

  const phoneNumberContact = contacts.find(
    ({ contactType }) => contactType === ContactType.PHONE_NUMBER
  );

  if (!whatsAppContact) {
    contactFields.push({
      value: "",
      title: ContactType.WHATSAPP
    });
  }

  if (!phoneNumberContact) {
    contactFields.push({ value: "", title: ContactType.PHONE_NUMBER });
  }

  return contactFields;
};

const buildDocumentFields = (documents: Document[], accountType: string) => {
  const documentFields = documents?.map(doc => {
    return {
      title: doc?.documentType,
      value: validateDocument(doc?.documentNumber, doc?.documentType)
        ? doc?.documentNumber
        : ""
    };
  });

  if (accountType === AccountType.PHYSICAL) {
    const hasCPF = documentFields.find(
      doc => doc.title === AccountDocumentType.CPF
    );
    const hasRG = documentFields.find(
      doc => doc.title === AccountDocumentType.RG
    );

    if (!hasCPF) {
      documentFields.push({
        title: AccountDocumentType.CPF,
        value: ""
      });
    } else if (!hasRG) {
      documentFields.push({
        title: AccountDocumentType.RG,
        value: ""
      });
    }
  } else {
    if (documentFields.length === 0) {
      documentFields.push({
        title: AccountDocumentType.CNPJ,
        value: ""
      });
    }
  }

  return documentFields;
};

export const createTermAccountData = (accountInfo: Account) => {
  if (accountInfo?.accountType === AccountType.LEGAL) {
    return {
      Nome: validateFieldData("string", accountInfo?.fullName),
      Documentos: buildDocumentFields(
        accountInfo?.documents,
        accountInfo.accountType
      ),
      Endereço: accountInfo?.addresses.map(account => {
        return [
          {
            title: "",
            value: validateFieldData(
              "string",
              `${account?.street}, ${account?.number ?? ""}`
            )
          },
          {
            title: "",
            value: validateFieldData("string", account?.neighborhood)
          },
          {
            title: "",
            value: validateFieldData("string", account?.city)
          },
          {
            title: "",
            value: validateFieldData("string", account?.zipCode)
          }
        ];
      }),
      "Dados Bancários": accountInfo?.checkingAccounts.map(account => {
        return [
          {
            title: "Agencia",
            value: validateFieldData("string", account?.agencyNumber)
          },
          {
            title: "Conta Bancária",
            value: validateFieldData("string", account?.accountNumber)
          }
        ];
      }),
      "Representantes Legais": accountInfo?.legalRepresentatives.flatMap(
        (account, index, array) => {
          return [
            {
              title: "Nome",
              value: validateFieldData("string", account?.fullName)
            },
            {
              title: "Email",
              value: validateFieldData("string", account?.email)
            },
            {
              title: "Whatsapp",
              value: validateFieldData("string", account?.whatsapp)
            },
            ...(index < array.length - 1 ? [{ separator: true }] : [])
          ];
        }
      )
    };
  }
  return {
    Nome: validateFieldData("string", accountInfo?.fullName),
    "Data de Nascimento": birthDateFormat.test(accountInfo?.birthDate)
      ? accountInfo?.birthDate
      : "",
    Documentos: buildDocumentFields(
      accountInfo?.documents,
      accountInfo.accountType
    ),
    Endereço: accountInfo?.addresses.map(account => {
      return [
        {
          title: "",
          value: validateFieldData(
            "string",
            `${account.street}, ${account?.number ?? ""}`
          )
        },
        {
          title: "",
          value: validateFieldData("string", account?.neighborhood)
        },
        {
          title: "",
          value: validateFieldData("string", account?.city)
        },
        {
          title: "",
          value: validateFieldData("string", account?.zipCode)
        }
      ];
    }),
    "Estado Civil": TranslatedMaritalStatus[accountInfo?.maritalStatus?.name],
    Ocupação: validateFieldData("string", accountInfo?.occupation),
    Contato: buildContactsFields(accountInfo?.contacts),
    "Dados Bancários": accountInfo?.checkingAccounts?.map(account => {
      return [
        {
          title: "Agencia",
          value: validateFieldData("string", account?.agencyNumber)
        },
        {
          title: "Conta Bancária",
          value: validateFieldData("string", account?.accountNumber)
        }
      ];
    })
  };
};
