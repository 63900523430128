import { PrimaryButton, Stack } from "@fluentui/react";
import * as Yup from "yup";

import FormBuilder from "@/core/libs/form-builder";
import { Profile } from "@/modules/User/types";

interface SecurityProps {
  dismissMessage: any;
  edit: (values, { setSubmitting }) => void;
  message: any;
  profile: Profile;
}

const schema = Yup.object().shape({
  currentPassword: Yup.string()
    .required("Obrigatório")
    .min(6, "Senha muito curta"),
  newPassword: Yup.string().required("Obrigatório").min(6, "Senha muito curta"),
  confirmNewPassword: Yup.string()
    .required("Obrigatório")
    .oneOf([Yup.ref("newPassword"), null], "As senhas devem corresponder")
});

export function SecurityComponent({
  profile,
  edit,
  message,
  dismissMessage
}: SecurityProps): JSX.Element {
  const cleanMessage = () => {
    dismissMessage();
  };

  const submitForm = (values, { setSubmitting }) => {
    cleanMessage();
    const submitValues = Object.assign({}, values);
    delete submitValues.confirmNewPassword;

    edit(submitValues, { setSubmitting });
  };

  return (
    <div style={{ width: "20rem" }}>
      <FormBuilder
        fields={[
          {
            label: "Senha antiga",
            type: "password",
            name: "currentPassword",
            value: ""
          },
          {
            label: "Nova senha",
            type: "password",
            name: "newPassword",
            value: ""
          },
          {
            label: "Confirmar senha",
            type: "password",
            name: "confirmNewPassword",
            value: ""
          }
        ]}
        onSubmit={submitForm}
        message={message}
        dismissMessage={dismissMessage}
        validationSchema={schema}
      >
        <Stack horizontal disableShrink tokens={{ childrenGap: 10 }}>
          <PrimaryButton text="Alterar senha" type="submit" />
        </Stack>
      </FormBuilder>
    </div>
  );
}
