import { ShippingsTypeActionsTypes } from "../types/ShippingsType.reducer.types";

export function createShippingsTypeRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ShippingsTypeActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createShippingsTypeSuccess(message) {
  return {
    type: ShippingsTypeActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createShippingsTypeFailure(message) {
  return {
    type: ShippingsTypeActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editShippingsTypeRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ShippingsTypeActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editShippingsTypeSuccess(message) {
  return {
    type: ShippingsTypeActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editShippingsTypeFailure(message) {
  return {
    type: ShippingsTypeActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listShippingsTypeRequest(filter, { isAdmin = false }) {
  return {
    type: ShippingsTypeActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listShippingsTypeSuccess({ list }) {
  return {
    type: ShippingsTypeActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listShippingsTypeFailure() {
  return {
    type: ShippingsTypeActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ShippingsTypeActionsTypes.DISMISS_MESSAGE
  };
}
