import { TreeDataValue } from "@/core/libs/tree-select-dropdown/TreeSelectDropdown";

export const flattenValues = (businessUnit: TreeDataValue) => {
  const values = [businessUnit.value];

  if (Array.isArray(businessUnit.children)) {
    values.push(...businessUnit.children.flatMap(flattenValues));
  }

  return values;
};
