import { IMessageBarStyles, MessageBar, MessageBarType } from "@fluentui/react";

const styles: IMessageBarStyles = {
  content: {
    display: "flex",
    justifyContent: "center"
  },
  text: {
    flexGrow: 0
  }
};

export function SandboxBarMessage(): JSX.Element {
  return (
    <MessageBar messageBarType={MessageBarType.warning} styles={styles}>
      <span className="ms-fontWeight-semibold ms-fontSize-16">
        Ambiente de treinamento - Sem valor legal
      </span>
    </MessageBar>
  );
}
