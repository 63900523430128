import styled from "styled-components";

export const DefaultCardTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1vh;
  padding: 0px 8px;
`;

type PaymentCardWrapperProps = {
  isInsidePanel?: boolean;
};

export const PaymentCardWrapper = styled.div<PaymentCardWrapperProps>`
  display: ${props => (props.isInsidePanel ? "block" : "flex")};
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  column-gap: 1rem;
`;
