import { LinesOccurrencesActionsTypes } from "../types/LinesOccurrences.reducer.types";

export function listLinesOccurrencesRequest(filter, { isAdmin = false }) {
  return {
    type: LinesOccurrencesActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listLinesOccurrencesSuccess({ list }) {
  return {
    type: LinesOccurrencesActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listLinesOccurrencesFailure() {
  return {
    type: LinesOccurrencesActionsTypes.LIST_FAIL
  };
}
