import { getTheme, ITheme } from "@fluentui/react/lib/Styling";
import { ReactNode, useContext } from "react";

import { Sidebar } from "./components";
import { IUserInfo } from "./components/FreshChat/interfaces/fresh-chat.interface";
import HelpButton from "./components/HelpButton";
import { MainLayoutContext } from "./Layout";
import { StyledContentArea, StyledContentWrapper } from "./styles";

import { useGetProfile } from "@/core/libs/api/react-query/index";

type MainLayoutProps = {
  children: ReactNode;
};

function MainLayout({ children }: MainLayoutProps): JSX.Element {
  const { toggleFocusZoneState, isFocusZoneDisabled } =
    useContext(MainLayoutContext);

  const userProfileQuery = useGetProfile();
  const user = userProfileQuery.data?.data;
  const theme: ITheme = getTheme();

  const userInfoMapper = (user: any): IUserInfo => {
    return {
      id: user.id,
      email: user.email,
      fullName: user.fullName,
      businessUnit: user.businessUnit
    };
  };

  return (
    <StyledContentWrapper>
      <Sidebar theme={theme} />
      <StyledContentArea
        color={theme.palette.neutralLighterAlt}
        isDev={import.meta.env.VITE_IS_SANDBOX === "true"}
      >
        {children}
        <HelpButton
          toggleFocusTrapZone={toggleFocusZoneState}
          isFocusZoneDisabled={isFocusZoneDisabled}
          userInfo={userInfoMapper(user)}
        />
      </StyledContentArea>
    </StyledContentWrapper>
  );
}

export default MainLayout;
