import { Checkbox } from "@fluentui/react";

import { FilterComponentProps } from "../types";

import { Space } from "@/components/Space";

export function CheckboxFilter({
  filterConfigs,
  selectedKeys,
  debouncedTerms,
  itemsPerPage,
  setSelectedKeys,
  _paginate
}: FilterComponentProps): JSX.Element {
  const filterQuery = filterConfigs.filterQuery as string;

  return (
    <Space>
      <Checkbox
        defaultChecked={
          selectedKeys[filterQuery]
            ? selectedKeys[filterQuery]
            : filterConfigs.defaultValue === "true"
        }
        disabled={filterConfigs.disabled ? true : false}
        label={filterConfigs.label}
        onChange={(_, item) => {
          setSelectedKeys((previousSelectedKeys: typeof selectedKeys) => ({
            ...previousSelectedKeys,
            [filterQuery as string]: item
          }));

          _paginate({
            limit: itemsPerPage ?? 10,
            ...debouncedTerms,
            ...selectedKeys,
            [filterQuery]: item
          });
        }}
      ></Checkbox>
    </Space>
  );
}
