import { BusinessUnitsActionsTypes } from "../types/BusinessUnits.reducer.types";

export function createBURequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: BusinessUnitsActionsTypes.CREATE_BU_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createBUSuccess(message) {
  return {
    type: BusinessUnitsActionsTypes.CREATE_BU_SUCCESS,
    payload: { message }
  };
}

export function createBUFailure(message) {
  return {
    type: BusinessUnitsActionsTypes.CREATE_BU_FAIL,
    payload: { message }
  };
}

export function editBURequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: BusinessUnitsActionsTypes.EDIT_BU_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editBUSuccess(message) {
  return {
    type: BusinessUnitsActionsTypes.EDIT_BU_SUCCESS,
    payload: { message }
  };
}

export function editBUFailure(message) {
  return {
    type: BusinessUnitsActionsTypes.EDIT_BU_FAIL,
    payload: { message }
  };
}

export function listBURequest(filter, { isAdmin = false }) {
  return {
    type: BusinessUnitsActionsTypes.LIST_BU_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listBUSuccess({ list }) {
  return {
    type: BusinessUnitsActionsTypes.LIST_BU_SUCCESS,
    payload: { list }
  };
}

export function listBUFailure() {
  return {
    type: BusinessUnitsActionsTypes.LIST_BU_FAIL
  };
}

export function dismissMessage() {
  return {
    type: BusinessUnitsActionsTypes.DISMISS_MESSAGE
  };
}
