import Api from "./api";

class ServiceProviders extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/service-providers${subPaths}`
        : `service-providers${subPaths}`
    );
  }
}

export default ServiceProviders;
