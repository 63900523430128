import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class ContractsSigners extends Api {
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/contracts/signers/${subPaths}`
        : `contracts/signers/${subPaths}`
    );
  }

  addSignerSecret({ params, signal }: IApi) {
    const options = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}`,
      bodyObj: null
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }
}

export default ContractsSigners;
