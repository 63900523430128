import {
  ConstrainMode,
  DefaultButton,
  DetailsList,
  SelectionMode,
  Stack,
  Text
} from "@fluentui/react";
import { useState } from "react";

import { ViewProgress } from "../../ViewProgress";

import {
  ContractBadgeColors,
  TranslatedContractCurrentStep
} from "@/modules/Contracts/enums";

interface ItemReplacementHistoryProps {
  previousItems: any[];
  isCompactMode: boolean;
}

function ItemReplacementHistory({
  previousItems,
  isCompactMode
}: ItemReplacementHistoryProps) {
  const [isVisible, setIsVisible] = useState(false);

  const firstItem = previousItems.findIndex(item => !item.approvalFlow);

  return (
    <Stack>
      <Stack.Item align="start" styles={{ root: { margin: 15 } }}>
        <DefaultButton
          toggle
          checked={isVisible}
          text={isVisible ? "Esconder histórico" : "Mostrar histórico"}
          iconProps={
            isVisible ? { iconName: "ChevronUp" } : { iconName: "ChevronDown" }
          }
          onClick={() => setIsVisible(!isVisible)}
        />
      </Stack.Item>
      {isVisible && (
        <>
          {previousItems.length > 0 && (
            <DetailsList
              styles={{
                headerWrapper: { marginTop: -16 }
              }}
              selectionMode={SelectionMode.none}
              constrainMode={ConstrainMode.horizontalConstrained}
              compact={isCompactMode}
              columns={[
                {
                  key: "serialNumber",
                  name: "Número de série",
                  minWidth: 150,
                  maxWidth: 150,
                  isRowHeader: true,
                  onRender(item) {
                    return item.inventory
                      .filter(
                        iv =>
                          iv?.product?.category?.code === "TAG" ||
                          iv?.product?.category?.code === "CHIP"
                      )
                      .map(iv =>
                        iv?.product?.category?.code === "TAG"
                          ? iv.serialNumber.match(/.{1,4}/g).join(" ")
                          : iv?.product?.category?.code === "CHIP" &&
                            iv.serialNumber
                      );
                  }
                },
                {
                  key: "status",
                  name: "Estado",
                  minWidth: 100,
                  maxWidth: 200,
                  isMultiline: true,
                  onRender(item) {
                    return (
                      <Text
                        style={{
                          color:
                            ContractBadgeColors[item.currentStep.internalName]
                        }}
                      >
                        {item.nextStep?.friendlyName ??
                          TranslatedContractCurrentStep[
                            item.currentStep.internalName
                          ]}
                      </Text>
                    );
                  }
                },
                {
                  key: "progress",
                  name: "Esteira",
                  minWidth: 100,
                  flexGrow: 1,
                  onRender(item) {
                    if (!item.approvalFlow) {
                      if (
                        item.inventory.filter(
                          iv => iv?.product?.category?.code === "TAG"
                        )
                      )
                        return "Primeira tag.";
                      if (
                        item.inventory.filter(
                          iv => iv?.product?.category?.code === "CHIP"
                        )
                      )
                        return "Primeiro chip.";
                    }

                    return (
                      <ViewProgress
                        currentStep={item.currentStep.order}
                        steps={item.approvalFlow.steps}
                        size="small"
                      />
                    );
                  }
                }
              ]}
              items={previousItems.filter(item => item.approvalFlow)}
            />
          )}
          {firstItem !== -1 && (
            <div
              style={{
                padding: 10
              }}
              className="ms-fontSize-14"
            >
              <span className="ms-fontWeight-semibold">
                Número de série original:
              </span>{" "}
              {previousItems[firstItem]?.inventory
                .filter(
                  iv =>
                    iv?.product?.category?.code === "TAG" ||
                    iv?.product?.category?.code === "CHIP"
                )
                .map(iv =>
                  iv?.product?.category?.code === "TAG"
                    ? iv.serialNumber.match(/.{1,4}/g).join(" ")
                    : iv?.product?.category?.code === "CHIP" && iv.serialNumber
                )}
            </div>
          )}
        </>
      )}
    </Stack>
  );
}

export default ItemReplacementHistory;
