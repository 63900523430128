import Api from "./api";

class InventoriesOccurrences extends Api {
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/inventories/ocurrences${subPaths}`
        : `ocurrences${subPaths}`
    );
  }
}

export default InventoriesOccurrences;
