import { Link as FabricLink, PrimaryButton, Stack } from "@fluentui/react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import FormBuilder from "@/core/libs/form-builder";
import { IMessageBar } from "@/core/libs/message-bar";

const fields = [{ label: "E-mail", type: "email", name: "email", value: "" }];

const Step1Schema = Yup.object().shape({
  email: Yup.string().email("E-mail inválido").required("Obrigatório")
});

interface Step1Props {
  recoverPassword: (
    values: any,
    { setSubmitting }: { setSubmitting: any }
  ) => void;
  message: IMessageBar | undefined;
  dismissMessage: () => void | void;
}

function Step1(props: Step1Props): JSX.Element {
  return (
    <FormBuilder
      fields={fields}
      onSubmit={props.recoverPassword}
      message={props.message}
      dismissMessage={props.dismissMessage}
      validationSchema={Step1Schema}
    >
      <PrimaryButton text="Recuperar" allowDisabledFocus type="submit" />
      <Stack.Item align="end">
        <FabricLink as={Link} to="/login">
          Login
        </FabricLink>
      </Stack.Item>
    </FormBuilder>
  );
}

export default Step1;
