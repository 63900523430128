import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;

  margin: 0 10px;

  strong {
    color: #395ca3;
  }

  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    color: #395ca3;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  text-align: left;
  font-size: 0.9rem;
`;

export const PlanContent = styled.div`
  display: flex;
  align-items: center;
`;

export const AdditionalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;

  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    color: #395ca3;
  }

  .table {
    display: flex;
    flex-direction: column;

    .table-offer-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    .table-offers {
      display: flex;
      flex-direction: column;
    }

    .offer-content {
      display: flex;
      justify-content: space-between;
      .description {
        p {
          margin-left: 10px;
        }
        span {
          font-size: 0.6rem;
          color: #395ca3;
          font-weight: bold;
        }
      }
      .value {
      }
    }
  }
`;
