import { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import Login from "@/modules/Auth/pages/Login";
import NotFound from "@/modules/Auth/pages/NotFound";
import Recover from "@/modules/Auth/pages/Recover";
import SetPassword from "@/modules/Auth/pages/SetPassword";

function AuthRoutes(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") return history.push("/login");

    if (
      location.pathname !== "/login" &&
      location.pathname !== "/recover" &&
      location.pathname !== "/set-password"
    ) {
      return history.push(`/login?origin=${location.pathname}`);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/recover" component={Recover} />
      <Route exact path="/set-password" component={SetPassword} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default AuthRoutes;
