import { getTheme, IconButton } from "@fluentui/react";
import { useState } from "react";

import { ProductContract } from "../ProductOfferContract/ProductContract";
import { ProductFilter } from "../ProductOfferFilters/ProductFilter";
import { ProductPlans } from "../ProductOfferPlans/ProductPlans";

import {
  Container,
  ImageContainer,
  PricesContainer
} from "./ProductComboModal.styles";
import { ProductImageInfo } from "./ProductImageInfo";

import {
  ProductOfferItem,
  ProductStep
} from "@/modules/Offers/types/ProductsOffers.types";

type ProductComboModalProps = {
  productOffer: ProductOfferItem;
  toggleOpenModal?: () => void;
};

const theme = getTheme();

function ProductComboModal({
  productOffer,
  toggleOpenModal
}: ProductComboModalProps): JSX.Element {
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [cashFilter, setCashFilter] = useState<string>(undefined);
  const [carrierFilter, setCarrierFilter] = useState<string>(undefined);
  const [productStep, setProductStep] = useState<string>(ProductStep.FILTER);

  const handleCashFilter = (filter: string) => {
    if (cashFilter === filter) {
      setCashFilter(undefined);
    } else {
      setCashFilter(filter);
      carrierFilter && setCarrierFilter(undefined);
      setProductStep(ProductStep.LIST_PLANS);
    }
  };

  const handleCarrierFilter = (filter: string) => {
    if (carrierFilter === filter) {
      setCarrierFilter(undefined);
    } else {
      setCarrierFilter(filter);
      cashFilter && setCashFilter(undefined);
      setProductStep(ProductStep.LIST_PLANS);
    }
  };

  const handleAllFilters = () => {
    if (carrierFilter) {
      setCarrierFilter(undefined);
    }

    if (cashFilter) {
      setCashFilter(undefined);
    }

    setProductStep(ProductStep.LIST_PLANS);
  };

  return (
    <Container>
      <ImageContainer>
        <ProductImageInfo productOffer={productOffer} />
      </ImageContainer>

      <PricesContainer>
        <IconButton
          styles={{
            root: {
              color: theme.palette.themeDarkAlt,
              zIndex: 1,
              position: "absolute",
              top: 10,
              right: 10
            },
            rootHovered: {
              color: theme.palette.neutralDark
            }
          }}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Close popup modal"
          onClick={toggleOpenModal}
        />

        {productStep === ProductStep.FILTER && (
          <ProductFilter
            productOffer={productOffer}
            currentCashFilter={cashFilter}
            currentCarrierFilter={carrierFilter}
            handleCashFilterClick={handleCashFilter}
            handleCarrierFilterClick={handleCarrierFilter}
            handleAllFiltersClick={handleAllFilters}
          />
        )}

        {productStep === ProductStep.LIST_PLANS && (
          <ProductPlans
            productOffer={productOffer}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            currentCashFilter={cashFilter}
            currentCarrierFilter={carrierFilter}
            setProductStep={setProductStep}
            setCashFilter={setCashFilter}
            setCarrierFilter={setCarrierFilter}
          />
        )}

        {productStep === ProductStep.CONTRACT && (
          <ProductContract
            productOffer={productOffer}
            setProductStep={setProductStep}
            selectedOfferItem={selectedItem}
          />
        )}
      </PricesContainer>
    </Container>
  );
}

export default ProductComboModal;
