import { ReactNode } from "react";

import { Container } from "./styles";

type StripedBackgroundProps = {
  children: ReactNode;
};

function StripedBackground({ children }: StripedBackgroundProps): JSX.Element {
  return <Container>{children}</Container>;
}

export default StripedBackground;
