import {
  Checkbox,
  ComboBox,
  Dropdown,
  MaskedTextField,
  TextField,
  Toggle
} from "@fluentui/react";
import MDEditor from "@uiw/react-md-editor";
import { Field } from "formik";
import { useState } from "react";

import { AddressesFields } from "../adresses-fields/addresses-fields";

import { ArrayField } from "./array-fields";
import BasicFields from "./basic-fields";
import { BusinessUnitSettingsFields } from "./business-unit-settings-fields";
import { CheckingAccountsFields } from "./checking-accounts-fields";
import { ContactsFields } from "./contacts-fields";
import { DateField } from "./date-field";
import { DocumentsFields } from "./documents-fields";
import { FilesField } from "./files-fields";
import { getNestedObject } from "./form-builder";
import { GroupedTree } from "./grouped-tree-field";
import { Utils } from "./helpers";
import { LinkedGroupsFields } from "./linked-groups-fields";
import OccupationField from "./occupation-field";
import { PivotMenu } from "./pivot-menu";
import { SearchSelectField } from "./search-select-field";
import { SignersFields } from "./signers-fields";
import { FieldTextContainer } from "./styles";
import { TableField } from "./table-field";
import { Input } from "./types";

import { LabelWithTooltip } from "@/components/LabelWithTooltip";

interface SwitcherProps {
  input: Input;
  setFieldValue: any;
  errors: any;
  values: any;
  touched: any;
  linkedGroups: any;
  setFieldTouched: any;
}

export const FormBuilderSwitcher = (props: SwitcherProps) => {
  const [isFreeformEnabled, setIsFreeformEnabled] = useState(false);
  const {
    input,
    setFieldValue,
    errors,
    values,
    touched,
    linkedGroups,
    setFieldTouched
  } = props;

  switch (input.type) {
    case "addresses":
      return (
        <AddressesFields
          addressCfg={input}
          key="addressFields"
          values={values[input.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      );

    case "contacts":
      return (
        <ContactsFields
          contactCfg={input}
          key="contactFields"
          values={values[input.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      );

    case "unitBusinessSettings":
      return (
        <BusinessUnitSettingsFields
          BusinessUnitSettingCfg={input}
          key="BusinessUnitSettingFields"
          values={values[input.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      );

    case "checkingAccounts":
      return (
        <CheckingAccountsFields
          config={input}
          key="checkingAccountsFields"
          values={values[input.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      );

    case "linkedGroupsFields":
      return (
        <LinkedGroupsFields
          config={input}
          key="linkedGroupsFields"
          linkedGroups={linkedGroups}
          values={values[input.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      );

    case "comboBox":
      return (
        <FieldTextContainer>
          <LabelWithTooltip
            description={input.tooltipDescription}
            label={input.label}
            id={input.name}
            required={input.required || false}
          />
          <Field
            name={input.name}
            type="text"
            as={ComboBox}
            id={input.name}
            onChange={(event, option?, index?, value?) => {
              const selectedOptions = new Set(
                getNestedObject(values, input.name.split("."))
              );

              if (value && input.allowFreeform) {
                input.options = [...input.options, { key: value, text: value }];
              }

              if (option?.selected || value) {
                selectedOptions.add(option?.key || value);
              } else {
                selectedOptions.delete(option?.key || value);
              }

              setFieldValue(input.name, [...selectedOptions]);
            }}
            placeholder={input.placeholder || input.label}
            key={input.name}
            disabled={input.disabled || false}
            errorMessage={
              getNestedObject(errors, input.name.split(".")) &&
              getNestedObject(touched, input.name.split("."))
                ? getNestedObject(errors, input.name.split("."))
                : null
            }
            onFocus={() => {
              if (!isFreeformEnabled && input.allowFreeform) {
                setTimeout(() => {
                  setIsFreeformEnabled(input.allowFreeform);
                }, 100);
              }
            }}
            onBlur={() => {
              if (isFreeformEnabled) setIsFreeformEnabled(false);
            }}
            selectedKey={
              getNestedObject(values, input.name.split(".")) || undefined
            }
            value={undefined}
            allowFreeform={isFreeformEnabled}
            autoComplete="on"
            options={input.options}
            multiSelect={input.multiSelect}
            required={input.required || false}
            description={input.description}
          />
        </FieldTextContainer>
      );

    case "documents":
      return (
        <DocumentsFields
          documentCfg={input}
          key="documentFields"
          values={values[input.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      );

    case "date":
      return (
        <DateField
          id={`input-${input.name}`}
          name={input.name}
          type={input.type}
          key={input.name}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled || false}
          errorMessage={
            getNestedObject(errors, input.name.split(".")) &&
            getNestedObject(touched, input.name.split("."))
              ? getNestedObject(errors, input.name.split("."))
              : null
          }
          value={Utils.formatDateValue(values[input.name])}
          isRequired={input.required || false}
          setFieldValue={setFieldValue}
          minDate={input.minDate}
          maxDate={input.maxDate}
          tooltipDescription={input.tooltipDescription}
          invalidDateErrorMessage={input?.invalidDateErrorMessage}
        />
      );

    case "hidden":
      return (
        <Field
          name={input.name}
          type="hidden"
          key={input.name}
          id={input.name}
        />
      );

    case "select":
      if (
        input.value &&
        values[input.name] === undefined &&
        !touched[input.name]
      ) {
        setFieldValue(input.name, input.value);
      } else if (values[input.name] === undefined) {
        setFieldValue(input.name, "");
      }

      return (
        <FieldTextContainer>
          <LabelWithTooltip
            description={input.tooltipDescription}
            label={input.label}
            required={input.required || false}
            id={input.name}
          />
          <Field
            name={input.name}
            id={input.name}
            as={Dropdown}
            type={input.type}
            onBlur={e => {
              setFieldTouched(input.name, true);
            }}
            onChange={(_evt, item) => {
              if (input.multiSelect) {
                const selectedOptions = new Set(
                  getNestedObject(values, input.name.split("."))
                );
                if (item.selected) {
                  selectedOptions.add(item.key);
                } else {
                  selectedOptions.delete(item.key);
                }

                setFieldValue(input.name, [...selectedOptions]);
              } else {
                values[input.name] === item.key
                  ? setFieldValue(input.name, null)
                  : setFieldValue(input.name, item.key);
              }

              if (input.funcSetCondition) {
                item.data
                  ? input.funcSetCondition(item.data)
                  : input.funcSetCondition(item.key);
              }

              if (input.fieldsToResetValueOnChange) {
                input.fieldsToResetValueOnChange.forEach(fieldName => {
                  setFieldValue(fieldName, undefined);
                });
              }

              if (input.executeOnChange) {
                input.executeOnChange(item);
              }
            }}
            placeholder={input.placeholder || input.label}
            key={input.name}
            disabled={input.disabled || false}
            errorMessage={
              getNestedObject(errors, input.name.split(".")) &&
              getNestedObject(touched, input.name.split("."))
                ? getNestedObject(errors, input.name.split("."))
                : null
            }
            selectedKeys={input.multiSelect ? values[input.name] : undefined}
            selectedKey={
              input.multiSelect
                ? undefined
                : values[input.name] !== undefined
                ? values[input.name]
                : input.value
            }
            options={input.options}
            onRenderOption={input.onRenderOption}
            styles={{
              dropdown: { width: "100%" }
            }}
            multiSelect={input.multiSelect}
            required={input.required || false}
            description={input.description}
            calloutProps={{ calloutMaxHeight: 500 }}
          />
        </FieldTextContainer>
      );

    case "textarea":
      return (
        <FieldTextContainer>
          <LabelWithTooltip
            description={input.tooltipDescription}
            label={input.label}
            required={input.required || false}
            id={input.name}
          />
          <Field
            id={`input-${input.name}`}
            name={input.name}
            type={input.type}
            as={TextField}
            placeholder={input.placeholder || input.label}
            key={input.name}
            disabled={input.disabled || false}
            errorMessage={
              errors[input.name] && touched[input.name]
                ? errors[input.name]
                : null
            }
            required={input.required || false}
            description={input.description}
            onChange={(_, value: string) => {
              let newValue = value;

              if (input.name === "lines") {
                const regex = /[^\d\n]/g;
                newValue = value.replace(regex, "");
              }

              setFieldValue(input.name, newValue);
            }}
            multiline
            rows={3}
          />
        </FieldTextContainer>
      );

    case "occupation":
      return (
        <OccupationField
          key={input.name}
          occupationConfig={input}
          setValue={setFieldValue}
          errors={errors}
          touched={touched}
          defaultValue={values[input.name] || ""}
        />
      );

    case "searchSelectField":
      if (input.value && values[input.name] === undefined) {
        setFieldValue(input.name, input.value);
      }
      return (
        <SearchSelectField
          key={input.name}
          fieldConfig={input}
          setValue={setFieldValue}
          errors={errors}
          touched={touched}
          defaultValue={values[input.name] || ""}
        />
      );

    case "basicFields":
      return (
        <BasicFields
          fields={input.fields}
          parentDescription={input?.description || ""}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          values={values}
        />
      );

    case "signers":
      return (
        <SignersFields
          SignersCfg={input}
          key="signersFields"
          values={values[input.name]}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      );

    case "groupedTree":
      return (
        <GroupedTree
          groupedTreeConfig={input}
          key="groupedTree"
          values={values[input.name]}
          groups={input.groups}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          disabled={input.disabled}
        />
      );

    case "maskedText":
      if (input.value && values[input.name] === undefined) {
        setFieldValue(input.name, input.value);
      }
      return (
        <FieldTextContainer>
          <LabelWithTooltip
            description={input.tooltipDescription}
            label={input.label}
            id={`label-${input.name}`}
            required={input.required || false}
          />
          <Field
            name={input.name}
            id={`input-${input.name}`}
            type={input.type}
            as={MaskedTextField}
            placeholder={input.placeholder || input.label}
            key={input.name}
            disabled={input.disabled || false}
            errorMessage={
              errors[input.name] && touched[input.name]
                ? errors[input.name]
                : null
            }
            value={values[input.name] ? values[input.name] : input.value || ""}
            onChange={(event, value: string) => {
              if (input.mask) {
                setFieldValue(input.name, value.replace(/\D/g, ""));
              } else {
                setFieldValue(input.name, value);
              }
            }}
            defaultValue={values[input.name] ?? ""}
            mask={input?.mask}
            required={input.required || false}
            description={input.description}
            maxLength={input.maxLength}
          />
        </FieldTextContainer>
      );

    case "CustomMaskedText":
      return (
        <FieldTextContainer>
          <LabelWithTooltip
            description={input.tooltipDescription}
            id={`label-${input.name}`}
            label={input.label}
            required={input.required || false}
          />
          <Field
            name={input.name}
            id={`input-${input.name}`}
            type={input.type}
            as={TextField}
            placeholder={input.placeholder || input.label}
            key={input.name}
            disabled={input.disabled || false}
            errorMessage={
              errors[input.name] && touched[input.name]
                ? errors[input.name]
                : null
            }
            onChange={(event, value: string) => {
              input?.maskFormatter && value !== ""
                ? setFieldValue(input.name, input.maskFormatter(value))
                : setFieldValue(input.name, value);
            }}
            value={
              input?.maskFormatter && values[input.name] !== ""
                ? input.maskFormatter(values[input.name])
                : values[input.name]
            }
            required={input.required || false}
            description={input.description}
            maxLength={input.maxLength ?? ""}
          />
        </FieldTextContainer>
      );

    case "files":
      return (
        <FilesField
          fileConfig={input}
          key={input.name}
          label={input.label || input.description}
          values={values[input.name]}
          required={input.required}
          setFieldValue={setFieldValue}
        />
      );

    case "arrayField":
      return (
        <ArrayField
          key={input.name}
          fieldConfig={input}
          setFieldValue={setFieldValue}
          errors={errors}
          values={values[input.name]}
          touched={touched}
        />
      );

    case "money":
      return (
        <FieldTextContainer>
          <LabelWithTooltip
            description={input.tooltipDescription}
            id={`label-${input.name}`}
            label={input.label}
            required={input.required || false}
          />
          <Field
            name={input.name}
            id={`input-${input.name}`}
            type={input.type}
            as={TextField}
            placeholder={input.placeholder || input.label}
            key={input.name}
            disabled={input.disabled || false}
            errorMessage={
              getNestedObject(errors, input.name.split(".")) &&
              getNestedObject(touched, input.name.split("."))
                ? getNestedObject(errors, input.name.split("."))
                : null
            }
            prefix="R$"
            required={input.required || false}
            description={input.description}
            value={
              values[input.name]?.length > 2
                ? Utils.toLocaleStringWithoutCurrency(values[input.name])
                : values[input.name] || undefined
            }
            onChange={(event, value) => {
              if (value && value !== "") {
                value = value.replace(/\D/g, "");
                if (value.length === 3) {
                  value = value.replace(/(\d{1})(\d{2})/, "$1,$2");
                } else if (value.length === 4) {
                  value = value.replace(/(\d{2})(\d{2})/, "$1,$2");
                } else if (value.length === 5) {
                  value = value.replace(/(\d{3})(\d{2})/, "$1,$2");
                } else if (value.length === 6) {
                  value = value.replace(/(\d{1})(\d{3})(\d{2})/, "$1.$2,$3");
                } else if (value.length === 7) {
                  value = value.replace(/(\d{2})(\d{3})(\d{2})/, "$1.$2,$3");
                } else if (value.length === 8) {
                  value = value.replace(/(\d{3})(\d{3})(\d{2})/, "$1.$2,$3");
                } else if (value.length === 9) {
                  value = value.replace(
                    /(\d{1})(\d{3})(\d{3})(\d{2})/,
                    "$1.$2.$3,$4"
                  );
                } else if (value.length === 10) {
                  value = value.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{2})/,
                    "$1.$2.$3,$4"
                  );
                } else if (value.length === 11) {
                  value = value.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    "$1.$2.$3,$4"
                  );
                } else if (value.length === 12) {
                  value = value.replace(
                    /(\d{1})(\d{3})(\d{3})(\d{3})(\d{2})/,
                    "$1.$2.$3.$4,$5"
                  );
                } else {
                  value = value.replace(/(\d{10,})(\d{2})/, "$1.$2");
                }
              } else {
                value = "000";
              }
              setFieldValue(input.name, value);
            }}
          />
        </FieldTextContainer>
      );

    case "tableField":
      return (
        <Field
          name={input.name}
          type={input.type}
          key={input.name}
          as={TableField}
          fieldConfig={input}
          currentSelectedValues={values[input.name]}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errorMessage={
            getNestedObject(errors, input.name.split(".")) &&
            getNestedObject(touched, input.name.split("."))
              ? getNestedObject(errors, input.name.split("."))
              : null
          }
        />
      );

    case "checkbox":
      return (
        <>
          <LabelWithTooltip
            description={input.tooltipDescription}
            label={input.label}
            id={`label-${input.name}`}
            required={input.required || false}
          />
          <Field
            name={input.name}
            id={`input-${input.name}`}
            type={input.type}
            key={input.name}
            disabled={input.disabled || false}
            required={input.required || false}
            as={Checkbox}
            placeholder={input.placeholder || input.label}
            checked={input.value || false}
            onChange={(e, value) => {
              input.onChangeHandle(value);
              setFieldValue(input.name, value);
            }}
            errorMessage={
              getNestedObject(errors, input.name.split(".")) &&
              getNestedObject(touched, input.name.split("."))
                ? getNestedObject(errors, input.name.split("."))
                : null
            }
          />
        </>
      );
    case "switch":
      if (values[input.name] === undefined) {
        setFieldValue(input.name, input.value ?? false);
      }
      return (
        <>
          <LabelWithTooltip
            description={input.tooltipDescription}
            id={`label-${input.name}`}
            label={input.label}
            required={input.required || false}
          />
          <Field
            id={`input-${input.name}`}
            name={input.name}
            type={input.type}
            key={input.name}
            disabled={input.disabled || false}
            required={input.required || false}
            as={Toggle}
            placeholder={input.placeholder || input.label}
            defaultChecked={
              input?.value ??
              (values[input.name] ? values[input.name] : input.value || false)
            }
            onChange={(e, value) => {
              setFieldValue(input.name, value);
            }}
            errorMessage={
              getNestedObject(errors, input.name.split(".")) &&
              getNestedObject(touched, input.name.split("."))
                ? getNestedObject(errors, input.name.split("."))
                : null
            }
            onText={input?.onText}
            offText={input?.offText}
          />
        </>
      );

    case "markdown":
      return (
        <div>
          <LabelWithTooltip
            description={input.tooltipDescription}
            id={`label-${input.name}`}
            label={input.label}
          />
          <div
            data-color-mode="light"
            style={{ border: "1px #ccc solid", marginTop: "4px" }}
          >
            <MDEditor
              id={`input-${input.name}`}
              key={input.name}
              value={values[input.name]}
              color="#fff"
              onChange={e => {
                setFieldValue(input.name, e);
              }}
              height={input?.height ?? ""}
              hideToolbar={true}
            />
          </div>
        </div>
      );

    case "menu":
      return (
        <PivotMenu
          input={input}
          setFieldValue={setFieldValue}
          errors={errors}
          values={values}
          touched={touched}
          linkedGroups={linkedGroups}
          setFieldTouched={setFieldTouched}
        />
      );
    default:
      if (input.value && input.name.includes(".")) {
        const [objName, keyName] = input.name.split(".");
        const obj = values[objName];
        if (obj[keyName] === undefined) {
          Object.assign(obj, {
            ...obj,
            [keyName]: input.value
          });
          setFieldValue(objName, obj);
        }
      } else if (input.value && values[input.name] === undefined) {
        setFieldValue(input.name, input.value);
      }
      return (
        <FieldTextContainer>
          <LabelWithTooltip
            description={input.tooltipDescription}
            label={input.label}
            required={input.required || false}
            id={`label-${input.name}`}
          />
          <Field
            id={`input-${input.name}`}
            name={input.name}
            type={input.type}
            as={input.mask ? MaskedTextField : TextField}
            placeholder={input.placeholder || input.label}
            key={input.name}
            disabled={input.disabled || false}
            errorMessage={
              errors[input.name] && touched[input.name]
                ? errors[input.name]
                : null
            }
            canRevealPassword={input.canRevealPassword ?? false}
            mask={input.mask}
            required={input.required || false}
            description={input.description}
            min={input.min}
          />
        </FieldTextContainer>
      );
  }
};
