export interface IBusinessUnit {
  id: number;
  name: string;
  settings?: any;
  children?: IBusinessUnit[];
}

export const destructureAllBusinessUnitsChildreen = (
  data: Array<IBusinessUnit>
) => {
  const units = [];
  for (const businessUnit of data) {
    if (businessUnit.children) {
      units.push(
        ...destructureAllBusinessUnitsChildreen(businessUnit.children)
      );
    }
    units.push(businessUnit);
  }
  return units;
};
