import { DefaultButton } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { IBusinessUnitSignature } from "../../interfaces/IBusinessUnitSignature.interface";
import { ITerm } from "../../interfaces/ITerm.interface";

import { ButtonContainer, Wrapper } from "./styles";

import { useApi } from "@/core/libs/api/react-query/useApi";

interface TermSignaturesModalInterface {
  term: ITerm;
  toggleVisible: (data: boolean) => void;
}

export function TermSignaturesModal({
  term,
  toggleVisible
}: TermSignaturesModalInterface): JSX.Element {
  const [businessUnitSignatureTypeList, setBusinessUnitSignatureTypeList] =
    useState<IBusinessUnitSignature[]>([]);

  const { execute } = useApi();

  const getTermSignatureTypes = useCallback(async () => {
    const signaturesRequest = await execute({
      url: `business-units/${term.businessUnitId}`,
      method: "GET",
      notificationMessage: `Buscando assinaturas válidas para o termo #${term.id}`
    });

    if (signaturesRequest.response.ok) {
      setBusinessUnitSignatureTypeList(signaturesRequest.data);
    }
  }, [term.id]);

  const updateTermSignature = useCallback(
    async (signatureId: number) => {
      await execute({
        url: `terms/${term.id}`,
        method: "PATCH",
        notificationMessage: `Atualizando a assinatura do #${term.id}`,
        bodyObj: Object({ signatureId })
      });
      toggleVisible(false);
    },
    [term.id]
  );

  useEffect(() => {
    getTermSignatureTypes();
  }, [getTermSignatureTypes]);

  return (
    <Wrapper>
      <h1>Escolha a modalidade de assinatura para este termo:</h1>

      <ButtonContainer>
        {businessUnitSignatureTypeList.map((buSignature, index) => (
          <DefaultButton
            key={index}
            onClick={() => updateTermSignature(buSignature.signatureId)}
          >
            {buSignature.signature.name}
          </DefaultButton>
        ))}
      </ButtonContainer>
    </Wrapper>
  );
}
