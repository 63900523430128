import { CommandBar, ICommandBarItemProps, ITheme } from "@fluentui/react";

import { CustomButton } from "../CustomButtons";

interface SidebarHeaderProps {
  theme: ITheme;
  closeMenu?: () => void;
}

export function SidebarHeader({
  theme,
  closeMenu
}: SidebarHeaderProps): JSX.Element {
  const items: ICommandBarItemProps[] = [
    {
      key: "home",
      iconOnly: true,
      ariaLabel: "Libertas",
      iconProps: { iconName: "libertas" },
      to: "/",
      commandBarButtonAs: CustomButton,
      onClick: closeMenu
    }
  ];

  const farItems: ICommandBarItemProps[] = [
    {
      key: "menuClose",
      ariaLabel: "Close Menu",
      iconOnly: true,
      iconProps: { iconName: "ChromeClose" },
      commandBarButtonAs: CustomButton,
      onClick: closeMenu
    }
  ];

  return (
    <CommandBar
      items={items}
      farItems={farItems}
      styles={{
        root: {
          borderBottom: "1px solid",
          borderBottomColor: theme.palette.neutralLight,
          minHeight: 48,
          paddingLeft: 10
        }
      }}
    />
  );
}
