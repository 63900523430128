import { Stack } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { ITerm } from "../../interfaces/ITerm.interface";
import { createTermAccountData } from "../../utils/createTermAccountData";
import { DocumentsCard } from "../DocumentsCard";
import { ShowInfoCard } from "../ShowInfoCard";
import { SignaturesCard } from "../SignaturesCard";

import { TranslatedSignatureType } from "@/modules/Contracts/enums";

interface IDisplayTermInfo {
  term: ITerm;
  openDrawer: () => void;
}

export const DisplayTermInfo = ({ term, openDrawer }: IDisplayTermInfo) => {
  const [accountData, setAccountData] = useState({});
  const [termData, setTermData] = useState({});

  const createAccountData = useCallback(() => {
    const newInfo = createTermAccountData(term?.account);
    setAccountData(newInfo);
  }, [term?.account]);

  useEffect(() => {
    createAccountData();

    setTermData({
      "Tipo de Assinatura": TranslatedSignatureType[term?.signature?.type],
      "Autor do termo": term?.user?.fullName,
      "Criado em": new Date(term?.createdAt).toLocaleDateString("pt-br"),
      "Atualizado em": new Date(term?.updatedAt).toLocaleDateString("pt-br")
    });
  }, [createAccountData, term]);

  const currentStep = term?.currentStep.internalName;

  return (
    <>
      <Stack>
        <div style={{ marginTop: 20 }}>
          <Stack horizontal wrap tokens={{ childrenGap: 15 }}>
            <Stack.Item className="ms-sm12 ms-xl5" grow>
              <ShowInfoCard
                openDrawer={openDrawer}
                info={accountData}
                title={"Cooperado"}
                iconName={"Contact"}
                isButtonDisabled={currentStep !== "REGISTER_CORRECTION"}
              />
            </Stack.Item>
            <Stack.Item className="ms-sm12 ms-xl5" grow>
              <ShowInfoCard
                info={termData}
                title={"Dados Base"}
                iconName={"ClipboardList"}
              />
            </Stack.Item>
          </Stack>
        </div>
      </Stack>
      <Stack horizontal wrap tokens={{ childrenGap: 15 }}>
        <Stack.Item className="ms-sm12 ms-xl5" grow>
          <SignaturesCard
            documentId={term.id}
            eletronicSignature={term?.externalElectronicSignatureData}
          />
        </Stack.Item>
        <Stack.Item className="ms-sm12 ms-xl5" grow>
          <DocumentsCard uploads={[]} canUpload={false} />
        </Stack.Item>
      </Stack>
    </>
  );
};
