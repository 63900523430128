import { ListOfOfferPrice, ListOfOffers } from "./Offers.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum OffersActionsTypes {
  CREATE_REQUEST = "offers/CREATE_REQUEST",
  CREATE_FAIL = "offers/CREATE_FAIL",
  CREATE_SUCCESS = "offers/CREATE_SUCCESS",
  CREATE_OFFER_PRICING_GROUP_REQUEST = "offers/CREATE_OFFER_PRICING_GROUP_REQUEST",
  CREATE_OFFER_PRICING_GROUP_FAIL = "offers/CREATE_OFFER_PRICING_GROUP_FAIL",
  CREATE_OFFER_PRICING_GROUP_SUCCESS = "offers/CREATE_OFFER_PRICING_GROUP_SUCCESS",
  EDIT_OFFER_PRICING_GROUP_REQUEST = "offers/EDIT_OFFER_PRICING_GROUP_REQUEST",
  EDIT_OFFER_PRICING_GROUP_FAIL = "offers/EDIT_OFFER_PRICING_GROUP_FAIL",
  EDIT_OFFER_PRICING_GROUP_SUCCESS = "offers/EDIT_OFFER_PRICING_GROUP_SUCCESS",
  DELETE_OFFER_PRICING_GROUP_REQUEST = "offers/DELETE_OFFER_PRICING_GROUP_REQUEST",
  DELETE_OFFER_PRICING_GROUP_FAIL = "offers/DELETE_OFFER_PRICING_GROUP_FAIL",
  DELETE_OFFER_PRICING_GROUP_SUCCESS = "offers/DELETE_OFFER_PRICING_GROUP_SUCCESS",
  CREATE_OFFER_PRODUCT_REQUEST = "offers/CREATE_OFFER_PRODUCT_REQUEST",
  CREATE_OFFER_PRODUCT_FAIL = "offers/CREATE_OFFER_PRODUCT_FAIL",
  CREATE_OFFER_PRODUCT_SUCCESS = "offers/CREATE_OFFER_PRODUCT_SUCCESS",
  DELETE_OFFER_PRODUCT_REQUEST = "offers/DELETE_OFFER_PRODUCT_REQUEST",
  DELETE_OFFER_PRODUCT_FAIL = "offers/DELETE_OFFER_PRODUCT_FAIL",
  DELETE_OFFER_PRODUCT_SUCCESS = "offers/DELETE_OFFER_PRODUCT_SUCCESS",
  DISMISS_MESSAGE = "offers/DISMISS_MESSAGE",
  EDIT_REQUEST = "offers/EDIT_REQUEST",
  EDIT_FAIL = "offers/EDIT_FAIL",
  EDIT_SUCCESS = "offers/EDIT_SUCCESS",
  DELETE_OFFER_REQUEST = "offers/DELETE_OFFER_REQUEST",
  DELETE_OFFER_FAIL = "offers/DELETE_OFFER_FAIL",
  DELETE_OFFER_SUCCESS = "offers/DELETE_OFFER_SUCCESS",
  LIST_REQUEST = "offers/LIST_REQUEST",
  LIST_FAIL = "offers/LIST_FAIL",
  LIST_SUCCESS = "offers/LIST_SUCCESS",
  LIST_OFFER_PRICE_REQUEST = "offers/LIST_OFFER_PRICE_REQUEST",
  LIST_OFFER_PRICE_FAIL = "offers/LIST_OFFER_PRICE_FAIL",
  LIST_OFFER_PRICE_SUCCESS = "offers/LIST_OFFER_PRICE_SUCCESS"
}

export interface OffersState {
  isLoadingList: boolean;
  isLoadingOfferPriceList: boolean;
  list?: ListOfOffers;
  listOfOfferPrice?: ListOfOfferPrice;
  message?: IMessageBar;
  offerPriceMessage?: IMessageBar;
}

export interface CreateRequest {
  type: OffersActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: OffersActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: OffersActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateOfferPricingGroupRequest {
  type: OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_REQUEST;
}

export interface CreateOfferPricingGroupError {
  type: OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateOfferPricingGroupSuccess {
  type: OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface EditOfferPricingGroupRequest {
  type: OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_REQUEST;
}

export interface EditOfferPricingGroupError {
  type: OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditOfferPricingGroupSuccess {
  type: OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateOfferProductRequest {
  type: OffersActionsTypes.CREATE_OFFER_PRODUCT_REQUEST;
}

export interface CreateOfferProductError {
  type: OffersActionsTypes.CREATE_OFFER_PRODUCT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateOfferProductSuccess {
  type: OffersActionsTypes.CREATE_OFFER_PRODUCT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: OffersActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: OffersActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: OffersActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: OffersActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DeleteOfferPricingGroupRequest {
  type: OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_REQUEST;
}

export interface DeleteOfferPricingGroupError {
  type: OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface DeleteOfferPricingGroupSuccess {
  type: OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DeleteOfferRequest {
  type: OffersActionsTypes.DELETE_OFFER_REQUEST;
}

export interface DeleteOfferError {
  type: OffersActionsTypes.DELETE_OFFER_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface DeleteOfferSuccess {
  type: OffersActionsTypes.DELETE_OFFER_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DeleteOfferProductRequest {
  type: OffersActionsTypes.DELETE_OFFER_PRODUCT_REQUEST;
}

export interface DeleteOfferProductError {
  type: OffersActionsTypes.DELETE_OFFER_PRODUCT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface DeleteOfferProductSuccess {
  type: OffersActionsTypes.DELETE_OFFER_PRODUCT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: OffersActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: OffersActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: OffersActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfOffers;
  };
}

export interface ListOfferPriceRequest {
  type: OffersActionsTypes.LIST_OFFER_PRICE_REQUEST;
}

export interface ListOfferPriceError {
  type: OffersActionsTypes.LIST_OFFER_PRICE_FAIL;
}

export interface ListOfferPriceSuccess {
  type: OffersActionsTypes.LIST_OFFER_PRICE_SUCCESS;
  payload: {
    list: ListOfOfferPrice;
  };
}

export type OffersReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | CreateOfferPricingGroupRequest
  | CreateOfferPricingGroupError
  | CreateOfferPricingGroupSuccess
  | CreateOfferProductRequest
  | CreateOfferProductError
  | CreateOfferProductSuccess
  | EditOfferPricingGroupRequest
  | EditOfferPricingGroupError
  | EditOfferPricingGroupSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | DeleteOfferRequest
  | DeleteOfferError
  | DeleteOfferSuccess
  | DeleteOfferPricingGroupRequest
  | DeleteOfferPricingGroupError
  | DeleteOfferPricingGroupSuccess
  | DeleteOfferProductRequest
  | DeleteOfferProductError
  | DeleteOfferProductSuccess
  | ListRequest
  | ListError
  | ListSuccess
  | ListOfferPriceRequest
  | ListOfferPriceError
  | ListOfferPriceSuccess;
