import { Label } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { MaskedTextField, TextField } from "@fluentui/react/lib/TextField";
import { Field } from "formik";

import { DateField } from "./date-field";
import { Utils } from "./helpers";
import OccupationField from "./occupation-field";
import { FieldTextContainer } from "./styles";
import { As, Fields } from "./types";

import { LabelWithTooltip } from "@/components/LabelWithTooltip";

interface BasicFieldProps {
  fields?: Array<Fields>;
  parentDescription?: string;
  errors?: any;
  touched?: any;
  setFieldValue: (value, fielName) => void;
  values: any;
}

function BasicField({
  fields,
  parentDescription,
  errors,
  touched,
  setFieldValue,
  values
}: BasicFieldProps): JSX.Element {
  return (
    <>
      {parentDescription && <Label>{parentDescription}</Label>}
      {fields.map((field, index) => {
        switch (field.type) {
          case "select":
            return (
              <FieldTextContainer>
                <LabelWithTooltip
                  description={field.tooltipDescription}
                  label={field.label}
                  id={`label-${field.name}-${index}`}
                  required={field.required || false}
                />
                <Field
                  id={`input-${field.name}-${index}`}
                  name={field.name}
                  type={field.type}
                  as={Dropdown as As}
                  onChange={(evt, item) => {
                    setFieldValue(`${field.name}`, item.key);

                    if (field.funcSetCondition) {
                      field.funcSetCondition(item.key);
                    }
                  }}
                  placeholder={field.placeholder || field.label}
                  key={field.name}
                  disabled={field.disabled || false}
                  errorMessage={
                    errors[field.name] && touched[field.name]
                      ? errors[field.name]
                      : null
                  }
                  defaultSelectedKey={
                    field.value !== "" ? field.value : undefined
                  }
                  options={field.options}
                  styles={{ dropdown: { width: "100%" } }}
                  required={field.required || false}
                  description={field.description}
                />
              </FieldTextContainer>
            );

          case "date":
            return (
              <DateField
                id={`input-${field.name}-${index}`}
                name={field.name}
                type={field.type}
                key={field.name}
                label={field.label}
                placeholder={field.placeholder}
                disabled={field.disabled || false}
                errorMessage={
                  errors[field.name] && touched[field.name]
                    ? errors[field.name]
                    : null
                }
                value={Utils.formatDateValue(values[field.name])}
                isRequired={field.required || false}
                setFieldValue={setFieldValue}
                maxDate={field.maxDate}
                tooltipDescription={field.tooltipDescription}
              />
            );

          case "occupation":
            return (
              <OccupationField
                key={field.name}
                occupationConfig={field}
                setValue={setFieldValue}
                errors={errors}
                touched={touched}
                defaultValue={values[field.name] || ""}
              />
            );

          default:
            return (
              <FieldTextContainer>
                <LabelWithTooltip
                  description={field.tooltipDescription}
                  label={field.label}
                  id={`label-${field.name}-${index}`}
                  required={field.required || false}
                />
                <Field
                  id={`input-${field.name}-${index}`}
                  name={field.name}
                  type={field.type}
                  as={field.mask ? MaskedTextField : (TextField as As)}
                  placeholder={field.placeholder || field.label}
                  key={field.name}
                  disabled={field.disabled || false}
                  errorMessage={
                    errors[field.name] && touched[field.name]
                      ? errors[field.name]
                      : null
                  }
                  mask={field.mask}
                  required={field.required || false}
                  description={field.description}
                />
              </FieldTextContainer>
            );
        }
      })}
    </>
  );
}

export default BasicField;
