import produce from "immer";

import {
  ProductsCategoriesActionsTypes,
  ProductsCategoriesReducerActions,
  ProductsCategoriesState
} from "../types/ProductsCategories.reducer.types";

const INITIAL_STATE: ProductsCategoriesState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ProductsCategoriesReducerActions
): ProductsCategoriesState {
  return produce(state, draft => {
    switch (action.type) {
      case ProductsCategoriesActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ProductsCategoriesActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ProductsCategoriesActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ProductsCategoriesActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ProductsCategoriesActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ProductsCategoriesActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ProductsCategoriesActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ProductsCategoriesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case ProductsCategoriesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case ProductsCategoriesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
