import Api from "./api";

class ReportCategories extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/report-categories${subPaths}`
        : `report-categories${subPaths}`
    );
  }
}

export default ReportCategories;
