import { ListOfInventoriesOccurrences } from "./InventoriesOccurrences.types";

export enum InventoriesOccurrencesActionsTypes {
  LIST_REQUEST = "occurrences/LIST_REQUEST",
  LIST_FAIL = "occurrences/LIST_FAIL",
  LIST_SUCCESS = "occurrences/LIST_SUCCESS"
}

export interface InventoriesOccurrencesState {
  isLoadingList: boolean;
  list: ListOfInventoriesOccurrences;
}

export interface ListRequest {
  type: InventoriesOccurrencesActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: InventoriesOccurrencesActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: InventoriesOccurrencesActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfInventoriesOccurrences;
  };
}

export type InventoriesOccurrencesReducerActions =
  | ListRequest
  | ListError
  | ListSuccess;
