import { TreeDataValue } from "@/core/libs/tree-select-dropdown/TreeSelectDropdown";

export const selectCheckedBu = (
  businessUnitList: TreeDataValue[]
): TreeDataValue[] => {
  const selectedBuList = [];

  const traverse = (businessUnit: TreeDataValue) => {
    const isChecked = businessUnit?.checked;
    const hasChildren =
      businessUnit?.children && businessUnit?.children.length > 0;

    if (isChecked) {
      selectedBuList.push(businessUnit);
    } else if (hasChildren) {
      businessUnit.children.forEach(child => {
        const childIsChecked = child?.checked;
        const childHasChildren = child?.children && child.children.length > 0;
        if (childIsChecked) {
          selectedBuList.push(child);
        } else if (childHasChildren) {
          traverse(child);
        }
      });
    }
  };

  businessUnitList.forEach(traverse);

  return selectedBuList;
};
