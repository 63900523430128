import { isObjectEmpty } from "@/utils/IsObjectEmpty";
import { removeEmptyPropsFromObject } from "@/utils/RemoveEmptyPropsFromObject";

export function removeEmptyObjectsFromArray(array) {
  const newArray = array.reduce((allObjects, currentObject) => {
    removeEmptyPropsFromObject(currentObject);
    !isObjectEmpty(currentObject || {}) && allObjects.push(currentObject);
    return allObjects;
  }, []);

  return newArray;
}
