import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  editProfileFailure,
  editProfileSuccess,
  getProfileFailure,
  getProfileSuccess,
  selectBUFailure,
  selectBUSuccess
} from "../actions/profile";
import { ProfileActionsTypes } from "../types";

import { SagaCustomError } from "@/common/types";
import { UsersService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

export function* getProfile() {
  try {
    const abortController = new AbortController();
    const { signal } = abortController;

    const service = UsersService();

    const response = yield call(service.getOwnProfile, { signal });

    const selectedBU = localStorage.getItem("selectedBU");
    let primaryBU = undefined;
    if (!selectedBU) {
      if (response.data?.businessUnit && response.data?.businessUnit?.id) {
        primaryBU = response.data?.businessUnit;
      }
      if (response.data?.businessUnits && response.data?.businessUnits[0]) {
        primaryBU = response.data?.businessUnits[0];
      }

      primaryBU &&
        localStorage.setItem("selectedBU", JSON.stringify(primaryBU));
    }

    if (response.data) {
      response.data.role.permissions = response.data.role.permissions.reduce(
        (totalPermissions, currentPermission) => {
          totalPermissions[currentPermission.scope] =
            totalPermissions[currentPermission.scope] || {};

          if (
            totalPermissions[currentPermission.scope][currentPermission.module]
          ) {
            //merges actions from the same module
            totalPermissions[currentPermission.scope][
              currentPermission.module
            ].push(...currentPermission.actions);
          } else {
            //adds a new module and it's initial actions
            totalPermissions[currentPermission.scope][
              currentPermission.module
            ] = currentPermission.actions;
          }
          return totalPermissions;
        },
        Object.create(null)
      );
    }

    yield put(
      getProfileSuccess({
        user: response.data,
        selectedBU: selectedBU ? JSON.parse(selectedBU) : primaryBU
      })
    );
  } catch (error) {
    yield put(getProfileFailure());
    localStorage.removeItem("selectedBU");
  }
}

export function* editProfile({ payload }: AnyAction) {
  const { values, setSubmitting } = payload;

  setSubmitting(true);

  try {
    const abortController = new AbortController();
    const { signal } = abortController;

    const usersService = UsersService();

    const response = yield call(usersService.editOwnProfile, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editProfileSuccess({
        message: `Perfil editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );
  } catch (error) {
    yield put(
      editProfileFailure({
        code: error.statusCode,
        message: "Não foi possível editar o seu perfil",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

// TODO: update contracts when changing BU
export function* selectBU({ payload }: AnyAction) {
  const { value } = payload;
  const oldSelected = localStorage.getItem("selectedBU");

  if (!oldSelected) {
    yield put(selectBUSuccess({ selectedBU: value }));

    localStorage.setItem("selectedBU", JSON.stringify(value));
  } else {
    // TODO: validate
    if (JSON.stringify(value) === oldSelected) {
      yield put(selectBUFailure());
    } else {
      yield put(selectBUSuccess({ selectedBU: value }));

      localStorage.setItem("selectedBU", JSON.stringify(value));
    }
  }
}

export default all([
  takeLatest(ProfileActionsTypes.GET_PROFILE_REQUEST, getProfile),
  takeLatest(ProfileActionsTypes.EDIT_PROFILE_REQUEST, editProfile),
  takeLatest(ProfileActionsTypes.SELECTBU_REQUEST, selectBU)
]);
