import { useQuery } from "@tanstack/react-query";

import { useApi } from "../../useApi";

import { useAuth } from "@/core/libs/use-auth";
import { PaymentMethods } from "@/modules/Settings/types/OffersPrices.types";

export const useGetPaymentMethod = () => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();

  const context = useQuery({
    queryKey: ["payment-method"],
    queryFn: () => {
      return execute({ url: `payment-methods` });
    },
    enabled: isAuthenticated
  });

  const listOfPaymentMethods: PaymentMethods[] = context.data?.data;
  const isLoadingPaymentMethodList = context.isLoading;

  return {
    listOfPaymentMethods,
    isLoadingPaymentMethodList
  };
};
