import {
  Checkbox,
  DefaultButton,
  DialogFooter,
  Modal,
  PrimaryButton,
  Separator,
  Stack,
  mergeStyleSets
} from "@fluentui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";

import { PersonalizedOffer } from "@/components/Contracts/Step3/InsertItem/InsertItemTag";
import { useGetFeeTypeByService } from "@/core/libs/api/react-query";
import { FieldsBuilder } from "@/core/libs/form-builder";
import {
  FeeContext,
  OfferPriceFeeType,
  PaymentStrategy
} from "@/modules/Settings/types/OffersPrices.types";
import { formatToSelect } from "@/utils/FormatToSelect";

interface OfferOverrideModalProps {
  showModal: boolean;
  closeModal: () => void;
  styles?: string;
  handleSubmitModal: (data: any) => void;
  data?: PersonalizedOffer | object;
  serviceId: number;
}

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch"
  }
});

export const OfferOverrideModal = ({
  showModal,
  closeModal,
  styles = contentStyles.container,
  handleSubmitModal,
  data,
  serviceId
}: OfferOverrideModalProps): JSX.Element => {
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (data) setInitialValues(data);
  }, [data]);

  const { feeTypeList } = useGetFeeTypeByService({
    serviceId
  });

  return (
    <Modal
      isOpen={showModal}
      onDismiss={closeModal}
      isBlocking={true}
      containerClassName={styles}
    >
      {showModal ? (
        <Formik initialValues={initialValues} onSubmit={handleSubmitModal}>
          {({ errors, touched, setFieldValue, values }: any) => {
            return (
              <Form>
                <Stack tokens={{ childrenGap: 15, padding: "1rem" }}>
                  <Stack>
                    <Stack style={{ maxWidth: 450 }}>
                      <Stack.Item>
                        <h1 className="ms-fontSize-28">Pacotes de Benefício</h1>
                      </Stack.Item>

                      <Stack horizontal>
                        <Stack.Item className="ms-Grid-col ms-sm12 ms-lg6">
                          <FieldsBuilder
                            {...{
                              fields: [
                                [
                                  {
                                    label: "Valor",
                                    type: "money",
                                    name: "price",
                                    value: values?.price
                                  }
                                ]
                              ],
                              errors,
                              touched,
                              setFieldValue,
                              values
                            }}
                          />
                        </Stack.Item>

                        <Stack.Item grow className="ms-Grid-col ms-sm12 ms-lg6">
                          <FieldsBuilder
                            {...{
                              fields: [
                                [
                                  {
                                    label: "Ativação",
                                    type: "money",
                                    name: "activationFee",
                                    value: values?.activationFee
                                  }
                                ]
                              ],
                              errors,
                              touched,
                              setFieldValue,
                              values
                            }}
                          />
                        </Stack.Item>
                      </Stack>

                      <Stack.Item styles={{ root: { width: "100%" } }}>
                        <Separator />
                      </Stack.Item>

                      <Stack.Item>
                        <Checkbox
                          label="Concordo em personalizar este benefício, fazendo com que o contrato
          passe por aprovação posterior, podendo ser aprovado ou não."
                          checked={values?.isChecked}
                          onChange={(e, checked) =>
                            setFieldValue("isChecked", checked)
                          }
                        />
                      </Stack.Item>
                    </Stack>
                  </Stack>
                  <DialogFooter>
                    <PrimaryButton
                      text="Salvar Personalização"
                      type="submit"
                      disabled={!values?.isChecked}
                    />
                    <DefaultButton onClick={closeModal} text="Cancelar" />
                  </DialogFooter>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Stack style={{ alignItems: "center", marginTop: "4.5rem" }}>
          <DefaultButton
            style={{ width: "10rem" }}
            id="button-cancel"
            onClick={closeModal}
          >
            Cancelar
          </DefaultButton>
        </Stack>
      )}
    </Modal>
  );
};
