import {
  Dialog,
  DialogType,
  Modal,
  PrimaryButton,
  Stack
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Contract, ServiceCategoryType } from "../contract.interface";
import { SalesTeamSelect } from "../ContractSalesTeamInfo";
import { ContractRoaming } from "../DisplayContractInfo/ContractRoaming";

import { PersonalizeTotalFee } from "./Confirmation/PersonalizeTotalFee";
import { Witnesses } from "./Confirmation/Witnesses";
import { MembershipValidationModal } from "./MembershipValidationModal";
import { SelectSignature } from "./Signature";

import { PermissionsAction } from "@/common/types/permissions";
import { DisplayContractInfo } from "@/components/Contracts/DisplayContractInfo";
import { Card } from "@/components/Shared/Card";
import { Loader } from "@/components/Shared/Loader";
import { ContractsService } from "@/core/libs/api";
import {
  useGetPaymentConditions,
  useGetProfile
} from "@/core/libs/api/react-query";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { MessageBar, messageBarTypes } from "@/core/libs/message-bar";
import { useConfirmModal } from "@/hooks/useConfirmModal";
import { SignatureType } from "@/modules/Contracts/enums";
import { StyledStep } from "@/modules/Contracts/styles";

interface PropsStep5 {
  contract: Contract;
  refetchContractData?: () => Promise<any>;
  monthlyPaymentMethod: string;
}

function editContractRequest({ contractId, values }) {
  const abortController = new AbortController();
  const { signal } = abortController;

  const contractsService = ContractsService();
  const params = {
    id: contractId,
    body: values
  };

  return contractsService.edit({ params, signal });
}

export function Step5(props: PropsStep5): JSX.Element {
  const { contract, refetchContractData } = props;
  const [message, setMessage] = useState(undefined);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [term, setTerm] = useState(null);

  const [selectedSalesTeamId, setSelectedSalesTeam] = useState<number>(
    contract?.salesTeamId
  );
  const [travelDate, setTravelDate] = useState<Date>();
  const { execute } = useApi();

  const [showSignaturesDialog, setShowSignaturesDialog] =
    useState<boolean>(false);
  const [showConfirmContract, setShowConfirmContract] = useState(false);
  const [showTermModal, setShowTermModal] = useState(false);

  const history = useHistory();
  const { openModalWithSettings, setSubmittingConfirmModal } =
    useConfirmModal();

  const userProfile = useGetProfile();
  const userPermissions =
    userProfile?.data?.data?.role?.permissions?.pos?.contracts;

  const hasUpdateSalesTeamPermission: string = userPermissions.find(
    permissions => permissions === PermissionsAction.OVERWRITE_SALES_TEAM
  );

  const isRoamingContract =
    contract.service.id === 4 &&
    contract.serviceCategory.type === ServiceCategoryType.MOBILE_LINE_PRE;

  const refetchContract = () => {
    setIsLoading(true);
    refetchContractData().finally(() => setIsLoading(false));
  };

  const openTotalFeeOverride = () => {
    setShowDialog(true);
  };

  const cleanMessage = () => {
    setMessage(undefined);
  };

  const confirmContract = async () => {
    setMessage(undefined);
    setIsSubmitting(true);

    const abortController = new AbortController();
    const { signal } = abortController;
    const contractsService = ContractsService();
    const params: any = {
      id: contract.id
    };

    const response = await contractsService.process({ params, signal });
    if (response.error) {
      setMessage({
        message:
          response.error.message ??
          "Não foi possível processar o contrato, por favor tente novamente",
        type: messageBarTypes.ERROR
      });
      setIsSubmitting(false);
    } else {
      history.push(`/contracts/${contract.id}`);
    }
  };

  useEffect(() => {
    setSubmittingConfirmModal(isSubmitting);
  }, [isSubmitting, setSubmittingConfirmModal]);

  async function openModal() {
    const res = await execute({
      url: `terms/validate-account/${contract.account.id}`,
      method: "GET",
      notificationMessage: "Validando adesão do cooperado à Coopercompany",
      params: Object(contract.account.id)
    });
    const { term, isMember } = res.data;
    setTerm(term);
    const associationRequired =
      contract.contractModel.serviceModelOptions[0].associationRequired;
    if (associationRequired && !isMember) {
      setShowTermModal(true);
    } else {
      setShowSignaturesDialog(true);
    }
  }

  async function openModalConfirmContract() {
    return openModalWithSettings(
      "Finalizando contrato",
      "Deseja confirmar este contrato?",
      async () => {
        await confirmContract();
      }
    );
  }

  const { data: invoiceInfos } = useGetPaymentConditions({
    contractId: props.contract.id
  });

  return (
    <>
      {isLoading || isSubmitting ? (
        <Loader />
      ) : (
        <Stack tokens={{ childrenGap: 20 }} style={{ position: "relative" }}>
          <StyledStep>
            <Stack
              tokens={{ childrenGap: 15 }}
              styles={{ root: { marginTop: 15 } }}
            >
              {message && (
                <MessageBar message={message} dismissMessage={cleanMessage} />
              )}

              <DisplayContractInfo
                clientInfoTitle={"Dados do cooperado"}
                contractInfoTitle={"Dados base"}
                contract={contract}
                refetchContractData={refetchContractData}
                className="ms-sm12"
                isInsideStep={true}
                openTotalFeeOverride={openTotalFeeOverride}
                hideContractValues={true}
                hidePaymentArea={true}
                invoiceInfos={invoiceInfos}
              />

              {hasUpdateSalesTeamPermission && (
                <SalesTeamSelect
                  salesTeamId={selectedSalesTeamId}
                  handleChangeSalesTeam={option => {
                    setSelectedSalesTeam(Number(option.key));
                  }}
                />
              )}

              {isRoamingContract && (
                <ContractRoaming
                  travelDate={travelDate}
                  setTravelDate={setTravelDate}
                />
              )}

              {contract?.signature?.type === SignatureType.STANDARD ? (
                <Card>
                  <Stack tokens={{ padding: "m" }}>
                    <Stack.Item>
                      <Witnesses
                        contract={contract}
                        processContract={confirmContract}
                      />
                    </Stack.Item>
                  </Stack>
                </Card>
              ) : (
                <Stack.Item align="center">
                  <PrimaryButton
                    id="button-confirm-contract"
                    text="Confirmar Contrato"
                    onClick={openModal}
                    disabled={isSubmitting}
                  />
                </Stack.Item>
              )}
            </Stack>
          </StyledStep>

          <Modal
            isOpen={showTermModal}
            onDismiss={() => setShowTermModal(false)}
            isBlocking={false}
          >
            <MembershipValidationModal
              term={term}
              showTermModal={setShowTermModal}
            />
          </Modal>

          <SelectSignature
            showSignatures={showSignaturesDialog}
            setShowSignaturesDialog={setShowSignaturesDialog}
            contract={contract}
            refetchContract={refetchContract}
            openModalConfirmContract={openModalConfirmContract}
            setShowConfirmContract={setShowConfirmContract}
            selectedSalesTeamId={selectedSalesTeamId}
            travelDate={travelDate}
          />

          <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Edição de valores",
              subText:
                "Contratos com valores personalizadas estão sujeitos à aprovação.",
              styles: { innerContent: { position: "static" } }
            }}
            onDismiss={() => setShowDialog(false)}
          >
            <PersonalizeTotalFee
              editContractRequest={editContractRequest}
              closePersonalization={() => setShowDialog(false)}
              fields={contract?.totalFeeOverrideFields}
              contractId={contract.id}
              refetchContract={refetchContract}
            />
          </Dialog>
        </Stack>
      )}
    </>
  );
}
