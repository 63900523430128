import { IAdditionalOfferPrice } from "@/components/Settings/AdditionalComponents/types";
import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";

export function calcMinValue(
  additionalOffersPrice: IAdditionalOfferPrice[],
  value: number
): string {
  const requiredServiceFees = calculateRequiredServiceFees(
    additionalOffersPrice
  );

  const cheapestUniqueServiceMonthlyFee = getCheapestUniqueServiceFee(
    additionalOffersPrice
  );

  const totalWithUnique =
    requiredServiceFees + parseFloat(cheapestUniqueServiceMonthlyFee);

  const finalTotalValue = totalWithUnique + value;

  return transformToCurrencyString(finalTotalValue);
}

const calculateRequiredServiceFees = (
  services: IAdditionalOfferPrice[]
): number => {
  return services
    .filter(service => service.additionalOffer?.required)
    .reduce(
      (total, service) => total + parseFloat(service.monthlyFee || "0.00"),
      0
    );
};

const getCheapestUniqueServiceFee = (
  services: IAdditionalOfferPrice[]
): string => {
  const uniqueServices = services.filter(service => {
    const isUnique =
      service.additionalOffer?.additional.additionalCategory.isUnique;
    const isNotRequired = !service.additionalOffer?.required;
    return isUnique && isNotRequired;
  });

  if (uniqueServices.length === 0) {
    return "0.00";
  }

  const cheapestUniqueService = uniqueServices.reduce(
    (cheapestService, currentService) => {
      return parseFloat(currentService.monthlyFee) <
        parseFloat(cheapestService.monthlyFee)
        ? currentService
        : cheapestService;
    },
    uniqueServices[0]
  );

  return cheapestUniqueService.monthlyFee;
};
