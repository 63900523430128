import { PaddingBottomIcon } from "@fluentui/react-icons-mdl2";
import { FocusTrapZone } from "@fluentui/react/lib/FocusTrapZone";
import { IStackStyles, Stack } from "@fluentui/react/lib/Stack";
import { ReactNode } from "react";

import { AuthArea, AuthWrapper } from "./Layout.styles";

import logoLibertas from "@/assets/images/login-background.svg";

const stackStyles: IStackStyles = {
  root: {
    minWidth: "320px",
    maxWidth: "320px"
  }
};

type LayoutProps = {
  children: ReactNode;
};

export function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <AuthWrapper>
      <Stack
        style={{
          //background: "#96969646", //b8b8b846
          alignContent: "center",
          minWidth: "500px",
          maxWidth: "500px",
          alignItems: "center",
          borderRadius: "10px",
          padding: "0px 0px 80px 0px"
        }}
        tokens={{ childrenGap: 25 }}
      >
        <Stack tokens={{ childrenGap: 1 }}></Stack>
        <AuthArea>
          <Stack.Item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div
              style={{
                width: "fit-content",
                height: "fit-content",
                marginBottom: 8
              }}
            >
              <img
                src={logoLibertas}
                alt="Libertas logo"
                height="35"
                srcSet={`
                `}
              />
            </div>
          </Stack.Item>
          <FocusTrapZone>{children}</FocusTrapZone>
        </AuthArea>
      </Stack>
    </AuthWrapper>
  );
}
