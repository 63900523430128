import produce from "immer";

import {
  PricingGroupsActionsTypes,
  PricingGroupsReducerActions,
  PricingGroupsState
} from "../types/PricingGroups.reducer.types";

const INITIAL_STATE: PricingGroupsState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: PricingGroupsReducerActions
): PricingGroupsState {
  return produce(state, draft => {
    switch (action.type) {
      case PricingGroupsActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case PricingGroupsActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case PricingGroupsActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case PricingGroupsActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case PricingGroupsActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case PricingGroupsActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case PricingGroupsActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case PricingGroupsActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case PricingGroupsActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case PricingGroupsActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
