import { SidebarMenuActionsTypes } from "../types/SidebarMenu.actions.types";

export function OpenSidebarMenu() {
  return {
    type: SidebarMenuActionsTypes.OPEN_SIDEBAR_MENU
  };
}

export function CloseSidebarMenu() {
  return {
    type: SidebarMenuActionsTypes.CLOSE_SIDEBAR_MENU
  };
}

export function ExpandSidebarMenu() {
  return {
    type: SidebarMenuActionsTypes.EXPAND_SIDEBAR_MENU
  };
}

export function RetractSidebarMenu() {
  return {
    type: SidebarMenuActionsTypes.RETRACT_SIDEBAR_MENU
  };
}
