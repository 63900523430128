import { ListOfBusinessUnits } from "./BusinessUnits.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum BusinessUnitsActionsTypes {
  CREATE_BU_REQUEST = "business-units/CREATE_BU_REQUEST",
  CREATE_BU_FAIL = "business-units/CREATE_BU_FAIL",
  CREATE_BU_SUCCESS = "business-units/CREATE_BU_SUCCESS",
  DISMISS_MESSAGE = "business-units/DISMISS_MESSAGE",
  EDIT_BU_REQUEST = "business-units/EDIT_BU_REQUEST",
  EDIT_BU_FAIL = "business-units/EDIT_BU_FAIL",
  EDIT_BU_SUCCESS = "business-units/EDIT_BU_SUCCESS",
  LIST_BU_REQUEST = "business-units/LIST_BU_REQUEST",
  LIST_BU_FAIL = "business-units/LIST_BU_FAIL",
  LIST_BU_SUCCESS = "business-units/LIST_BU_SUCCESS"
}

export interface BusinessUnitsState {
  isLoadingList: boolean;
  list: ListOfBusinessUnits;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: BusinessUnitsActionsTypes.CREATE_BU_REQUEST;
}

export interface CreateError {
  type: BusinessUnitsActionsTypes.CREATE_BU_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: BusinessUnitsActionsTypes.CREATE_BU_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: BusinessUnitsActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: BusinessUnitsActionsTypes.EDIT_BU_REQUEST;
}

export interface EditError {
  type: BusinessUnitsActionsTypes.EDIT_BU_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: BusinessUnitsActionsTypes.EDIT_BU_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: BusinessUnitsActionsTypes.LIST_BU_REQUEST;
}

export interface ListError {
  type: BusinessUnitsActionsTypes.LIST_BU_FAIL;
}

export interface ListSuccess {
  type: BusinessUnitsActionsTypes.LIST_BU_SUCCESS;
  payload: {
    list: ListOfBusinessUnits;
  };
}

export type BusinessUnitsReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
