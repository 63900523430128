import { useState } from "react";

import {
  ImageTitle,
  ProductBrand,
  ProductTitle,
  SpecificationContainer,
  SpecificationItem,
  SpecificationTitle,
  ThumbnailImage
} from "./ProductComboModal.styles";

import { SlideShow } from "@/components/Shared/SlideShow";
import { TableImage } from "@/components/Shared/TableImage";
import { ProductOfferItem } from "@/modules/Offers/types/ProductsOffers.types";

type ProductImageInfoProps = {
  productOffer: ProductOfferItem;
};

const orderImages = (a, b) => {
  return a?.order - b?.order;
};

const productSimQuantity = {
  1: "Single SIM",
  2: "Dual SIM",
  3: "Tri SIM",
  4: "Quadri SIM"
};

export function ProductImageInfo({
  productOffer
}: ProductImageInfoProps): JSX.Element {
  const [initiaImglIndex, setInitialImgIndex] = useState<number>(0);

  return (
    <>
      <ImageTitle>
        {productOffer?.name && (
          <ProductTitle className="ms-fontSize-32 ms-fontWeight-semibold">
            {productOffer.name}
          </ProductTitle>
        )}

        {productOffer?.brand?.name && (
          <ProductBrand className="ms-fontSize-20" style={{ marginBottom: 10 }}>
            {productOffer.brand.name}
          </ProductBrand>
        )}
      </ImageTitle>

      {productOffer?.images.length > 0 && (
        <>
          <SlideShow
            images={productOffer.images.sort(orderImages)}
            initialSlideIndex={initiaImglIndex}
            setInitialSlideIndex={setInitialImgIndex}
            height="300px"
            maxWidth="300px"
            maxImageHeight="300px"
            maxImageWidth="100%"
          />
          <ThumbnailImage>
            {productOffer.images.sort(orderImages).map((image, index) => (
              <TableImage
                key={"table image" + image.id}
                imgFile={image}
                onClick={() => setInitialImgIndex(index)}
              />
            ))}
          </ThumbnailImage>
        </>
      )}

      {productOffer?.specifications && (
        <SpecificationContainer>
          <SpecificationTitle className="ms-fontSize-28 ms-fontWeight-semibold">
            Especificações
          </SpecificationTitle>

          {productOffer.specifications.memory?.storage && (
            <SpecificationItem className="ms-fontSize-16">
              {productOffer.specifications.memory.storage} GB de armazenamento
            </SpecificationItem>
          )}

          {productOffer.specifications.screen?.size && (
            <SpecificationItem className="ms-fontSize-16">
              Tela de {productOffer.specifications.screen.size}
            </SpecificationItem>
          )}

          {productOffer.specifications.battery?.capacity && (
            <SpecificationItem className="ms-fontSize-16">
              Bateria de {productOffer.specifications.battery.capacity} mAh
            </SpecificationItem>
          )}

          {productOffer.specifications.sims?.length > 0 && (
            <SpecificationItem className="ms-fontSize-16">
              {productSimQuantity[productOffer.specifications.sims.length]}
            </SpecificationItem>
          )}

          {productOffer.specifications.os?.os && (
            <SpecificationItem className="ms-fontSize-16">
              {productOffer.specifications.os.os}
            </SpecificationItem>
          )}

          {productOffer.specifications.hardware?.processor && (
            <SpecificationItem className="ms-fontSize-16">
              {productOffer.specifications.hardware.processor}
            </SpecificationItem>
          )}

          {productOffer.specifications.connectivies?.length > 0 && (
            <SpecificationItem className="ms-fontSize-16">
              {productOffer.specifications.connectivies.map(
                (network, index) => (
                  <span key={`${network.network}${index}`}>
                    {network.network}G{" "}
                  </span>
                )
              )}
              {productOffer.specifications.physicalProperty?.hasWifi && (
                <span>e Wi-Fi</span>
              )}
            </SpecificationItem>
          )}

          {productOffer.specifications.cameras?.length > 0 && (
            <SpecificationItem className="ms-fontSize-16">
              Câmera{" "}
              {productOffer.specifications.cameras.map((camera, index) => (
                <span key={`${camera.side}${index}`}> {camera.resolution}</span>
              ))}
            </SpecificationItem>
          )}
        </SpecificationContainer>
      )}
    </>
  );
}
