import {
  getTheme,
  IObjectWithKey,
  Label,
  Selection,
  SelectionMode,
  Stack
} from "@fluentui/react";
import { useEffect, useRef, useState } from "react";

import { ListData } from "@/core/libs/list-data";

type TableFieldProps = {
  fieldConfig: any;
  currentSelectedValues: any;
  setFieldValue: (fieldName, fieldValue) => void;
  setFieldTouched: (fieldName, isTouched, sholdValidate) => void;
  errorMessage: string;
};

export function TableField({
  fieldConfig,
  currentSelectedValues,
  setFieldValue,
  setFieldTouched,
  errorMessage
}: TableFieldProps): JSX.Element {
  const [items, setItems] = useState(null);
  const { current: selection } = useRef<Selection<IObjectWithKey>>(
    new Selection({
      onSelectionChanged: () => {
        setFieldTouched(fieldConfig.name, true, false);
        const item = selection.getSelection();

        if (item.length > 0) {
          fieldConfig.selectionMode === SelectionMode.multiple
            ? setFieldValue(fieldConfig.name, item || [])
            : setFieldValue(fieldConfig.name, item[0]);
        } else if (selection.getItems().filter(item => !!item).length > 0) {
          setFieldValue(fieldConfig.name, undefined);
        }
      }
    })
  );
  const theme = getTheme();

  useEffect(() => {
    const itemsWithKey =
      fieldConfig.items?.items
        ?.filter(
          item => !currentSelectedValues?.some(value => value?.id === item?.id)
        )
        ?.map(item => ({
          ...item,
          key: item?.id
        })) ?? [];

    const currentSelectedValuesWithKey =
      currentSelectedValues?.map(item => ({
        ...item,
        key: item?.id
      })) ?? [];

    const allItems = [...currentSelectedValuesWithKey, ...itemsWithKey];

    setItems({ items: allItems, meta: fieldConfig.items?.meta });
    //eslint-disable-next-line
  }, [fieldConfig.items]);

  useEffect(() => {
    selection.setAllSelected(false);

    currentSelectedValues &&
      currentSelectedValues.forEach(item => {
        selection.setKeySelected(item?.id, true, true);
      });
    //eslint-disable-next-line
  }, [items]);

  return (
    <Stack>
      <Label required={fieldConfig.required || false}>
        {fieldConfig.label}
      </Label>

      <Stack
        style={{
          border: "1px solid",
          borderRadius: "2px",
          borderColor: errorMessage && theme.palette.redDark
        }}
      >
        <ListData
          isRowSelectable
          customSelection={selection}
          headerAction={undefined}
          headerText="Cargo"
          items={items || []}
          isLoadingList={fieldConfig.isLoadingItems || false}
          columns={fieldConfig.columns}
          selectionMode={fieldConfig.selectionMode}
          _paginate={fieldConfig.paginate}
          isCheckSelection={fieldConfig.isCheckSelection}
          selectionPreservedOnEmptyClick={
            fieldConfig.selectionPreservedOnEmptyClick
          }
          hasFilters={fieldConfig.hasFilters}
          filters={fieldConfig.filters}
          isDefaultFilterVisible={fieldConfig.isDefaultFilterVisible}
          menuItems={undefined}
          setSelectedItem={null}
        />
      </Stack>

      {errorMessage && (
        <span
          style={{ fontSize: 12, color: theme.palette.redDark, paddingTop: 5 }}
        >
          {errorMessage}
        </span>
      )}
    </Stack>
  );
}
