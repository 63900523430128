import { ServiceProvidersActionsTypes } from "../types/ServiceProviders.reducer.types";

export function createServiceProvidersRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ServiceProvidersActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createServiceProvidersSuccess(message) {
  return {
    type: ServiceProvidersActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createServiceProvidersFailure(message) {
  return {
    type: ServiceProvidersActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editServiceProvidersRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ServiceProvidersActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editServiceProvidersSuccess(message) {
  return {
    type: ServiceProvidersActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editServiceProvidersFailure(message) {
  return {
    type: ServiceProvidersActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listServiceProvidersRequest(filter, { isAdmin = false }) {
  return {
    type: ServiceProvidersActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listServiceProvidersSucces({ list }) {
  return {
    type: ServiceProvidersActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listServiceProvidersFailure() {
  return {
    type: ServiceProvidersActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ServiceProvidersActionsTypes.DISMISS_MESSAGE
  };
}
