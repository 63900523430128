import { ListOfModelsGroups } from "./ModelsGroups.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum ModelsGroupsActionsTypes {
  CREATE_REQUEST = "models-groups/CREATE_REQUEST",
  CREATE_FAIL = "models-groups/CREATE_FAIL",
  CREATE_SUCCESS = "models-groups/CREATE_SUCCESS",
  DISMISS_MESSAGE = "models-groups/DISMISS_MESSAGE",
  EDIT_REQUEST = "models-groups/EDIT_REQUEST",
  EDIT_FAIL = "models-groups/EDIT_FAIL",
  EDIT_SUCCESS = "models-groups/EDIT_SUCCESS",
  DELETE_REQUEST = "models-groups/DELETE_REQUEST",
  DELETE_FAIL = "models-groups/DELETE_FAIL",
  DELETE_SUCCESS = "models-groups/DELETE_SUCCESS",
  LIST_REQUEST = "models-groups/LIST_REQUEST",
  LIST_FAIL = "models-groups/LIST_FAIL",
  LIST_SUCCESS = "models-groups/LIST_SUCCESS"
}

export interface ModelsGroupsState {
  isLoadingList: boolean;
  list: ListOfModelsGroups;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: ModelsGroupsActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: ModelsGroupsActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: ModelsGroupsActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: ModelsGroupsActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: ModelsGroupsActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: ModelsGroupsActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: ModelsGroupsActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DeleteRequest {
  type: ModelsGroupsActionsTypes.DELETE_REQUEST;
}

export interface DeleteError {
  type: ModelsGroupsActionsTypes.DELETE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface DeleteSuccess {
  type: ModelsGroupsActionsTypes.DELETE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: ModelsGroupsActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: ModelsGroupsActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: ModelsGroupsActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfModelsGroups;
  };
}

export type ModelsGroupsReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | DeleteRequest
  | DeleteError
  | DeleteSuccess
  | ListRequest
  | ListError
  | ListSuccess;
