export interface ILocationsApi {
  fetchStates: () => Promise<{ key: number; text: string }[]>;
  fetchCities: (stateId: number) => Promise<{ key: string; text: string }[]>;
}

export class PrimaryApiService implements ILocationsApi {
  async fetchStates() {
    try {
      const response = await fetch(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );
      const statesData = await response.json();
      return statesData.map(state => ({ key: state.id, text: state.nome }));
    } catch (error) {
      throw new Error("Primary API failed");
    }
  }

  async fetchCities(stateId) {
    try {
      const response = await fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`
      );
      const citiesData = await response.json();
      return citiesData.map(city => ({ key: city.nome, text: city.nome }));
    } catch (error) {
      throw new Error("Primary API failed");
    }
  }
}

export class FallbackApiService implements ILocationsApi {
  async fetchStates() {
    try {
      const response = await fetch("https://brasilapi.com.br/api/ibge/uf/v1");
      const statesData = await response.json();
      return statesData.map(state => ({ key: state.sigla, text: state.nome }));
    } catch (error) {
      throw new Error("Fallback API failed");
    }
  }

  async fetchCities(stateId) {
    try {
      const response = await fetch(
        `https://brasilapi.com.br/api/ibge/municipios/v1/${stateId}?providers=gov,wikipedia,dados-abertos-br`
      );
      const citiesData = await response.json();
      return citiesData.map(city => ({ key: city.nome, text: city.nome }));
    } catch (error) {
      throw new Error("Fallback API failed");
    }
  }
}
