import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { useApi } from "../../useApi";

import { useAuth } from "@/core/libs/use-auth";
import { useSelectedBu } from "@/hooks/useSelectedBu";

export const useGetTags = () => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const { selectedBU } = useSelectedBu();
  const tagsQueryClient = useQueryClient();

  useEffect(() => {
    tagsQueryClient.invalidateQueries(["tags"]);
  }, [selectedBU]);

  const context = useQuery(
    ["tags"],
    () => {
      const selectedBu = JSON.parse(localStorage.getItem("selectedBU"));
      const bu = selectedBu.id;

      return execute({
        url: `dashboards/tags?query=true`,
        notificationMessage: "Buscando tags dashboard na página inicial",
        params: {
          businessActionId: bu
        }
      });
    },
    {
      staleTime: Infinity,
      enabled: isAuthenticated
    }
  );

  const listOfTags = context.data?.data;
  const isLoadingTagOffersList = context.isLoading;

  return {
    listOfTags,
    isLoadingTagOffersList
  };
};
