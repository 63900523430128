import styled from "styled-components";

export const Wrapper = styled.div`
  width: 30rem;
  height: 20rem;
  padding: 2rem;
  p {
    line-height: 3rem;
    font-size: 1.1rem;
    color: #201f1e;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 0.5rem;

  h1 {
    font-size: 1.5rem;
    color: #106ebe;
    text-align: left;
    font-weight: bold;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;
