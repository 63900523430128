import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useSelectedBu } from "./useSelectedBu";

import { useGetProfile } from "@/core/libs/api/react-query";
import { filterParamsInObject } from "@/core/libs/api/utils/filterParamsInObject";
import {
  formatURLParams,
  parseURLParams
} from "@/core/libs/api/utils/formatUrlParams";
import { findModuleByLocation } from "@/utils/findModuleByLocation";

export interface UrlParams {
  pathname: string;
  params: Record<string, string>;
}

export interface UrlFilterContextData {
  changeUrlParams: (params?: object | Record<string, string>) => void;
  urlParams: { pathname?: string; params?: Record<string, string> };
  setUrlParams: React.Dispatch<React.SetStateAction<UrlParams>>;
}

const UrlFilterContext = createContext<UrlFilterContextData>(
  {} as UrlFilterContextData
);

export const UrlFilterProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const history = useHistory();
  const { selectedBU, handleSelectBu } = useSelectedBu();
  const userProfile = useGetProfile();

  const pathName = findModuleByLocation(location);
  const paramsObj = parseURLParams(location.search);

  const [urlParams, setUrlParams] = useState<UrlParams>({
    pathname: pathName,
    params: paramsObj
  });
  const [isInitialValueSet, setIsInitialValueSet] = useState(false);

  const businessUnitSelectedParam = paramsObj["businessUnitSelected"];

  const businessUnitSelectedParamRef = useRef(
    businessUnitSelectedParam
  ).current;

  const changeUrlParams = useCallback(
    (params?: object | Record<string, string>) => {
      const newParams = formatURLParams(params);
      const actualURL = location.pathname;
      const newURL = `${actualURL}?${newParams}`;
      history.replace(newURL);

      if (params === urlParams.params) return;

      const filteredURLParams = filterParamsInObject(urlParams.params, params);

      if (filteredURLParams["businessUnitSelected"]) {
        delete filteredURLParams["businessUnitSelected"];
      }

      setUrlParams({
        pathname: findModuleByLocation(location),
        params: { ...filteredURLParams, ...params }
      });
    },
    [history, location, urlParams.params]
  );

  useEffect(() => {
    if (isInitialValueSet && selectedBU) {
      changeUrlParams({ ...paramsObj, businessUnitSelected: selectedBU.id });
    }
  }, [selectedBU, isInitialValueSet]);

  useEffect(() => {
    if (businessUnitSelectedParam && userProfile?.data && !isInitialValueSet) {
      handleSelectBu(Number(businessUnitSelectedParamRef));
      setIsInitialValueSet(true);
    }
  }, [
    userProfile,
    isInitialValueSet,
    businessUnitSelectedParam,
    businessUnitSelectedParamRef
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObj = Object.fromEntries(searchParams);

    const pathname = findModuleByLocation(location);
    if (pathname !== urlParams.pathname) {
      setUrlParams({
        pathname: pathname,
        params: paramsObj
      });
    }
  }, [location, location.pathname, urlParams.pathname]);

  return (
    <UrlFilterContext.Provider
      value={{ changeUrlParams, urlParams, setUrlParams }}
    >
      {children}
    </UrlFilterContext.Provider>
  );
};

export const useURLFilter = () => {
  const context = useContext(UrlFilterContext);
  return context;
};
