import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { fields as fieldsAccountLegal } from "../../fields/AccountsLegal.fields";
import { fields } from "../../fields/AccountsPhysical.fields";
import {
  getLegalAccountSchema,
  getPhysicalAccountSchema
} from "../../schemas/accounts.schema";
import {
  AccountType,
  EditAccount,
  EditProps,
  PaymentMethod
} from "../../types/Accounts.types";

import { FindParam } from "./enums/FindParam";

import { CustomDrawerFooter } from "@/components/Shared/CustomDrawer";
import { Loader } from "@/components/Shared/Loader";
import { AccountsService } from "@/core/libs/api";
import { useGetProfile } from "@/core/libs/api/react-query";
import useGetLocations from "@/core/libs/api/react-query/hook/use-get-locations";
import { useApi } from "@/core/libs/api/react-query/useApi";
import FormBuilder from "@/core/libs/form-builder";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { formatDate } from "@/utils/FormatDate";

const Accounts = AccountsService(undefined);
const { signal } = new AbortController();

export function EditAccounts({
  edit,
  closeDrawer,
  dismissMessage,
  drawerContainerRef,
  message,
  selectedItem,
  addWhatsApp,
  maritalStatusOptions,
  checkingAccountsRequired,
  isTerm
}: EditProps): JSX.Element {
  const formId = "edit-accounts-form";

  const [accountData, setAccountData] = useState<EditAccount>();
  const [isFetching, setIsFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [salesTeamList, setSalesTeamsList] = useState([]);

  const { execute } = useApi();
  const { selectedBU } = useSelectedBu();

  const userProfile = useGetProfile();
  const { cities, states, onStateChange, fetchStates } = useGetLocations();

  const permissions = userProfile.data?.data?.role?.permissions;

  let isSubmitted = false;

  if (message && message.type === "success") isSubmitted = true;

  async function findState(
    state: string,
    findParam: FindParam = FindParam.KEY
  ) {
    const statesFound = await fetchStates();
    return statesFound?.find(item => item[findParam] === state);
  }

  async function requestAccount() {
    const resultAccount = await Accounts.get({
      id: selectedItem,
      signal,
      params: { query: { businessActionId: selectedBU?.id } }
    });

    if (resultAccount?.data) {
      const account = resultAccount?.data;
      const hasWhatsApp = account.contacts.find(
        contact => contact.contactType === "WHATSAPP"
      );
      if (
        !hasWhatsApp &&
        account.accountType === AccountType.PHYSICAL &&
        addWhatsApp
      ) {
        setAccountData({
          ...account,
          contacts: [
            ...account.contacts,
            {
              contact: "",
              contactType: "WHATSAPP",
              id: account.id,
              objectTypeCode: "ACCOUNT",
              parentTypeCodeId: account.id
            }
          ]
        });
      } else {
        if (account?.naturalnessState) {
          const stateFound = await findState(
            account.naturalnessState,
            FindParam.TEXT
          );

          onStateChange({
            key: stateFound?.key,
            value: stateFound?.text
          });
        }

        setAccountData({
          ...account
        });
      }
    }
  }

  function handleCloseDrawer() {
    closeDrawer();
    dismissMessage();
  }

  async function submitForm(values: any, { setSubmitting }) {
    setSubmitting(true);
    const commonObject = {
      genderStatus: values?.accountLegal ? "" : values.account[0].genderStatus,
      documents: values?.documents ?? values?.documentsLegal,
      birthDate: values?.accountLegal ? "" : values.account[0].birthDate,
      contacts: values?.contacts,
      checkingAccounts: values?.checkingAccounts,
      legalRepresentatives: values?.legalRepresentatives || [],
      addresses: values?.addresses.map(address => ({
        ...address,
        state: address.state.replace(/[^a-zA-Z0-9]/g, "")
      })),
      salesTeamId: values?.accountSalesTeam,
      fullName: values?.accountLegal
        ? values.accountLegal[0].fullName
        : values.account[0].fullName
    };

    if (!values?.accountLegal) {
      const stateFound = await findState(values.state, FindParam.KEY);

      const naturalness = [
        {
          state: stateFound?.text,
          city: values.city
        }
      ];

      values?.naturalness &&
        Object.assign(commonObject, {
          ...commonObject,
          naturalness
        });
    }

    values?.account &&
      Object.assign(commonObject, {
        ...commonObject,
        ...values.account[0]
      });
    values?.accountLegal &&
      Object.assign(commonObject, {
        ...commonObject,
        ...values.accountLegal[0]
      });

    const { fullName, ...commonObjectWithoutName } = commonObject;

    const res = await edit(
      {
        body: commonObjectWithoutName,
        query: { businessActionId: accountData?.businessUnit.id }
      },
      { setSubmitting },
      handleCloseDrawer
    );

    if (!res?.response?.ok) {
      setErrorMessage(res?.error?.message);
    }

    setSubmitting(false);
  }

  const getSalesTeamsFromApi = useCallback(async () => {
    const res = await execute({
      url: "sales-teams",
      notificationMessage:
        "Buscando times comerciais na página de criação de cooperado"
    });

    let options = [];

    if (res.data) {
      options = res.data.items.map(option => {
        return {
          key: option.id,
          text: `${option.name} ${
            option.parent?.name ? `(${option.parent.name})` : ""
          }`
        };
      });
    }

    setSalesTeamsList(options);
  }, [execute]);

  useEffect(() => {
    setIsFetching(true);
    getSalesTeamsFromApi();
    Promise.all([requestAccount()]).then(() => setIsFetching(false));
  }, []);

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <Stack tokens={{ childrenGap: 5 }}>
          <FormBuilder
            formId={formId}
            fields={
              accountData?.accountType === AccountType.PHYSICAL
                ? fields.edit({
                    maritalStatusOptions: maritalStatusOptions,
                    initialValues: accountData ?? null,
                    isEditField: true,
                    bankNumberValue: selectedBU?.bankNumber,
                    agencyNumberValue: selectedBU?.agencyNumber,
                    executeOnChange: onStateChange,
                    states,
                    cities,
                    bankAccountRequired: isTerm
                      ? false
                      : checkingAccountsRequired ||
                        selectedBU.paymentMethod ===
                          PaymentMethod.DEBITO_AUTOMATICO,
                    salesTeamList: salesTeamList || [],
                    permissions: permissions ?? [],
                    whatsappRequired: addWhatsApp
                  })
                : fieldsAccountLegal.edit({
                    initialValues: accountData ?? null,
                    isEditField: true,
                    bankNumberValue: selectedBU?.bankNumber,
                    agencyNumberValue: selectedBU?.agencyNumber,
                    bankAccountRequired: isTerm
                      ? false
                      : checkingAccountsRequired ||
                        selectedBU.paymentMethod ===
                          PaymentMethod.DEBITO_AUTOMATICO,
                    salesTeamList: salesTeamList || [],
                    permissions: permissions ?? [],
                    whatsappRequired: addWhatsApp,
                    requireWhatsappForLegalRepresentative: addWhatsApp
                  })
            }
            onSubmit={submitForm}
            message={message}
            dismissMessage={dismissMessage}
            dismissPanel={handleCloseDrawer}
            validationSchema={
              accountData?.accountType === AccountType.PHYSICAL
                ? getPhysicalAccountSchema({
                    checkingAccountsRequired: isTerm
                      ? false
                      : checkingAccountsRequired ||
                        selectedBU.paymentMethod ===
                          PaymentMethod.DEBITO_AUTOMATICO
                  })
                : getLegalAccountSchema({
                    checkingAccountsRequired: isTerm
                      ? false
                      : checkingAccountsRequired ||
                        selectedBU.paymentMethod ===
                          PaymentMethod.DEBITO_AUTOMATICO
                  })
            }
          >
            {accountData && (
              <>
                {accountData?.user?.fullName ? (
                  <Text>
                    Ultima atualização por:
                    <b> {accountData?.user?.fullName} </b>
                  </Text>
                ) : (
                  <></>
                )}
                <Text>
                  Data da ultima atualização:{" "}
                  <b>
                    {formatDate(accountData?.updatedAt.toString())}{" "}
                    {new Date(accountData?.updatedAt)?.getHours()}:
                    {new Date(accountData?.updatedAt)?.getMinutes()}
                  </b>
                </Text>
              </>
            )}

            <CustomDrawerFooter drawerContainerRef={drawerContainerRef}>
              {errorMessage && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline>
                  {errorMessage}
                </MessageBar>
              )}
              <DefaultButton
                id="close-account-drawer-button"
                onClick={handleCloseDrawer}
                style={{ margin: "12px" }}
              >
                {!isSubmitted ? "Cancelar" : "Fechar"}
              </DefaultButton>

              <PrimaryButton
                id="create-account-button"
                type="primary"
                form={formId}
                disabled={isSubmitted}
              >
                Editar
              </PrimaryButton>
            </CustomDrawerFooter>
          </FormBuilder>
        </Stack>
      )}
    </>
  );
}
