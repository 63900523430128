import Api from "./api";

class EmailTemplate extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin ? `admin/email-template${subPaths}` : `email-template${subPaths}`
    );
  }
}

export default EmailTemplate;
