import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { listFailure, listSuccess } from "../actions/productsOffers";
import { ProductsOffersActionsTypes } from "../types/ProductsOffers.reducer.types";

import { DashboardsService } from "@/core/libs/api";

const abortController = new AbortController();
const { signal } = abortController;
const service = DashboardsService("/products");

const orderValues = (a, b) => {
  return Number(a) - Number(b);
};

export function* listProductsOffers({ payload }: AnyAction) {
  const { filter } = payload;

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    if (response?.data?.products?.items.length > 0) {
      // eslint-disable-next-line
      response?.data?.products.items.forEach(offer => {
        const allCashValue = [];
        const allCarriers = [];
        const allData = [];

        if (offer?.saleConditions?.length > 0) {
          offer.saleConditions.forEach(saleCondition => {
            const summedData =
              Number(saleCondition.offerPrice?.offer?.bonusData?.value || 0) +
              Number(saleCondition.offerPrice?.offer?.plan?.data || 0);

            if (!allCashValue.includes(saleCondition?.cashValue)) {
              allCashValue.push(saleCondition?.cashValue);
            }

            if (
              !allCarriers.includes(
                saleCondition?.offerPrice?.offer?.plan?.carrier?.name
              )
            ) {
              allCarriers.push(
                saleCondition?.offerPrice?.offer?.plan?.carrier?.name
              );
            }

            if (!allData.includes(summedData)) {
              allData.push(summedData);
            }

            saleCondition.carrier =
              saleCondition?.offerPrice?.offer?.plan?.carrier?.name;

            saleCondition.summedData = summedData;
          });
        }

        if (allCashValue.length > 0) {
          allCashValue.sort(orderValues);
        }

        if (allData.length > 0) {
          allData.sort(orderValues);
        }

        offer.allCashValue = allCashValue;
        offer.allCarriers = allCarriers;
        offer.allData = allData;
      });
    }

    yield put(listSuccess({ list: response.data }));
  } catch (error) {
    yield put(listFailure());
  }
}

export default all([
  takeLatest(ProductsOffersActionsTypes.LIST_REQUEST, listProductsOffers)
]);
