import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useAsyncCallback } from "react-async-hook";

import { ContractsService, ProductsService } from "@/core/libs/api";
import { TextFilter } from "@/core/libs/list-data/lib/Filters";

const productsService = ProductsService();
const contractsService = ContractsService();

interface ChipReplacementDialogProps {
  item: any;
  service: number;
  businessUnitId: number;
  onSuccess: () => void;
  disabled: boolean;
}

function ChipReplacementDialog({
  item,
  service,
  businessUnitId: businessActionId,
  onSuccess,
  disabled
}: ChipReplacementDialogProps) {
  const [isDialogHidden, setIsDialogHidden] = useState(true);

  const [inventoryData, setInventoryData] = useState<{
    inventoryId: number;
    serialNumber?: string;
  }>(undefined);
  const [serialNumber, setSerialNumber] = useState<string>(undefined);

  const [errorMessage, setErrorMessage] = useState<string>(undefined);
  const [successMessage, setSuccessMessage] = useState<string>(undefined);

  const replaceItem = useAsyncCallback(
    async () => {
      setErrorMessage(undefined);

      const abortController = new AbortController();
      const { signal } = abortController;

      return contractsService.replaceItem({
        params: { id: item.id, body: { inventory: inventoryData.inventoryId } },
        signal
      });
    },
    {
      onSuccess({ response, error }) {
        if (response.ok) {
          setIsDialogHidden(true);
          setSuccessMessage("Processo de substituição iniciado");
          onSuccess();
        } else {
          setErrorMessage(error.message);
        }
      },
      onError(error) {
        setErrorMessage(error.message);
      }
    }
  );

  const resetFields = () => {
    setSuccessMessage(undefined);
    setErrorMessage(undefined);
    setSerialNumber(undefined);
    setInventoryData(undefined);
  };

  useEffect(() => {
    if (isDialogHidden) resetFields();
  }, [isDialogHidden]);

  return (
    <>
      <PrimaryButton
        text="Substituir Chip"
        onClick={() => setIsDialogHidden(false)}
        disabled={disabled}
      />

      <Dialog
        hidden={isDialogHidden}
        onDismiss={() => setIsDialogHidden(true)}
        modalProps={{ onDismissed: () => setInventoryData(undefined) }}
        minWidth={400}
        maxWidth={400}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Substituição de Chip",
          closeButtonAriaLabel: "Cancelar",
          subText: "Digite o ICCID do chip deseja para efetuar substituição."
        }}
      >
        <Stack tokens={{ childrenGap: 15 }} horizontalAlign="center">
          <TextFilter
            _paginate={() => {
              return;
            }}
            filterConfigs={{
              maxWidth: "292px",
              minWidth: "292px",
              filterQuery: "serialNumber",
              placeholder: "Digite o ICCID do chip...",
              hideLabel: true,
              maxLength: 20
            }}
            selectedTerms={serialNumber ? { serialNumber: serialNumber } : {}}
            visibleTerms={
              serialNumber
                ? {
                    serialNumber: serialNumber
                  }
                : {}
            }
            setVisibleTerms={value => {
              return;
            }}
            setSelectedTerms={value => {
              const { serialNumber } = value();
              setSerialNumber(serialNumber);
              if (serialNumber.length === 20) {
                setErrorMessage(undefined);

                const abortController = new AbortController();
                const { signal } = abortController;

                productsService
                  .searchProduct({
                    params: {
                      query: {
                        businessActionId,
                        serialNumber,
                        operation: item.operation.id,
                        service
                      }
                    },
                    signal
                  })
                  .then(({ data, error }) => {
                    if (error) {
                      if (error.status === 400) {
                        return setErrorMessage(
                          "O número de série não existe ou já está sendo utilizado"
                        );
                      }

                      return setErrorMessage(error.message);
                    }

                    setInventoryData({
                      inventoryId: data.inventory.id,
                      serialNumber: data.inventory.serialNumber
                    });
                    setSuccessMessage(
                      `Chip Disponível ${data.inventory.serialNumber}`
                    );
                  });
              }
            }}
          />
          {errorMessage && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline>
              {errorMessage}
            </MessageBar>
          )}
          {successMessage && (
            <MessageBar messageBarType={MessageBarType.success} isMultiline>
              {successMessage}
            </MessageBar>
          )}
        </Stack>
        {successMessage && (
          <>
            <DialogFooter>
              {replaceItem.loading && (
                <Spinner
                  size={SpinnerSize.xSmall}
                  styles={{ root: { display: "inline-block" } }}
                />
              )}
              <PrimaryButton
                id="submit-button"
                onClick={() => replaceItem.execute()}
                disabled={replaceItem.loading}
                text="Substituir"
              />
              <DefaultButton
                id="cancel-button"
                onClick={() => setIsDialogHidden(true)}
                disabled={replaceItem.loading}
                text="Cancelar"
              />
            </DialogFooter>
          </>
        )}
      </Dialog>
    </>
  );
}

export default ChipReplacementDialog;
