import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createPlansFailure,
  createPlansSuccess,
  editPlansFailure,
  editPlansSuccess,
  listPlansFailure,
  listPlansRequest,
  listPlansSuccess
} from "../actions/plans";
import { PlansActionsTypes } from "../types/Plans.reducer.types";

import { SagaCustomError } from "@/common/types";
import { PlansService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createPlans({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = PlansService(undefined, isAdmin);

  //Deep copy of the object without reference
  const newValues = JSON.parse(JSON.stringify(values));

  if (newValues.includedServices?.length > 0) {
    newValues.includedServices = newValues.includedServices.reduce(
      (allServices, currentService) => {
        currentService.includedService !== "" &&
          allServices.push(currentService.includedService);
        return allServices;
      },
      []
    );
  }

  newValues.includedServices?.length < 1 && delete newValues.includedServices;

  if (newValues.appsInfos?.length > 0) {
    newValues.appsInfos = newValues.appsInfos.reduce((allApps, currentApp) => {
      currentApp.appsInfo !== "" && allApps.push(currentApp.appsInfo);
      return allApps;
    }, []);
  }

  newValues.appsInfos?.length < 1 && delete newValues.appsInfos;

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createPlansSuccess({
        message: `Plano de benefícios criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listPlansRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      createPlansFailure({
        code: error?.statusCode,
        message:
          "Não foi possível criar um plano de benefícios com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editPlans({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = PlansService(undefined, isAdmin);

  //Deep copy of the object without reference
  const newValues = JSON.parse(JSON.stringify(values));

  if (newValues.includedServices?.length > 0) {
    newValues.includedServices = newValues.includedServices.reduce(
      (allServices, currentService) => {
        currentService.includedService !== "" &&
          allServices.push(currentService.includedService);
        return allServices;
      },
      []
    );
  }

  newValues.includedServices.length < 1 && delete newValues.includedServices;

  if (newValues.appsInfos?.length > 0) {
    newValues.appsInfos = newValues.appsInfos.reduce((allApps, currentApp) => {
      currentApp.appsInfo !== "" && allApps.push(currentApp.appsInfo);
      return allApps;
    }, []);
  }

  newValues.appsInfos?.length < 1 && delete newValues.appsInfos;

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editPlansSuccess({
        message: `Plano de benefícios editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listPlansRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      editPlansFailure({
        code: error?.statusCode,
        message:
          "Não foi possível editar o plano de benefícios com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listPlans({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = PlansService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listPlansSuccess({ list: response.data }));
  } catch (error) {
    yield put(listPlansFailure());
  }
}

export default all([
  takeLatest(PlansActionsTypes.CREATE_REQUEST, createPlans),
  takeLatest(PlansActionsTypes.EDIT_REQUEST, editPlans),
  takeLatest(PlansActionsTypes.LIST_REQUEST, listPlans)
]);
