import {
  Dropdown,
  IconButton,
  MessageBar,
  MessageBarType,
  Stack
} from "@fluentui/react";
import { useState } from "react";

import { FiltersTypeEnum } from "./enums";
import {
  CheckboxFilter,
  DatePicker,
  DateRangerPickerFilter,
  MaskedText,
  SelectFilter,
  TextArrayFilter,
  TextFilter
} from "./Filters";
import { FiltersContainer } from "./styles";
import { ListDataFiltersProps } from "./types";

export function ListDataFilters({
  _paginate,
  setItemsPerPage,
  setVisibleTerms,
  setSelectedKeys,
  setSelectedTerms,
  filterCurrentItems,
  toggleFilterVisibility,
  items,
  filters,
  visibleTerms,
  selectedKeys,
  itemsPerPage,
  selectedTerms,
  debouncedTerms,
  isItemsPerPageFilterDisabled,
  isCloseFilterButtonDisabled,
  filterTitle,
  massActionsType
}: ListDataFiltersProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [sucessMessage, setSucessMessage] = useState<string | null>(null);

  return (
    <FiltersContainer
      style={{
        padding: "8px",
        paddingBottom: "20px",
        background: massActionsType ? "none" : "#faf9f8",
        borderBottom: massActionsType
          ? "1px solid rgba(122, 148, 200)"
          : "1px solid #edebe9",
        borderTop: massActionsType
          ? "1px solid rgb(122, 148, 200)"
          : "1px solid #edebe9"
      }}
      onClick={() => {
        !!errorMessage && setErrorMessage(null);
        !!sucessMessage && setSucessMessage(null);
      }}
    >
      {filterTitle && (
        <h3
          style={{
            margin: "auto",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: 600
          }}
        >
          {filterTitle}
        </h3>
      )}
      <Stack
        horizontal
        horizontalAlign="space-between"
        onClick={() => {
          setErrorMessage(null);
        }}
      >
        <Stack tokens={{ childrenGap: 10 }} horizontal wrap>
          {filters?.length > 0 &&
            filters
              .filter(f => !f.massActionsType)
              .map((filter, index) => {
                switch (filter.type) {
                  case FiltersTypeEnum.SELECT: {
                    return (
                      <SelectFilter
                        key={`${filter.filterQuery}${index}`}
                        filterConfigs={{ ...filter, maxWidth: "300px" }}
                        debouncedTerms={debouncedTerms}
                        selectedKeys={selectedKeys}
                        itemsPerPage={itemsPerPage}
                        _paginate={_paginate}
                        setSelectedKeys={setSelectedKeys}
                      />
                    );
                  }

                  case FiltersTypeEnum.DATE: {
                    return (
                      <DatePicker
                        key={`${filter.filterQuery}${index}`}
                        filterConfigs={filter}
                        debouncedTerms={debouncedTerms}
                        selectedKeys={selectedKeys}
                        itemsPerPage={itemsPerPage}
                        _paginate={_paginate}
                        setSelectedKeys={setSelectedKeys}
                      />
                    );
                  }

                  case FiltersTypeEnum.DATE_RANGE: {
                    return (
                      <DateRangerPickerFilter
                        key={`${filter.filterQuery}${index}`}
                        filterConfigs={filter}
                        debouncedTerms={debouncedTerms}
                        selectedKeys={selectedKeys}
                        itemsPerPage={itemsPerPage}
                        _paginate={_paginate}
                        setSelectedKeys={setSelectedKeys}
                      />
                    );
                  }

                  case FiltersTypeEnum.CHECKBOX: {
                    return (
                      <CheckboxFilter
                        key={`${filter.filterQuery}${index}`}
                        filterConfigs={filter}
                        selectedKeys={selectedKeys}
                        debouncedTerms={debouncedTerms}
                        itemsPerPage={itemsPerPage}
                        setSelectedKeys={setSelectedKeys}
                        _paginate={_paginate}
                      />
                    );
                  }

                  case FiltersTypeEnum.MASK_TEXT: {
                    return (
                      <MaskedText
                        key={`${filter.filterQuery}${index}`}
                        filterConfigs={filter}
                        selectedTerms={selectedTerms}
                        visibleTerms={visibleTerms}
                        setVisibleTerms={setVisibleTerms}
                        setSelectedTerms={setSelectedTerms}
                        mask={filter.mask}
                      />
                    );
                  }

                  case FiltersTypeEnum.ARRAY_TEXT: {
                    return (
                      <TextArrayFilter
                        key={`${filter.filterQuery}${index}`}
                        filterConfigs={filter}
                        selectedTerms={selectedTerms}
                        visibleTerms={visibleTerms}
                        setVisibleTerms={setVisibleTerms}
                        setSelectedTerms={setSelectedTerms}
                      />
                    );
                  }

                  default: {
                    return (
                      <TextFilter
                        key={`${filter.filterQuery}${index}`}
                        filterConfigs={filter}
                        selectedTerms={selectedTerms}
                        visibleTerms={visibleTerms}
                        setVisibleTerms={setVisibleTerms}
                        setSelectedTerms={setSelectedTerms}
                      />
                    );
                  }
                }
              })}

          {!isItemsPerPageFilterDisabled && (
            <Stack
              tokens={{ childrenGap: 5 }}
              verticalAlign="start"
              styles={{ root: { width: "100%" } }}
            >
              <Dropdown
                placeholder="Quantidade por página"
                label="Quantidade por página"
                options={[
                  { key: "10", text: "10" },
                  { key: "20", text: "20" },
                  { key: "30", text: "30" },
                  { key: "40", text: "40" },
                  { key: "50", text: "50" }
                ]}
                onChange={(_, value) => {
                  setItemsPerPage(Number(value.key));
                  _paginate({
                    limit: Number(value.key),
                    ...selectedKeys,
                    ...selectedTerms
                  });
                }}
                style={{ width: "15%" }}
              />
            </Stack>
          )}
        </Stack>

        {!isCloseFilterButtonDisabled && (
          <IconButton
            iconProps={{ iconName: "ChromeClose" }}
            title="Fechar"
            ariaLabel="Fechar"
            onClick={toggleFilterVisibility}
          />
        )}
      </Stack>

      {!!errorMessage && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline>
          {errorMessage}
        </MessageBar>
      )}
      {!!sucessMessage && (
        <MessageBar messageBarType={MessageBarType.success} isMultiline>
          {sucessMessage}
        </MessageBar>
      )}
    </FiltersContainer>
  );
}
