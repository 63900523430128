import { getTheme, Icon } from "@fluentui/react";
import styled, { keyframes } from "styled-components";

const theme = getTheme();

const fade = keyframes`
  from {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
`;

export const SlideShowContainer = styled.div<{
  height: string;
  maxWidth: string;
  disableMargin?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${props => props.maxWidth};
  height: ${props => props.height};
  position: relative;
  margin: ${props => (props.disableMargin ? "none" : "auto")};

  &:hover {
    i {
      display: flex;
    }
  }

  &:focus {
    outline-color: transparent;
  }
`;

export const Slides = styled.div<{
  maxImageWidth: string;
  maxImageHeight?: string;
}>`
  -webkit-animation-name: ${fade};
  -webkit-animation-duration: 1.5s;
  animation-name: ${fade};
  animation-duration: 1.5s;

  > img {
    max-width: ${props => props.maxImageWidth};
    ${props => props.maxImageHeight && `max-height: ${props.maxImageHeight}`};
  }
`;

export const NavigationButton = styled(Icon)<{
  prevButton?: boolean;
  nextButton?: boolean;
}>`
  display: none;

  cursor: pointer;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  padding: 1vw;
  max-width: 10px;
  max-height: 10px;
  font-size: 18px;
  color: ${theme.palette.neutralSecondary};
  transition: 0.6s ease;
  border-radius: 50%;
  background: ${theme.palette.neutralLighter};
  border: none !important;

  ${props => props.prevButton && "left: 0;"}
  ${props => props.nextButton && "right: 0;"}

  @media (max-width: 800px) {
    font-size: 16px;
    max-width: none;
    max-height: none;
  }
`;

export const DotNavigationContainer = styled.div`
  position: absolute;
  bottom: 10px;
`;

export const DotNavigation = styled.div<{
  isActive: boolean;
  dotColor: string;
}>`
  cursor: pointer;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  border: 1px solid ${props => props.dotColor};
  display: inline-block;
  border-radius: 50%;
  transition: background-color 0.6s ease;
  ${props => props.isActive && `background-color: ${props.dotColor};`}

  &:hover {
    background-color: ${theme.palette.neutralSecondary};
  }
`;
