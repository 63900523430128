import { ListOfPlans } from "./Plans.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum PlansActionsTypes {
  CREATE_REQUEST = "plans/CREATE_REQUEST",
  CREATE_FAIL = "plans/CREATE_FAIL",
  CREATE_SUCCESS = "plans/CREATE_SUCCESS",
  DISMISS_MESSAGE = "plans/DISMISS_MESSAGE",
  EDIT_REQUEST = "plans/EDIT_REQUEST",
  EDIT_FAIL = "plans/EDIT_FAIL",
  EDIT_SUCCESS = "plans/EDIT_SUCCESS",
  LIST_REQUEST = "plans/LIST_REQUEST",
  LIST_FAIL = "plans/LIST_FAIL",
  LIST_SUCCESS = "plans/LIST_SUCCESS"
}

export interface PlansState {
  isLoadingList: boolean;
  list: ListOfPlans;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: PlansActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: PlansActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: PlansActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: PlansActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: PlansActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: PlansActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: PlansActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: PlansActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: PlansActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: PlansActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfPlans;
  };
}

export type PlansReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
