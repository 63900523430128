import Api from "./api";

class ElectronicSignatures extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/eletronic-signature${subPaths}`
        : `eletronic-signature${subPaths}`
    );
  }
}

export default ElectronicSignatures;
