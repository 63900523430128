import { Spinner, SpinnerSize } from "@fluentui/react";

import { StyledLoader } from "./styles";

type LoaderProps = {
  height?: string;
  customMessage?: string;
  spinnerSize?: SpinnerSize;
};

export function Loader({
  height,
  customMessage,
  spinnerSize
}: LoaderProps): JSX.Element {
  return (
    <StyledLoader height={height}>
      <Spinner
        size={spinnerSize ?? SpinnerSize.large}
        label={customMessage ?? "Carregando..."}
      />
    </StyledLoader>
  );
}
