import { Icon, Stack } from "@fluentui/react";

import { ContractInfoCamp } from "../ClientInfo";
import { DefaultCardTitle } from "../styles";

import { Card } from "@/components/Shared/Card";
import { TranslatedSignatureType } from "@/modules/Contracts/enums";
import { formatDateAndTimeToString } from "@/utils/FormatDate";

interface ContractInfoProps {
  contract: any;
  title: any;
  padding?: any;
  isInsidePanel: boolean;
}

const tokens = {
  sectionStack: {
    childrenGap: 10,
    padding: 20
  },
  headingStack: {
    childrenGap: 10
  }
};

export function ContractInfo({
  contract,
  title,
  isInsidePanel
}: ContractInfoProps): JSX.Element {
  const createdAtDate = new Date(contract.createdAt);
  const updatedAtDate = new Date(contract.updatedAt);

  const buParent = contract.businessUnit?.parent;
  const names = [
    buParent?.parent?.name,
    buParent?.name,
    contract.businessUnit.name
  ].filter(Boolean);
  const buName = names.join(" -> ");

  return (
    <Stack verticalFill>
      <Stack
        horizontal
        className="ms-sm12"
        style={{ marginBottom: "1vh", height: 35 }}
      >
        <DefaultCardTitle>
          <Icon iconName="ClipboardList" style={{ marginRight: 10 }} />
          {title}
        </DefaultCardTitle>
      </Stack>

      <Card borderRadius="1rem" padding="1rem">
        <Stack horizontal wrap tokens={tokens.sectionStack}>
          <Stack.Item
            grow
            className={`ms-sm12 ${!isInsidePanel ? "ms-lg5" : ""}`}
          >
            <Stack tokens={tokens.headingStack}>
              <ContractInfoCamp
                title={"Tipo de Assinatura:"}
                value={TranslatedSignatureType[contract?.signature?.type]}
              />
              <ContractInfoCamp title={"Empresa:"} value={buName} />
              <ContractInfoCamp
                title={"Autor do Contrato:"}
                value={contract.user.fullName}
              />
              <ContractInfoCamp
                title={"Criado em:"}
                value={formatDateAndTimeToString(createdAtDate)}
              />
              <ContractInfoCamp
                title={"Atualizado em:"}
                value={formatDateAndTimeToString(updatedAtDate)}
              />
            </Stack>
          </Stack.Item>

          <Stack.Item
            grow
            className={`ms-sm12 ${!isInsidePanel ? "ms-lg5" : ""}`}
          >
            <Stack tokens={tokens.headingStack}>
              <ContractInfoCamp
                title={"Categoria:"}
                value={contract.serviceCategory.name}
              />
              <ContractInfoCamp
                title={"Benefício prestado:"}
                value={contract.service.name}
              />
              {contract?.paymentMethod?.name && (
                <ContractInfoCamp
                  title={"Método de Pagamento da Adesão:"}
                  value={contract.paymentMethod.name}
                />
              )}
              <ContractInfoCamp
                title={"Entrega:"}
                value={
                  contract.shipping
                    ? `
                      Frete
                      ${contract.shipping?.name}
                      - Prazo de entrega
                      ${contract.shipping?.deliveryTime}
                      ${contract.shipping?.deliveryTime > 1 ? "dias" : "dia"}
                      - Valor
                      ${toLocaleString(contract.shipping?.price)}`
                    : "Não foram encontrados dados sobre a entrega."
                }
              />
              <ContractInfoCamp
                title={"Time comercial:"}
                value={
                  contract.salesTeam?.name
                    ? `${contract.salesTeam?.name}`
                    : " — "
                }
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </Card>
    </Stack>
  );
}

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}
