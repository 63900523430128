import {
  DefaultButton,
  Dropdown,
  getTheme,
  IconButton,
  MaskedTextField,
  Stack,
  TextField
} from "@fluentui/react";
import { Field, FieldArray } from "formik";
import { useEffect } from "react";

import { DateField } from "./date-field";
import { Utils } from "./helpers";
import { SearchSelectField } from "./search-select-field";
import { FieldTextContainer } from "./styles";
import { As } from "./types";

import { LabelWithTooltip } from "@/components/LabelWithTooltip";
import StripedBackground from "@/components/Shared/StripedBackground";
import { formatMoneyValue } from "@/utils/FormatMoneyValue";
import { formatPercentageValue } from "@/utils/FormatPercentageValue";
interface ArrayFieldProps {
  fieldConfig: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  value?: any;
}
export function ArrayField({
  values,
  errors,
  fieldConfig,
  setFieldValue,
  touched
}: ArrayFieldProps): JSX.Element {
  const theme = getTheme();
  let canAdd = true;
  if (fieldConfig.limit) {
    canAdd = values?.length < fieldConfig.limit;
  }
  useEffect(() => {
    if (fieldConfig.value && values === undefined) {
      setFieldValue(fieldConfig.name, fieldConfig.value);
    }
  }, []);

  return (
    <FieldArray
      name={fieldConfig.name}
      key={fieldConfig.name}
      render={arrayHelpers => (
        <div>
          <LabelWithTooltip
            description={fieldConfig.tooltipDescription}
            label={fieldConfig.label}
            required={fieldConfig.required || false}
          />
          <Stack tokens={{ childrenGap: 10 }}>
            {values instanceof Array &&
              values.map((item, arrayIndex) => {
                return (
                  <StripedBackground
                    key={fieldConfig.name + arrayIndex + "background"}
                  >
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <Stack tokens={{ childrenGap: 5 }} grow>
                        <div className="ms-Grid-row">
                          {fieldConfig.fields.map(field => {
                            const mask = field?.maskCondition
                              ? field?.maskCondition(item)
                              : null;

                            const optionsNotSelected = field?.options?.filter(
                              opt => {
                                const valueSelected = values
                                  .map(v => v[field.name])
                                  .filter((v: any) => v !== "");

                                return !valueSelected.includes(opt.key);
                              }
                            );

                            const selectOptions = field?.exclusiveOptions
                              ? field.options.filter(opt => {
                                  const valueSelected = values
                                    .map(v => v[field.name])
                                    .filter(
                                      (v: any) =>
                                        v !== "" && v !== item[field.name]
                                    );

                                  return !valueSelected.includes(opt.key);
                                })
                              : field.options;

                            field?.autoSelectOption &&
                              Object.assign(fieldConfig?.defaultValues, {
                                ...fieldConfig?.defaultValues,
                                [field.name]: optionsNotSelected[0]?.key
                              });
                            switch (field.type) {
                              case "select":
                                return (
                                  <div
                                    className={
                                      fieldConfig?.removeCentralization
                                        ? ""
                                        : `ms-Grid-col ms-sm12${
                                            field.size
                                              ? ` ms-lg${field.size}`
                                              : ""
                                          }`
                                    }
                                    key={`${field.name}[${arrayIndex}]`}
                                  >
                                    <LabelWithTooltip
                                      description={field.tooltipDescription}
                                      label={
                                        fieldConfig.showQuantity
                                          ? `${field.label} ${arrayIndex + 1}`
                                          : field.label
                                      }
                                      required={field.required || false}
                                      id={`${field.name}-${arrayIndex}-label`}
                                    />
                                    <Field
                                      id={`${fieldConfig.name}-${field.name}-${arrayIndex}`}
                                      name={`${fieldConfig.name}[${arrayIndex}].${field.name}`}
                                      type={field.type}
                                      as={Dropdown as As}
                                      onChange={(evt, itemOption) => {
                                        item[field.name] === itemOption.key
                                          ? setFieldValue(
                                              `${fieldConfig.name}[${arrayIndex}].${field.name}`,
                                              undefined
                                            )
                                          : setFieldValue(
                                              `${fieldConfig.name}[${arrayIndex}].${field.name}`,
                                              itemOption.key
                                            );
                                        if (field.fieldsToResetValueOnChange) {
                                          field.fieldsToResetValueOnChange.forEach(
                                            fieldName => {
                                              setFieldValue(
                                                fieldName,
                                                undefined
                                              );
                                            }
                                          );
                                        }
                                        if (field.executeOnChange) {
                                          field.executeOnChange({
                                            key: itemOption.key,
                                            value:
                                              itemOption.name || itemOption.text
                                          });
                                        }
                                      }}
                                      placeholder={field.label}
                                      key={`${fieldConfig.name}[${arrayIndex}].${field.name}`}
                                      disabled={
                                        field.disabled ||
                                        (field?.disabledInputOn &&
                                          field?.disabledInputOn(
                                            item,
                                            values,
                                            arrayIndex
                                          )) ||
                                        false
                                      }
                                      options={selectOptions}
                                      styles={{ dropdown: { width: "100%" } }}
                                      required={field.required || false}
                                      errorMessage={
                                        errors[fieldConfig.name]?.[
                                          arrayIndex
                                        ]?.[field.name] &&
                                        touched[fieldConfig.name]?.[
                                          arrayIndex
                                        ]?.[field.name]
                                          ? errors[fieldConfig.name]?.[
                                              arrayIndex
                                            ]?.[field.name]
                                          : null
                                      }
                                      selectedKey={item[field.name] ?? ""}
                                    />
                                  </div>
                                );
                              case "date":
                                return (
                                  <div
                                    className={
                                      fieldConfig?.removeCentralization
                                        ? ""
                                        : `ms-Grid-col ms-sm12${
                                            field.size
                                              ? ` ms-lg${field.size}`
                                              : ""
                                          }`
                                    }
                                    key={`${field.name}[${arrayIndex}]`}
                                  >
                                    <DateField
                                      name={`${fieldConfig.name}[${arrayIndex}].${field.name}`}
                                      type={field.type}
                                      id={`${fieldConfig.name}-${field.name}-${arrayIndex}`}
                                      key={field.name}
                                      label={
                                        fieldConfig.showQuantity
                                          ? `${field.label} ${arrayIndex + 1}`
                                          : field.label
                                      }
                                      placeholder={field.placeholder}
                                      disabled={field.disabled || false}
                                      errorMessage={
                                        errors[fieldConfig.name]?.[
                                          arrayIndex
                                        ]?.[field.name]
                                      }
                                      value={Utils.formatDateValue(
                                        item[field.name]
                                      )}
                                      isRequired={field.required || false}
                                      setFieldValue={setFieldValue}
                                      maxDate={field.maxDate}
                                      minDate={field.minDate}
                                      tooltipDescription={
                                        field.tooltipDescription
                                      }
                                      invalidDateErrorMessage={
                                        field?.invalidDateErrorMessage
                                      }
                                    />
                                  </div>
                                );
                              case "percentage":
                                return (
                                  <FieldTextContainer
                                    className={
                                      fieldConfig?.removeCentralization
                                        ? ""
                                        : `ms-Grid-col ms-sm12${
                                            field.size
                                              ? ` ms-lg${field.size}`
                                              : ""
                                          }`
                                    }
                                  >
                                    <LabelWithTooltip
                                      description={field.tooltipDescription}
                                      id={`label-${field.name}`}
                                      label={field.label}
                                      required={field.required || false}
                                    />
                                    <Field
                                      key={`${fieldConfig.name}[${arrayIndex}].${field.name}`}
                                      name={field.name}
                                      id={`input-number-${field.name}-${arrayIndex}`}
                                      type={"text"}
                                      as={TextField}
                                      placeholder={
                                        field.placeholder || field.label
                                      }
                                      disabled={field.disabled || false}
                                      errorMessage={
                                        errors[field.name] &&
                                        touched[field.name]
                                          ? errors[field.name]
                                          : null
                                      }
                                      onChange={(event, value) => {
                                        value = formatPercentageValue(value);
                                        setFieldValue(
                                          `${fieldConfig.name}[${arrayIndex}].${field.name}`,
                                          value
                                        );
                                      }}
                                      value={values[arrayIndex][field.name]}
                                      required={field.required || false}
                                      description={field.description}
                                      // a length máxima precisa ser 5, pois a "," também conta
                                      maxLength={5}
                                    />
                                  </FieldTextContainer>
                                );

                              case "number":
                                return (
                                  <FieldTextContainer
                                    className={
                                      fieldConfig?.removeCentralization
                                        ? ""
                                        : `ms-Grid-col ms-sm12${
                                            field.size
                                              ? ` ms-lg${field.size}`
                                              : ""
                                          }`
                                    }
                                  >
                                    <LabelWithTooltip
                                      description={field.tooltipDescription}
                                      id={`label-${field.name}`}
                                      label={field.label}
                                      required={field.required || false}
                                    />
                                    <Field
                                      key={`${fieldConfig.name}[${arrayIndex}].${field.name}`}
                                      name={field.name}
                                      id={`input-number-${field.name}-${arrayIndex}`}
                                      type={"text"}
                                      as={TextField}
                                      placeholder={
                                        field.placeholder || field.label
                                      }
                                      disabled={field.disabled || false}
                                      errorMessage={
                                        errors[field.name] &&
                                        touched[field.name]
                                          ? errors[field.name]
                                          : null
                                      }
                                      onChange={(event, value: string) => {
                                        value = value.replace(/[^0-9]/g, "");
                                        event.target.value = value;
                                        setFieldValue(
                                          `${fieldConfig.name}[${arrayIndex}].${field.name}`,
                                          value
                                        );
                                      }}
                                      value={values[arrayIndex][field.name]}
                                      required={field.required || false}
                                      description={field.description}
                                      maxLength={field.maxLength ?? ""}
                                    />
                                  </FieldTextContainer>
                                );
                              case "textarea":
                                return (
                                  <div
                                    className={
                                      fieldConfig?.removeCentralization
                                        ? ""
                                        : `ms-Grid-col ms-sm12${
                                            field.size
                                              ? ` ms-lg${field.size}`
                                              : ""
                                          }`
                                    }
                                    key={`${field.name}[${arrayIndex}]`}
                                  >
                                    <FieldTextContainer>
                                      <LabelWithTooltip
                                        description={field.tooltipDescription}
                                        label={
                                          fieldConfig.showQuantity
                                            ? `${field.label} ${arrayIndex + 1}`
                                            : field.label
                                        }
                                        required={field.required || false}
                                        id={`${fieldConfig.name}[${arrayIndex}].${field.name}-label`}
                                      />
                                      <Field
                                        name={`${fieldConfig.name}[${arrayIndex}].${field.name}`}
                                        type={field.type}
                                        id={`${fieldConfig.name}-${field.name}-${arrayIndex}`}
                                        as={TextField as As}
                                        placeholder={
                                          field.placeholder || field.label
                                        }
                                        key={`${fieldConfig.name}[${arrayIndex}].${field.name}`}
                                        disabled={field.disabled || false}
                                        errorMessage={
                                          errors[fieldConfig.name]?.[
                                            arrayIndex
                                          ]?.[field.name] &&
                                          touched[fieldConfig.name]?.[
                                            arrayIndex
                                          ]?.[field.name]
                                            ? errors[fieldConfig.name]?.[
                                                arrayIndex
                                              ]?.[field.name]
                                            : null
                                        }
                                        required={field.required || false}
                                        description={field.description}
                                        multiline
                                        autoAdjustHeight
                                        rows={3}
                                      />
                                    </FieldTextContainer>
                                  </div>
                                );
                              case "money":
                                return (
                                  <div
                                    key={`${field.name}[${arrayIndex}]`}
                                    className={
                                      fieldConfig?.removeCentralization
                                        ? ""
                                        : `ms-Grid-col ms-sm12${
                                            field.size
                                              ? ` ms-lg${field.size}`
                                              : ""
                                          }`
                                    }
                                  >
                                    <Field
                                      name={`fees[${arrayIndex}].${field.name}`}
                                      type={field.type}
                                      as={TextField}
                                      prefix="R$"
                                      placeholder={field.label}
                                      label={field.label}
                                      disabled={field.disabled || false}
                                      required={field.required || false}
                                      errorMessage={
                                        errors[`${field.name}`] &&
                                        touched[`${field.name}`]
                                          ? errors[`${field.name}`]
                                          : null
                                      }
                                      onChange={(event, value) => {
                                        value = formatMoneyValue(value);
                                        setFieldValue(
                                          `fees[${arrayIndex}].${field.name}`,
                                          value
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              case "searchSelectField":
                                if (
                                  item.value &&
                                  item[field.name] === undefined
                                ) {
                                  setFieldValue(item.name, item.value);
                                }
                                return (
                                  <SearchSelectField
                                    key={item.name}
                                    fieldConfig={field}
                                    setValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    defaultValue={item[field.name] || ""}
                                  />
                                );
                              default:
                                return (
                                  <div
                                    className={
                                      fieldConfig?.removeCentralization
                                        ? ""
                                        : `ms-Grid-col ms-sm12${
                                            field.size
                                              ? ` ms-lg${field.size}`
                                              : ""
                                          }`
                                    }
                                    key={`${field.name}[${arrayIndex}]`}
                                  >
                                    <FieldTextContainer>
                                      <LabelWithTooltip
                                        description={field.tooltipDescription}
                                        label={
                                          fieldConfig.showQuantity
                                            ? `${field.label} ${arrayIndex + 1}`
                                            : field.label
                                        }
                                        required={field.required || false}
                                        id={`${fieldConfig.name}-${field.name}-${arrayIndex}-label`}
                                      />
                                      <Field
                                        id={`${fieldConfig.name}-${field.name}-${arrayIndex}`}
                                        name={`${fieldConfig.name}[${arrayIndex}].${field.name}`}
                                        type={field.type}
                                        as={
                                          field.mask || mask
                                            ? MaskedTextField
                                            : (TextField as As)
                                        }
                                        placeholder={field.label}
                                        key={`${fieldConfig.name}[${arrayIndex}].${field.name}`}
                                        disabled={
                                          field.disabled ||
                                          (field?.disabledInputOn &&
                                            field?.disabledInputOn(
                                              item,
                                              values,
                                              arrayIndex
                                            )) ||
                                          false
                                        }
                                        validate={value => {
                                          if (value) {
                                            if (field?.customValidation) {
                                              const res =
                                                field?.customValidation(value);
                                              if (!res.success) {
                                                return res.message;
                                              }
                                            }
                                          }
                                        }}
                                        onChange={(event, value: string) => {
                                          if (field.mask || mask) {
                                            value = value.replace(/\D/g, "");
                                            setFieldValue(
                                              `${fieldConfig.name}[${arrayIndex}].${field.name}`,
                                              value
                                            );
                                          } else {
                                            setFieldValue(
                                              `${fieldConfig.name}[${arrayIndex}].${field.name}`,
                                              value
                                            );
                                          }
                                        }}
                                        mask={mask ? mask : field.mask ?? null}
                                        required={field.required || false}
                                        errorMessage={
                                          errors[fieldConfig.name]?.[
                                            arrayIndex
                                          ]?.[field.name] &&
                                          touched[fieldConfig.name]?.[
                                            arrayIndex
                                          ]?.[field.name]
                                            ? errors[fieldConfig.name]?.[
                                                arrayIndex
                                              ]?.[field.name]
                                            : null
                                        }
                                      />
                                    </FieldTextContainer>
                                  </div>
                                );
                            }
                          })}
                        </div>
                      </Stack>
                      {((fieldConfig?.deleteEnabledOn &&
                        fieldConfig?.deleteEnabledOn(
                          item,
                          values,
                          arrayIndex
                        )) ||
                        (!fieldConfig?.deleteEnabledOn &&
                          fieldConfig.deleteButtonEnabled &&
                          values.length > fieldConfig?.minItems)) && (
                        <Stack.Item align="center">
                          <IconButton
                            id={`delete-${fieldConfig.name}-button`}
                            iconProps={{ iconName: "Delete" }}
                            title="Remover"
                            ariaLabel="Remover Item"
                            styles={{
                              root: {
                                color: theme.palette.red
                              },
                              iconHovered: {
                                color: theme.palette.redDark
                              }
                            }}
                            onClick={() => arrayHelpers.remove(arrayIndex)}
                          />
                        </Stack.Item>
                      )}
                    </Stack>
                  </StripedBackground>
                );
              })}
            {fieldConfig.newFieldButtonEnabled && (
              <div>
                <DefaultButton
                  id={`add-${fieldConfig.name}-button`}
                  type="button"
                  onClick={() => {
                    if (fieldConfig?.defaultValues) {
                      arrayHelpers.push({ ...fieldConfig.defaultValues });
                    }
                  }}
                  disabled={!canAdd}
                >
                  {fieldConfig.newFieldButtonMessage}
                </DefaultButton>
              </div>
            )}
          </Stack>
        </div>
      )}
    />
  );
}
