import produce from "immer";

import {
  InventoriesOccurrencesActionsTypes,
  InventoriesOccurrencesReducerActions,
  InventoriesOccurrencesState
} from "../types/InventoriesOccurrences.reducer.types";

const INITIAL_STATE: InventoriesOccurrencesState = {
  list: undefined,
  isLoadingList: false
};

export default function reducer(
  state = INITIAL_STATE,
  action: InventoriesOccurrencesReducerActions
): InventoriesOccurrencesState {
  return produce(state, draft => {
    switch (action.type) {
      case InventoriesOccurrencesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case InventoriesOccurrencesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case InventoriesOccurrencesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
