import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createModelsGroupsFailure,
  createModelsGroupsSuccess,
  deleteModelsGroupsFailure,
  deleteModelsGroupsSuccess,
  editModelsGroupsFailure,
  editModelsGroupsSuccess,
  listModelsGroupsFailure,
  listModelsGroupsRequest,
  listModelsGroupsSuccess
} from "../actions/modelsGroups";
import { ModelsGroupsActionsTypes } from "../types/ModelsGroups.reducer.types";

import { SagaCustomError } from "@/common/types";
import { ModelsGroupsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createModelsGroups({ payload }: AnyAction) {
  const { values, setSubmitting, isAdmin, closePanel } = payload;
  const service = ModelsGroupsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createModelsGroupsSuccess({
        message: `Grupo criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listModelsGroupsRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      createModelsGroupsFailure({
        code: error?.statusCode,
        message: "Não foi possível criar o grupo com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editModelsGroups({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin, refreshList } = payload;
  const service = ModelsGroupsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editModelsGroupsSuccess({
        message: `Grupo editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    if (refreshList) {
      refreshList();
    }
  } catch (error) {
    yield put(
      editModelsGroupsFailure({
        code: error?.statusCode,
        message: "Não foi possível editar o grupo com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* deleteModelGroup({ payload }: AnyAction) {
  const { values, setSubmitting, setSubmitted, closePanel, isAdmin } = payload;
  const service = ModelsGroupsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.deleteModelsGroups, {
      params: { body: {}, id: values.id },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      deleteModelsGroupsSuccess({
        message: `Grupo apagado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    setSubmitted(true);

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listModelsGroupsRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      deleteModelsGroupsFailure({
        code: error?.statusCode,
        message: "Não foi possível apagar o grupo selecionado",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listModelsGroups({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = ModelsGroupsService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }
    yield put(listModelsGroupsSuccess({ list: response.data }));
  } catch (error) {
    yield put(listModelsGroupsFailure());
  }
}

export default all([
  takeLatest(ModelsGroupsActionsTypes.CREATE_REQUEST, createModelsGroups),
  takeLatest(ModelsGroupsActionsTypes.EDIT_REQUEST, editModelsGroups),
  takeLatest(ModelsGroupsActionsTypes.DELETE_REQUEST, deleteModelGroup),
  takeLatest(ModelsGroupsActionsTypes.LIST_REQUEST, listModelsGroups)
]);
