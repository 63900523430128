import { useCallback, useRef, useState } from "react";

export function useApiRequest<T>({ apiCall }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<T>(null);
  const cancelRequest = useRef(false);

  const { signal } = new AbortController();

  const execute = async ({ params = {}, args = {} } = {}) => {
    try {
      setIsLoading(true);
      const response = await apiCall({ params, signal, ...args });

      if (!response.data) {
        throw response.error;
      }

      if (cancelRequest.current) return;

      setData(response.data);
      setIsLoading(false);
      return response.data;
    } catch (e) {
      if (cancelRequest.current) return;

      setError(e);
      setData(null);
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    error,
    data,
    cancelRequest,
    // eslint-disable-next-line
    execute: useCallback(execute, []) // to avoid infinite calls when inside a `useEffect`
  };
}
