import { Pivot, PivotItem } from "@fluentui/react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";

import { Card } from "@/components/Shared/Card";
import "./style.scss";
import { useSelectedBu } from "@/hooks/useSelectedBu";

function ResultsPanel(): JSX.Element {
  const { selectedBU } = useSelectedBu();

  return (
    <>
      <Helmet>
        <title>Painel de Resultados - Libertas</title>
      </Helmet>
      <Pivot
        styles={{
          root: {
            marginBottom: 10,
            padding: 0
          }
        }}
        aria-label="Abas da página de Painel de Resultados"
      >
        {selectedBU?.settings?.powerbi ? (
          <PivotItem headerText="Painel 1" className={`pivotItem`}>
            <Card className={`card`}>
              <iframe
                title="Power BI"
                src={selectedBU?.settings.powerbi}
                frameBorder="0"
                allowFullScreen
                className={`iframe`}
              ></iframe>
            </Card>
          </PivotItem>
        ) : (
          <Redirect
            to={{
              pathname: "/"
            }}
          />
        )}
        {/* @TODO: Refatorar para algo mais dinâmico, quando houver vários dash boards do Power B.I.*/}
        {selectedBU?.settings?.powerbi1 && (
          <PivotItem headerText="Painel 2" className={`pivotItem`}>
            <Card className={`card`}>
              <iframe
                title="Power BI"
                src={selectedBU?.settings.powerbi1}
                frameBorder="0"
                allowFullScreen
                className={`iframe`}
              ></iframe>
            </Card>
          </PivotItem>
        )}
        {selectedBU?.settings?.powerbi2 && (
          <PivotItem headerText="Painel 3" className={`pivotItem`}>
            <Card className={`card`}>
              <iframe
                title="Power BI"
                src={selectedBU?.settings.powerbi2}
                frameBorder="0"
                allowFullScreen
                className={`iframe`}
              ></iframe>
            </Card>
          </PivotItem>
        )}
        {selectedBU?.settings?.powerbi3 && (
          <PivotItem headerText="Painel 4" className={`pivotItem`}>
            <Card className={`card`}>
              <iframe
                title="Power BI"
                src={selectedBU.settings.powerbi3}
                frameBorder="0"
                allowFullScreen
                className={`iframe`}
              ></iframe>
            </Card>
          </PivotItem>
        )}
      </Pivot>
    </>
  );
}

export default ResultsPanel;
