import { KeyboardEvent, useCallback, useEffect, useState } from "react";

import {
  DotNavigation,
  DotNavigationContainer,
  NavigationButton,
  Slides,
  SlideShowContainer
} from "./styles";

type SlideShowProps = {
  showDotNavigator?: boolean;
  showNavigationButton?: boolean;
  isDotNavigationInside?: boolean;
  isDotNavigationOutside?: boolean;
  dotNavigationColor?: string;
  isAutomatic?: boolean;
  initialSlideIndex?: number;
  setInitialSlideIndex?: (arg: any) => void;
  height: string;
  maxWidth: string;
  maxImageWidth: string;
  maxImageHeight?: string;
  disableMargin?: boolean;
  images?: {
    id: string | number;
    fileName?: string;
    order: number;
    url: string;
    onClick?: () => void;
  }[];
};

export function SlideShow({
  showDotNavigator,
  showNavigationButton = true,
  initialSlideIndex,
  setInitialSlideIndex,
  dotNavigationColor,
  isDotNavigationInside,
  isDotNavigationOutside,
  isAutomatic,
  height,
  disableMargin = false,
  maxWidth,
  maxImageWidth,
  maxImageHeight,
  images
}: SlideShowProps): JSX.Element {
  const [slideIndex, setSlideIndex] = useState<number>(initialSlideIndex | 0);
  const [automaticSlide, setAutomaticSlide] = useState<boolean>(isAutomatic);

  useEffect(() => {
    if (initialSlideIndex !== undefined) {
      setSlideIndex(initialSlideIndex);
    }
  }, [initialSlideIndex]);

  useEffect(() => {
    if (slideIndex !== undefined && setInitialSlideIndex) {
      setInitialSlideIndex(undefined);
    }
  }, [slideIndex, setInitialSlideIndex]);

  const handleSlideClick = useCallback(
    (number: number) => {
      if (number === 1 && slideIndex + 1 >= images.length) {
        setSlideIndex(0);
      } else if (number === -1 && slideIndex === 0) {
        setSlideIndex(images.length - 1);
      } else {
        setSlideIndex(prevIndex => {
          return prevIndex + number;
        });
      }
    },
    [images.length, slideIndex]
  );

  useEffect(() => {
    let timer;

    if (automaticSlide) {
      timer = setTimeout(() => {
        handleSlideClick(1);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [handleSlideClick, automaticSlide]);

  const handleKeyboardPress = (event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowLeft": {
        handleSlideClick(-1);
        break;
      }
      case "ArrowRight": {
        handleSlideClick(1);
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <SlideShowContainer
        height={height}
        maxWidth={maxWidth}
        onPointerEnter={() => isAutomatic && setAutomaticSlide(false)}
        onPointerLeave={() => isAutomatic && setAutomaticSlide(true)}
        onKeyDown={handleKeyboardPress}
        tabIndex={0}
        disableMargin={disableMargin}
      >
        <Slides maxImageWidth={maxImageWidth} maxImageHeight={maxImageHeight}>
          <img
            src={images[slideIndex]?.url}
            alt={
              images[slideIndex]?.fileName ||
              "Image file" + images[slideIndex]?.id
            }
            style={{
              width: "100%",
              cursor: images[slideIndex]?.onClick ? "pointer" : "initial"
            }}
            onClick={images[slideIndex]?.onClick}
          />
        </Slides>

        {showNavigationButton && (
          <>
            <NavigationButton
              iconName="ChevronLeft"
              onClick={() => handleSlideClick(-1)}
              prevButton={true}
            />

            <NavigationButton
              iconName="ChevronRight"
              onClick={() => handleSlideClick(1)}
              nextButton={true}
            />
          </>
        )}

        {showDotNavigator && isDotNavigationInside && (
          <DotNavigationContainer>
            {images.map((dot, index) => (
              <DotNavigation
                key={dot.fileName || "Image file" + index}
                dotColor={dotNavigationColor}
                isActive={slideIndex === index}
                onClick={() => slideIndex !== index && setSlideIndex(index)}
              />
            ))}
          </DotNavigationContainer>
        )}
      </SlideShowContainer>

      {showDotNavigator && isDotNavigationOutside && (
        <div style={{ textAlign: "center", marginTop: 10 }}>
          {images.map((dot, index) => (
            <DotNavigation
              key={dot.fileName || "Image file" + index}
              dotColor={dotNavigationColor}
              isActive={slideIndex === index}
              onClick={() => slideIndex !== index && setSlideIndex(index)}
            />
          ))}
        </div>
      )}
    </>
  );
}
