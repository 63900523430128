import { Stack } from "@fluentui/react";

import { PlanText } from "../../PlansOffers/PlanOfferContract/PlanOffer.styles";

import { PlanTypeEnum } from "./enums/planTypeEnum";
import { renderPriceText } from "./renderPriceText";
import { Title, Value } from "./styles";

import { currencyValueToLocaleString } from "@/utils/currencyValueToLocaleString";

interface RenderPriceProps {
  price: string;
  discount?: number;
  discountNumberOfMonths?: number;
  beginsAt?: number;
  type: PlanTypeEnum;
}

export const renderPrice = ({
  price,
  discount = 0,
  discountNumberOfMonths,
  beginsAt,
  type
}: RenderPriceProps) => {
  const value = Number(price) - discount;

  const getTitleText = (type: PlanTypeEnum): string => {
    switch (type) {
      case PlanTypeEnum.PLAN:
        return "Valor Base do Plano";
      case PlanTypeEnum.TAG:
        return "Valor Base da Tag";
      default:
        return "";
    }
  };

  return (
    <Stack style={{ padding: "1rem 20px 0 1rem" }}>
      <Title> {getTitleText(type)}</Title>
      <Value>
        {currencyValueToLocaleString(value)}
        <span>/ Mensais</span>
      </Value>
      {type === PlanTypeEnum.PLAN && (
        <PlanText fontSize="14px">
          {renderPriceText({
            price,
            discount,
            discountNumberOfMonths,
            beginsAt
          })}
        </PlanText>
      )}
      <Stack.Item align="end"></Stack.Item>
    </Stack>
  );
};
