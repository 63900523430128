import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class Organizations extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin ? `admin/organizations${subPaths}` : `organizations${subPaths}`
    );
  }

  getAllOrganizationsByUserId({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/user-organizations`
    };
    params.headers && Object.assign(options, { headers: params.headers });
    return makeRequest(options);
  }
}

export default Organizations;
