import { Icon, INavLink, Nav, Stack } from "@fluentui/react";
import { MouseEvent } from "react";
import { useHistory } from "react-router-dom";

import { SidebarComponentsProps } from "../../types/Sidebar.types";

import { PermissionsScope } from "@/common/types/permissions";
import { useGetProfile } from "@/core/libs/api/react-query/index";

export function SidebarFooter({
  theme,
  closeMenu,
  links,
  isMenuExpanded,
  isMenuOpen
}: SidebarComponentsProps): JSX.Element {
  const userProfileQuery = useGetProfile();
  const permissions = userProfileQuery.data?.data?.role.permissions;

  const history = useHistory();

  return (
    <Nav
      onLinkClick={(ev?: MouseEvent<HTMLElement>, item?: INavLink) => {
        if (closeMenu) closeMenu();
        if (ev) ev.nativeEvent.preventDefault();
        if (item.links) item.isExpanded = !item.isExpanded;
        if (item && item.key) history.push(item.key);
      }}
      styles={{
        root: {
          display: permissions[PermissionsScope.ADMIN] ? "block" : "none",
          borderRadius: 0,
          boxSizing: "border-box",
          height: "100%",
          overflowY: "auto",
          width: "100%",
          selectors: {
            ".ms-Nav-compositeLink.is-expanded": {
              background: theme.palette.neutralLighterAlt,
              borderLeft: "2px solid",
              borderLeftColor: theme.palette.neutralQuaternary
            }
          }
        },
        groupContent: {
          marginBottom: 10,
          marginTop: 10
        },
        link: {
          border: 0,
          borderRadius: 0,
          padding: 0
        }
      }}
      groups={[
        {
          links: links
        }
      ]}
      onRenderLink={item => renderItem(item, isMenuExpanded, isMenuOpen)}
    />
  );
}

const renderItem = (item, isMenuExpanded, isMenuOpen) => {
  return (
    <Stack horizontal style={{ width: "100%", paddingLeft: 8 }}>
      <Icon
        iconName={item.iconName}
        styles={{
          root: {
            fontSize: isMenuExpanded ? 16 : 20,
            cursor: "pointer",
            color: "#335393",
            marginRight: 8
          }
        }}
      />
      {(isMenuExpanded || isMenuOpen) && <span>{item.name}</span>}
    </Stack>
  );
};
