import { ListOfBanners } from "./Banners.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum BannersActionsTypes {
  CREATE_REQUEST = "banners/CREATE_REQUEST",
  CREATE_FAIL = "banners/CREATE_FAIL",
  CREATE_SUCCESS = "banners/CREATE_SUCCESS",
  DISMISS_MESSAGE = "banners/DISMISS_MESSAGE",
  EDIT_REQUEST = "banners/EDIT_REQUEST",
  EDIT_FAIL = "banners/EDIT_FAIL",
  EDIT_SUCCESS = "banners/EDIT_SUCCESS",
  LIST_REQUEST = "banners/LIST_REQUEST",
  LIST_FAIL = "banners/LIST_FAIL",
  LIST_SUCCESS = "banners/LIST_SUCCESS"
}

export interface BannersState {
  isLoadingList: boolean;
  list?: ListOfBanners;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: BannersActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: BannersActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: BannersActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: BannersActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: BannersActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: BannersActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: BannersActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: BannersActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: BannersActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: BannersActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfBanners;
  };
}

export type BannersReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
