import { DistributionCentersActionsTypes } from "../types/DistributionCenters.reducer.types";

export function createRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: DistributionCentersActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createSuccess(message) {
  return {
    type: DistributionCentersActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createFailure(message) {
  return {
    type: DistributionCentersActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: DistributionCentersActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editSuccess(message) {
  return {
    type: DistributionCentersActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editFailure(message) {
  return {
    type: DistributionCentersActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listRequest(filter, { isAdmin = false }) {
  return {
    type: DistributionCentersActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listSuccess({ list }) {
  return {
    type: DistributionCentersActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: DistributionCentersActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: DistributionCentersActionsTypes.DISMISS_MESSAGE
  };
}
