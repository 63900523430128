import { AccordionContentWrapper } from "../Accordion/styles";
import AccordionListItem from "../AccordionListItem";

type AccordionContentType = {
  items: Array<any>;
};

function AccordionContent({ items }: AccordionContentType) {
  return (
    <AccordionContentWrapper>
      {items.map(item => (
        <AccordionListItem
          key={item.label}
          label={item.label}
          value={item.value}
          isStrong={item.isStrong}
        />
      ))}
    </AccordionContentWrapper>
  );
}

export default AccordionContent;
