import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const Container = styled.div`
  > div {
    padding: 10px;
    border-radius: 4px;
  }

  :nth-child(even) > div {
    background: ${theme.palette.neutralLighterAlt};
  }
`;
