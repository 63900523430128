import { getTheme, Stack } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const ProductPlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 100%;

  * {
    box-sizing: border-box;
  }
`;

export const ProductRow = styled(Stack.Item)`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 20px;
  }
`;

export const ProductItem = styled(Stack.Item)<{ minWidth?: string }>`
  display: flex;
  align-items: center;
  max-width: 600px;
  ${props => props.minWidth && `min-width: ${props.minWidth}`};

  & + & {
    margin-top: 10px;
  }
`;
export const ProductFilterContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 20px 10px 20px;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 5px solid ${theme.palette.neutralLight};

  > h1 {
    margin-top: 20px;
  }
`;

export const ProductPlansTitle = styled.h1`
  text-transform: uppercase;
  margin: 50px 0 0 20px;
`;

export const ProductBrandLogo = styled.img`
  height: 15px;
`;

export const ProductAppIcon = styled.img`
  height: 25px;
  margin-left: 5px;
`;

export const ProductPlanName = styled.p<{ isSpecial?: boolean }>`
  display: inline-block;
  color: ${props =>
    props.isSpecial ? theme.palette.red : theme.palette.themeDarkAlt};
  text-transform: uppercase;
  margin-left: 10px;
`;

export const ProductPricing = styled.div<{ flexEnd?: boolean }>`
  display: flex;
  justify-content: flex-end;
  column-gap: 40px;
  width: 100%;
  margin-left: 10px;
`;

export const PlanPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  max-width: 110px;
`;

export const CurrencySymbol = styled.span`
  color: ${theme.palette.themeDarkAlt};
`;

export const TotalPrice = styled.span`
  color: ${theme.palette.themeDarkAlt};
`;

export const AdditionalPriceInfo = styled.p`
  color: ${theme.palette.themeDarkAlt};
`;

export const DevicePrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PriceTitle = styled.h2`
  text-transform: uppercase;
  color: ${theme.palette.themeTertiary};
  display: flex;
  text-align: right;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CustomSeparator = styled.div`
  width: 16px;
  height: 100%;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: relative;
    background-color: ${theme.palette.themeTertiary};
    width: 2px;
    height: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    content: "";
    display: block;
    position: relative;
    background-color: ${theme.palette.themeTertiary};
    width: 2px;
    height: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const OfferRules = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OfferTitle = styled.h2`
  text-transform: uppercase;
`;

export const OfferRulesList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  background: ${theme.palette.white};
  border-radius: 5px;
  margin-top: 10px;

  li + li {
    margin-top: 10px;
  }

  > li {
    display: flex;
    align-items: center;
    color: ${theme.palette.blueDark};

    &::before {
      content: "";
      padding: 3px;
      margin-right: 5px;
      border-radius: 50%;
      display: inline-block;
      background: ${theme.palette.blueDark};
    }
  }
`;

export const ButtonContinue = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.palette.themeSecondary};
  border: none;
  border-radius: 5px;
  color: ${theme.palette.white};
  cursor: pointer;
  padding: 15px;
  width: 200px;
  text-transform: uppercase;
`;

export const PlansAppsInfoList = styled.ul`
  margin: 10px;
  padding: 0;

  li + li {
    margin-top: 10px;
  }

  > li {
    display: flex;
    align-items: center;
    color: ${theme.palette.blueDark};

    &::before {
      content: "";
      padding: 3px;
      margin-right: 5px;
      border-radius: 50%;
      display: inline-block;
      background: ${theme.palette.blueDark};
    }
  }
`;
