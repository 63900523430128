import styled, { css } from "styled-components";

export const SpaceContent = styled.div<{
  customMargin: string;
  customAlignItems: string;
}>`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${props =>
    props.customAlignItems
      ? css`
          align-items: ${props.customAlignItems};
        `
      : css`
          align-items: center;
        `}

  & > * {
    width: auto;
    ${({ customMargin }: { customMargin: string; customAlignItems: string }) =>
      customMargin
        ? css`
            margin-right: ${customMargin};
          `
        : css`
            margin-right: 8px;
          `}
  }
  & > *:last-child {
    margin-right: 8px;
    width: auto;
  }
`;
