import { Label, Spinner } from "@fluentui/react";
import { useEffect, useState } from "react";

import SelectOffer from "./SelectOffer";
import { DefaultProps } from "./types";

interface ContractOffersProps extends DefaultProps {
  carrier: string | number;
  offers: any[];
  allPlansAndOffers: any[];
  setOffers: (offers) => void;
  handleSelectedPriceId: ({
    offerPriceId,
    isSmart
  }: {
    offerPriceId: number;
    isSmart: boolean;
  }) => void;
  setIsConectivityOrSmart?: (state: boolean) => void;
}

interface IOfferPrice {
  id: number;
  price: string;
}

interface IDiscount {
  value: string;
  numberOfMonths: string;
}

interface IItem {
  combo: string;
  discount: IDiscount;
  offerPrice: IOfferPrice;
}

enum TypeComboPlan {
  LIGHT = "LIGHT",
  LIGHT_DADOS = "LIGHT DADOS",
  CONECTIVIDADE_BASICA = "CONECTIVIDADE BASICA",
  CONECTIVIDADE_AVANCADA = "CONECTIVIDADE AVANCADA",
  SMART = "SMART",
  TAG = "TAG"
}

interface IOffer {
  offerPrice: { id: number; price: string };
  combo: string | number;
  name: string;
  discount: {
    value: string | number;
    numberOfMonths: string | number;
  };
}

export function ContractOffers({
  allPlansAndOffers,
  carrier,
  setFieldValue,
  handleSelectedPriceId,
  offers,
  setOffers,
  setIsConectivityOrSmart
}: ContractOffersProps): JSX.Element {
  const [groups, setGroups] = useState([]);

  function mapOfferToDetails(offer: IOffer, planId: number) {
    const { offerPrice, combo, name, discount } = offer;

    const comboNameStr = name ? `| ${name}` : "";
    const comboType = TypeComboPlan[combo];
    const offerPriceStr = toLocaleString(offerPrice?.price);
    const discountStr = discount
      ? ` com desconto de ${toLocaleString(discount.value)} por ${
          discount.numberOfMonths
        } meses`
      : "";
    const description = `Combo ${comboType} (${offerPriceStr}/mês${discountStr}) ${comboNameStr}`;

    return {
      key: offerPrice?.id,
      description,
      details: offer,
      plan: planId
    };
  }

  useEffect(() => {
    setOffers([]);
    setGroups([]);

    if (allPlansAndOffers) {
      const groups = [];
      const offers = [];

      Object.entries(allPlansAndOffers).forEach((entry: [string, any]) => {
        groups.push({
          count: entry[1].offers?.length,
          key: entry[0],
          name: entry[1].name,
          startIndex: offers?.length,
          level: 1,
          isCollapsed: true
        });

        if (entry[1] && entry[1]?.offers) {
          entry[1]?.offers.forEach((offer: IOffer) => {
            offers.push(mapOfferToDetails(offer, entry[1].id));
          });
        }
      });

      setGroups(groups);
      setOffers(offers);
    }
  }, [allPlansAndOffers]);

  return (
    <div>
      <Label required={true}>Selecione um benefício</Label>
      <div style={{ border: "1px solid", borderRadius: "2px" }}>
        {offers?.length > 0 && groups?.length > 0 ? (
          <SelectOffer
            offers={offers}
            groups={groups}
            setFieldValue={setFieldValue}
            handleSelectedPriceId={handleSelectedPriceId}
            setIsConectivityOrSmart={setIsConectivityOrSmart}
          />
        ) : (
          <div
            style={{
              display: "flex",
              height: "300px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spinner
              label={
                carrier ? "Carregando pacotes de benefícios" : "Selecione uma operadora"
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}
