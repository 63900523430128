import {
  DefaultButton,
  getTheme,
  Icon,
  PrimaryButton,
  Shimmer,
  ShimmerElementType,
  Stack
} from "@fluentui/react";
import { useState } from "react";

import { DefaultCardTitle } from "../../DisplayContractInfo/styles";

import { PixSection } from "./PixSection";
import { ButtonsWrapper, Container, Section } from "./styles";

import { PaymentMethodType } from "@/common/types/PaymentMethod.types";
import {
  PaymentRequest,
  PaymentRequestStatus
} from "@/common/types/PaymentRequestTypes";
import { Card } from "@/components/Shared/Card";
import { useApi } from "@/core/libs/api/react-query/useApi";

interface PaymentAreaProps {
  paymentType: PaymentMethodType;
  paymentRequest: PaymentRequest;
  canShowConfirmPaymentButton: boolean;
  contractId: number;
  isInsidePanel?: boolean;
  refetchContractData?: () => void;
}

export const PaymentArea = ({
  paymentType,
  paymentRequest,
  canShowConfirmPaymentButton,
  contractId,
  isInsidePanel,
  refetchContractData
}: PaymentAreaProps) => {
  const theme = getTheme();
  const { execute } = useApi();

  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);

  const showErrors =
    paymentType === PaymentMethodType.PIX &&
    !paymentRequest.code &&
    !paymentRequest.transactionId;

  const showConfirmPaymentButton =
    canShowConfirmPaymentButton &&
    paymentRequest?.requestStatus === PaymentRequestStatus.PROCESSING;

  const showPaymentArea = paymentType !== PaymentMethodType.INVOICE_DEBIT;

  const handleConfirmPayment = async () => {
    setIsLoadingPayment(true);
    try {
      await execute({
        url: `contracts/${contractId}/status`,
        method: "POST",
        params: { interaction: "confirm-setup-paid" },
        showAlert: true,
        messages: {
          error: "Erro ao confirmar pagamento",
          success: "Pagamento confirmado com sucesso"
        }
      });

      refetchContractData();
    } finally {
      setIsLoadingPayment(false);
    }
  };

  const handleOpenChat = () => {
    window.fcWidget.open();
  };

  const renderPaymentSection = (
    type: PaymentMethodType,
    paymentRequest: PaymentRequest
  ): JSX.Element | null => {
    switch (type) {
      case PaymentMethodType.PIX:
        return (
          <PixSection
            paymentRequest={paymentRequest}
            handleConfirmPayment={handleConfirmPayment}
            showConfirmPaymentButton={showConfirmPaymentButton}
          />
        );
      case PaymentMethodType.CREDIT_CARD:
        return (
          <Section>
            <h2 style={{ fontSize: "16px" }}>
              Solicite o link de pagamento via cartão de crédito em nossa
              central de atendimento.
            </h2>

            <ButtonsWrapper>
              <DefaultButton text="Abrir Chat" onClick={handleOpenChat} />
              {showConfirmPaymentButton && (
                <PrimaryButton
                  text="Confirmar Pagamento"
                  onClick={() => handleConfirmPayment()}
                />
              )}
            </ButtonsWrapper>
          </Section>
        );
      default:
        return null;
    }
  };

  if (showPaymentArea) {
    return (
      <Stack
        verticalFill
        style={{
          width: `${isInsidePanel ? "100%" : "30%"}`,
          marginTop: "10px"
        }}
      >
        <DefaultCardTitle>
          <Icon iconName="PaymentCard" style={{ marginRight: 10 }} />
          Área de Pagamento
        </DefaultCardTitle>
        <Card
          padding="1.5rem"
          borderRadius="0.75rem"
          height="100%"
          style={{ minHeight: "380px" }}
        >
          {isLoadingPayment ? (
            <Container style={{ flexDirection: "column" }}>
              <Shimmer
                style={{ marginBottom: "1rem", width: "80%" }}
                shimmerElements={[
                  {
                    type: ShimmerElementType.line,
                    height: 250
                  }
                ]}
              />
              <Shimmer
                style={{ marginBottom: "1rem", width: "80%" }}
                shimmerElements={[
                  {
                    type: ShimmerElementType.line,
                    height: 60
                  }
                ]}
              />
            </Container>
          ) : (
            <>
              {showErrors ? (
                <Container>
                  <Section
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "300px"
                    }}
                  >
                    <p
                      style={{
                        color: theme.palette.blackTranslucent40,
                        fontWeight: 700,
                        marginBottom: 0
                      }}
                    >
                      Ocorreu um erro ao gerar o código Pix.
                    </p>
                    <p
                      style={{
                        color: theme.palette.blackTranslucent40,
                        fontWeight: 700,
                        marginBottom: 0
                      }}
                    >
                      Por favor, entre em contato com o suporte.
                    </p>
                    <Icon
                      iconName="StatusErrorFull"
                      style={{
                        fontSize: "100px",
                        color: theme.palette.blackTranslucent40
                      }}
                    />
                  </Section>
                </Container>
              ) : (
                <Container>
                  {renderPaymentSection(paymentType, paymentRequest)}
                </Container>
              )}
            </>
          )}
        </Card>
      </Stack>
    );
  } else {
    return null;
  }
};
