import { IUserInfoDatabase } from "./interfaces/fresh-chat.interface";

import { FreshChatService } from "@/core/libs/api";

const serviceFreshChat = FreshChatService(undefined);
const abortController = new AbortController();
const { signal } = abortController;

export function initFreshChat(userInfo?: IUserInfoDatabase): void {
  const externalId = userInfo?.userInfoDatabase?.externalId;
  const restoreId = userInfo?.userInfoDatabase?.restoreId;

  if (userInfo) {
    window.fcWidget?.init({
      token: "58ffeac5-d1bc-4c90-939a-a3dca60cb161",
      host: "https://wchat.freshchat.com",
      externalId: `${externalId ? externalId : userInfo?.id}`,
      restoreId: `${restoreId ? restoreId : null}`,
      config: {
        headerProperty: {
          hideChatButton: true
        }
      }
    });
  }

  window.fcWidget?.user?.get((resp: any) => {
    const status = resp && resp.status;

    if (status !== 200) {
      window.fcWidget.user.setFirstName(userInfo?.fullName);
      window.fcWidget.user.setEmail(userInfo?.email);
      window.fcWidget.user.setProperties({
        fullName: userInfo?.fullName,
        businessUnit: userInfo?.businessUnitSelected
      });

      window.fcWidget?.on("user:created", async (resp: any) => {
        const status = resp && resp.status,
          data = resp && resp.data;
        if (status === 200) {
          if (data.restoreId) {
            const { externalId, restoreId } = data;
            await serviceFreshChat.upsert({
              signal,
              params: { body: { externalId, restoreId } }
            });
          }
        }
      });
    }
  });
}

function initialize(i, t) {
  let e;
  i.getElementById(t)
    ? initFreshChat()
    : (((e = i.createElement("script")).id = t),
      (e.async = !0),
      (e.src = "https://wchat.freshchat.com/js/widget.js"),
      (e.onload = initFreshChat),
      i.head.appendChild(e));
}
function initiateCall() {
  initialize(document, "Freshchat-js-sdk");
}
window.addEventListener
  ? window.addEventListener("load", initiateCall, !1)
  : window.global.attachEvent("load", initiateCall, !1);
