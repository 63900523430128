interface IData {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
  erro?: boolean;
}

interface IUpdateAddressesFieldsInputs {
  updateInput: (a: string, b: string) => void;
  setPopulatedFields: (obj: object) => void;
  data: IData;
  currentAddress: unknown;
}

export function updateAddressesFieldsInputs({
  updateInput,
  setPopulatedFields,
  data,
  currentAddress
}: IUpdateAddressesFieldsInputs) {
  updateInput(`${currentAddress}.city`, data.localidade);
  updateInput(`${currentAddress}.state`, data.uf);

  if (data.logradouro !== "")
    updateInput(`${currentAddress}.street`, data.logradouro);
  if (data.bairro !== "")
    updateInput(`${currentAddress}.neighborhood`, data.bairro);

  setPopulatedFields({
    city: data.localidade,
    state: data.uf,
    street: data.logradouro !== "" ? data.logradouro : false,
    neighborhood: data.bairro !== "" ? data.bairro : false,
    number: "",
    note: ""
  });
}
