import { useQuery } from "@tanstack/react-query";

import { useApi } from "../../useApi";

import { IInvoiceInfos } from "@/components/Contracts/components/Invoice/interfaces";

export const useGetPaymentConditions = ({
  contractId
}: {
  contractId: number;
}) => {
  const { execute } = useApi();

  return useQuery({
    queryKey: ["payment-conditions", contractId],
    queryFn: async (): Promise<IInvoiceInfos> => {
      const data = await execute({
        url: `contracts/${contractId}/payment-conditions`
      });

      return data.data;
    }
  });
};
