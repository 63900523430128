import { Separator, Stack } from "@fluentui/react";

enum TranslateAccountInfoField {
  EMAIL = "Email",
  PHONE_NUMBER = "Telefone",
  WHATSAPP = "Whatsapp"
}

interface IInfoCamp {
  title?: string;
  value: string | { title: string; value: string }[];
  separator?: string;
}

export const InfoCamp = ({
  title,
  value
}: {
  title?: string;
  value?: string | IInfoCamp[];
}): JSX.Element => {
  const textColor = value ? undefined : "red";

  return (
    <div
      style={{
        lineHeight: 1.2,
        overflowWrap: "break-word",
        maxWidth: "17rem"
      }}
    >
      {title && (
        <div
          className="ms-fontWeight-semibold"
          style={{ width: "280px", color: textColor }}
        >
          {title}
        </div>
      )}

      {typeof value === "string" ? (
        <div>{value}</div>
      ) : (
        <Stack>
          {value?.map((data, idx) => {
            const text = data?.title
              ? `${TranslateAccountInfoField[data?.title] || data?.title}: ${
                  data?.value
                }`
              : data?.value;

            const mapTextColor = data.value ? undefined : "red";
            const maxFontWeight = data.value ? undefined : "bolder";

            if (data.separator) {
              return <Separator key={`separator-${idx}`} />;
            }

            return (
              <span
                key={`${data?.value}${idx}`}
                style={{
                  overflowWrap: "break-word",
                  color: mapTextColor,
                  fontWeight: maxFontWeight
                }}
              >
                {text}
              </span>
            );
          })}
        </Stack>
      )}
    </div>
  );
};
