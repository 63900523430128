import Api from "./api";

class Roles extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/roles${subPaths}` : `roles${subPaths}`);
  }
}

export default Roles;
