import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ProductContractQuestions } from "./ProductContractQuestion";

import { ContractsService, OperationsService } from "@/core/libs/api";
import CarriersApi from "@/core/libs/api/lib/carriers";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import { IMessageBar, messageBarTypes } from "@/core/libs/message-bar";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import {
  OperationTypes,
  ProductContractOperation,
  ProductContractType,
  ProductOfferItem,
  SaleCondition
} from "@/modules/Offers/types/ProductsOffers.types";

type ProductContractProps = {
  setProductStep: (step: string) => void;
  selectedOfferItem: SaleCondition;
  productOffer: ProductOfferItem;
};

const abortController = new AbortController();
const { signal } = abortController;

const contractService = ContractsService();
const operationService = OperationsService();
const carriers = new CarriersApi("", false);

const createContractItem = (values, contractId, { businessActionId }) => {
  const params = {
    id: contractId,
    body: values,
    query: { businessActionId }
  };

  return contractService.saveItem({ params, signal });
};

const createItemInNewContract = (values, { businessActionId }) => {
  const params = {
    body: values,
    query: { businessActionId }
  };

  return contractService.saveItemInNewContract({ params, signal });
};

export function ProductContract({
  productOffer,
  setProductStep,
  selectedOfferItem
}: ProductContractProps): JSX.Element {
  const [selectedIccidInventory, setSelectedIccidInventory] =
    useState<number>(null);
  const [contractType, setContractType] = useState<string>(undefined);
  const [contractOperations, setContractOperations] = useState(undefined);
  const [contractLines, setContractLines] = useState(undefined);
  const [contractAreaCodes, setContractAreaCodes] = useState(undefined);
  const [operation, setOperation] = useState<OperationTypes>(undefined);
  const [selectedContract, setSelectedContract] = useState(undefined);
  const [selectedSignatureType, setSelectedSignatureType] =
    useState<string>(undefined);
  const [selectedPhoneNumber, setSelectedPhoneNumber] =
    useState<string>(undefined);
  const [selectedInstallment, setSelectedInstallment] = useState<string>(null);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(true);
  const [submitMessage, setSubmitMessage] = useState<IMessageBar>(null);
  const [carriersList, setCarriersList] = useState();
  const [initialCarrier, setInitialCarrier] = useState();
  const [selectedPhoneId, setSelectedPhoneId] = useState<number>(undefined);
  const history = useHistory();

  const { selectedBU } = useSelectedBu();

  const [selectedPhoneAreaCode, setSelectedPhoneAreaCode] = useState<string>(
    selectedBU?.defaultAreaCode
      ? String(selectedBU?.defaultAreaCode)
      : undefined
  );

  const requestOperations = async () => {
    const serviceFromOfferItem =
      selectedOfferItem.offerPrice?.offer?.plan?.service?.id;
    const params = {
      query: {
        service: serviceFromOfferItem || 1,
        businessActionId: selectedBU?.id,
        limit: 100
      }
    };
    const response = await operationService.list({ params, signal });

    if (response?.data?.length > 0) {
      const formatToSelect = response.data.map(operation => ({
        key: operation.id,
        text: operation.name,
        data: operation
      }));

      setContractOperations(formatToSelect);
    }
  };

  const getCarriers = async () => {
    const carriersList = await carriers.listCarriers();

    setCarriersList(
      carriersList
        .filter(carrier => carrier.name !== "Taggy")
        .map(carrier => ({ text: carrier.name, key: carrier.id }))
    );
  };

  const requestLines = useCallback(async () => {
    if (contractLines !== undefined) {
      setContractLines(undefined);
    }

    const params = {
      query: {
        plan: selectedOfferItem.offerPrice.offer.plan.id,
        ddd: selectedPhoneAreaCode,
        businessActionId: selectedBU?.id
      }
    };
    const response = await contractService.listLines({ params, signal });

    if (response?.data?.lines?.length > 0) {
      const formatToSelect = response.data.lines.map(line => ({
        key: line.id,
        text: `${line.number.slice(2, -4)}-${line.number.slice(-4)}`
      }));

      setContractLines(formatToSelect);
    }

    if (response?.data?.availableAreaCodes?.length > 0) {
      const formatToSelect = response.data.availableAreaCodes.map(areaCode => ({
        key: areaCode.code,
        text: areaCode.code
      }));

      setContractAreaCodes(formatToSelect);
    }
  }, [selectedPhoneAreaCode, selectedBU]);

  useEffect(() => {
    if (operation) {
      if (operation.type !== "RENEWAL" && operation.type !== "BRING_YOUR_OWN") {
        setIsLoadingOptions(true);
        requestLines().then(() => setIsLoadingOptions(false));
      }
      if (operation.type === "BRING_YOUR_OWN" && operation.id == 2) {
        setIsLoadingOptions(true);
        getCarriers().then(() => setIsLoadingOptions(false));
      }
    }
  }, [selectedPhoneAreaCode, operation, requestLines]);

  useEffect(() => {
    requestOperations().then(() => setIsLoadingOptions(false));
  }, []);

  useEffect(() => {
    if (
      !(Number(selectedOfferItem.cashValue) > 0) &&
      !(Number(selectedOfferItem.financedValue) > 0)
    ) {
      // Default value for free product
      setSelectedInstallment("24");
    }
  }, [selectedOfferItem.cashValue, selectedOfferItem.financedValue]);

  useEffect(() => {
    const contractTypeExists = contractType !== undefined;
    const signatureTypeExists = selectedSignatureType !== undefined;
    const operationTypeExists = operation !== undefined;
    const operationTypeIsPortability =
      String(operation?.id) === ProductContractOperation.PORTABILIDADE;
    const selectedPhoneNumberExists = selectedPhoneNumber !== undefined;
    const selectedPhoneNumberIsValid = selectedPhoneNumber?.length === 11;
    const selectedContractExists = selectedContract !== undefined;
    const selectedInstallmentExists = selectedInstallment !== null;
    const starmoveValidation =
      +productOffer.brand.id === 8 ? !selectedInstallmentExists : true;

    switch (contractType) {
      case ProductContractType.NEW: {
        if (
          contractTypeExists &&
          operationTypeExists &&
          signatureTypeExists &&
          selectedPhoneNumberExists &&
          starmoveValidation
        ) {
          if (operationTypeIsPortability && selectedPhoneNumberIsValid) {
            setIsSubmitButtonDisabled(false);
          } else if (!operationTypeIsPortability) {
            setIsSubmitButtonDisabled(false);
          } else {
            if (!isSubmitButtonDisabled) {
              setIsSubmitButtonDisabled(true);
            }
          }
        } else {
          if (!isSubmitButtonDisabled) {
            setIsSubmitButtonDisabled(true);
          }
        }

        break;
      }

      case ProductContractType.EXISTENT: {
        if (
          contractTypeExists &&
          operationTypeExists &&
          selectedPhoneNumberExists &&
          selectedContractExists &&
          (selectedInstallmentExists || +productOffer.brand.id === 8)
        ) {
          if (operationTypeIsPortability && selectedPhoneNumberIsValid) {
            setIsSubmitButtonDisabled(false);
          } else if (!operationTypeIsPortability) {
            setIsSubmitButtonDisabled(false);
          } else {
            if (!isSubmitButtonDisabled) {
              setIsSubmitButtonDisabled(true);
            }
          }
        } else {
          if (!isSubmitButtonDisabled) {
            setIsSubmitButtonDisabled(true);
          }
        }

        break;
      }

      default:
        break;
    }
  }, [
    contractType,
    isSubmitButtonDisabled,
    operation,
    selectedPhoneNumber,
    selectedContract,
    selectedSignatureType,
    selectedInstallment
  ]);

  const onSubmit = (additionalContractItems = []) => {
    setIsSubmitting(true);

    if (contractType === ProductContractType.NEW) {
      const values = {
        businessActionId: selectedBU?.id,
        line: selectedPhoneId,
        lineNumber: selectedPhoneNumber,
        offerPrice: selectedOfferItem.offerPrice.id,
        saleCondition: selectedOfferItem.id,
        operation: operation.id,
        signatureType: selectedSignatureType,
        carrier: undefined,
        plan: undefined,
        additionalInfo: {
          installment: selectedInstallment
        },
        additionalContractItems
      };
      if (selectedIccidInventory)
        Object.assign(values, { iccidInventoryId: selectedIccidInventory });

      if (String(operation.id) === ProductContractOperation.PORTABILIDADE) {
        values.carrier = selectedOfferItem.offerPrice.offer.plan.carrier.id;
        values.plan = selectedOfferItem.offerPrice.offer.plan.id;
      }

      const handleNewContract = async () => {
        const response = await createItemInNewContract(values, {
          businessActionId: selectedBU?.id
        });

        if (response.response.ok) {
          history.push(`contracts/${response?.data?.contract}/edit`, {
            message: response?.data?.message
          });
        } else {
          setIsSubmitting(false);

          setSubmitMessage({
            code: response.error?.status,
            message: response.error?.message,
            type: messageBarTypes.ERROR
          });
        }
      };

      handleNewContract();
    } else if (contractType === ProductContractType.EXISTENT) {
      const values = {
        offerPrice: selectedOfferItem.offerPrice.id,
        line: selectedPhoneId,
        lineNumber: selectedPhoneNumber,
        operation: operation.id,
        saleCondition: selectedOfferItem.id,
        carrier: undefined,
        plan: undefined,
        additionalInfo: {
          installment: selectedInstallment
        },
        additionalContractItems
      };

      if (String(operation.id) === ProductContractOperation.PORTABILIDADE) {
        values.carrier = selectedOfferItem.offerPrice.offer.plan.carrier.id;
        values.plan = selectedOfferItem.offerPrice.offer.plan.id;
      }

      const handleNewItem = async () => {
        const response = await createContractItem(
          values,
          selectedContract?.id,
          { businessActionId: selectedContract?.businessUnit?.id }
        );

        if (response.response.ok) {
          history.push(
            `contracts/${selectedContract.id}/edit`,
            !selectedContract.account
              ? {
                  message: response?.data?.message
                }
              : null
          );
        } else {
          setIsSubmitting(false);

          setSubmitMessage({
            code: response.error?.status,
            message: response.error?.message,
            type: messageBarTypes.ERROR
          });
        }
      };

      handleNewItem();
    }
  };

  const handleSelectedContractType = (type: string) => {
    if (selectedSignatureType) {
      setSelectedSignatureType(undefined);
    }

    setContractType(type);
  };

  return (
    <ProductContractQuestions
      productOffer={productOffer}
      selectedBU={selectedBU}
      onSubmit={onSubmit}
      selectedCashValue={selectedOfferItem.cashValue}
      selectedFinancedValue={selectedOfferItem.financedValue}
      isSubmitting={isSubmitting}
      contractLines={contractLines}
      availableAreaCodes={contractAreaCodes}
      contractOperations={contractOperations}
      contractType={contractType}
      operation={operation}
      selectedPhoneAreaCode={selectedPhoneAreaCode}
      selectedPhoneNumber={selectedPhoneNumber}
      setContractType={handleSelectedContractType}
      setSelectedSignatureType={setSelectedSignatureType}
      selectedSignatureType={selectedSignatureType}
      setOperationType={setOperation}
      setProductStep={setProductStep}
      setSelectedContract={setSelectedContract}
      setSelectedPhoneAreaCode={setSelectedPhoneAreaCode}
      setSelectedPhoneNumber={setSelectedPhoneNumber}
      setSelectedInstallment={setSelectedInstallment}
      setIccid={inventoryId => setSelectedIccidInventory(inventoryId)}
      isLoadingOptions={isLoadingOptions}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      clearSubmitMessage={() => setSubmitMessage(null)}
      submitMessage={submitMessage}
      carriers={carriersList}
      setInitialCarrier={setInitialCarrier}
      initialCarrier={initialCarrier}
      selectedOfferItem={selectedOfferItem}
      setSelectedPhoneId={value => setSelectedPhoneId(value)}
      selectedPhoneId={selectedPhoneId}
    />
  );
}
