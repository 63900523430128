import { ListOfUsers } from "./Users.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum UsersActionsTypes {
  CREATE_REQUEST = "users/CREATE_REQUEST",
  CREATE_FAIL = "users/CREATE_FAIL",
  CREATE_SUCCESS = "users/CREATE_SUCCESS",
  DISMISS_MESSAGE = "users/DISMISS_MESSAGE",
  EDIT_REQUEST = "users/EDIT_REQUEST",
  EDIT_FAIL = "users/EDIT_FAIL",
  EDIT_SUCCESS = "users/EDIT_SUCCESS",
  LIST_REQUEST = "users/LIST_REQUEST",
  LIST_FAIL = "users/LIST_FAIL",
  LIST_SUCCESS = "users/LIST_SUCCESS"
}

export interface UsersState {
  isLoadingList: boolean;
  list: ListOfUsers;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: UsersActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: UsersActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: UsersActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: UsersActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: UsersActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: UsersActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: UsersActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: UsersActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: UsersActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: UsersActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfUsers;
  };
}

export type UsersReducersActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
