import {
  MessageBar as FabricMessageBar,
  MessageBarType
} from "@fluentui/react/lib/MessageBar";

import { messageBarTypes } from "./types";

function detectMessageType(message) {
  if (message?.type) {
    return message?.type;
  } else {
    if (message?.code) {
      switch (message?.code.toString().charAt(0)) {
        case "5":
          return messageBarTypes.ERROR;

        case "4":
          return messageBarTypes.WARNING;

        default:
          return messageBarTypes.DEFAULT;
      }
    }
  }
}

function renderMessage(message, dismissMessage): JSX.Element {
  const messageType = detectMessageType(message);

  switch (messageType) {
    case "blocked":
      return (
        <BlockedMessage message={message} dismissMessage={dismissMessage} />
      );

    case "error":
      return <ErrorMessage message={message} dismissMessage={dismissMessage} />;

    case "severeWarning":
      return (
        <SevereWarningMessage
          message={message}
          dismissMessage={dismissMessage}
        />
      );

    case "success":
      return (
        <SuccessMessage message={message} dismissMessage={dismissMessage} />
      );

    case "warning":
      return (
        <WarningMessage message={message} dismissMessage={dismissMessage} />
      );

    default:
      return (
        <DefaultMessage message={message} dismissMessage={dismissMessage} />
      );
  }
}

function BlockedMessage({ message, dismissMessage }): JSX.Element {
  return (
    <FabricMessageBar
      messageBarType={MessageBarType.blocked}
      onDismiss={dismissMessage}
      dismissButtonAriaLabel="Close"
    >
      {message?.code ? `[${message?.code}] - ` : null}
      {message?.message}
    </FabricMessageBar>
  );
}

function DefaultMessage({ message, dismissMessage }): JSX.Element {
  return (
    <FabricMessageBar onDismiss={dismissMessage} dismissButtonAriaLabel="Close">
      {message?.code ? `[${message?.code}] - ` : null}
      {message?.message}
    </FabricMessageBar>
  );
}

function ErrorMessage({ message, dismissMessage }): JSX.Element {
  return (
    <FabricMessageBar
      messageBarType={MessageBarType.error}
      onDismiss={dismissMessage}
      dismissButtonAriaLabel="Close"
    >
      {message?.code ? `[${message?.code}] - ` : null}
      {message?.message}
    </FabricMessageBar>
  );
}

function SevereWarningMessage({ message, dismissMessage }): JSX.Element {
  return (
    <FabricMessageBar
      messageBarType={MessageBarType.severeWarning}
      onDismiss={dismissMessage}
      dismissButtonAriaLabel="Close"
    >
      {message?.code ? `[${message?.code}] - ` : null}
      {message?.message}
    </FabricMessageBar>
  );
}

function SuccessMessage({ message, dismissMessage }): JSX.Element {
  return (
    <FabricMessageBar
      messageBarType={MessageBarType.success}
      onDismiss={dismissMessage}
      dismissButtonAriaLabel="Close"
    >
      {message?.code ? `[${message?.code}] - ` : null}
      {message?.message}
    </FabricMessageBar>
  );
}

function WarningMessage({ message, dismissMessage }): JSX.Element {
  return (
    <FabricMessageBar
      messageBarType={MessageBarType.warning}
      onDismiss={dismissMessage}
      dismissButtonAriaLabel="Close"
    >
      {message?.code ? `[${message?.code}] - ` : null}
      {message?.message}
    </FabricMessageBar>
  );
}

export function MessageBar({ message, dismissMessage }): JSX.Element {
  return renderMessage(message, dismissMessage);
}
