import { getTheme, Stack } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const ImageLogo = styled.img`
  height: 30px;
  margin: 20px 0;
`;

export const StyledBadgeRibbonContainer = styled(Stack)<{ color: string }>`
  position: relative;
  display: inline-block;
  width: 100%;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: -15px;
    right: 0;
    background-color: ${props => props.color};
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover::after {
    overflow: visible;
    max-width: unset;
  }
`;

export const PlanButton = styled.button<{
  isActive?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
}>`
  font-size: 14px;
  font-weight: 600;
  background: ${props =>
    props.isActive ? theme.palette.themeSecondary : theme.palette.neutralLight};
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  color: ${props => (props.isActive ? "white" : "initial")};
  width: ${props => (props.width ? props.width : "")};
  min-width: ${props => (props.minWidth ? props.minWidth : "")};
  max-width: ${props => (props.maxWidth ? props.maxWidth : "")};
  &:hover {
    background: ${theme.palette.themeSecondary};
    color: white;
  }
`;
