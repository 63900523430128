import { Pivot, PivotItem } from "@fluentui/react";
import { FormikErrors, FormikTouched } from "formik";
import { useEffect, useState } from "react";

import { FormBuilderSwitcher } from "./form-builder-switcher";
import { Input } from "./types";
interface PivotMenuProps {
  input: Input;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: FormikErrors<any>;
  values: any;
  touched: FormikTouched<any>;
  linkedGroups: any;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
}

export const PivotMenu = (props: PivotMenuProps) => {
  const {
    input,
    setFieldValue,
    errors,
    values,
    touched,
    linkedGroups,
    setFieldTouched
  } = props;

  const [selectedItem, setSelectedItem] = useState(0);

  const validateSchema = async () => {
    try {
      const schema = input.fields[selectedItem].schema;
      if (schema) {
        await schema.validate(values, { abortEarly: false });
        return true;
      }
    } catch (err) {
      err.inner.forEach(error => {
        setFieldTouched(error.path, true);
      });
      return false;
    }
  };
  const validateFieldsWhenChangeItem = async () => {
    const isValid = await validateSchema();
    if (isValid) {
      setSelectedItem(input.selectedKey);
      return true;
    } else {
      input.setSelectedKey(input.selectedKey - 1);
      return false;
    }
  };
  const _handleLinkClick = async (item: PivotItem): Promise<void> => {
    if (input.fields[selectedItem].schema) {
      const isValid = await validateSchema();
      if (isValid) {
        const itemFinded = input.fields.findIndex(
          ip => ip.key === item.props.itemKey
        );
        input.setSelectedKey(itemFinded);
        if (input.fields.length - 1 === itemFinded) {
          input.onSubMenuChange(true);
        } else {
          input.onSubMenuChange(false);
        }
      }
    } else {
      const itemFinded = input.fields.findIndex(
        ip => ip.key === item.props.itemKey
      );
      input.setSelectedKey(itemFinded);
      if (input.fields.length - 1 === itemFinded) {
        input.onSubMenuChange(true);
      } else {
        input.onSubMenuChange(false);
      }
    }
  };

  useEffect(() => {
    if (selectedItem === input.fields.length - 1) {
      input.onSubMenuChange(true);
    } else {
      input.onSubMenuChange(false);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (
      input.selectedKey !== selectedItem &&
      input.selectedKey < input.fields.length
    ) {
      if (input.fields[selectedItem].schema) {
        validateFieldsWhenChangeItem();
      } else {
        setSelectedItem(input.selectedKey);
      }
    } else if (input.selectedKey !== selectedItem) {
      validateFieldsWhenChangeItem();
      input.setSelectedKey(selectedItem);
    }
  }, [input.selectedKey]);

  return (
    <Pivot
      selectedKey={input.fields[selectedItem]?.key}
      onLinkClick={item => {
        _handleLinkClick(item);
      }}
      style={{
        width: "100%",
        justifyContent: "center"
      }}
      key={input.key}
    >
      {input.fields?.map(item => (
        <PivotItem
          key={`pivotItemKey_${item.key}`}
          headerText={item.name}
          itemKey={item.key}
        >
          {item.fields.map(field => {
            return (
              <div key={field.name} style={{ margin: "10px" }}>
                <FormBuilderSwitcher
                  errors={errors}
                  input={field}
                  linkedGroups={linkedGroups}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                />
              </div>
            );
          })}
        </PivotItem>
      ))}
    </Pivot>
  );
};
