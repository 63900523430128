import { Organization } from "@/modules/Settings/types/BusinessUnits.types";

export enum PaymentTypes {
  PIX = "PIX",
  BANK_TRANSFER = "BANK_TRANSFER"
}

export type PaymentType = `${PaymentTypes}`;

export type ActivationFeePaymentInfo = {
  paymentType: PaymentType;
  data: {
    key?: string;
    bankNumber?: string;
    agencyNumber?: string;
    accountNumber?: string;
  };
};

export type BuSettings = {
  defaultPhoneAreaCode: string;
  hasContractActivationFee: boolean;
  calculationRationale: string;
  newsLink: string;
  powerbi?: string;
  powerbi1?: string;
  powerbi2?: string;
  powerbi3?: string;
  offersLink: string;
  paymentMethod: string;
  promotionsLink: string;
  sandbox: boolean;
  activationFeePaymentInfo?: ActivationFeePaymentInfo[];
  creditApprovalMessage?: string;
  acceptChipReplacement?: string;
  hideStandardSignature?: string;
  hideDigitalSignature?: string;
};

export type Profile = {
  id: number;
  fullName: string;
  email: string;
  organizations: Organization[];
  businessUnit: {
    id: number;
    name: string;
    parent?: BusinessUnit;
  };
  businessUnits?: {
    id: number;
    name: string;
    settings?: BuSettings;
    children?: BusinessUnit[];
  }[];
  role: {
    id: number;
    permissions: {
      [scope: string]: {
        [module: string]: string[];
      };
    };
  };
};

export type BusinessUnit = {
  id: number;
  all?: boolean;
  fullName: string;
  name?: string;
  agencyNumber?: string;
  bankNumber?: string;
  isAutonomous?: boolean;
  defaultAreaCode?: number;
  paymentMethod?: string;
  isIccidRequired?: boolean;
  settings?: BuSettings;
  parent?: BusinessUnit;
  whatsAppNumber?: string;
  salesTeamId?: number;
};

export type EditProfile = Profile;

export interface ProfileComponentProps {
  dismissMessage: () => void;
  edit: (values, { setSubmitting }) => void;
  message: any;
  profile: EditProfile;
}
