import { Stack, Text } from "@fluentui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { INotificationEvent } from "./interfaces/INotificationEvent";
import { OptOutCard } from "./OptOutCard";
import { ShimmerSection } from "./ShimmerSection";

import { Wrap } from "@/components/styles/OptOut";
import { useApi } from "@/core/libs/api/react-query/useApi";

export const OptOutComponent = (): JSX.Element => {
  const [notificationEvents, setNotificationEvents] =
    useState<INotificationEvent[]>();

  const { execute } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: ["USER-NOTIFICATION-EVENTS"],
    queryFn: () => {
      return execute({
        url: "notification-event/user",
        notificationMessage: "Buscando notificações disponíveis para o usuário"
      });
    },
    staleTime: 240000
  });

  useEffect(() => {
    if (data?.data) {
      setNotificationEvents(data.data);
    }
  }, [data]);

  return (
    <Stack>
      <Text
        variant={"xLarge"}
        styles={{ root: { color: "#395CA3", paddingBottom: "1rem" } }}
      >
        Configuração de Notificações
      </Text>

      <Text
        variant={"mediumPlus"}
        styles={{ root: { fontWeight: "bold", paddingBottom: "1rem" } }}
      >
        Bloqueio de Notificações
      </Text>

      <Wrap>
        {isFetching && (
          <>
            <ShimmerSection />
            <ShimmerSection />
          </>
        )}

        {notificationEvents?.map(notificationEvent => (
          <OptOutCard
            key={notificationEvent.id}
            title={notificationEvent.name}
            notificationEventId={notificationEvent.id}
          />
        ))}
      </Wrap>
    </Stack>
  );
};
