import { useCallback, useContext, useEffect, useState } from "react";

import { OrganizationsService } from "../../api";
import { saveAuthKey } from "../../api/utils/auth";

import { AuthContext } from "./AuthProvider";
import { dismissMessageAction, handleLogin, logoutAction } from "./authReducer";
import { actionTypes, UseAuthInterface } from "./types";

import { useOrganization } from "@/hooks/useOrganization";
import { AuthParams } from "@/lib/auth";

export const useAuth: UseAuthInterface = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { openOrganizationSelection, organizationSelected } = useOrganization();

  const [authKeys, setAuthKeys] = useState<AuthParams>(undefined);

  const login = useCallback(
    async (values, { setSubmitting }) => {
      const result = await handleLogin({ dispatch, data: values });
      const organizationService = OrganizationsService("", true);
      const { signal } = new AbortController();

      if (!result.error) {
        const params = {
          headers: {
            authorization: `Bearer ${result.data.accessToken.token}`
          }
        };

        const organizationsResponse =
          await organizationService.getAllOrganizationsByUserId({
            params,
            signal
          });

        if (!organizationsResponse.error) {
          openOrganizationSelection(organizationsResponse.data);

          setAuthKeys({
            accessToken: result.data.accessToken.token,
            accessTokenExpiration: result.data.accessToken.exp,
            refreshToken: result.data.refreshToken.token,
            refreshTokenExpiration: result.data.refreshToken.exp
          });
        }
      }
      setSubmitting(false);
    },
    [dispatch, openOrganizationSelection]
  );

  const logout = () => {
    logoutAction({ dispatch });
  };

  const dismissMessage = () => {
    dismissMessageAction({ dispatch });
  };

  useEffect(() => {
    if (!state.isAuthenticated && authKeys && organizationSelected) {
      saveAuthKey(authKeys);

      dispatch({
        type: actionTypes.LOGIN_SUCCESS
      });
    }
  }, [state.isAuthenticated, authKeys, organizationSelected]);

  return {
    isAuthenticating: state.isAuthenticating,
    isAuthenticated: state.isAuthenticated,
    isChecking: state.isChecking,
    message: state.message,
    login,
    logout,
    dismissMessage
  };
};
