import { CarriersActionsTypes } from "../types/Carriers.reducer.types";

export function createCarriersRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: CarriersActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createCarriersSuccess(message) {
  return {
    type: CarriersActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createCarriersFailure(message) {
  return {
    type: CarriersActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editCarriersRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: CarriersActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editCarriersSuccess(message) {
  return {
    type: CarriersActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editCarriersFailure(message) {
  return {
    type: CarriersActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listCarriersRequest(filter, { isAdmin = false }) {
  return {
    type: CarriersActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listCarriersSuccess({ list }) {
  return {
    type: CarriersActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listCarriersFailure() {
  return {
    type: CarriersActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: CarriersActionsTypes.DISMISS_MESSAGE
  };
}
