import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const PlanContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  background: ${theme.palette.neutralLighterAlt};
  overflow-y: auto;

  * {
    box-sizing: border-box;
  }
`;
