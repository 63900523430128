import { Contract } from "../../contract.interface";

export interface SelectSignatureProps {
  showSignatures: boolean;
  setShowSignaturesDialog: (value: boolean) => void;
  contract: Contract;
  refetchContract: () => void;
  openModalConfirmContract: () => Promise<void>;
  setShowConfirmContract: (value: boolean) => void;
  selectedSalesTeamId: number;
  travelDate: Date;
}

export interface GetAccountSignaturesProps {
  accountId: number;
  contactType: string;
}

export interface FormValuesType {
  whatsapp: string;
  email: string;
}
export enum ContactType {
  PHONE_NUMBER = "PHONE_NUMBER",
  CELL_NUMBER = "CELL_NUMBER",
  FAX = "FAX",
  EMAIL = "EMAIL",
  SKYPE = "SKYPE",
  WHATSAPP = "WHATSAPP",
  FACEBOOK = "FACEBOOK",
  STANDARD = "STANDARD"
}

export interface AccountContact {
  id: number;
  contactType: ContactType;
  contact: string;
}

export interface LegalRepresentative {
  id: number;
  email: string;
  whatsapp: string;
}

export interface OverwriteContract {
  accountId: number;
  signatureId?: string;
  salesTeamId?: number;
  accountContact?: AccountContact;
  accountLegalRepresentatives?: LegalRepresentative[];
  travelDate?: Date;
}

export interface SelectedSignatureProps {
  id: string;
  name: ContactType | any;
}

export interface SelectedContact {
  id: string;
  name: string;
}

export interface ContactsPhysicalProps {
  id: string;
  objectTypeCode: string;
  contact: string;
  contactType: ContactType;
  parentTypeCodeId: number;
}

export interface ContactsLegalProps {
  birthDate: string;
  document: string;
  email: string;
  fullName: string;
  id: string;
  parentTypeCodeId: number;
  role: null | string;
  whatsapp: string;
}

export type Contacts = ContactsPhysicalProps[] | ContactsLegalProps[];
