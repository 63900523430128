import {
  MembershipStatusEnum,
  MembershipStatusTranslator
} from "../types/MembershipPage.types";
interface IColoredLabel {
  backgroundColor: string;
  color: string;
  children: string;
  borderRadius?: string;
}

export const translateMembershipStatus = (
  membershipStatus: string
): IColoredLabel => {
  const object = {
    [MembershipStatusEnum.EXPIRED]: {
      children: MembershipStatusTranslator.EXPIRED,
      borderColor: "#F04A00",
      color: "#F04A00"
    },
    [MembershipStatusEnum.REFUSED]: {
      children: MembershipStatusTranslator.REFUSED,
      borderColor: "#ff0000",
      color: " 	#ff0000"
    },
    [MembershipStatusEnum.DISSOCIATED]: {
      children: MembershipStatusTranslator.DISSOCIATED,
      color: "#4D4D4D",
      borderColor: "#4D4D4D"
    },
    [MembershipStatusEnum.NOT_ASSOCIATED]: {
      children: MembershipStatusTranslator.NOT_ASSOCIATED,
      color: "#4D4D4D",
      borderColor: "#4D4D4D"
    },
    [MembershipStatusEnum.INITIALIZED]: {
      children: MembershipStatusTranslator.INITIALIZED,
      color: "#335393",
      borderColor: "#335393"
    },
    [MembershipStatusEnum.SIGNED]: {
      children: MembershipStatusTranslator.SIGNED,
      borderColor: "#01A020",
      color: "#01A020"
    },
    [MembershipStatusEnum.INTEGRATED]: {
      children: MembershipStatusTranslator.INTEGRATED,
      borderColor: "#01A020",
      color: "#01A020"
    },
    [MembershipStatusEnum.AWAITING_SIGNATURE]: {
      children: MembershipStatusTranslator.AWAITING_SIGNATURE,
      color: "#FFBF00",
      borderColor: "#FFBF00"
    },
    [MembershipStatusEnum.INVOICE_CHARGED]: {
      children: MembershipStatusTranslator.INVOICE_CHARGED,
      color: "#335393",
      borderColor: "#335393"
    }
  };
  return object[membershipStatus];
};
