import produce from "immer";

import {
  OffersActionsTypes,
  OffersReducerActions,
  OffersState
} from "../types/Offers.reducer.types";

const INITIAL_STATE: OffersState = {
  list: undefined,
  listOfOfferPrice: undefined,
  isLoadingList: false,
  isLoadingOfferPriceList: false,
  message: undefined,
  offerPriceMessage: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: OffersReducerActions
): OffersState {
  return produce(state, draft => {
    switch (action.type) {
      case OffersActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.CREATE_OFFER_PRODUCT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersActionsTypes.CREATE_OFFER_PRODUCT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.CREATE_OFFER_PRODUCT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.DELETE_OFFER_PRODUCT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersActionsTypes.DELETE_OFFER_PRODUCT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.DELETE_OFFER_PRODUCT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case OffersActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.DELETE_OFFER_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersActionsTypes.DELETE_OFFER_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.DELETE_OFFER_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case OffersActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case OffersActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }

      case OffersActionsTypes.LIST_OFFER_PRICE_REQUEST: {
        draft.isLoadingOfferPriceList = true;
        draft.listOfOfferPrice = undefined;
        break;
      }

      case OffersActionsTypes.LIST_OFFER_PRICE_FAIL: {
        draft.isLoadingOfferPriceList = false;
        draft.listOfOfferPrice = undefined;
        break;
      }

      case OffersActionsTypes.LIST_OFFER_PRICE_SUCCESS: {
        draft.isLoadingOfferPriceList = false;
        draft.listOfOfferPrice = action.payload.list;
        break;
      }
      default:
    }
  });
}
