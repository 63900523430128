import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const BadgeContainer = styled.span`
  --badge-base: 4px;

  --badge-color: ${theme.palette.themePrimary};
  --badge-text-color: ${theme.palette.white};
  --badge-size: 1.5;

  /* Fluent UI's font-family. */
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;

  color: var(--badge-text-color);
  background-color: var(--badge-color);

  display: inline-flex;
  gap: calc(4px * var(--badge-size));
  border-radius: 2px;
  align-items: center;

  padding: calc(4px * var(--badge-size)) calc(4px * var(--badge-size) * 1.5);

  &.primary {
    --badge-color: ${theme.palette.themePrimary};
  }

  &.secondary {
    --badge-color: ${theme.palette.themeSecondary};
  }

  &.success {
    --badge-color: ${theme.palette.green};
  }

  &.warning {
    --badge-color: ${theme.palette.yellow};
    --badge-text-color: ${theme.palette.blueMid};
  }
  &.info {
    --badge-color: ${theme.palette.themeDarkAlt};
    --badge-text-color: ${theme.palette.white};
  }
  &.infoNeutral {
    --badge-color: ${theme.palette.green};
    --badge-text-color: ${theme.palette.black};
  }

  &.danger {
    --badge-color: ${theme.palette.red};
  }

  &.light {
    --badge-color: ${theme.palette.neutralLight};
    --badge-text-color: ${theme.palette.accent};
  }

  &.small {
    --badge-size: 1;
    font-size: 12px;
  }

  &.smallXs {
    --badge-size: 0.45;
    font-size: 11px;
  }

  &.large {
    --badge-size: 2;
    font-size: 16px;
  }

  &.pill {
    border-radius: 500px;
    padding: calc(4px * var(--badge-size)) calc(4px * var(--badge-size) * 2);
  }

  &.outlined {
    --badge-text-color: var(--badge-color);
    border: 1px solid var(--badge-color);
    background-color: transparent;
  }
`;
