import {
  ConstrainMode,
  Dropdown,
  DropdownMenuItemType,
  getTheme,
  Icon,
  IconButton,
  IDropdownOption,
  IIconStyles,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack
} from "@fluentui/react";
import { FormEvent, useEffect, useState } from "react";

import { ProductPlanItem } from "./ProductPlanItem";
import {
  ErrorMessageContainer,
  OfferRules,
  OfferRulesList,
  OfferTitle,
  ProductFilterContainer,
  ProductPlansContainer,
  ProductPlansTitle
} from "./ProductPlans.styles";

import {
  ProductOfferItem,
  ProductStep,
  SaleCondition
} from "@/modules/Offers/types/ProductsOffers.types";

type ProductPlansProps = {
  productOffer: ProductOfferItem;
  selectedItem: SaleCondition;
  setSelectedItem: (arg) => void;
  setProductStep: (step: string) => void;
  setCashFilter: (option: string) => void;
  setCarrierFilter: (option: string) => void;
  currentCashFilter: string;
  currentCarrierFilter: string;
};

const iconStyles: IIconStyles = {
  root: {
    fontSize: "20px",
    fontWeight: "bold",
    height: "20px",
    width: "20px",
    marginLeft: 5,
    marginTop: 4
  }
};

const sortingFunctions = {
  ascending(a, b) {
    return Number(a) - Number(b);
  },
  descending(a, b) {
    return Number(a) > Number(b) ? -1 : 1;
  }
};

const orderFilteredPlans = (
  plans: SaleCondition[],
  selectedOptions: IDropdownOption[]
): SaleCondition[] => {
  const hasDataOrder = selectedOptions.find(item => item.data === "data");
  const hasPlanOrder = selectedOptions.find(item => item.data === "plan");
  const hasDeviceOrder = selectedOptions.find(item => item.data === "device");

  if (hasDataOrder) {
    const [sortingType] = String(hasDataOrder.key).split(/(?=[A-Z])/);

    plans = plans.sort((planA, planB) =>
      sortingFunctions[sortingType](planA.summedData, planB.summedData)
    );
  }

  if (hasPlanOrder) {
    const [sortingType] = String(hasPlanOrder.key).split(/(?=[A-Z])/);

    plans = plans.sort((planA, planB) =>
      sortingFunctions[sortingType](
        planA.offerPrice.price,
        planB.offerPrice.price
      )
    );
  }

  if (hasDeviceOrder) {
    const [sortingType] = String(hasDeviceOrder.key).split(/(?=[A-Z])/);

    plans = plans.sort((planA, planB) =>
      sortingFunctions[sortingType](planA.cashValue, planB.cashValue)
    );
  }

  return plans;
};

export function ProductPlans({
  productOffer,
  selectedItem,
  setSelectedItem,
  setProductStep,
  setCarrierFilter,
  setCashFilter,
  currentCarrierFilter,
  currentCashFilter
}: ProductPlansProps): JSX.Element {
  const [dataFilter, setDataFilter] = useState<number>(undefined);
  const [filteredPlans, setFilteredPlans] =
    useState<SaleCondition[]>(undefined);
  const [selectedOptions, setSelectedOptions] = useState<IDropdownOption[]>([]);
  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        const item = selection.getSelection();

        if (item.length > 0) {
          setSelectedItem(item[0]);
        } else {
          setSelectedItem(undefined);
        }
      }
    })
  );

  const [orderOptions] = useState([
    {
      key: "deviceHeader",
      text: "Aparelho",
      itemType: DropdownMenuItemType.Header
    },
    { key: "descendingDevice", data: "device", text: "Maior Preço" },
    { key: "ascendingDevice", data: "device", text: "Menor Preço" },
    { key: "divider_1", text: "-", itemType: DropdownMenuItemType.Divider },
    {
      key: "planHeader",
      text: "Plano de benefícios",
      itemType: DropdownMenuItemType.Header
    },
    { key: "descendingPlan", data: "plan", text: "Maior Preço" },
    { key: "ascendingPlan", data: "plan", text: "Menor Preço" },
    { key: "divider_2", text: "-", itemType: DropdownMenuItemType.Divider },
    { key: "dataHeader", text: "Dados", itemType: DropdownMenuItemType.Header },
    { key: "descendingData", data: "data", text: "Maior Pacote" },
    { key: "ascendingData", data: "data", text: "Menor Pacote" }
  ]);
  const theme = getTheme();

  useEffect(() => {
    setSelectedItem(undefined);
    selection.setAllSelected(false);

    let filteredSaleConditions = productOffer.saleConditions;

    if (currentCashFilter) {
      filteredSaleConditions = filteredSaleConditions.filter(
        condition => Number(condition.cashValue) === Number(currentCashFilter)
      );
    }

    if (currentCarrierFilter) {
      filteredSaleConditions = filteredSaleConditions.filter(
        condition => String(condition.carrier) === String(currentCarrierFilter)
      );
    }

    if (dataFilter) {
      filteredSaleConditions = filteredSaleConditions.filter(
        condition => condition.summedData === dataFilter
      );
    }

    if (selectedOptions.length > 0) {
      filteredSaleConditions = orderFilteredPlans(
        filteredPlans,
        selectedOptions
      );
    }

    setFilteredPlans([...filteredSaleConditions]);
    //eslint-disable-next-line
  }, [currentCarrierFilter, currentCashFilter, dataFilter, selectedOptions]);

  const columns = [
    {
      key: "plans",
      name: "plan",
      fieldName: "plan",
      minWidth: 500,
      isMultiline: true,
      onRender: (item: SaleCondition) => {
        return (
          <ProductPlanItem item={item} theme={theme} renderAdditionals={true} />
        );
      }
    }
  ];

  const handleOrderChange = (
    event: FormEvent<HTMLDivElement>,
    option: IDropdownOption
  ): void => {
    if (option.selected) {
      setSelectedOptions([
        ...selectedOptions.filter(item => item.data !== option.data),
        option
      ]);
    } else {
      setSelectedOptions(
        selectedOptions.filter(item => item.key !== option.key)
      );
    }
  };

  return (
    <ProductPlansContainer>
      <IconButton
        styles={{
          root: {
            color: theme.palette.themeDarkAlt,
            zIndex: 1,
            position: "absolute",
            top: 10,
            left: 10
          },
          rootHovered: {
            color: theme.palette.neutralDark
          }
        }}
        iconProps={{ iconName: "ChevronLeftMed" }}
        ariaLabel="Go back to previous step"
        onClick={() => setProductStep(ProductStep.FILTER)}
      />

      <ProductPlansTitle className="ms-fontSize-28 ms-fontWeight-semibold">
        Disponível nos benefícios:
      </ProductPlansTitle>

      <ProductFilterContainer>
        <Dropdown
          label="Valor do aparelho"
          onChange={(_, option) => {
            if (option.key === currentCashFilter) {
              setCashFilter(undefined);
            } else {
              setCashFilter(String(option?.key));
            }
          }}
          placeholder="Selecione uma opção"
          options={productOffer?.allCashValue?.map(product => ({
            key: product,
            text: toLocaleString(product)
          }))}
          selectedKey={currentCashFilter || ""}
          styles={{
            dropdown: { width: 170 }
          }}
        />

        <Dropdown
          label="Operadora"
          onChange={(_, option) => {
            if (option.key === currentCarrierFilter) {
              setCarrierFilter(undefined);
            } else {
              setCarrierFilter(String(option?.key));
            }
          }}
          placeholder="Selecione uma opção"
          options={productOffer?.allCarriers?.map(product => ({
            key: product,
            text: product
          }))}
          selectedKey={currentCarrierFilter || ""}
          styles={{
            root: { marginLeft: 10 },
            dropdown: { width: 170 }
          }}
        />

        <Dropdown
          label="Quantidade de dados"
          onChange={(_, option) => {
            if (option.key === dataFilter) {
              setDataFilter(undefined);
            } else {
              setDataFilter(Number(option.key));
            }
          }}
          placeholder="Selecione uma opção"
          options={productOffer?.allData?.map(data => ({
            key: data,
            text: String(data) + " GB"
          }))}
          selectedKey={dataFilter || ""}
          styles={{
            root: { marginLeft: 10 },
            dropdown: { width: 170 }
          }}
        />

        <Stack style={{ width: "100%" }} horizontalAlign="end">
          <Dropdown
            label="Ordernar por"
            placeholder="Selecione uma opção"
            selectedKeys={selectedOptions.map(keys => keys.key as string)}
            onChange={handleOrderChange}
            options={orderOptions}
            multiSelect
            styles={{
              root: { marginLeft: 10 },
              dropdown: { width: 170 }
            }}
          />
        </Stack>
      </ProductFilterContainer>

      <Stack
        tokens={{ padding: 20 }}
        styles={{
          root: { width: "100%", height: "100%", overflowY: "auto" }
        }}
        data-is-scrollable="true"
      >
        {(filteredPlans === undefined || filteredPlans?.length > 0) && (
          <ShimmeredDetailsList
            selectionMode={SelectionMode.single}
            constrainMode={ConstrainMode.horizontalConstrained}
            items={filteredPlans || []}
            columns={columns}
            setKey="Plans"
            selection={selection}
            isHeaderVisible={false}
            selectionPreservedOnEmptyClick
            detailsListStyles={{
              root: {
                selectors: {
                  "& .ms-DetailsRow-cell": {
                    alignItems: "center",
                    display: "flex"
                  },
                  "& .ms-DetailsRow-cellCheck": {
                    alignItems: "center",
                    display: "flex"
                  },
                  "& .ms-DetailsRow": {
                    borderTop: `5px solid ${theme.palette.neutralLight}`,
                    borderBottom: "none",
                    cursor: "pointer",
                    backgroundColor: "transparent"
                  },
                  "& .ms-DetailsRow:hover": {
                    backgroundColor: `${theme.palette.neutralLight} !important`
                  },
                  "& .ms-DetailsRow.is-selected": {
                    backgroundColor: theme.palette.neutralLight
                  }
                }
              }
            }}
          />
        )}

        {filteredPlans !== undefined && filteredPlans.length < 1 && (
          <ErrorMessageContainer>
            <h1 className="ms-fontSize-24 ms-fontWeight-semibold ms-motion-fadeIn">
              Nenhum benefício encontrado de acordo com os filtros selecionados
            </h1>
          </ErrorMessageContainer>
        )}
      </Stack>

      {selectedItem && (
        <Stack
          horizontal
          tokens={{ padding: 10, childrenGap: 10 }}
          styles={{
            root: { width: "100%", position: "relative", bottom: "0" }
          }}
        >
          <Stack.Item grow>
            <OfferRules>
              <OfferTitle className="ms-fontSize-24 ms-fontWeight-semibold">
                Regulamentos do benefício:
              </OfferTitle>

              <OfferRulesList>
                <li className="ms-fontWeight-semibold">
                  {selectedItem?.offerPrice?.offer?.plan?.data} GB de Internet
                  móvel
                  {selectedItem?.offerPrice?.offer?.bonusData?.value &&
                    ` + bônus ${selectedItem.offerPrice.offer.bonusData.value} GB`}
                  {selectedItem?.offerPrice?.offer?.bonusData?.numberOfMonths &&
                    ` por ${selectedItem.offerPrice.offer.bonusData.numberOfMonths} meses`}
                </li>

                {selectedItem?.offerPrice?.offer?.plan?.includedServices
                  ?.length > 0 && (
                  <li className="ms-fontWeight-semibold">
                    {selectedItem.offerPrice.offer.plan.includedServices.join(
                      ", "
                    )}
                  </li>
                )}

                <li className="ms-fontWeight-semibold">
                  {selectedItem?.offerPrice?.price &&
                    `Benefício no valor de ${toLocaleString(
                      selectedItem.offerPrice.price
                    )}`}

                  {selectedItem?.offerPrice?.offer?.discount?.value &&
                    ` com desconto de ${toLocaleString(
                      selectedItem.offerPrice.offer.discount.value
                    )}`}

                  {selectedItem?.offerPrice?.offer?.discount?.value &&
                    ` por ${selectedItem?.offerPrice?.offer?.discount?.numberOfMonths} meses`}

                  {selectedItem?.offerPrice?.offer?.discount?.value &&
                    ` a partir da ${selectedItem?.offerPrice?.offer?.discount?.beginsAt}.ª fatura`}
                </li>
              </OfferRulesList>
            </OfferRules>
          </Stack.Item>

          <Stack.Item align="end">
            <PrimaryButton
              style={{ padding: 25 }}
              type="button"
              onClick={() => setProductStep(ProductStep.CONTRACT)}
            >
              <span className="ms-fontSize-18">Continuar</span>
              <Icon iconName="ChevronRightMed" styles={iconStyles} />
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      )}
    </ProductPlansContainer>
  );
}

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}
