import { Helmet } from "react-helmet";

function NotFound(): JSX.Element {
  return (
    <>
      <Helmet>
        <title>Página não encontrada - Libertas</title>
      </Helmet>
      <div style={{ textAlign: "center" }}>Página não encontrada</div>
    </>
  );
}

export default NotFound;
