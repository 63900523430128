import { ITooltipProps, TooltipDelay, TooltipHost } from "@fluentui/react";

import { OffersToolTipContent } from "./OffersToolTipContent";
import { IOffersToolTip } from "./types";

export const OffersToolTip = ({ plan, position, children }: IOffersToolTip) => {
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => <OffersToolTipContent plan={plan} />
  };

  return (
    <TooltipHost
      tooltipProps={tooltipProps}
      delay={TooltipDelay.zero}
      directionalHint={position}
    >
      {children}
    </TooltipHost>
  );
};
