import { Link as FabricLink } from "@fluentui/react/lib/Link";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { IMessageBar, MessageBar } from "@/core/libs/message-bar";

interface Step3Props {
  message: IMessageBar | undefined;
}

function Step3(props: Step3Props): JSX.Element {
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(4);

  useEffect(() => {
    if (!timeLeft) {
      history.push("/login");
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
    //eslint-disable-next-line
  }, [timeLeft]);

  return (
    <div>
      <MessageBar message={props.message} dismissMessage={undefined} />
      <p style={{ marginTop: 20 }}>
        Redirecionado para a página de{" "}
        <FabricLink as={Link} to="/login">
          Login
        </FabricLink>{" "}
        em {timeLeft}s.
      </p>
    </div>
  );
}

export default Step3;
