import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createLinesFailure,
  createLinesSuccess,
  editLinesFailure,
  editLinesSuccess,
  listLinesDashboardFailure,
  listLinesDashboardRequest,
  listLinesDashboardSuccess,
  listLinesFailure,
  listLinesRequest,
  listLinesSuccess
} from "../actions/lines";
import { LinesActionsTypes } from "../types/Lines.reducer.types";

import { SagaCustomError } from "@/common/types";
import { LinesService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createLines({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = LinesService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createLinesSuccess({
        message: `Linha criada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listLinesRequest(undefined, { isAdmin }));
    yield put(listLinesDashboardRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      createLinesFailure({
        code: error?.statusCode,
        message: "Não foi possível criar a linha com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editLines({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = LinesService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...values } },
      signal
    });
    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;
      error.message = response.error.message;

      throw error;
    }

    yield put(
      editLinesSuccess({
        message: `Linha editada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listLinesRequest(undefined, { isAdmin }));
    yield put(listLinesDashboardRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      editLinesFailure({
        code: error?.statusCode,
        message:
          error.message ??
          "Não foi possível editar a linha com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listLines({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = LinesService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };
    let response;

    if (filter) {
      params.query = { ...params.query, ...filter };

      response = yield call(service.listWithPostMethod, {
        params: {
          body: {
            ...params.query
          }
        },
        signal
      });
    } else {
      response = yield call(service.list, { params, signal });
    }

    if (response.error) {
      throw new Error();
    }

    yield put(listLinesSuccess({ list: response.data }));
  } catch {
    yield put(listLinesFailure());
  }
}

export function* listLinesDashboard({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = LinesService("/dashboards", isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    const totalAreaCodes = new Set([]);

    response.data.forEach(carrier => {
      carrier.plans.forEach(plan => {
        plan.areaCodes.forEach(areaCode => totalAreaCodes.add(areaCode.code));
      });
    });

    const allLinesDashboardItems = response.data.reduce(
      (allItems, currentItem) => {
        currentItem.plans.forEach(plan => {
          allItems.push({
            ...plan,
            carrier: currentItem?.name,
            service: plan.service?.name,
            plan: plan?.name
          });
        });

        return allItems;
      },
      []
    );

    const linesDashboardList = {
      items: allLinesDashboardItems,
      meta: { totalItems: allLinesDashboardItems.length, totalPages: 1 },
      totalAreaCodes: [...totalAreaCodes]
    };

    if (response.error) {
      throw new Error();
    }

    yield put(listLinesDashboardSuccess({ list: linesDashboardList }));
  } catch (error) {
    yield put(listLinesDashboardFailure());
  }
}

export default all([
  takeLatest(LinesActionsTypes.CREATE_REQUEST, createLines),
  takeLatest(LinesActionsTypes.EDIT_REQUEST, editLines),
  takeLatest(LinesActionsTypes.LIST_REQUEST, listLines),
  takeLatest(LinesActionsTypes.LIST_DASHBOARD_REQUEST, listLinesDashboard)
]);
