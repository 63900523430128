import Accounts from "./lib/accounts";
import Auth from "./lib/auth";
import BusinessUnits from "./lib/businessUnits";
import Carriers from "./lib/carriers";
import Contracts from "./lib/contracts";
import ContractsSigners from "./lib/contracts-signers";
import ContractsUploads from "./lib/contractsUpload";
import Dashboards from "./lib/dashboards";
import ElectronicSignatures from "./lib/electronicSignatures";
import EmailTemplate from "./lib/emailTemplate";
import EmailTemplateGroup from "./lib/emailTemplateGroup";
import EmailTrigger from "./lib/emailTemplateTrigger";
import FreshChat from "./lib/freshChat";
import Inventories from "./lib/inventories";
import InventoriesOccurrences from "./lib/inventoriesOccurrences";
import Lines from "./lib/lines";
import LinesOccurrences from "./lib/linesOccurrences";
import MaritalStatus from "./lib/maritalStatus";
import Models from "./lib/models";
import ModelsAttachment from "./lib/modelsAttachment";
import ModelsGroups from "./lib/modelsGroups";
import Offers from "./lib/offers";
import Operations from "./lib/operations";
import Organizations from "./lib/organizations";
import Permissions from "./lib/permissions";
import Plans from "./lib/plans";
import PlansOffers from "./lib/plansOffers";
import PricingGroups from "./lib/pricingGroups";
import Products from "./lib/products";
import ProductsBrands from "./lib/productsBrands";
import ProductsOffers from "./lib/productsOffers";
import ReportCategories from "./lib/reportCategories";
import Reports from "./lib/reports";
import Roles from "./lib/roles";
import SalesTeams from "./lib/salesTeams";
import ServiceProviders from "./lib/serviceProviders";
import Services from "./lib/services";
import Shippings from "./lib/shippings";
import ShippingsGroups from "./lib/shippingsGroups";
import ShippingsGroupsAddresses from "./lib/shippingsGroupsAddresses";
import ShippingsTypes from "./lib/shippingsType";
import Tags from "./lib/tags";
import Users from "./lib/users";

export * from "./lib/authApi";

function EmailTemplateGroupService(subPaths = "", isAdmin = false) {
  return new EmailTemplateGroup(subPaths, isAdmin);
}

function EmailTemplateService(subPaths = "", isAdmin = false) {
  return new EmailTemplate(subPaths, isAdmin);
}

function EmailTriggerService(subPaths = "", isAdmin = false) {
  return new EmailTrigger(subPaths, isAdmin);
}

function AccountsService(subPaths = "") {
  return new Accounts(subPaths);
}
function MaritalStatusService(subPaths = "", isAdmin = false) {
  return new MaritalStatus(subPaths, isAdmin);
}

function AuthService(subPaths = "", isAdmin = false) {
  return new Auth(subPaths, isAdmin);
}

function BusinessUnitsService(subPaths = "", isAdmin = false) {
  return new BusinessUnits(subPaths, isAdmin);
}

function CarriersService(subPaths = "", isAdmin = false) {
  return new Carriers(subPaths, isAdmin);
}

function ContractsService(subPaths = "", isAdmin = false) {
  return new Contracts(subPaths, isAdmin);
}
function ProductsBrandsService(subPaths = "", isAdmin = false) {
  return new ProductsBrands(subPaths, isAdmin);
}

function ContractsUploadService(subPaths = "", isAdmin = false) {
  return new ContractsUploads(subPaths, isAdmin);
}

function LinesService(subPaths = "", isAdmin = false) {
  return new Lines(subPaths, isAdmin);
}

function LinesOccurrencesService(subPaths = "", isAdmin = false) {
  return new LinesOccurrences(subPaths, isAdmin);
}

function OrganizationsService(subPaths = "", isAdmin = false) {
  return new Organizations(subPaths, isAdmin);
}

function SalesTeamsService(subPaths = "", isAdmin = false) {
  return new SalesTeams(subPaths, isAdmin);
}

function ServiceProvidersService(subPaths = "", isAdmin = false) {
  return new ServiceProviders(subPaths, isAdmin);
}

function PlansService(subPaths = "", isAdmin = false) {
  return new Plans(subPaths, isAdmin);
}

function ServicesService(subPaths = "", isAdmin = false) {
  return new Services(subPaths, isAdmin);
}

function UsersService(subPaths = "", isAdmin = false) {
  return new Users(subPaths, isAdmin);
}

function ShippingsService(subPaths = "", isAdmin = false) {
  return new Shippings(subPaths, isAdmin);
}

function ShippingsTypeService(subPaths = "", isAdmin = false) {
  return new ShippingsTypes(subPaths, isAdmin);
}

function ShippingsGroupsService(subPaths = "", isAdmin = false) {
  return new ShippingsGroups(subPaths, isAdmin);
}

function ShippingsGroupsAddressesService(id) {
  return new ShippingsGroupsAddresses(id);
}

function ModelsService(subPaths = "", isAdmin = false) {
  return new Models(subPaths, isAdmin);
}

function ModelsGroupsService(subPaths = "", isAdmin = false) {
  return new ModelsGroups(subPaths, isAdmin);
}

function ModelsAttachmentService(subPaths = "", isAdmin = false) {
  return new ModelsAttachment(subPaths, isAdmin);
}

function RolesService(subPaths = "", isAdmin = false) {
  return new Roles(subPaths, isAdmin);
}

function InventoriesService(subPaths = "", isAdmin = false) {
  return new Inventories(subPaths, isAdmin);
}

function InventoriesOccurrencesService(subPaths = "", isAdmin = false) {
  return new InventoriesOccurrences(subPaths, isAdmin);
}

function ProductsService(subPaths = "", isAdmin = false) {
  return new Products(subPaths, isAdmin);
}

function ProductsOffersService() {
  return new ProductsOffers();
}

function OffersService(subPaths = "", isAdmin = false) {
  return new Offers(subPaths, isAdmin);
}

function PlansOffersService() {
  return new PlansOffers();
}

function PricingGroupsService(subPaths = "", isAdmin = false) {
  return new PricingGroups(subPaths, isAdmin);
}

function DashboardsService(subPaths = "") {
  return new Dashboards(subPaths);
}

function OperationsService(subPaths = "", isAdmin = false) {
  return new Operations(subPaths, isAdmin);
}

function ReportCategoriesService(subPaths = "", isAdmin = false) {
  return new ReportCategories(subPaths, isAdmin);
}

function ReportsService(subPaths = "", isAdmin = false) {
  return new Reports(subPaths, isAdmin);
}

function PermissionsService(subPaths = "", isAdmin = false) {
  return new Permissions(subPaths, isAdmin);
}

function TagsService(subPaths = "") {
  return new Tags(subPaths);
}

function FreshChatService(subPaths = "", isAdmin = false) {
  return new FreshChat(subPaths, isAdmin);
}

function ElectronicSignaturesService(subPaths = "", isAdmin = false) {
  return new ElectronicSignatures(subPaths, isAdmin);
}

function ContractsSignersService(subPaths = "", isAdmin = true) {
  return new ContractsSigners(subPaths, isAdmin);
}

export {
  AccountsService,
  AuthService,
  BusinessUnitsService,
  CarriersService,
  ContractsService,
  DashboardsService,
  InventoriesOccurrencesService,
  InventoriesService,
  LinesOccurrencesService,
  LinesService,
  ModelsAttachmentService,
  ModelsGroupsService,
  ModelsService,
  OffersService,
  OperationsService,
  OrganizationsService,
  SalesTeamsService,
  ServiceProvidersService,
  PermissionsService,
  PlansOffersService,
  PlansService,
  PricingGroupsService,
  ProductsOffersService,
  ProductsService,
  ProductsBrandsService,
  ReportCategoriesService,
  ReportsService,
  RolesService,
  ServicesService,
  ShippingsGroupsAddressesService,
  ShippingsGroupsService,
  ShippingsService,
  ShippingsTypeService,
  TagsService,
  UsersService,
  ContractsUploadService,
  FreshChatService,
  ElectronicSignaturesService,
  EmailTemplateGroupService,
  EmailTriggerService,
  EmailTemplateService,
  MaritalStatusService,
  ContractsSignersService
};
