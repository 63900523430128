import styled from "styled-components";

import backgroundLibertas from "@/assets/images/login-background.png";

export const AuthArea = styled.div`
  background: #f2f6fc;
  box-sizing: border-box;
  min-width: 400px;
  max-width: 400px;
  padding: 3rem;
  position: relative;
  width: 100%;
  border-radius: 5px;
  border: 3px solid #a19f9d;
`;

export const AuthWrapper = styled.div`
  align-items: center;
  background-image: url(${backgroundLibertas});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  // background-color: #20345b;
`;
