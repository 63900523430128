import { useBoolean } from "@fluentui/react-hooks";
import { PrimaryButton, Spinner, Text } from "@fluentui/react/lib/index";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";

import { ConfirmDialog } from "./ConfirmDialog";
import { IGeneratedOptOut, IOptOut, IOptOutCardProps } from "./interfaces";
import { OptOutChannelPivot } from "./OptOutChannelPivot";
import { ShimmerSection } from "./ShimmerSection";
import {
  filterOptOutByChannel,
  flattenValues,
  formatBUList,
  markRecursive,
  removeOptOuts,
  selectCheckedBu
} from "./utils";
import { selectedNodesToTree } from "./utils/SelectedNodesToTree";

import {
  Container,
  SelectContainer,
  SideBar,
  Wrapper
} from "@/components/styles/OptOut";
import { useGetProfile } from "@/core/libs/api/react-query";
import { useApi } from "@/core/libs/api/react-query/useApi";
import {
  TreeDataValue,
  TreeSelectDropdownComponent
} from "@/core/libs/tree-select-dropdown/TreeSelectDropdown";

export const OptOutCard = ({
  title,
  notificationEventId
}: IOptOutCardProps) => {
  const { execute } = useApi();
  const userGetProfile = useGetProfile();
  const userBusinessUnits = userGetProfile.data?.data?.businessUnits;

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [notificationChannelId, setNotificationChannelId] = useState<number>();
  const [optOutList, setOptOutList] = useState<IOptOut[]>();
  const [businessUnitList, setBusinessUnitList] = useState<TreeDataValue[]>(
    formatBUList(userBusinessUnits)
  );
  const [selectedBusinessUnitNames, setSelectedBusinessUnitsNames] =
    useState<string[]>();

  const selectedNodes = useRef<TreeDataValue[]>([]);

  const updateSelectedNodes = useCallback(businessUnits => {
    selectedNodes.current = businessUnits;
  }, []);

  const getOptOut = useQuery({
    queryKey: [`OPT-OUT-CHANNELS-EVENT-${notificationEventId}`],
    queryFn: () => {
      return execute({
        url: "user-notification-event-opt-out",
        notificationMessage: `Buscando a lista de bloqueio notificações de ${title}`,
        params: { notificationEventId }
      });
    },
    staleTime: 120000
  });
  const isFetchingOptOut = getOptOut.isFetched;

  const addCheckToBuList = useCallback(
    (optOut: IOptOut[], channelId?: number): void => {
      const optOutByChannel = filterOptOutByChannel(optOut, channelId);
      const businessUnitIds = optOutByChannel.map(
        optOut => optOut.businessUnitId
      );

      setBusinessUnitList(prevState => {
        return markRecursive(prevState, businessUnitIds);
      });
    },
    []
  );

  useEffect(() => {
    const data = getOptOut?.data;

    if (data?.data) {
      setOptOutList(data.data);
      addCheckToBuList(data.data, notificationChannelId);
    }
  }, [getOptOut?.data, notificationChannelId, setOptOutList, addCheckToBuList]);

  const generateUpdatedOptOutList = useCallback((): Array<IGeneratedOptOut> => {
    const nodesTree = selectedNodesToTree(
      selectedNodes.current,
      userBusinessUnits
    );
    const selectedValues = selectCheckedBu(nodesTree);
    const selectedValuesId = selectedValues.flatMap(flattenValues);

    const optOutBuIdList = filterOptOutByChannel(
      optOutList,
      notificationChannelId
    ).map(opt => opt.businessUnitId);

    const deselectedBuIdList = optOutBuIdList.filter(
      value => !selectedValuesId.includes(value)
    );

    const create = selectedValuesId.map(businessUnitId => {
      return {
        businessUnitId,
        notificationEventId,
        notificationChannelId
      };
    });

    const remove = removeOptOuts(
      deselectedBuIdList,
      optOutList,
      notificationChannelId,
      notificationEventId
    );

    return [...create, ...remove];
  }, [optOutList, notificationChannelId, notificationEventId]);

  const handleChangeOptOut = useCallback(async (): Promise<void> => {
    setIsSubmitting(true);
    toggleHideDialog();
    const info = generateUpdatedOptOutList();

    await execute({
      url: "user-notification-event-opt-out",
      method: "PATCH",
      bodyObj: Object(info),
      showAlert: true,
      messages: {
        success: `Bloqueio de notificação atualizado!`,
        error: `Erro na atualização de bloqueio de notificação, contate o suporte.`
      }
    });

    await getOptOut.refetch();
    setIsSubmitting(false);
  }, [execute, generateUpdatedOptOutList, toggleHideDialog, getOptOut]);

  const openDialog = () => {
    const selectedBusinessUnits = selectCheckedBu(selectedNodes.current);
    const businessUnitsNames = selectedBusinessUnits.map(
      businessUnit => businessUnit.label
    );
    setSelectedBusinessUnitsNames(businessUnitsNames);
    toggleHideDialog();
  };

  const changePivotItem = useCallback(
    (channel: { id: number; name: string }) => {
      setNotificationChannelId(channel.id);
    },
    []
  );

  if (!isFetchingOptOut) {
    return <ShimmerSection />;
  }

  return (
    <Wrapper>
      <ConfirmDialog
        title="Atualizar bloqueio de notificações"
        executeOnClick={handleChangeOptOut}
        toggleHideDialog={openDialog}
        hideDialog={hideDialog}
        subText={`Você deseja atualizar bloqueios as notificações de ${title} nas unidades de negócio abaixo?`}
        contentList={selectedBusinessUnitNames}
      />

      <SideBar>
        <Text variant="large" styles={{ root: { fontWeight: "bold" } }}>
          {title}
        </Text>
      </SideBar>

      <Container>
        <OptOutChannelPivot
          notificationEventId={notificationEventId}
          onSelectPivotItem={changePivotItem}
        />

        <SelectContainer>
          {businessUnitList && (
            <TreeSelectDropdownComponent
              placeholder="Unidade de negócio"
              options={businessUnitList}
              label="Unidades de negócio"
              onChangeSelectedItems={updateSelectedNodes}
            />
          )}

          <PrimaryButton onClick={openDialog} disabled={isSubmitting}>
            {isSubmitting ? (
              <Spinner
                style={{ marginRight: "0.5rem" }}
                label="Salvando"
                labelPosition="right"
              />
            ) : (
              "Salvar"
            )}
          </PrimaryButton>
        </SelectContainer>
      </Container>
    </Wrapper>
  );
};
