import { Stack } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { PlanContractQuestions } from "./PlanContractQuestion";
import { PlanOffer } from "./PlanOffer";

import { ContractsService, OperationsService } from "@/core/libs/api";
import CarriersApi from "@/core/libs/api/lib/carriers";
import { IMessageBar } from "@/core/libs/message-bar";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { Plan, PlanOfferItem } from "@/modules/Offers/types/PlansOffers.types";
import {
  OperationTypes,
  ProductContractOperation,
  ProductContractType
} from "@/modules/Offers/types/ProductsOffers.types";

type PlanOfferContractProps = {
  selectedPlan: Plan;
  selectedCarrier: PlanOfferItem;
};

const abortController = new AbortController();
const { signal } = abortController;

const contractService = ContractsService();
const operationService = OperationsService();
const carriers = new CarriersApi("", false);

export function PlanOfferContract({
  selectedPlan,
  selectedCarrier
}: PlanOfferContractProps): JSX.Element {
  const [contractType, setContractType] = useState<string>(undefined);
  const [contractOperations, setContractOperations] = useState(undefined);
  const [contractLines, setContractLines] = useState(undefined);
  const [contractAreaCodes, setContractAreaCodes] = useState(undefined);
  const [operation, setOperation] = useState<OperationTypes>(undefined);
  const [selectedContract, setSelectedContract] = useState(undefined);
  const [selectedSignatureType, setSelectedSignatureType] =
    useState<string>(undefined);
  const [selectedPhoneNumber, setSelectedPhoneNumber] =
    useState<string>(undefined);
  const [selectedPhoneId, setSelectedPhoneId] = useState<number>(undefined);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(true);
  const [linesError, setLinesError] = useState<string>();
  const [submitMessage, setSubmitMessage] = useState<IMessageBar>(null);
  const [carriersList, setCarriersList] = useState();
  const [initialCarrier, setInitialCarrier] = useState(undefined);

  const { selectedBU } = useSelectedBu();

  const [selectedPhoneAreaCode, setSelectedPhoneAreaCode] = useState<string>(
    selectedBU?.defaultAreaCode
      ? String(selectedBU?.defaultAreaCode)
      : undefined
  );

  const requestOperations = useCallback(async () => {
    const params = {
      query: {
        service: selectedPlan.service.id,
        businessActionId: selectedBU?.id,
        offer: selectedPlan.offer.id,
        limit: 100
      }
    };

    const response = await operationService.list({ params, signal });

    if (response?.data?.length > 0) {
      const formatToSelect = response.data.map(operation => ({
        key: operation.id,
        text: operation.name,
        data: operation,
        id: `option-${operation.id}`
      }));

      setContractOperations(formatToSelect);
    }
  }, [selectedBU]);

  const requestLines = useCallback(async () => {
    if (contractLines !== undefined) {
      setContractLines(undefined);
    }

    const params = {
      query: {
        plan: selectedPlan?.id,
        ddd: selectedPhoneAreaCode,
        businessActionId: selectedBU?.id
      }
    };

    const response = await contractService.listLines({ params, signal });

    if (response?.data?.lines?.length > 0) {
      const formatToSelect = response.data.lines.map(line => ({
        key: line.id,
        text: `${line.number.slice(2, -4)}-${line.number.slice(-4)}`,
        id: `option-${line.id}`
      }));

      setContractLines(formatToSelect);
    }

    if (response?.data?.availableAreaCodes?.length > 0) {
      const formatToSelect = response.data.availableAreaCodes.map(areaCode => ({
        key: areaCode.code,
        text: areaCode.code,
        id: `option-${areaCode.code}`
      }));

      setContractAreaCodes(formatToSelect);
    }

    if (response?.error?.message) {
      setLinesError(response.error.message);
    }

    //eslint-disable-next-line
  }, [selectedPhoneAreaCode, selectedBU]);

  useEffect(() => {
    if (operation) {
      if (operation.type !== "RENEWAL" && operation.type !== "BRING_YOUR_OWN") {
        setIsLoadingOptions(true);
        requestLines().then(() => setIsLoadingOptions(false));
      } else {
        getCarriers();
        setContractLines(undefined);
        setContractAreaCodes(undefined);
      }
    }
  }, [selectedPhoneAreaCode, operation, requestLines]);

  useEffect(() => {
    requestOperations().then(() => setIsLoadingOptions(false));
  }, [requestOperations]);

  useEffect(() => {
    const contractTypeExists = contractType !== undefined;
    const operationTypeExists = operation !== undefined;
    const operationTypeIsPortability =
      String(operation?.id) === ProductContractOperation.PORTABILIDADE;
    const selectedPhoneIdExists = selectedPhoneId !== undefined;
    const selectedPhoneNumberExists = selectedPhoneNumber !== undefined;
    const selectedPhoneNumberIsValid = selectedPhoneNumber?.length === 11;
    const selectedContractExists = selectedContract !== undefined;

    const hasSomePhoneNumber =
      selectedPhoneIdExists || selectedPhoneNumberExists;

    switch (contractType) {
      case ProductContractType.NEW: {
        if (contractTypeExists && operationTypeExists && hasSomePhoneNumber) {
          if (operationTypeIsPortability && selectedPhoneNumberIsValid) {
            setIsSubmitButtonDisabled(false);
          } else if (!operationTypeIsPortability) {
            setIsSubmitButtonDisabled(false);
          } else {
            if (!isSubmitButtonDisabled) {
              setIsSubmitButtonDisabled(true);
            }
          }
        } else {
          if (!isSubmitButtonDisabled) {
            setIsSubmitButtonDisabled(true);
          }
        }

        break;
      }

      case ProductContractType.EXISTENT: {
        if (
          contractTypeExists &&
          operationTypeExists &&
          hasSomePhoneNumber &&
          selectedContractExists
        ) {
          if (operationTypeIsPortability && selectedPhoneNumberIsValid) {
            setIsSubmitButtonDisabled(false);
          } else if (!operationTypeIsPortability) {
            setIsSubmitButtonDisabled(false);
          } else {
            if (!isSubmitButtonDisabled) {
              setIsSubmitButtonDisabled(true);
            }
          }
        } else {
          if (!isSubmitButtonDisabled) {
            setIsSubmitButtonDisabled(true);
          }
        }

        break;
      }

      default:
        break;
    }
  }, [
    contractType,
    isSubmitButtonDisabled,
    operation,
    selectedPhoneId,
    selectedPhoneNumber,
    selectedContract,
    selectedSignatureType
  ]);

  const handleSelectedContractType = (type: string) => {
    if (selectedSignatureType) {
      setSelectedSignatureType(undefined);
    }

    setContractType(type);
  };

  const getCarriers = async () => {
    const carriersList = await carriers.listCarriers();

    setCarriersList(
      carriersList
        .filter(carrier => carrier.name !== "Taggy")
        .map(carrier => ({
          text: carrier.name,
          key: carrier.id,
          id: `option-${carrier.id}`
        }))
    );
  };

  return (
    <Stack horizontal>
      <PlanOffer
        selectedPlan={selectedPlan}
        selectedCarrier={selectedCarrier}
      />

      <PlanContractQuestions
        contractLines={contractLines}
        availableAreaCodes={contractAreaCodes}
        contractOperations={contractOperations}
        contractType={contractType}
        operation={operation}
        selectedPhoneAreaCode={selectedPhoneAreaCode}
        selectedPhoneNumber={selectedPhoneNumber}
        selectedPhoneId={selectedPhoneId}
        setSelectedPhoneId={setSelectedPhoneId}
        setContractType={handleSelectedContractType}
        setSelectedSignatureType={setSelectedSignatureType}
        selectedSignatureType={selectedSignatureType}
        setOperationType={setOperation}
        setSelectedContract={setSelectedContract}
        selectedContract={selectedContract}
        setSelectedPhoneAreaCode={setSelectedPhoneAreaCode}
        setSelectedPhoneNumber={setSelectedPhoneNumber}
        isLoadingOptions={isLoadingOptions}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        linesError={linesError}
        clearSubmitMessage={() => setSubmitMessage(null)}
        selectedBU={selectedBU}
        selectedPlan={selectedPlan}
        selectedCarrier={selectedCarrier}
        carriers={carriersList}
        setInitialCarrier={setInitialCarrier}
        initialCarrier={initialCarrier}
      />
    </Stack>
  );
}
