import { CommomListProps } from "@/common/types/ListFromApi.types";
import { IMessageBar } from "@/core/libs/message-bar";

export enum LinesActionsTypes {
  CREATE_REQUEST = "lines/CREATE_REQUEST",
  CREATE_FAIL = "lines/CREATE_FAIL",
  CREATE_SUCCESS = "lines/CREATE_SUCCESS",
  DISMISS_MESSAGE = "lines/DISMISS_MESSAGE",
  EDIT_REQUEST = "lines/EDIT_REQUEST",
  EDIT_FAIL = "lines/EDIT_FAIL",
  EDIT_SUCCESS = "lines/EDIT_SUCCESS",
  LIST_REQUEST = "lines/LIST_REQUEST",
  LIST_FAIL = "lines/LIST_FAIL",
  LIST_SUCCESS = "lines/LIST_SUCCESS",
  LIST_DASHBOARD_REQUEST = "lines/LIST_DASHBOARD_REQUEST",
  LIST_DASHBOARD_FAIL = "lines/LIST_DASHBOARD_FAIL",
  LIST_DASHBOARD_SUCCESS = "lines/LIST_DASHBOARD_SUCCESS"
}

export interface LinesState {
  isLoadingList: boolean;
  isLoadingDashboardList: boolean;
  list?: ListOfLines;
  dashboardList: any;
  message?: IMessageBar;
}
export interface Plan {
  id: number;
  name: string;
}

export interface Carrier {
  id: number;
  name: string;
  logo: string;
}

export interface LineItem {
  id?: number;
  number?: string;
  lineStatus?: string;
  carrierStatus?: string;
  reservedBetween?: any;
  plans?: Plan[];
  carrier?: Carrier;
  contractItems?: any;
  contract?: any;
}

export interface Meta {
  itemsPerPage: number;
  itemCount: number;
  totalItems: number;
  currentPage: number;
  totalPages: number;
}
export interface ListOfLines extends CommomListProps {
  items?: LineItem[];
  meta: Meta;
}

export interface CreateRequest {
  type: LinesActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: LinesActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: LinesActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: LinesActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: LinesActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: LinesActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: LinesActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: LinesActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: LinesActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: LinesActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfLines;
  };
}

export interface ListDashboardRequest {
  type: LinesActionsTypes.LIST_DASHBOARD_REQUEST;
}

export interface ListDashboardError {
  type: LinesActionsTypes.LIST_DASHBOARD_FAIL;
}

export interface ListDashboardSuccess {
  type: LinesActionsTypes.LIST_DASHBOARD_SUCCESS;
  payload: {
    list: any;
  };
}

export type LinesReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess
  | ListDashboardRequest
  | ListDashboardError
  | ListDashboardSuccess;
