import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 1rem;
  margin-left: 1rem;
  padding-bottom: 1rem;
`;
