import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const ImageContainer = styled.div`
  img {
    margin-right: 5px;
    padding: 2px 5px 2px 5px;
    cursor: pointer;
    max-height: 50px;
    border: 1px solid transparent;

    &:hover {
      border-color: ${theme.palette.neutralTertiaryAlt};
    }
  }
`;
