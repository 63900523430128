export class Utils {
  public static copy(aObject) {
    if (!aObject) {
      return aObject;
    }

    let v;
    const bObject = Array.isArray(aObject) ? [] : {};
    for (const k in aObject) {
      v = aObject[k];
      bObject[k] = typeof v === "object" ? this.copy(v) : v;
    }

    return bObject;
  }

  /**
   * Convert new Date() to 22/12/2012
   * @param input
   */
  public static getDate(input) {
    return [input.getDate(), input.getMonth() + 1, input.getFullYear()]
      .map(n => (n < 10 ? `0${n}` : `${n}`))
      .join("/");
  }

  /**
   * Convert fields into initial values
   * @param  {any} fields
   */
  public static getInitialValuesFromFields(fields) {
    const initialValues = {};

    if (fields instanceof Array) {
      fields.forEach(i => {
        if (i instanceof Object) {
          initialValues[i.name] = i.value;
        } else {
          // TODO: grouped or pivot item
        }
      });
    }

    return initialValues;
  }

  /**
   * Convert 22/12/2012 to new Date()
   * @param input
   */
  public static formatDateValue(input) {
    if (!input) return;
    const formatedDate = new Date(
      typeof input === "string" ? input.split("-") : input
    );
    return formatedDate;
  }

  public static setValuesOnFields(fields, values) {
    const fieldsWithValues = this.copy(fields);
    fieldsWithValues.forEach(field => {
      field.value = values[field.name];
    });

    return fieldsWithValues;
  }

  public static ValidateCPF(cpf) {
    cpf = cpf?.replace(/\D/g, "");
    if (cpf?.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    let result = true;
    [9, 10].forEach(function (j) {
      let soma = 0,
        r;
      cpf
        .split(/(?=)/)
        .splice(0, j)
        .forEach(function (e, i) {
          soma += parseInt(e) * (j + 2 - (i + 1));
        });
      r = soma % 11;
      r = r < 2 ? 0 : 11 - r;
      if (r !== Number(cpf.substring(j, j + 1))) result = false;
    });
    return result;
  }

  public static ValidateCNPJ(s) {
    const cnpj = s.replace(/[^\d]+/g, "");

    // Validates the number of characters
    if (cnpj.length !== 14) return false;

    // Eliminates invalid characters with all characters the same
    if (/^(\d)\1+$/.test(cnpj)) return false;

    // Validation calculation
    const t = cnpj.length - 2,
      d = cnpj.substring(t),
      d1 = parseInt(d.charAt(0)),
      d2 = parseInt(d.charAt(1)),
      calc = x => {
        const n = cnpj.substring(0, x);
        let y = x - 7,
          s = 0,
          r = 0;

        for (let i = x; i >= 1; i--) {
          s += n.charAt(x - i) * y--;
          if (y < 2) y = 9;
        }

        r = 11 - (s % 11);
        return r > 9 ? 0 : r;
      };

    return calc(t) === d1 && calc(t + 1) === d2;
  }

  public static toLocaleStringWithoutCurrency(value) {
    return this.convertNumber(value).toLocaleString("pt-BR", {
      minimumFractionDigits: 2
    });
  }

  public static convertNumber(value) {
    return isNaN(value)
      ? Number(value.replace(/\./g, "").replace(/,/g, "."))
      : Number(value);
  }
}
