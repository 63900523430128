import {
  ConstrainMode,
  DetailsList,
  IColumn,
  Icon,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
  Toggle
} from "@fluentui/react";
import { MouseEvent, useEffect, useRef, useState } from "react";

import AccordionContractDetailRow from "../components/AccordionContractDetailRow";
import ChipReplacementDialog from "../components/ItemReplacement/Chip/ChipReplacementDialog";
import TagReplacementDialog from "../components/ItemReplacement/Tag/TagReplacementDialog";

import {
  buildColumns,
  copyAndSortProducts
} from "@/components/Contracts/productsColumns";
import { Card } from "@/components/Shared/Card";
import { listStyles } from "@/core/libs/list-data/lib/styles";
import { ServicesCategoriesTypesEnum } from "@/modules/Settings/types/ServicesCategories.types";
import { BusinessUnit } from "@/modules/User/types";

export interface ProductInfoProps {
  items: any;
  contract: any;
  actions?: any;
  buildMenuItems?: any;
  setSelectedItem?: any;
  displayProductTitle?: any;
  isEditingEnabled?: boolean;
  nextStep?: { id: number; internalName: string; order: number };
  currentStep?: { id: number; internalName: string; order: number };
  approvalFlow?: {
    steps: { id: number; internalName: string; order: number }[];
  };
  refetchContractData?: () => Promise<any>;
  defaultCompactMode?: boolean;
  service: number;
  serviceCategoryType?: ServicesCategoriesTypesEnum;
  businessUnitId: number;
  onOpenRenewalProductsModal: () => void;
  businessUnit: BusinessUnit;
}

export function DisplayProducts(props: ProductInfoProps): JSX.Element {
  const [isItemEditting, setIsItemEditting] = useState<number[]>([]);
  const [isCompactMode, setIsCompactMode] = useState(
    props.defaultCompactMode || false
  );
  const [products, setProducts] = useState(props.items || undefined);
  const [columns, setColumns] = useState([]);
  const refProducts = useRef(products);
  const refColumns = useRef(columns);

  const { items, isEditingEnabled, currentStep, approvalFlow, businessUnit } =
    props;

  const serviceDeliveredStepOrder =
    approvalFlow?.steps?.find(step => step.internalName === "SERVICE_DELIVERED")
      ?.order || 0;

  const updateProducts = updateProducts => {
    refProducts.current = updateProducts;
    setProducts(updateProducts);
  };

  const updateColumns = updateColumns => {
    refColumns.current = updateColumns;
    setColumns(updateColumns);
  };

  const onColumnClick = (
    event: MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    let sortedItems = refProducts.current;
    let isSortedDescending = column.isSortedDescending;

    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Sort the items.
    sortedItems = copyAndSortProducts(
      sortedItems,
      column.fieldName,
      isSortedDescending
    );

    // Reset the items and columns to match the state.
    updateProducts(sortedItems);

    updateColumns(
      refColumns.current.map(col => {
        col.isSorted = col.key === column.key;

        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        } else {
          col.isSortedDescending = false;
        }

        return col;
      })
    );
  };

  const tagReplacementColumn: IColumn = {
    key: "tagReplacement",
    name: "Substituição",
    minWidth: 140,
    onRender: item => {
      return (
        <TagReplacementDialog
          item={item}
          onSuccess={() => props.refetchContractData()}
          businessUnitId={props.businessUnitId}
          service={props.service}
          disabled={
            currentStep.order <= serviceDeliveredStepOrder ||
            ["TIMED_OUT", "REJECTED", "CANCELED"].includes(
              currentStep.internalName
            )
          }
        />
      );
    }
  };

  const chipReplacementColumn: IColumn = {
    key: "chipReplacement",
    name: "Substituição de chip",
    minWidth: 140,

    onRender: item => {
      return (
        <ChipReplacementDialog
          item={item}
          onSuccess={() => props.refetchContractData()}
          businessUnitId={props.businessUnitId}
          service={props.service}
          disabled={
            (item?.currentStep?.order ?? currentStep.order ?? 0) <=
              (item?.approvalFlow?.steps.find(
                step => step.internalName === "SERVICE_DELIVERED"
              )?.order ?? serviceDeliveredStepOrder) ||
            ["TIMED_OUT", "REJECTED", "CANCELED"].includes(
              currentStep.internalName
            )
          }
        />
      );
    }
  };

  useEffect(() => {
    if (columns) {
      const newColumns = buildColumns({
        isCompactMode,
        onColumnClick,
        hasAction: false,
        serviceCategoryType: props.serviceCategoryType
      });

      if (
        props.serviceCategoryType === ServicesCategoriesTypesEnum.TAG &&
        isEditingEnabled
      ) {
        newColumns.push(tagReplacementColumn);
      }
      if (
        props.serviceCategoryType === ServicesCategoriesTypesEnum.MOBILE_LINE &&
        isEditingEnabled &&
        (businessUnit?.settings?.acceptChipReplacement
          ? businessUnit?.settings?.acceptChipReplacement === "true"
          : businessUnit?.parent?.settings?.acceptChipReplacement &&
            businessUnit?.parent?.settings?.acceptChipReplacement == "true")
      ) {
        newColumns.push(chipReplacementColumn);
      }

      setColumns(newColumns);
      refColumns.current = newColumns;
    }
    //eslint-disable-next-line
  }, [isCompactMode, isItemEditting]);

  useEffect(() => {
    const defaultColumns = buildColumns({
      isCompactMode,
      onColumnClick,
      hasAction: false,
      serviceCategoryType: props.serviceCategoryType
    });

    if (
      props.serviceCategoryType === ServicesCategoriesTypesEnum.TAG &&
      isEditingEnabled
    ) {
      defaultColumns.push(tagReplacementColumn);
    }
    if (
      props.serviceCategoryType === ServicesCategoriesTypesEnum.MOBILE_LINE &&
      isEditingEnabled &&
      (businessUnit?.settings?.acceptChipReplacement
        ? businessUnit?.settings?.acceptChipReplacement === "true"
        : businessUnit?.parent?.settings?.acceptChipReplacement &&
          businessUnit?.parent?.settings?.acceptChipReplacement == "true")
    ) {
      defaultColumns.push(chipReplacementColumn);
    }

    setColumns(defaultColumns);
    refColumns.current = defaultColumns;
    //eslint-disable-next-line
  }, []);

  const serviceCategoryIsTag =
    props.serviceCategoryType === ServicesCategoriesTypesEnum.TAG;

  let tagsWithoutCanceled = [];

  if (serviceCategoryIsTag) {
    tagsWithoutCanceled = items.filter(
      item => !item.inventory[0]?.canceledAtStartag
    );
  }

  return (
    <Stack>
      <Stack
        tokens={{ padding: 10 }}
        horizontal
        horizontalAlign="space-between"
      >
        <Text variant={"xLarge"}>
          <Icon iconName="ProductVariant" style={{ marginRight: 10 }} />
          {items?.length === 1 ? "Benefício combo" : "Benefícios combos"}
        </Text>

        {isEditingEnabled && serviceCategoryIsTag && (
          <PrimaryButton
            id="migrate-plans-button"
            disabled={
              tagsWithoutCanceled.length === 0 ||
              currentStep.order < serviceDeliveredStepOrder ||
              ["TIMED_OUT", "REJECTED", "CANCELED"].includes(
                currentStep.internalName
              )
            }
            onClick={() => props.onOpenRenewalProductsModal()}
          >
            Migrar planos de benefícios
          </PrimaryButton>
        )}
      </Stack>
      <Card borderRadius="1rem" padding="1rem">
        <Stack
          tokens={{ padding: 20 }}
          horizontal
          horizontalAlign="space-between"
        >
          <Toggle
            data-testid="toggle-view-mode"
            label="Habilitar modo compacto"
            checked={isCompactMode}
            onChange={(_, checked) => setIsCompactMode(checked)}
            onText="Compacto"
            offText="Normal"
          />
        </Stack>

        <DetailsList
          selectionMode={SelectionMode.none}
          constrainMode={ConstrainMode.horizontalConstrained}
          items={items}
          columns={columns}
          styles={listStyles}
          onRenderRow={rowProps => (
            <AccordionContractDetailRow
              rowProps={rowProps}
              isCompactMode={isCompactMode}
              items={items}
              refetchContractData={props.refetchContractData}
            />
          )}
        />
      </Card>
    </Stack>
  );
}
