import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class Lines extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/lines${subPaths}` : `lines${subPaths}`);
    this.listWithPostMethod = this.listWithPostMethod.bind(this);
  }

  listWithPostMethod({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/search`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  linesBulkUpdate({ params = {}, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/bulk-update`
    };

    if (params.body) options.bodyObj = params.body;

    return makeRequest(options);
  }
}

export default Lines;
