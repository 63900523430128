import {
  DefaultButton,
  Dropdown,
  getTheme,
  IconButton,
  Label,
  MaskedTextField,
  Stack,
  TextField
} from "@fluentui/react";
import { useConstCallback } from "@fluentui/react-hooks";
import { Field, FieldArray } from "formik";
import { useEffect, useState } from "react";

import StripedBackground from "@/components/Shared/StripedBackground";

interface DocumentsFieldsProps {
  documentCfg: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
}

interface DocumentFieldProps {
  currentDocumentName: string;
  currentDocumentIndex: number;
  cfg: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  arrayHelpers: any;
  value?: any;
}

function DocumentField(props: DocumentFieldProps): JSX.Element {
  const theme = getTheme();
  const [mask, setMask] = useState(undefined);

  const useMask = useConstCallback(mask => setMask(mask));
  const removeMask = useConstCallback(() => setMask(undefined));

  const {
    cfg,
    currentDocumentName,
    setFieldValue,
    errors,
    touched,
    currentDocumentIndex,
    arrayHelpers,
    value
  } = props;

  useEffect(() => {
    if (value) {
      cfg.fields.forEach(field => {
        if (field.name === "documentType") {
          const detectedMask = field.options.find(
            x => x.key === value.documentType
          );
          //eslint-disable-next-line
          if (detectedMask?.mask) useMask(detectedMask.mask);
        }
      });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <StripedBackground>
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
        key={`${currentDocumentName}${currentDocumentIndex}`}
      >
        <Stack tokens={{ childrenGap: 5 }} grow>
          <div className="ms-Grid-row" style={{ marginBottom: 15 }}>
            {cfg.fields.map(field => {
              switch (field.type) {
                case "select":
                  // FIXME: improve
                  if (value.documentType) {
                    const fieldMask = field.options.find(
                      x => x.key === value.documentType
                    );
                    //prettier-ignore
                    //eslint-disable-next-line
                    if (mask && mask !== fieldMask.mask) useMask(fieldMask?.mask);
                  }

                  return (
                    <div
                      className={`ms-Grid-col ms-sm12${
                        field.size ? ` ms-lg${field.size}` : ""
                      }`}
                      key={`${field.name}[${currentDocumentIndex}]`}
                    >
                      <Field
                        name={`${currentDocumentName}[${currentDocumentIndex}].${field.name}`}
                        id={`${currentDocumentName}[${currentDocumentIndex}].${field.name}`}
                        key={`${currentDocumentName}[${currentDocumentIndex}].${field.name}`}
                        type={field.type}
                        as={Dropdown}
                        onChange={(evt, item) => {
                          if (item.mask) {
                            //eslint-disable-next-line
                            useMask(item.mask);
                          } else {
                            removeMask();
                          }

                          setFieldValue(
                            `${currentDocumentName}[${currentDocumentIndex}].${field.name}`,
                            item.key
                          );
                        }}
                        placeholder={field.label}
                        selectedKey={value[field.name]}
                        label={field.label}
                        disabled={value.id || field.disabled || false}
                        options={field.options}
                        styles={{ dropdown: { width: "100%" } }}
                        required={field.required || false}
                        errorMessage={
                          errors[currentDocumentName]?.[currentDocumentIndex]?.[
                            field.name
                          ] &&
                          touched[currentDocumentName]?.[
                            currentDocumentIndex
                          ]?.[field.name]
                            ? errors[currentDocumentName]?.[
                                currentDocumentIndex
                              ]?.[field.name]
                            : null
                        }
                      />
                    </div>
                  );

                default:
                  return (
                    <div
                      className={`ms-Grid-col ms-sm12${
                        field.size ? ` ms-lg${field.size}` : ""
                      }`}
                      key={`${field.name}[${currentDocumentIndex}]`}
                    >
                      {mask ? (
                        <Field
                          name={`${currentDocumentName}[${currentDocumentIndex}].${field.name}`}
                          key={`${currentDocumentName}[${currentDocumentIndex}].${field.name}`}
                          type={field.type}
                          as={MaskedTextField}
                          placeholder={field.label}
                          label={field.label}
                          disabled={value.id || field.disabled || false}
                          mask={mask}
                          required={field.required || false}
                          onChange={(event, value: string) => {
                            if (mask) {
                              value = value.replace(/\D/g, "");
                              setFieldValue(
                                `${currentDocumentName}[${currentDocumentIndex}].${field.name}`,
                                value
                              );
                            } else {
                              setFieldValue(
                                `${currentDocumentName}[${currentDocumentIndex}].${field.name}`,
                                value
                              );
                            }
                          }}
                          errorMessage={
                            errors[currentDocumentName]?.[
                              currentDocumentIndex
                            ]?.[field.name] &&
                            touched[currentDocumentName]?.[
                              currentDocumentIndex
                            ]?.[field.name]
                              ? errors[currentDocumentName]?.[
                                  currentDocumentIndex
                                ]?.[field.name]
                              : null
                          }
                        />
                      ) : (
                        <Field
                          name={`${currentDocumentName}[${currentDocumentIndex}].${field.name}`}
                          key={`${currentDocumentName}[${currentDocumentIndex}].${field.name}`}
                          type={field.type}
                          as={TextField}
                          placeholder={field.label}
                          label={field.label}
                          disabled={value.id || field.disabled || false}
                          required={field.required || false}
                          errorMessage={
                            errors[currentDocumentName]?.[
                              currentDocumentIndex
                            ]?.[field.name] &&
                            touched[currentDocumentName]?.[
                              currentDocumentIndex
                            ]?.[field.name]
                              ? errors[currentDocumentName]?.[
                                  currentDocumentIndex
                                ]?.[field.name]
                              : null
                          }
                        />
                      )}
                    </div>
                  );
              }
            })}
          </div>
        </Stack>

        <Stack.Item align="center">
          <IconButton
            iconProps={{ iconName: "Delete" }}
            title="Remover documento"
            ariaLabel="Remover documento"
            styles={{
              root: {
                color: theme.palette.red
              },
              iconHovered: {
                color: theme.palette.redDark
              }
            }}
            onClick={() => {
              arrayHelpers.remove(currentDocumentIndex);
            }}
          />
        </Stack.Item>
      </Stack>
    </StripedBackground>
  );
}

export function DocumentsFields(props: DocumentsFieldsProps): JSX.Element {
  const { documentCfg, values, errors, touched, setFieldValue } = props;
  // TODO: Remove used options
  // const [documents, setDocuments] = useState([]);
  // const [options, setOptions] = useState([]);

  // useEffect(() => {
  //   const usedDocuments = values.map(value => value.documentType);
  //   const options = [];
  //   documentCfg.fields.forEach(value => {
  //     if (value.name === 'documentType') {
  //       options.push(...value.options);
  //     }
  //   });

  //   const filteredOptions = options.filter(f => {
  //     return!usedDocuments.includes(f.key)
  //   });

  //   setOptions(filteredOptions);
  //   setDocuments(values);
  // }, [values])

  return (
    <FieldArray
      name={documentCfg.name}
      key={documentCfg.name}
      render={arrayHelpers => (
        <div style={{ marginTop: 10 }}>
          <Label required={documentCfg.required || false}>
            {documentCfg.label}
          </Label>
          <Stack tokens={{ childrenGap: 10 }}>
            {values instanceof Array &&
              values.map((document, documentIndex) => {
                return (
                  <DocumentField
                    key={documentIndex}
                    cfg={documentCfg}
                    currentDocumentName={documentCfg.name}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    currentDocumentIndex={documentIndex}
                    arrayHelpers={arrayHelpers}
                    value={document}
                  />
                );
              })}
            <div>
              <DefaultButton
                type="button"
                onClick={() => {
                  arrayHelpers.push({ documentType: "", documentNumber: "" });
                }}
              >
                Adicionar novo documento
              </DefaultButton>
            </div>
          </Stack>
        </div>
      )}
    />
  );
}
