import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createFailure,
  createOfferPricingGroupFailure,
  createOfferPricingGroupSuccess,
  createOfferProductFailure,
  createOfferProductSuccess,
  createSuccess,
  deleteOfferFailure,
  deleteOfferPricingGroupFailure,
  deleteOfferPricingGroupSuccess,
  deleteOfferProductFailure,
  deleteOfferProductSuccess,
  deleteOfferSuccess,
  editFailure,
  editOfferPricingGroupFailure,
  editOfferPricingGroupSuccess,
  editSuccess,
  listFailure,
  listOfferPriceFailure,
  listOfferPriceSuccess,
  listRequest,
  listSuccess
} from "../actions/offers";
import { OffersActionsTypes } from "../types/Offers.reducer.types";

import { SagaCustomError } from "@/common/types";
import { OffersService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";
import { parseStringToFloatDoubleFixed } from "@/utils/ParseStringToFloatDoubleFixed";

const abortController = new AbortController();
const { signal } = abortController;

function convertCurrencyNumber(value) {
  return isNaN(value)
    ? Number(value.replace(/\./g, "").replace(/,/g, "."))
    : Number(value);
}

export function* createOffer({ payload }: AnyAction) {
  const { values, setSubmitting, redirectFunction, isAdmin } = payload;
  const service = OffersService(undefined, isAdmin);
  const newValues = Object.assign({}, values);

  delete newValues.carrier;
  delete newValues.service;
  delete newValues.discount;
  delete newValues.discountBeginsAt;
  delete newValues.discountNumberOfMonths;
  delete newValues.bonusData;
  delete newValues.bonusDataBeginsAt;
  delete newValues.bonusDataNumberOfMonths;
  delete newValues.fidelityDuration;
  delete newValues.simValue;

  newValues.activationFee = convertCurrencyNumber(values.activationFee);

  if (values.fidelityDuration) {
    newValues.fidelityDuration = Number(values.fidelityDuration);
  }

  if (values.simValue) {
    newValues.simValue = convertCurrencyNumber(values.simValue);
  }

  if (values.discount) {
    newValues.discount = {
      ...values.discount,
      value: parseStringToFloatDoubleFixed(values?.discount?.value)
    };
  }

  if (values.bonusData) {
    newValues.bonusData = values.bonusData;
  }

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createSuccess({
        message: `Pacotes de Benefícios criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (redirectFunction) {
      setTimeout(() => {
        redirectFunction(response.data?.id);
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      createFailure({
        code: error?.statusCode,
        message: "Não foi possível criar o benefício com os dados informados.",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* createOfferPricingGroup({ payload }: AnyAction) {
  const { values, setSubmitting, isAdmin, closePanel, refetchList } = payload;
  const service = OffersService(`/${values.offer}/offers-prices`, isAdmin);

  const newValues = Object.assign({}, values);

  newValues.validBetween = `[${values.from},${values.to})`;
  newValues.price = convertCurrencyNumber(values.price);
  delete newValues.from;
  delete newValues.to;

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createOfferPricingGroupSuccess({
        message: `Grupo de precificação criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    refetchList();
  } catch (error) {
    yield put(
      createOfferPricingGroupFailure({
        code: error?.statusCode,
        message:
          "Não foi possível criar o grupo de precificação com os dados informados.",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

function formatarDataParaFormatoDoBanco(data) {
  const ano = data.getFullYear();
  const mes = (data.getMonth() + 1).toString().padStart(2, "0"); // +1 porque o mês começa em 0
  const dia = data.getDate().toString().padStart(2, "0");
  return `${ano}-${mes}-${dia}`;
}

export function* editOfferPricingGroup({ payload }: AnyAction) {
  const { values, setSubmitting, isAdmin, closePanel, refetchList } = payload;
  const service = OffersService(`/offers-prices`, isAdmin);

  const newValues = Object.assign({}, values);

  newValues.validBetween = `[${formatarDataParaFormatoDoBanco(
    new Date(values.from)
  )},${formatarDataParaFormatoDoBanco(new Date(values.to))})`;

  newValues.price = convertCurrencyNumber(values.price);
  delete newValues.from;
  delete newValues.to;

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editOfferPricingGroupSuccess({
        message: `Grupo de precificação editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    refetchList();
  } catch (error) {
    yield put(
      editOfferPricingGroupFailure({
        code: error?.statusCode,
        message:
          "Não foi possível editar o grupo de precificação com os dados informados.",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* deleteOfferPricingGroup({ payload }: AnyAction) {
  const { pricingGroupId, setSubmitting, isAdmin, refetchList } = payload;
  const service = OffersService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.deleteOfferPricingGroup, {
      params: { body: {}, id: pricingGroupId },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      deleteOfferPricingGroupSuccess({
        message: `Grupo de precificação apagado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    // REFRESH LIST
    refetchList();
  } catch (error) {
    yield put(
      deleteOfferPricingGroupFailure({
        code: error?.statusCode,
        message: "Não foi possível apagar o grupo de precificação.",
        type: messageBarTypes.ERROR
      })
    );
    setSubmitting(false);
  }
}

export function* createOfferProduct({ payload }: AnyAction) {
  const { values, setSubmitting, isAdmin, closePanel, refetchList } = payload;
  const service = OffersService(
    `/offers-prices/${values.offerPrice}/sales-conditions`,
    isAdmin
  );

  const newValues = Object.assign({}, values);

  newValues.cashValue = convertCurrencyNumber(values.cashValue);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createOfferProductSuccess({
        message: `Benefício combo adicionado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    refetchList();
  } catch (error) {
    yield put(
      createOfferProductFailure({
        code: error?.statusCode,
        message:
          "Não foi possível adicionar o benefício combo com os dados informados.",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* deleteOfferProduct({ payload }: AnyAction) {
  const { offerProductId, setSubmitting, isAdmin, refetchList } = payload;
  const service = OffersService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.deleteOfferProduct, {
      params: { body: {}, id: offerProductId },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      deleteOfferProductSuccess({
        message: `Benefício combo apagado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    // REFRESH LIST
    refetchList();
  } catch (error) {
    yield put(
      deleteOfferProductFailure({
        code: error?.statusCode,
        message: "Não foi possível apagar o benefício combo.",
        type: messageBarTypes.ERROR
      })
    );
    setSubmitting(false);
  }
}

export function* editOffer({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = OffersService(undefined, isAdmin);
  if (values?.discount?.value) {
    values.discount.value =
      values?.discount && values?.discount?.value
        ? parseStringToFloatDoubleFixed(`${values?.discount?.value}`)
        : null;
  }

  const newValues = {
    name: values.name,
    id: values.id,
    status: values.status,
    additionalOffer: values?.additionalOffer,
    activationFee: values.activationFee?.replace(",", "."),
    discount: values?.discount,
    bonusData: values?.bonusData,
    simValue: values.simValue?.replace(",", ".")
  };

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editSuccess({
        message: `Benefício editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      editFailure({
        code: error?.statusCode,
        message: error.message,
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* deleteOffer({ payload }: AnyAction) {
  const { offerId, setSubmitting, isAdmin, redirectFunction } = payload;
  const service = OffersService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.deleteOffer, {
      params: { body: {}, id: offerId },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(deleteOfferSuccess());

    if (redirectFunction) {
      setTimeout(() => {
        redirectFunction();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      deleteOfferFailure({
        code: error?.statusCode,
        message: "Não foi possível deletar o benefício.",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listOffers({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = OffersService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listSuccess({ list: response.data }));
  } catch (error) {
    yield put(listFailure());
  }
}

export function* listOfferPrice({ payload }: AnyAction) {
  const { filter, id, isAdmin } = payload;
  const service = OffersService(`/${id}/prices`, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listOfferPriceSuccess({ list: response.data }));
  } catch (error) {
    yield put(listOfferPriceFailure());
  }
}

export default all([
  takeLatest(OffersActionsTypes.CREATE_REQUEST, createOffer),
  takeLatest(
    OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_REQUEST,
    createOfferPricingGroup
  ),
  takeLatest(
    OffersActionsTypes.CREATE_OFFER_PRODUCT_REQUEST,
    createOfferProduct
  ),
  takeLatest(
    OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_REQUEST,
    editOfferPricingGroup
  ),
  takeLatest(
    OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_REQUEST,
    deleteOfferPricingGroup
  ),
  takeLatest(
    OffersActionsTypes.DELETE_OFFER_PRODUCT_REQUEST,
    deleteOfferProduct
  ),
  takeLatest(OffersActionsTypes.EDIT_REQUEST, editOffer),
  takeLatest(OffersActionsTypes.DELETE_OFFER_REQUEST, deleteOffer),
  takeLatest(OffersActionsTypes.LIST_REQUEST, listOffers),
  takeLatest(OffersActionsTypes.LIST_OFFER_PRICE_REQUEST, listOfferPrice)
]);
