import { ValidateCNPJ } from "@/utils/ValidateCNPJ";
import { ValidateCPF } from "@/utils/ValidateCPF";

export const validateDocument = (value, type): boolean => {
  const validateRG = value => typeof value === "string";

  const validationCases = {
    CPF: () => ValidateCPF(value),
    RG: () => validateRG(value),
    CNPJ: () => ValidateCNPJ(value)
  };

  return validationCases[type] ? validationCases[type]() : true;
};
