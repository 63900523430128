import produce from "immer";

import {
  OffersPricesActionsTypes,
  OffersPricesReducerActions,
  OffersPricesState
} from "../types/OffersPrices.reducer.types";

const INITIAL_STATE: OffersPricesState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: OffersPricesReducerActions
): OffersPricesState {
  return produce(state, draft => {
    switch (action.type) {
      case OffersPricesActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersPricesActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersPricesActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersPricesActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case OffersPricesActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OffersPricesActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OffersPricesActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OffersPricesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case OffersPricesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case OffersPricesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
