import {
  Icon,
  TextField,
  DefaultButton,
  getTheme,
  PrimaryButton
} from "@fluentui/react";
import QRCode from "qrcode.react";
import { useState } from "react";

import { Section, ButtonsWrapper } from "./styles";

import {
  PaymentRequest,
  PaymentRequestStatus
} from "@/common/types/PaymentRequestTypes";
import { formatDateAndTimeToString } from "@/utils/FormatDate";

interface PixSectionProps {
  paymentRequest: PaymentRequest;
  handleConfirmPayment: () => void;
  showConfirmPaymentButton: boolean;
}

interface PixSectionInfo {
  title: string;
  message: string;
  showConfirmPaymentButton?: boolean;
  icon?: string;
  showQrCode?: boolean;
}

export function PixSection({
  paymentRequest,
  handleConfirmPayment,
  showConfirmPaymentButton
}: PixSectionProps) {
  const theme = getTheme();

  const [copyCode, setCopyCode] = useState("Copiar código");

  const formatPixSectionInfo = (
    paymentRequest: PaymentRequest
  ): PixSectionInfo => {
    switch (paymentRequest.requestStatus) {
      case PaymentRequestStatus.COMPLETED:
        return {
          title: "Pix efetuado com sucesso!",
          message:
            paymentRequest.completedAt &&
            `Pagamento confirmado em: ${formatDateAndTimeToString(
              paymentRequest.completedAt
            )}`,
          icon: "CompletedSolid",
          showConfirmPaymentButton: showConfirmPaymentButton,
          showQrCode: false
        };

      case PaymentRequestStatus.REFUNDED:
        return {
          title: "Reembolso do Pix realizado com sucesso.",
          message: `Reembolso realizado em: ${formatDateAndTimeToString(
            paymentRequest?.refundedAt
          )}`,
          icon: "RepeatAll",
          showConfirmPaymentButton: false,
          showQrCode: false
        };

      case PaymentRequestStatus.REFUND_REQUESTED:
        return {
          title: "Aguardando reembolso.",
          message: "O reembolso será realizado em breve.",
          icon: "Clock",
          showConfirmPaymentButton: false,
          showQrCode: false
        };

      case PaymentRequestStatus.EXPIRED:
        return {
          title: "Prazo encerrado.",
          message: `O prazo encerrou em: ${formatDateAndTimeToString(
            paymentRequest?.expiresAt
          )}`,
          icon: "RemoveEvent",
          showConfirmPaymentButton: false,
          showQrCode: false
        };

      default:
        return {
          title: "Código Pix para pagamento",
          message: "Encaminhe para o cooperado através de mensagem ou e-mail.",
          icon: "",
          showConfirmPaymentButton: showConfirmPaymentButton,
          showQrCode: true
        };
    }
  };

  const pixSectionInfo: PixSectionInfo = formatPixSectionInfo(paymentRequest);

  const handleCopyCode = () => {
    navigator.clipboard.writeText(paymentRequest?.code);
    setCopyCode("Código copiado");

    setTimeout(() => {
      setCopyCode("Copiar código");
    }, 2500);
  };

  return (
    <Section>
      <strong>{pixSectionInfo.title}</strong>

      <p style={{ marginBottom: "0" }}>{pixSectionInfo.message}</p>

      {pixSectionInfo.showQrCode ? (
        <QRCode value={paymentRequest?.code} style={{ marginTop: "1rem" }} />
      ) : (
        <Icon
          iconName={pixSectionInfo?.icon}
          style={{
            fontSize: "100px",
            color: theme.palette.themeDark
          }}
        />
      )}

      <TextField
        styles={{ root: { width: 300 } }}
        label="Pix Copia e Cola"
        disabled={true}
        readOnly={true}
        defaultValue={paymentRequest?.code}
        value={paymentRequest?.code}
      />

      <ButtonsWrapper>
        <DefaultButton text={copyCode} onClick={handleCopyCode} />

        {showConfirmPaymentButton && (
          <PrimaryButton
            text="Verificar Pagamento"
            onClick={() => handleConfirmPayment()}
          />
        )}
      </ButtonsWrapper>
    </Section>
  );
}
