import { LinesActionsTypes } from "../types/Lines.reducer.types";

export function createLinesRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: LinesActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createLinesSuccess(message) {
  return {
    type: LinesActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createLinesFailure(message) {
  return {
    type: LinesActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editLinesRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: LinesActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editLinesSuccess(message) {
  return {
    type: LinesActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editLinesFailure(message) {
  return {
    type: LinesActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listLinesRequest(filter, { isAdmin = false }) {
  return {
    type: LinesActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listLinesSuccess({ list }) {
  return {
    type: LinesActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listLinesFailure() {
  return {
    type: LinesActionsTypes.LIST_FAIL
  };
}

export function listLinesDashboardRequest(filter, { isAdmin = false }) {
  return {
    type: LinesActionsTypes.LIST_DASHBOARD_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listLinesDashboardSuccess({ list }) {
  return {
    type: LinesActionsTypes.LIST_DASHBOARD_SUCCESS,
    payload: { list }
  };
}

export function listLinesDashboardFailure() {
  return {
    type: LinesActionsTypes.LIST_DASHBOARD_FAIL
  };
}

export function dismissMessage() {
  return {
    type: LinesActionsTypes.DISMISS_MESSAGE
  };
}
