import {
  ProductsCarriers,
  ProductsCategory,
  ProductsBrands,
  ListOfProductsOffers,
  ProductOfferItem,
  SaleCondition
} from "@/modules/Offers/types/ProductsOffers.types";

export const formatProductOffers = (products: ListOfProductsOffers) => {
  if (!products?.products?.items || !products?.products || !products) return;

  const items = Array.from(formatProducts(products.products.items));

  return {
    productsOffersList: {
      ...products,
      products: { ...products, items: items }
    },
    productsBrands: products?.productsBrands,
    productsCarriers: products?.carriers,
    productsCategories: products?.productsCategories
  };
};

function* formatProducts(products: ProductOfferItem[]) {
  for (const product of products) {
    if (product?.saleConditions?.length > 0) {
      const { allCarriers, allCashValue, allData } = generateRestProductsValues(
        product.saleConditions
      );
      if (allCashValue.length > 0) {
        allCashValue.sort(orderValues);
      }

      if (allData.length > 0) {
        allData.sort(orderValues);
      }
      yield {
        ...product,
        allCashValue,
        allCarriers,
        allData
      };
    } else {
      yield {
        ...product,
        allCashValue: [],
        allCarriers: [],
        allData: []
      };
    }
  }
}
function generateRestProductsValues(saleConditions: SaleCondition[]) {
  const allCashValue: Set<string> = new Set();
  const allCarriers: Set<string> = new Set();
  const allData: Set<number> = new Set();

  for (const saleCondition of saleConditions) {
    const summedData =
      Number(saleCondition.offerPrice?.offer?.bonusData?.value || 0) +
      Number(saleCondition.offerPrice?.offer?.plan?.data || 0);

    if (!allCashValue.has(saleCondition?.cashValue)) {
      allCashValue.add(saleCondition?.cashValue);
    }

    if (
      !allCarriers.has(saleCondition?.offerPrice?.offer?.plan?.carrier?.name)
    ) {
      allCarriers.has(saleCondition?.offerPrice?.offer?.plan?.carrier?.name);
    }

    if (!allData.has(summedData)) {
      allData.add(summedData);
    }

    saleCondition.carrier =
      saleCondition?.offerPrice?.offer?.plan?.carrier?.name;

    saleCondition.summedData = summedData;
  }
  return {
    allCashValue: Array.from(allCashValue),
    allCarriers: Array.from(allCarriers),
    allData: Array.from(allData)
  };
}

const orderValues = (a, b) => {
  return Number(a) - Number(b);
};

export interface IFormatProductsResponse {
  productsOffersList: ListOfProductsOffers;
  productsBrands: ProductsBrands[];
  productsCarriers: ProductsCarriers[];
  productsCategories: ProductsCategory[];
}
