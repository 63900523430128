import produce from "immer";

import {
  ServiceProvidersActionsTypes,
  ServiceProvidersReducerActions,
  ServiceProvidersState
} from "../types/ServiceProviders.reducer.types";

const INITIAL_STATE: ServiceProvidersState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ServiceProvidersReducerActions
): ServiceProvidersState {
  return produce(state, draft => {
    switch (action.type) {
      case ServiceProvidersActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ServiceProvidersActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ServiceProvidersActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ServiceProvidersActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ServiceProvidersActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ServiceProvidersActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ServiceProvidersActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ServiceProvidersActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case ServiceProvidersActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case ServiceProvidersActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
