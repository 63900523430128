import {
  DefaultButton,
  Dropdown,
  getTheme,
  IconButton,
  Label,
  MaskedTextField,
  Stack,
  TextField
} from "@fluentui/react";
import { useConstCallback } from "@fluentui/react-hooks";
import { Field, FieldArray } from "formik";
import { useState } from "react";

import StripedBackground from "@/components/Shared/StripedBackground";

interface CheckingAccountsFieldsProps {
  config;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
}

interface CheckingAccountFieldProps {
  currentAccountName: string;
  currentAccountIndex: number;
  cfg: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  arrayHelpers: any;
  value?: any;
}

function CheckingAccount(props: CheckingAccountFieldProps) {
  const theme = getTheme();
  const [mask, setMask] = useState(undefined);

  const useMask = useConstCallback(mask => setMask(mask));
  const removeMask = useConstCallback(() => setMask(undefined));

  const {
    cfg,
    currentAccountName,
    setFieldValue,
    errors,
    touched,
    currentAccountIndex,
    arrayHelpers,
    value
  } = props;

  return (
    <StripedBackground>
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
        key={`${currentAccountName}${currentAccountIndex}`}
      >
        <Stack tokens={{ childrenGap: 5 }} grow>
          <div className="ms-Grid-row" style={{ marginBottom: 15 }}>
            {cfg.fields.map(field => {
              switch (field.type) {
                case "select":
                  // FIXME: improve
                  if (value.accountType) {
                    const fieldMask = field.options.find(
                      x => x.key === value.accountType
                    );
                    //prettier-ignore
                    //eslint-disable-next-line
                    if (mask && mask !== fieldMask.mask) useMask(fieldMask?.mask);
                  }

                  return (
                    <div
                      className={`ms-Grid-col ms-sm12${
                        field.size ? ` ms-lg${field.size}` : ""
                      }`}
                      key={`${field.name}[${currentAccountIndex}]`}
                    >
                      <Field
                        name={`${currentAccountName}[${currentAccountIndex}].${field.name}`}
                        key={`${currentAccountName}[${currentAccountIndex}].${field.name}`}
                        id={`${currentAccountName}[${currentAccountIndex}].${field.name}`}
                        type={field.type}
                        as={Dropdown}
                        onChange={(evt, item) => {
                          if (item.mask) {
                            //eslint-disable-next-line
                            useMask(item.mask);
                          } else {
                            removeMask();
                          }

                          setFieldValue(
                            `${currentAccountName}[${currentAccountIndex}].${field.name}`,
                            item.key
                          );
                        }}
                        placeholder={field.label}
                        defaultSelectedKey={value.accountType}
                        selectedKey={value[field.name]}
                        label={field.label}
                        disabled={field.disabled || false}
                        options={field.options}
                        styles={{ dropdown: { width: "100%" } }}
                        required={field.required || false}
                        errorMessage={
                          errors[currentAccountName]?.[currentAccountIndex]?.[
                            field.name
                          ] &&
                          touched[currentAccountName]?.[currentAccountIndex]?.[
                            field.name
                          ]
                            ? errors[currentAccountName]?.[
                                currentAccountIndex
                              ]?.[field.name]
                            : null
                        }
                      />
                    </div>
                  );

                default:
                  return (
                    <div
                      className={`ms-Grid-col ms-sm12${
                        field.size ? ` ms-lg${field.size}` : ""
                      }`}
                      key={`${field.name}[${currentAccountIndex}]`}
                    >
                      <Field
                        name={`${currentAccountName}[${currentAccountIndex}].${field.name}`}
                        key={`${currentAccountName}[${currentAccountIndex}].${field.name}`}
                        type={field.type}
                        as={mask ? MaskedTextField : TextField}
                        placeholder={field.label}
                        label={field.label}
                        disabled={field.disabled || false}
                        mask={mask}
                        required={field.required || false}
                        errorMessage={
                          errors[currentAccountName]?.[currentAccountIndex]?.[
                            field.name
                          ] &&
                          touched[currentAccountName]?.[currentAccountIndex]?.[
                            field.name
                          ]
                            ? errors[currentAccountName]?.[
                                currentAccountIndex
                              ]?.[field.name]
                            : null
                        }
                        autoFocus={field.autoFocus}
                      />
                    </div>
                  );
              }
            })}
          </div>
        </Stack>
        <Stack.Item align="center">
          <IconButton
            iconProps={{ iconName: "Delete" }}
            title="Remover conta"
            ariaLabel="Remover conta"
            styles={{
              root: {
                color: theme.palette.red
              },
              iconHovered: {
                color: theme.palette.redDark
              }
            }}
            onClick={() => arrayHelpers.remove(currentAccountIndex)}
          />
        </Stack.Item>
      </Stack>
    </StripedBackground>
  );
}

export function CheckingAccountsFields(
  props: CheckingAccountsFieldsProps
): JSX.Element {
  const { config, values, errors, touched, setFieldValue } = props;
  return (
    <FieldArray
      name={config.name}
      key={config.name}
      render={arrayHelpers => (
        <div style={{ marginTop: 10 }}>
          <Label required={config.required || false}>{config.label}</Label>
          <Stack tokens={{ childrenGap: 10 }}>
            {values &&
              values.map((field, index) => {
                return (
                  <CheckingAccount
                    key={index}
                    cfg={config}
                    currentAccountName={config.name}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    currentAccountIndex={index}
                    arrayHelpers={arrayHelpers}
                    value={field}
                  />
                );
              })}
            <div>
              <DefaultButton
                type="button"
                onClick={() => {
                  let bankNumber = "";
                  let agencyNumber = "";
                  if (config?.defaultValues?.bankNumber) {
                    bankNumber = config.defaultValues.bankNumber;
                  }
                  if (config?.defaultValues?.agencyNumber) {
                    agencyNumber = config.defaultValues.agencyNumber;
                  }
                  arrayHelpers.push({
                    bankNumber: bankNumber,
                    agencyNumber: agencyNumber,
                    accountNumber: ""
                  });
                }}
              >
                Adicionar nova conta
              </DefaultButton>
            </div>
          </Stack>
        </div>
      )}
    />
  );
}
