import {
  FontIcon,
  ProgressIndicator,
  Stack,
  TooltipHost
} from "@fluentui/react";

import {
  ContractBadgeColors,
  ContractStatus,
  IconNames,
  TranslatedContractCurrentStep
} from "@/modules/Contracts/enums";

interface MenuProps {
  steps: any[];
  currentStep: number;
  size?: "normal" | "small";
}

export function ViewProgress({
  currentStep,
  steps,
  size = "normal"
}: MenuProps) {
  const findCurrentStepInSteps = steps?.find(
    step => step?.order === currentStep
  );
  const currentStepName = findCurrentStepInSteps?.internalName;
  const maxPercentage = steps?.reduce((p, c) =>
    p.order > c.order ? p.order : c.order
  );

  const isContractFailed =
    currentStepName === ContractStatus.CANCELED ||
    currentStepName === ContractStatus.REJECTED ||
    currentStepName === ContractStatus.TIMED_OUT;

  const isContractFinished =
    isContractFailed || currentStepName === ContractStatus.DONE;

  return (
    <Stack grow>
      <ProgressIndicator
        percentComplete={isContractFinished ? 1 : currentStep / maxPercentage}
        styles={{
          progressBar: {
            background: isContractFinished
              ? ContractBadgeColors[currentStepName]
              : ""
          }
        }}
      />
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{ userSelect: "none" }}
      >
        {steps?.length > 0 &&
          steps.map(step => {
            if (
              (step.internalName === "REJECTED" &&
                currentStepName !== "REJECTED") ||
              (step.internalName === "TIMED_OUT" &&
                currentStepName !== "TIMED_OUT") ||
              step.internalName === "IN_PROGRESS" ||
              (step.internalName === "CANCELED" &&
                currentStepName !== "CANCELED")
            ) {
              return null;
            }

            return (
              <TooltipHost
                content={TranslatedContractCurrentStep[step.internalName]}
                className="ms-fontSize-14 ms-fontWeight-semibold"
                key={step.internalName}
              >
                <FontIcon
                  iconName={IconNames[step.internalName] || "StatusErrorFull"}
                  style={{
                    color:
                      isContractFailed &&
                      currentStepName === step.internalName &&
                      ContractBadgeColors[currentStepName]
                        ? ContractBadgeColors[currentStepName]
                        : step.order <= currentStep
                        ? "#395ca3"
                        : "#6b6b6b",
                    fontSize: size === "normal" ? 30 : 20,
                    padding: size === "normal" ? 10 : 5,
                    borderBottom: "2px solid",
                    cursor: "help",
                    borderBottomColor:
                      isContractFailed &&
                      currentStepName === step.internalName &&
                      ContractBadgeColors[currentStepName]
                        ? ContractBadgeColors[currentStepName]
                        : step.order === currentStep
                        ? "#395ca3"
                        : "transparent"
                  }}
                />
              </TooltipHost>
            );
          })}
      </Stack>
    </Stack>
  );
}
