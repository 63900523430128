import produce from "immer";

import {
  ReportCategoriesActionsTypes,
  ReportCategoriesReducerActions,
  ReportCategoriesState
} from "../types/ReportCategories.reducer.types";

const INITIAL_STATE: ReportCategoriesState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ReportCategoriesReducerActions
): ReportCategoriesState {
  return produce(state, draft => {
    switch (action.type) {
      case ReportCategoriesActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ReportCategoriesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case ReportCategoriesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case ReportCategoriesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
    }
  });
}
