import { format } from "date-fns";
// eslint-disable-next-line
import { ptBR } from "date-fns/locale";

export const formatDate = (date: string): string => {
  const dateFormatted = new Intl.DateTimeFormat("pt-BR").format(new Date(date));

  return dateFormatted;
};

export const newFormatDate = (date: string, dateFormat: string): string => {
  if (date) {
    return format(new Date(date), dateFormat);
  } else {
    return "";
  }
};

export const formatDateAndTimeToString = (date: Date): string => {
  if (!date) return "";

  return format(new Date(date), "dd 'de' MMMM 'de' yyyy 'às' HH:mm", {
    locale: ptBR
  });
};
