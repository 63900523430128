import styled from "styled-components";

export const UploadInput = styled.label`
  height: 150px;
  background: #f3f2f1;
  border-radius: 5px;
  border: 1px dashed #395ca3;
  display: block;
  position: relative;
  cursor: pointer;
  align-self: center;
  margin: 20px;

  input {
    opacity: 0;
    width: 100%;
    cursor: pointer;
    height: 100px;
  }

  i {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #395ca3;
    text-align: center;
  }
`;
