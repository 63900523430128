import { DetailsRow, IColumn } from "@fluentui/react/lib/DetailsList";
import { GroupedList, IGroup } from "@fluentui/react/lib/GroupedList";
import {
  Selection,
  SelectionMode,
  SelectionZone
} from "@fluentui/react/lib/Selection";
import { Component } from "react";

interface SelectOfferProps {
  offers: any[];
  groups: IGroup[];
  setFieldValue: () => void;
  handleSelectedPriceId: ({
    offerPriceId,
    isSmart
  }: {
    offerPriceId: number;
    isSmart: boolean;
  }) => void;
  setIsConectivityOrSmart?: (state: boolean) => void;
}

type SelectedPlan = {
  description?: string;
  key?: string;
  details?: {
    id?: number;
    combo?: string;
    discount?: {
      beginsAt: number;
      numberOfMonths: number;
      value: number;
    };
    offerPrice?: {
      id: number;
      price: string;
    };
  };
  plan?: number;
};

enum ECombo {
  LIGHT = "LIGHT",
  LIGHT_DADOS = "LIGHT_DADOS",
  CONECTIVIDADE_BASICA = "CONECTIVIDADE_BASICA",
  CONECTIVIDADE_AVANCADA = "CONECTIVIDADE_AVANCADA",
  SMART = "SMART"
}

class SelectOffer extends Component<SelectOfferProps> {
  private _columns: IColumn[];
  private _selection: Selection;

  constructor(props) {
    super(props);

    this._columns = [
      {
        key: "description",
        name: "Descrição",
        fieldName: "description",
        minWidth: 280
      }
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        props.setFieldValue("line", "");

        if (this._getSelectionDetails()) {
          const isSmartOrConectivity = [
            "SMART",
            "CONECTIVIDADE_AVANCADA"
          ].includes(ECombo[this._getSelectionDetails()?.details?.combo]);

          if (props?.setIsConectivityOrSmart) {
            props.setIsConectivityOrSmart(isSmartOrConectivity);
          }

          props.setFieldValue("offerPrice", this._getSelectionDetails()?.key);
          props.setFieldValue("plan", this._getSelectionDetails()?.plan);
          props.setFieldValue("saleCondition", undefined);
          props.setFieldValue("additionalInfo.installment", undefined);

          if (!isSmartOrConectivity) {
            props.setFieldValue("additionalInfo", undefined);
            props.handleSelectedPriceId({
              offerPriceId: this._getSelectionDetails()?.key,
              isSmart: false
            });
          }

          if (isSmartOrConectivity) {
            props.handleSelectedPriceId({
              offerPriceId: this._getSelectionDetails()?.key,
              isSmart: true
            });
          }

          props.setFieldValue(
            "additionalInfo.planIsConectivityOrSmart",
            isSmartOrConectivity
          );
        } else {
          props.setFieldValue("offerPrice", undefined);
          props.setFieldValue("plan", undefined);
          props.setFieldValue("additionalInfo", undefined);
          props.handleSelectedPriceId({
            offerPriceId: this._getSelectionDetails()?.key,
            isSmart: false
          });
        }
      },
      // FIXME: https://github.com/OfficeDev/@fluentui/react/pull/11726
      selectionMode: SelectionMode.single
    });

    this._selection.setItems(props.offers);
  }

  componentDidUpdate(prevProps) {
    const { offers } = this.props;

    if (offers !== prevProps.offers) {
      this._selection.setItems(offers);
    }
    return null;
  }

  render() {
    const { offers, groups } = this.props;

    return (
      <SelectionZone
        selection={this._selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
      >
        <GroupedList
          items={offers}
          onRenderCell={this._onRenderCell}
          selection={this._selection}
          selectionMode={SelectionMode.single}
          groups={groups}
          compact
          groupProps={{
            headerProps: {
              indentWidth: 1
            }
          }}
        />
      </SelectionZone>
    );
  }

  private _onRenderCell = (
    nestingDepth: number,
    item: any,
    itemIndex: number
  ): JSX.Element => {
    return (
      <DetailsRow
        id={`plan-${itemIndex}-select`}
        columns={this._columns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex}
        selection={this._selection}
        selectionMode={SelectionMode.single}
        indentWidth={1}
        styles={{
          root: {
            width: "100%",
            selectors: {
              "& .ms-DetailsRow-check": {
                opacity: 1
              }
            }
          },
          fields: {
            width: "calc(100% - 48px)"
          },
          cell: {
            whiteSpace: "pre-wrap"
          }
        }}
      />
    );
  };

  private _getSelectionDetails(): SelectedPlan {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return undefined;
      default:
        return this._selection.getSelection()[0] as any;
    }
  }
}

export default SelectOffer;
