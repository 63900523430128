import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createContractFailure,
  createContractSuccess,
  editContractFailure,
  editContractSuccess,
  listContractFailure,
  listContractRequest,
  listContractSuccess
} from "../actions/contracts";
import { ContractActionsTypes } from "../types";

import { SagaCustomError } from "@/common/types";
import { ContractsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

export function* createContract({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel } = payload;

  setSubmitting(true);

  try {
    const abortController = new AbortController();
    const { signal } = abortController;

    const service = ContractsService();

    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;
      error.message = response.error.message;

      throw error;
    }

    yield put(
      createContractSuccess({
        message: `Contrato criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listContractRequest());
  } catch (error) {
    yield put(
      createContractFailure({
        message: error?.message,
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editContract({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel } = payload;

  setSubmitting(true);

  try {
    const abortController = new AbortController();
    const { signal } = abortController;

    const service = ContractsService();

    const response = yield call(service.edit, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;
      error.message = response.error.message;

      throw error;
    }

    yield put(
      editContractSuccess({
        message: `Contrato editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listContractRequest());
  } catch (error) {
    yield put(
      editContractFailure({
        message: error?.message,
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listContract({ payload }: AnyAction) {
  const { filter } = payload;

  try {
    const abortController = new AbortController();
    const { signal } = abortController;

    const service = ContractsService();

    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listContractSuccess({ list: response.data }));
  } catch (error) {
    yield put(listContractFailure());
  }
}

export default all([
  takeLatest(ContractActionsTypes.CREATE_REQUEST, createContract),
  takeLatest(ContractActionsTypes.EDIT_REQUEST, editContract),
  takeLatest(ContractActionsTypes.LIST_REQUEST, listContract)
]);
