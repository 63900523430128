import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StringifiableRecord } from "query-string";

import { ExecuteData, useApi } from "../../useApi";

import { IFeeType } from "./fee-type.interface";
import { IUseGetFeeTypeByServiceProps } from "./useGetFeeTypeByService.types";

import { useAuth } from "@/core/libs/use-auth";
import { useSelectedBu } from "@/hooks/useSelectedBu";

const FEE_TYPE_QUERY_TOKEN = "fee-type";

export const useGetFeeTypeByService = (
  props?: IUseGetFeeTypeByServiceProps
) => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();

  const { selectedBU } = useSelectedBu();

  const paramValues = {
    businessActionId: selectedBU.id,
    all: true,
    page: 1,
    ...props?.params
  };

  const fetchData = async (params: StringifiableRecord) => {
    const data: ExecuteData = {
      url: props?.urlString || `admin/fee-type/service/${props.serviceId}`,
      method: props?.method || "GET",
      notificationMessage: "Buscando tipos de taxas",
      params: params
    };

    if (data.method !== "GET") {
      data.bodyObj = Object(params);
    }

    return execute(data);
  };

  const { data, isFetching } = useQuery({
    queryKey: [FEE_TYPE_QUERY_TOKEN, paramValues],
    queryFn: () => fetchData(paramValues),
    enabled: isAuthenticated,
    staleTime: 120000,
    keepPreviousData: true
  });

  const invalidateQuery = async () => {
    queryClient.invalidateQueries({ queryKey: [FEE_TYPE_QUERY_TOKEN] });
  };

  const feeTypeList: IFeeType[] = data?.data ?? [];

  return {
    feeTypeList,
    isLoadingFeeTypeList: isFetching,
    invalidateQuery
  };
};
