import { getTheme, Stack } from "@fluentui/react";

import ItemReplacementHistory from "./ItemReplacementHistory";
import ItemReplacementProcess from "./ItemReplacementProcess";
import { Children, IContractItem } from "./types/contractItem.interface";

interface ItemReplacementRowProps {
  item: IContractItem;
  isCompactMode: boolean;
  onSuccess: () => void;
}

const theme = getTheme();

function ItemReplacementRow({
  item,
  isCompactMode,
  onSuccess
}: ItemReplacementRowProps) {
  const currentChildren: Children = item?.children
    .filter(children => !!children?.nextStep?.id)
    .sort((a, b) => a.id - b.id)[0];

  return (
    <div style={{ padding: 15, borderBottom: "1px solid rgb(243, 242, 241)" }}>
      <Stack styles={{ root: { boxShadow: theme.effects.elevation4 } }}>
        <Stack
          style={{
            backgroundColor: theme.palette.themeLighterAlt,
            color: "#395ca3",
            padding: 10
          }}
          horizontal
        >
          <span className="ms-fontWeight-semibold ms-fontSize-14">
            Substituições
          </span>
        </Stack>

        {currentChildren?.approvalFlow && (
          <ItemReplacementProcess
            item={currentChildren}
            size={isCompactMode ? "small" : "normal"}
            onSuccess={onSuccess}
          />
        )}

        {item.children?.length > 0 && (
          <ItemReplacementHistory
            previousItems={item.children}
            isCompactMode={isCompactMode}
          />
        )}
      </Stack>
    </div>
  );
}

export default ItemReplacementRow;
