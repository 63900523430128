import { currencyValueToLocaleString } from "@/utils/currencyValueToLocaleString";

interface renderPriceTextProps {
  price: string;
  discount: number;
  discountNumberOfMonths: number;
  beginsAt: number;
}

export const renderPriceText = ({
  price,
  discount,
  discountNumberOfMonths,
  beginsAt
}: renderPriceTextProps): string => {
  let text = `Pacote de Benefício no valor de ${currencyValueToLocaleString(
    price
  )}`;

  if (discount > 0) {
    text += ` com desconto de ${currencyValueToLocaleString(discount)}`;

    if (discountNumberOfMonths) {
      text += ` por ${discountNumberOfMonths} meses`;
    }

    if (beginsAt) {
      text += ` a partir da ${beginsAt}.ª fatura.`;
    }
  }

  return text;
};
