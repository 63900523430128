import { Stack } from "@fluentui/react";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { buildTermsColumns } from "./fields/Terms.columns";
import { buildTermsFilters } from "./fields/Terms.filters";

import { FilterParamsType } from "@/common/types/ListFromApi.types";
import { Card } from "@/components/Shared/Card";
import { useGetServices } from "@/core/libs/api/react-query/hook/use-get-services";
import { useGetTerms } from "@/core/libs/api/react-query/hook/use-get-terms";
import { ListData } from "@/core/libs/list-data";
import { useURLFilter } from "@/hooks/useUrlFIlter";
import { formatToSelect } from "@/utils/FormatToSelect";

function Terms(): JSX.Element {
  const { changeUrlParams, urlParams } = useURLFilter();
  const { termsList, isLoadingTermsList } = useGetTerms({
    params: urlParams.params,
    urlString: "terms",
    method: "GET"
  });

  const { servicesList, isLoadingServicesList } = useGetServices({
    method: "GET"
  });

  const servicesFormatted = formatToSelect(servicesList);
  const history = useHistory();

  const handleAccountRequest = useCallback(
    (filterParams?: FilterParamsType) => {
      changeUrlParams(filterParams);
    },
    [changeUrlParams]
  );

  return (
    <Stack styles={{ root: { width: "100%" } }}>
      <Helmet>
        <title>Termos - Libertas</title>
      </Helmet>

      <Stack tokens={{ childrenGap: 15 }}>
        <h2 className="ms-fontSize-24">Termos</h2>
        <Card>
          <ListData
            headerAction={undefined}
            headerText="Termos"
            isLoadingList={isLoadingTermsList}
            items={termsList || []}
            columns={buildTermsColumns({
              hasActions: false,
              history
            })}
            menuItems={termsList}
            _paginate={handleAccountRequest}
            filters={buildTermsFilters({
              servicesList: servicesFormatted,
              isLoadingServicesList
            })}
            hasFilters
            isDefaultFilterVisible
          />
        </Card>
      </Stack>
    </Stack>
  );
}

export default Terms;
