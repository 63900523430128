import { Label } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { IDatePickerStrings } from "@fluentui/react/lib/DatePicker";
import { useState } from "react";

import { DatePickerStyled } from "./styles";

import { LabelWithTooltip } from "@/components/LabelWithTooltip";

interface DateFieldProps {
  setFieldValue?: any;
  value?: any;
  name?: any;
  placeholder?: any;
  type: string;
  label: string;
  disabled: boolean;
  errorMessage: any;
  isRequired: boolean;
  minDate?: Date;
  maxDate?: Date;
  tooltipDescription?: string;
  invalidDateErrorMessage?: string;
  id?: string;
}

export function DateField(props: DateFieldProps): JSX.Element {
  const {
    setFieldValue,
    name,
    placeholder,
    minDate,
    maxDate,
    label,
    isRequired,
    tooltipDescription,
    invalidDateErrorMessage,
    id
  } = props;
  const [value, setValue] = useState<Date | null | undefined>(props.value);
  const onSelectDate = (date: Date | null | undefined): void => {
    setValue(date);
    setFieldValue(name, date?.toISOString().split("T")[0]);
  };

  const onFormatDate = (date: Date): string => {
    if (date) {
      return [date.getDate(), date.getMonth() + 1, date.getFullYear()]
        .map(n => (n < 10 ? `0${n}` : `${n}`))
        .join("/");
    } else {
      return "";
    }
  };

  const onParseDateFromString = (val: string): Date => {
    if (val.replace(/\D/g, "").length >= 6) {
      const formatedDate = val
        .match(/\d/g)
        .join("")
        .replace(/^(\d\d)(\d\d)(\d+)$/g, "$1/$2/$3")
        .slice(0, 10);

      const date = value || new Date();
      const values = (formatedDate || "").trim().split("/");
      const day =
        formatedDate.length > 0
          ? Math.max(1, Math.min(31, parseInt(values[0], 10)))
          : date.getDate();
      const month =
        formatedDate.length > 1
          ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1
          : date.getMonth();
      let year =
        formatedDate.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
      if (year < 100) {
        year += date.getFullYear() - (date.getFullYear() % 100);
      }

      return new Date(year, month, day);
    }
  };

  const DayPickerStrings: IDatePickerStrings = {
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],

    shortMonths: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez"
    ],

    days: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado"
    ],

    shortDays: ["D", "S", "T", "Q", "Q", "S", "S"],

    goToToday: "Ir para hoje",
    prevMonthAriaLabel: "Ir para o mês",
    nextMonthAriaLabel: "Ir para o mês",
    prevYearAriaLabel: "Ir para o ano",
    nextYearAriaLabel: "I para o ano",
    closeButtonAriaLabel: "Fechar",

    isRequiredErrorMessage: "Data é obrigatória.",

    invalidInputErrorMessage: "Formato de data inválida.",

    isOutOfBoundsErrorMessage: invalidDateErrorMessage
  };

  return (
    <>
      <LabelWithTooltip
        description={tooltipDescription}
        label={label}
        required={isRequired || false}
      />
      <DatePickerStyled
        id={id}
        allowTextInput={true}
        strings={DayPickerStrings}
        value={value}
        placeholder={placeholder}
        onSelectDate={onSelectDate}
        formatDate={onFormatDate}
        parseDateFromString={onParseDateFromString}
        showGoToToday={false}
        minDate={minDate}
        maxDate={maxDate}
        initialPickerDate={maxDate ? new Date(maxDate) : new Date()}
        isRequired={isRequired || false}
        ariaLabel="Selecione uma data"
      />
    </>
  );
}
