import { AccountActionsTypes } from "../types";

type FunctionsCallback = {
  setIsSubmitting: (isSubmitting: boolean) => void;
  setCreatedAccount?: (data) => void;
  businessActionId: number;
};

export function createAccountRequest(
  values: any,
  { setIsSubmitting, setCreatedAccount, businessActionId }: FunctionsCallback,
  onFinish: (values) => void
) {
  return {
    type: AccountActionsTypes.CREATE_REQUEST,
    payload: {
      values,
      businessActionId,
      setIsSubmitting,
      onFinish,
      setCreatedAccount
    }
  };
}

export function createAccountSuccess(message) {
  return {
    type: AccountActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createAccountFailure(message) {
  return {
    type: AccountActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editAccountRequest(
  values,
  { setIsSubmitting, businessActionId },
  onFinish: (values) => void
) {
  return {
    type: AccountActionsTypes.EDIT_REQUEST,
    payload: { values, setIsSubmitting, businessActionId, onFinish }
  };
}

export function editAccountSuccess(message) {
  return {
    type: AccountActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editAccountFailure(message) {
  return {
    type: AccountActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listAccountRequest(filter?) {
  return {
    type: AccountActionsTypes.LIST_REQUEST,
    payload: { filter }
  };
}

export function listAccountSuccess({ list }) {
  return {
    type: AccountActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listAccountFailure() {
  return {
    type: AccountActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: AccountActionsTypes.DISMISS_MESSAGE
  };
}
