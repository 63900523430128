import { Text } from "@fluentui/react";
import { ReactElement, useState } from "react";

import {
  AccordionContent,
  AccordionSection,
  AccordionTitle,
  TotalValue
} from "./styles";

import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";

interface AccordionProps {
  title: string;
  content: ReactElement;
  compactMode: boolean;
  showValue?: boolean;
  total?: number | string;
  label?: string;
  isCloseDefault?: boolean;
  additionalLabel?: string;
  additionalTotal?: string;
}

const Accordion = ({
  title,
  content,
  compactMode,
  label = "Valor total mensal:",
  showValue = true,
  total = 0,
  isCloseDefault = false,
  additionalLabel,
  additionalTotal
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(!isCloseDefault);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  if (typeof total === "number") {
    total = total.toString();
  }

  const showValues = showValue && total;
  const compactModeStyle = compactMode ? "10px" : "";

  return (
    <AccordionSection style={{ marginLeft: compactModeStyle }}>
      <AccordionTitle onClick={toggleAccordion}>
        <span style={{ fontWeight: "600" }}>{title}</span>
        <span>
          {showValues && (
            <>
              <TotalValue>
                {additionalLabel &&
                additionalTotal &&
                additionalTotal !== "0.00" ? (
                  <>
                    {` ${additionalLabel} `}
                    <strong>
                      {transformToCurrencyString(additionalTotal)}
                    </strong>
                  </>
                ) : null}
                {`${label} `}
                <strong>{transformToCurrencyString(total)}</strong>
              </TotalValue>
            </>
          )}

          <Text
            style={{
              fontSize: "0.8rem",
              letterSpacing: 0.01,
              color: "rgb(51,83,147)"
            }}
          >
            <span>
              {showValues && "|  "} <strong>{isOpen ? "-" : "+"}</strong>{" "}
              {isOpen ? "ver menos" : "ver mais"}
            </span>
          </Text>
        </span>
      </AccordionTitle>

      {isOpen && <AccordionContent>{content}</AccordionContent>}
    </AccordionSection>
  );
};

export default Accordion;
