import { ListOfPlansOffers } from "./PlansOffers.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum PlansOffersActionsTypes {
  DISMISS_MESSAGE = "plans-offers/DISMISS_MESSAGE",
  LIST_REQUEST = "plans-offers/LIST_REQUEST",
  LIST_FAIL = "plans-offers/LIST_FAIL",
  LIST_SUCCESS = "plans-offers/LIST_SUCCESS"
}

export interface PlansOffersState {
  isLoadingList: boolean;
  list?: ListOfPlansOffers;
  message?: IMessageBar;
}

export interface DismissMessage {
  type: PlansOffersActionsTypes.DISMISS_MESSAGE;
}

export interface ListRequest {
  type: PlansOffersActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: PlansOffersActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: PlansOffersActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfPlansOffers;
  };
}

export type PlansOffersReducerActions =
  | DismissMessage
  | ListRequest
  | ListError
  | ListSuccess;
