import { MessageBar } from "@fluentui/react";
import styled from "styled-components";

import { IMessageBarStyled } from "./types";

export const MessageBarStyled = styled(MessageBar)<IMessageBarStyled>`
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);

  padding: 5px;
  width: auto;
  max-width: 500px;
  border-radius: 10px;

  z-index: 1000;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

  background-color: ${props => props.bgColor};

  & > div {
    display: flex;
    align-items: center;
    & > div {
      font-size: 1rem;
      text-align: center;
      font-weight: 500;
      color: ${props => props.textColor};
    }

    & > .ms-MessageBar-dismissSingleLine > button {
      display: flex;
    }
  }
`;
