import { IColumn, Toggle, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

import { IItemQuantities } from "./types";

import { IItem, RowActionsButton } from "@/components/RowActionsButton";
import { IAdditionalOfferPrice } from "@/components/Settings/AdditionalComponents/types";

interface ColumnsProps {
  isChecked: (item: IAdditionalOfferPrice) => boolean;
  toggleSelection: (item: IAdditionalOfferPrice) => void;
  actionMenu: (id: number) => IItem[];
  canEdit: boolean;
  handleQuantityChange: (itemId: number, quantity: string) => void;
  itemQuantities: IItemQuantities[];
}

interface TooltipProps {
  description: string;
  name: string;
}

const Tooltip = ({ description, name }: TooltipProps) => {
  const tooltipId = useId("tooltip");

  return (
    <TooltipHost
      content={description}
      calloutProps={{ gapSpace: 0 }}
      id={tooltipId}
      styles={{
        root: {
          display: "flex",
          height: "100%",
          marginTop: "1px"
        }
      }}
    >
      <p
        style={{
          marginBottom: "0px",
          marginLeft: "2px"
        }}
      >
        {name}
      </p>
    </TooltipHost>
  );
};

export const buildColumns = ({
  isChecked: isChecked,
  toggleSelection,
  actionMenu,
  canEdit,
  handleQuantityChange,
  itemQuantities
}: ColumnsProps) => {
  const columns: IColumn[] = [
    {
      key: "qnt",
      name: "Quantidade",
      minWidth: 50,
      maxWidth: 50,
      onRender: item => {
        const isDisabled = item.maxQnt === 1 || !isChecked(item);
        const qtdCss: React.CSSProperties = {
          border: "none",
          width: "100%",
          padding: "5px",
          fontSize: "16px",
          outline: "none",
          textAlign: "center",
          cursor: "pointer"
        };
        if (!isDisabled)
          Object.assign(qtdCss, {
            ...qtdCss,
            border: "1px solidrgb(51, 83, 147)"
          });
        return (
          <input
            type="number"
            style={qtdCss}
            value={
              itemQuantities
                ? itemQuantities.find(i => i.itemId === item.id)?.quantity || 1
                : 1
            }
            min={1}
            max={item.maxQnt}
            disabled={isDisabled}
            onChange={e => handleQuantityChange(item.id, e.target.value)}
          />
        );
      }
    },
    {
      key: "add",
      name: "Adicionado",
      minWidth: 70,
      onRender: item => (
        <Toggle
          id={`switch-item-${item.id}`}
          checked={isChecked(item)}
          disabled={canEdit ? false : item.additionalOffer.required}
          onChange={() => toggleSelection(item)}
          onText={"Sim"}
          offText={"Não"}
        />
      )
    },

    {
      key: "name",
      name: "Nome",
      fieldName: "name",
      isResizable: true,
      minWidth: 140,
      onRender: item => (
        <div
          title={item.additionalOffer.name}
          style={{
            textAlign: "center",
            scrollbarWidth: "thin",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Tooltip
            description={item?.additionalOffer?.description}
            name={item?.additionalOffer?.name}
          />
        </div>
      )
    },
    {
      key: "activationValue",
      name: "Valor de Ativação",
      fieldName: "activationValue",
      minWidth: 100,
      onRender: item => (
        <p style={{ textAlign: "center" }}>R$ {item.activationValue}</p>
      )
    },
    {
      key: "monthlyFee",
      name: "Valor Mensal",
      fieldName: "monthlyFee",
      minWidth: 100,
      maxWidth: 100,
      onRender: item => (
        <p style={{ margin: "auto", width: "100%", textAlign: "center" }}>
          R$ {item.monthlyFee}
        </p>
      )
    },
    {
      key: "bonus",
      name: "Bônus",
      fieldName: "bonus",
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: "productImage",
      name: "Benefício combo",
      fieldName: "productImage",
      isRowHeader: true,
      minWidth: 50,
      maxWidth: 100,
      isPadded: true,
      onRender: item => {
        const imageUrl = item?.additionalOffer?.product?.productImage?.url;
        const altText = item?.additionalOffer?.product?.name;
        return imageUrl ? (
          <img
            src={imageUrl}
            alt={altText}
            title={altText}
            style={{ height: "50px" }}
          />
        ) : undefined;
      }
    }
  ];

  if (canEdit) {
    columns.push({
      key: "action",
      name: "Ação",
      fieldName: "action",
      isRowHeader: true,
      minWidth: 10,
      maxWidth: 10,
      isPadded: true,
      onRender: item => RowActionsButton({ items: actionMenu, id: item.id })
    });
  }

  return columns;
};
