import { ListOfLinesOccurrences } from "./LinesOccurrences.types";

export enum LinesOccurrencesActionsTypes {
  LIST_REQUEST = "linesOccurrences/LIST_REQUEST",
  LIST_FAIL = "linesOccurrences/LIST_FAIL",
  LIST_SUCCESS = "linesOccurrences/LIST_SUCCESS"
}

export interface LinesOccurrencesState {
  isLoadingList: boolean;
  list: ListOfLinesOccurrences;
}

export interface ListRequest {
  type: LinesOccurrencesActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: LinesOccurrencesActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: LinesOccurrencesActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfLinesOccurrences;
  };
}

export type LinesOccurrencesReducerActions =
  | ListRequest
  | ListError
  | ListSuccess;
