import {
  DetailsList,
  DetailsRow,
  DirectionalHint,
  Label,
  PrimaryButton,
  SelectionMode,
  Text,
  TooltipHost,
  VerticalDivider
} from "@fluentui/react";
import { Modal } from "@fluentui/react/lib/Modal";
import { useCallback, useEffect, useState } from "react";

import { buildColumns } from "./additional.columns";
import { AdditionalOfferPriceToAppendProps, IItemQuantities } from "./types";

import { AdditionalInputs } from "@/components/Settings/AdditionalComponents/AdditionalInputs";
import { IAdditionalOffer } from "@/components/Settings/AdditionalComponents/types";
import { MessageBar } from "@/core/libs/message-bar";
import { AdditionalCategoryTypes } from "@/modules/Settings/pages/AdditionalCategory/enum";
import {
  additionalSchemaBonus,
  additionalSchemaDefault,
  additionalSchemaProduct
} from "@/modules/Settings/pages/Offers/OffersCreate/offer.schemas";
import { parseStringToFloatDoubleFixed } from "@/utils/ParseStringToFloatDoubleFixed";
import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";

const customStyles = {
  main: {
    padding: "40px"
  }
};

const verticalDividerStyles = {
  divider: {
    border: "solid 0.5px #cecece",
    height: "120px"
  }
};

export const AdditionalOfferPriceToAppend = ({
  isModalOpen,
  setIsModalOpen,
  submitMessage,
  clearSubmitMessage,
  submit,
  additionalOfferPriceList,
  canEdit,
  planPrice
}: AdditionalOfferPriceToAppendProps) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedAdditionalOffer, setSelectedAdditionalOffer] =
    useState<IAdditionalOffer>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };

  const [itemQuantities, setItemQuantities] = useState<IItemQuantities[]>();
  const handleQuantityChange = useCallback((itemId, quantity) => {
    setItemQuantities([
      {
        itemId,
        quantity
      }
    ]);
  }, []);

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    if (additionalOfferPriceList.length > 0) {
      const itemsList = [...additionalOfferPriceList];

      itemsList.sort(function (x, y) {
        return x.additionalOffer.required === y.additionalOffer.required
          ? 0
          : x.additionalOffer.required
          ? -1
          : 1;
      });

      setItems(itemsList);
      const defaultCheckedList = [];

      additionalOfferPriceList.forEach(additional => {
        const ado = additional.additionalOffer;
        const additionalOfferMustBeChecked = ado.required || ado.defaultChecked;

        if (additionalOfferMustBeChecked) {
          defaultCheckedList.push(additional);
        }
      });

      setSelectedItems(defaultCheckedList);
    }
  }, [additionalOfferPriceList]);

  const isChecked = item => {
    const itemFound = selectedItems.find(
      additional => additional.id === item.id
    );

    if (itemFound) {
      return true;
    }

    return false;
  };

  const getItemQuantities = (itemQuantities, additional) => {
    if (itemQuantities === undefined) {
      return 1;
    }
    return itemQuantities.find(i => i.itemId === additional.id)?.quantity || 1;
  };

  const toggleSelection = useCallback(
    item => {
      const list = [...selectedItems];
      const id = item.id;
      const requiredItem = item.additionalOffer.required;
      const uniqueItem =
        item.additionalOffer.additional.additionalCategory.isUnique;
      const itemFound = list.find(additional => additional.id === id);

      if (!requiredItem || canEdit) {
        if (itemFound) {
          const indexToRemove = list.findIndex(
            additional => additional.id === id
          );
          list.splice(indexToRemove, 1);

          if (uniqueItem) {
            const defaultCheckedOption = items.find(
              item => item?.additionalOffer?.defaultChecked
            );

            if (defaultCheckedOption) {
              setSelectedItems([...list, defaultCheckedOption]);
            }
            return;
          }

          setSelectedItems(list);
        } else {
          if (uniqueItem) {
            const excludesCategory = list.filter(
              l =>
                item.additionalOffer.additional.additionalCategory.id !==
                l.additionalOffer.additional.additionalCategory.id
            );

            setSelectedItems([...excludesCategory, item]);
            return;
          }

          list.push(item);
          setSelectedItems(list);
        }
      }
    },
    [canEdit, selectedItems]
  );

  const additionalFeeForRequiredItems = items
    .filter(item => item?.additionalOffer?.required)
    .reduce((sum, item) => sum + +item?.monthlyFee?.replace(",", "."), 0);

  const formatToSend = useCallback(() => {
    const formattedList = [];
    selectedItems.forEach(additional => {
      formattedList.push({
        monthlyFeeOverride: +additional.monthlyFee.replace(",", "."),
        activationValueOverride: +additional.activationValue.replace(",", "."),
        bonusOverride: additional.bonus,
        additionalOfferPriceId: additional.id,
        qnt: getItemQuantities(itemQuantities, additional)
      });
    });

    return formattedList;
  }, [selectedItems, itemQuantities]);

  const editAdditionalOffer = useCallback(
    (id: number) => {
      const item = items.find(obj => obj.id === id);

      setSelectedAdditionalOffer({
        id: item.id,
        name: item.additionalOffer.name,
        required: item.additionalOffer.required,
        activationValue: item.activationValue,
        monthlyFee: item.monthlyFee,
        bonus: item.bonus,
        additionalId: item.additionalOffer.additionalId,
        productId: item.productId,
        additional: item.additionalOffer,
        status: item.status,
        additionalOfferId: item.additionalOffer.id,
        defaultChecked: item.additionalOffer.defaultChecked
      });
      handleOpenEditModal();
    },
    [items]
  );

  const optionsAction = useCallback(
    id => [
      {
        key: "item2",
        name: "Editar",
        onClick: () => editAdditionalOffer(id)
      }
    ],
    [editAdditionalOffer]
  );

  const calculateTotalFee = useCallback(() => {
    const { totalActivationValue, totalMonthlyFee } = selectedItems.reduce(
      (totals, item) => {
        const itemMonthlyFee = +item.monthlyFee.replace(",", ".");
        const itemQuantity =
          itemQuantities?.find(i => i.itemId === item.id)?.quantity ?? 1;

        const itemActivationValue = +item.activationValue.replace(",", ".");
        totals.totalActivationValue += itemActivationValue * itemQuantity;
        totals.totalMonthlyFee += itemMonthlyFee * itemQuantity;
        return totals;
      },
      { totalActivationValue: 0, totalMonthlyFee: 0 }
    );

    const planTotalPrice = planPrice + totalMonthlyFee;

    return { totalActivationValue, totalMonthlyFee, planTotalPrice };
  }, [selectedItems, planPrice, itemQuantities]);

  const handleEdit = (additionalOffer: IAdditionalOffer) => {
    const list = [...items];
    const index = items.findIndex(objeto => objeto.id === additionalOffer.id);
    list[index] = {
      ...additionalOffer,
      additionalOffer: selectedAdditionalOffer.additional
    };
    setItems(list);
    setIsEditModalOpen(false);
    setSelectedAdditionalOffer({} as IAdditionalOffer);
    setSelectedItems(prevState =>
      prevState?.map(selectedItem => {
        if (selectedItem?.id === additionalOffer.id) return additionalOffer;
        else return selectedItem;
      })
    );
  };

  const onSubmit = () => {
    const additionalOfferPrice = formatToSend();
    submit(additionalOfferPrice);
  };

  const schema =
    selectedAdditionalOffer?.additional?.additionalCategory?.type ===
    AdditionalCategoryTypes.SERVICE_BONUS
      ? additionalSchemaBonus
      : selectedAdditionalOffer?.additional?.additionalCategory?.type ===
        AdditionalCategoryTypes.PRODUCT
      ? additionalSchemaProduct
      : additionalSchemaDefault;

  return (
    <>
      <Modal
        titleAriaId={"append-additional-offer-modal"}
        isOpen={isModalOpen}
        onDismiss={handleCloseModal}
        styles={customStyles}
      >
        <Text variant={"large"}>
          Atividades de valor agregado para esse benefício:
        </Text>
        {items.length > 0 && (
          <DetailsList
            items={items.map(i => ({
              ...i,
              monthlyFee: parseStringToFloatDoubleFixed(i.monthlyFee),
              activationValue: parseStringToFloatDoubleFixed(i.activationValue)
            }))}
            columns={buildColumns({
              isChecked: isChecked,
              toggleSelection,
              canEdit,
              actionMenu: optionsAction,
              handleQuantityChange,
              itemQuantities
            })}
            selectionMode={SelectionMode.none}
            onRenderRow={props => {
              props.styles = { root: { cursor: "default" } };
              const title = props.item.additionalOffer?.required
                ? "Obrigatório"
                : "";
              return (
                <TooltipHost
                  content={title}
                  directionalHint={DirectionalHint.rightTopEdge}
                >
                  <DetailsRow {...props} />
                </TooltipHost>
              );
            }}
            selectionPreservedOnEmptyClick={true}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
            gap: "30px"
          }}
        >
          <div>
            <p
              style={{
                color: "#395CA3",
                fontSize: "2.5rem",
                margin: "0px"
              }}
            >
              {transformToCurrencyString(
                calculateTotalFee().totalActivationValue
              )}
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "0.7rem"
              }}
            >
              Valor de ativação <br /> das atividades de valor agregado
            </p>
          </div>
          <VerticalDivider styles={verticalDividerStyles} />
          <div>
            <p
              style={{
                color: "#395CA3",
                fontSize: "2.5rem",
                margin: "0px"
              }}
            >
              {transformToCurrencyString(calculateTotalFee().planTotalPrice)}
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "0.7rem"
              }}
            >
              Valor total mensal <br /> do pacote de benefício
            </p>
          </div>
          <VerticalDivider styles={verticalDividerStyles} />
          <div>
            <p
              style={{
                color: "#395CA3",
                fontSize: "2.5rem",
                margin: "0px"
              }}
            >
              {transformToCurrencyString(calculateTotalFee().totalMonthlyFee)}
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "0.7rem"
              }}
            >
              Valor mensal <br /> das atividades de valor agregado
            </p>
          </div>
          <VerticalDivider styles={verticalDividerStyles} />
          <div>
            <p
              style={{
                color: "#395CA3",
                fontSize: "2.5rem",
                margin: "0px"
              }}
            >
              {transformToCurrencyString(additionalFeeForRequiredItems)}
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "0.7rem"
              }}
            >
              Adquira o <br /> plano a partir de:
            </p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "25px"
          }}
        >
          <PrimaryButton
            style={{ padding: "20px" }}
            type="secondary"
            onClick={onSubmit}
          >
            Continuar
          </PrimaryButton>
        </div>
        {submitMessage && (
          <div style={{ marginTop: 20, width: "100%" }}>
            <MessageBar
              message={submitMessage}
              dismissMessage={clearSubmitMessage}
            />
          </div>
        )}
      </Modal>
      <Modal
        titleAriaId={"append-additional-offer-modal"}
        isOpen={isEditModalOpen}
        onDismiss={handleCloseEditModal}
        styles={customStyles}
      >
        <div style={{ width: "300px" }}>
          <Label>Editar adicional</Label>
          <AdditionalInputs
            initialValues={selectedAdditionalOffer}
            schema={schema}
            setValue={handleEdit}
            buttonText="Editar"
            onlyValues={true}
          />
        </div>
      </Modal>
    </>
  );
};
