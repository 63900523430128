import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createFailure,
  createSuccess,
  editFailure,
  editSuccess,
  listFailure,
  listRequest,
  listSuccess
} from "../actions/productsBrands";
import { ProductsBrandsActionsTypes } from "../types/ProductsBrands.reducer.types";

import { SagaCustomError } from "@/common/types";
import { ProductsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createProductBrand({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ProductsService("/brands", isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createSuccess({
        message: `Marca criada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, isAdmin));
  } catch (error) {
    yield put(
      createFailure({
        code: error?.statusCode,
        message: "Não foi possível criar a marca com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editProductBrand({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ProductsService("/brands", isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editSuccess({
        message: `Marca editada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, isAdmin));
  } catch (error) {
    yield put(
      editFailure({
        code: error?.statusCode,
        message: "Não foi possível editar a marca com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listProductsBrands({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = ProductsService("/brands", isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listSuccess({ list: response.data }));
  } catch (error) {
    yield put(listFailure());
  }
}

export default all([
  takeLatest(ProductsBrandsActionsTypes.CREATE_REQUEST, createProductBrand),
  takeLatest(ProductsBrandsActionsTypes.EDIT_REQUEST, editProductBrand),
  takeLatest(ProductsBrandsActionsTypes.LIST_REQUEST, listProductsBrands)
]);
