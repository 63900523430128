import { getTheme, Stack } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const CarrierLogo = styled.img`
  height: 50px;
  margin: 20px 0;
`;

export const TagContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  background: ${theme.palette.neutralLighterAlt};
  overflow-y: auto;

  * {
    box-sizing: border-box;
  }
`;

export const TagOfferContainer = styled(Stack)`
  background: white;
  min-width: 250px;
  max-width: 350px;
  overflow-y: auto;
  height: 100%;
  & > div {
    width: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  @media (max-height: 560px) {
    justify-content: initial;
  }
`;

export const TagPlanTitle = styled.h1`
  text-transform: uppercase;
  font-size: 18px;
  color: ${theme.palette.neutralTertiary};
  padding: 10px 0 0 0;
`;

export const TagPlanName = styled.p`
  font-size: 20px;
  color: ${theme.palette.black};
  font-weight: 600;
  padding: 10px 0;
  text-align: center;
`;

export const TagPlanText = styled.p<{ fontSize: string }>`
  font-size: ${props => props.fontSize};
  color: ${theme.palette.themeSecondary};
`;
