import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import RoutesLayout from "./RoutesLayout";

import { useAuth } from "@/core/libs/use-auth";
import { useOrganization } from "@/hooks/useOrganization";
import AppAuth from "@/modules/Auth";

function Routes(): JSX.Element {
  const { isAuthenticated, isChecking } = useAuth();
  const location = useLocation();
  const { clearOrganization } = useOrganization();

  useEffect(() => {
    const loginLocations = ["/login", "/recover", "/set-password"];

    if (loginLocations.includes(location.pathname)) {
      window.fcWidget?.destroy();
      clearOrganization();
    }
  }, [location]);

  return !isAuthenticated ? !isChecking && <AppAuth /> : <RoutesLayout />;
}

export default Routes;
