import { Stack } from "@fluentui/react";
import { useState } from "react";
import { Helmet } from "react-helmet";

import { Loader } from "@/components/Shared/Loader";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import { useSelectedBu } from "@/hooks/useSelectedBu";

function News(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);

  const { selectedBU } = useSelectedBu();

  return (
    <Stack
      tokens={{ childrenGap: 15 }}
      verticalFill
      style={{ position: "relative" }}
    >
      <Helmet>
        <title>Notícias - Libertas</title>
      </Helmet>

      {isLoading ? (
        <Loader customMessage="Carregando Notícias" />
      ) : (
        <h2 className="ms-fontSize-24 ms-fontWeight-semibold">Notícias</h2>
      )}

      {selectedBU?.settings?.newsLink && (
        <iframe
          src={selectedBU?.settings.newsLink}
          title="Notícias"
          style={{ width: `100%`, height: `calc(100% - 10px)` }}
          onLoad={() => setIsLoading(false)}
        />
      )}
    </Stack>
  );
}

export default News;
