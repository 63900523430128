import { Stack } from "@fluentui/react";
import { useCallback, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import undrawDataProcessing from "@/assets/images/undrawDataProcessing.svg";
import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import { BannersOffers } from "@/components/Offers/BannersOffers";
import { PlansOffers } from "@/components/Offers/PlansOffers";
import { ProductsOffers } from "@/components/Offers/ProductOffers";
import TagOffers from "@/components/Offers/TagOffers";
import { FadeInStack } from "@/components/Shared/FadeInStack";
import { Loader } from "@/components/Shared/Loader";
import { useGetBanners } from "@/core/libs/api/react-query/hook/use-get-banners";
import { useGetPabx } from "@/core/libs/api/react-query/hook/use-get-pabx";
import {
  useGetDashboardProducts,
  useGetPlans,
  useGetProfile,
  useGetTags
} from "@/core/libs/api/react-query/index";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { listRequest as listProductsOffersRequest } from "@/modules/Offers/actions/productsOffers";

function Offers(): JSX.Element {
  const isInitialRender = useRef(true);
  const userProfile = useGetProfile();
  const { selectedBU } = useSelectedBu();
  const permissions = userProfile.data?.data?.role.permissions;

  const { listOfBanners, isLoadingBannersList } = useGetBanners();
  const { listOfTags, isLoadingTagOffersList } = useGetTags();
  const { listOfPlansOffers, isLoadingPlansOffersList } = useGetPlans();
  const { listOfPabxOffers, isLoadingPabxOffersList } = useGetPabx();

  const {
    productsOffersList,
    productsBrands,
    productsCarriers,
    productsCategories,
    isLoadingProductsOffersList
  } = useGetDashboardProducts();

  const canReadDashboardTags = permissions[PermissionsScope.POS]?.[
    PermissionsPosModule.DASHBOARD_TAGS
  ]?.some(
    (dashboardTagsAction: PermissionsAction) =>
      dashboardTagsAction === PermissionsAction.ALL ||
      dashboardTagsAction === PermissionsAction.READ
  );

  const dispatch = useDispatch();

  const requestListOfProductOffers = useCallback(
    (filters?) => {
      dispatch(
        listProductsOffersRequest({
          businessActionId: selectedBU?.id,
          ...filters
        })
      );
    },
    [dispatch, selectedBU]
  );

  useEffect(() => {
    if (isInitialRender.current && productsOffersList) {
      isInitialRender.current = false;
    }
  }, [productsOffersList]);

  useEffect(() => {
    if (!isInitialRender.current) {
      isInitialRender.current = true;
    }
  }, [selectedBU]);

  return (
    <Stack
      styles={{ root: { width: "100%", height: "100%", minHeight: "100%" } }}
    >
      <Helmet>
        <title>Pacotes de Benefícios - Libertas</title>
      </Helmet>
      <Stack
        tokens={{ childrenGap: 15 }}
        styles={{ root: { minHeight: "100%" } }}
      >
        {isLoadingBannersList ? (
          <Stack.Item
            shrink={0}
            styles={{ root: { position: "relative", height: 200 } }}
          >
            <Loader customMessage="Carregando banners" />
          </Stack.Item>
        ) : (
          listOfBanners?.length > 0 && (
            <FadeInStack>
              <BannersOffers banners={listOfBanners} />
            </FadeInStack>
          )
        )}

        {(isLoadingTagOffersList || isLoadingPabxOffersList) &&
        canReadDashboardTags ? (
          <Stack.Item shrink={0} styles={{ root: { height: 200 } }}>
            <Loader customMessage="Carregando pacotes de benefícios Tag e PABX" />
          </Stack.Item>
        ) : (
          <>
            <h1
              className="ms-fontWeight-semibold ms-fontSize-24"
              style={{ paddingTop: 10, margin: 0 }}
            >
              Pacote de Benefícios Tag e Conectividade Fixa
            </h1>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "5rem",
                margin: 0
              }}
            >
              {listOfTags && (
                <FadeInStack>
                  <TagOffers tags={listOfTags} />
                </FadeInStack>
              )}
              {listOfPabxOffers && (
                <FadeInStack>
                  <PlansOffers
                    plans={listOfPabxOffers}
                    isLoadingList={isLoadingPabxOffersList}
                  />
                </FadeInStack>
              )}
            </div>
          </>
        )}

        {isLoadingPlansOffersList ? (
          <Stack.Item
            shrink={0}
            styles={{ root: { position: "relative", height: 300 } }}
          >
            <Loader customMessage="Carregando pacote de benefícios somente chip" />
          </Stack.Item>
        ) : (
          listOfPlansOffers?.length > 0 && (
            <FadeInStack>
              <PlansOffers
                plans={listOfPlansOffers}
                isLoadingList={isLoadingPlansOffersList}
                title="Pacote de Benefícios Móvel"
              />
            </FadeInStack>
          )
        )}

        {isLoadingProductsOffersList && isInitialRender.current ? (
          <Stack.Item
            shrink={0}
            styles={{
              root: { position: "relative", height: 400 }
            }}
          >
            <Loader customMessage="Carregando combos" />
          </Stack.Item>
        ) : (
          productsOffersList?.products?.items?.length > 0 && (
            <FadeInStack>
              <ProductsOffers
                products={productsOffersList?.products}
                productsBrands={productsBrands}
                productsCarriers={productsCarriers}
                productsCategories={productsCategories}
                isLoadingList={isLoadingProductsOffersList}
                requestListOfProductOffers={requestListOfProductOffers}
              />
            </FadeInStack>
          )
        )}

        {(listOfBanners?.length < 1 ||
          (!listOfBanners && !isLoadingBannersList)) &&
          (listOfPlansOffers?.length < 1 ||
            (!listOfPlansOffers && !isLoadingPlansOffersList)) &&
          (productsOffersList?.products?.items?.length < 1 ||
            (!productsOffersList && !isLoadingProductsOffersList)) && (
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              styles={{ root: { height: "100%" } }}
            >
              <img
                src={undrawDataProcessing}
                style={{
                  userSelect: "none",
                  width: "50vw",
                  height: "50vh"
                }}
                draggable="false"
                alt="Nenhum benefício encontrado"
                title="Sem benefícios para essa unidade de negócio."
              />
            </Stack>
          )}
      </Stack>
    </Stack>
  );
}

export default Offers;
