import { AuthService } from "../../api";
import { messageBarTypes } from "../../message-bar";

import {
  actionTypes,
  AuthAction,
  AuthState,
  CheckAuthenticationAction,
  ILogoutAction
} from "./types";

import { clearAuthKey, retrieveAuthKey } from "@/core/libs/api/utils/auth";

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case actionTypes.CHECK:
      return {
        ...state,
        isChecking: true
      };

    case actionTypes.CHECK_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isChecking: false
      };

    case actionTypes.CHECK_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isChecking: false
      };

    case actionTypes.DISMISS_MESSAGE:
      return {
        ...state,
        message: undefined
      };

    case actionTypes.LOGIN:
      return {
        ...state,
        message: undefined
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false
      };

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        message: undefined
      };

    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        message: action.message
      };

    default:
      return state;
  }
};

export const handleLogin = async ({ dispatch, data }) => {
  dispatch({
    type: actionTypes.LOGIN
  });
  localStorage.removeItem("selectedBU");
  const abortController = new AbortController();
  const { signal } = abortController;
  const service = AuthService();
  const result = await service.login({ params: { body: data }, signal });
  if (result.error) {
    dispatch({
      type: actionTypes.LOGIN_FAIL,
      message: {
        code: result.error.status,
        message: result.error.message,
        type:
          result?.error?.status?.toString().charAt(0) === "5"
            ? messageBarTypes.ERROR
            : messageBarTypes.WARNING
      }
    });
  }
  return result;
};

export const checkAuthentication: CheckAuthenticationAction = async ({
  dispatch
}) => {
  dispatch({
    type: actionTypes.CHECK
  });

  const authKey = retrieveAuthKey();

  if (!authKey) {
    dispatch({
      type: actionTypes.CHECK_FAIL
    });

    clearAuthKey();
    localStorage.removeItem("selectedBU");
  } else {
    dispatch({
      type: actionTypes.CHECK_SUCCESS
    });
  }

  return !!authKey;
};

export const dismissMessageAction = async ({ dispatch }) => {
  dispatch({
    type: actionTypes.DISMISS_MESSAGE
  });
};

export const logoutAction: ILogoutAction = async ({ dispatch }) => {
  dispatch({
    type: actionTypes.LOGOUT
  });

  clearAuthKey();
  localStorage.removeItem("selectedBU");
};
