import { DetailsRow, IDetailsRowProps } from "@fluentui/react";

import Accordion from "../Accordion";
import AccordionContent from "../AccordionContent";
import ItemReplacementRow from "../ItemReplacement/ItemReplacementRow";

import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";

type AccordionContractDetailRowType = {
  rowProps?: IDetailsRowProps;
  isCompactMode: boolean;
  refetchContractData: () => Promise<void>;
  items?: unknown;
};

function AccordionContractDetailRow({
  rowProps,
  isCompactMode,
  refetchContractData
}: AccordionContractDetailRowType) {
  const item = rowProps?.item;
  const additionalContractItems = item?.additionalContractItems;
  const simValue = item?.offerPrice?.offer?.simValue;
  const offerOverride = item?.offerOverride;
  const offerPrice = item?.offerPrice;

  const offerDiscount = Number(
    offerOverride?.discount
      ? offerOverride?.discount
      : offerPrice?.offer?.discount?.value || 0
  );

  const valueWithDiscount =
    Number(offerOverride?.price || offerPrice?.price) - offerDiscount;

  const offerValues = {
    valueWithDiscount,
    price: Number(offerPrice?.price),
    installment: item?.additionalInfo?.installment ?? 0,
    productCashValue: item?.saleCondition?.cashValue ?? 0,
    productFinancedValue: item?.saleCondition?.financedValue ?? 0
  };

  const inventoryExists = Boolean(item?.inventory?.length);

  const additionalContractItemsExists = Boolean(
    item?.additionalContractItems?.length
  );

  const totalPlan = offerValues?.valueWithDiscount;
  const totalProduct = offerValues?.productCashValue;

  return (
    <>
      <DetailsRow
        {...rowProps}
        styles={
          (item?.approvalFlow?.steps.length > 0 ||
            item?.children?.length > 0) && {
            root: { borderBottom: "none" }
          }
        }
      />
      {(item?.approvalFlow?.steps.length > 0 || item?.children?.length > 0) && (
        <ItemReplacementRow
          item={item}
          isCompactMode={isCompactMode}
          onSuccess={() => refetchContractData()}
        />
      )}

      <Accordion
        title="Detalhes dos itens"
        showValue={false}
        isCloseDefault={true}
        content={
          <>
            {simValue && simValue !== "0" && (
              <Accordion
                isCloseDefault
                title="Chip"
                label="Valor da ativação do chip:"
                total={simValue ?? 0}
                content={
                  <AccordionContent
                    items={[
                      {
                        label: "Valor",
                        value: transformToCurrencyString(simValue ?? 0)
                      }
                    ]}
                  />
                }
                compactMode={isCompactMode}
              />
            )}
            <Accordion
              isCloseDefault
              title="Plano de benefícios"
              total={totalPlan}
              content={
                <AccordionContent
                  items={[
                    {
                      label: "Valor total com desconto",
                      value: `${transformToCurrencyString(
                        offerValues.valueWithDiscount
                      )} = ${transformToCurrencyString(
                        offerOverride?.price || offerPrice?.price
                      )} - ${transformToCurrencyString(offerDiscount)}`,
                      isStrong: true
                    },
                    {
                      label: "Valor mensal",
                      value: transformToCurrencyString(
                        offerOverride?.price || offerPrice?.price
                      )
                    }
                  ]}
                />
              }
              compactMode={isCompactMode}
            />

            {inventoryExists && (
              <Accordion
                isCloseDefault
                title={
                  item?.inventory.map(
                    inventory => inventory.product.category.code
                  )[0] === "TAG"
                    ? "Tag"
                    : "Aparelho"
                }
                total={totalProduct}
                content={
                  <AccordionContent
                    items={[
                      {
                        label: "Valor à vista",
                        value: transformToCurrencyString(
                          offerValues.productCashValue
                        )
                      },
                      {
                        label: "Valor à prazo",
                        value: transformToCurrencyString(
                          offerValues.productFinancedValue
                        )
                      },
                      {
                        label: "Quantidade de parcelas",
                        value: offerValues.installment
                      }
                    ]}
                  />
                }
                compactMode={isCompactMode}
              />
            )}
          </>
        }
        compactMode={isCompactMode}
      />

      {additionalContractItemsExists && (
        <Accordion
          showValue={false}
          isCloseDefault={true}
          title="Detalhes dos atividades de valor agregado"
          content={
            <>
              {additionalContractItems.map((additionalContract, key) => {
                const additionalDetailsItems = [
                  {
                    label: "Valor de ativação",
                    value: transformToCurrencyString(
                      additionalContract?.activationValueOverride
                    )
                  },
                  {
                    label: "Valor mensal",
                    value: transformToCurrencyString(
                      additionalContract?.monthlyFeeOverride
                    )
                  }
                ];
                additionalContract?.bonusOverride &&
                  additionalDetailsItems.push({
                    label: "Bônus",
                    value: transformToCurrencyString(
                      additionalContract?.bonusOverride
                    )
                  });
                return (
                  <Accordion
                    isCloseDefault
                    key={key}
                    total={additionalContract.monthlyFeeOverride}
                    showValue={true}
                    additionalLabel={
                      additionalContract?.activationValueOverride
                        ? "Valor de Ativação"
                        : null
                    }
                    additionalTotal={
                      additionalContract?.activationValueOverride
                    }
                    title={
                      additionalContract?.additionalOfferPrice?.additionalOffer
                        ?.name
                    }
                    content={
                      <AccordionContent items={additionalDetailsItems} />
                    }
                    compactMode={isCompactMode}
                  />
                );
              })}
            </>
          }
          compactMode={isCompactMode}
        ></Accordion>
      )}
    </>
  );
}

export default AccordionContractDetailRow;
