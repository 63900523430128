import produce from "immer";

import {
  ProductsOffersActionsTypes,
  ProductsOffersReducerActions,
  ProductsOffersState
} from "../types/ProductsOffers.reducer.types";

const INITIAL_STATE: ProductsOffersState = {
  list: undefined,
  productsBrands: undefined,
  productsCategories: undefined,
  productsCarriers: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ProductsOffersReducerActions
): ProductsOffersState {
  return produce(state, draft => {
    switch (action.type) {
      case ProductsOffersActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ProductsOffersActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case ProductsOffersActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case ProductsOffersActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        draft.productsBrands = action.payload.list.productsBrands;
        draft.productsCategories = action.payload.list.productsCategories;
        draft.productsCarriers = action.payload.list.carriers;
        break;
      }
      default:
    }
  });
}
