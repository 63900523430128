import produce from "immer";

import {
  ServicesActionsTypes,
  ServicesReducerActions,
  ServicesState
} from "../types/Services.reducer.types";

const INITIAL_STATE: ServicesState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ServicesReducerActions
): ServicesState {
  return produce(state, draft => {
    switch (action.type) {
      case ServicesActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ServicesActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ServicesActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ServicesActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ServicesActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ServicesActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ServicesActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ServicesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case ServicesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case ServicesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }

      default:
    }
  });
}
