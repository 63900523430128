import { SalesTeamsActionsTypes } from "../types/SalesTeams.reducer.types";

export function createSalesTeamsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: SalesTeamsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createSalesTeamsSuccess(message) {
  return {
    type: SalesTeamsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createSalesTeamsFailure(message) {
  return {
    type: SalesTeamsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editSalesTeamsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: SalesTeamsActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editSalesTeamsSuccess(message) {
  return {
    type: SalesTeamsActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editSalesTeamsFailure(message) {
  return {
    type: SalesTeamsActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listSalesTeamsRequest(filter, { isAdmin = false }) {
  return {
    type: SalesTeamsActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listSalesTeamsSuccess({ list }) {
  return {
    type: SalesTeamsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listSalesTeamsFailure() {
  return {
    type: SalesTeamsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: SalesTeamsActionsTypes.DISMISS_MESSAGE
  };
}
