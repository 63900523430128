import { ReportsActionsTypes } from "../types/Reports.reducer.types ";

export function createReportsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ReportsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createReportsSuccess(message) {
  return {
    type: ReportsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createReportsFailure(message) {
  return {
    type: ReportsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function listReportsRequest(filter, { isAdmin = false }) {
  return {
    type: ReportsActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listReportsSuccess({ list }) {
  return {
    type: ReportsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listReportsFailure() {
  return {
    type: ReportsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ReportsActionsTypes.DISMISS_MESSAGE
  };
}
