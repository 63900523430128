import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createSalesTeamsFailure,
  createSalesTeamsSuccess,
  editSalesTeamsFailure,
  editSalesTeamsSuccess,
  listSalesTeamsFailure,
  listSalesTeamsRequest,
  listSalesTeamsSuccess
} from "../actions/salesTeams";
import { SalesTeamsActionsTypes } from "../types/SalesTeams.reducer.types";

import { SagaCustomError } from "@/common/types";
import { SalesTeamsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createSalesTeams({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = SalesTeamsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createSalesTeamsSuccess({
        message: `Time comercial criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listSalesTeamsRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      createSalesTeamsFailure({
        code: error?.statusCode,
        message:
          "Não foi possível criar o time comercial com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editSalesTeams({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = SalesTeamsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editSalesTeamsSuccess({
        message: `Time comercial editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listSalesTeamsRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      editSalesTeamsFailure({
        code: error?.statusCode,
        message:
          "Não foi possível editar o time comerical com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listSalesTeams({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = SalesTeamsService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 100 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listSalesTeamsSuccess({ list: response.data }));
  } catch (error) {
    yield put(listSalesTeamsFailure());
  }
}

export default all([
  takeLatest(SalesTeamsActionsTypes.CREATE_REQUEST, createSalesTeams),
  takeLatest(SalesTeamsActionsTypes.EDIT_REQUEST, editSalesTeams),
  takeLatest(SalesTeamsActionsTypes.LIST_REQUEST, listSalesTeams)
]);
