import { combineReducers } from "redux";

import sidebarMenu from "@/common/reducers/sidebarMenu";
import accounts from "@/modules/Accounts/reducers/accounts";
import contracts from "@/modules/Contracts/reducers/contracts";
import bannersOffers from "@/modules/Offers/reducers/bannersOffers";
import plansOffers from "@/modules/Offers/reducers/plansOffers";
import productsOffers from "@/modules/Offers/reducers/productsOffers";
import tagOffers from "@/modules/Offers/reducers/tagOffers";
import banners from "@/modules/Settings/reducers/banners";
import businessUnits from "@/modules/Settings/reducers/businessUnits";
import carriers from "@/modules/Settings/reducers/carriers";
import contractModels from "@/modules/Settings/reducers/contractModels";
import distributionCenters from "@/modules/Settings/reducers/distributionCenters";
import electronicSignatures from "@/modules/Settings/reducers/electronicSignatures";
import inventories from "@/modules/Settings/reducers/inventories";
import inventoriesOccurrences from "@/modules/Settings/reducers/inventoriesOccurrences";
import lines from "@/modules/Settings/reducers/lines";
import linesOccurrences from "@/modules/Settings/reducers/linesOccurrences";
import modelsAttachments from "@/modules/Settings/reducers/modelsAttachments";
import modelsGroups from "@/modules/Settings/reducers/modelsGroups";
import offers from "@/modules/Settings/reducers/offers";
import offersPrices from "@/modules/Settings/reducers/offersPrices";
import organizations from "@/modules/Settings/reducers/organizations";
import permissions from "@/modules/Settings/reducers/permissions";
import plans from "@/modules/Settings/reducers/plans";
import pricingGroups from "@/modules/Settings/reducers/pricingGroups";
import products from "@/modules/Settings/reducers/products";
import productsBrands from "@/modules/Settings/reducers/productsBrands";
import productsCategories from "@/modules/Settings/reducers/productsCategories";
import reportCategories from "@/modules/Settings/reducers/reportCategories";
import reports from "@/modules/Settings/reducers/reports";
import roles from "@/modules/Settings/reducers/roles";
import salesTeams from "@/modules/Settings/reducers/salesTeams";
import serviceProviders from "@/modules/Settings/reducers/serviceProviders";
import services from "@/modules/Settings/reducers/services";
import servicesCategories from "@/modules/Settings/reducers/servicesCategories";
import shippingsGroups from "@/modules/Settings/reducers/ShippingsGroups";
import shippingsType from "@/modules/Settings/reducers/ShippingsType";
import users from "@/modules/Settings/reducers/users";
import profile from "@/modules/User/reducers/profile";

const rootReducer = combineReducers({
  accounts,
  businessUnits,
  carriers,
  contracts,
  lines,
  linesOccurrences,
  organizations,
  salesTeams,
  plans,
  profile,
  users,
  shippingsType,
  shippingsGroups,
  contractModels,
  modelsGroups,
  modelsAttachments,
  distributionCenters,
  products,
  productsBrands,
  productsCategories,
  inventories,
  inventoriesOccurrences,
  offersPrices,
  banners,
  pricingGroups,
  services,
  serviceProviders,
  servicesCategories,
  permissions,
  reports,
  reportCategories,
  roles,
  offers,
  productsOffers,
  plansOffers,
  bannersOffers,
  tagOffers,
  sidebarMenu,
  electronicSignatures
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
