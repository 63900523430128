import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Field } from "formik";
import { useEffect, useState } from "react";

import { ShowMessageDiv } from "@/components/Shared/MessageBar";
import { ServicesService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

interface PropsSelectCategory {
  businessUnit: number;
  error: any;
  touched: any;
  setFieldValue: any;
  setServicesList: (services) => void;
  selected: any;
}

export function SelectCategory(props: PropsSelectCategory): JSX.Element {
  const [productCategories, setProductCategories] = useState([]);
  const [message, setMessage] = useState(undefined);

  const cleanMessage = () => {
    setMessage(undefined);
  };

  useEffect(() => {
    //. Our component “subscribes” to the promise, but it never “unsubscribes” or cancels the request.
    //If for any reason, our component is unmounted before the promise resolves, our code will try to “set state”
    //(calling setProductCategories) on an unmounted component.
    let isSubscribed = true;

    if (!props.selected) {
      setProductCategories([]);

      const abortController = new AbortController();
      const { signal } = abortController;
      const service = ServicesService("/categories");
      const params = {};
      if (props.businessUnit) {
        params["query"] = {
          businessActionId: props.businessUnit
        };
      }

      service.list({ params, signal }).then(response => {
        if (isSubscribed) {
          if (response.error || response.data.length === 0) {
            setMessage({
              message:
                "Não foi possível listar as categorias de contrato, por favor atualize a página",
              type: messageBarTypes.ERROR
            });
          } else {
            const categories: IDropdownOption[] = [];

            response.data.forEach(category => {
              categories.push({
                key: category.id,
                text: category.name,
                data: category.services,
                id: `option-category-${category.id}`
              });
            });

            setProductCategories(categories);
          }
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
    //eslint-disable-next-line
  }, [props.businessUnit]);

  return (
    <div className="ms-Grid" dir="ltr">
      <ShowMessageDiv message={message} cleanMessage={cleanMessage} />
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-lg3">
          <h2 className="ms-fontSize-20">Categoria</h2>
          <p>Descrição</p>
        </div>
        <div className="ms-Grid-col ms-sm12 ms-lg4">
          {props.selected ? (
            <Dropdown
              id="productCategories-select"
              label="Selecione uma categoria"
              options={[{ key: props.selected.id, text: props.selected.name }]}
              disabled={true}
              defaultSelectedKey={props.selected.id}
            />
          ) : productCategories.length === 0 ? (
            <Dropdown
              id="productCategories-select"
              label="Selecione uma categoria"
              placeholder={"Carregando..."}
              options={[]}
              disabled={true}
            />
          ) : (
            <Field
              name="serviceCategory"
              key="serviceCategory"
              id="serviceCategory"
              type="select"
              as={Dropdown}
              onChange={(evt, item) => {
                props.setFieldValue("serviceCategory", item.key);
                props.setFieldValue("service", "");
                props.setServicesList(item.data);
              }}
              placeholder="Selecione uma categoria"
              label="Selecione uma categoria"
              errorMessage={props.error && props.touched ? props.error : null}
              options={productCategories}
              styles={{ dropdown: { width: "100%" } }}
              required={true}
              onRenderOption={option => (
                <button
                  onClick={() => {
                    props.setFieldValue("serviceCategory", option.key);
                    props.setFieldValue("service", "");
                    props.setServicesList(option.data);
                  }}
                  data-testid={`button-custom-${option.id}`}
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  {option.text}
                </button>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}
