import Api from "./api";

class EmailTrigger extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin ? `admin/email-trigger${subPaths}` : `email-trigger${subPaths}`
    );
  }
}

export default EmailTrigger;
