import styled from "styled-components";

const StyledContentArea = styled.div<{ color?: string; isDev?: boolean }>`
  background: ${props => props.color || "white"};
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: ${props =>
    props.isDev ? "calc(100vh - 78px)" : "calc(100vh - 49px)"};
  overflow: hidden;
  overflow-y: auto;
  position: relative;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledMainLayout = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: column;
`;

/* const NotificationWrapper = styled.div`
  background-color: #555;
  color: white;
  text-decoration: none;
  padding: 15px 26px;
  position: relative;
  display: inline-block;
  border-radius: 2px;

  &:hover {
    cursor: pointer;
    background: #335393;
  }
`;

const NotificationSpan = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
`; */

export { StyledContentArea, StyledContentWrapper, StyledMainLayout };
