import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createReportsFailure,
  createReportsSuccess,
  listReportsFailure,
  listReportsRequest,
  listReportsSuccess
} from "../actions/reports";
import { ReportsActionsTypes } from "../types/Reports.reducer.types ";

import { SagaCustomError } from "@/common/types";
import { ReportsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createReports({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ReportsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createReportsSuccess({
        message: `Relatório criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listReportsRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      createReportsFailure({
        code: error?.statusCode,
        message: "Não foi possível criar a relatório com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listReports({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = ReportsService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });
    if (response.error) {
      throw new Error();
    }

    yield put(listReportsSuccess({ list: response.data }));
  } catch (error) {
    yield put(listReportsFailure());
  }
}

export default all([
  takeLatest(ReportsActionsTypes.CREATE_REQUEST, createReports),
  takeLatest(ReportsActionsTypes.LIST_REQUEST, listReports)
]);
