import { PrimaryButton, Stack, TextField, Toggle } from "@fluentui/react";
import { Field } from "formik";
import { useEffect, useState } from "react";

import { AdditionalInputsProps, IAdditionalOffer } from "./types";

import { LabelWithTooltip } from "@/components/LabelWithTooltip";
import ProductsService from "@/core/libs/api/lib/products";
import { SearchSelectField } from "@/core/libs/form-builder/lib/search-select-field";
import { AdditionalCategoryOptionsEnum } from "@/modules/Settings/pages/AdditionalCategory/enum";
import { parseStringToFloatDoubleFixed } from "@/utils/ParseStringToFloatDoubleFixed";

const productsService = new ProductsService("", true);

export const AdditionalInputs = ({
  setSelectedAdditional,
  selectedAdditional,
  setValue,
  errorsProp,
  touchedProp,
  schema,
  initialValues,
  buttonText,
  onlyValues
}: AdditionalInputsProps) => {
  const [currentAdditional, setCurrentAdditional] =
    useState<IAdditionalOffer>(initialValues);
  const [productsOptions, setProductsOptions] = useState();
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [requestProductsMetaInfo, setRequestProductsMetaInfo] = useState();

  const [errorMessage, setErrorMessage] = useState({
    name: "",
    activationValue: "",
    description: "",
    monthlyFee: "",
    bonus: "",
    productId: ""
  });

  const abortController = new AbortController();
  const { signal } = abortController;

  const requestProducts = async params => {
    setIsLoadingProducts(true);
    params = { query: { limit: 999, ...params } };
    const response = await productsService.getProducts({ params, signal });

    if (response?.data) {
      const options = response.data.items.map(option => {
        return {
          key: option.id,
          text: option.name
        };
      });
      setRequestProductsMetaInfo(response.data.meta);
      setProductsOptions(options);
    }
    setIsLoadingProducts(false);
  };

  const handleSetCurrentAdditional = (fields: { [key: string]: unknown }) => {
    setCurrentAdditional({
      ...currentAdditional,
      ...fields
    });
  };

  const handleAddAdditionalToValues = async () => {
    const isValid = await validateData();

    if (isValid) {
      setValue(currentAdditional);
      if (setSelectedAdditional) setSelectedAdditional(null);
      if (setCurrentAdditional) {
        setCurrentAdditional({
          name: "",
          required: false,
          activationValue: "",
          description: "",
          monthlyFee: "",
          additionalId: 0,
          defaultChecked: false
        });
      }
    }
  };

  const validateData = async () => {
    setErrorMessage({
      name: "",
      activationValue: "",
      description: "",
      monthlyFee: "",
      bonus: "",
      productId: ""
    });

    try {
      await schema.validate(currentAdditional, { abortEarly: false });
      return true;
    } catch (err) {
      err.inner.forEach(async error => {
        setErrorMessage(prevState => ({
          ...prevState,
          [`${error.path}`]: error.errors[0]
        }));
      });
      return false;
    }
  };

  const searchSelectConfig = {
    label: "Produtos",
    type: "searchSelectField",
    name: "productId",
    queryName: "name",
    requestFunction: requestProducts,
    requestMetaInfo: requestProductsMetaInfo,
    isLoadingOptions: isLoadingProducts,
    options: productsOptions || [],
    value: "",
    disabled: onlyValues,
    required:
      selectedAdditional?.additionalCategory?.type ===
      AdditionalCategoryOptionsEnum.PRODUCT
  };

  useEffect(() => {
    requestProducts({});
  }, []);

  return (
    <Stack.Item
      style={{
        width: "90%",
        margin: "auto"
      }}
    >
      <Stack.Item>
        <Field
          label="Nome"
          type="text"
          as={TextField}
          name="name"
          id="name-input"
          placeholder="Nome"
          value={currentAdditional?.name}
          onChange={(event, value) => {
            handleSetCurrentAdditional({ name: value });
          }}
          required={true}
          disabled={initialValues.name || onlyValues}
          errorMessage={errorMessage.name}
        />
        <Field
          label="Descrição"
          type="text"
          multiline
          rows={3}
          as={TextField}
          name="description"
          id="description-input"
          placeholder="Descrição"
          value={currentAdditional?.description}
          onChange={(_, value: string) => {
            handleSetCurrentAdditional({ description: value });
          }}
          disabled={initialValues.description || onlyValues}
          errorMessage={errorMessage.description}
        />
      </Stack.Item>
      <Stack.Item>
        <Field
          label="Taxa de ativação"
          placeholder="Taxa de ativação"
          type="money"
          id="activationValue-input"
          prefix="R$"
          as={TextField}
          name="activationValue"
          value={parseStringToFloatDoubleFixed(
            currentAdditional?.activationValue
          )}
          required={true}
          errorMessage={errorMessage.activationValue}
          onChange={(_, value) => {
            handleSetCurrentAdditional({
              activationValue: parseStringToFloatDoubleFixed(value)
            });
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <Field
          label="Valor mensal"
          placeholder="Valor mensal"
          type="money"
          prefix="R$"
          as={TextField}
          name="monthlyFee"
          id="monthlyFee-input"
          value={parseStringToFloatDoubleFixed(currentAdditional?.monthlyFee)}
          required={true}
          errorMessage={errorMessage.monthlyFee}
          onChange={(_, value) => {
            handleSetCurrentAdditional({
              monthlyFee: parseStringToFloatDoubleFixed(value)
            });
          }}
        />
      </Stack.Item>
      {(selectedAdditional?.additionalCategory?.type ===
        AdditionalCategoryOptionsEnum.SERVICE_BONUS ||
        initialValues?.additional?.additionalCategory?.type ===
          AdditionalCategoryOptionsEnum.SERVICE_BONUS ||
        initialValues?.bonus) && (
        <Stack.Item>
          <LabelWithTooltip
            description="Bônus que será adicionado na oferta."
            label="Bonus"
          />
          <Field
            type="number"
            as={TextField}
            name="bonus"
            input="bonus-input"
            placeholder="Bônus"
            value={currentAdditional?.bonus}
            required={
              selectedAdditional?.additionalCategory.type ===
              AdditionalCategoryOptionsEnum.SERVICE_BONUS
            }
            onChange={(_, value) => {
              handleSetCurrentAdditional({ bonus: value });
            }}
            errorMessage={errorMessage.bonus}
          />
        </Stack.Item>
      )}

      {selectedAdditional?.additionalCategory?.type ===
        AdditionalCategoryOptionsEnum.PRODUCT && (
        <Stack.Item>
          <SearchSelectField
            key="products-search-select"
            fieldConfig={searchSelectConfig}
            setValue={handleSetCurrentAdditional}
            errors={errorsProp}
            touched={touchedProp}
            defaultValue={""}
            errorMessage={errorMessage.productId}
          />
        </Stack.Item>
      )}

      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { marginTop: 10 } }}
      >
        {!onlyValues && (
          <>
            <Stack.Item align="center">
              <Field
                id="input-required"
                name="required"
                type="switch"
                key="required"
                label="Adicional obrigatório"
                as={Toggle}
                offText="Não"
                onText="Sim"
                defaultChecked={false}
                required={true}
                disabled={initialValues.name}
                onChange={(_, value: boolean) => {
                  handleSetCurrentAdditional({
                    required: value
                  });
                }}
              />
            </Stack.Item>

            <Stack.Item align="center">
              <Field
                id="input-default-checked"
                name="defaultChecked"
                type="switch"
                key="defaultChecked"
                label="Selecionado por padrão"
                title="Adicional deve estar selecionado no momento de confirmação"
                as={Toggle}
                offText="Não"
                onText="Sim"
                defaultChecked={false}
                disabled={initialValues.name || currentAdditional.required}
                style={{
                  cursor: currentAdditional.required ? "not-allowed" : "auto"
                }}
                onChange={(_, value: boolean) => {
                  handleSetCurrentAdditional({ defaultChecked: value });
                }}
              />
            </Stack.Item>
          </>
        )}
        <Stack.Item align="center">
          <PrimaryButton
            id="insert-additional-button"
            onClick={() => {
              handleAddAdditionalToValues();
            }}
          >
            {buttonText}
          </PrimaryButton>
        </Stack.Item>
      </Stack>
    </Stack.Item>
  );
};
