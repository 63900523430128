function* compareExistenceBetweenArrays(ruleArr: string[], obj: object) {
  for (const rarr of Array.from(ruleArr) as string[]) {
    if (obj[rarr]) yield { [rarr]: obj[rarr] };
  }
}

export const filterObjectByArray = (obj: object, keysArr: string[]) => {
  if (!obj) return {};
  const res = compareExistenceBetweenArrays(keysArr, obj);

  const map = new Map();

  Array.from(res).forEach(item => {
    Object.entries(item).forEach(([key, value]) => {
      map.set(key, value);
    });
  });

  return Object.fromEntries(map);
};
