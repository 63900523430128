import {
  FocusTrapZone,
  FocusZone,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack
} from "@fluentui/react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { AddressCard } from "./AddressCard";
import { CheckingAccountCard } from "./CheckingAccountCard";
import { EmailCard } from "./EmailCard";

import { Loader } from "@/components/Shared/Loader";
import { ContractsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";
import { PaymentMethod } from "@/modules/Contracts/enums";

interface FormProps {
  initialValues: any;
  contract: any;
  paymentMethod: any;
  emailsOptions: any;
  selectedEmail: any;
  selectedCheckingAccount: any;
  checkingAccountsOptions: any;
  selectedAddress: any;
  addressesOptions: any;
  message: any;
  setMessage: any;
  setSelectedCheckingAccount: any;
  setSelectedEmail: any;
  setSelectedAddress: any;
  nextStep: (contract, step) => void;
  setIsEditingAccount: (condition: boolean) => void;
  setIsAccountIncomplete: (condition: boolean) => void;
  setFieldToFocus: (fieldName: string) => void;
}

const schema = Yup.object().shape({
  account: Yup.number().required("Obrigatório")
});

export function FormStepTwo({
  addressesOptions,
  checkingAccountsOptions,
  contract,
  emailsOptions,
  initialValues,
  nextStep,
  paymentMethod,
  selectedAddress,
  selectedCheckingAccount,
  selectedEmail,
  setFieldToFocus,
  setIsAccountIncomplete,
  setIsEditingAccount,
  setMessage,
  setSelectedAddress,
  setSelectedCheckingAccount,
  setSelectedEmail
}: FormProps) {
  function clearMessage() {
    setMessage(undefined);
  }

  function cantSubmit(msg, { fieldName }) {
    clearMessage();
    setFieldToFocus(fieldName);
    setMessage({
      message: msg,
      type: messageBarTypes.WARNING
    });
    setIsAccountIncomplete(true);
    setIsEditingAccount(true);

    return;
  }

  async function editContract(values, { setSubmitting }: FormikHelpers<any>) {
    clearMessage();
    setSubmitting(true);

    if (
      contract.account &&
      values.account === contract.account.id &&
      values.accountAddress === contract.accountAddress.id &&
      values.accountContact === contract.accountContact.id &&
      contract.checkingAccount &&
      values.checkingAccount === contract.checkingAccount.id
    ) {
      setSubmitting(false);
      await nextStep(contract, 2);
      return;
    }

    if (
      paymentMethod === PaymentMethod.DEBITO_AUTOMATICO &&
      !values.checkingAccount
    ) {
      setSubmitting(false);
      cantSubmit(
        "É necessário informar uma conta para avançar para o proximo passo.",
        { fieldName: "bankNumber" }
      );
      return;
    }

    if (!values.accountDocument) {
      setSubmitting(false);
      cantSubmit(
        "É necessário informar um documento para avançar para o proximo passo.",
        { fieldName: "documentNumber" }
      );
      return;
    }

    if (!values.accountContact) {
      setSubmitting(false);
      cantSubmit(
        "É necessário informar um email para avançar para o proximo passo.",
        { fieldName: "contact" }
      );
      return;
    }

    if (!values.accountAddress) {
      setSubmitting(false);
      cantSubmit(
        "É necessário informar um endereço para avançar para o proximo passo.",
        { fieldName: "zipCode" }
      );
      return;
    }

    const abortController = new AbortController();
    const { signal } = abortController;
    const service = ContractsService();
    const params = {
      body: values,
      id: contract.id
    };
    const response = await service.edit({ params, signal });
    if (response.error) {
      setSubmitting(false);
      setMessage({
        message:
          response.error.message ??
          "Não foi possível editar o contrato, por favor atualize a página e tente novamente.",
        type: messageBarTypes.ERROR
      });
    } else {
      const contract = response.data;
      await nextStep(contract, 2);
      setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={editContract}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue }) => (
        <>
          {isSubmitting ? (
            <Loader />
          ) : (
            <Form>
              <Stack tokens={{ childrenGap: 20 }}>
                <CheckingAccountCard
                  checkingAccountsOptions={checkingAccountsOptions}
                  selectedCheckingAccount={selectedCheckingAccount}
                  setFieldValue={setFieldValue}
                  setSelectedCheckingAccount={setSelectedCheckingAccount}
                  paymentMethod={paymentMethod}
                />
                <EmailCard
                  emailsOptions={emailsOptions}
                  selectedEmail={selectedEmail}
                  setFieldValue={setFieldValue}
                  setSelectedEmail={setSelectedEmail}
                />
                <AddressCard
                  addressesOptions={addressesOptions}
                  selectedAddress={selectedAddress}
                  setFieldValue={setFieldValue}
                  setSelectedAddress={setSelectedAddress}
                />
                <Stack.Item align="center">
                  <PrimaryButton
                    id="submit-button-step-2"
                    text={isSubmitting ? "Processando..." : "Avançar"}
                    type="submit"
                    disabled={isSubmitting}
                  />
                </Stack.Item>
              </Stack>
            </Form>
          )}
        </>
      )}
    </Formik>
  );
}
