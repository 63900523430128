import { Separator } from "@fluentui/react";
import { Label } from "@fluentui/react/lib/Label";
import { Stack } from "@fluentui/react/lib/Stack";
import { MaskedTextField, TextField } from "@fluentui/react/lib/TextField";
import { Field, FieldArray } from "formik";
import { Fragment } from "react";

interface WitnessesFieldsProps {
  cfg: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
}

interface WitnessesFieldProps {
  cfg: any;
  current: any;
  currentFieldName: string;
  currentFieldIndex: number;
  setFieldValue: any;
  errors: any;
  touched: any;
  arrayHelpers: any;
  value: any;
}

function WitnessesField(props: WitnessesFieldProps): JSX.Element {
  const {
    cfg,
    current,
    currentFieldName,
    currentFieldIndex,
    setFieldValue,
    errors,
    touched,
    value
  } = props;

  return (
    <Stack tokens={{ childrenGap: 5 }} key={current}>
      {cfg.fields.map(field => {
        return (
          <Stack.Item grow key={`${current}.${field.name}`}>
            {field.mask ? (
              <Field
                name={`${current}.${field.name}`}
                id={`${currentFieldName}-${field.name}-${currentFieldIndex}`}
                type={field.type}
                as={MaskedTextField}
                placeholder={field.placeholder || field.label}
                label={field.label}
                disabled={field.disabled || false}
                errorMessage={
                  errors?.[currentFieldName]?.[currentFieldIndex]?.[
                    field.name
                  ] &&
                  touched?.[currentFieldName]?.[currentFieldIndex]?.[field.name]
                    ? errors[currentFieldName][currentFieldIndex][field.name]
                    : null
                }
                mask={field.mask}
                required={field.required || false}
                description={field.description}
                value={value.documentNumber || field.value || undefined}
                onChange={(evt, item) => {
                  if (field.mask) {
                    const value = item.replace(/\D/g, "");
                    setFieldValue(`${current}.${field.name}`, value);
                  } else {
                    setFieldValue(`${current}.${field.name}`, value);
                  }
                }}
              />
            ) : (
              <Field
                name={`${current}.${field.name}`}
                id={`${currentFieldName}-${field.type}-${currentFieldIndex}`}
                type={field.type}
                as={TextField}
                placeholder={field.placeholder || field.label}
                label={field.label}
                disabled={field.disabled || false}
                errorMessage={
                  errors?.[currentFieldName]?.[currentFieldIndex]?.[
                    field.name
                  ] &&
                  touched?.[currentFieldName]?.[currentFieldIndex]?.[field.name]
                    ? errors[currentFieldName][currentFieldIndex][field.name]
                    : null
                }
                onChange={(e, newValue) => {
                  setFieldValue(`${current}.${field.name}`, newValue);
                }}
                required={field.required || false}
                description={field.description}
              />
            )}
          </Stack.Item>
        );
      })}
    </Stack>
  );
}

export function WitnessesFields(props: WitnessesFieldsProps): JSX.Element {
  const { cfg, values, errors, touched, setFieldValue } = props;

  return (
    <FieldArray
      name={cfg.name}
      key={cfg.name}
      render={arrayHelpers => (
        <Stack
          tokens={{ childrenGap: 5 }}
          grow
          horizontal
          horizontalAlign="space-between"
        >
          {values instanceof Array &&
            values.map((field, index) => {
              return (
                <Fragment key={`${cfg.name}[${index}]`}>
                  {index > 0 && (
                    <Separator
                      vertical
                      styles={{ root: { padding: "0 15px" } }}
                    />
                  )}
                  <Stack.Item
                    shrink="unset"
                    styles={{ root: { width: "100%" } }}
                  >
                    <Label required={cfg.required || false}>
                      {cfg.label.slice(0, -1)} ({index + 1})
                    </Label>
                    <WitnessesField
                      {...{
                        cfg,
                        current: `${cfg.name}[${index}]`,
                        currentFieldName: cfg.name,
                        currentFieldIndex: index,
                        setFieldValue,
                        errors,
                        touched,
                        arrayHelpers,
                        value: field
                      }}
                    />
                  </Stack.Item>
                </Fragment>
              );
            })}
        </Stack>
      )}
    />
  );
}
