import { Shimmer, ShimmerElementType } from "@fluentui/react";

import { InvoicePlan } from "./interfaces";
import { Scroll, Subtitle, Title } from "./styles";

import { phoneNumberFormatter } from "@/utils/PhoneNumberFormatter";
import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";

interface InvoicePlansProps {
  plans: InvoicePlan[];
  isLoading?: boolean;
}

export function InvoicePlans({ plans, isLoading = false }: InvoicePlansProps) {
  if (isLoading)
    return (
      <Scroll>
        <Shimmer
          style={{ marginBottom: "1rem" }}
          shimmerElements={[
            {
              type: ShimmerElementType.line,
              height: 35,
              width: "60%"
            }
          ]}
        />

        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, height: 20, width: "60%" }
          ]}
        />
      </Scroll>
    );
  return (
    <Scroll>
      {plans?.map((invoiceInfo, index) => {
        const formattedNumber = phoneNumberFormatter(invoiceInfo.lineNumber);
        const plansHasCombo =
          invoiceInfo.combo.financedValue > 0 ||
          invoiceInfo.combo.cashValue > 0;

        return (
          <div key={index}>
            <Title>
              <p>
                {invoiceInfo.name}
                {invoiceInfo.lineNumber && ` - ${formattedNumber}`}
              </p>
            </Title>

            <Subtitle>
              <p>Taxa Mensal</p>
              <p>{transformToCurrencyString(Number(invoiceInfo.monthlyFee))}</p>
            </Subtitle>

            {plansHasCombo && (
              <Subtitle>
                <p>Combo</p>
                <span>
                  {transformToCurrencyString(
                    Number(invoiceInfo.combo.cashValue)
                  )}
                  <p>à vista ou</p>

                  {transformToCurrencyString(
                    Number(invoiceInfo.combo.financedValue)
                  )}
                  <p>à prazo</p>
                </span>
              </Subtitle>
            )}
          </div>
        );
      })}
    </Scroll>
  );
}
