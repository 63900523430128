import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class LinesOccurrences extends Api {
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/lines/ocurrences${subPaths}`
        : `lines/ocurrences${subPaths}`
    );
    this.listWithPostMethod = this.listWithPostMethod.bind(this);
  }
  listWithPostMethod({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/search`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }
}

export default LinesOccurrences;
