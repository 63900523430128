import { ListOfProducts } from "./Products.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum ProductsActionsTypes {
  CREATE_REQUEST = "products/CREATE_REQUEST",
  CREATE_FAIL = "products/CREATE_FAIL",
  CREATE_SUCCESS = "products/CREATE_SUCCESS",
  DISMISS_MESSAGE = "products/DISMISS_MESSAGE",
  EDIT_REQUEST = "products/EDIT_REQUEST",
  EDIT_FAIL = "products/EDIT_FAIL",
  EDIT_SUCCESS = "products/EDIT_SUCCESS",
  DELETE_REQUEST = "products/DELETE_REQUEST",
  DELETE_FAIL = "products/DELETE_FAIL",
  DELETE_SUCCESS = "products/DELETE_SUCCESS",
  LIST_REQUEST = "products/LIST_REQUEST",
  LIST_FAIL = "products/LIST_FAIL",
  LIST_SUCCESS = "products/LIST_SUCCESS"
}

export type ProductsState = {
  isLoadingList: boolean;
  list?: ListOfProducts;
  message?: IMessageBar;
};

export type CreateRequest = {
  type: ProductsActionsTypes.CREATE_REQUEST;
};

export type CreateError = {
  type: ProductsActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
};

export type CreateSuccess = {
  type: ProductsActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
};

export type DismissMessage = {
  type: ProductsActionsTypes.DISMISS_MESSAGE;
};

export type EditRequest = {
  type: ProductsActionsTypes.EDIT_REQUEST;
};

export type EditError = {
  type: ProductsActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
};

export type EditSuccess = {
  type: ProductsActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
};

export type DeleteRequest = {
  type: ProductsActionsTypes.DELETE_REQUEST;
};

export type DeleteError = {
  type: ProductsActionsTypes.DELETE_FAIL;
  payload: {
    message: IMessageBar;
  };
};

export type DeleteSuccess = {
  type: ProductsActionsTypes.DELETE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
};

export type ListRequest = {
  type: ProductsActionsTypes.LIST_REQUEST;
};

export type ListError = {
  type: ProductsActionsTypes.LIST_FAIL;
};

export type ListSuccess = {
  type: ProductsActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfProducts;
  };
};

export type ProductsReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | DeleteRequest
  | DeleteError
  | DeleteSuccess
  | ListRequest
  | ListError
  | ListSuccess;
