type FileConfigProps = {
  label: string;
  type: string;
  name: string;
  maxFiles: number;
  showOrder: boolean;
  isImage?: boolean;
  deleteButtonDescription: string;
  uploadIconName: string;
  uploadTextDescription: string;
  value?: string;
  allowedFileType: string;
  attachmentTypeConfig?: AttachmentTypeConfigProps;
};

type AttachmentTypeConfigProps = {
  options: Array<{ key: string; text: string }>;
};

enum AttachmentTypes {
  DOCUMENT = "document",
  CONTRACT_SIGNATURE = "contractSignature",
  CONTRACT_ACTIVATION_FEE_PROOF = "contractActivationFeeProof",
  TERM_OWNER_TRANSFER = "termOwnerTransfer"
}

export const fields = {
  get uploadDocument(): Array<FileConfigProps> {
    return [
      {
        label: "Documentos",
        type: "files",
        maxFiles: 10,
        showOrder: false,
        deleteButtonDescription: "Apagar Documento da lista",
        uploadIconName: "CloudUpload",
        uploadTextDescription: "Selecione os arquivos ou arraste-os aqui",
        allowedFileType: "image/*,application/pdf",
        name: "files",
        value: "",
        attachmentTypeConfig: {
          options: [
            { key: AttachmentTypes.DOCUMENT, text: "RG/CNH" },
            {
              key: AttachmentTypes.CONTRACT_SIGNATURE,
              text: "Contrato Assinado"
            },
            {
              key: AttachmentTypes.CONTRACT_ACTIVATION_FEE_PROOF,
              text: "Comprovante de Adesão"
            },
            {
              key: AttachmentTypes.TERM_OWNER_TRANSFER,
              text: "Termo de Transferência de Titulariade"
            }
          ]
        }
      }
    ];
  }
};
