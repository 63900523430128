export enum AdditionalCategoryTypes {
  SERVICE = "Benefício",
  SERVICE_BONUS = "Benefício bônus",
  PRODUCT = "Benefício combo"
}

export enum AdditionalCategoryOptionsEnum {
  SERVICE = "SERVICE",
  SERVICE_BONUS = "SERVICE_BONUS",
  PRODUCT = "PRODUCT"
}
