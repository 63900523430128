export enum AccountActionsTypes {
  CREATE_REQUEST = "accounts/CREATE_REQUEST",
  CREATE_FAIL = "accounts/CREATE_FAIL",
  CREATE_SUCCESS = "accounts/CREATE_SUCCESS",
  DISMISS_MESSAGE = "accounts/DISMISS_MESSAGE",
  EDIT_REQUEST = "accounts/EDIT_REQUEST",
  EDIT_FAIL = "accounts/EDIT_FAIL",
  EDIT_SUCCESS = "accounts/EDIT_SUCCESS",
  LIST_REQUEST = "accounts/LIST_REQUEST",
  LIST_FAIL = "accounts/LIST_FAIL",
  LIST_SUCCESS = "accounts/LIST_SUCCESS"
}
