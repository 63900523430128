import { makeRequest } from "../utils/fetchData";

import Api from "./api";

class Carriers extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/carriers${subPaths}` : `carriers${subPaths}`);
  }

  async listCarriers() {
    const options: any = {
      method: "GET",
      baseUrl: `${this.baseUrl}`
    };
    const carriers = await makeRequest(options);
    return carriers.data.items;
  }
}

export default Carriers;
