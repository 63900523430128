import { Icon, INavLink, Nav, Stack } from "@fluentui/react";
import { MouseEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { SidebarComponentsProps } from "../../types/Sidebar.types";

export function SidebarMain({
  theme,
  closeMenu,
  links,
  isMenuExpanded,
  isMenuOpen,
  maxPathNameRange
}: SidebarComponentsProps): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  return (
    <Nav
      selectedKey={
        location.pathname.match(/\//gi).length >= 2
          ? location.pathname.split("/").slice(0, maxPathNameRange).join("/")
          : location.pathname
      }
      onLinkClick={(ev?: MouseEvent<HTMLElement>, item?: INavLink) => {
        if (!item.links && closeMenu) closeMenu();
        if (ev.ctrlKey) return;
        if (ev) ev.nativeEvent.preventDefault();
        if (item.links) item.isExpanded = !item.isExpanded;
        if (item && item.key) history.push(item.key);
      }}
      styles={{
        root: {
          width: "100%",
          borderRadius: 0,
          boxSizing: "border-box",
          overflowY: "auto",
          selectors: {
            ".ms-Nav-compositeLink.is-expanded": {
              background: theme.palette.neutralLighterAlt,
              borderLeft: "2px solid",
              borderLeftColor: theme.palette.neutralQuaternary
            }
          }
        },
        link: {
          border: 0,
          borderRadius: 0,
          padding: 0
        }
      }}
      groups={[
        {
          links: links
        }
      ]}
      onRenderLink={item => renderItem(item, isMenuExpanded, isMenuOpen)}
    />
  );
}

function renderItem(item, isMenuExpanded, isMenuOpen) {
  const marginByLevel = (level: number) => {
    switch (level) {
      case 1:
        return "0px";
      case 2:
        return "12px";
      case 3:
        return "24px";
      case 4:
        return "36px";
      case 5:
        return "48px";
      default:
        return "0px";
    }
  };
  return (
    <Stack
      horizontal
      style={{
        width: "100%",
        marginLeft: isMenuExpanded || isMenuOpen ? "12px" : 0
      }}
    >
      <Stack.Item
        grow={!isMenuExpanded && !isMenuOpen}
        align="center"
        style={{
          marginLeft: marginByLevel(item.level)
        }}
      >
        <Icon
          iconName={item.iconName}
          styles={{
            root: {
              fontSize: isMenuExpanded ? 16 : 20,
              cursor: "pointer",
              color: "#335393"
            }
          }}
        />
      </Stack.Item>

      {(isMenuExpanded || isMenuOpen) && (
        <span style={{ paddingLeft: item.links ? 20 : 8 }}>{item.name}</span>
      )}
    </Stack>
  );
}
