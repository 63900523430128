import { Stack } from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import { Contract } from "../../contract.interface";
import { LegalFields, PhysicalFields, ShimmerFields } from "../Fields";

import { FilterParamsType } from "@/common/types/ListFromApi.types";
import { Card } from "@/components/Shared/Card";
import {
  CustomDrawer,
  DrawerActions,
  DrawerStateType
} from "@/components/Shared/CustomDrawer";
import { AccountsService, MaritalStatusService } from "@/core/libs/api";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { IMessageBar, messageBarTypes } from "@/core/libs/message-bar";
import { useDebounce } from "@/hooks/index";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { CreateAccount } from "@/modules/Accounts/pages/create/CreateAccount";
import { EditAccounts } from "@/modules/Accounts/pages/edit/EditAccount";
import {
  AccountDirectus,
  MaritalStatusTranslate
} from "@/modules/Accounts/types";
import { PaymentMethod } from "@/modules/Contracts/enums";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";
import { ValidateCNPJ } from "@/utils/ValidateCNPJ";
import { ValidateCPF } from "@/utils/ValidateCPF";

const accountsService = AccountsService();

const isDocumentNumberValid = (documentNumber: string) => {
  if (documentNumber.length < 11 || documentNumber.length > 14) {
    return false;
  }

  if (documentNumber.length > 11 && documentNumber.length < 14) {
    return false;
  }

  if (documentNumber.length === 11 && !ValidateCPF(documentNumber)) {
    return false;
  }

  if (documentNumber.length > 11 && !ValidateCNPJ(documentNumber)) {
    return false;
  }

  return true;
};

const requestClient = (documentNumber: string, businessActionId: number) => {
  const abortController = new AbortController();
  const { signal } = abortController;

  return accountsService.findAccount({
    params: {
      query: {
        documentNumber,
        businessActionId
      }
    },
    signal
  });
};

enum AccountType {
  PHYSICAL = "PHYSICAL",
  LEGAL = "LEGAL"
}

interface SearchClientProps {
  contract?: Contract;
  currentBU: number;
  fieldToFocus: string;
  isAccountIncomplete: boolean;
  isEditingAccount: boolean;
  message: IMessageBar;
  setFieldToFocus: (fieldName: string) => void;
  setIsAccountIncomplete: (condition: boolean) => void;
  setIsEditingAccount: (condition: boolean) => void;
  setMessage: ({ message, type, code }: IMessageBar) => void;
  setUpperAccount: (account: any) => void;
}

export function SearchClient({
  currentBU,
  isAccountIncomplete,
  setIsAccountIncomplete,
  setMessage,
  contract,
  setUpperAccount
}: SearchClientProps): JSX.Element {
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(
    contract.accountDocument?.documentNumber
  );
  const [items, setItems] = useState<any>();
  const [accountIdToEdit, setAccountIdToEdit] = useState(null);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [account, setAccount] = useState(contract.account);
  const [documentSearched, setDocumentSearched] = useState<string>();
  const [isSearching, setIsSearching] = useState(false);
  const [directusAccountData, setDirectusAccountData] =
    useState<AccountDirectus>(null);
  const [maritalStatusOptions, setMaritalStatusOptions] =
    useState<Array<{ key: number; text: string }>>(null);
  const debouncedSearchTerm: string | undefined = useDebounce(searchTerm, 500);
  const { selectedBU } = useSelectedBu();
  const { execute } = useApi();

  //Drawer
  const drawerContainerRef = useRef<HTMLDivElement>(null);

  const [drawerState, setDrawerState] = useState<DrawerStateType>({
    action: null,
    isOpen: false
  });

  const cleanData = useCallback(() => {
    setMessage(undefined);
    setErrorMessage(undefined);
    setDocumentSearched(undefined);
    setAccount(null);
    setUpperAccount(null);
  }, [setMessage, setUpperAccount]);

  const handleMaritalStatusRequest = async () => {
    const { signal } = new AbortController();
    const service = MaritalStatusService("", true);

    const result = await service.list({ params: {}, signal });
    setMaritalStatusOptions(
      result.data?.items?.map(item => ({
        key: item.id,
        text: MaritalStatusTranslate[item.name]
      }))
    );
  };

  const documentNumberOnlyDigits = debouncedSearchTerm?.replace(/\D/g, "");

  const requestDirectusAccount = async () => {
    const response = await execute({
      url: `accounts/directus-account?documentNumber=${documentNumberOnlyDigits}`,
      method: "GET"
    });

    return response.data;
  };

  const getClient = useCallback(() => {
    setIsAccountIncomplete(false);
    cleanData();

    const documentNumberOnlyDigits = debouncedSearchTerm.replace(/\D/g, "");
    if (isDocumentNumberValid(documentNumberOnlyDigits)) {
      setIsSearching(true);
      setDocumentSearched(documentNumberOnlyDigits);
      requestClient(documentNumberOnlyDigits, currentBU).then(
        async ({ data, error }) => {
          if (data) {
            if (
              selectedBU?.paymentMethod === PaymentMethod.DEBITO_AUTOMATICO &&
              data.checkingAccounts.length < 1
            ) {
              setIsAccountIncomplete(true);
              setAccountIdToEdit(data.id);
              openEditDrawer();
              setMessage({
                message:
                  "Você precisa adicionar uma conta para continuar esse contrato.",
                type: messageBarTypes.WARNING
              });

              return;
            }

            if (!(data.contacts.length > 0)) {
              setIsAccountIncomplete(true);
              setAccountIdToEdit(data.id);
              openEditDrawer();
              setMessage({
                message:
                  "Você precisa adicionar um e-mail para continuar esse contrato.",
                type: messageBarTypes.WARNING
              });

              return;
            }

            if (!(data.addresses.length > 0)) {
              setIsAccountIncomplete(true);
              setAccountIdToEdit(data.id);
              openEditDrawer();
              setMessage({
                message:
                  "Você precisa adicionar um endereço para continuar esse contrato.",
                type: messageBarTypes.WARNING
              });

              return;
            }

            if (data.accountType === AccountType.PHYSICAL && !data.birthDate) {
              setIsAccountIncomplete(true);
              setAccountIdToEdit(data.id);
              openEditDrawer();
              setMessage({
                message:
                  "Você precisa adicionar uma data de nascimento para continuar esse contrato.",
                type: messageBarTypes.WARNING
              });

              return;
            }
            setIsSearching(false);
            setAccount(data);
            setUpperAccount(data);
            return;
          }
          const accountData = await requestDirectusAccount();
          setDirectusAccountData(accountData);

          if (accountData) {
            setAccount(accountData);
            setIsSearching(false);
            setUpperAccount(accountData);
            setIsCreatingAccount(true);
            openCreateDrawer();
            setMessage({
              message:
                "Cooperado identificado, valide se todas as informações estão corretas",
              type: messageBarTypes.SUCCESS
            });
          }

          if (error?.status === 404) {
            setIsCreatingAccount(true);
            openCreateDrawer();
          }
        }
      );
    } else {
      setAccount(null);
      setUpperAccount(null);

      setErrorMessage("Número de documento inválido");
      setMessage(undefined);
    }
  }, [
    currentBU,
    debouncedSearchTerm,
    selectedBU?.paymentMethod,
    setIsAccountIncomplete,
    setMessage,
    setUpperAccount,
    cleanData
  ]);

  useEffect(() => {
    if (
      debouncedSearchTerm &&
      contract?.accountDocument?.documentNumber !== debouncedSearchTerm
    ) {
      cleanData();
      getClient();
    }
  }, [
    cleanData,
    contract?.accountDocument?.documentNumber,
    debouncedSearchTerm,
    getClient
  ]);

  function closeDrawer() {
    setDrawerState({ action: null, isOpen: false });
  }

  function openCreateDrawer() {
    setDrawerState({ action: DrawerActions.CREATE, isOpen: true });
  }

  function openEditDrawer() {
    setDrawerState({ action: DrawerActions.EDIT, isOpen: true });
  }

  async function createAccounts(
    values,
    { setSubmitting, businessActionId },
    closeDrawer
  ) {
    const { signal } = new AbortController();
    const service = AccountsService("");
    const result = await service.create({
      params: { body: values, query: { businessActionId: selectedBU?.id } },
      signal
    });

    if (result?.response?.ok) {
      const documentNumber = values.documents.find(
        value => value.documentType === "CPF" || value.documentType === "CNPJ"
      ).documentNumber;

      setSearchTerm(documentNumber);
      setIsCreatingAccount(false);

      setItems(prevState =>
        prevState?.items ? [...prevState.items, result?.data] : [result?.data]
      );
    } else {
      setErrorMessage(result.error.message);
      return result;
    }
    closeDrawer();
    return result;
  }

  async function handleAccountRequest(params?: FilterParamsType) {
    const { signal } = new AbortController();
    const service = AccountsService("");

    if (selectedBU?.id) {
      const query = {
        businessActionId: selectedBU?.id,
        all: selectedBU?.all,
        ...params
      };

      const result = await service.list({ params: { query }, signal });
      if (result.response?.ok) {
        setItems({
          items: result.data?.items,
          meta: result.data?.meta
        });
      }
    }
  }

  async function editAccount(values, { setSubmitting }, closeDrawer) {
    const { signal } = new AbortController();
    const service = AccountsService("");
    const result = await service.edit({
      params: {
        ...values,
        query: { businessActionId: selectedBU?.id },
        id: accountIdToEdit
      },
      signal
    });

    if (result?.response?.ok) {
      handleAccountRequest();
      closeDrawer();
    }

    return result;
  }

  useEffect(() => {
    handleMaritalStatusRequest();
  }, []);
  return (
    <Stack styles={{ root: { width: "100%" } }}>
      <Helmet>
        <title>Buscar conta do cooperado - Libertas</title>
      </Helmet>
      <Stack tokens={{ childrenGap: 15 }}>
        <Card>
          <TextField
            id="input-search-client"
            label="CPF / CNPJ"
            placeholder="Digite o CPF/CNPJ do cooperado."
            onKeyDown={event => {
              if (event.key === "Enter") {
                getClient();
              }
            }}
            onChange={(_event, item) => {
              setSearchTerm(cpfCnpjFormatter(item));
            }}
            maxLength={18}
            value={cpfCnpjFormatter(searchTerm || "")}
            errorMessage={errorMessage}
          />
          {isSearching && <ShimmerFields />}

          {!isAccountIncomplete &&
            account?.accountType === AccountType.PHYSICAL && (
              <PhysicalFields account={account} />
            )}

          {!isAccountIncomplete &&
            account?.accountType === AccountType.LEGAL && (
              <LegalFields account={account} />
            )}

          <CustomDrawer
            isOpen={drawerState.isOpen}
            action={drawerState.action}
            module={"Contas dos Cooperados"}
            size="medium"
            onCloseDrawer={closeDrawer}
            drawerContainerRef={drawerContainerRef}
          >
            {isCreatingAccount && (
              <CreateAccount
                create={createAccounts}
                message={null}
                drawerContainerRef={drawerContainerRef}
                dismissMessage={() => {
                  return;
                }}
                closeDrawer={closeDrawer}
                document={documentSearched}
                maritalStatusOptions={maritalStatusOptions}
                directusAccount={directusAccountData}
              />
            )}
            {accountIdToEdit && (
              <EditAccounts
                edit={editAccount}
                selectedItem={accountIdToEdit}
                message={null}
                drawerContainerRef={drawerContainerRef}
                closeDrawer={closeDrawer}
                dismissMessage={() => {
                  return;
                }}
                maritalStatusOptions={maritalStatusOptions}
              />
            )}
          </CustomDrawer>
        </Card>
      </Stack>
    </Stack>
  );
}
