import { TermStepEnum } from "../enums/termStep.enum";
import { TermStepTranslatorEnum } from "../enums/termStepTranslator.enum";

import { FiltersTypeEnum } from "@/core/libs/list-data/lib/enums";
import { FiltersProps } from "@/core/libs/list-data/lib/types";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";

const removeMask = (value: string) => {
  return value?.replace(/\D/g, "");
};

interface BuildTermsFiltersProps {
  servicesList: { key: number; text: string }[];
  isLoadingServicesList: boolean;
}

export function buildTermsFilters({
  servicesList,
  isLoadingServicesList
}: BuildTermsFiltersProps) {
  const termsFilters: FiltersProps[] = [
    {
      label: "Nome",
      placeholder: "Nome",
      filterQuery: "fullName",
      type: FiltersTypeEnum.TEXT,
      maxWidth: "200px"
    },
    {
      label: "CPF/CNPJ",
      placeholder: "CPF/CNPJ",
      filterQuery: "documentNumber",
      type: FiltersTypeEnum.TEXT,
      maxWidth: "200px",
      maskFormatter: cpfCnpjFormatter,
      valueFormatter: removeMask
    },
    {
      label: "Nome Unidade de Negocio",
      placeholder: "Unidade de Negocio",
      filterQuery: "businessUnitName",
      type: FiltersTypeEnum.TEXT,
      maxWidth: "200px"
    },
    {
      label: "Benefício",
      placeholder: "Benefício",
      filterQuery: "serviceId",
      type: FiltersTypeEnum.SELECT,
      options: !isLoadingServicesList && servicesList,
      minWidth: "300px"
    },
    {
      label: "Termos criados entre",
      filterQuery: ["beginsAt", "finishesAt"],
      type: FiltersTypeEnum.DATE_RANGE
    },
    {
      label: "Estado",
      placeholder: "Estado",
      filterQuery: "currentStep",
      type: FiltersTypeEnum.SELECT,
      minWidth: "200px",
      options: [
        {
          key: TermStepEnum.TIMED_OUT,
          text: TermStepTranslatorEnum.TIMED_OUT
        },
        {
          key: TermStepEnum.REGISTER_CORRECTION,
          text: TermStepTranslatorEnum.REGISTER_CORRECTION
        },
        {
          key: TermStepEnum.MEMBER_SIGNED,
          text: TermStepTranslatorEnum.MEMBER_SIGNED
        },
        {
          key: TermStepEnum.OPTIONAL_SIGNATURES_COLLECTED,
          text: TermStepTranslatorEnum.OPTIONAL_SIGNATURES_COLLECTED
        },
        {
          key: TermStepEnum.CANCELED,
          text: TermStepTranslatorEnum.CANCELED
        },
        {
          key: TermStepEnum.DONE,
          text: TermStepTranslatorEnum.DONE
        },
        {
          key: TermStepEnum.REJECTED,
          text: TermStepTranslatorEnum.REJECTED
        }
      ]
    }
  ];
  return termsFilters;
}
