import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StringifiableRecord } from "query-string";

import { useApi } from "../../useApi";

import { IUseGetContractsResponse } from "./useGetContracts.types";

import { useAuth } from "@/core/libs/use-auth";
import { useSelectedBu } from "@/hooks/useSelectedBu";

const CONTRACTS_QUERY_TOKEN = Symbol("contracts");

export const useGetContracts = (params?: object) => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const contractsQueryClient = useQueryClient();

  const { selectedBU } = useSelectedBu();

  const paramValues = {
    businessActionId: selectedBU?.id,
    businessUnitSelected: selectedBU?.id,
    limit: 10,
    me: false,
    all: true,
    page: 1,
    ...params
  };

  const fetchData = async (params: StringifiableRecord) => {
    return execute({
      url: `contracts`,
      notificationMessage: "Buscando Contratos",
      params
    });
  };

  const { data, isFetching } = useQuery({
    queryKey: [CONTRACTS_QUERY_TOKEN, paramValues],
    queryFn: () => fetchData(paramValues),
    enabled: isAuthenticated,
    staleTime: 120000,
    keepPreviousData: true
  });

  const contractsList: IUseGetContractsResponse = data?.data;

  const invalidateQuery = () => {
    contractsQueryClient.invalidateQueries({
      queryKey: [CONTRACTS_QUERY_TOKEN]
    });
  };

  return {
    contractsList,
    isLoadingContractsList: isFetching,
    invalidateQuery
  };
};
