import { getTheme, Stack } from "@fluentui/react";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { useEffect, useState } from "react";

import { contentStyles } from "./styles";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import { ContractsService } from "@/core/libs/api";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import {
  ContractBadgeColors,
  ContractFlags,
  TranslatedContractCurrentStep
} from "@/modules/Contracts/enums";
import { Badge } from "@/modules/Contracts/styles";
import { ServicesCategoriesTypesEnum } from "@/modules/Settings/types/ServicesCategories.types";

interface MenuProps {
  contract: any;
  nextStep: any;
  reloadState: ({
    changeFetchingState
  }: {
    changeFetchingState?: boolean;
  }) => void;
  setIsVisible: any;
  setIsContractOccurrencesDrawerVisible: any;
  action: string;
  setAction: (action: string) => void;
  setActionMessage: (actionMessage: string) => void;
  toggleActionModal: (action?: string) => void;
  contractId: string;
  isButtonDisabled: boolean;
}
const contractsCache = [];

export function ViewMenu({
  contract,
  nextStep,
  action,
  reloadState,
  setIsVisible,
  setIsContractOccurrencesDrawerVisible,
  setAction,
  setActionMessage,
  toggleActionModal,
  contractId,
  isButtonDisabled
}: MenuProps): JSX.Element {
  const theme = getTheme();

  const userProfile = useGetProfile();
  const permissions = userProfile.data?.data?.role.permissions;
  const [contractStatus, setContractStatus] = useState(null);

  useEffect(() => {
    if (contract.serviceCategory.type === ServicesCategoriesTypesEnum.TAG) {
      const contractsThatCannotBeCanceled = async () => {
        const service = ContractsService();
        const { signal } = new AbortController();
        const { data } = await service.getContractsThatCannotBeCanceled({
          signal
        });
        setContractStatus(data);
      };
      contractsThatCannotBeCanceled();
      contractsCache.push(contractStatus);
    }
  }, []);

  return (
    <Stack
      className={contentStyles.stackContainer}
      tokens={{ childrenGap: 10 }}
      horizontal
      verticalAlign="center"
    >
      <div>
        <h2 className="ms-fontSize-24">Contrato #{contract.id}</h2>
      </div>

      <Stack
        className={contentStyles.badgeContainer}
        horizontal
        wrap
        tokens={{ childrenGap: 10 }}
      >
        <Stack.Item styles={{ root: { marginLeft: 10 } }}>
          <Badge
            bgColor={ContractBadgeColors[contract?.currentStep?.internalName]}
          >
            {nextStep?.friendlyName ??
              TranslatedContractCurrentStep[
                contract?.currentStep?.internalName
              ]}
          </Badge>
        </Stack.Item>

        {contract?.flag && contract.flag !== ContractFlags.REGULAR && (
          <Stack.Item styles={{ root: { marginLeft: 10 } }}>
            <Badge bgColor={theme.semanticColors.severeWarningIcon}>
              {ContractFlags[contract.flag]}
            </Badge>
          </Stack.Item>
        )}
      </Stack>

      <div className={contentStyles.commandBarContainer}>
        <CommandBar
          items={buildMenu(
            contract,
            permissions,
            nextStep,
            setIsVisible,
            setIsContractOccurrencesDrawerVisible,
            action,
            toggleActionModal,
            setAction,
            setActionMessage,
            contractId,
            isButtonDisabled
          )}
          styles={{
            root: {
              padding: 0
            }
          }}
        />
      </div>
    </Stack>
  );
}

const buildMenu = (
  contract = {} as any,
  permissions,
  nextStep,
  setIsVisible,
  setIsContractOccurrencesDrawerVisible,
  action,
  toggleActionModal,
  setAction,
  setActionMessage,
  contractId,
  isButtonDisabled
) => {
  const status = [];
  let canSendDocuments = false;
  const doneAction = contract.approvalFlow?.steps?.find(
    step => step.internalName === "DONE"
  );
  const deliveredAction = contract.approvalFlow?.steps?.find(
    step => step.internalName === "SERVICE_DELIVERED"
  );
  const rejectedAction = contract.approvalFlow?.steps?.find(
    step => step.internalName === "REJECTED"
  );
  const canceledAction = contract.approvalFlow?.steps?.find(
    step => step.internalName === "CANCELED"
  );

  const downloadContract = async () => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const params = {
      id: contractId
    };

    const service = ContractsService();
    const response = await service.downloadContract({ params, signal });
    response.data && window.open(response.data, "_blank");
  };

  if (
    nextStep &&
    nextStep.interactionType === "ACTION_STATUS" &&
    permissions[PermissionsScope.POS]?.[PermissionsPosModule.CONTRACTS]?.some(
      contractAction =>
        contractAction === PermissionsAction.ALL ||
        contractAction === nextStep.interactionAction
    )
  ) {
    status.push({
      key: nextStep.id,
      text: nextStep.interactionName,
      onClick: () => {
        toggleActionModal();
        if (nextStep.interactionAction !== action) {
          setAction(nextStep.interactionAction);
          setActionMessage(nextStep.interactionName);
        }
      },
      id: `nextStep-${nextStep?.internalName}-button`.replace(/\s/g, ""),
      disabled: isButtonDisabled
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let newStatus: string | any[];
  const contractsStatus = contractsCache?.flatMap(value => value);
  if (contractsStatus.map(value => value?.id).includes(contract?.id)) {
    newStatus = status.filter(
      item =>
        item.text !== "Cancelar Contrato" && item.text !== "Rejeitar Contrato"
    );
  } else {
    newStatus = status;
  }
  if (
    nextStep &&
    nextStep.order < deliveredAction?.order &&
    canceledAction &&
    permissions[PermissionsScope.POS]?.[PermissionsPosModule.CONTRACTS]?.some(
      contractAction =>
        contractAction === PermissionsAction.ALL ||
        contractAction === canceledAction?.interactionAction
    )
  ) {
    status.push({
      key: canceledAction?.id,
      text: canceledAction?.interactionName,
      onClick: () => {
        toggleActionModal(canceledAction?.interactionAction);
        if (canceledAction?.interactionAction !== action) {
          setAction(canceledAction?.interactionAction);
          setActionMessage(canceledAction?.interactionName);
        }
      },
      id: `nextStep-${canceledAction?.internalName}-button`.replace(/\s/g, "-")
    });
  }

  if (
    nextStep &&
    nextStep.order < doneAction?.order &&
    rejectedAction &&
    permissions[PermissionsScope.POS]?.[PermissionsPosModule.CONTRACTS]?.some(
      contractAction =>
        contractAction === PermissionsAction.ALL ||
        contractAction === rejectedAction?.interactionAction
    )
  ) {
    status.push({
      key: rejectedAction?.id,
      text: rejectedAction?.interactionName,
      onClick: () => {
        toggleActionModal(rejectedAction.interactionAction);
        if (rejectedAction.interactionAction !== action) {
          setAction(rejectedAction.interactionAction);
          setActionMessage(rejectedAction?.interactionName);
        }
      },
      id: `nextStep-${rejectedAction?.internalName}-button`.replace(/\s/g, "-")
    });
  }

  if (contract?.approvalFlow) {
    const lastStepToSendDocument = contract.approvalFlow.steps.find(
      step => step.internalName === "SERVICE_DELIVERED"
    );

    if (
      contract.currentStep?.order >= 10 &&
      contract.currentStep?.order <= lastStepToSendDocument?.order
    ) {
      canSendDocuments = true;
    }
  }
  const menu = [
    {
      id: "button-print",
      key: "Imprimir",
      text: "Imprimir",
      iconProps: {
        iconName: "Print"
      },
      onClick: async () => {
        await downloadContract();
      },
      disabled: !contract.pdfPath
    },
    {
      id: "button-change-status",
      key: "status",
      text: "Alterar status",
      iconProps: {
        iconName: "Sync"
      },
      subMenuProps: {
        items: newStatus
      },
      disabled: newStatus?.length === 0
    },
    {
      id: "button-priattach-documentsnt",
      key: "Anexar Documento(s)",
      text: "Anexar Documento(s)",
      iconProps: {
        iconName: "DocumentSet"
      },
      disabled: !canSendDocuments,
      onClick: () => setIsVisible(true)
    }
  ];

  if (
    permissions[PermissionsScope.POS]?.[PermissionsPosModule.CONTRACTS]?.some(
      contractAction =>
        contractAction === PermissionsAction.ALL ||
        contractAction === "contract-occurrences"
    )
  ) {
    menu.push({
      id: "button-contract-history",
      key: "contractOccurrences",
      text: "Histórico do contrato",
      iconProps: {
        iconName: "FullHistory"
      },
      onClick: () => setIsContractOccurrencesDrawerVisible(true),
      disabled: false
    });
  }

  return menu;
};
