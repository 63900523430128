export enum FiltersTypeEnum {
  SELECT = "select",
  DATE = "date",
  DATE_RANGE = "dateRange",
  CHECKBOX = "checkbox",
  TEXT = "text",
  ARRAY_TEXT = "arrayText",
  MASK_TEXT = "maskText",
  CURRENT_ITEMS_ONLY = "currentItemsOnly",
  SERIAL_NUMBER = "serialNumber",
  NUMBER = "number"
}
