import { Alignment, Icon, Stack, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

import { PlanAppsTitle, PlansAppsInfoList } from "./styles";

import { productAppIcons } from "@/components/Offers/ProductOffers/ProductOfferPlans/ProductPlanItem";
import { ProductAppIcon } from "@/components/Offers/ProductOffers/ProductOfferPlans/ProductPlans.styles";

type PlanAppIconListProps = {
  appInfo: string[];
  appIcon: string[];
  verticalAlign: Alignment;
  height: string;
  appTitle?: string;
};

export function PlanAppIconList({
  appInfo = [],
  appIcon = [],
  appTitle,
  verticalAlign,
  height
}: PlanAppIconListProps): JSX.Element {
  const tooltipId = useId("tooltip-apps");

  return (
    <Stack
      horizontalAlign="center"
      tokens={{ padding: 20 }}
      verticalAlign={verticalAlign}
      style={{ height: height }}
    >
      {appTitle && <PlanAppsTitle>{appTitle}</PlanAppsTitle>}

      <Stack wrap horizontal horizontalAlign="center" verticalAlign="baseline">
        {appInfo?.length > 0 && (
          <TooltipHost
            tooltipProps={{
              onRenderContent: () =>
                appInfo?.length > 0 && (
                  <PlansAppsInfoList>
                    {appInfo?.map((info, index) => (
                      <li
                        className="ms-fontWeight-semibold"
                        key={"infoApp" + index}
                      >
                        {info}
                      </li>
                    ))}
                  </PlansAppsInfoList>
                )
            }}
            id={tooltipId}
            styles={{ root: { position: "relative", bottom: "10%" } }}
          >
            <Icon
              iconName="Info"
              aria-describedby={tooltipId}
              style={{
                fontSize: 16,
                cursor: "pointer"
              }}
            />
          </TooltipHost>
        )}

        {appIcon?.map(app => (
          <ProductAppIcon
            src={productAppIcons[app]}
            alt={app + " ICON APP"}
            key={app}
          />
        ))}
      </Stack>
    </Stack>
  );
}
