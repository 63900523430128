import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createModelsFailure,
  createModelsSuccess,
  editModelsFailure,
  editModelsSuccess,
  listModelsFailure,
  listModelsRequest,
  listModelsSuccess
} from "../actions/contractModels";
import { ModelsActionsTypes } from "../types/ContractModels.reducer.types";

import { SagaCustomError } from "@/common/types";
import { ModelsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createModels({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ModelsService("contracts/models", isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createModelsSuccess({
        message: `Modelo de contrato criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listModelsRequest(undefined, { isAdmin: true }));
  } catch (error) {
    yield put(
      createModelsFailure({
        code: error?.statusCode,
        message:
          "Não foi possível criar o modelo de contrato com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editModels({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ModelsService("contracts/models", isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editModelsSuccess({
        message: `Modelo de contrato editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listModelsRequest(undefined, { isAdmin: true }));
  } catch (error) {
    yield put(
      editModelsFailure({
        code: error?.statusCode,
        message:
          "Não foi possível editar o modelo de contrato com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listModels({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = ModelsService("contracts/models", isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }
    yield put(listModelsSuccess({ list: response.data }));
  } catch (error) {
    yield put(listModelsFailure());
  }
}

export default all([
  takeLatest(ModelsActionsTypes.CREATE_REQUEST, createModels),
  takeLatest(ModelsActionsTypes.EDIT_REQUEST, editModels),
  takeLatest(ModelsActionsTypes.LIST_REQUEST, listModels)
]);
