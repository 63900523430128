import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { useApi } from "../../useApi";

import { useAuth } from "@/core/libs/use-auth";
import { useSelectedBu } from "@/hooks/useSelectedBu";

export const useGetPabx = () => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const { selectedBU } = useSelectedBu();
  const pabxQueryClient = useQueryClient();

  useEffect(() => {
    pabxQueryClient.invalidateQueries(["pabx"]);
  }, [selectedBU]);

  const context = useQuery(
    ["pabx"],
    () => {
      const selectedBu = JSON.parse(localStorage.getItem("selectedBU"));
      const bu = selectedBu.id;

      return execute({
        url: `dashboards/pabx`,
        notificationMessage: "Buscando Benefícios PABX",
        params: { businessActionId: bu }
      });
    },
    {
      staleTime: Infinity,
      enabled: isAuthenticated
    }
  );

  const listOfPabxOffers = context.data?.data;
  const isLoadingPabxOffersList = context.isLoading;

  return {
    listOfPabxOffers,
    isLoadingPabxOffersList
  };
};
