import { DefaultButton, Stack, TextField } from "@fluentui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

type GoToProps = {
  route: string;
  label?: string;
};

export function GoTo({ route, label }: GoToProps) {
  const [routeId, setRouteId] = useState<string | number>();
  const history = useHistory();

  function handleGo() {
    routeId && history.push(`/${route}/${routeId}`);
  }

  return (
    <Stack horizontal gap={8} verticalAlign="center">
      <span>{label}</span>
      <TextField
        placeholder="ex.: 12345"
        onChange={e => setRouteId(e.currentTarget.value)}
      />
      <DefaultButton onClick={handleGo}>Ir para</DefaultButton>
    </Stack>
  );
}
