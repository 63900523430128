import { Modal, ProgressIndicator, Stack } from "@fluentui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { DisplayTermInfo } from "../../components/DisplayTermInfo";
import { TermSignaturesModal } from "../../components/TermSignaturesModal";
import { TermsViewMenu } from "../../components/ViewMenu/TermsViewMenu";
import { ITerm } from "../../interfaces/ITerm.interface";

import { ApprovalStep } from "@/components/Contracts/contract.interface";
import { ViewProgress } from "@/components/Contracts/ViewProgress";
import {
  CustomDrawer,
  DrawerActions,
  DrawerModules
} from "@/components/Shared/CustomDrawer";
import { MaritalStatusService } from "@/core/libs/api";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { FetchResponse } from "@/core/libs/api/utils/fetchData";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { EditAccounts } from "@/modules/Accounts/pages";
import { MaritalStatusTranslate } from "@/modules/Accounts/types";

export const ViewTerms = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [term, setTerm] = useState<ITerm>();
  const [steps, setSteps] = useState<ApprovalStep[]>();
  const [occurrencesDrawerVisible, setOccurrencesDrawerVisible] =
    useState<boolean>(false);
  const [drawerState, setDrawerState] = useState({
    action: null,
    isOpen: false
  });
  const [maritalStatusOptions, setMaritalStatusOptions] =
    useState<Array<{ key: number; text: string }>>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showSignaturesModal, setShowSignaturesModal] =
    useState<boolean>(false);

  const history = useHistory();
  const { selectedBU } = useSelectedBu();
  const { execute } = useApi();
  const drawerContainerRef = useRef<HTMLDivElement>(null);

  function openDrawer() {
    setDrawerState({ action: DrawerActions.EDIT, isOpen: true });
  }

  function closeDrawer() {
    setDrawerState({ action: "", isOpen: false });
  }

  const getTerm = useCallback(async () => {
    setIsLoading(true);
    const url = history.location.pathname;
    const splitURL = url.split("/");
    const termId = splitURL[splitURL.length - 1];

    const res = await execute({
      url: `terms/${termId}`,
      notificationMessage: `Buscando termo #${termId}`,
      params: {
        businessActionId: selectedBU.id
      }
    });

    const data: ITerm = res?.data;

    setCurrentStep(data?.currentStep?.order);
    setSteps(data?.approvalFlow?.steps);
    setTerm(res.data);
    setIsLoading(false);
  }, [history.location.pathname, selectedBU.id, execute]);

  const handleMaritalStatusRequest = useCallback(async () => {
    const { signal } = new AbortController();
    const service = MaritalStatusService("", true);

    const result = await service.list({ params: {}, signal });
    setMaritalStatusOptions(
      result.data?.items?.map(item => ({
        key: item.id,
        text: MaritalStatusTranslate[item.name]
      }))
    );
  }, []);

  const editAccount = useCallback(
    async (values, { setSubmitting }, closeDrawer): Promise<FetchResponse> => {
      const accountId = term?.account?.id;
      const res = await execute({
        url: `accounts/${accountId}`,
        method: "PATCH",
        params: { businessActionId: selectedBU.id },
        bodyObj: Object(values.body)
      });

      if (res?.response.ok) {
        getTerm();
      }

      if (!res?.response?.ok) {
        return res;
      }

      setShowSignaturesModal(true);
      closeDrawer();

      return res;
    },
    [term?.account?.id, execute, selectedBU.id, getTerm]
  );

  useEffect(() => {
    if (selectedBU) {
      setIsLoading(false);
    }
  }, [selectedBU]);

  useEffect(() => {
    getTerm();
    handleMaritalStatusRequest();
  }, [getTerm, handleMaritalStatusRequest]);

  return (
    <>
      <Helmet>
        <title>Visualização de termo - Libertas</title>
      </Helmet>
      {isLoading && (
        <ProgressIndicator
          styles={{
            root: { position: "absolute", left: 0, right: 0, top: 0 },
            itemProgress: { padding: 0 }
          }}
        />
      )}
      {term && (
        <Stack tokens={{ childrenGap: 15 }} style={{ position: "relative" }}>
          <TermsViewMenu
            term={term}
            refetchTerm={getTerm}
            currentStep={term?.currentStep}
            setOccurrencesDrawerVisible={setOccurrencesDrawerVisible}
            setIsLoading={setIsLoading}
          />

          <div>
            {steps?.length > 0 && (
              <ViewProgress currentStep={currentStep} steps={steps} />
            )}
          </div>

          <DisplayTermInfo term={term} openDrawer={openDrawer} />

          <Modal
            isOpen={showSignaturesModal}
            onDismiss={() => setShowSignaturesModal(false)}
            isBlocking={false}
          >
            <TermSignaturesModal
              term={term}
              toggleVisible={setShowSignaturesModal}
            />
          </Modal>

          <CustomDrawer
            isOpen={drawerState.isOpen}
            action={drawerState.action}
            module={DrawerModules.ACCOUNT}
            onCloseDrawer={closeDrawer}
            size="medium"
            drawerContainerRef={drawerContainerRef}
          >
            {drawerState.action === DrawerActions.EDIT && (
              <EditAccounts
                edit={editAccount}
                closeDrawer={closeDrawer}
                dismissMessage={() => {
                  return;
                }}
                drawerContainerRef={drawerContainerRef}
                message={null}
                addWhatsApp={true}
                selectedItem={String(term?.account?.id)}
                maritalStatusOptions={maritalStatusOptions}
                checkingAccountsRequired={false}
                isTerm={true}
              />
            )}
          </CustomDrawer>
        </Stack>
      )}
    </>
  );
};
