import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class MaritalStatus extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin: boolean) {
    super(
      isAdmin ? `admin/marital-status${subPaths}` : `marital-status${subPaths}`
    );
  }
}

export default MaritalStatus;
