import produce from "immer";

import {
  LinesActionsTypes,
  LinesReducerActions,
  LinesState
} from "../types/Lines.reducer.types";

const INITIAL_STATE: LinesState = {
  list: undefined,
  dashboardList: null,
  isLoadingList: false,
  isLoadingDashboardList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: LinesReducerActions
): LinesState {
  return produce(state, draft => {
    switch (action.type) {
      case LinesActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case LinesActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case LinesActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case LinesActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case LinesActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case LinesActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case LinesActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case LinesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case LinesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case LinesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }

      case LinesActionsTypes.LIST_DASHBOARD_REQUEST: {
        draft.isLoadingDashboardList = true;
        draft.dashboardList = null;
        break;
      }

      case LinesActionsTypes.LIST_DASHBOARD_FAIL: {
        draft.isLoadingDashboardList = false;
        draft.dashboardList = null;
        break;
      }

      case LinesActionsTypes.LIST_DASHBOARD_SUCCESS: {
        draft.isLoadingDashboardList = false;
        draft.dashboardList = action.payload.list;
        break;
      }
      default:
    }
  });
}
