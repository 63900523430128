import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const PlanAppsTitle = styled.h1`
  text-transform: uppercase;
  font-size: 18px;
  color: ${theme.palette.neutralTertiary};
  margin-bottom: 10px;
`;

export const PlansAppsInfoList = styled.ul`
  margin: 10px;
  padding: 0;

  li + li {
    margin-top: 10px;
  }

  > li {
    display: flex;
    align-items: center;
    color: ${theme.palette.blueDark};

    &::before {
      content: "";
      padding: 3px;
      margin-right: 5px;
      border-radius: 50%;
      display: inline-block;
      background: ${theme.palette.blueDark};
    }
  }
`;
