import Api from "./api";

class ShippingsGroupsAddresses extends Api {
  // TODO: Allow Create Edit Get List
  constructor(id: number) {
    super(`admin/shippings/groups/${id}/addresses`);
  }
}

export default ShippingsGroupsAddresses;
