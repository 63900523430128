import { Shimmer } from "@fluentui/react/lib/Shimmer";
import { TextField } from "@fluentui/react/lib/TextField";

import { TranslatedMaritalStatus } from "@/modules/Contracts/enums";
import { formatISOStringToDateString } from "@/utils/FormatISOStringToDateString";

interface FieldsProps {
  account: any;
}

export function LegalFields(props: FieldsProps): JSX.Element {
  const { account } = props;
  return (
    <div className="ms-Grid row" style={{ marginTop: 10 }}>
      <div className="ms-Grid-col ms-sm12 ms-lg4">
        <TextField
          label="Nome"
          disabled={true}
          defaultValue={account.fullName}
        />
      </div>
    </div>
  );
}

export function PhysicalFields(props: FieldsProps): JSX.Element {
  const { account } = props;

  return (
    <div className="ms-Grid row" style={{ marginTop: 10 }}>
      <div className="ms-Grid-col ms-sm12 ms-lg4">
        <TextField
          label="Nome"
          disabled={true}
          defaultValue={account.fullName}
        />
      </div>
      <div className="ms-Grid-col ms-sm12 ms-lg4">
        <TextField
          label="Profissão"
          disabled={true}
          defaultValue={account.occupation}
        />
      </div>
      <div className="ms-Grid-col ms-sm12 ms-lg4">
        <TextField
          label="Estado civil"
          disabled={true}
          defaultValue={TranslatedMaritalStatus[account.maritalStatus?.name]}
        />
      </div>
      <div className="ms-Grid-col ms-sm12 ms-lg4">
        <TextField
          label="Data de nascimento"
          disabled={true}
          defaultValue={formatISOStringToDateString(account.birthDate)}
        />
      </div>
    </div>
  );
}

export function ShimmerFields(): JSX.Element {
  return (
    <div className="ms-Grid row" style={{ marginTop: 20 }}>
      {[4, 4, 4, 4, 4, 4].map((size, index) => (
        <div
          className={`ms-Grid-col ms-sm12 ms-lg${size}`}
          style={{ marginTop: 10 }}
          key={index}
        >
          <Shimmer
            width="80px"
            styles={{
              root: {
                marginBottom: 5
              }
            }}
          />
          <Shimmer
            styles={{
              shimmerWrapper: {
                height: 30
              }
            }}
          />
        </div>
      ))}
    </div>
  );
}
