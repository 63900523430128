import produce from "immer";

import {
  ProfileActionsTypes,
  ProfileReducerActions,
  ProfileState
} from "../types";

export const INITIAL_STATE: ProfileState = {
  message: undefined,
  user: undefined,
  selectedBU: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ProfileReducerActions
): ProfileState {
  return produce(state, draft => {
    switch (action.type) {
      case ProfileActionsTypes.GET_PROFILE_SUCCESS: {
        draft.user = action.payload.user;
        draft.selectedBU = action.payload.selectedBU;
        break;
      }

      case ProfileActionsTypes.DISMISS_PROFILE: {
        draft.message = undefined;
        draft.user = undefined;
        draft.selectedBU = undefined;
        break;
      }

      case ProfileActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ProfileActionsTypes.EDIT_PROFILE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ProfileActionsTypes.EDIT_PROFILE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ProfileActionsTypes.EDIT_PROFILE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ProfileActionsTypes.SELECTBU_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ProfileActionsTypes.SELECTBU_SUCCESS: {
        draft.selectedBU = action.payload.selectedBU;
        break;
      }
      default:
    }
  });
}
