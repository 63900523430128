import styled from "styled-components";

interface ContainerProps {
  width: number | string;
  maxHeight: number | string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  max-height: ${props => props.maxHeight};
  width: ${props => props.width};

  border-radius: 5px;

  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2),
    0 8px 24px rgba(16, 22, 26, 0.2);

  z-index: 1000;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 4px;
  }
`;
