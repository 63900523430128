import { IBusinessUnit } from "../interfaces";

import { formatBUList } from "./FormatBuList";
import { markRecursive } from "./MarkRecursive";

import { TreeDataValue } from "@/core/libs/tree-select-dropdown/TreeSelectDropdown";

export const selectedNodesToTree = (
  businessUnits: TreeDataValue[],
  userBusinessUnits: IBusinessUnit[]
): TreeDataValue[] => {
  const selectedBuId = businessUnits?.map(businessUnit => businessUnit.value);
  const buList = formatBUList(userBusinessUnits);
  return markRecursive(buList, selectedBuId);
};
