import { ReactNode, createContext, useContext, useState } from "react";

import { UseApiAlerts } from "./useApiAlerts";

import {
  AlertProps,
  AlertTypes,
  IAlertContextData
} from "@/common/types/AlertTypes";
import { Alert } from "@/components/Shared/Alert/Alert";

const AlertContext = createContext<IAlertContextData>({} as IAlertContextData);

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertProps>({} as AlertProps);

  const { createApiAlertsResponse } = UseApiAlerts();

  const createAlert = (data: AlertProps) => {
    setAlert(data);
    setIsAlertOpen(true);
    if (data.type === AlertTypes.API) {
      const createResponseData = {
        ...data,
        createdAt: new Date().getTime()
      };
      createApiAlertsResponse(createResponseData);
    }
  };

  return (
    <AlertContext.Provider value={{ createAlert }}>
      {children}
      <Alert
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        {...alert}
      />
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  return context;
};
