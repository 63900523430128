import { ColumnActionsMode, IColumn, Icon } from "@fluentui/react";

import { translateTermSteps } from "../utils/translateTermSteps";

import { ColoredLabel } from "@/components/ColoredLabel/ColoredLabel";
import { ClickableDiv } from "@/modules/Contracts/styles";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";

export function buildTermsColumns({
  hasActions,
  history
}: {
  hasActions: boolean;
  history;
}) {
  const columns: IColumn[] = [
    {
      key: "fullName",
      name: "Nome",
      fieldName: "fullName",
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 400,
      isPadded: true,
      onRenderHeader: () => {
        return (
          <div>
            <Icon iconName="Contact" style={{ paddingRight: "5px" }} />
            Cooperado
          </div>
        );
      },
      onRender: item => {
        const termsPath = `/terms/${item.id}`;
        return (
          <ClickableDiv onClick={() => history.push(termsPath)} width="auto">
            {item.account.fullName}
          </ClickableDiv>
        );
      }
    },
    {
      key: "documentNumber",
      name: "CPF/CNPJ",
      fieldName: "documentNumber",
      isRowHeader: true,
      minWidth: 100,
      maxWidth: 300,
      onRender(value) {
        return value.account.documents
          .filter(
            documents =>
              documents.documentType === "CPF" ||
              documents.documentType === "CNPJ"
          )
          .map(document => cpfCnpjFormatter(document.documentNumber));
      },
      isPadded: true,
      onRenderHeader: () => {
        return (
          <div>
            <Icon iconName="ContactCard" style={{ paddingRight: "5px" }} />
            CPF/CNPJ
          </div>
        );
      }
    },
    {
      key: "businessUnitId",
      name: "Unidade de Negocio",
      fieldName: "businessUnit",
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 350,
      isPadded: true,
      onRender: ({ businessUnit }) => {
        const buParent = businessUnit?.parent;
        const names = [
          buParent?.parent?.name,
          buParent?.name,
          businessUnit.name
        ].filter(Boolean);
        const text = names.join(" -> ");
        return (
          <p
            style={{ margin: 0, cursor: "default", whiteSpace: "break-spaces" }}
            title={text}
          >
            {text}
          </p>
        );
      },
      onRenderHeader: () => {
        return (
          <div>
            <Icon iconName="EMI" style={{ paddingRight: "5px" }} />
            Unidade de Negócio
          </div>
        );
      }
    },
    {
      key: "currentStep",
      name: "Estado",
      minWidth: 160,
      maxWidth: 160,
      fieldName: "currentStep",
      isRowHeader: true,
      isPadded: true,
      isMultiline: true,
      onRender: item => (
        <ColoredLabel {...translateTermSteps(item.currentStep.internalName)} />
      ),
      onRenderHeader: () => {
        return (
          <div>
            <Icon iconName="ContactInfo" style={{ paddingRight: "5px" }} />
            Status
          </div>
        );
      }
    }
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "actions",
      fieldName: "actions",
      minWidth: 40,
      maxWidth: 40,
      columnActionsMode: ColumnActionsMode.disabled,
      isIconOnly: true
    });
  }

  return columns;
}
