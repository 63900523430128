import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Link as FabricLink } from "@fluentui/react/lib/Link";
import { Stack } from "@fluentui/react/lib/Stack";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import FormBuilder from "@/core/libs/form-builder";
import { useAuth } from "@/core/libs/use-auth";

const fields = [
  { label: "E-mail", type: "email", name: "email", value: "" },
  {
    label: "Senha",
    type: "password",
    name: "password",
    value: "",
    canRevealPassword: true
  }
];

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("E-mail inválido").required("Obrigatório"),
  password: Yup.string().required("Obrigatório")
});

function Login(): JSX.Element {
  return (
    <>
      <Helmet>
        <title>Login - Libertas</title>
      </Helmet>
      <FormBuilder
        fields={fields}
        onSubmit={useAuth().login}
        message={useAuth().message}
        dismissMessage={useAuth().dismissMessage}
        validationSchema={LoginSchema}
      >
        <PrimaryButton text="Entrar" allowDisabledFocus type="submit" />
        <Stack.Item align="end">
          <FabricLink as={Link} to="/recover">
            Esqueceu a senha?
          </FabricLink>
        </Stack.Item>
      </FormBuilder>
    </>
  );
}

export default Login;
