import { Pivot, PivotItem, ProgressIndicator, Stack } from "@fluentui/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { dismissMessage, editProfileRequest } from "./actions/profile";

import {
  OptOutComponent,
  ProfileComponent,
  SecurityComponent
} from "@/components/User";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import { RootState } from "@/core/store/rootReducer";

export default function UserProfile(): JSX.Element {
  const {
    profile: { message }
  } = useSelector((store: RootState) => store);
  const dispatch = useDispatch();

  const getProfile = useGetProfile();
  const user = getProfile.data?.data;

  useEffect(() => {
    dispatch(dismissMessage());
  }, [dispatch]);

  function editProfile(values, { setSubmitting }) {
    dispatch(editProfileRequest(values, { setSubmitting }));
  }

  return (
    <Stack styles={{ root: { width: "100%" } }}>
      <Helmet>
        <title>Configurações do usuário - Libertas</title>
      </Helmet>

      {user ? (
        <Stack tokens={{ childrenGap: 15 }}>
          <h2 className="ms-fontSize-24">Configurações do usuário</h2>
          <Pivot>
            <PivotItem headerText="Perfil" style={{ paddingTop: 20 }}>
              <ProfileComponent
                dismissMessage={() => dispatch(dismissMessage())}
                edit={editProfile}
                message={message}
                profile={user}
              />
            </PivotItem>
            <PivotItem headerText="Segurança" style={{ paddingTop: 20 }}>
              <SecurityComponent
                profile={user}
                dismissMessage={() => dispatch(dismissMessage())}
                edit={editProfile}
                message={message}
              />
            </PivotItem>
            <PivotItem headerText="Notificações" style={{ paddingTop: 20 }}>
              <OptOutComponent />
            </PivotItem>
          </Pivot>
        </Stack>
      ) : (
        <ProgressIndicator />
      )}
    </Stack>
  );
}
