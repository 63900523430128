import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const Title = styled.p`
  font-size: 1rem;
  color: ${theme.palette.neutralTertiary};
`;

export const Value = styled.p`
  font-size: 2rem;
  color: #2b88d8;
  span {
    font-size: 14px;
  }
`;
