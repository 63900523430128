import {
  IDropdownOption,
  Label,
  MaskedTextField,
  Text,
  Dropdown
} from "@fluentui/react";

import SearchLines from "./SearchLines";
import { DefaultProps, InitialCarrierType, StepForm } from "./types";

import { Carrier } from "@/modules/Settings/types/Plans.types";

interface ContractLineProps extends DefaultProps {
  planId: number;
  isCustomLineNumber: boolean;
  operation: {
    id: number;
    name: string;
    type: string;
    carriers: Carrier[];
  };
  carriers: IDropdownOption[];
  setInitialCarrier: (option: InitialCarrierType) => void;
  setQuestionStep: (step: StepForm) => void;
  values: any;
  initialCarrier: InitialCarrierType;
}

export function ContractLine({
  setInitialCarrier,
  setQuestionStep,
  setMessage,
  planId,
  isCustomLineNumber,
  message,
  setFieldValue,
  operation,
  carriers,
  values,
  initialCarrier
}: ContractLineProps) {
  if (isCustomLineNumber) {
    return (
      <>
        {operation.name === "Portabilidade" && (
          <>
            <Text variant={"medium"} style={{ fontWeight: 600 }}>
              Informe a operadora de origem
            </Text>

            <Dropdown
              id="select-carriers-origin"
              required
              onChange={(_, option) => {
                setInitialCarrier(option);
              }}
              placeholder="Selecione uma opção"
              options={carriers}
              styles={{
                root: { marginTop: 20, marginBottom: 10 },
                dropdown: { width: 200 }
              }}
            />
          </>
        )}

        <MaskedTextField
          id="input-phone-number"
          label="Digite o número"
          mask="(99) 99999-9999"
          required
          value={values.lineNumber}
          onChange={(_, item) => {
            setFieldValue("line", undefined);
            setFieldValue("lineNumber", item.replace(/\D/g, ""));

            if (item.replace(/\D/g, "").length === 11) {
              setQuestionStep(StepForm.LINE_STEP);
            } else {
              setQuestionStep(StepForm.PLAN_STEP);
            }
          }}
        />
      </>
    );
  }

  return (
    <>
      <Label required={true}>Selecione uma linha</Label>
      <SearchLines
        planId={planId}
        setQuestionStep={setQuestionStep}
        {...{ message, setMessage, setFieldValue, values }}
      />
    </>
  );
}
