import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StringifiableRecord } from "query-string";

import { ExecuteData, useApi } from "../../useApi";

import { IUseGetTermsProps } from "./useGetTerms.types";

import { useAuth } from "@/core/libs/use-auth";
import { useSelectedBu } from "@/hooks/useSelectedBu";

const TERMS_QUERY_TOKEN = "terms";

export const useGetTerms = ({
  params,
  urlString,
  method
}: IUseGetTermsProps) => {
  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();

  const { selectedBU } = useSelectedBu();

  const paramValues = {
    businessActionId: selectedBU.id,
    all: true,
    page: 1,
    ...params
  };

  const fetchData = async (params: StringifiableRecord) => {
    const data: ExecuteData = {
      url: urlString || "terms",
      method: method || "GET",
      notificationMessage: "Buscando Termos",
      params: params
    };

    if (data.method !== "GET") {
      data.bodyObj = Object(params);
    }

    return execute(data);
  };

  const { data, isFetching } = useQuery({
    queryKey: [TERMS_QUERY_TOKEN, paramValues],
    queryFn: () => fetchData(paramValues),
    enabled: isAuthenticated,
    staleTime: 120000,
    keepPreviousData: true
  });

  const invalidateQuery = async () => {
    queryClient.invalidateQueries({ queryKey: [TERMS_QUERY_TOKEN] });
  };

  return {
    termsList: data?.data,
    isLoadingTermsList: isFetching,
    invalidateQuery
  };
};
