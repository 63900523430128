import produce from "immer";

import {
  ModelsGroupsActionsTypes,
  ModelsGroupsReducerActions,
  ModelsGroupsState
} from "../types/ModelsGroups.reducer.types";

const INITIAL_STATE: ModelsGroupsState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ModelsGroupsReducerActions
): ModelsGroupsState {
  return produce(state, draft => {
    switch (action.type) {
      case ModelsGroupsActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ModelsGroupsActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsGroupsActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsGroupsActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ModelsGroupsActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ModelsGroupsActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsGroupsActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsGroupsActionsTypes.DELETE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ModelsGroupsActionsTypes.DELETE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsGroupsActionsTypes.DELETE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsGroupsActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case ModelsGroupsActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case ModelsGroupsActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
