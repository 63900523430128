import { Label, mergeStyles } from "@fluentui/react";

import { Tooltip } from "./Tooltip";

const labelClass = mergeStyles({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  whiteSpace: "nowrap",
  gap: "4px"
});

interface LabelProps {
  label: string;
  description?: string;
  required?: boolean;
  id?: string;
}

export const LabelWithTooltip = (props: LabelProps) => {
  const { label, description, required, id } = props;
  return (
    <div
      className={labelClass}
      style={{
        marginLeft: description ? "-12px" : "0"
      }}
    >
      {description && <Tooltip description={description} />}
      <Label id={id} htmlFor={id} required={required || false}>
        {label}
      </Label>
    </div>
  );
};
