import { getTheme, Stack } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const CarrierLogo = styled.img`
  height: 50px;
  margin: 20px 0;
`;

export const PlanOfferContainer = styled(Stack)`
  background: white;
  min-width: 250px;
  max-width: 350px;
  height: 100%;
  & > div {
    width: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  @media (max-height: 560px) {
    justify-content: initial;
  }
`;

export const PlanTitle = styled.h1`
  text-transform: uppercase;
  font-size: 18px;
  color: ${theme.palette.neutralTertiary};
  padding: 10px 0 0 0;
`;

export const PlanName = styled.p`
  font-size: 20px;
  color: ${theme.palette.neutralSecondaryAlt};
  font-weight: 600;
  padding: 10px 0;
  text-align: center;
`;

export const PlanText = styled.p<{ fontSize: string }>`
  font-size: ${props => props.fontSize};
  color: ${theme.palette.themeSecondary};
`;

export const PlanOfferRulesList = styled.ul`
  margin: 10px;
  padding: 0;

  li + li {
    margin-top: 10px;
  }

  > li {
    display: flex;
    align-items: center;
    color: ${theme.palette.blueDark};

    &::before {
      content: "";
      padding: 3px;
      margin-right: 5px;
      border-radius: 50%;
      display: inline-block;
      background: ${theme.palette.blueDark};
    }
  }
`;
