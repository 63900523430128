import { BannersActionsTypes } from "../types/Banners.reducer.types";

export function createRequest(values, { setSubmitting }, closePanel, isAdmin?) {
  return {
    type: BannersActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createSuccess(message) {
  return {
    type: BannersActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createFailure(message) {
  return {
    type: BannersActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editRequest(values, { setSubmitting }, closePanel, isAdmin?) {
  return {
    type: BannersActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editSuccess(message) {
  return {
    type: BannersActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editFailure(message) {
  return {
    type: BannersActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listRequest(filter?, isAdmin?) {
  return {
    type: BannersActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listSuccess({ list }) {
  return {
    type: BannersActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: BannersActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: BannersActionsTypes.DISMISS_MESSAGE
  };
}
