import { ModelsAttachmentsActionsTypes } from "../types/ModelsAttachments.reducer.types";

export function createModelsAttachmentsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ModelsAttachmentsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createModelsAttachmentsSuccess(message) {
  return {
    type: ModelsAttachmentsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createModelsAttachmentsFailure(message) {
  return {
    type: ModelsAttachmentsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editModelsAttachmentsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ModelsAttachmentsActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editModelsAttachmentsSuccess(message) {
  return {
    type: ModelsAttachmentsActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editModelsAttachmentsFailure(message) {
  return {
    type: ModelsAttachmentsActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listModelsAttachmentsRequest(filter, { isAdmin = false }) {
  return {
    type: ModelsAttachmentsActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listModelsAttachmentsSuccess({ list }) {
  return {
    type: ModelsAttachmentsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listModelsAttachmentsFailure() {
  return {
    type: ModelsAttachmentsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ModelsAttachmentsActionsTypes.DISMISS_MESSAGE
  };
}
