import { MutableRefObject, ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";

type CustomDrawerHeaderProps = {
  children: ReactNode | string;
  removePreviousTitle?: boolean;
  /**
   * Drawer Container reference
   */
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
};

export function CustomDrawerHeader({
  children,
  removePreviousTitle,
  drawerContainerRef
}: CustomDrawerHeaderProps): JSX.Element {
  const [drawerRef, setDrawerRef] = useState<Element | null>(null);
  const [previousTitle, setPreviousTitle] = useState<Element | null>(null);

  useEffect(() => {
    if (!drawerRef && drawerContainerRef) {
      const [defaultElementRef] =
        drawerContainerRef.current.getElementsByClassName(
          "custom-drawer-header"
        );

      if (defaultElementRef) {
        //remove default elemente title
        if (removePreviousTitle && defaultElementRef.firstElementChild) {
          setPreviousTitle(defaultElementRef.firstElementChild);
          defaultElementRef.removeChild(defaultElementRef.firstElementChild);
        }

        setDrawerRef(defaultElementRef);
      }
    }

    return () => {
      if (removePreviousTitle && drawerRef && previousTitle) {
        drawerRef.appendChild(previousTitle);
      }
    };
  }, [drawerContainerRef, drawerRef, previousTitle, removePreviousTitle]);

  if (!drawerRef) return null;

  return ReactDOM.createPortal(children, drawerRef);
}
