import { Icon } from "@fluentui/react";

import { UploadInput } from "./styles";

type UploadFileInputProps = {
  setFiles: (prevSate) => void;
  maxFiles: number;
  currentItemLength: number;
  uploadIconName: string;
  uploadTextDescription: string;
  allowedFileType: string;
};

export function UploadFileInput({
  setFiles,
  maxFiles,
  currentItemLength,
  uploadIconName,
  uploadTextDescription,
  allowedFileType
}: UploadFileInputProps): JSX.Element {
  const handleFileChange = e => {
    if (e.target.files.length > 0) {
      Array.from(e.target.files).forEach((file: any) => {
        const url = URL.createObjectURL(file);
        file.url = url;
        file.fileName = file.name;
      });

      const files = Array.from(e.target.files).filter(
        (file, index) => index + 1 + currentItemLength <= maxFiles
      );

      setFiles(prevState => {
        return [...prevState, ...files];
      });
    }
  };

  return (
    <UploadInput>
      <input
        type="file"
        id="files"
        name="files"
        accept={allowedFileType}
        multiple
        onChange={e => handleFileChange(e)}
      />
      <Icon
        iconName={uploadIconName}
        style={{ fontSize: 50, color: "#395ca3" }}
      />
      <span>{uploadTextDescription}</span>
    </UploadInput>
  );
}
