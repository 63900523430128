export const parseStringToFloatDoubleFixed = (value: string) => {
  if (value && value !== "") {
    if (value.includes(".") || value.includes(",")) {
      value = (parseFloat(value.replace(/\D/g, "")) / 100).toFixed(2);
    } else {
      value = parseFloat(value.replace(/\D/g, "")).toFixed(2);
    }
  } else {
    value = parseFloat("0").toFixed(2);
  }
  return value;
};
