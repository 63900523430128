import { Icon, IIconStyles, Separator, Stack } from "@fluentui/react";

import {
  ButtonBuy,
  ProductBrand,
  ProductDescription,
  ProductImage,
  ProductImageContainer,
  ProductInfoContainer,
  ProductTitle
} from "./styles";

import { ProductOfferItem } from "@/modules/Offers/types/ProductsOffers.types";

const orderImages = (a, b) => {
  return a?.order - b?.order;
};

const iconStyles: IIconStyles = {
  root: {
    fontSize: "24px",
    height: "24px",
    width: "24px"
  }
};

type ProductCardItemProps = {
  product: ProductOfferItem;
  setSelectedProduct: (product: ProductOfferItem) => void;
  toggleOpenModal: () => void;
};

export function ProductCardItem({
  product,
  setSelectedProduct,
  toggleOpenModal
}: ProductCardItemProps): JSX.Element {
  return (
    <Stack.Item
      grow
      className="ms-depth-16 ms-sm6 ms-md4 ms-lg4 ms-xl3"
      styles={{
        root: {
          background: "#ffffff",
          boxSizing: "border-box",
          maxWidth: "350px",
          minWidth: "235px",
          display: "flex",
          flexDirection: "column"
        }
      }}
    >
      {product?.images.length > 0 && (
        <ProductImageContainer>
          <ProductImage
            src={product?.images.sort(orderImages)[0]?.url}
            alt={product?.name + product?.id}
          />
        </ProductImageContainer>
      )}

      <Separator>
        <Icon iconName="CellPhone" styles={iconStyles} />
      </Separator>

      <ProductInfoContainer>
        {product?.brand?.name && (
          <ProductBrand className="ms-fontSize-18">
            {product.brand.name}
          </ProductBrand>
        )}

        {product?.name && (
          <ProductTitle className="ms-fontSize-24 ms-fontWeight-semibold">
            {product?.name}{" "}
            {product?.specifications?.color
              ? ` - ${product.specifications.color}`
              : ""}
          </ProductTitle>
        )}

        {product?.specifications?.memory?.storage && (
          <ProductDescription className="ms-fontSize-16">
            {product.specifications.memory.storage} GB de armazenamento
          </ProductDescription>
        )}

        {product?.specifications?.screen?.size && (
          <ProductDescription className="ms-fontSize-16">
            Tela de {product.specifications.screen.size}
          </ProductDescription>
        )}

        {product?.specifications?.battery?.capacity && (
          <ProductDescription className="ms-fontSize-16">
            Bateria de {product.specifications.battery.capacity} mAh
          </ProductDescription>
        )}

        {product?.inStock && (
          <ProductTitle className="ms-fontSize-16 ms-fontWeight-semibold">
            Quantidade em estoque: {product?.inStock}
          </ProductTitle>
        )}
      </ProductInfoContainer>

      <ButtonBuy
        onClick={() => {
          setSelectedProduct(product);
          toggleOpenModal();
        }}
        type="button"
      >
        Escolher
      </ButtonBuy>
    </Stack.Item>
  );
}
