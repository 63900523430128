import { makeRequest } from "../utils/fetchData";
import { MakeRequestOptions } from "../utils/types";

import Api, { IApi } from "./api";

class Models extends Api {
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/${subPaths}` : `${subPaths}`);
  }

  createModelService({ params, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}service-model-options/${params.id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  getModelsServices({ params, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}service-model-options/all/${params.id}`
    };
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }

  editModelService({ params, signal }: IApi) {
    let id = null;
    if (params.body.id && !params.id) {
      id = params.body.id;
    } else {
      id = params.id;
    }
    const options: MakeRequestOptions = {
      signal,
      method: "PATCH",
      baseUrl: `${this.baseUrl}/${id}`
    };

    options.bodyObj = params.body;
    return makeRequest(options);
  }
}

export default Models;
