import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createFailure,
  createSuccess,
  editFailure,
  editSuccess,
  listFailure,
  listRequest,
  listSuccess
} from "../actions/banners";
import { BannersActionsTypes } from "../types/Banners.reducer.types";

import { SagaCustomError } from "@/common/types";
import { OffersService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createBanner({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin = false } = payload;
  const service = OffersService("/banners", isAdmin);

  const valuesBeforeSubmit = Object.assign({}, values);
  const dataToSubmit = new FormData();

  valuesBeforeSubmit.validBetween = `[${values.from},${values.to})`;
  delete valuesBeforeSubmit.from;
  delete valuesBeforeSubmit.to;

  if (valuesBeforeSubmit.pricingGroups?.length > 0) {
    valuesBeforeSubmit.pricingGroups = JSON.stringify(
      valuesBeforeSubmit.pricingGroups.map(group => ({ id: group }))
    );
  }

  Object.keys(valuesBeforeSubmit).forEach(itemName => {
    if (
      valuesBeforeSubmit[itemName].length > 0 &&
      Array.isArray(valuesBeforeSubmit[itemName])
    ) {
      valuesBeforeSubmit[itemName].forEach(file => {
        dataToSubmit.append(itemName, file);
      });
    } else {
      dataToSubmit.append(itemName, valuesBeforeSubmit[itemName]);
    }
  });

  setSubmitting(true);

  try {
    const response = yield call(service.createBanner, {
      params: { body: dataToSubmit },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createSuccess({
        message: `Banner criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, isAdmin));
  } catch (error) {
    yield put(
      createFailure({
        code: error?.statusCode,
        message: "Não foi possível criar o banner com os dados informados.",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editBanner({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin = false } = payload;
  const service = OffersService("/banners", isAdmin);

  const valuesBeforeSubmit = Object.assign({}, values);
  const dataToSubmit = new FormData();

  valuesBeforeSubmit.validBetween = `[${values.from},${values.to})`;
  delete valuesBeforeSubmit.from;
  delete valuesBeforeSubmit.to;

  if (valuesBeforeSubmit.pricingGroups?.length > 0) {
    valuesBeforeSubmit.pricingGroups = JSON.stringify(
      valuesBeforeSubmit.pricingGroups.map(group => ({ id: group }))
    );
  }

  if (valuesBeforeSubmit.image) {
    const objectImages = valuesBeforeSubmit.image.filter(
      image => !(image instanceof File)
    );

    if (objectImages.length > 0) {
      delete valuesBeforeSubmit.image;
    }
  }

  Object.keys(valuesBeforeSubmit).forEach(itemName => {
    if (
      valuesBeforeSubmit[itemName].length > 0 &&
      Array.isArray(valuesBeforeSubmit[itemName])
    ) {
      valuesBeforeSubmit[itemName].forEach(file => {
        dataToSubmit.append(itemName, file);
      });
    } else {
      dataToSubmit.append(itemName, valuesBeforeSubmit[itemName]);
    }
  });

  setSubmitting(true);

  try {
    const response = yield call(service.editBanner, {
      params: { body: dataToSubmit, id: values.id },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editSuccess({
        message: `Banner editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, isAdmin));
  } catch (error) {
    yield put(
      editFailure({
        code: error?.statusCode,
        message: "Não foi possível editar o banner com os dados informados.",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listBanners({ payload }: AnyAction) {
  const { filter, isAdmin = false } = payload;
  const service = OffersService("/banners", isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listSuccess({ list: response.data }));
  } catch (error) {
    yield put(listFailure());
  }
}

export default all([
  takeLatest(BannersActionsTypes.CREATE_REQUEST, createBanner),
  takeLatest(BannersActionsTypes.EDIT_REQUEST, editBanner),
  takeLatest(BannersActionsTypes.LIST_REQUEST, listBanners)
]);
