import {
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize
} from "@fluentui/react";
import { useEffect, useState } from "react";

import { ModalContainer } from "./styles";

import { SelectFilter } from "@/core/libs/list-data/lib/Filters";
import { Organization } from "@/modules/Settings/types/BusinessUnits.types";
type OrganizationModalProps = {
  modalIsOpen: boolean;
  setModalIsOpen: (modalIsOpen: boolean) => void;
  sendDataFunction: (organizarionSelected: number) => void;
  organizations: Array<Organization>;
  organizationValue: Organization;
};

export const OrganizationModal = ({
  modalIsOpen,
  setModalIsOpen,
  sendDataFunction,
  organizations,
  organizationValue
}: OrganizationModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [organizationSelected, setOrganizationSelected] =
    useState<number>(null);
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    organizationValue && setOrganizationSelected(organizationValue.id);
  }, [organizationValue]);

  return (
    <ModalContainer>
      <Dialog
        hidden={!modalIsOpen}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Selecione a organização desejada",
          titleProps: {},
          isMultiline: true,
          subText:
            "A organização selecionada será utilizada para todo o aplicativo."
        }}
        styles={{
          root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }
        }}
      >
        <SelectFilter
          setSelectedKeys={value => {
            if (value?.organization) {
              setOrganizationSelected(value.organization);
            }
          }}
          _paginate={() => {
            return;
          }}
          selectedKeys={{ organization: organizationSelected }}
          filterConfigs={{
            maxWidth: "292px",
            minWidth: "292px",
            filterQuery: "organization",
            placeholder: "Selecione a organização",
            hideLabel: true,
            options: organizations?.map(org => ({
              key: org.id,
              text: org.name
            }))
          }}
        />
        {errorMessage && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline>
            {errorMessage}
          </MessageBar>
        )}
        <DialogFooter>
          {isSubmitting && (
            <Spinner
              size={SpinnerSize.xSmall}
              styles={{ root: { display: "inline-block" } }}
            />
          )}

          <PrimaryButton
            text={`Sim, enviar`}
            onClick={async () => {
              if (organizationSelected) {
                setIsSubmitting(true);
                await sendDataFunction(organizationSelected);
              } else {
                setErrorMessage("Por favor selecione a organização desejada.");
                setIsSubmitting(false);
                return;
              }

              setIsSubmitting(false);
              setModalIsOpen(false);
            }}
          />
        </DialogFooter>
      </Dialog>
    </ModalContainer>
  );
};
