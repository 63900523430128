import {
  Dialog,
  DialogFooter,
  DefaultButton,
  PrimaryButton,
  DialogType
} from "@fluentui/react";
import { FC } from "react";

import TagPicture from "@/components/Contracts/components/ItemReplacement/Tag/TagPicture";

interface ConfirmDialogProps {
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  serialNumber?: string;
  vehicleLicensePlate?: string;
  vehicleType?: string;
}

const TagConfirmationDialog: FC<ConfirmDialogProps> = ({
  isVisible,
  onConfirm,
  onCancel,
  serialNumber
}) => {
  const handleConfirm = () => onConfirm();
  const handleCancel = () => onCancel();

  return (
    <Dialog
      hidden={!isVisible}
      onDismiss={handleCancel}
      dialogContentProps={{
        type: DialogType.normal,
        title: "O número de série está correto?",
        subText: "O número está localizado no canto inferior direito da tag."
      }}
      modalProps={{
        isBlocking: false,
        containerClassName: "ms-dialogMainOverride"
      }}
    >
      <TagPicture serialNumber={serialNumber} />
      <DialogFooter>
        <PrimaryButton onClick={handleConfirm} text="Confirmar" />
        <DefaultButton onClick={handleCancel} text="Cancelar" />
      </DialogFooter>
    </Dialog>
  );
};

export default TagConfirmationDialog;
