export enum DrawerActions {
  CREATE = "Cadastrar",
  EDIT = "Editar",
  VIEW = "Visualizar",
  INCLUDE = "Incluir"
}

export enum DrawerModules {
  ACCOUNT = "Cooperado",
  ANEXO = "Anexo",
  BUSINESS_UNIT = "Unidade de negócio",
  BRAND = "Marca",
  CATEGORY = "Categoria",
  CARRIER = "Operadora",
  INVENTORY_OCCURRENCE = "Ocorrência de Inventário",
  CONTRACT = "Contrato",
  CONTRACT_MODEL = "Modelo de Contrato",
  DISTRIBUTION_CENTER = "Centro de Distribuição",
  LINE = "Linha(s)",
  LINE_OCCURRENCE = "Ocorrência de Linha",
  MODEL = "Modelo de Contrato",
  MODEL_GROUPS = "Grupo de Modelo de Contrato",
  ORGANIZATION = "Organização",
  SALES_TEAMS = "Time comercial",
  SERVICE_PROVIDERS = "Prestador de Benefício",
  PAYMENT_METHODS = "Metodos de Pagamento",
  OPERATION = "Operação",
  PLAN = "Plano de benefícios",
  PRODUCT = "Benefício combo",
  PERMISSION = "Permissão",
  PRICING_GROUP = "Grupo de Precificação",
  REPORTS = "Relatórios",
  ROLE = "Cargo",
  SHIPPING_TYPE = "Tipo de Frete",
  SHIPPING_GROUP = "Grupo de Tarifa",
  SERVICE = "Benefício(s) Prestado(s)",
  USER = "Usuário",
  LOG = "Log",
  ELECTRONIC_SIGNATURES = "Assinaturas Digitais",
  ADDITIONAL = "Atividades de valor agregado",
  ADDITIONAL_CATEGORY = "Categoria de Atividades de valor agregado"
}

export enum DrawerSize {
  small = 400,
  medium = 650,
  large = 800,
  fullScreen = "100%"
}
