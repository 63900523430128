import DropdownTreeSelect from "react-dropdown-tree-select";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > span {
    font-weight: 600;
    font-size: 14px;
    color: #323130;
  }

  > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 0.5rem;

    button {
      font-weight: 600;
      display: flex;
    }

    img {
      width: 1rem;
      height: 1rem;
      margin-right: 3px;
    }
  }
`;

export const Dropdown = styled(DropdownTreeSelect)`
  .dropdown {
    width: 100%;
    .dropdown-content {
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.26);
      border: 1px solid;
      border-color: #8a8886;
      padding-inline: 10px;
    }

    .dropdown-trigger {
      outline-offset: 2px;
      border-color: #8a8886 !important;
      color: #3e3e42;
      min-height: 40px;
      width: 100%;
      .tag {
        padding-inline: 10px;
        background-color: #f3f4f6;
        border-radius: 5px;
        cursor: default;
        color: #216dd0;
        font-weight: 600;
        opacity: 0.7;
        .tag button {
          display: none;
        }

        .tag-remove {
          margin-left: 5px;
          font-size: 14px;
          color: #323130;
          font-weight: 600;
        }
      }

      &.arrow.top::after,
      &.arrow.bottom::after {
        content: "\\E70D" !important;
        font-family: "FabricMDL2Icons";
        color: #555;
        font-size: 0.7rem;
        font-weight: 400;
        position: absolute;
        right: 10px;
        top: 25%;
      }
    }

    /* checkbox styles */
    .checkbox-item {
      position: relative;
      width: 1rem;
      height: 1rem;
      margin-right: 0.75rem;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0;
      vertical-align: middle;
    }

    .checkbox-item:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 2px solid #aaa;
      transition: all 0.3s ease-in-out;
    }

    .checkbox-item:checked:before {
      height: 50%;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      border-top-style: none;
      border-right-style: none;
      border-color: #2196f3;
    }

    .search {
      border-bottom: none;
      border-color: #8a8886;
      padding-inline-start: 8px;
    }

    .search::placeholder {
      border-color: #8a8886;
      color: #515155;
    }
  }
  .toggle {
    margin-left: 0.2rem;
    font: normal normal normal 18px/1 "Material Icons";
    color: #555;
    white-space: pre;
    margin-right: 4px;

    &.collapsed::after {
      cursor: pointer;
      content: "\\E70D";
      vertical-align: middle;
      font-family: "FabricMDL2Icons";
      font-size: 0.7rem;
    }

    &.expanded::after {
      cursor: pointer;
      content: "\\E70E";
      vertical-align: middle;
      font-family: "FabricMDL2Icons";
      font-size: 0.7rem;
    }
  }
`;
