import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Spinner,
  Stack,
  TextField
} from "@fluentui/react";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { Loader } from "@/components/Shared/Loader";
import { MessageBar, messageBarTypes } from "@/core/libs/message-bar";

interface PersonalizeTotalFeeProps {
  editContractRequest: ({ contractId, values }) => Promise<any>;
  refetchContract: () => void;
  contractId: number;
  closePersonalization: () => void;
  fields: any;
}

enum TotalFeeFieldsTranslated {
  activationFee = "Valor total da valor a ser cobrado de ativação",
  contractActivationFee = "Valor total da adesão",
  shipping = "Valor total do frete",
  sim = "Valor total do chip"
}

export function PersonalizeTotalFee({
  editContractRequest,
  refetchContract,
  closePersonalization,
  fields,
  contractId
}: PersonalizeTotalFeeProps): JSX.Element {
  const [initialValues, setInitialValues] = useState(undefined);
  const [schema, setSchema] = useState(null);
  const [message, setMessage] = useState(null);

  const cleanMessage = () => {
    setMessage(null);
  };

  useEffect(() => {
    const initialFields = Object.entries(fields)?.reduce(
      (allFields, [fieldName, value]) => {
        allFields[fieldName] = toLocaleString(value || "000");
        return allFields;
      },
      Object.create(null)
    );

    const initialSchema = Yup.object().shape(
      Object.entries(fields)?.reduce((allFields, [fieldName]) => {
        allFields[fieldName] = Yup.string();
        return allFields;
      }, Object.create(null))
    );

    setInitialValues(initialFields);
    setSchema(initialSchema);
    //eslint-disable-next-line
  }, []);

  const transformValuesToString = obj => {
    const newObj = {};
    for (const prop in obj) {
      newObj[prop] = obj[prop].toFixed(2).toString().replace(".", ",");
    }
    return newObj;
  };

  const compareObjects = (obj1, obj2) => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    for (const prop in obj1) {
      if (obj1[prop] !== obj2[prop]) {
        return false;
      }
    }

    return true;
  };
  const onSubmit = (values, { setSubmitting }) => {
    const obj = transformValuesToString(fields);
    if (values && compareObjects(values, obj)) {
      setTimeout(() => {
        closePersonalization();
      }, 1000);
    } else if (values) {
      setSubmitting(true);

      //Convert string values to number
      const totalFeeOverride = Object.entries(values).reduce(
        (allFields, [fieldName, value]) => {
          allFields[fieldName] = parseFloat(
            String(value)
              .replace(/[^0-9,.]/g, "")
              .replace(/[.]/g, "")
              .replace(",", ".")
          );
          return allFields;
        },
        Object.create(null)
      );

      editContractRequest({ contractId, values: { totalFeeOverride } }).then(
        response => {
          if (response.error) {
            setMessage({
              message:
                "Não foi possivel personalizar o contrato, por favor tente novamente",
              type: messageBarTypes.ERROR
            });
          } else {
            setMessage({
              message: "Contrato personalizado com sucesso.",
              type: messageBarTypes.SUCCESS
            });
            refetchContract();

            setTimeout(() => {
              closePersonalization();
            }, 1000);
          }

          setSubmitting(false);
        }
      );
    }
  };

  return (
    <>
      {!initialValues ? (
        <div
          style={{
            display: "flex",
            height: "120px",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spinner label="Carregando" />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting, setFieldValue }: any) => {
            return (
              <Form>
                <Stack
                  tokens={{ childrenGap: 15 }}
                  styles={{ root: { marginTop: 20 } }}
                >
                  {Object.entries(fields).map(([fieldName], index) => {
                    return (
                      <div key={`fields[${index}]`}>
                        <Field
                          name={fieldName}
                          type="text"
                          id={fieldName}
                          as={TextField}
                          prefix="R$"
                          label={TotalFeeFieldsTranslated[fieldName]}
                          errorMessage={
                            errors[fieldName] && touched[fieldName]
                              ? errors[fieldName]
                              : null
                          }
                          onChange={(event, newValue) =>
                            setFieldValue(
                              fieldName,
                              formatCurrencyMask(newValue)
                            )
                          }
                          maxLength={20}
                          required={true}
                        />
                      </div>
                    );
                  })}

                  {message && (
                    <MessageBar
                      message={message}
                      dismissMessage={cleanMessage}
                    />
                  )}

                  <DialogFooter>
                    <PrimaryButton
                      id="submit-button"
                      text="Salvar"
                      type="submit"
                    />
                    <DefaultButton
                      id="button-cancel"
                      onClick={closePersonalization}
                      text="Cancelar"
                    />
                  </DialogFooter>

                  {isSubmitting && <Loader />}
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}

function convertNumber(value) {
  return isNaN(value)
    ? Number(value.replace(/\./g, "").replace(/,/g, "."))
    : Number(value);
}

function toLocaleString(value) {
  return convertNumber(value).toLocaleString("pt-BR", {
    minimumFractionDigits: 2
  });
}

function formatCurrencyMask(valor) {
  if (valor) {
    const v = ((valor.replace(/\D/g, "") / 100).toFixed(2) + "").split(".");

    const m = v[0]
      .split("")
      .reverse()
      .join("")
      .match(/.{1,3}/g);

    for (let i = 0; i < m.length; i++)
      m[i] = m[i].split("").reverse().join("") + ".";

    const r = m.reverse().join("");

    return r.substring(0, r.lastIndexOf(".")) + "," + v[1];
  }
}
