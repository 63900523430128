import styled from "styled-components";

export const ContractProductsRenewalModalContainer = styled.section`
  margin: 16px;
  max-width: 560px;
  line-height: normal; /* Precisei fazer isso por causa da confusão de CSS em App.tsx */
`;

export const ContractProductsRenewalModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContractProductsRenewalModalContent = styled.section`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContractProductsRenewalModalFooter = styled.footer`
  margin: 16px 0;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
