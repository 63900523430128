import {
  Dropdown,
  DropdownMenuItemType,
  IconButton,
  MaskedTextField,
  Stack
} from "@fluentui/react";
import { Field } from "formik";
import { useEffect, useState } from "react";

import { SearchProductProps } from "../PersonalizeLineOffer";
import { SelectedProductContract } from "../types";

import { FieldsBuilder } from "@/core/libs/form-builder";
import { IMessageBar, MessageBar } from "@/core/libs/message-bar";

type ProductsOptions = {
  key: number;
  text: string;
  data: SelectedProductContract;
};

type ProductOverrideProps = {
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: string | number | boolean) => void;
  handleChangeSerialNumber: (
    serialNumber: string,
    operation: number,
    service: number
  ) => Promise<SearchProductProps | null>;
  cleanSerialNumberMessage: () => void;
  inStockProducts: ProductsOptions[];
  outOfStockProducts: ProductsOptions[];
  serialNumberMessage: IMessageBar;
  operation: number;
  service: number;
};

export function ProductOverride({
  values,
  errors,
  touched,
  setFieldValue,
  handleChangeSerialNumber,
  cleanSerialNumberMessage,
  serialNumberMessage,
  inStockProducts,
  outOfStockProducts,
  operation,
  service
}: ProductOverrideProps): JSX.Element {
  const [productsOptions, setProductsOptions] = useState(undefined);
  const [isSerialNumberDisabled, setIsSerialNumberDisabled] = useState<boolean>(
    !!values?.productItem?.inventory?.serialNumber
  );
  const serialNumberLength = 15;
  useEffect(() => {
    const dropdownProducts = [];

    if (inStockProducts?.length > 0) {
      dropdownProducts.push(
        {
          key: "inStockHeader",
          text: "Em estoque",
          itemType: DropdownMenuItemType.Header
        },
        ...inStockProducts
      );
    }

    if (outOfStockProducts?.length > 0) {
      dropdownProducts.push(
        { key: "divider_1", text: "-", itemType: DropdownMenuItemType.Divider },
        {
          key: "outOfStockHeader",
          text: "Fora de estoque (necessário digitar IMEI)",
          itemType: DropdownMenuItemType.Header
        },
        ...outOfStockProducts
      );
    }

    setProductsOptions(dropdownProducts);
  }, [inStockProducts, outOfStockProducts]);

  function handleProductChange(product) {
    const isProductInStock = !!product?.inventory?.serialNumber;

    setFieldValue("productItem", product);

    if (isProductInStock) {
      setFieldValue(
        "productCashValue",
        product?.saleConditions?.[0]?.cashValue || "000"
      );
      setFieldValue(
        "productFinancedValue",
        product?.saleConditions?.[0]?.financedValue || "000"
      );
      setFieldValue("serialNumber", product?.inventory?.serialNumber);
      setFieldValue("inventory", product?.inventory?.id);

      if (!isSerialNumberDisabled) {
        setIsSerialNumberDisabled(true);
      }
    } else {
      setFieldValue("productCashValue", "000");
      setFieldValue("productFinancedValue", "000");
      setFieldValue("serialNumber", "");

      if (isSerialNumberDisabled) {
        setIsSerialNumberDisabled(false);
      }
    }
  }

  function handleIMEIChange(serialNumber: string) {
    //remove mask
    const cleanValue = serialNumber.replace(/\D/g, "");
    setFieldValue("serialNumber", cleanValue);

    if (cleanValue.length === serialNumberLength) {
      handleChangeSerialNumber(cleanValue, operation, service).then(
        productId => {
          if (productId) {
            setFieldValue("inventory", productId.inventory?.id);
            const product = productsOptions.find(
              currentProduct => currentProduct.key === productId.id
            );
            setFieldValue("productItem", product.data);
            setFieldValue(
              "productCashValue",
              product.data?.saleConditions?.[0]?.cashValue || "000"
            );
            setFieldValue(
              "productFinancedValue",
              product.data?.saleConditions?.[0]?.financedValue || "000"
            );

            setIsSerialNumberDisabled(true);
          } else {
            setFieldValue("productItem.id", "");
            setFieldValue("productCashValue", "000");
            setFieldValue("productFinancedValue", "000");
          }
        }
      );
    }
  }

  return (
    <Stack className="ms-Grid-col ms-sm12 ms-lg6" style={{ maxWidth: 450 }}>
      <Stack.Item tokens={{ margin: "0 0 20px 0" }}>
        <h1 className="ms-fontSize-28">Combo</h1>
      </Stack.Item>

      <Stack.Item grow className="ms-Grid-col ms-sm12 ms-lg12">
        <Field
          label="Benefício combo"
          name="productItem"
          required
          type="select"
          id="productItem"
          as={Dropdown}
          placeholder={
            productsOptions
              ? "Selecione uma opção"
              : "Nenhum aparelho encontrado"
          }
          key="productItem"
          selectedKey={values?.productItem?.id}
          onChange={(evt, item) => handleProductChange(item.data)}
          errorMessage={
            errors["productItem"]?.id && touched["productItem"]
              ? errors["productItem"]?.id
              : null
          }
          options={productsOptions}
          disabled={!productsOptions}
          calloutProps={{ calloutMaxHeight: 500 }}
        />
      </Stack.Item>

      <Stack.Item grow className="ms-Grid-col ms-sm12 ms-lg12">
        <FieldsBuilder
          {...{
            fields: [
              [
                {
                  label: "Valor à vista",
                  type: "money",
                  name: "productCashValue",
                  value:
                    values.productCashValue ||
                    values?.productItem?.productCashValue ||
                    "000"
                }
              ]
            ],
            errors,
            touched,
            setFieldValue,
            values
          }}
        />
      </Stack.Item>

      <Stack.Item grow className="ms-Grid-col ms-sm12 ms-lg12">
        <FieldsBuilder
          {...{
            fields: [
              [
                {
                  label: "Valor à prazo",
                  type: "money",
                  name: "productFinancedValue",
                  value:
                    values.productFinancedValue ||
                    values?.productItem?.productFinancedValue ||
                    "000"
                }
              ]
            ],
            errors,
            touched,
            setFieldValue,
            values
          }}
        />
      </Stack.Item>

      <Stack horizontal verticalAlign="center">
        <Stack.Item grow className="ms-Grid-col ms-sm12 ms-lg12" align="center">
          <Field
            label="IMEI"
            name="serialNumber"
            required
            type="text"
            as={MaskedTextField}
            mask="******-**-******-*"
            key="serialNumber"
            value={values.serialNumber || values?.productItem?.serialNumber}
            onChange={(evt, newValue) => handleIMEIChange(newValue)}
            errorMessage={
              errors["serialNumber"] && touched["serialNumber"]
                ? errors["serialNumber"]
                : null
            }
            disabled={isSerialNumberDisabled}
          />
        </Stack.Item>

        {isSerialNumberDisabled && (
          <Stack.Item align="center" tokens={{ margin: "8px 0 0 0" }}>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Editar IMEI"
              ariaLabel="Editar IMEI"
              styles={{
                icon: {
                  fontSize: 20
                }
              }}
              onClick={() => setIsSerialNumberDisabled(!isSerialNumberDisabled)}
            />
          </Stack.Item>
        )}
      </Stack>

      {serialNumberMessage && (
        <Stack
          className="ms-Grid-col ms-sm12 ms-lg12"
          tokens={{ padding: "20px 0" }}
        >
          <MessageBar
            message={serialNumberMessage}
            dismissMessage={cleanSerialNumberMessage}
          />
        </Stack>
      )}
    </Stack>
  );
}
