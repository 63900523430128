import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório."),
  combo: Yup.string().required("Campo obrigatório."),
  plan: Yup.number().required("Campo obrigatório."),
  fidelityDuration: Yup.number()
    .min(0, "A duração mínima de fidelidade é nenhum mês.")
    .max(36, "A duração máxima de fidelidade é 36 meses.")
    .required("Campo obrigatório."),
  operations: Yup.array().required("Campo obrigatório.")
});

export const additionalSchemaDefault = Yup.object().shape({
  name: Yup.string().required("Obrigatório."),
  activationValue: Yup.string().required("Obrigatório."),
  monthlyFee: Yup.string().required("Obrigatório."),
  additionalId: Yup.number().required("Obrigatório.")
});

export const additionalSchemaBonus = additionalSchemaDefault.concat(
  Yup.object().shape({
    bonus: Yup.number()
      .typeError("Bônus deve ser um número.")
      .required("Obrigatório.")
  })
);

export const additionalSchemaProduct = additionalSchemaDefault.concat(
  Yup.object().shape({
    productId: Yup.number().required("Obrigatório.")
  })
);
