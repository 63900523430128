import produce from "immer";

import {
  PermissionsActionsTypes,
  PermissionsReducerActions,
  PermissionsState
} from "../types/Permissions.reducer.types";

const INITIAL_STATE: PermissionsState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: PermissionsReducerActions
): PermissionsState {
  return produce(state, draft => {
    switch (action.type) {
      case PermissionsActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case PermissionsActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case PermissionsActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case PermissionsActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case PermissionsActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case PermissionsActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case PermissionsActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case PermissionsActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case PermissionsActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case PermissionsActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
