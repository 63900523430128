import { IDetailsListStyles } from "@fluentui/react";
import styled from "styled-components";

export const detailsListStyles: Partial<IDetailsListStyles> = {
  root: {
    overflowX: "clip" // this is necessary for position: sticky to work
  },
  headerWrapper: {
    position: "sticky",
    top: 0,
    zIndex: 1
  }
};

export const ContractProductsRenewalReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContractProductsRenewalReviewInfoTitle = styled.span`
  font-weight: 600;
`;

export const ContractProductsRenewalReviewWrapper = styled.div`
  max-height: 487px;
  overflow: auto;
`;
