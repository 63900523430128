import { Permission } from "@/common/types/permissions";

export function transformPermissions(permissions: Permission[]) {
  const localPermissions = permissions.reduce(
    (totalPermissions: Permission[], currentPermission: Permission) => {
      const { scope, module, actions } = currentPermission;

      totalPermissions[scope] ??= {};

      const moduleExistisOnScope = Object.prototype.hasOwnProperty.call(
        totalPermissions[scope],
        module
      );

      if (moduleExistisOnScope) {
        //merges actions from the same module
        totalPermissions[scope][module].push(...actions);
      } else {
        //adds a new module and it's initial actions
        totalPermissions[scope][module] = actions;
      }

      return totalPermissions;
    },
    Object.create(null)
  );

  return localPermissions;
}
