import { IDropdownOption } from "@fluentui/react";
import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";

import { InsertItemFields } from "./InsertItemFields";
import {
  FormValuesType,
  InitialCarrierType,
  InsertItemProps,
  SelectedProductContract,
  StepForm
} from "./types";

import { IAdditionalOfferPrice } from "@/components/Settings/AdditionalComponents/types";
import { Loader } from "@/components/Shared/Loader";
import { ContractsService, ProductsService } from "@/core/libs/api";
import CarriersApi from "@/core/libs/api/lib/carriers";
import { IMessageBar, messageBarTypes } from "@/core/libs/message-bar";
import { ProductContractOperation } from "@/modules/Offers/types/ProductsOffers.types";
import { isObjectEmpty } from "@/utils/IsObjectEmpty";

const contractsService = ContractsService();
const productsService = ProductsService();
const carriersApi = new CarriersApi("", false);

export async function listAllOffers(
  service: number,
  businessActionId: number,
  setMessage: (message: { message: string; type: messageBarTypes }) => void,
  setParentOperations,
  setOperations
): Promise<void> {
  const abortController = new AbortController();
  const { signal } = abortController;

  const params = { query: { service, businessActionId } };

  const response = await contractsService.listOffers({ params, signal });

  if (response.error) {
    setMessage({
      message:
        response.error?.message ??
        "Não foi possível listar as operações, por favor atualize a página",
      type: messageBarTypes.ERROR
    });

    return;
  }

  const operations: IDropdownOption[] = [];

  response.data.forEach(operation => {
    operations.push({
      key: operation.id,
      text: operation.name,
      data: operation
    });
  });

  setParentOperations(response.data);
  setOperations(operations);
}

export const getDeviceList = (
  offerPriceId: number,
  businessActionId: number
) => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const params = {
    query: { offerPrice: offerPriceId, businessActionId }
  };

  return contractsService.getProductsList({ params, signal });
};

export const getDeviceForPersonalizationList = businessActionId => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const params = {
    query: { businessActionId }
  };

  return productsService.list({ params, signal });
};

export const searchProductBySerialNumber = (
  businessActionId: number,
  serialNumber: string,
  operation: number,
  service: number
) => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const params = {
    query: { businessActionId, serialNumber, operation, service }
  };

  return productsService.searchProduct({ params, signal });
};

const createContractItem = (
  values,
  contractId: number,
  { businessActionId }: { businessActionId: number }
) => {
  const abortController = new AbortController();
  const { signal } = abortController;
  const params = {
    id: contractId,
    body: values,
    query: { businessActionId }
  };

  return contractsService.saveItem({ params, signal });
};

const schema = Yup.object().shape({
  operation: Yup.number().required("Obrigatório"),
  offerPrice: Yup.number().required("Obrigatório"),
  additionalInfo: Yup.object().shape({
    planIsConectivityOrSmart: Yup.boolean(),
    serialNumber: Yup.string().min(
      15,
      "O Número de Serial deve ter 15 digitos."
    ),
    installment: Yup.string().when("planIsConectivityOrSmart", {
      is: true,
      then: Yup.string().typeError("Obrigatório informar o número de parcelas.")
    })
  })
});

const schemaWithLineId = Yup.object().shape({
  line: Yup.number().required("Obrigatório")
});

const schemaWithLineNumber = Yup.object().shape({
  lineNumber: Yup.string().required("Obrigatório")
});

export function InsertItem({
  contract,
  close,
  parcelLimit,
  drawerContainerRef
}: InsertItemProps): JSX.Element {
  const [isLoadingFirstRequest, setIsLoadingFirstRequest] = useState(true);
  const [message, setMessage] = useState<IMessageBar>(null);
  const [carrier, setCarrier] = useState<string | number>(undefined);

  const [operations, setOperations] = useState([]);
  const [parentOperations, setParentOperations] = useState([]);
  const [offers, setOffers] = useState([]);

  const [isPersonalizedOffer, setIsPersonalizedOffer] =
    useState<boolean>(false);
  const [personalizedOffer, setStatePersonalizedOffer] = useState(false);
  const [questionStep, setQuestionStep] = useState<StepForm>(
    StepForm.INITIAL_STEP
  );
  const [selectedOfferPriceId, setSelectedOfferPriceId] =
    useState<number>(undefined);
  const [isSmartOffer, setIsSmartOffer] = useState<boolean>(false);
  const [contractProducts, setContractProducts] = useState(undefined);
  const [selectedProductContract, setSelectedProductContract] =
    useState<SelectedProductContract>(undefined);

  const [carriersList, setCarriersList] = useState();
  const [initialCarrier, setInitialCarrier] =
    useState<InitialCarrierType>(undefined);
  const [additionalOfferPrice, setAdditionalOfferPrice] = useState<
    IAdditionalOfferPrice[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValuesType>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [currentSchema, setCurrentSchema] = useState(schema);

  useEffect(() => {
    listAllOffers(
      contract.service.id,
      contract.businessUnit.id,
      setMessage,
      setParentOperations,
      setOperations
    ).finally(() => setIsLoadingFirstRequest(false));
  }, [contract.service.id, contract.businessUnit.id]);

  useEffect(() => {
    getCarriers();
  }, []);

  useEffect(() => {
    if (selectedOfferPriceId) {
      const selectedOffer = offers.find(
        offer => offer.key === selectedOfferPriceId
      );
      setAdditionalOfferPrice(
        selectedOffer.details.offerPrice.additionalOfferPrice
      );
    }
  }, [selectedOfferPriceId, offers]);

  useEffect(() => {
    if (selectedProductContract) {
      setSelectedProductContract(undefined);
    }

    if (isSmartOffer) {
      const handleProductList = async () => {
        const response = await getDeviceList(
          selectedOfferPriceId,
          contract.businessUnit.id
        );
        if (response?.data?.length > 0) {
          const formatToSelect = response.data.map(product => ({
            key: product.id,
            text: `${product?.name} ${
              product.specifications?.memory?.storage
                ? `• ${product.specifications?.memory?.storage}GB`
                : ""
            } ${
              product.specifications?.memory?.ram
                ? `• ${product.specifications?.memory?.ram}GB RAM`
                : ""
            } ${
              product.specifications?.screen?.size
                ? `• Tela ${product.specifications?.screen?.size}"`
                : ""
            } ${
              product.specifications?.color
                ? `• ${product.specifications?.color}`
                : ""
            } ${
              product.inventory?.serialNumber
                ? `• IMEI ${product.inventory?.serialNumber}`
                : ""
            } ${
              product.inventory?.distributionCenter?.id
                ? `• #${product.inventory?.distributionCenter?.id}`
                : ""
            } ${
              product.inventory?.distributionCenter?.name
                ? ` ${product.inventory?.distributionCenter?.name}`
                : ""
            }`,
            data: product
          }));

          setContractProducts(formatToSelect);
        } else {
          if (contractProducts) {
            setContractProducts(undefined);
          }
        }
      };

      handleProductList();
    }
    //(this rule does not apply in this context)
  }, [selectedOfferPriceId, isSmartOffer]);

  useEffect(() => {
    if (selectedProductContract) {
      setSelectedProductContract(undefined);
    }

    if (questionStep !== StepForm.INITIAL_STEP) {
      setQuestionStep(StepForm.INITIAL_STEP);
    }

    if (selectedOfferPriceId) {
      setSelectedOfferPriceId(undefined);
    }

    if (isPersonalizedOffer) {
      removePersonalizedOffer();
    }
  }, [carrier]);

  const closePanel = (ev, refresh?) => {
    close(ev, refresh);
  };

  const removePersonalizedOffer = () => {
    setIsPersonalizedOffer(false);
    setStatePersonalizedOffer(undefined);

    if (selectedProductContract) {
      setSelectedProductContract(undefined);
    }
  };

  const initialValues = {
    operation: "",
    offerPrice: "",
    additionalInfo: {
      planIsConectivityOrSmart: false,
      installment: ""
    }
  };

  const onSubmit = useCallback(
    (additionalContractItems = [], values = formValues) => {
      setIsSubmitting(true);
      const submitValues = Object.assign({}, values);
      submitValues.additionalContractItems = additionalContractItems;

      if (
        submitValues.operation ===
        Number(ProductContractOperation.PORTABILIDADE)
      ) {
        submitValues.carrier = carrier;
        if (initialCarrier) {
          submitValues.initialCarrier = initialCarrier.key;
        }
      }

      if (isPersonalizedOffer && personalizedOffer) {
        submitValues.offerOverride = personalizedOffer;

        if (values?.offerOverride) {
          submitValues.offerOverride = {
            ...submitValues.offerOverride,
            ...values.offerOverride
          };
        }
      } else if (
        submitValues.offerOverride &&
        isObjectEmpty(submitValues.offerOverride)
      ) {
        delete submitValues.offerOverride;
      }

      //remove prop used only in front-end
      if (submitValues?.additionalInfo?.planIsConectivityOrSmart) {
        delete submitValues.additionalInfo.planIsConectivityOrSmart;
      }

      //remove empty object
      if (
        !submitValues?.additionalInfo ||
        isObjectEmpty(submitValues?.additionalInfo)
      ) {
        delete submitValues?.additionalInfo;
      }

      createContractItem(submitValues, contract.id, {
        businessActionId: contract.businessUnit.id
      }).then(response => {
        if (response.error) {
          setMessage({
            message:
              response.error?.message ??
              "Não foi possível adicionar o benefício combo, por favor tente novamente",
            type: messageBarTypes.ERROR
          });

          return setIsSubmitting(false);
        } else {
          setIsSubmitting(false);

          return closePanel(false, true);
        }
      });
    },
    [
      carrier,
      closePanel,
      contract,
      formValues,
      initialCarrier,
      isPersonalizedOffer,
      personalizedOffer
    ]
  );

  const getCarriers = async () => {
    const carriersList = await carriersApi.listCarriers();

    setCarriersList(
      carriersList
        .filter(carrier => carrier.name !== "Taggy")
        .map(carrier => ({ text: carrier.name, key: carrier.id }))
    );
  };

  const openAdditionalModalOrSubmit = useCallback(
    async values => {
      setFormValues(values);

      if (additionalOfferPrice.length > 0) {
        setIsModalOpen(true);
      } else {
        onSubmit([], values);
      }
    },
    [onSubmit, additionalOfferPrice]
  );

  return (
    <>
      {isLoadingFirstRequest ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={currentSchema}
          onSubmit={openAdditionalModalOrSubmit}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <InsertItemFields
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              selectedProductContract={selectedProductContract}
              parentOperations={parentOperations}
              contract={contract}
              contractProducts={contractProducts}
              setSelectedProductContract={setSelectedProductContract}
              offers={offers}
              operations={operations}
              setOffers={setOffers}
              selectedOfferPriceId={selectedOfferPriceId}
              additionalOfferPrice={additionalOfferPrice}
              carriersList={carriersList}
              carrier={carrier}
              initialCarrier={initialCarrier}
              setCarrier={setCarrier}
              setInitialCarrier={setInitialCarrier}
              setSelectedOfferPriceId={setSelectedOfferPriceId}
              setIsSmartOffer={setIsSmartOffer}
              isPersonalizedOffer={isPersonalizedOffer}
              removePersonalizedOffer={removePersonalizedOffer}
              personalizedOffer={personalizedOffer}
              setIsPersonalizedOffer={setIsPersonalizedOffer}
              setStatePersonalizedOffer={setStatePersonalizedOffer}
              isSubmitting={isSubmitting}
              onSubmit={onSubmit}
              drawerContainerRef={drawerContainerRef}
              closePanel={closePanel}
              parcelLimit={parcelLimit}
              message={message}
              setMessage={setMessage}
              schema={schema}
              setCurrentSchema={setCurrentSchema}
              schemaWithLineNumber={schemaWithLineNumber}
              schemaWithLineId={schemaWithLineId}
              questionStep={questionStep}
              setQuestionStep={setQuestionStep}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </Formik>
      )}
    </>
  );
}
