import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createBUFailure,
  createBUSuccess,
  editBUFailure,
  editBUSuccess,
  listBUFailure,
  listBURequest,
  listBUSuccess
} from "../actions/businessUnits";
import { BusinessUnitsActionsTypes } from "../types/BusinessUnits.reducer.types";

import { SagaCustomError } from "@/common/types";
import { BusinessUnitsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";
import { isObjectEmpty } from "@/utils/IsObjectEmpty";
import { removeEmptyObjectsFromArray } from "@/utils/RemoveEmptyObjectsFromArray";
import { removeEmptyPropsFromObject } from "@/utils/RemoveEmptyPropsFromObject";

const abortController = new AbortController();
const { signal } = abortController;
const arrayCharacter = "[";
const objectCharacter = "{";

function handleValuesBeforeSubmitting(values) {
  if (values.settings?.length > 0) {
    values.settings = values.settings.reduce((allSettings, currentSetting) => {
      const firstCharacter = currentSetting.value?.charAt(0);
      if (
        firstCharacter === arrayCharacter ||
        firstCharacter === objectCharacter
      ) {
        allSettings[currentSetting.configName] = JSON.parse(
          currentSetting.value
        );
      } else {
        allSettings[currentSetting.configName] = currentSetting.value;
      }
      return allSettings;
    }, Object.create(null));

    removeEmptyPropsFromObject(values.settings);

    if (isObjectEmpty(values.settings)) delete values.settings;
  }

  if (values.primaryAddress) {
    [values.primaryAddress] = values.primaryAddress;

    removeEmptyPropsFromObject(values.primaryAddress);

    if (isObjectEmpty(values.primaryAddress)) {
      delete values.primaryAddress;
    }
  }

  if (values.contacts?.length > 0) {
    values.contacts = removeEmptyObjectsFromArray(values.contacts);

    if (values.contacts.length < 1) delete values.contacts;
  }

  if (values.signers?.length > 0) {
    values.signers = removeEmptyObjectsFromArray(values.signers);

    if (values.signers.length < 1) delete values.signers;
  }

  if (values.documents?.length > 0) {
    values.documents = removeEmptyObjectsFromArray(values.documents);

    if (values.documents.length < 1) delete values.documents;
  }
}

export function* createBusinessUnit({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = BusinessUnitsService(undefined, isAdmin);

  setSubmitting(true);
  //Deep copy of the object without reference
  const newValues = JSON.parse(JSON.stringify(values));

  handleValuesBeforeSubmitting(newValues);

  try {
    const response = yield call(service.create, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createBUSuccess({
        message: `Unidade de negócio criada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listBURequest({ limit: 999 }, { isAdmin }));
  } catch (error) {
    yield put(
      createBUFailure({
        code: error?.statusCode,
        message:
          "Não foi possível criar a unidade de negócio com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editBusinessUnit({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = BusinessUnitsService(undefined, isAdmin);

  setSubmitting(true);
  //Deep copy of the object without reference
  const newValues = JSON.parse(JSON.stringify(values));

  handleValuesBeforeSubmitting(newValues);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...newValues } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editBUSuccess({
        message: `Unidade de negócio editada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listBURequest({ limit: 999 }, { isAdmin }));
  } catch (error) {
    yield put(
      editBUFailure({
        code: error?.statusCode,
        message:
          "Não foi possível editar a unidade de negócio com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listBusinessUnit({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = BusinessUnitsService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listBUSuccess({ list: response.data }));
  } catch (error) {
    yield put(listBUFailure());
  }
}

export default all([
  takeLatest(BusinessUnitsActionsTypes.CREATE_BU_REQUEST, createBusinessUnit),
  takeLatest(BusinessUnitsActionsTypes.EDIT_BU_REQUEST, editBusinessUnit),
  takeLatest(BusinessUnitsActionsTypes.LIST_BU_REQUEST, listBusinessUnit)
]);
