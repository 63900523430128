import Api from "./api";

class Shippings extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/shippings${subPaths}` : `shippings${subPaths}`);
  }
}

export default Shippings;
