import { Wrapper } from "./styles";

interface IColoredLabel {
  backgroundColor: string;
  color: string;
  children: string;
  borderRadius?: string;
  borderColor?: string;
}

export const ColoredLabel = ({
  backgroundColor,
  borderRadius = "1.2rem",
  color,
  children,
  borderColor
}: IColoredLabel) => {
  return (
    <Wrapper
      color={color}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      borderColor={borderColor}
    >
      <div> {children}</div>
    </Wrapper>
  );
};
