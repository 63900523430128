import styled from "styled-components";

const StyledLoader = styled.div<{ height?: string }>`
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  ${props => props.height && `height: ${props.height}`}
`;

export { StyledLoader };
