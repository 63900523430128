import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import { Stack } from "@fluentui/react/lib/Stack";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Step1 } from "@/components/Contracts";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import { useSelectedBu } from "@/hooks/useSelectedBu";

function ContractsCreate(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep] = useState(0);

  const userProfile = useGetProfile();
  const user = userProfile.data?.data;
  const { selectedBU } = useSelectedBu();

  useEffect(() => {
    if (selectedBU) {
      setIsLoading(false);
    }
  }, [selectedBU]);

  return (
    <>
      <Helmet>
        <title>Confecção de contrato - Libertas</title>
      </Helmet>

      {isLoading && (
        <ProgressIndicator
          styles={{
            root: { position: "absolute", left: 0, right: 0, top: 0 },
            itemProgress: { padding: 0 }
          }}
        />
      )}

      <Stack tokens={{ childrenGap: 15 }}>
        <h2 className="ms-fontSize-24">Novo contrato</h2>

        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <ProgressIndicator
                percentComplete={Math.round(currentStep * 33) / 100}
                styles={{ root: { width: "75%", margin: "auto" } }}
              />
            </div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <Pivot
                styles={{
                  root: {
                    justifyContent: "space-around",
                    display: "flex"
                  }
                }}
                selectedKey={String(currentStep)}
              >
                <PivotItem headerText="Dados Base">
                  <Stack
                    styles={{
                      root: {
                        padding: 20
                      }
                    }}
                  >
                    {!isLoading ? (
                      <Step1 user={user} selectedBU={selectedBU} />
                    ) : null}
                  </Stack>
                </PivotItem>

                <PivotItem headerText="Cooperado"></PivotItem>
                <PivotItem headerText="Benefícios"></PivotItem>
                <PivotItem headerText="Método de Pagamento"></PivotItem>
                <PivotItem headerText="Confirmação"></PivotItem>
              </Pivot>
            </div>
          </div>
        </div>
      </Stack>
    </>
  );
}

export default ContractsCreate;
