import { PlansActionsTypes } from "../types/Plans.reducer.types";

export function createPlansRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: PlansActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createPlansSuccess(message) {
  return {
    type: PlansActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createPlansFailure(message) {
  return {
    type: PlansActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editPlansRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: PlansActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editPlansSuccess(message) {
  return {
    type: PlansActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editPlansFailure(message) {
  return {
    type: PlansActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listPlansRequest(filter = {}, { isAdmin = false }) {
  return {
    type: PlansActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listPlansSuccess({ list }) {
  return {
    type: PlansActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listPlansFailure() {
  return {
    type: PlansActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: PlansActionsTypes.DISMISS_MESSAGE
  };
}
