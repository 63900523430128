import { Stack } from "@fluentui/react";

import { Invoice } from "../components/Invoice";
import { IInvoiceInfos } from "../components/Invoice/interfaces";
import { PaymentArea } from "../components/PaymentRequest";
import { ServiceCategoryType } from "../contract.interface";

import { ClientInfo } from "./ClientInfo";
import { ContractDocuments } from "./ContractDocuments";
import { ContractInfo } from "./ContractInfo";
import { ContractSignatures } from "./ContractSignatures";
import { PaymentCardWrapper } from "./styles";

import { PaymentMethodType } from "@/common/types/PaymentMethod.types";
import { DisplayProducts } from "@/components/Contracts/ContractProduct";
import { ContractStatus, SignatureType } from "@/modules/Contracts/enums";
import { ActivationFeePaymentInfo } from "@/modules/User/types";

interface ContractInfoProps {
  contract: any;
  clientInfoTitle: string;
  contractInfoTitle: string;
  invoiceInfos: IInvoiceInfos;
  uploads?: any;
  nextStep?: any;
  setIsVisible?: (condition: boolean) => void;
  className?: string;
  isInsideStep?: boolean;
  openTotalFeeOverride?: () => void;
  isEditingEnabled?: boolean;
  refetchContractData?: () => Promise<void>;
  defaultProductCompactMode?: boolean;
  toggleActionModal?: () => void;
  setAction?: (action: string) => void;
  setActionMessage?: (actionMessage: string) => void;
  openDrawer?: () => void;
  isInsidePanel?: boolean;
  activationFeePaymentInfo?: ActivationFeePaymentInfo[];
  onOpenRenewalProductsModal?: () => void;
  hideContractValues?: boolean;
  hidePaymentArea?: boolean;
}

export function DisplayContractInfo({
  contract,
  clientInfoTitle,
  contractInfoTitle,
  uploads,
  nextStep,
  setIsVisible,
  className,
  isInsideStep,
  isEditingEnabled,
  refetchContractData,
  defaultProductCompactMode,
  openDrawer,
  toggleActionModal,
  setAction,
  setActionMessage,
  isInsidePanel,
  onOpenRenewalProductsModal,
  invoiceInfos,
  hidePaymentArea
}: ContractInfoProps): JSX.Element {
  let canSendDocuments = false;

  const lastStepToSendDocument = contract?.approvalFlow.steps?.find(
    step => step.internalName === ContractStatus.SERVICE_DELIVERED
  );

  if (
    lastStepToSendDocument &&
    contract.currentStep?.order >= 10 &&
    contract.currentStep?.order <= lastStepToSendDocument?.order
  ) {
    canSendDocuments = true;
  }

  const serviceTypeIsTag =
    contract?.serviceCategory?.type === ServiceCategoryType.TAG;

  const isInvoicePayment =
    contract?.paymentRequest?.[0]?.typeProvider ===
    PaymentMethodType.INVOICE_DEBIT;

  const displayPaymentArea =
    !hidePaymentArea &&
    contract?.approvalFlow?.steps?.some(
      step => step.internalName === "SETUP_PAID"
    ) &&
    contract.paymentRequest?.length > 0 &&
    Number(contract.activationInstallment?.total) > 0 &&
    !isInvoicePayment;

  return (
    <Stack>
      <div style={{ marginTop: 20 }}>
        <Stack tokens={{ childrenGap: 15 }}>
          <Stack horizontal wrap tokens={{ childrenGap: 15 }}>
            <Stack.Item className="ms-sm12 ms-xl5" grow>
              <ClientInfo
                title={clientInfoTitle}
                contract={contract}
                openDrawer={openDrawer}
                isInsidePanel={isInsidePanel}
              />
            </Stack.Item>

            <Stack.Item className="ms-sm12 ms-xl5" grow>
              <ContractInfo
                title={contractInfoTitle}
                contract={contract}
                isInsidePanel={isInsidePanel}
              />
            </Stack.Item>
          </Stack>

          <DisplayProducts
            items={contract.items}
            contract={contract}
            isEditingEnabled={isEditingEnabled}
            nextStep={nextStep}
            currentStep={contract.currentStep}
            approvalFlow={contract.approvalFlow}
            refetchContractData={refetchContractData}
            defaultCompactMode={defaultProductCompactMode}
            serviceCategoryType={contract.serviceCategory.type}
            businessUnitId={contract.businessUnit.id}
            service={contract.service.id}
            onOpenRenewalProductsModal={onOpenRenewalProductsModal}
            businessUnit={contract.businessUnit}
          />

          <PaymentCardWrapper isInsidePanel={isInsidePanel}>
            {!serviceTypeIsTag && (
              <Invoice
                invoiceInfos={invoiceInfos}
                paymentMethodName={contract?.paymentMethod?.name}
                isInsidePanel={isInsidePanel}
                displayPaymentArea={displayPaymentArea}
              />
            )}

            {displayPaymentArea && (
              <PaymentArea
                paymentType={contract?.paymentMethod?.type}
                paymentRequest={contract?.paymentRequest?.[0]}
                canShowConfirmPaymentButton={
                  contract?.nextStep?.internalName === "SETUP_PAID"
                }
                contractId={contract.id}
                isInsidePanel={isInsidePanel}
                refetchContractData={refetchContractData}
              />
            )}
          </PaymentCardWrapper>
          <Stack horizontal wrap tokens={{ childrenGap: 15 }}>
            {/* VERIFY IF IS INSIDE A STEP, IF IS INSIDE A STEP, DON'T HAVE SIGNATURES AND DOCUMENTS */}
            {!isInsideStep && (
              <>
                {[
                  SignatureType.ELETRONIC,
                  SignatureType.ELETRONIC_WHATSAPP
                ].includes(contract?.signature?.type) && (
                  <Stack.Item className={className} grow>
                    <ContractSignatures
                      contractId={contract.id}
                      eletronicSignature={contract.eletronicSignature}
                    />
                  </Stack.Item>
                )}

                <Stack.Item className={className} grow>
                  <ContractDocuments
                    uploads={uploads ? uploads : contract.uploads}
                    canSendDocument={canSendDocuments}
                    isSendingDocumentStep={
                      nextStep?.internalName === ContractStatus.DOCUMENTS_SENT
                    }
                    handleContractStatus={() => {
                      setAction(nextStep?.interactionAction);
                      setActionMessage(nextStep?.interactionName);
                      toggleActionModal();
                    }}
                    setIsVisible={setIsVisible ? setIsVisible : null}
                  />
                </Stack.Item>
              </>
            )}
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
}
