import { BannersOffersActionsTypes } from "../types/BannersOffers.reducer.types";

export function listRequest(filter?) {
  return {
    type: BannersOffersActionsTypes.LIST_REQUEST,
    payload: { filter }
  };
}

export function listSuccess({ list }) {
  return {
    type: BannersOffersActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: BannersOffersActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: BannersOffersActionsTypes.DISMISS_MESSAGE
  };
}
