import {
  Dropdown,
  IconButton,
  mergeStyleSets,
  Modal,
  Stack
} from "@fluentui/react";
import { useEffect, useState } from "react";

import { ProductCardItem } from "./ProductCardItem";
import ProductComboModal from "./ProductOfferModal/ProductComboModal";
import { NavigationButton } from "./styles";

import undrawNoData from "@/assets/images/undraw_no_data_qbuo.svg";
import { Loader } from "@/components/Shared/Loader";
import {
  ListOfProducts,
  ProductOfferItem,
  ProductsBrands,
  ProductsCarriers,
  ProductsCategory
} from "@/modules/Offers/types/ProductsOffers.types";

type ProductsOffersProps = {
  requestListOfProductOffers: (fitler) => void;
  removeListOfProductOffers?: () => void;
  products?: ListOfProducts;
  productsBrands: ProductsBrands[];
  productsCarriers: ProductsCarriers[];
  productsCategories: ProductsCategory[];
  isLoadingList?: boolean;
};

const dataFilterOptions = [
  {
    key: 2,
    text: "2 GB"
  },
  {
    key: 4,
    text: "4 GB"
  },
  {
    key: 5,
    text: "5 GB"
  },
  {
    key: 6,
    text: "6 GB"
  },
  {
    key: 10,
    text: "10 GB"
  },
  {
    key: 15,
    text: "15 GB"
  },
  {
    key: 20,
    text: "20 GB"
  },
  {
    key: 25,
    text: "25 GB"
  },
  {
    key: 45,
    text: "45 GB"
  }
];

export function ProductsOffers({
  requestListOfProductOffers,
  products,
  productsBrands,
  productsCarriers,
  productsCategories,
  isLoadingList
}: ProductsOffersProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedProduct, setSelectedProduct] =
    useState<ProductOfferItem>(undefined);
  const [selectionOfBrands, setSelectionOfBrands] = useState(undefined);
  const [selectionOfCategories, setSelectionOfCategories] = useState(undefined);
  const [selectionOfCarriers, setSelectionOfCarriers] = useState(undefined);
  const [numberOfPages, setNumberOfPages] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedFilters, setSelectedFilter] = useState({});

  const productFilters = [
    {
      placeholder: "Selecione uma Marca",
      filterQuery: "brand",
      options: selectionOfBrands
    },
    {
      placeholder: "Selecione uma Categoria",
      filterQuery: "category",
      minWidth: "200px",
      options: selectionOfCategories
    },
    {
      placeholder: "Selecione os Dados",
      filterQuery: "data",
      options: dataFilterOptions
    },
    {
      placeholder: "Selecione uma Operadora",
      filterQuery: "carrier",
      options: selectionOfCarriers
    }
  ];

  useEffect(() => {
    if (productsBrands && selectionOfBrands === undefined) {
      const selectableBrands = productsBrands.map(brand => ({
        key: brand.id,
        text: brand.name
      }));
      setSelectionOfBrands(selectableBrands);
    }

    if (productsCarriers && selectionOfCarriers === undefined) {
      const selectableCarriers = productsCarriers.map(carrier => ({
        key: carrier.id,
        text: carrier.name
      }));
      setSelectionOfCarriers(selectableCarriers);
    }

    if (productsCategories && selectionOfCategories === undefined) {
      const selectableCategories = productsCategories.map(category => ({
        key: category.id,
        text: category.name
      }));
      setSelectionOfCategories(selectableCategories);
    }
  }, [
    productsBrands,
    selectionOfBrands,
    productsCarriers,
    selectionOfCarriers,
    productsCategories,
    selectionOfCategories
  ]);

  const toggleOpenModal = () => {
    setIsModalOpen(prevState => {
      return !prevState;
    });
  };

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    const pages = [];

    if (products?.meta) {
      for (let index = 1; index <= products.meta.totalPages; index += 1) {
        pages.push(index);
      }
      setNumberOfPages(pages);
    }
  }, [products]);

  useEffect(() => {
    if (!isFirstRender) {
      requestListOfProductOffers({ page: selectedPage, ...selectedFilters });
    }
    //eslint-disable-next-line
  }, [selectedPage, selectedFilters]);

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: 15, padding: "10px 0 0 0" }}
        horizontalAlign="space-between"
      >
        <h1 className="ms-fontWeight-semibold ms-fontSize-24">Combos</h1>

        <Stack
          horizontal
          horizontalAlign="end"
          wrap
          tokens={{ childrenGap: 15 }}
        >
          {productFilters.map(filter => (
            <Dropdown
              key={filter.filterQuery}
              placeholder={filter.placeholder}
              options={filter.options}
              selectedKey={selectedFilters[filter.filterQuery] || ""}
              onChange={(event, value) => {
                const currentKeys = Object.assign({}, selectedFilters);

                if (currentKeys[filter.filterQuery] === value.key) {
                  delete currentKeys[filter.filterQuery];
                } else {
                  currentKeys[filter.filterQuery] = value.key;
                }
                setSelectedFilter(currentKeys);
              }}
              styles={{
                root: {
                  minWidth: filter?.minWidth
                }
              }}
              disabled={filter.options?.length < 1}
            />
          ))}
        </Stack>
      </Stack>

      <Stack
        horizontalAlign="center"
        styles={{ root: { marginTop: 20, position: "relative" } }}
      >
        {isLoadingList ? (
          <Loader height="70vh" />
        ) : products?.items?.length > 0 ? (
          <Stack
            styles={{ root: { maxWidth: 2000, width: "100%" } }}
            horizontalAlign="center"
            tokens={{ childrenGap: 25, padding: 20 }}
          >
            <Stack
              horizontal
              wrap
              tokens={{ childrenGap: 25 }}
              styles={{ root: { maxWidth: 1200, width: "100%" } }}
              horizontalAlign="center"
            >
              {products?.items?.map(product => (
                <ProductCardItem
                  key={product.id}
                  product={product}
                  setSelectedProduct={setSelectedProduct}
                  toggleOpenModal={toggleOpenModal}
                />
              ))}
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 10 }}
            >
              {selectedPage !== numberOfPages[0] && numberOfPages.length > 0 && (
                <IconButton
                  iconProps={{ iconName: "ChevronLeftMed" }}
                  title="Página anterior"
                  ariaLabel="Página anterior"
                  styles={{
                    icon: {
                      fontSize: 24
                    }
                  }}
                  onClick={() => setSelectedPage(selectedPage - 1)}
                />
              )}

              {numberOfPages.map(
                page =>
                  ((page >= selectedPage - 2 && page <= selectedPage + 2) ||
                    page === selectedPage) && (
                    <NavigationButton
                      type="button"
                      key={page}
                      onClick={() => {
                        setSelectedPage(page);
                      }}
                      disabled={selectedPage === page}
                    >
                      {page}
                    </NavigationButton>
                  )
              )}

              {selectedPage !== numberOfPages[numberOfPages.length - 1] &&
                numberOfPages.length > 0 && (
                  <IconButton
                    iconProps={{ iconName: "ChevronRightMed" }}
                    title="Página anterior"
                    ariaLabel="Página anterior"
                    styles={{
                      icon: {
                        fontSize: 24
                      }
                    }}
                    onClick={() => setSelectedPage(selectedPage + 1)}
                  />
                )}
            </Stack>
          </Stack>
        ) : (
          <Stack tokens={{ padding: 20 }}>
            <img
              src={undrawNoData}
              title="Nenhum dado encontrado no banco"
              alt="Nenhum dado encontrado no banco"
              style={{ width: "55vh", height: "55vh" }}
            />
          </Stack>
        )}
      </Stack>

      <Modal
        titleAriaId="Product combo title"
        isOpen={isModalOpen}
        onDismiss={toggleOpenModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
        scrollableContentClassName={scrollableStyles.container}
      >
        <ProductComboModal
          productOffer={selectedProduct}
          toggleOpenModal={toggleOpenModal}
        />
      </Modal>
    </>
  );
}

const scrollableStyles = mergeStyleSets({
  container: {
    display: "flex",
    overflowY: "hidden"
  }
});

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    height: "100%"
  }
});
