import { ImageContainer } from "./styles";

type TableImageProps = {
  onClick?: () => void;
  imgFile: {
    id?: string | number;
    url?: string;
    fileName?: string;
  };
};

export function TableImage({ imgFile, onClick }: TableImageProps): JSX.Element {
  return (
    <ImageContainer>
      <img
        key={imgFile.id}
        src={imgFile?.url}
        alt={imgFile?.fileName || "File image " + imgFile?.id}
        onClick={onClick}
      />
    </ImageContainer>
  );
}
