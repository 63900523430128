import { PlansOffersActionsTypes } from "../types/PlansOffers.reducer.types";

export function listRequest(filter?) {
  return {
    type: PlansOffersActionsTypes.LIST_REQUEST,
    payload: { filter }
  };
}

export function listSuccess({ list }) {
  return {
    type: PlansOffersActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: PlansOffersActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: PlansOffersActionsTypes.DISMISS_MESSAGE
  };
}
