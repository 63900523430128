interface AuthParams {
  /** Token used to make authenticated requests. */
  accessToken: string;
  /** When `accessToken` is set to expire. After this time, any request will return `401`. */
  accessTokenExpiration: number;
  /** Token used to get a new `accessToken` after its expiration. */
  refreshToken: string;
  /** When `refreshToken` is set to expire. After this time, any request will return `401`. */
  refreshTokenExpiration: number;
}

const AUTH_KEY_NAME = "auth";

/** Retrieve authorization key recorded. Can be null. */
export const retrieveAuthKey = () => {
  const item = localStorage.getItem(AUTH_KEY_NAME);

  if (item !== null) {
    return JSON.parse(item) as AuthParams;
  }
};

/** Save authorization key. */
export const saveAuthKey = (authParams: AuthParams) =>
  localStorage.setItem(AUTH_KEY_NAME, JSON.stringify(authParams));

/** Delete authorization key. */
export const clearAuthKey = () => localStorage.removeItem(AUTH_KEY_NAME);
