export enum MembershipStatusEnum {
  AWAITING_SIGNATURE = "awaiting-signature",
  DISSOCIATED = "dissociated",
  EXPIRED = "expired",
  REFUSED = "refused",
  SIGNED = "signed",
  NOT_ASSOCIATED = "not-associated",
  INITIALIZED = "initialized",
  INTEGRATED = "integrated",
  INVOICE_CHARGED = "invoice-charged"
}

export enum MembershipStatusTranslator {
  AWAITING_SIGNATURE = "Aguardando assinatura",
  DISSOCIATED = "Desassociado",
  EXPIRED = "Expirado",
  REFUSED = "Recusado",
  SIGNED = "Assinado",
  NOT_ASSOCIATED = "Não associado",
  INITIALIZED = "Iniciado",
  INTEGRATED = "Integralizado",
  INVOICE_CHARGED = "Lançado em fatura"
}
