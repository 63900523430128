import { useEffect, useRef } from "react";

import { Container } from "./styles";
import { TeachingBubbleProps } from "./types";

/**
 * This component is a floating div which is rendered next button with given id
 * @param onClose function called to close div.
 * @param targetButtonId button id searched to open the div
 * @param positionAdjustment adjust div position with top and left
 * @param customStyles control de width and max-height for render scrollbar
 * @returns
 */
export const TeachingBubble = ({
  children,
  targetButtonId,
  positionAdjustment,
  onClose,
  customStyles
}: TeachingBubbleProps) => {
  const divRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const targetButton = document.getElementById(targetButtonId);
  if (!targetButton) {
    console.error(`Button with ID ${targetButtonId} not found.`);
    return null;
  }

  const handleOutsideClick = event => {
    if (
      onClose &&
      divRef.current &&
      !divRef.current.contains(event.target) &&
      !targetButton.contains(event.target)
    ) {
      onClose();
    }
  };

  const { top, left } = targetButton.getBoundingClientRect();
  return (
    <Container
      ref={divRef}
      maxHeight={customStyles.maxHeight || "auto"}
      width={customStyles.width || "auto"}
      style={{
        position: "absolute",
        top: top + positionAdjustment.top,
        left: positionAdjustment.left && left + positionAdjustment.left,
        right: customStyles.right || "auto",
        overflowY: customStyles.overflowY || "auto"
      }}
    >
      {children}
    </Container>
  );
};
