import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize
} from "@fluentui/react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import styled from "styled-components";

type ConfirmModalProviderProps = {
  children: ReactNode;
};

type IConfirmModalContextData = {
  openModalWithSettings: (
    title: string | JSX.Element,
    message: string,
    cb: any,
    params?: {
      customComponent?: React.FC<any>;
      maxWidth?: number;
      timeOutModeProps?: {
        timeOut: number;
      };
      hidePrimaryButton?: boolean;
      secondaryButtonText?: string;
      primaryButtonText?: string;
    }
  ) => void;
  setSubmittingConfirmModal: (isSubmitting: boolean) => void;
};

const ModalContainer = styled.div`
  position: fixed;
  z-index: 500;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    min-width: 35%;
    min-height: 20%;
    max-width: 50%;
    width: fit-content;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
`;

const ConfirmModalContext = createContext<IConfirmModalContextData>(
  {} as IConfirmModalContextData
);
export const ConfirmModalProvider = ({
  children
}: ConfirmModalProviderProps) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string | JSX.Element | undefined>();
  const [message, setMessage] = useState<string | undefined>();
  const [sendDataFunction, setSendDataFuncion] = useState<any | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [CustomComponentJsx, setCustomComponentJsx] = useState<
    React.FC<any> | undefined
  >();
  const [maxWidth, setMaxWidth] = useState<number>(500);
  const [timeOutNumber, setTimeOutNumber] = useState<number>();
  const [hidePrimaryButton, setHidePrimaryButton] = useState<boolean>(false);
  const [secondaryButtonText, setSecondaryButtonText] = useState<string>();
  const [primaryButtonText, setPrimaryButtonText] = useState<string>();
  const [isSubmittingConfirmModal, setSubmittingConfirmModal] =
    useState<boolean>(false);

  const clearData = () => {
    setMessage(undefined);
    setTitle(undefined);
    setSendDataFuncion(undefined);
    setCustomComponentJsx(undefined);
    setMaxWidth(500);
  };
  const openModalWithSettings = (
    title: string | JSX.Element,
    message: string,
    cb: any,
    params?: {
      customComponent?: React.FC<any>;
      maxWidth?: number;
      hidePrimaryButton?: boolean;
      secondaryButtonText: string;
      primaryButtonText: string;
      timeOutModeProps?: {
        timeOut: number;
      };
    }
  ) => {
    setMessage(message);
    setTitle(title);
    setSendDataFuncion(() => cb);

    if (params) {
      params.customComponent
        ? setCustomComponentJsx(params.customComponent)
        : setCustomComponentJsx(undefined);

      params.hidePrimaryButton === true &&
        setHidePrimaryButton(params.hidePrimaryButton);
      params.secondaryButtonText &&
        setSecondaryButtonText(params.secondaryButtonText);
      params.primaryButtonText &&
        setPrimaryButtonText(params.primaryButtonText);

      params.maxWidth && setMaxWidth(params.maxWidth);
      if (params.timeOutModeProps) {
        setTimeOutNumber(params.timeOutModeProps.timeOut);
        setIsSubmitting(true);
      }
    }
    setModalIsOpen(true);
  };

  useEffect(() => {
    if (!modalIsOpen) {
      clearData();
    }
  }, [modalIsOpen]);
  useEffect(() => {
    if (timeOutNumber) {
      setTimeout(() => {
        setIsSubmitting(false);
        setModalIsOpen(false);
        sendDataFunction();
      }, timeOutNumber);
    }
  }, [timeOutNumber]);

  return (
    <ConfirmModalContext.Provider
      value={{
        openModalWithSettings: openModalWithSettings,
        setSubmittingConfirmModal: setSubmittingConfirmModal
      }}
    >
      {modalIsOpen && (
        <ModalContainer>
          <Dialog
            hidden={!modalIsOpen}
            onDismiss={() => setModalIsOpen(false)}
            dialogContentProps={{
              type: DialogType.normal,
              title: title,
              titleProps: {},
              isMultiline: true,
              closeButtonAriaLabel: "Cancelar",
              subText: message
            }}
            maxWidth={maxWidth}
          >
            {CustomComponentJsx && CustomComponentJsx}
            <DialogFooter>
              {isSubmitting && (
                <Spinner
                  size={SpinnerSize.xSmall}
                  styles={{ root: { display: "inline-block" } }}
                />
              )}
              {!timeOutNumber && (
                <>
                  <DefaultButton
                    id="button-cancel"
                    onClick={() => setModalIsOpen(false)}
                    style={{ marginRight: "0.5rem" }}
                    text={secondaryButtonText ?? "Cancelar"}
                  />

                  {!hidePrimaryButton && (
                    <PrimaryButton
                      id="button-confirm"
                      text={primaryButtonText ?? `Sim, enviar`}
                      disabled={isSubmittingConfirmModal}
                      onClick={async () => {
                        await sendDataFunction();
                        setModalIsOpen(false);
                      }}
                    />
                  )}
                </>
              )}
            </DialogFooter>
          </Dialog>
        </ModalContainer>
      )}

      {children}
    </ConfirmModalContext.Provider>
  );
};
export const useConfirmModal = () => {
  const context = useContext(ConfirmModalContext);
  return context;
};
