import styled from "styled-components";

const StyledSidebar = styled.div<{
  borderColor: string;
  isMenuExpanded: boolean;
  isDev?: boolean;
}>`
  border-right: 1px solid ${props => props.borderColor};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: ${props => (props.isMenuExpanded ? "280px" : "50px")};
  height: ${props =>
    props.isDev ? "calc(100vh - 78px)" : "calc(100vh - 49px)"};
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 1ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
`;

const StyledGrowSidebar = styled.div`
  flex-grow: 1;
`;

export { StyledGrowSidebar, StyledSidebar };
