import { makeRequest } from "../utils/fetchData";
type IAuth = {
  params?: any;
  signal: AbortSignal;
  id?: any;
};

class Auth {
  private baseUrl: string;

  constructor(subPaths: string, isAdmin?: boolean) {
    this.baseUrl = isAdmin
      ? import.meta.env.VITE_API_URL + "/admin"
      : import.meta.env.VITE_API_URL;
  }

  changePassword({ params, signal }: IAuth) {
    const options: any = {
      signal,
      method: "PATCH",
      baseUrl: `${this.baseUrl}/auth/password/set/`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;
    return makeRequest(options);
  }

  async login({ params, signal }: IAuth) {
    const options: any = {
      method: "POST",
      signal,
      baseUrl: `${this.baseUrl}/auth/login`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  async recover({ params, signal }: IAuth) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/auth/password/recovery`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }
}

export default Auth;
