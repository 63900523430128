import { MessageBarType } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { useAlert } from "./useAlert";

import { AlertTypes } from "@/common/types/AlertTypes";
import { IApiNotificationsData } from "@/common/types/ApiNotificationsTypes";
import {
  ResponseNotificationsContent,
  SelectedOption
} from "@/components/Shared/Layouts/components/ResponseNotifications/ResponseNotificationsContent";
import { useGetNotifications } from "@/core/libs/api/react-query";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { useAuth } from "@/core/libs/use-auth";

enum SignalStatus {
  OK = "OK",
  ERROR = "ERROR"
}

const ApiNotificationsContext = createContext<IApiNotificationsData>(
  {} as IApiNotificationsData
);

export const NotificationsProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isShowingNotifications, setIsShowingNotifications] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const { execute } = useApi();
  const { isAuthenticated } = useAuth();
  const { createAlert } = useAlert();

  const {
    listOfNotifications,
    invalidateQuery,
    selectedOption,
    setSelectedOption,
    isLoading,
    fetchData,
    isLoadingNextPage,
    hasNextPage,

    setNeedRefetch,
    needRefetch
  } = useGetNotifications();

  const countUnreadNotifications = useCallback(async () => {
    const unread = await execute({
      url: `notification-recipients/count-unread`
    });

    setUnreadNotifications(unread.data.unreadNotifications);
  }, [execute]);

  const readAllNotifications = useCallback(async () => {
    await execute({
      url: `notification-recipients/read-all`,
      method: "PUT"
    });

    countUnreadNotifications();
    invalidateQuery();
  }, [execute, invalidateQuery, countUnreadNotifications]);

  const handleReadNotificationRecipient = useCallback(
    async (id: number) => {
      try {
        return await execute({
          url: `notification-recipients/read/${id}`,
          method: "PUT"
        });
      } catch (error) {
        console.error(error);
      }
    },
    [execute]
  );

  const readNotification = useCallback(
    async (id: number) => {
      await handleReadNotificationRecipient(id);
      await Promise.allSettled([countUnreadNotifications(), invalidateQuery()]);
    },
    [handleReadNotificationRecipient, countUnreadNotifications, invalidateQuery]
  );

  const negotiate = useCallback(async () => {
    const auth = await execute({
      url: `notification/connect`,
      method: "GET"
    });

    return auth.data.url;
  }, [execute]);

  const notificationSignalStrategy = useMemo<{
    [key in SignalStatus]: (data: { messageText?: string }) => Promise<void>;
  }>(
    () => ({
      [SignalStatus.OK]: async ({ messageText }) => {
        if (messageText) {
          createAlert({
            level: MessageBarType.info,
            message: messageText,
            type: AlertTypes.NOTIFICATION,
            timeout: 4000
          });
        }

        countUnreadNotifications();
        setNeedRefetch(true);
      },
      [SignalStatus.ERROR]: null
    }),
    [createAlert, countUnreadNotifications, setNeedRefetch]
  );

  const openNotificationWSConnection = useCallback(async () => {
    const token = await negotiate();
    const ws = new WebSocket(token);

    ws.addEventListener("message", async payload => {
      const dataParsed = JSON.parse(payload.data);
      const signal = dataParsed?.signal?.replaceAll('"', "");
      if (signal) await notificationSignalStrategy[signal](dataParsed);
    });
  }, [negotiate, notificationSignalStrategy]);

  const changeSelectedOption = useCallback(
    async (option: SelectedOption) => {
      setSelectedOption(option);
    },
    [setSelectedOption]
  );

  const openNotificationsBubbleButtonId = useId("open-api-notifications");

  const handleNotificationBubbleVisibility = () => {
    setIsShowingNotifications(prevState => {
      if (needRefetch) {
        fetchData();
        setNeedRefetch(false);
      }

      return !prevState;
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      openNotificationWSConnection();
      countUnreadNotifications();
    }
  }, [isAuthenticated]);

  return (
    <ApiNotificationsContext.Provider
      value={{
        handleNotificationBubbleVisibility,
        openNotificationsBubbleButtonId,
        isNotificationsBubbleVisible: isShowingNotifications,
        countUnreadNotifications: unreadNotifications
      }}
    >
      {isShowingNotifications && (
        <ResponseNotificationsContent
          handleCloseBubble={handleNotificationBubbleVisibility}
          openNotificationsBubbleButtonId={openNotificationsBubbleButtonId}
          listOfNotifications={listOfNotifications}
          handleReadNotification={readNotification}
          handleReadAllNotifications={readAllNotifications}
          paginateNotifications={fetchData}
          selectedOption={selectedOption}
          setSelectedOption={changeSelectedOption}
          isLoading={isLoading}
          isLoadingNextPage={isLoadingNextPage}
          hasNextPage={hasNextPage}
        />
      )}
      {children}
    </ApiNotificationsContext.Provider>
  );
};

export const useNotifications = (): IApiNotificationsData => {
  const context = useContext(ApiNotificationsContext);
  return context;
};
