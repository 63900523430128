import { Spinner, Stack, Text, FontIcon } from "@fluentui/react";
import { useEffect, useState } from "react";

import { ContractsService } from "@/core/libs/api";
import { phoneNumberFormatter } from "@/utils/PhoneNumberFormatter";

type ContractOccurrence =
  | ContractOccurrenceCommon
  | ContractOccurrenceStep
  | ContractOccurrenceItem;

interface ContractOccurrenceCommon {
  id: number;
  createdAt: string;
  ipAddress: string;
  logType: ContractsActionsLogsType;
  statusChangeReason?: string;
  user?: User;
  contractItem?: ContractItem;
  currentStep?: Step;
  nextStep?: Step;
  upload?: Upload;
  account?: Account;
}

interface ContractOccurrenceStep extends ContractOccurrenceCommon {
  currentStep: Step;
}

interface ContractOccurrenceItem extends ContractOccurrenceCommon {
  contractItem: ContractItem;
}

interface ContractOccurrenceUpload extends ContractOccurrenceCommon {
  upload: Upload;
}

interface ContractOccurrenceAccount extends ContractOccurrenceCommon {
  account: Account;
}

interface Upload {
  fileName: string;
}

interface Account {
  id: number;
  fullName: string;
}

interface ContractItem {
  id: number;
  operation: Operation;
  line: Line;
  offerPrice: OfferPrice;
  inventory: Inventory[] | null;
}

interface Inventory {
  serialType: string;
  serialNumber: string;
  product: Product;
}

interface Product {
  name: string;
}

interface Line {
  id: number;
  number: string;
}

interface OfferPrice {
  id: number;
  price: string;
  offer: Offer;
}

interface Offer {
  id: number;
  combo: string;
  plan: Plan;
}

interface Plan {
  id: number;
  name: string;
  carrier: Operation;
}

interface Operation {
  id: number;
  name: string;
}

interface Step {
  id: number;
  internalName: string;
  friendlyName: string;
}

interface User {
  id: number;
  fullName: string;
  email: string;
}

type ContractsActionsLogsType =
  | "CREATED_BY_OFFER"
  | "CREATED"
  | "ACCOUNT_ADDED"
  | "SHIPPING_UPDATED"
  | "SIGNATURES_ADDED"
  | "SIGNATURES_UPDATED"
  | "DOCUMENT_UPLOADED"
  | "DOCUMENT_REMOVED"
  | "ACCOUNT_NOTIFIED"
  | "ITEM_ADDED"
  | "ITEM_REMOVED"
  | "STATUS_CHANGED"
  | "STATUS_CHANGED_BY_DIGITAL_SIGNATURE"
  | "INSTALLMENTS_CALCULATED"
  | "MODIFIED"
  | "REMOVED"
  | "DELETED"
  | "AUTO_CLOSE"
  | "PAYMENT_REQUEST_UPDATED";

const ContractsActionsLogsTypeMessage: Record<
  ContractsActionsLogsType,
  string
> = {
  CREATED_BY_OFFER: "Criado no book de benefícios",
  CREATED: "Criado",
  ACCOUNT_ADDED: "Cooperado adicionado",
  SHIPPING_UPDATED: "Frete atualizado",
  SIGNATURES_ADDED: "Assinatura(s) digital(ais) adicionada(s)",
  SIGNATURES_UPDATED: "Assinatura(s) digital(ais) atualizada(s)",
  DOCUMENT_UPLOADED: "Documento enviado",
  DOCUMENT_REMOVED: "Documento removido",
  ACCOUNT_NOTIFIED: "Cooperado notificado",
  ITEM_ADDED: "Item adicionado",
  ITEM_REMOVED: "Item removido",
  STATUS_CHANGED: "Estado alterado",
  STATUS_CHANGED_BY_DIGITAL_SIGNATURE: "Estado alterado por assinatura(s)",
  INSTALLMENTS_CALCULATED: "Valor atualizado",
  MODIFIED: "Alterado",
  REMOVED: "Removido",
  DELETED: "Removido",
  AUTO_CLOSE: "Concluído",
  PAYMENT_REQUEST_UPDATED: "Pagamento da adesão confirmado"
};

const ContractsActionsLogsTypeDotColor: Record<
  ContractsActionsLogsType,
  string
> = {
  CREATED_BY_OFFER: "green",
  CREATED: "green",
  ACCOUNT_ADDED: "blue",
  SHIPPING_UPDATED: "blue",
  SIGNATURES_ADDED: "blue",
  SIGNATURES_UPDATED: "blue",
  DOCUMENT_UPLOADED: "blue",
  DOCUMENT_REMOVED: "blue",
  ACCOUNT_NOTIFIED: "blue",
  ITEM_ADDED: "blue",
  ITEM_REMOVED: "blue",
  STATUS_CHANGED: "green",
  STATUS_CHANGED_BY_DIGITAL_SIGNATURE: "green",
  INSTALLMENTS_CALCULATED: "blue",
  MODIFIED: "blue",
  REMOVED: "red",
  DELETED: "red",
  AUTO_CLOSE: "green",
  PAYMENT_REQUEST_UPDATED: "blue"
};

const intl = new Intl.DateTimeFormat("pt-BR", {
  dateStyle: "short",
  timeStyle: "medium"
});

function relativeTime(now: Date, time: Date) {
  const rtf = new Intl.RelativeTimeFormat("pt-BR", { numeric: "auto" });

  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = now.getTime() - time.getTime();

  if (elapsed < msPerMinute) {
    return "agora";
  } else if (elapsed < msPerHour) {
    return rtf.format(-Math.ceil(elapsed / msPerMinute), "minute");
  } else if (elapsed < msPerDay) {
    return rtf.format(-Math.ceil(elapsed / msPerHour), "hour");
  } else if (elapsed < msPerMonth) {
    return rtf.format(-Math.ceil(elapsed / msPerDay), "day");
  } else if (elapsed < msPerYear) {
    return rtf.format(-Math.ceil(elapsed / msPerMonth), "month");
  } else {
    return rtf.format(-Math.ceil(elapsed / msPerYear), "year");
  }
}
function isContractOccurrenceStep(
  occurrence: ContractOccurrence
): occurrence is ContractOccurrenceStep {
  return (
    occurrence.currentStep !== null && occurrence.logType === "STATUS_CHANGED"
  );
}

function isContractOccurrenceItem(
  occurrence: ContractOccurrence
): occurrence is ContractOccurrenceItem {
  return (
    occurrence.contractItem !== null &&
    (occurrence.logType === "ITEM_ADDED" ||
      occurrence.logType === "ITEM_REMOVED")
  );
}

function isContractOccurrenceAccount(
  occurrence: ContractOccurrence
): occurrence is ContractOccurrenceAccount {
  return occurrence.account !== null && occurrence.logType === "ACCOUNT_ADDED";
}

function isContractOccurrenceUpload(
  occurrence: ContractOccurrence
): occurrence is ContractOccurrenceUpload {
  return (
    occurrence.upload !== null &&
    (occurrence.logType === "DOCUMENT_UPLOADED" ||
      occurrence.logType === "DOCUMENT_REMOVED")
  );
}

interface ContractOccurrencesProps {
  contractId: number;
}

function ContractOccurrences({ contractId }: ContractOccurrencesProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [contractOccurrences, setContractOccurrences] = useState<
    ContractOccurrence[]
  >([]);

  async function getContract(contractId: number) {
    const abortController = new AbortController();
    const { signal } = abortController;

    const service = ContractsService();
    return service.getOccurrences({ id: contractId.toString(), signal });
  }

  useEffect(() => {
    getContract(contractId)
      .then(o => {
        const sortedOccurrences = o.data;
        sortedOccurrences.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });
        setContractOccurrences(sortedOccurrences);
      })
      .finally(() => setIsLoading(false));
  }, [contractId]);

  const now = new Date();

  if (isLoading) {
    return <Spinner label="Carregando histórico do contrato..." />;
  }

  if (!contractOccurrences.length) {
    return <Text>Não há histórico para este contrato.</Text>;
  }

  return (
    <Stack tokens={{ childrenGap: 16 }} styles={{ root: { padding: 16 } }}>
      {contractOccurrences.map((occurrence, index) => {
        const createdAt = new Date(occurrence.createdAt);
        const createdAtFormatted = intl.format(createdAt);
        const relativeDate = relativeTime(now, createdAt);

        return (
          <Stack
            key={index}
            horizontal
            tokens={{ childrenGap: 16 }}
            styles={{
              root: {
                padding: 12,
                borderBottom: "1px solid #ddd",
                alignItems: "center"
              }
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 12,
                height: 12,
                backgroundColor: "white",
                borderRadius: "50%",
                border: `2px solid ${
                  ContractsActionsLogsTypeDotColor[occurrence.logType] || "gray"
                }`
              }}
            >
              <FontIcon
                iconName="CircleFill"
                style={{
                  fontSize: 8,
                  color: "#fff"
                }}
              />
            </div>
            <Stack styles={{ root: { flex: 1 } }}>
              <Text variant="mediumPlus" styles={{ root: { fontWeight: 600 } }}>
                {ContractsActionsLogsTypeMessage[occurrence.logType]}
              </Text>
              {occurrence.statusChangeReason && (
                <Text styles={{ root: { marginTop: 8, fontSize: 12 } }}>
                  Motivo da alteração de Status:
                  <br />
                  {occurrence.statusChangeReason}
                </Text>
              )}
              {isContractOccurrenceItem(occurrence) && (
                <Text styles={{ root: { marginTop: 8, fontSize: 12 } }}>
                  {occurrence.contractItem.line && (
                    <>
                      <Text
                        styles={{
                          root: { fontWeight: 600 }
                        }}
                      >
                        {" "}
                        Linha:
                      </Text>{" "}
                      {phoneNumberFormatter(
                        occurrence.contractItem.line.number
                      )}
                      <br />
                    </>
                  )}
                  {occurrence.contractItem.offerPrice && (
                    <>
                      <Text
                        styles={{
                          root: { fontWeight: 600 }
                        }}
                      >
                        Plano de benefícios:
                      </Text>{" "}
                      {occurrence.contractItem.offerPrice.offer.plan.name}
                      <br />
                      <Text
                        styles={{
                          root: { fontWeight: 600 }
                        }}
                      >
                        Pacote de Benefícios:
                      </Text>{" "}
                      {occurrence.contractItem.offerPrice.offer.combo}
                      <br />
                    </>
                  )}
                  {occurrence.contractItem.inventory &&
                    occurrence.contractItem.inventory[0] &&
                    occurrence.contractItem.inventory.map((iv, index) => (
                      <div key={index}>
                        <Text
                          styles={{
                            root: { fontWeight: 600 }
                          }}
                        >
                          Benefício combos:
                        </Text>{" "}
                        {iv.product?.name}
                        <br />
                        <Text
                          styles={{
                            root: { fontWeight: 600 }
                          }}
                        >
                          Tipo de série:
                        </Text>{" "}
                        {iv.serialType}
                        <br />
                        <Text
                          styles={{
                            root: { fontWeight: 600 }
                          }}
                        >
                          Número de série:
                        </Text>{" "}
                        {iv.serialNumber}
                        <br />
                      </div>
                    ))}
                </Text>
              )}
              {isContractOccurrenceStep(occurrence) && (
                <Text
                  styles={{
                    root: { marginTop: 8, fontSize: 14 }
                  }}
                >
                  <Text
                    styles={{
                      root: { fontWeight: 600 }
                    }}
                  >
                    Atual:
                  </Text>{" "}
                  {occurrence.currentStep.friendlyName}
                  {occurrence.nextStep && (
                    <>
                      <br />
                      <Text
                        styles={{
                          root: { fontWeight: 600 }
                        }}
                      >
                        Próximo:
                      </Text>{" "}
                      {occurrence.nextStep.friendlyName}
                    </>
                  )}
                </Text>
              )}

              <Stack
                horizontal
                horizontalAlign="space-between"
                styles={{ root: { marginTop: 8 } }}
              >
                <Text styles={{ root: { fontSize: 12, color: "#888" } }}>
                  {occurrence.user?.email || "Email não disponível"}
                </Text>
              </Stack>

              <Stack
                horizontal
                horizontalAlign="space-between"
                styles={{ root: { marginTop: 8 } }}
              >
                <Text>{occurrence.user?.fullName || "Desconhecido"}</Text>
                <Text>{createdAtFormatted}</Text>
              </Stack>
              <Text>{relativeDate}</Text>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}

export default ContractOccurrences;
