import { Icon, PrimaryButton, Stack } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { fields as formFields } from "./fields";

import { Loader } from "@/components/Shared/Loader";
import { ContractsService } from "@/core/libs/api";
import {
  FieldsBuilder,
  Utils as FormBuilderUtils
} from "@/core/libs/form-builder";
import { MessageBar, messageBarTypes } from "@/core/libs/message-bar";
import { useConfirmModal } from "@/hooks/useConfirmModal";
import { isObjectEmpty } from "@/utils/IsObjectEmpty";

interface WitnessesProps {
  contract: any;
  processContract: () => Promise<void>;
}

const schema = Yup.object().shape({
  witnesses: Yup.array().of(
    Yup.object().shape({
      fullName: Yup.string().required(
        "Obrigatório informar o nome da testemunha."
      ),
      documentNumber: Yup.string()
        .min(11, "O CPF precisa ter no mínimo 11 dígitos.")
        .required("Obrigatório informar o CPF.")
        .test("cpfValidation", "CPF inválido", value => {
          if (value) {
            return FormBuilderUtils.ValidateCPF(value);
          } else {
            return true;
          }
        })
    })
  )
});

export function Witnesses({
  contract,
  processContract
}: WitnessesProps): JSX.Element {
  const witnessesFormId = "witnesses-form-id";
  const [fields] = useState(
    FormBuilderUtils.setValuesOnFields(formFields.witnesses, contract)
  );
  const [initialValues, setInitialValues] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const { openModalWithSettings } = useConfirmModal();

  function clearMessage() {
    setMessage(null);
  }

  useEffect(() => {
    setInitialValues(
      FormBuilderUtils.getInitialValuesFromFields(formFields.witnesses)
    );
  }, []);

  async function onSubmit(values, { setSubmitting }) {
    setMessage(null);

    const documents = new Set();
    values.witnesses.forEach(item => {
      documents.add(item.documentNumber);
    });

    if (documents.size !== 2) {
      setMessage({
        message: "Testemunhas duplicadas, por favor altere e tente novamente.",
        type: messageBarTypes.WARNING
      });

      setSubmitting(false);
    } else if (
      contract?.accountDocument?.documentType === "CPF" &&
      documents.has(contract?.accountDocument?.documentNumber)
    ) {
      setMessage({
        message:
          "O CPF do cooperado não pode ser usado como testemunha, por favor altere e tente novamente.",
        type: messageBarTypes.WARNING
      });

      setSubmitting(false);
    } else {
      openModalWithSettings(
        "Criar contrato",
        "Deseja criar um novo contrato?",
        async () => {
          const abortController = new AbortController();
          const { signal } = abortController;
          const contractsService = ContractsService();
          const params = {
            id: contract.id,
            body: values
          };
          contractsService.edit({ params, signal }).then(response => {
            if (response.error) {
              setMessage({
                message:
                  "Não foi possível adicionar testemunhas, por favor tente novamente",
                type: messageBarTypes.ERROR
              });
              setSubmitting(false);
            } else {
              setSubmitting(false);
              processContract();
            }
          });
        }
      );
    }
  }

  return (
    <>
      <h2 className="ms-fontSize-18" style={{ marginBottom: 20 }}>
        <Icon iconName="Group" style={{ marginRight: 10 }} />
        Testemunhas
      </h2>

      {initialValues && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => (
            <Form id={witnessesFormId}>
              <Stack tokens={{ childrenGap: 15 }}>
                {message && (
                  <MessageBar message={message} dismissMessage={clearMessage} />
                )}

                <FieldsBuilder
                  {...{
                    fields,
                    errors,
                    touched,
                    setFieldValue,
                    values
                  }}
                />

                <Stack.Item align="center">
                  <PrimaryButton
                    id="submit-button"
                    text={
                      isSubmitting ? "Processando..." : "Confirmar Contrato"
                    }
                    disabled={
                      isSubmitting ||
                      !isObjectEmpty(errors) ||
                      isObjectEmpty(touched)
                    }
                    type="primary"
                  />
                </Stack.Item>
              </Stack>

              {isSubmitting && <Loader customMessage="Confirmando..." />}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
