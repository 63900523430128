import { Switch } from "react-router-dom";

import { useGetProfile } from "../libs/api/react-query";

import PrivateRoute from "./PrivateRoute";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import MembershipMigration from "@/modules/MembershipMigration";

function MembershipRoute(): JSX.Element {
  const userProfileQuery = useGetProfile();
  const permissions = userProfileQuery.data?.data?.role.permissions || [];
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/members"
        permissionScope={permissions[PermissionsScope.POS]}
        module={PermissionsPosModule.MEMBERS}
        action={PermissionsAction.READ}
      >
        <MembershipMigration
          canMigrate={permissions[PermissionsScope.POS][
            PermissionsPosModule.ACCOUNTS
          ]?.some(
            (action: string) =>
              action === PermissionsAction.ALL ||
              action === PermissionsAction.LIST
          )}
          canIntegrate={permissions[PermissionsScope.POS][
            PermissionsPosModule.MEMBERS
          ]?.some((action: string) => action === PermissionsAction.INTEGRATE)}
        />
      </PrivateRoute>
    </Switch>
  );
}

export default MembershipRoute;
