import Api from "./api";

class Services extends Api {
  // TODO: Allow ist
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/services${subPaths}` : `services${subPaths}`);
  }
}

export default Services;
