import {
  CompoundButton,
  Dropdown,
  getTheme,
  IconButton,
  IDropdownOption,
  MaskedTextField
} from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import { useEffect, useState } from "react";

import { AdditionalOfferPriceToAppend } from "../../AdditionalComponents/AdditionalOfferPriceToAppend";
import { FiltersOptions } from "../ProductOfferFilters/ProductFilter.styles";

import {
  ProductContractContainer,
  SubmitButton
} from "./ProductContract.styles";
import { ProductContractList } from "./ProductContractList";

import { IccidInputContent } from "@/components/IccidInputContent/IccidInputContent";
import { IAdditionalOfferPrice } from "@/components/Settings/AdditionalComponents/types";
import { Loader } from "@/components/Shared/Loader";
import { IMessageBar, MessageBar } from "@/core/libs/message-bar";
import { SignatureType } from "@/modules/Contracts/enums";
import {
  OperationTypes,
  ProductContractType,
  ProductOfferItem,
  ProductStep,
  SaleCondition
} from "@/modules/Offers/types/ProductsOffers.types";
import { LineStrategyType } from "@/modules/Settings/pages/Operations/enum";
import { BusinessUnit } from "@/modules/User/types";

type ProductContractQuestionsProps = {
  productOffer: ProductOfferItem;
  selectedBU: BusinessUnit;
  setIccid: (inventoryId: number) => void;
  setProductStep: (step: string) => void;
  setContractType: (type: string) => void;
  setOperationType: (operation: OperationTypes) => void;
  setSelectedPhoneNumber: (number: string) => void;
  setSelectedPhoneAreaCode: (number: string) => void;
  setSelectedContract: (contract) => void;
  setSelectedSignatureType: (type: string) => void;
  setSelectedInstallment: (quantity: string) => void;
  onSubmit: (additioanlOfferPrice?: IAdditionalOfferPrice[]) => void;
  submitMessage: IMessageBar;
  clearSubmitMessage: () => void;
  selectedCashValue: string;
  selectedFinancedValue: string;
  isSubmitting: boolean;
  contractType: string;
  selectedSignatureType: string;
  operation: OperationTypes;
  selectedPhoneNumber: string;
  selectedPhoneAreaCode: string;
  isLoadingOptions: boolean;
  isSubmitButtonDisabled: boolean;
  contractOperations: { key: string; text: string }[];
  contractLines: { key: string; text: string }[];
  availableAreaCodes: { key: string; text: string }[];
  carriers: { key: string; text: string }[];
  initialCarrier?: { key: string; text: string };
  setInitialCarrier: (initialCarrier) => void;
  selectedOfferItem: SaleCondition;
  setSelectedPhoneId: (value: number) => void;
  selectedPhoneId: number;
};

const contractTypeOptions = [
  {
    key: ProductContractType.NEW,
    text: ProductContractType.NEW
  },
  {
    key: ProductContractType.EXISTENT,
    text: ProductContractType.EXISTENT
  }
];

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}

function fixCashValue(value) {
  return Math.ceil(value * 10) / 10;
}

const parcelsOptions = (limit, cashValue: string, financedValue: string) => {
  const parcels: IDropdownOption[] = [];

  for (let index = 1; index <= limit; index++) {
    parcels.push({
      key: index,
      text:
        index === 1
          ? `À vista por ${toLocaleString(cashValue)}`
          : `${index} parcelas de ${toLocaleString(
              fixCashValue(Number(financedValue) / index)
            )}`
    });
  }

  return parcels;
};

export function ProductContractQuestions({
  selectedBU,
  onSubmit,
  setIccid,
  clearSubmitMessage,
  setProductStep,
  setContractType,
  setOperationType,
  setSelectedPhoneNumber,
  setSelectedPhoneAreaCode,
  setSelectedContract,
  setSelectedSignatureType,
  setSelectedInstallment,
  submitMessage,
  selectedCashValue,
  selectedFinancedValue,
  contractOperations,
  contractLines,
  availableAreaCodes,
  contractType,
  selectedSignatureType,
  operation,
  selectedPhoneNumber,
  selectedPhoneAreaCode,
  isLoadingOptions,
  isSubmitButtonDisabled,
  isSubmitting,
  carriers,
  initialCarrier,
  setInitialCarrier,
  productOffer,
  setSelectedPhoneId,
  selectedOfferItem,
  selectedPhoneId
}: ProductContractQuestionsProps): JSX.Element {
  const hideStandardSignature =
    selectedBU?.settings?.hideStandardSignature === "true";
  const hideDigitalSignature =
    selectedBU?.settings?.hideDigitalSignature === "true";
  const signatures = [
    { key: SignatureType.ELETRONIC, text: "Digital" },
    { key: SignatureType.STANDARD, text: "Manual" }
  ];

  const visibleSignatures = hideStandardSignature
    ? [signatures[0]]
    : hideDigitalSignature
    ? [signatures[1]]
    : signatures;

  const [isNumberChanged, setIsNumberChanged] = useState<boolean>(false);
  const [isValidIccid, setIsValidIccid] = useState<boolean>(false);
  const [isModalOpen, setIsModalOPen] = useState<boolean>(false);

  useEffect(() => {
    const isSelectedPhoneAreaCodeValid = availableAreaCodes?.some(
      areaCode => areaCode.key === selectedPhoneAreaCode
    );

    if (!isSelectedPhoneAreaCodeValid && !!availableAreaCodes) {
      setSelectedPhoneAreaCode(availableAreaCodes?.[0].key);
    }
  }, [availableAreaCodes, selectedPhoneAreaCode, setSelectedPhoneAreaCode]);

  const theme = getTheme();

  const typedLineStrategies = [
    LineStrategyType.MANUALLY_TYPED_LINE_AND_SEARCH_REMOVED,
    LineStrategyType.MANUALLY_TYPED_LINE_AND_SEARCH_SOLD
  ];
  const cannotChoseLineStrategies = [
    LineStrategyType.NO_LINE,
    LineStrategyType.RANDOM_LINE_SEARCH,
    LineStrategyType.VIRTUAL_LINE
  ];

  const canChoseLine =
    operation && !cannotChoseLineStrategies.includes(operation.lineStrategy);
  const canInsertLineNumber =
    operation && typedLineStrategies.includes(operation.lineStrategy);

  const openAdditionalModal = () => {
    if (selectedOfferItem.offerPrice.additionalOfferPrice.length > 0) {
      setIsModalOPen(true);
    } else {
      onSubmit();
    }
  };

  return (
    <ProductContractContainer>
      <IconButton
        styles={{
          root: {
            color: theme.palette.themeDarkAlt,
            zIndex: 1,
            position: "absolute",
            top: 10,
            left: 10
          },
          rootHovered: {
            color: theme.palette.neutralDark
          }
        }}
        iconProps={{ iconName: "ChevronLeftMed" }}
        ariaLabel="Go back to previous step"
        onClick={() => setProductStep(ProductStep.LIST_PLANS)}
      />

      <div style={{ width: "100%" }}>
        <div
          className="ms-depth-8"
          style={{
            background: theme.palette.white,
            padding: 30,
            marginTop: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Text variant={"xLarge"}>
            Deseja criar um novo contrato ou selecionar um já existente?
          </Text>

          <FiltersOptions>
            {contractTypeOptions.map(type => (
              <CompoundButton
                id={`contractTypeOptions-button-${type.key}`}
                key={String(type.key)}
                checked={contractType === type.key}
                style={{
                  width: "100px",
                  border: "0",
                  borderRadius: "10px"
                }}
                onClick={() => setContractType(String(type.key))}
              >
                <Text variant={"mediumPlus"} style={{ fontWeight: 600 }}>
                  {type.text}
                </Text>
              </CompoundButton>
            ))}
          </FiltersOptions>
        </div>

        {contractType === ProductContractType.EXISTENT && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Selecione um contrato</Text>

            <div
              style={{
                overflowY: "auto",
                maxHeight: 250,
                width: "100%"
              }}
            >
              <ProductContractList setSelectedContract={setSelectedContract} />
            </div>
          </div>
        )}

        {contractType !== undefined && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Selecione o tipo de operação</Text>

            <Dropdown
              required
              onChange={(event, option) => {
                setOperationType(option.data);

                if (selectedPhoneNumber) {
                  setSelectedPhoneNumber(undefined);
                  setSelectedPhoneId(undefined);
                }
              }}
              placeholder="Selecione uma opção"
              options={contractOperations}
              styles={{
                root: { marginTop: 20 },
                dropdown: { width: 200 }
              }}
            />
          </div>
        )}

        {canChoseLine && canInsertLineNumber && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            {operation.id === 2 && (
              <>
                <Text variant={"xLarge"}>Informe a operadora de origem</Text>
                <Dropdown
                  required
                  onChange={(event, option) => {
                    setInitialCarrier(option);
                  }}
                  placeholder="Selecione uma opção"
                  options={carriers}
                  styles={{
                    root: { marginTop: 20, marginBottom: 10 },
                    dropdown: { width: 200 }
                  }}
                />
              </>
            )}

            <Text variant={"xLarge"}>Digite o número do telefone</Text>
            <MaskedTextField
              mask="(99) 99999-9999"
              required
              styles={{
                root: { marginTop: 20 }
              }}
              onChange={(event, newValue) => {
                setSelectedPhoneId(undefined);
                setSelectedPhoneNumber(newValue.replace(/\D/g, ""));
                if (operation.id === 2) {
                  setIsNumberChanged(true);
                }
              }}
              errorMessage={
                selectedPhoneNumber?.length < 11
                  ? "O número deve ter 11 dígitos"
                  : undefined
              }
            />
          </div>
        )}

        {canChoseLine && canInsertLineNumber === false && (
          <div
            className="ms-depth-8"
            style={{
              background: theme.palette.white,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text variant={"xLarge"}>Selecione um número</Text>

            <div style={{ display: "flex", alignItems: "baseline" }}>
              <Dropdown
                placeholder="DDD"
                label="DDD"
                required
                styles={{
                  root: {
                    width: 80,
                    marginRight: 10
                  }
                }}
                options={availableAreaCodes}
                defaultSelectedKey={selectedPhoneAreaCode}
                onChange={(evt, option) => {
                  setSelectedPhoneAreaCode(String(option.key));
                  setSelectedPhoneNumber(undefined);
                  setSelectedPhoneId(undefined);
                }}
                disabled={!availableAreaCodes}
              />

              <Dropdown
                required
                label="Número"
                onChange={(event, option) => {
                  setSelectedPhoneNumber(undefined);
                  setSelectedPhoneId(Number(option?.key));
                  setIsNumberChanged(true);
                }}
                selectedKey={Number(selectedPhoneId)}
                placeholder={
                  selectedPhoneAreaCode?.length !== 2
                    ? "Selecione um DDD"
                    : contractLines === undefined
                    ? "Nenhum número encontrado"
                    : "Selecione um número"
                }
                options={contractLines}
                styles={{
                  root: { marginTop: 20 },
                  dropdown: { width: 200 }
                }}
                disabled={
                  selectedPhoneAreaCode?.length !== 2 ||
                  contractLines === undefined
                }
              />
            </div>
          </div>
        )}

        {(!canInsertLineNumber || operation.id === 2) &&
          isNumberChanged &&
          +productOffer.brand.id !== 8 &&
          contractType !== ProductContractType.EXISTENT && (
            <IccidInputContent
              onIccidValidate={(valid: boolean) => {
                setIsValidIccid(valid);
              }}
              setIccid={iccid => {
                //Sem nescessidade da informação do iccid
                return;
              }}
              setInventoryId={inventoryId => {
                setIccid(inventoryId);
              }}
              operation={operation}
              selectedBU={selectedBU}
            />
          )}
      </div>

      <AdditionalOfferPriceToAppend
        additionalOfferPriceList={
          selectedOfferItem.offerPrice.additionalOfferPrice
        }
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOPen}
        submit={onSubmit}
        submitMessage={submitMessage}
        clearSubmitMessage={clearSubmitMessage}
        canEdit={false}
        planPrice={+selectedOfferItem.offerPrice.price}
      />

      {submitMessage && (
        <div style={{ marginTop: 20, width: "100%" }}>
          <MessageBar
            message={submitMessage}
            dismissMessage={clearSubmitMessage}
          />
        </div>
      )}

      <div style={{ display: "flex", height: "100%" }}>
        <SubmitButton
          disabled={
            isSubmitButtonDisabled || +productOffer.brand.id == 8
              ? false
              : !isValidIccid &&
                operation.type === "STANDARD" &&
                +productOffer.brand.id !== 8
          }
          onClick={openAdditionalModal}
        >
          Adicionar Benefício
        </SubmitButton>
      </div>

      {(isSubmitting || isLoadingOptions) && <Loader />}
    </ProductContractContainer>
  );
}
