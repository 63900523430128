import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

export const ProductImageContainer = styled.div`
  display: flex;
  padding: 20px;
  height: 250px;
  justify-content: center;
  overflow: hidden;
`;

export const ProductImage = styled.img`
  height: auto;
  max-height: 200px;
`;

export const ProductInfoContainer = styled.div`
  padding: 20px;
`;

export const ProductBrand = styled.p`
  color: ${theme.palette.neutralTertiary};
  text-transform: uppercase;
`;

export const ProductTitle = styled.p`
  color: ${theme.palette.black};
  margin: 10px 0;
`;

export const ProductDescription = styled.p`
  color: ${theme.palette.neutralTertiary};
  margin: 5px 0;
`;

export const ButtonBuy = styled.button`
  background: ${theme.palette.themeSecondary};
  border: none;
  color: ${theme.palette.white};
  cursor: pointer;
  padding: 10px;
  width: 100%;
  margin-top: auto;
`;
