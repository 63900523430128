import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  width: 300px;
  height: auto;

  h6,
  p {
    margin-top: 5px;

    text-align: center;
    color: #395ca3;
    font-weight: 600;
  }
  p {
    font-weight: 400;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 10px;

  padding: 15px 10px;
  width: 300px;
  height: auto;

  background-color: white;

  span {
    color: #395ca3;
    font-weight: 600;
  }

  .api-alerts-message {
    width: 22rem;
    font-size: 0.7rem;
  }

  .secondary-content {
    display: flex;
    justify-content: space-between;

    color: #395ca3;
    font-weight: 600;
  }
  .response {
    font-size: 1rem;
  }
`;
