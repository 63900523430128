import { IDetailsListStyles, IDropdownStyles } from "@fluentui/react";
import styled from "styled-components";

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdownOptionText: {
    overflow: "visible",
    whiteSpace: "normal"
  },
  dropdownItem: {
    height: "auto",
    padding: 8
  },
  dropdownItemSelected: {
    height: "auto",
    padding: 8
  },
  root: {
    width: "100%",
    overflow: "hidden"
  }
};

export const detailsListStyles: Partial<IDetailsListStyles> = {
  root: {
    overflowX: "clip" // this is necessary for position: sticky to work
  },
  headerWrapper: {
    position: "sticky",
    top: 0,
    zIndex: 1
  },
  contentWrapper: {
    "& .ms-DetailsRow-cell": {
      // centraliza o conteúdo da célula
      display: "flex",
      alignItems: "center"
    }
  }
};

export const ContractProductsRenewalListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContractProductsRenewalListWrapper = styled.div`
  max-height: 365px;
  overflow: auto;
`;

export const ContractProductsRenewalListOfferControls = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`;
