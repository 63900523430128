import { MessageBarType } from "@fluentui/react";
import { useEffect } from "react";

import { MessageBarStyled } from "./styles";
import { IAlert } from "./types";

export const Alert = ({
  message,
  level,
  isAlertOpen,
  setIsAlertOpen,
  timeout = 3000
}: IAlert) => {
  useEffect(() => {
    if (isAlertOpen) {
      setTimeout(() => {
        setIsAlertOpen(false);
      }, timeout);
    }
  }, [isAlertOpen]);

  const translateLevelToColor = (level: MessageBarType) => {
    switch (level) {
      case MessageBarType.error:
        return { backgroundColor: "#FCCDD0", textColor: "#AF1111" };

      // Success
      case MessageBarType.success:
        return { backgroundColor: "#B8E6B8", textColor: "#004d00" };

      // Warning
      case MessageBarType.warning:
        return { backgroundColor: "#FFF2C2", textColor: "#816500" };

      case MessageBarType.info:
        return { backgroundColor: "#395CA3", textColor: "#fff" };

      default:
        return { backgroundColor: "#E9E8E7", textColor: "default" };
    }
  };

  return (
    <>
      {isAlertOpen && (
        <MessageBarStyled
          styles={{
            icon: { color: translateLevelToColor(level).textColor },
            dismissal: {
              ".ms-Button-icon": {
                color: translateLevelToColor(level).textColor
              }
            }
          }}
          messageBarType={level}
          isMultiline={true}
          onDismiss={() => {
            setIsAlertOpen(false);
          }}
          dismissButtonAriaLabel="Fechar"
          delayedRender={true}
          bgColor={translateLevelToColor(level).backgroundColor}
          textColor={translateLevelToColor(level).textColor}
        >
          {message}
        </MessageBarStyled>
      )}
    </>
  );
};
