import { ListOfInventories } from "./Inventories.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum InventoriesActionsTypes {
  CREATE_REQUEST = "inventories/CREATE_REQUEST",
  CREATE_FAIL = "inventories/CREATE_FAIL",
  CREATE_SUCCESS = "inventories/CREATE_SUCCESS",
  DISMISS_MESSAGE = "inventories/DISMISS_MESSAGE",
  EDIT_REQUEST = "inventories/EDIT_REQUEST",
  EDIT_FAIL = "inventories/EDIT_FAIL",
  EDIT_SUCCESS = "inventories/EDIT_SUCCESS",
  TRANSFER_REQUEST = "inventories/TRANSFER_REQUEST",
  TRANSFER_FAIL = "inventories/TRANSFER_FAIL",
  TRANSFER_SUCCESS = "inventories/TRANSFER_SUCCESS",
  LIST_REQUEST = "inventories/LIST_REQUEST",
  LIST_FAIL = "inventories/LIST_FAIL",
  LIST_SUCCESS = "inventories/LIST_SUCCESS"
}

export interface InventoriesState {
  isLoadingList: boolean;
  list?: ListOfInventories;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: InventoriesActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: InventoriesActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: InventoriesActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: InventoriesActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: InventoriesActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: InventoriesActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: InventoriesActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface TransferSuccess {
  type: InventoriesActionsTypes.TRANSFER_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface TransferRequest {
  type: InventoriesActionsTypes.TRANSFER_REQUEST;
}

export interface TransferError {
  type: InventoriesActionsTypes.TRANSFER_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: InventoriesActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: InventoriesActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: InventoriesActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfInventories;
  };
}

export type InventoriesReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | TransferRequest
  | TransferError
  | TransferSuccess
  | ListRequest
  | ListError
  | ListSuccess;
