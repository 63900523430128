import { Dropdown, IDropdownOption } from "@fluentui/react";

import { DefaultProps } from "./types";

interface ContractOperationProps extends DefaultProps {
  operations: IDropdownOption[];
  resetCarrierAndOffers: () => void;
  formatAndSetCarriers: (carriers) => void;
}

export function ContractOperation({
  setFieldValue,
  formatAndSetCarriers,
  resetCarrierAndOffers,
  operations
}: ContractOperationProps): JSX.Element {
  return (
    <Dropdown
      id="select-contract-operation"
      label="Selecione uma operação"
      placeholder="Selecione uma operação"
      options={operations}
      required
      onChange={(_, item) => {
        setFieldValue("line", "");
        setFieldValue("lineNumber", "");
        setFieldValue("plan", undefined);
        setFieldValue("additionalInfo", undefined);
        setFieldValue("offerPrice", "");
        setFieldValue("operation", item.key);
        resetCarrierAndOffers();
        formatAndSetCarriers(item.data.carriers);
      }}
    />
  );
}
