import { ModelsGroupsActionsTypes } from "../types/ModelsGroups.reducer.types";

export function createModelsGroupsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ModelsGroupsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createModelsGroupsSuccess(message) {
  return {
    type: ModelsGroupsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createModelsGroupsFailure(message) {
  return {
    type: ModelsGroupsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editModelsGroupsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel,
  refreshList?
) {
  return {
    type: ModelsGroupsActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin, refreshList }
  };
}

export function editModelsGroupsSuccess(message) {
  return {
    type: ModelsGroupsActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editModelsGroupsFailure(message) {
  return {
    type: ModelsGroupsActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function deleteModelsGroupsRequest(
  values,
  { setSubmitting, setSubmitted, isAdmin = false },
  closePanel
) {
  return {
    type: ModelsGroupsActionsTypes.DELETE_REQUEST,
    payload: { values, setSubmitting, setSubmitted, isAdmin, closePanel }
  };
}

export function deleteModelsGroupsSuccess(message) {
  return {
    type: ModelsGroupsActionsTypes.DELETE_SUCCESS,
    payload: { message }
  };
}

export function deleteModelsGroupsFailure(message) {
  return {
    type: ModelsGroupsActionsTypes.DELETE_FAIL,
    payload: { message }
  };
}

export function listModelsGroupsRequest(filter = {}, { isAdmin = false }) {
  return {
    type: ModelsGroupsActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listModelsGroupsSuccess({ list }) {
  return {
    type: ModelsGroupsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listModelsGroupsFailure() {
  return {
    type: ModelsGroupsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ModelsGroupsActionsTypes.DISMISS_MESSAGE
  };
}
