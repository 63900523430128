import { IBusinessUnit } from "../interfaces";

export const formatBUList = (businessUnits: IBusinessUnit[]) => {
  if (!businessUnits || businessUnits.length === 0) return;
  const arr = businessUnits.map(bu => {
    const newBu = {
      label: bu?.name,
      value: bu?.id,
      children: bu?.children ? formatBUList(bu?.children) : [],
      checked: false
    };
    return newBu;
  });

  return arr;
};
