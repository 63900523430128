import { PermissionsAction } from "@/common/types/permissions";

export const checkUserPermission = ({
  permissions,
  scope,
  module,
  action
}): boolean => {
  if (!action) return true;

  return permissions[scope]?.[module]?.some(
    actionName => actionName === PermissionsAction.ALL || actionName === action
  );
};
