import { getTheme, Icon, Stack, TooltipHost } from "@fluentui/react";

import { OverrideValue } from "./styles";

import { productAppIcons } from "@/components/Offers/ProductOffers/ProductOfferPlans/ProductPlanItem";
import {
  AdditionalPriceInfo,
  CurrencySymbol,
  DevicePrice,
  PlanPrice,
  PriceContainer,
  PriceTitle,
  ProductAppIcon,
  ProductBrandLogo,
  ProductItem,
  ProductPlanName,
  ProductPricing,
  ProductRow,
  TotalPrice
} from "@/components/Offers/ProductOffers/ProductOfferPlans/ProductPlans.styles";
import { ProductItemProps } from "@/modules/Contracts/types";
import { OfferCombo } from "@/modules/Settings/types/Offers.types";
import { ProductCategoryCodes } from "@/modules/Settings/types/ProductsCategories.types";
import { convertCurrencyStringToNumber } from "@/utils/ConvertCurrencyStringToNumber";

function ProductListItem({ item }: ProductItemProps): JSX.Element {
  const theme = getTheme();

  const isSpecialOffer =
    (item.offerPrice?.offer?.plan?.name?.search(/especial/i) !== -1 ||
      item.offerPrice?.offer?.plan?.name?.search(/black friday 2021/i) !==
        -1) &&
    item?.offerPrice?.offer?.combo === OfferCombo.SMART;

  const getPriceValue = () => {
    const offerOverrideDiscount = convertCurrencyStringToNumber(
      item.offerOverride?.discount
    );
    const offerPriceDiscount = convertCurrencyStringToNumber(
      item.offerPrice?.offer?.discount?.value
    );
    const offerPrice = convertCurrencyStringToNumber(item.offerPrice?.price);
    const offerOverridePrice = convertCurrencyStringToNumber(
      item.offerOverride?.price
    );

    const price = offerOverridePrice || offerPrice;
    const discount = item.offerOverride
      ? offerOverrideDiscount
      : offerPriceDiscount;

    return preventNegativeNumbers(price - discount);
  };

  const getBenefitValue = () => {
    const price = getPriceValue();
    const benefit =
      item?.additionalContractItems?.reduce((sum: number, item) => {
        const monthlyFee = parseFloat(item?.monthlyFeeOverride) || 0;
        return sum + monthlyFee;
      }, 0) || 0;
    return price + benefit;
  };

  const getActivationValue = () => {
    const activation =
      item?.offerOverride?.activationFee ??
      item?.offerPrice?.offer?.activationFee;
    const activationValue =
      item.additionalContractItems
        .map(item => parseFloat(item?.activationValueOverride))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0) +
      parseFloat(
        (item?.offerOverride?.sim as string) ?? item.offerPrice?.offer?.simValue
      ) +
      parseFloat(activation?.toString());

    return activationValue;
  };

  const renderDiscountLabel = () => {
    if (
      item.offerOverride?.discount ||
      item.offerPrice?.offer?.discount?.value
    ) {
      return "*";
    }
    return null;
  };

  return (
    <Stack
      verticalAlign="center"
      style={{
        width: "100%",
        marginRight: 10,
        marginTop: 10
      }}
    >
      <ProductRow>
        <div>
          <ProductBrandLogo
            src={item?.offerPrice?.offer?.plan?.carrier?.logo}
            alt={item?.offerPrice?.offer?.plan?.carrier?.name}
          />

          <ProductPlanName
            isSpecial={isSpecialOffer}
            className="ms-fontSize-20 ms-fontWeight-bold"
          >
            {isSpecialOffer ? "** " : ""} {item?.offerPrice?.offer?.plan?.name}
          </ProductPlanName>
        </div>

        {item?.offerPrice?.offer?.plan?.freeDataApps?.length > 0 && (
          <div style={{ marginLeft: 10 }}>
            <TooltipHost
              content={item?.offerPrice?.offer?.plan?.appsInfos
                ?.reduce(
                  (allPhrase, currentPhrase) => allPhrase + " " + currentPhrase,
                  ""
                )
                .toString()}
              styles={{
                root: { position: "relative", bottom: "10%" }
              }}
            >
              <Icon
                iconName="Info"
                style={{
                  fontSize: 16,
                  cursor: "default"
                }}
              />
            </TooltipHost>

            {item?.offerPrice?.offer?.plan?.freeDataApps.map(app => (
              <ProductAppIcon
                src={productAppIcons[app]}
                alt={app + " ICON APP"}
                key={app}
              />
            ))}
          </div>
        )}
      </ProductRow>

      <ProductRow>
        <div>
          {isSpecialOffer && (
            <ProductItem className="ms-fontSize-16">
              <Icon
                iconName="Info"
                style={{
                  fontSize: 18,
                  marginRight: 15
                }}
              />
              <span
                style={{
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  color: theme.palette.red
                }}
              >
                ** Atenção! Este benefício possui condições especiais para
                validação. Consulte o Book de pacotes de Benefícios vigente para
                mais detalhes.
              </span>
            </ProductItem>
          )}

          {item?.offerPrice?.offer?.plan?.voice && (
            <ProductItem className="ms-fontSize-16">
              <Icon
                iconName="Phone"
                style={{
                  fontSize: 18,
                  marginRight: 15
                }}
              />
              <span>{item.offerPrice.offer.plan.voice}</span>
            </ProductItem>
          )}

          <ProductItem className="ms-fontSize-16">
            {item?.offerPrice?.offer?.plan?.data && (
              <>
                <Icon
                  iconName="Globe"
                  style={{
                    fontSize: 18,
                    marginRight: 15
                  }}
                />
                <span>
                  {Number(item?.offerPrice?.offer?.plan?.data) ||
                    0 + Number(item?.offerPrice?.offer?.bonusData?.value) ||
                    0}{" "}
                  GB de internet móvel
                </span>
              </>
            )}

            {Number(item?.offerOverride?.bonusData) > 0 ||
            (isNaN(Number(item?.offerOverride?.bonusData)) &&
              item?.offerPrice?.offer?.bonusData?.value) ? (
              <span>
                &nbsp;+ bônus de{" "}
                {Number(item?.offerOverride?.bonusData) ? (
                  <>
                    <span style={{ textDecoration: "line-through" }}>
                      {item?.offerPrice?.offer?.bonusData?.value}
                    </span>{" "}
                    {item?.offerOverride?.bonusData}
                  </>
                ) : (
                  Number(item?.offerPrice?.offer?.bonusData?.value)
                )}{" "}
                GB por{" "}
                {Number(item?.offerOverride?.bonusDataNumberOfMonths) ? (
                  <>
                    <span style={{ textDecoration: "line-through" }}>
                      {item?.offerPrice?.offer?.bonusData?.numberOfMonths}
                    </span>{" "}
                    {item?.offerOverride?.bonusDataNumberOfMonths}
                  </>
                ) : (
                  Number(item?.offerPrice?.offer?.bonusData?.numberOfMonths)
                )}{" "}
                meses
              </span>
            ) : item?.offerPrice?.offer?.bonusData?.value ? (
              <>
                &nbsp;
                <span style={{ textDecoration: "line-through" }}>
                  + bônus de {Number(item?.offerPrice?.offer?.bonusData?.value)}{" "}
                  GB por{" "}
                  {Number(item?.offerPrice?.offer?.bonusData?.numberOfMonths)}{" "}
                  meses
                </span>
                <OverrideValue theme={theme}>&nbsp;SEM BÔNUS</OverrideValue>
              </>
            ) : null}
          </ProductItem>

          <ProductItem className="ms-fontSize-16">
            <Icon
              iconName="Money"
              style={{
                fontSize: 18,
                marginRight: 15
              }}
            />
            <span>
              {item?.offerOverride?.discount ||
              item?.offerPrice?.offer?.discount?.value
                ? "* "
                : null}
              Pacote de Benefício no valor de{" "}
              {Number(item?.offerOverride?.price) ? (
                <>
                  <span style={{ textDecoration: "line-through" }}>
                    R$ {toLocaleString(item?.offerPrice?.price)}
                  </span>{" "}
                  <OverrideValue theme={theme}>
                    R$ {toLocaleString(item.offerOverride.price)}
                  </OverrideValue>
                </>
              ) : (
                `R$ ${toLocaleString(item?.offerPrice?.price)}`
              )}
              {!isNaN(Number(item?.offerOverride?.discount)) &&
              Number(item?.offerOverride?.discount) > 0 ? (
                <>
                  {" "}
                  com desconto de{" "}
                  <span style={{ textDecoration: "line-through" }}>
                    R${" "}
                    {toLocaleString(item?.offerPrice?.offer?.discount?.value)}
                  </span>{" "}
                  <OverrideValue theme={theme}>
                    R$ {toLocaleString(item.offerOverride.discount)}
                  </OverrideValue>
                </>
              ) : isNaN(Number(item?.offerOverride?.discount)) &&
                item?.offerPrice?.offer?.discount?.value ? (
                ` com desconto de R$ ${toLocaleString(
                  item.offerPrice.offer.discount.value
                )}`
              ) : item?.offerPrice?.offer?.discount?.value ? (
                <>
                  {" "}
                  <span style={{ textDecoration: "line-through" }}>
                    com desconto de R${" "}
                    {toLocaleString(item.offerPrice.offer.discount.value)} por{" "}
                    {item.offerPrice.offer.discount.numberOfMonths} meses a
                    partir da {item.offerPrice.offer.discount.beginsAt}.ª fatura
                  </span>{" "}
                  <OverrideValue theme={theme}>SEM DESCONTO</OverrideValue>
                </>
              ) : null}
              {Number(item?.offerOverride?.discount) > 0 &&
              item?.offerOverride?.discountNumberOfMonths ? (
                <>
                  {" "}
                  por{" "}
                  <span style={{ textDecoration: "line-through" }}>
                    {item?.offerPrice?.offer?.discount?.numberOfMonths}
                  </span>{" "}
                  <OverrideValue theme={theme}>
                    {item.offerOverride.discountNumberOfMonths}
                  </OverrideValue>{" "}
                  meses a partir da
                </>
              ) : isNaN(Number(item?.offerOverride?.discount)) &&
                item?.offerPrice?.offer?.discount?.numberOfMonths ? (
                ` por ${item.offerPrice.offer.discount.numberOfMonths} meses a partir da`
              ) : null}
              {Number(item?.offerOverride?.discount) > 0 &&
              item?.offerOverride?.discountBeginsAt ? (
                <>
                  {" "}
                  <span style={{ textDecoration: "line-through" }}>
                    {item?.offerPrice?.offer?.discount?.beginsAt}
                  </span>{" "}
                  <OverrideValue theme={theme}>
                    {item.offerOverride.discountBeginsAt}.ª
                  </OverrideValue>{" "}
                  fatura
                </>
              ) : isNaN(Number(item?.offerOverride?.discount)) &&
                item?.offerPrice?.offer?.discount?.beginsAt ? (
                ` ${item.offerPrice.offer.discount.beginsAt}.ª fatura`
              ) : null}
              {!isNaN(Number(item?.offerOverride?.activationFee)) ? (
                <>
                  {" "}
                  e valor a ser cobrado de ativação de{" "}
                  <span style={{ textDecoration: "line-through" }}>
                    R$ {toLocaleString(item?.offerPrice?.offer?.activationFee)}
                  </span>{" "}
                  <OverrideValue theme={theme}>
                    R$ {toLocaleString(item.offerOverride.activationFee)}
                  </OverrideValue>
                </>
              ) : item?.offerPrice?.offer?.activationFee ? (
                ` e valor a ser cobrado
                de ativação de R$ ${toLocaleString(
                  item.offerPrice.offer.activationFee
                )}`
              ) : null}
            </span>
          </ProductItem>

          {item?.inventory &&
            item?.inventory.map(iv => {
              return (
                <>
                  <ProductItem minWidth="200px" className="ms-fontSize-16">
                    <Icon
                      iconName={
                        (iv.product?.category?.code ===
                          ProductCategoryCodes.CELULAR &&
                          "CellPhone") ||
                        (iv.product?.category?.code ===
                          ProductCategoryCodes.INTERNET_E_MODEM &&
                          "WifiEthernet") ||
                        (iv.product?.category?.code ===
                          ProductCategoryCodes.MONITORAMENTO_E_RASTREAMENTO &&
                          "NetworkTower") ||
                        (iv.product?.category?.code ===
                          ProductCategoryCodes.CHIP &&
                          "WifiEthernet")
                      }
                      style={{
                        fontSize: 18,
                        marginRight: 15
                      }}
                    />
                    <span>
                      {`${iv?.product?.name} ${
                        iv?.product?.specifications?.color
                          ? `-  ${iv?.product?.specifications?.color}`
                          : ""
                      } ${
                        iv?.product?.specifications?.memory?.ram
                          ? `- ${iv?.product?.specifications?.memory?.ram} GB RAM`
                          : ""
                      } ${
                        iv?.product?.specifications?.memory?.storage
                          ? `- ${iv?.product?.specifications?.memory?.storage} GB`
                          : ""
                      } ${
                        iv?.product?.specifications?.screen?.size
                          ? `- Tela de ${iv?.product?.specifications?.screen?.size}"`
                          : ""
                      }`}
                    </span>
                    {(iv?.serialType === "IMEI" ||
                      iv?.serialType === "ICCID") && (
                      <ProductItem
                        minWidth="200px"
                        className="ms-fontSize-16"
                        style={{ marginLeft: "4px" }}
                      >
                        <h1
                          className="ms-fontWeight-semibold"
                          style={{ marginBottom: "0px" }}
                        >
                          {iv?.serialType === "IMEI"
                            ? "IMEI: "
                            : iv?.serialType === "ICCID" && "ICCID: "}
                          <span className="ms-fontWeight-regular">
                            {iv?.serialNumber}
                          </span>
                        </h1>
                      </ProductItem>
                    )}
                  </ProductItem>
                </>
              );
            })}
        </div>

        <ProductPricing>
          {!item?.offerPrice?.offer?.activationFee &&
            item?.additionalContractItems?.length > 0 && (
              <PlanPrice>
                <PriceTitle className="ms-fontSize-16 ms-fontWeight-bold">
                  Valor total de Ativação
                </PriceTitle>
                <PriceContainer>
                  <div>
                    <CurrencySymbol className="ms-fontSize-10 ms-fontWeight-bold">
                      R$
                    </CurrencySymbol>{" "}
                    <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                      {toLocaleString(getActivationValue())}
                      {renderDiscountLabel()}
                    </TotalPrice>
                  </div>

                  <AdditionalPriceInfo className="ms-fontSize-12 ms-fontWeight-semibold">
                    na aderência
                  </AdditionalPriceInfo>
                </PriceContainer>
              </PlanPrice>
            )}

          <PlanPrice>
            <PriceTitle className="ms-fontSize-16 ms-fontWeight-bold">
              Plano de benefícios
            </PriceTitle>
            <PriceContainer>
              <div>
                <CurrencySymbol className="ms-fontSize-10 ms-fontWeight-bold">
                  R$
                </CurrencySymbol>{" "}
                <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                  {toLocaleString(getPriceValue())}
                  {renderDiscountLabel()}
                </TotalPrice>
              </div>

              <AdditionalPriceInfo className="ms-fontSize-12 ms-fontWeight-semibold">
                mensal
              </AdditionalPriceInfo>
            </PriceContainer>
          </PlanPrice>

          <PlanPrice>
            <PriceTitle className="ms-fontSize-16 ms-fontWeight-bold">
              Valor total mensal do benefício
            </PriceTitle>
            <PriceContainer>
              <div>
                <CurrencySymbol className="ms-fontSize-10 ms-fontWeight-bold">
                  R$
                </CurrencySymbol>{" "}
                <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                  {toLocaleString(getBenefitValue())}
                  {renderDiscountLabel()}
                </TotalPrice>
              </div>

              <AdditionalPriceInfo className="ms-fontSize-12 ms-fontWeight-semibold">
                mensal
              </AdditionalPriceInfo>
            </PriceContainer>
          </PlanPrice>

          {!!item?.inventory?.length &&
            item.inventory.map(iv => (
              <DevicePrice key={iv.id}>
                <PriceTitle
                  className="ms-fontSize-16 ms-fontWeight-bold"
                  style={{ width: "fit-content" }}
                >
                  {iv.serialType === "ICCID" ? "Chip" : "Aparelho"}
                </PriceTitle>

                {iv.serialType !== "ICCID" &&
                (Number(item?.offerOverride?.productFinancedValue) > 0 ||
                  (isNaN(Number(item?.offerOverride?.productFinancedValue)) &&
                    Number(item?.saleCondition?.financedValue) > 0)) &&
                Number(item?.additionalInfo?.installment) > 1 ? (
                  <PriceContainer>
                    <div>
                      <CurrencySymbol className="ms-fontSize-10 ms-fontWeight-bold">
                        R$
                      </CurrencySymbol>{" "}
                      <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                        {toLocaleString(
                          preventNegativeNumbers(
                            roundCents(
                              Number(
                                item?.offerOverride?.productFinancedValue ||
                                  item?.saleCondition?.financedValue
                              ) / item?.additionalInfo?.installment
                            )
                          )
                        )}
                      </TotalPrice>
                    </div>

                    <AdditionalPriceInfo className="ms-fontSize-12 ms-fontWeight-semibold">
                      {`1 + ${item?.additionalInfo?.installment - 1}X`}
                    </AdditionalPriceInfo>
                  </PriceContainer>
                ) : (
                  <PriceContainer>
                    {iv.serialType !== "ICCID" &&
                    (Number(item?.offerOverride?.productCashValue) > 0 ||
                      (isNaN(Number(item?.offerOverride?.productCashValue)) &&
                        Number(item?.saleCondition?.cashValue) > 0)) ? (
                      <>
                        <div>
                          <CurrencySymbol className="ms-fontSize-10 ms-fontWeight-bold">
                            R$
                          </CurrencySymbol>{" "}
                          <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                            {toLocaleString(
                              item?.offerOverride?.productCashValue ||
                                item?.saleCondition?.cashValue
                            )}
                          </TotalPrice>
                        </div>
                        <AdditionalPriceInfo className="ms-fontSize-12 ms-fontWeight-semibold">
                          à vista
                        </AdditionalPriceInfo>
                      </>
                    ) : iv.serialType === "ICCID" ? (
                      <>
                        <div>
                          <CurrencySymbol className="ms-fontSize-10 ms-fontWeight-bold">
                            R$
                          </CurrencySymbol>{" "}
                          <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                            {toLocaleString(
                              item?.offerPrice?.offer?.simValue ?? 0
                            )}
                          </TotalPrice>
                        </div>
                        <AdditionalPriceInfo className="ms-fontSize-12 ms-fontWeight-semibold">
                          à vista
                        </AdditionalPriceInfo>
                      </>
                    ) : (
                      <>
                        <PriceContainer>
                          <div>
                            <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                              GRÁTIS
                            </TotalPrice>
                          </div>
                        </PriceContainer>
                      </>
                    )}
                  </PriceContainer>
                )}
              </DevicePrice>
            ))}
        </ProductPricing>
      </ProductRow>
    </Stack>
  );
}

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", { minimumFractionDigits: 2 });
}

function roundCents(value) {
  return Math.ceil(value * 100) / 100;
}

function preventNegativeNumbers(value) {
  return value > 0 ? value : 0;
}

export default ProductListItem;
