import { Icon, Separator } from "@fluentui/react";

import {
  AdditionalContent,
  Container,
  Content,
  PlanContent
} from "./styles/styles";
import { calcMinValue } from "./utils/calcMinValue";

import { Plan } from "@/modules/Offers/types/PlansOffers.types";
import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";

export const OffersToolTipContent = ({ plan }: { plan: Plan }) => {
  const { voice, name } = plan;

  const discount = plan.offer?.discount?.value;
  const price = Number(plan.offer.offerPrice.price);
  const value = discount ? price - discount : price;
  const additionals = plan.offer.offerPrice?.additionalOfferPrice;
  const additionalBonus = additionals.filter(additional => additional?.bonus);
  const additionalService = additionals.filter(
    additional => !additional?.bonus && !additional?.additionalOffer?.product
  );

  return (
    <Container>
      <div>
        <Separator>
          <h1>{name}</h1>
        </Separator>
        <Content>
          <PlanContent>
            <Icon
              iconName="Calendar"
              style={{
                fontSize: 15,
                marginRight: 15,
                color: "#395ca3"
              }}
            />
            <div>{voice ? voice.split(".")[0] : null}.</div>
          </PlanContent>
          <PlanContent>
            <Icon
              iconName="Money"
              style={{
                fontSize: 15,
                marginRight: 15,
                marginTop: 5,
                color: "#395ca3"
              }}
            />
            <div className="price">{transformToCurrencyString(value)}</div>
          </PlanContent>
          {!!additionals.length && (
            <AdditionalContent>
              <Separator>
                <h2>ADICIONAIS</h2>
              </Separator>
              <div className="table">
                <div className="table-offer-header">
                  <strong>Bônus:</strong>
                  <strong>Valor:</strong>
                </div>
                {additionalBonus.length > 0 &&
                  additionalBonus.map(additional => {
                    if (!additional.additionalOffer) {
                      return;
                    }
                    return (
                      <div className="offer-content" key={additional.id}>
                        <div className="description">
                          <p>
                            {additional.additionalOffer.name} -{" "}
                            {additional?.bonus}{" "}
                            <span>
                              {additional.additionalOffer.required
                                ? "(Obrigatório)"
                                : ""}
                            </span>
                          </p>
                        </div>
                        <div className="value">
                          <p>
                            {transformToCurrencyString(additional.monthlyFee)}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                <div className="table-offer-header">
                  <strong>Benefício(s) Prestado(s):</strong>
                </div>

                {additionalService.length > 0 && (
                  <>
                    {additionalService.map(additional => {
                      if (!additional.additionalOffer) {
                        return;
                      }
                      return (
                        <div className="offer-content" key={additional.id}>
                          <div className="description">
                            <p>
                              {additional.additionalOffer.name} -{" "}
                              {additional?.bonus}{" "}
                              <span>
                                {additional.additionalOffer.required
                                  ? "(Obrigatório)"
                                  : ""}
                              </span>
                            </p>
                          </div>
                          <div className="value">
                            <p>
                              {transformToCurrencyString(additional.monthlyFee)}
                            </p>
                            <br />
                          </div>
                        </div>
                      );
                    })}
                    <div className="table-offer-header">
                      <strong>
                        {`A partir de ${calcMinValue(
                          additionals,
                          value
                        )} mensais:`}
                      </strong>
                    </div>
                  </>
                )}
              </div>
            </AdditionalContent>
          )}
        </Content>
      </div>
    </Container>
  );
};
