import {
  ComboBox,
  Dropdown,
  IComboBox,
  IComboBoxOption,
  SpinnerSize,
  Stack
} from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { DefaultProps, StepForm } from "./types";

import { Loader } from "@/components/Shared/Loader";
import { ContractsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";
import { useSelectedBu } from "@/hooks/useSelectedBu";

interface SearchLinesProps extends DefaultProps {
  planId: number;
  user: any;
  setQuestionStep: (step: string) => void;
  values: any;
}

const abortController = new AbortController();
const { signal } = abortController;
const contractsService = ContractsService();

function removeMask(value: string) {
  return value.replace(/\D/g, "");
}

function SearchLines({
  planId,
  setMessage,
  setFieldValue,
  setQuestionStep,
  values
}: SearchLinesProps): JSX.Element {
  const comboBoxRef = useRef<IComboBox>(null);
  const { selectedBU } = useSelectedBu();
  const [isFocused, setIsFocused] = useState(false);
  const [linesOptions, setLinesOptions] = useState<IComboBoxOption[]>(null);
  const [isLoadingLine, setIsLoadingLine] = useState(false);
  const [contractAreaCodes, setContractAreaCodes] = useState(null);
  const [selectedDDD, setSelectedDDD] = useState<string>(null);
  const [selectedNumberKey, setSelectedNumberKey] = useState<number>(null);

  useEffect(() => {
    if (selectedBU?.defaultAreaCode) {
      setSelectedDDD(selectedBU?.defaultAreaCode?.toString());
    }
  }, [selectedBU]);

  useEffect(() => {
    if (!values.line) {
      setSelectedNumberKey(null);
    }
  }, [values.line]);

  async function linesRequest() {
    const response = await contractsService.listLines({
      params: {
        query: {
          plan: planId,
          ddd: selectedDDD,
          businessActionId: selectedBU?.id
        }
      },
      signal
    });

    if (response.error) {
      setMessage({
        message:
          "Não foi possível listar as linhas, por favor atualize a página",
        type: messageBarTypes.ERROR
      });
    } else {
      const formattedLines = response.data.lines.map(line => ({
        key: line.id,
        text: `${line.number.slice(2, -4)}-${line.number.slice(-4)}`
      }));

      const formattedAreaCodes = response.data.availableAreaCodes.map(
        areaCode => ({ key: areaCode.code, text: areaCode.code })
      );

      setLinesOptions(formattedLines);
      setContractAreaCodes(formattedAreaCodes);
    }
  }

  useEffect(() => {
    setLinesOptions([]);

    if (selectedDDD && planId) {
      setMessage(undefined);
      setFieldValue("line", undefined);
      linesRequest();
    }
  }, [selectedDDD, planId]);

  async function findByNumber(phoneNumber: number) {
    if (selectedDDD && planId) {
      setMessage(undefined);
      setIsLoadingLine(true);

      const response = await contractsService.listLines({
        params: {
          query: {
            plan: planId,
            ddd: selectedDDD,
            lineNumber: phoneNumber,
            businessActionId: selectedBU?.id
          }
        },
        signal
      });

      if (response.error || !response.data.lines[0]) {
        setMessage({
          message:
            !!response.error && response.error.message
              ? response.error.message
              : "Não foi encontrada uma linha com esse numero, por favor verifique se todos os digitos estão corretos.",
          type: messageBarTypes.ERROR
        });
        //clear values if response returns error
        setSelectedNumberKey(null);
        setFieldValue("line", undefined);
        setFieldValue("offerOverride.lineNumber", undefined);
      } else {
        //get first object line of array
        const [line] = response.data.lines;

        const formattedLine = {
          key: line.id,
          text: `${line.number?.slice(2, -4)}-${line.number?.slice(-4)}`,
          data: { isPersonalizedLine: true }
        };

        setLinesOptions(prevState => [...prevState, formattedLine]);
        setSelectedNumberKey(formattedLine.key);
        setFieldValue("line", formattedLine.key);
        setFieldValue("offerOverride.lineNumber", line.number);
      }

      setIsLoadingLine(false);
    }
  }

  function handleComboBoxClick() {
    if (isFocused) {
      comboBoxRef.current.dismissMenu();
      setIsFocused(false);
    } else {
      comboBoxRef.current.focus(true);
      setIsFocused(true);
    }
  }

  function handleComboBoxChange(event, option?, index?, value?) {
    setQuestionStep(StepForm.LINE_STEP);
    if (option?.key) {
      setFieldValue("line", option.key);
      setSelectedNumberKey(Number(option.key));

      if (option.data?.isPersonalizedLine) {
        setFieldValue(
          "offerOverride.lineNumber",
          removeMask(`${selectedDDD}${option.text}`)
        );
      } else {
        setFieldValue("offerOverride.lineNumber", undefined);
      }
    } else {
      findByNumber(value);
    }
  }

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 10 }}
      styles={{
        root: {
          marginTop: 0
        }
      }}
    >
      <Dropdown
        placeholder="DDD"
        label="DDD"
        required
        styles={{ root: { width: 80 } }}
        options={contractAreaCodes || []}
        defaultSelectedKey={selectedDDD}
        onChange={(_, option) => {
          setSelectedDDD(String(option.key));
        }}
      />

      <Stack.Item grow styles={{ root: { position: "relative" } }}>
        <ComboBox
          componentRef={comboBoxRef}
          label="Número"
          placeholder={
            selectedDDD?.length !== 2
              ? "Selecione um DDD"
              : linesOptions?.length === 0
              ? "Nenhum número encontrado"
              : "Selecione um número"
          }
          allowFreeform
          autoComplete="on"
          useComboBoxAsMenuWidth
          openOnKeyboardFocus
          options={linesOptions || []}
          selectedKey={selectedNumberKey}
          onClick={handleComboBoxClick}
          onBlur={() => isFocused && setIsFocused(false)}
          onChange={handleComboBoxChange}
          required
          disabled={selectedDDD?.length !== 2 || linesOptions?.length === 0}
          autofill={{ maxLength: 9 }}
        />

        {isLoadingLine && (
          <Loader
            customMessage="Procurando linha..."
            spinnerSize={SpinnerSize.small}
          />
        )}
      </Stack.Item>
    </Stack>
  );
}

const mapStateToProps = state => ({
  user: {
    details: state.profile.user,
    selectedBU: state.profile.selectedBU
  }
});

export default connect(mapStateToProps)(SearchLines);
