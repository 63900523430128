import produce from "immer";

import {
  BusinessUnitsActionsTypes,
  BusinessUnitsReducerActions,
  BusinessUnitsState
} from "../types/BusinessUnits.reducer.types";

const INITIAL_STATE: BusinessUnitsState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: BusinessUnitsReducerActions
): BusinessUnitsState {
  return produce(state, draft => {
    switch (action.type) {
      case BusinessUnitsActionsTypes.CREATE_BU_REQUEST: {
        draft.message = undefined;
        break;
      }

      case BusinessUnitsActionsTypes.CREATE_BU_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case BusinessUnitsActionsTypes.CREATE_BU_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case BusinessUnitsActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case BusinessUnitsActionsTypes.EDIT_BU_REQUEST: {
        draft.message = undefined;
        break;
      }

      case BusinessUnitsActionsTypes.EDIT_BU_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case BusinessUnitsActionsTypes.EDIT_BU_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case BusinessUnitsActionsTypes.LIST_BU_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case BusinessUnitsActionsTypes.LIST_BU_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case BusinessUnitsActionsTypes.LIST_BU_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
