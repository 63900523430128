import {
  ChoiceGroup,
  DefaultButton,
  IconButton,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Text
} from "@fluentui/react";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import produce from "immer";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ContractProductsRenewalList from "./ContractProductsRenewalList";
import ContractProductsRenewalReview from "./ContractProductsRenewalReview";
import {
  ContractProductsRenewalModalContainer,
  ContractProductsRenewalModalContent,
  ContractProductsRenewalModalFooter,
  ContractProductsRenewalModalHeader
} from "./styles/ContractProductsRenewalModal.styles";

import { ContractsService } from "@/core/libs/api";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { SignatureType } from "@/modules/Contracts/enums";

async function requestOffers(
  businessUnitId: number,
  serviceId: number = null,
  serviceCategory: number = null
) {
  const abortController = new AbortController();
  const contracts = ContractsService();

  return contracts.listOffers({
    params: {
      query: {
        businessActionId: businessUnitId,
        service: serviceId,
        serviceCategory
      }
    },
    signal: abortController.signal
  });
}

async function requestRenewalItems(
  contractId: number,
  signatureType: "STANDARD" | "ELETRONIC",
  itemsToBeRenewed: SelectedItemToRenew[]
) {
  const abortController = new AbortController();
  const contracts = ContractsService();

  return contracts.renewalItems({
    id: `${contractId}`,
    params: {
      body: {
        signatureType,
        items: itemsToBeRenewed.map(item => ({
          id: item.itemId,
          offerPrice: item.offerPriceId
        }))
      }
    },
    signal: abortController.signal
  });
}

export interface SelectedItemToRenew {
  itemId: number;
  offerPriceId: number;
}

interface ContractProductsRenewalModalProps {
  contractId: number;
  service: any;
  serviceCategory: any;
  businessUnit: any;
  items: any[];
  isOpen: boolean;
  onDismiss: () => void;
}

function ContractProductsRenewalModal({
  contractId,
  serviceCategory,
  service,
  businessUnit,
  items,
  isOpen,
  onDismiss
}: ContractProductsRenewalModalProps) {
  const history = useHistory();

  const userProfile = useGetProfile();
  const { selectedBU } = useSelectedBu();

  const [carriers, setCarriers] = useState([]);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOffersLoading, setIsOffersLoading] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SelectedItemToRenew[]>([]);
  const [signatureType, setSignatureType] = useState<
    "STANDARD" | "ELETRONIC"
  >();

  const signatureTypes =
    selectedBU?.settings?.hideStandardSignature === "true"
      ? [{ key: SignatureType.ELETRONIC, text: "Digital" }]
      : selectedBU?.settings?.hideDigitalSignature === "true"
      ? [{ key: SignatureType.STANDARD, text: "Manual" }]
      : [
          { key: SignatureType.ELETRONIC, text: "Digital" },
          { key: SignatureType.STANDARD, text: "Manual" }
        ];

  useEffect(() => {
    if (isOpen) {
      setError(undefined);
      setIsOffersLoading(true);

      requestOffers(businessUnit.id, null, serviceCategory.id)
        .then(({ response, data, error }) => {
          if (response.ok) {
            // Existirá sempre apenas uma operação do tipo "RENEWAL", a qual
            // deve ser usada na migração.
            const operationRenewal = data.find(
              operation => operation.type === "RENEWAL"
            );

            setCarriers(operationRenewal.carriers);
          } else {
            throw new Error(error.message);
          }
        })
        .catch(error => setError(error.message))
        .finally(() => setIsOffersLoading(false));
    }
  }, [businessUnit.id, isOpen, service.id]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      onDismissed={() => {
        setError(undefined);
        setIsReviewing(false);
        setSelectedItems([]);
        setSignatureType(undefined);
      }}
    >
      <ContractProductsRenewalModalContainer>
        <ContractProductsRenewalModalHeader>
          <Text as="h2" variant="xLarge">
            Migrar planos de benefícios
          </Text>
          <IconButton onClick={onDismiss}>
            <ChromeCloseIcon />
          </IconButton>
        </ContractProductsRenewalModalHeader>

        <ContractProductsRenewalModalContent>
          {!isReviewing && (
            <>
              <Text as="p" block>
                Escolha os benefícios que deseja utilizar para inserir os
                os benefícios combos abaixo em um novo contrato. Não se esqueça de validar
                as condições comerciais em caso de troca de benefícios.
              </Text>

              <ChoiceGroup
                required
                label="Tipo de assinatura"
                selectedKey={signatureType}
                onChange={(_, option) => {
                  if (
                    option &&
                    (option.key === "STANDARD" || option.key === "ELETRONIC")
                  ) {
                    setSignatureType(option.key);
                  }
                }}
                options={signatureTypes}
              />

              <ContractProductsRenewalList
                serviceCategoryType={serviceCategory.type}
                items={items}
                selectedItems={selectedItems}
                onOfferChange={(itemId, offerPriceId) => {
                  setSelectedItems(
                    produce(draft => {
                      const itemIndex = draft.findIndex(
                        item => item.itemId === itemId
                      );

                      if (itemIndex !== -1) {
                        draft[itemIndex].offerPriceId = offerPriceId;
                      } else {
                        draft.push({ itemId, offerPriceId });
                      }
                    })
                  );
                }}
                onOfferReset={itemId => {
                  setSelectedItems(
                    produce(draft => {
                      const itemIndex = draft.findIndex(
                        item => item.itemId === itemId
                      );

                      if (itemIndex !== -1) {
                        draft.splice(itemIndex, 1);
                      }
                    })
                  );
                }}
                carriers={carriers}
                isLoading={isOffersLoading}
              />
            </>
          )}

          {isReviewing && (
            <ContractProductsRenewalReview
              serviceCategoryType={serviceCategory.type}
              signatureType={signatureType}
              items={items}
              selectedItems={selectedItems}
              carriers={carriers}
            />
          )}
        </ContractProductsRenewalModalContent>

        {error && (
          <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
        )}

        <ContractProductsRenewalModalFooter>
          {isLoading && <Spinner size={SpinnerSize.xSmall} />}

          {!isReviewing && (
            <>
              <PrimaryButton
                disabled={selectedItems.length === 0 || !signatureType}
                onClick={() => setIsReviewing(true)}
              >
                Revisar
              </PrimaryButton>
              <DefaultButton onClick={onDismiss}>Cancelar</DefaultButton>
            </>
          )}

          {isReviewing && (
            <>
              <PrimaryButton
                onClick={() => {
                  setIsLoading(true);
                  setError(undefined);

                  requestRenewalItems(contractId, signatureType, selectedItems)
                    .then(({ response, data, error }) => {
                      if (response.ok) {
                        history.push(`/contracts/${data.contract}/edit`);
                      } else {
                        throw new Error(error.message);
                      }
                    })
                    .catch(error => setError(error.message))
                    .finally(() => setIsLoading(false));
                }}
              >
                Confirmar
              </PrimaryButton>
              <DefaultButton onClick={() => setIsReviewing(false)}>
                Voltar
              </DefaultButton>
            </>
          )}
        </ContractProductsRenewalModalFooter>
      </ContractProductsRenewalModalContainer>
    </Modal>
  );
}

export default ContractProductsRenewalModal;
