import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createShippingsGroupsFailure,
  createShippingsGroupsSuccess,
  editShippingsGroupsFailure,
  editShippingsGroupsSuccess,
  listShippingsGroupsFailure,
  listShippingsGroupsRequest,
  listShippingsGroupsSuccess
} from "../actions/shippingsGroups";
import { ShippingsGroupsActionsTypes } from "../types/ShippingsGroups.reducer.types";

import { SagaCustomError } from "@/common/types";
import { ShippingsGroupsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createShippingsGroups({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ShippingsGroupsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createShippingsGroupsSuccess({
        message: `Grupo de tarifas criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listShippingsGroupsRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      createShippingsGroupsFailure({
        code: error?.statusCode,
        message:
          "Não foi possível criar o grupo de tarifas com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editShippingsGroups({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ShippingsGroupsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editShippingsGroupsSuccess({
        message: `Grupo de tarifas editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listShippingsGroupsRequest(undefined, { isAdmin: true }));
  } catch (error) {
    yield put(
      editShippingsGroupsFailure({
        code: error?.statusCode,
        message:
          "Não foi possível editar o grupo de tarifas com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listShippingsGroups({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = ShippingsGroupsService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listShippingsGroupsSuccess({ list: response.data }));
  } catch (error) {
    yield put(listShippingsGroupsFailure());
  }
}

export default all([
  takeLatest(ShippingsGroupsActionsTypes.CREATE_REQUEST, createShippingsGroups),
  takeLatest(ShippingsGroupsActionsTypes.EDIT_REQUEST, editShippingsGroups),
  takeLatest(ShippingsGroupsActionsTypes.LIST_REQUEST, listShippingsGroups)
]);
