export const formatPercentageValue = value => {
  const stringValue = typeof value === "number" ? value.toString() : value;
  if (!stringValue) return " ";

  const numericString = stringValue.replace(/\D/g, "");

  const intValue = parseInt(numericString);

  if (isNaN(intValue)) return " ";

  const formattedPercentage = (intValue / 100).toLocaleString("pt-BR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  return formattedPercentage;
};
