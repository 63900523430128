import produce from "immer";

import {
  DistributionCentersActionsTypes,
  DistributionCentersReducerActions,
  DistributionCentersState
} from "../types/DistributionCenters.reducer.types";

const INITIAL_STATE: DistributionCentersState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: DistributionCentersReducerActions
): DistributionCentersState {
  return produce(state, draft => {
    switch (action.type) {
      case DistributionCentersActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case DistributionCentersActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case DistributionCentersActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case DistributionCentersActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case DistributionCentersActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case DistributionCentersActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case DistributionCentersActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case DistributionCentersActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case DistributionCentersActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case DistributionCentersActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
