export enum IconSignature {
  transferor = "BusinessCenterLogo",
  transferee = "ReminderPerson",
  guarantor = "AccountManagement"
}

export enum SignatureName {
  transferor = "Cedente",
  transferee = "Cessionário",
  guarantor = "Avalista"
}
