import { Dropdown } from "@fluentui/react";

import { Card } from "@/components/Shared/Card";
import { StyledStep } from "@/modules/Contracts/styles";

interface AddressCardProps {
  addressesOptions: any;
  selectedAddress: any;
  setSelectedAddress: any;
  setFieldValue: any;
}

export function AddressCard({
  addressesOptions,
  selectedAddress,
  setSelectedAddress,
  setFieldValue
}: AddressCardProps) {
  return (
    <Card>
      <StyledStep>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-lg3">
              <h2 className="ms-fontSize-20">Endereço</h2>
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg4">
              <Dropdown
                id="address-select"
                placeholder="Selecione um endereço"
                options={addressesOptions}
                selectedKey={selectedAddress}
                onChange={(_event, item) => {
                  setFieldValue("accountAddress", item.key);
                  setSelectedAddress(item.key);
                }}
                onRenderOption={option => (
                  <button
                    onClick={() => {
                      setFieldValue("accountAddress", option.key);
                      setSelectedAddress(option.key);
                    }}
                    data-testid={`button-custom-${option.id}`}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    {option.text}
                  </button>
                )}
              />
            </div>
          </div>
        </div>
      </StyledStep>
    </Card>
  );
}
