export function ValidateCPF(cpf: string): boolean {
  cpf = cpf?.replace(/\D/g, "");
  if (cpf?.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

  let result = true;

  [9, 10].forEach(item => {
    let soma = 0;
    let rest: number;

    cpf
      .split(/(?=)/)
      .splice(0, item)
      .forEach(function (e, i) {
        soma += parseInt(e) * (item + 2 - (i + 1));
      });

    rest = soma % 11;
    rest = rest < 2 ? 0 : 11 - rest;

    if (rest !== Number(cpf.substring(item, item + 1))) result = false;
  });

  return result;
}
