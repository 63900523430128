import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class Plans extends Api {
  // TODO: Allow Create List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/plans${subPaths}` : `plans${subPaths}`);
  }

  duplicatePlan({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/duplicate`
    };
    if (params?.body) options.bodyObj = params.body;

    return makeRequest(options);
  }
}

export default Plans;
