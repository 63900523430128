import { Dropdown } from "@fluentui/react/lib/Dropdown";

import { Card } from "@/components/Shared/Card";
import { StyledStep } from "@/modules/Contracts/styles";

interface EmailCardProps {
  emailsOptions: any;
  selectedEmail: any;
  setSelectedEmail: any;
  setFieldValue: any;
}

export function EmailCard({
  emailsOptions,
  selectedEmail,
  setSelectedEmail,
  setFieldValue
}: EmailCardProps) {
  return (
    <Card>
      <StyledStep>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-lg3">
              <h2 className="ms-fontSize-20">Endereço de e-mail</h2>
              <p>Para envio de faturas e comunicados</p>
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg4">
              <Dropdown
                id="email-select"
                placeholder="Selecione um endereço de e-mail"
                options={emailsOptions}
                selectedKey={selectedEmail}
                onChange={(_event, item) => {
                  setFieldValue("accountContact", item.key);
                  setSelectedEmail(item.key);
                }}
                onRenderOption={option => (
                  <button
                    onClick={() => {
                      setFieldValue("accountContact", option.key);
                      setSelectedEmail(option.key);
                    }}
                    data-testid={`button-custom-${option.id}`}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    {option.text}
                  </button>
                )}
              />
            </div>
          </div>
        </div>
      </StyledStep>
    </Card>
  );
}
