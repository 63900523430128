import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class FreshChat extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/freshchat${subPaths}` : `freshchat${subPaths}`);
    this.upsert = this.upsert.bind(this);
  }

  upsert({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "PATCH",
      baseUrl: `${this.baseUrl}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }
}

export default FreshChat;
