import { SpaceContent } from "./styles";

type SpaceProps = {
  customMargin?: string;
  customAlignItems?: string;
  children: React.ReactNode;
};

export const Space: React.FC<SpaceProps> = ({
  children,
  customMargin,
  customAlignItems
}: SpaceProps) => {
  return (
    <SpaceContent
      customAlignItems={customAlignItems}
      customMargin={customMargin}
    >
      {children}
    </SpaceContent>
  );
};
