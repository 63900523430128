import * as Yup from "yup";

import FormBuilder from "@/core/libs/form-builder";
import { ProfileComponentProps } from "@/modules/User/types";

const schema = Yup.object().shape({
  id: Yup.string().required("Obrigatório"),
  fullName: Yup.string().required("Obrigatório"),
  email: Yup.string().email("E-mail inválido").required("Obrigatório")
});

export function ProfileComponent({
  profile,
  edit,
  message,
  dismissMessage
}: ProfileComponentProps): JSX.Element {
  return (
    <div style={{ width: "20rem" }}>
      <FormBuilder
        fields={[
          {
            label: "Nome",
            type: "text",
            name: "fullName",
            value: profile.fullName,
            disabled: true
          },
          {
            label: "E-mail",
            type: "email",
            name: "email",
            value: profile.email,
            disabled: true
          },
          {
            label: "Unidade de Negócio",
            type: "text",
            name: "businessUnit",
            value: `${profile.businessUnit.name} ${
              profile.businessUnit.parent?.name
                ? `(${profile.businessUnit.parent.name})`
                : ""
            }`,
            disabled: true
          }
        ]}
        onSubmit={edit}
        message={message}
        dismissMessage={dismissMessage}
        validationSchema={schema}
      />
    </div>
  );
}
