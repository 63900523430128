// abstração para indicar nome da próxima etapa na edição de contratos
export enum ContractStepsEnum {
  DADOS_BASE = 0,
  COOPERADO = 1,
  BENEFICIOS = 2,
  METODO_DE_PAGAMENTO = 3,
  CONFIRMACAO = 4
}

// passos do contrato em ordem
export const CONTRACT_STEPS = [
  ContractStepsEnum.DADOS_BASE,
  ContractStepsEnum.COOPERADO,
  ContractStepsEnum.BENEFICIOS,
  ContractStepsEnum.METODO_DE_PAGAMENTO,
  ContractStepsEnum.CONFIRMACAO
];
