import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class ShippingsGroups extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/shippings/groups${subPaths}`
        : `shippings/groups${subPaths}`
    );
  }

  listShippings({ id, params, signal }: IApi) {
    const options: any = {
      signal,
      method: "get",
      baseUrl: `${this.baseUrl}/${id}`
    };

    if (params.body) options.bodyObj = params.body;
    if (params.query) options.queryParams = params.query;

    return makeRequest(options);
  }
}

export default ShippingsGroups;
