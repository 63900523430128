import { Switch } from "react-router-dom";

import { useGetProfile } from "../libs/api/react-query";

import PrivateRoute from "./PrivateRoute";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import Accounts from "@/modules/Accounts";

function AccountsRoutes(): JSX.Element {
  const userProfileQuery = useGetProfile();
  const permissions = userProfileQuery.data?.data?.role.permissions || [];

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/accounts"
        permissionScope={permissions[PermissionsScope.POS]}
        module={PermissionsPosModule.ACCOUNTS}
        action={PermissionsAction.READ}
      >
        <Accounts
          canCreate={permissions[PermissionsScope.POS][
            PermissionsPosModule.ACCOUNTS
          ]?.some(
            (action: string) =>
              action === PermissionsAction.ALL ||
              action === PermissionsAction.CREATE
          )}
          canEdit={permissions[PermissionsScope.POS][
            PermissionsPosModule.ACCOUNTS
          ]?.some(
            (action: string) =>
              action === PermissionsAction.ALL ||
              action === PermissionsAction.UPDATE
          )}
        />
      </PrivateRoute>
    </Switch>
  );
}

export default AccountsRoutes;
