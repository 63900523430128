import { getTheme } from "@fluentui/react";
import styled from "styled-components";

const theme = getTheme();

type BadgeProps = {
  bgColor?: string;
  display?: string;
  fontWeight?: string;
  wordWrap?: string;
  whiteSpace?: string;
  flexGrow?: string;
  justifyContent?: string;
  fontColor?: string;
  border?: string;
};

export const StyledStep = styled.div`
  padding: 20px;
`;

export const ViewContractStyledLoader = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Badge = styled.span<BadgeProps>`
  font-size: 14px;
  padding: 0.5em;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  display: ${props => props.display ?? "inline-block"};
  flex-grow: ${props => props.flexGrow ?? ""};
  justify-content: ${props => props.justifyContent ?? ""};
  white-space: ${props => props.whiteSpace ?? "nowrap"};
  font-weight: ${props => props.fontWeight ?? "bold"};
  background: ${props => props.bgColor ?? "#6b6b6b"};
  color: ${props => props.fontColor ?? "#fff"};
  word-wrap: ${props => props.wordWrap ?? ""};
  border: ${props => props.border ?? ""};
`;

export const SubmittingDiv = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

type ClickableDivProps = {
  width?: string;
};

export const ClickableDiv = styled.div<ClickableDivProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: ${props => props.width ?? "100%"};
  padding: 10px 0;
`;

export const DocumentInfoSpan = styled.span`
  font-size: 12;
  color: ${theme.palette.neutralTertiary};
  font-weight: 600;
`;
