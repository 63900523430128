import produce from "immer";

import {
  ServicesCategoriesActionsTypes,
  ServicesCategoriesReducerActions,
  ServicesCategoriesState
} from "../types/ServicesCategories.reducer.types";

const INITIAL_STATE: ServicesCategoriesState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ServicesCategoriesReducerActions
): ServicesCategoriesState {
  return produce(state, draft => {
    switch (action.type) {
      case ServicesCategoriesActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ServicesCategoriesActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ServicesCategoriesActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ServicesCategoriesActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ServicesCategoriesActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ServicesCategoriesActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ServicesCategoriesActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ServicesCategoriesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case ServicesCategoriesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case ServicesCategoriesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
