import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { listFailure, listSuccess } from "../actions/tagOffers";
import { TagOffersActionsTypes } from "../types/TagOffers.reducer.types";

import { DashboardsService } from "@/core/libs/api";

const abortController = new AbortController();
const { signal } = abortController;
const service = DashboardsService("/tags");

export function* listTagOffers({ payload }: AnyAction) {
  const { filter } = payload;

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listSuccess({ list: response.data }));
  } catch (error) {
    yield put(listFailure());
  }
}

export default all([
  takeLatest(TagOffersActionsTypes.LIST_REQUEST, listTagOffers)
]);
