import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  listInventoriesOccurrencesFailure,
  listInventoriesOccurrencesSuccess
} from "../actions/inventoriesOccurrences";
import { InventoriesOccurrencesActionsTypes } from "../types/InventoriesOccurrences.reducer.types";

import { InventoriesOccurrencesService } from "@/core/libs/api";

const abortController = new AbortController();
const { signal } = abortController;

export function* listInventoriesOccurrences({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = InventoriesOccurrencesService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listInventoriesOccurrencesSuccess({ list: response.data }));
  } catch (error) {
    yield put(listInventoriesOccurrencesFailure());
  }
}

export default all([
  takeLatest(
    InventoriesOccurrencesActionsTypes.LIST_REQUEST,
    listInventoriesOccurrences
  )
]);
