import {
  CommandBarButton,
  ContextualMenuItem,
  ICommandBarItemProps,
  IComponentAsProps,
  IContextualMenuItemProps
} from "@fluentui/react";
import { useHistory } from "react-router-dom";

import { useSelectedBu } from "@/hooks/useSelectedBu";
export function CustomButton(
  props: IComponentAsProps<ICommandBarItemProps>
): JSX.Element {
  const history = useHistory();

  if (props.to) {
    const WrappedButton = () => (
      <CommandBarButton
        {...(props as any)}
        onClick={() => {
          // eslint-disable-next-line react/prop-types
          if (props.onClick) {
            // eslint-disable-next-line react/prop-types
            props.onClick();
          }
          // eslint-disable-next-line react/prop-types
          history.push(props.to);
        }}
      />
    );
    return <WrappedButton />;
  } else {
    return <CommandBarButton {...(props as any)} />;
  }
}

export function MenuButton(
  props: IComponentAsProps<ICommandBarItemProps>
): JSX.Element {
  return (
    <CommandBarButton
      {...(props as any)}
      iconProps={{
        iconName: props.isMenuOpen ? "ChromeClose" : "GlobalNavButton"
      }}
      className="ms-hiddenLgUp"
    />
  );
}

export function CustomMenuItem(props: IContextualMenuItemProps): JSX.Element {
  const history = useHistory();

  if (props.item.to) {
    const item = props.item;
    item.onClick = () => {
      if (item?.data?.currentBU.length > 0) {
        item.data.selectBU(...item.data.currentBU);
      }

      history.push(props.item.to);
    };
    const WrappedMenuItem = () => <ContextualMenuItem {...props} item={item} />;
    return <WrappedMenuItem />;
  } else {
    return <ContextualMenuItem {...props} item={props.item} />;
  }
}

export const CommandBarButtonCustom = (props: {
  item: any;
  bu: any;
  dismissMenu: (ev?: any, dismissAll?: boolean) => void;
}) => {
  const item = props?.item;
  const bu = props.bu;
  const { handleSelectBu } = useSelectedBu();

  return (
    <CommandBarButton
      id={item?.id}
      text={item?.text}
      split={true}
      menuProps={
        item?.subMenuProps?.items && {
          items: item?.subMenuProps?.items,
          onItemClick() {
            handleSelectBu(bu.id);
            props.dismissMenu();
          },
          styles: { list: {} }
        }
      }
      splitButtonMenuProps={{
        id: `split-button-selector-${bu?.id}`
      }}
      onClick={() => {
        handleSelectBu(bu.id);
        props.dismissMenu();
      }}
      styles={{
        flexContainer: {
          width: "100%",
          height: "32px",
          padding: "2px",
          justifyContent: "space-between"
        },
        root: { width: "100%" },
        textContainer: {
          minWidth: "320px",
          maxWidth: "450px",
          fontSize: "15px ",
          textAlign: "left"
        }
      }}
    />
  );
};
