import { ListOfDistributionCenters } from "./DistributionCenters.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum DistributionCentersActionsTypes {
  CREATE_REQUEST = "distribution-centers/CREATE_REQUEST",
  CREATE_FAIL = "distribution-centers/CREATE_FAIL",
  CREATE_SUCCESS = "distribution-centers/CREATE_SUCCESS",
  DISMISS_MESSAGE = "distribution-centers/DISMISS_MESSAGE",
  EDIT_REQUEST = "distribution-centers/EDIT_REQUEST",
  EDIT_FAIL = "distribution-centers/EDIT_FAIL",
  EDIT_SUCCESS = "distribution-centers/EDIT_SUCCESS",
  LIST_REQUEST = "distribution-centers/LIST_REQUEST",
  LIST_FAIL = "distribution-centers/LIST_FAIL",
  LIST_SUCCESS = "distribution-centers/LIST_SUCCESS"
}

export interface DistributionCentersState {
  isLoadingList: boolean;
  list?: ListOfDistributionCenters;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: DistributionCentersActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: DistributionCentersActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: DistributionCentersActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: DistributionCentersActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: DistributionCentersActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: DistributionCentersActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: DistributionCentersActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: DistributionCentersActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: DistributionCentersActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: DistributionCentersActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfDistributionCenters;
  };
}

export type DistributionCentersReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
