import {
  Icon,
  MessageBarType,
  mergeStyleSets,
  mergeStyles
} from "@fluentui/react";

import { Container, Content } from "./styles";

import { ApiAlertsResponseProps } from "@/common/types/ApiAlertsTypes";
import { TeachingBubble } from "@/components/TeachingBubble";
import { convertTimestampToDateAndTime } from "@/utils/ConvertTimestampToDateAndTime";

export const ResponseApiAlertsContent = ({
  handleAlertBubbleVisibility,
  openApiAlertsResponsesButtonId,
  responses
}: {
  handleAlertBubbleVisibility: () => void;
  openApiAlertsResponsesButtonId: string;
  responses: ApiAlertsResponseProps[];
}) => {
  const getIconByLevel = (level: MessageBarType) => {
    switch (level) {
      case MessageBarType.error:
        return "ErrorBadge";

      case MessageBarType.success:
        return "Completed";

      case MessageBarType.warning:
        return "Error";

      default:
        return "Error";
    }
  };
  const iconClass = mergeStyles({
    fontSize: "1rem",
    height: 20,
    width: 20,
    margin: "15px 0px",
    fontWeight: 600
  });

  const iconStyle = mergeStyleSets({
    blue: [{ color: "#395ca3" }, iconClass]
  });

  const orderedResponses = responses.sort((a, b) => b.createdAt - a.createdAt);

  return (
    <TeachingBubble
      customStyles={{ maxHeight: "400px" }}
      onClose={handleAlertBubbleVisibility}
      positionAdjustment={{ left: -265, top: 50 }}
      targetButtonId={openApiAlertsResponsesButtonId}
    >
      <Container>
        <h6>Respostas do Servidor</h6>
        {responses.length > 0 ? (
          orderedResponses.map((response, index) => {
            const icon = getIconByLevel(response.level);
            const { date, time } = convertTimestampToDateAndTime(
              response.createdAt
            );

            return (
              <Content key={`api-alerts-${index}`}>
                <Icon iconName={icon} className={iconStyle.blue} />
                <div className="api-alerts-message">
                  <div className="secondary-content">
                    <small>{response?.method}</small>
                    <small>{response.path}</small>
                  </div>
                  <div className="response">
                    <span>
                      {response?.code ? `${response.code}:${" "}` : ""}
                    </span>
                    {response.message}
                  </div>
                  <div className="secondary-content">
                    <small>{date}</small>
                    <small>{time}</small>
                  </div>
                </div>
              </Content>
            );
          })
        ) : (
          <p>Não há nada aqui ainda...</p>
        )}
      </Container>
    </TeachingBubble>
  );
};
