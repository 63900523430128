import { PlanOfferItem } from "./PlansOffers.types";
import { ListOfTagOffers } from "./TagOffers.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum TagOffersActionsTypes {
  DISMISS_MESSAGE = "tag-offers/DISMISS_MESSAGE",
  LIST_REQUEST = "tag-offers/LIST_REQUEST",
  LIST_FAIL = "tag-offers/LIST_FAIL",
  LIST_SUCCESS = "tag-offers/LIST_SUCCESS"
}

export interface TagOffersState {
  isLoadingList: boolean;
  list?: PlanOfferItem;
  message?: IMessageBar;
}

export interface DismissMessage {
  type: TagOffersActionsTypes.DISMISS_MESSAGE;
}

export interface ListRequest {
  type: TagOffersActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: TagOffersActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: TagOffersActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfTagOffers;
  };
}

export type TagOffersReducerActions =
  | DismissMessage
  | ListRequest
  | ListError
  | ListSuccess;
