import { ListOfReportCategories } from "./ReportCategories.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum ReportCategoriesActionsTypes {
  DISMISS_MESSAGE = "reportCategories/DISMISS_MESSAGE",
  LIST_REQUEST = "reportCategories/LIST_REQUEST",
  LIST_FAIL = "reportCategories/LIST_FAIL",
  LIST_SUCCESS = "reportCategories/LIST_SUCCESS"
}

export interface ReportCategoriesState {
  isLoadingList: boolean;
  list: ListOfReportCategories;
  message?: IMessageBar;
}

export interface DismissMessage {
  type: ReportCategoriesActionsTypes.DISMISS_MESSAGE;
}

export interface ListRequest {
  type: ReportCategoriesActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: ReportCategoriesActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: ReportCategoriesActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfReportCategories;
  };
}

export type ReportCategoriesReducerActions =
  | DismissMessage
  | ListRequest
  | ListError
  | ListSuccess;
