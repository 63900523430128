import { ModelsActionsTypes } from "../types/ContractModels.reducer.types";

export function createModelsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ModelsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createModelsSuccess(message) {
  return {
    type: ModelsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createModelsFailure(message) {
  return {
    type: ModelsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editModelsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ModelsActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editModelsSuccess(message) {
  return {
    type: ModelsActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editModelsFailure(message) {
  return {
    type: ModelsActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listModelsRequest(filter, { isAdmin = false }) {
  return {
    type: ModelsActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listModelsSuccess({ list }) {
  return {
    type: ModelsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listModelsFailure() {
  return {
    type: ModelsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ModelsActionsTypes.DISMISS_MESSAGE
  };
}
