import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Field } from "formik";
import { useEffect, useState } from "react";

interface PropsSelectService {
  businessUnit: number;
  serviceCategory: number;
  servicesList: { id: number; name: string }[];
  error: any;
  touched: any;
  setFieldValue: (fieldName, value) => void;
  selected: any;
}

export function SelectService(props: PropsSelectService): JSX.Element {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState<string | number>("");

  useEffect(() => {
    if (!props.selected) {
      setServices([]);

      if (props.serviceCategory) {
        const services: IDropdownOption[] = [];

        props.servicesList.forEach(service => {
          services.push({
            key: service.id,
            text: service.name,
            id: `option-service-${service.id}`
          });
        });

        setServices(services);
      }
    }
    //eslint-disable-next-line
  }, [props.serviceCategory, props.businessUnit, props.servicesList]);

  useEffect(() => {
    setSelectedService("");
  }, [props.serviceCategory]);

  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-lg3">
          <h2 className="ms-fontSize-20">Benefício prestado</h2>
          <p>Descrição</p>
        </div>
        <div className="ms-Grid-col ms-sm12 ms-lg4">
          {props.selected ? (
            <Dropdown
              id="productCategories-select"
              label="Selecione um benefício prestado"
              placeholder={
                !props.serviceCategory
                  ? "Selecione uma categoria"
                  : "Carregando..."
              }
              options={[{ key: props.selected.id, text: props.selected.name }]}
              disabled={true}
              defaultSelectedKey={props.selected.id}
            />
          ) : (
            <Field
              name="service"
              key="service"
              id="service"
              type="select"
              as={Dropdown}
              onChange={(evt, item) => {
                props.setFieldValue("service", item.key);
                setSelectedService(item.key);
              }}
              placeholder={
                services.length > 0
                  ? "Selecione um benefício prestado"
                  : props.serviceCategory
                  ? "Nenhum benefício prestado disponível, selecione outra categoria"
                  : "Por favor selecione uma categoria."
              }
              label="Selecione um benefício prestado"
              errorMessage={props.error && props.touched ? props.error : null}
              options={services}
              disabled={services.length < 1}
              selectedKey={selectedService}
              styles={{ dropdown: { width: "100%" } }}
              required={true}
              onRenderOption={option => (
                <button
                  onClick={() => {
                    props.setFieldValue("service", option.key);
                    setSelectedService(option.key);
                  }}
                  data-testid={`button-custom-${option.id}`}
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  {option.text}
                </button>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}
