import { INavLink } from "@fluentui/react";
import { useEffect, useState } from "react";

import { SidebarProps } from "../types/Sidebar.types";

import { SidebarMenuExpandable } from "./SidebarMenuExpandable";
import { SidebarFooter } from "./SidebarMenuFooter";
import { SidebarHeader } from "./SidebarMenuHeader";
import { SidebarMain } from "./SidebarMenuMain";
import { SidebarMenuPanel } from "./SidebarMenuPanel";

import {
  Permission,
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import { useSelectedBu } from "@/hooks/useSelectedBu";

export function Sidebar({ theme }: SidebarProps): JSX.Element {
  const [linksEnabled, setLinksEnabled] = useState<INavLink[] | []>([]);
  const [footerLink, setFooterLink] = useState<INavLink[] | []>([]);

  const userProfileQuery = useGetProfile();
  const { selectedBU } = useSelectedBu();
  const permissions: Permission[] =
    userProfileQuery.data?.data?.role.permissions;
  const settings = selectedBU?.settings;

  useEffect(() => {
    const mainLinks = [];
    const footerLink = [];

    permissions[PermissionsScope.POS] &&
      mainLinks.push({
        name: "Benefícios",
        url: "/benefits",
        key: "/benefits",
        iconName: "Money"
      });

    permissions[PermissionsScope.POS] &&
      settings?.newsLink &&
      mainLinks.push({
        name: "Notícias",
        url: "/news",
        key: "/news",
        iconName: "News"
      });

    permissions[PermissionsScope.POS] &&
      settings?.offersLink &&
      mainLinks.push({
        name: "Material de Negócios",
        url: "/business-material",
        key: "/business-material",
        iconName: "AccountActivity"
      });

    permissions[PermissionsScope.POS] &&
      settings?.promotionsLink &&
      mainLinks.push({
        name: "Resultado das Campanhas",
        url: "/promotions",
        key: "/promotions",
        iconName: "Savings"
      });

    settings?.powerbi &&
      mainLinks.push({
        name: "Painel de Resultados",
        url: "/results-panel",
        key: "/results-panel",
        iconName: "PowerBILogo"
      });

    permissions[PermissionsScope.POS][PermissionsPosModule.ACCOUNTS]?.some(
      (action: string) =>
        action === PermissionsAction.ALL || action === PermissionsAction.LIST
    ) &&
      mainLinks.push({
        name: "Registro dos Cooperados",
        url: "/accounts",
        key: "/accounts",
        iconName: "People"
      });

    permissions[PermissionsScope.POS][PermissionsPosModule.CONTRACTS]?.some(
      (action: string) =>
        action === PermissionsAction.ALL || action === PermissionsAction.LIST
    ) &&
      mainLinks.push({
        name: "Contratos",
        url: "/contracts",
        key: "/contracts",
        iconName: "DocumentSet"
      });

    permissions[PermissionsScope.POS][PermissionsPosModule.MEMBERS]?.some(
      (action: string) =>
        action === PermissionsAction.ALL || action === PermissionsAction.LIST
    ) &&
      mainLinks.push({
        name: "Membros Coopercompany",
        url: "/members",
        key: "/members",
        iconName: "AddGroup"
      });

    permissions[PermissionsScope.POS][PermissionsPosModule.TERMS]?.some(
      (action: string) =>
        action === PermissionsAction.ALL || action === PermissionsAction.LIST
    ) &&
      mainLinks.push({
        name: "Termos",
        url: "/terms",
        key: "/terms",
        iconName: "Documentation"
      });

    permissions[PermissionsScope.ADMIN] &&
      footerLink.push({
        name: "Painel Administrativo",
        url: "/settings",
        key: "/settings",
        iconName: "Settings"
      });

    mainLinks.length > 0 && setLinksEnabled(mainLinks);
    footerLink.length > 0 && setFooterLink(footerLink);
  }, [permissions, settings]);

  return (
    <>
      <SidebarMenuPanel
        HeaderContent={SidebarHeader}
        MainContent={SidebarMain}
        mainLinks={linksEnabled}
        FooterContent={SidebarFooter}
        footerLinks={footerLink}
        theme={theme}
      />

      <SidebarMenuExpandable
        theme={theme}
        FooterContent={SidebarFooter}
        MainContent={SidebarMain}
        footerLinks={footerLink}
        mainLinks={linksEnabled}
        maxPathNameRange={2}
      />
    </>
  );
}
