import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 500;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    min-width: 35%;
    min-height: 20%;
    max-width: 50%;
    width: fit-content;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
`;
