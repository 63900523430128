import { IOptOut } from "../interfaces";

export function removeOptOuts(
  businessUnitIds: number[],
  optOutList: IOptOut[],
  notificationChannelId: number,
  notificationEventId: number
) {
  return businessUnitIds.map(businessUnitId => {
    const optOut = optOutList.find(
      optOut =>
        optOut.businessUnitId === businessUnitId &&
        optOut.notificationEventChannel.notificationChannelId ===
          notificationChannelId
    );

    return {
      id: optOut?.id,
      businessUnitId,
      notificationEventId,
      notificationChannelId,
      status: false
    };
  });
}
