import produce from "immer";

import {
  CarriersActionsTypes,
  CarriersReducerActions,
  CarriersState
} from "../types/Carriers.reducer.types";

const INITIAL_STATE: CarriersState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: CarriersReducerActions
): CarriersState {
  return produce(state, draft => {
    switch (action.type) {
      case CarriersActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case CarriersActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case CarriersActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case CarriersActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case CarriersActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case CarriersActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case CarriersActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case CarriersActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case CarriersActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case CarriersActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
