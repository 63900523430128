export function currencyValueToLocaleString(value: number | string): string {
  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return "Valor inválido";
  }

  return numberValue.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}
