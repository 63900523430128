import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  listLinesOccurrencesFailure,
  listLinesOccurrencesSuccess
} from "../actions/linesOccurrences";
import { LinesOccurrencesActionsTypes } from "../types/LinesOccurrences.reducer.types";

import { LinesOccurrencesService } from "@/core/libs/api";

const abortController = new AbortController();
const { signal } = abortController;

export function* listLinesOccurrences({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;

  const service = LinesOccurrencesService("", isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.listWithPostMethod, {
      params: { body: { ...params.query } },
      signal
    });

    if (response.error) {
      throw new Error();
    }

    yield put(listLinesOccurrencesSuccess({ list: response.data }));
  } catch (error) {
    yield put(listLinesOccurrencesFailure());
  }
}

export default all([
  takeLatest(LinesOccurrencesActionsTypes.LIST_REQUEST, listLinesOccurrences)
]);
