import {
  CommandButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  getTheme,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack
} from "@fluentui/react";
import { useState } from "react";
import { useAsyncCallback } from "react-async-hook";

import { ViewProgress } from "../../ViewProgress";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import { ContractsService } from "@/core/libs/api";
import { useGetProfile } from "@/core/libs/api/react-query/index";
import {
  ContractBadgeColors,
  TranslatedContractCurrentStep
} from "@/modules/Contracts/enums";
import { Badge } from "@/modules/Contracts/styles";

const contractsService = ContractsService();
const theme = getTheme();

interface ItemReplacementProcessProps {
  item: any;
  size?: "normal" | "small";
  onSuccess: () => void;
}

function ItemReplacementProcess({
  item,
  size = "normal",
  onSuccess
}: ItemReplacementProcessProps) {
  const [isActionDialogHidden, setIsActionDialogHidden] = useState(true);
  const [actionData, setActionData] = useState<{
    interactionName: string;
    interactionAction: string;
  }>(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const userProfile = useGetProfile();
  const permissions = userProfile.data?.data?.role?.permissions;

  const action = useAsyncCallback(
    async () => {
      setErrorMessage(undefined);

      const abortController = new AbortController();
      const { signal } = abortController;

      return contractsService.statusItem({
        params: {
          id: item.id,
          query: { interaction: actionData.interactionAction }
        },
        signal
      });
    },
    {
      onSuccess({ response, error }) {
        if (response.ok) {
          setIsActionDialogHidden(true);
          setSuccessMessage("Ação aplicada na substituição");
          onSuccess();
        } else {
          setErrorMessage(error.message);
        }
      },
      onError(error) {
        setErrorMessage(error.message);
      }
    }
  );

  const status = [];
  const nextStep = item.nextStep;

  const doneAction = item.approvalFlow?.steps?.find(
    step => step.internalName === "DONE"
  );
  const deliveredAction = item.approvalFlow?.steps?.find(
    step => step.internalName === "SERVICE_DELIVERED"
  );
  const rejectedAction = item.approvalFlow?.steps?.find(
    step => step.internalName === "REJECTED"
  );
  const canceledAction = item.approvalFlow?.steps?.find(
    step => step.internalName === "CANCELED"
  );

  if (
    nextStep &&
    nextStep.interactionType === "ACTION_STATUS" &&
    permissions[PermissionsScope.POS]?.[PermissionsPosModule.CONTRACTS]?.some(
      contractAction =>
        contractAction === PermissionsAction.ALL ||
        contractAction === nextStep.interactionAction
    )
  ) {
    status.push({
      key: nextStep.id,
      text: nextStep.interactionName,
      onClick: () => {
        setActionData({
          interactionAction: nextStep.interactionAction,
          interactionName: nextStep.interactionName
        });
        setIsActionDialogHidden(false);
      }
    });
  }

  if (
    nextStep &&
    nextStep.order < deliveredAction?.order &&
    canceledAction &&
    permissions[PermissionsScope.POS]?.[PermissionsPosModule.CONTRACTS]?.some(
      contractAction =>
        contractAction === PermissionsAction.ALL ||
        contractAction === canceledAction?.interactionAction
    )
  ) {
    status.push({
      key: canceledAction.id,
      text: canceledAction.interactionName,
      onClick: () => {
        setActionData({
          interactionAction: canceledAction.interactionAction,
          interactionName: canceledAction.interactionName
        });
        setIsActionDialogHidden(false);
      }
    });
  }

  if (
    permissions &&
    nextStep &&
    nextStep.order < doneAction?.order &&
    rejectedAction &&
    permissions[PermissionsScope.POS]?.[PermissionsPosModule.CONTRACTS]?.some(
      contractAction =>
        contractAction === PermissionsAction.ALL ||
        contractAction === rejectedAction?.interactionAction
    )
  ) {
    status.push({
      key: rejectedAction.id,
      text: rejectedAction.interactionName,
      onClick: () => {
        setActionData({
          interactionAction: rejectedAction.interactionAction,
          interactionName: rejectedAction.interactionName
        });
        setIsActionDialogHidden(false);
      }
    });
  }

  return (
    <>
      <Stack>
        <Stack
          style={{
            padding: 10,
            backgroundColor: theme.palette.neutralLighterAlt,
            color: theme.palette.neutralDark
          }}
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <span className="ms-fontWeight-semibold ms-fontSize-14">
            Última substituição
          </span>
          {status.length > 0 && (
            <CommandButton
              iconProps={{ iconName: "Sync" }}
              text="Ações"
              menuProps={{ items: status }}
            />
          )}
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 30, padding: 15 }}
        >
          <Stack.Item style={{ width: 300 - 27 }}>
            <Badge
              bgColor={ContractBadgeColors[item.currentStep?.internalName]}
              display="block"
            >
              {item.nextStep?.friendlyName ??
                TranslatedContractCurrentStep[item.currentStep?.internalName]}
            </Badge>
          </Stack.Item>
          <Stack.Item grow={5}>
            <ViewProgress
              currentStep={item.currentStep.order}
              steps={item.approvalFlow.steps}
              size={size}
            />
          </Stack.Item>
        </Stack>
      </Stack>

      {successMessage && (
        <MessageBar
          messageBarType={MessageBarType.success}
          onDismiss={() => setSuccessMessage(null)}
        >
          {successMessage}
        </MessageBar>
      )}

      <Dialog
        hidden={isActionDialogHidden}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Deseja Realizar a Ação?",
          closeButtonAriaLabel: "Cancelar",
          subText: "Confirme a ação a ser realizada antes de prosseguir."
        }}
        modalProps={{
          onDismissed: () => setActionData(undefined)
        }}
      >
        <Stack tokens={{ childrenGap: 15 }}>
          <span>
            <span className="ms-fontWeight-semibold">Ação:</span>{" "}
            {actionData?.interactionName}
          </span>

          {errorMessage && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline>
              {errorMessage}
            </MessageBar>
          )}
        </Stack>

        <DialogFooter>
          {action.loading && (
            <Spinner
              size={SpinnerSize.xSmall}
              styles={{ root: { display: "inline-block" } }}
            />
          )}
          <PrimaryButton
            onClick={() => action.execute()}
            disabled={action.loading}
            text="Confirmar"
          />
          <DefaultButton
            onClick={() => setIsActionDialogHidden(true)}
            disabled={action.loading}
            text="Cancelar"
          />
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default ItemReplacementProcess;
