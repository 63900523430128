import { Text } from "@fluentui/react";
import { memo } from "react";

import { ContractProductsRenewalOfferOptionContainer } from "./styles/ContractProductsRenewalOfferOption.styles";

function formatCurrency(value: string | number) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(+value);
}

interface ContractProductsRenewalOfferOptionProps {
  title: string;
  discount?: any;
}

function ContractProductsRenewalListOfferOption({
  title,
  discount
}: ContractProductsRenewalOfferOptionProps) {
  const discountValue = formatCurrency(discount.value);
  const numberOfMonths = discount.numberOfMonths;
  const discountBegins = discount.beginsAt;

  return (
    <ContractProductsRenewalOfferOptionContainer>
      {title}
      {discount && discount.value !== 0 && (
        <Text variant="small" block>
          {`com desconto de ${discountValue} por ${numberOfMonths} meses a partir da ${discountBegins}.ª fatura.`}
        </Text>
      )}
    </ContractProductsRenewalOfferOptionContainer>
  );
}

export default memo(ContractProductsRenewalListOfferOption);
