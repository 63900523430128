import { Stack } from "@fluentui/react";
import styled, { keyframes } from "styled-components";

const FadeInAnimation = keyframes`
  0% {
    opacity: 0;
   }

  100% {
    opacity: 1;
   }
`;

export const FadeInStack = styled(Stack)`
  animation-name: ${FadeInAnimation};
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.8s;
`;
