import { IContextualMenuItem, Stack } from "@fluentui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { dismissMessage } from "./actions/accounts";
import { buildColumns } from "./fields/Accounts.columns";
import { accountFilters } from "./fields/Accounts.filters";
import { CreateAccount } from "./pages/create/CreateAccount";
import { EditAccounts } from "./pages/edit/EditAccount";
import { AccountsProps, MaritalStatusTranslate } from "./types/Accounts.types";

import { FilterParamsType } from "@/common/types/ListFromApi.types";
import { Card } from "@/components/Shared/Card";
import {
  CustomDrawer,
  DrawerActions,
  DrawerActionsType,
  DrawerModules,
  DrawerStateType
} from "@/components/Shared/CustomDrawer";
import { AccountsService, MaritalStatusService } from "@/core/libs/api";
import { useGetAccounts } from "@/core/libs/api/react-query/hook/use-get-accounts";
import { ListData } from "@/core/libs/list-data";
import { RootState } from "@/core/store/rootReducer";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { useURLFilter } from "@/hooks/useUrlFIlter";

function Accounts({ canCreate, canEdit }: AccountsProps): JSX.Element {
  const drawerContainerRef = useRef<HTMLDivElement>(null);
  const [drawerState, setDrawerState] = useState<DrawerStateType>({
    action: null,
    isOpen: false
  });

  const [selectedItemId, setSelectedItemId] = useState<string>(null);
  const [accountsItems, setAccountsItems] = useState<IContextualMenuItem[]>([]);
  const [maritalStatusOptions, setMaritalStatusOptions] =
    useState<Array<{ key: number; text: string }>>(null);

  const {
    accounts: { isLoadingList: isLoadingList, message }
  } = useSelector((store: RootState) => store);

  const { changeUrlParams, urlParams } = useURLFilter();
  const { accountsList, invalidateQuery } = useGetAccounts({
    params: urlParams.params
  });
  const { selectedBU } = useSelectedBu();

  const handleMaritalStatusRequest = async () => {
    const { signal } = new AbortController();
    const service = MaritalStatusService("", true);

    const result = await service.list({ params: {}, signal });
    setMaritalStatusOptions(
      result.data?.items?.map(item => ({
        key: item.id,
        text: MaritalStatusTranslate[item.name]
      }))
    );
  };

  const handleAccountRequest = useCallback(
    (filterParams?: FilterParamsType) => {
      changeUrlParams(filterParams);
    },
    [changeUrlParams]
  );

  async function createAccounts(values, _, closeDrawer) {
    const { signal } = new AbortController();
    const service = AccountsService();

    const result = await service.create({
      params: { body: values, query: { businessActionId: selectedBU?.id } },
      signal
    });
    if (!result?.response?.ok) {
      return result;
    }

    invalidateQuery();
    closeDrawer();
    return result;
  }

  async function editAccount(
    values: { body: any; query: any },
    _,
    closeDrawer
  ) {
    const { signal } = new AbortController();

    const service = AccountsService("");

    const result = await service.edit({
      params: {
        id: selectedItemId,
        ...values
      },
      signal
    });

    if (!result?.response?.ok) {
      return result;
    }

    invalidateQuery();
    closeDrawer();

    return result;
  }

  function openDrawer(action: DrawerActionsType) {
    setDrawerState({ action: action, isOpen: true });
  }

  function closeDrawer() {
    setDrawerState({ action: null, isOpen: false });
  }

  function handleDismissMessage() {
    dismissMessage();
  }

  useEffect(() => {
    const newItems: IContextualMenuItem[] = [];

    if (canEdit) {
      newItems.push({
        key: "editItem",
        text: "Editar",
        onClick: () => {
          openDrawer(DrawerActions.EDIT);
        }
      });
    }

    setAccountsItems(newItems);
  }, [canEdit]);

  useEffect(() => {
    handleMaritalStatusRequest();
  }, []);

  return (
    <Stack styles={{ root: { width: "100%" } }}>
      <Helmet>
        <title>Cooperados - Libertas</title>
      </Helmet>

      <Stack tokens={{ childrenGap: 15 }}>
        <h2 className="ms-fontSize-24">Cooperados</h2>
        <Card>
          <ListData
            headerAction={
              canCreate
                ? () =>
                    setDrawerState({
                      action: DrawerActions.CREATE,
                      isOpen: true
                    })
                : undefined
            }
            headerText="Cooperados"
            isLoadingList={isLoadingList}
            items={accountsList || []}
            columns={buildColumns({
              hasActions: canCreate,
              openDrawer,
              setSelectedItemId
            })}
            menuItems={accountsItems}
            setSelectedItem={setSelectedItemId}
            _paginate={handleAccountRequest}
            filters={accountFilters}
            hasFilters
            isDefaultFilterVisible
          />
        </Card>

        <CustomDrawer
          isOpen={drawerState.isOpen}
          action={drawerState.action}
          module={DrawerModules.ACCOUNT}
          size="medium"
          onCloseDrawer={closeDrawer}
          drawerContainerRef={drawerContainerRef}
        >
          {(drawerState.action === DrawerActions.CREATE && canCreate && (
            <CreateAccount
              create={createAccounts}
              message={message}
              dismissMessage={handleDismissMessage}
              closeDrawer={closeDrawer}
              drawerContainerRef={drawerContainerRef}
              maritalStatusOptions={maritalStatusOptions}
            />
          )) ||
            (drawerState.action === DrawerActions.EDIT && canEdit && (
              <EditAccounts
                edit={editAccount}
                message={message}
                dismissMessage={handleDismissMessage}
                closeDrawer={closeDrawer}
                drawerContainerRef={drawerContainerRef}
                selectedItem={selectedItemId}
                maritalStatusOptions={maritalStatusOptions}
              />
            ))}
        </CustomDrawer>
      </Stack>
    </Stack>
  );
}

export default Accounts;
