import produce from "immer";

import {
  SidebarMenuActionsTypes,
  SidebarMenuReducerActions,
  SidebarMenuState
} from "../types/SidebarMenu.actions.types";

const INITIAL_STATE: SidebarMenuState = {
  isMenuOpen: false,
  isMenuExpanded: true
};

export default function reducer(
  state = INITIAL_STATE,
  action: SidebarMenuReducerActions
): SidebarMenuState {
  return produce(state, draft => {
    switch (action.type) {
      case SidebarMenuActionsTypes.OPEN_SIDEBAR_MENU: {
        draft.isMenuOpen = true;
        break;
      }

      case SidebarMenuActionsTypes.CLOSE_SIDEBAR_MENU: {
        draft.isMenuOpen = false;
        break;
      }

      case SidebarMenuActionsTypes.EXPAND_SIDEBAR_MENU: {
        draft.isMenuExpanded = true;
        break;
      }

      case SidebarMenuActionsTypes.RETRACT_SIDEBAR_MENU: {
        draft.isMenuExpanded = false;
        break;
      }
      default:
    }
  });
}
