import { addYears } from "@fluentui/react";

import {
  AccountContactType,
  AccountDocumentType
} from "../types/Accounts.types";

import { formatAccountFields } from "./utils/formatAccountFields";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import { PermissionsFormat } from "@/common/types/PermissionsFormat.types";
import { checkSicoobAccount } from "@/utils/CheckSicoobAccount";

type FieldsProps = {
  initialValues?: any;
  hasDefaultValue?: boolean;
  isEditField?: boolean;
  bankNumberValue?: string;
  agencyNumberValue?: string;
  bankAccountRequired?: boolean;
  requireWhatsappForLegalRepresentative?: boolean;
  salesTeamList?: Array<{ key: number; text: string }>;
  permissions: PermissionsFormat;
  whatsappRequired?: boolean;
};

const today = new Date();
const minDate = addYears(today, -120);
const maxDate = addYears(today, -16);

const buildLegalAccountCheckingAccount = ({ values, isRequired, object }) => {
  if (values?.length > 0) {
    return values;
  } else if (isRequired) {
    return object;
  }
  return [];
};

export const fields = {
  common({
    initialValues,
    isEditField,
    bankNumberValue,
    agencyNumberValue,
    bankAccountRequired,
    requireWhatsappForLegalRepresentative,
    salesTeamList,
    permissions,
    whatsappRequired
  }: FieldsProps) {
    const allFields = [
      {
        name: "accounts",
        key: "initialDataGroupedFields",
        fields: [
          {
            label: "Dados Iniciais",
            type: "arrayField",
            name: "accountLegal",
            value: initialValues?.account || [
              {
                fullName: initialValues?.fullName,
                municipalRegistration: initialValues?.municipalRegistration,
                institutionalPortal: initialValues?.institutionalPortal,
                stateRegistration: initialValues?.stateRegistration
              }
            ],
            fields: [
              {
                label: "Razão Social",
                placeholder: "Razão Social",
                type: "text",
                name: "fullName",
                value: initialValues?.fullName || "",
                required: true,
                disabled: isEditField
              },
              {
                label: "Portal institucional",
                placeholder: "Portal institucional",
                type: "text",
                name: "institutionalPortal",
                value: initialValues?.institutionalPortal || "",
                required: false
              }
            ]
          }
        ]
      },
      {
        name: "contacts",
        key: "contactsGroupedFields",
        fields: [
          {
            label: "Contato(s)",
            type: "arrayField",
            name: "contacts",
            minItems: 2,
            newFieldButtonEnabled: true,
            deleteButtonEnabled: true,
            newFieldButtonMessage: "Adicionar novo contato",
            value: initialValues?.contacts
              ? formatAccountFields({
                  values: initialValues?.contacts,
                  requiredFields: [
                    AccountContactType.PHONE_NUMBER,
                    AccountContactType.EMAIL
                  ],
                  fieldKey: "contactType",
                  valueKey: "contact",
                  includeAllValues: true
                })
              : [
                  {
                    contactType: AccountContactType.EMAIL,
                    contact: ""
                  },
                  {
                    contactType: AccountContactType.PHONE_NUMBER,
                    contact: ""
                  }
                ],
            defaultValues: { contactType: "", contact: "" },
            size: 8,
            deleteEnabledOn: (fieldValue, values, idx) => {
              const indexes = [
                values.findIndex(
                  v => v.contactType === AccountContactType.EMAIL
                ),
                values.findIndex(
                  v => v.contactType === AccountContactType.PHONE_NUMBER
                )
              ];

              return (
                !indexes.includes(idx) ||
                (fieldValue.contactType !== AccountContactType.EMAIL &&
                  fieldValue.contactType !== AccountContactType.PHONE_NUMBER)
              );
            },
            autoSelectOnNewField: true,
            showQuantity: true,
            required: true,
            fields: [
              {
                label: "Tipo",
                type: "select",
                required: true,
                name: "contactType",
                autoSelectOption: true,
                disabledInputOn: (fieldValue, values, idx) => {
                  const indexes = [
                    values.findIndex(
                      v => v.contactType === AccountContactType.EMAIL
                    ),
                    values.findIndex(
                      v => v.contactType === AccountContactType.PHONE_NUMBER
                    )
                  ];

                  return indexes.includes(idx);
                },
                exclusiveOptions: true,
                size: 4,
                value: "",
                deleteButtonEnabled: true,

                options: [
                  {
                    key: AccountContactType.PHONE_NUMBER,
                    text: "Telefone"
                  },
                  { key: AccountContactType.EMAIL, text: "E-mail" },
                  { key: AccountContactType.WHATSAPP, text: "WhatsApp" }
                ]
              },
              {
                label: "Contato",
                type: "text",
                name: "contact",
                value: "",
                maskCondition: value => {
                  if (
                    [
                      AccountContactType.PHONE_NUMBER,
                      AccountContactType.WHATSAPP
                    ].includes(value.contactType)
                  ) {
                    return "(**) *****-****";
                  } else {
                    return null;
                  }
                },
                required: true
              }
            ]
          }
        ]
      },
      {
        name: "documents",
        key: "documentsGroupedFields",
        fields: [
          {
            label: "Documentos",
            type: "arrayField",
            name: "documents",
            newFieldButtonEnabled: true,
            deleteButtonEnabled: true,
            newFieldButtonDisabled: isEditField,
            minItems: 1,
            newFieldButtonMessage: "Adicionar novo documento",
            value: initialValues?.documents
              ? formatAccountFields({
                  values: initialValues?.documents,
                  requiredFields: [AccountDocumentType.CNPJ],
                  fieldKey: "documentType",
                  valueKey: "documentNumber",
                  includeAllValues: true
                })
              : [
                  {
                    documentType: AccountDocumentType.CNPJ,
                    documentNumber: ""
                  },
                  { documentType: AccountDocumentType.IE, documentNumber: "" },
                  {
                    documentType: AccountDocumentType.INSCRICAO_MUNICIPAL,
                    documentNumber: ""
                  }
                ],
            defaultValues: {
              documentType: "",
              documentNumber: ""
            },
            deleteEnabledOn: value => {
              return ![AccountDocumentType.CNPJ].includes(value.documentType);
            },
            limit: 3,
            max: 3,
            fields: [
              {
                label: "Tipo",
                type: "select",
                name: "documentType",
                value: "",
                size: 4,
                minItem: 1,
                exclusiveOptions: true,
                autoSelectOption: true,
                disabledInputOn: item => {
                  return [AccountDocumentType.CNPJ].includes(item.documentType);
                },
                options: [
                  {
                    key: AccountDocumentType.CNPJ,
                    text: "CNPJ"
                  },
                  { key: AccountDocumentType.IE, text: "INSC. ESTADUAL" },
                  {
                    key: AccountDocumentType.INSCRICAO_MUNICIPAL,
                    text: "INSC. MUNICIPAL"
                  }
                ]
              },
              {
                label: "Número",
                type: "text",
                name: "documentNumber",
                required: true,
                value: "",
                disabledInputOn: item => {
                  return (
                    isEditField &&
                    [AccountDocumentType.CNPJ].includes(item.documentType)
                  );
                },
                maskCondition: doc => {
                  if (doc.documentType === AccountDocumentType.CNPJ) {
                    return "**.***.***/****-**";
                  } else {
                    return null;
                  }
                }
              }
            ]
          }
        ]
      },
      {
        name: "legalRepresentatives",
        key: "legalRepresentativesGroupedFields",
        fields: [
          {
            label: "Representantes Legais",
            type: "arrayField",
            name: "legalRepresentatives",
            minItems: 1,
            newFieldButtonEnabled: true,
            deleteButtonEnabled: true,
            newFieldButtonMessage: "Adicionar novo representante",
            disabled: isEditField,
            value:
              initialValues?.legalRepresentatives?.length > 0
                ? initialValues?.legalRepresentatives
                : [
                    {
                      fullName: "",
                      document: "",
                      email: "",
                      birthDate: "",
                      maritalStatus: "",
                      role: "",
                      whatsapp: ""
                    }
                  ],
            defaultValues: {
              fullName: "",
              document: "",
              email: "",
              birthDate: "",
              maritalStatus: "",
              role: "",
              whatsapp: ""
            },
            fields: [
              {
                label: "Nome Completo",
                placeholder: "",
                type: "text",
                name: "fullName",
                value: "",
                disabledInputOn: (fieldValue, values, idx) => {
                  if ((!fieldValue || fieldValue === "") && isEditField)
                    return false;
                },
                required: !isEditField,
                customValidation: value => {
                  if (value) {
                    value = value.trimEnd();
                    value = value.trimStart();
                    if (value.split(" ").length >= 2) {
                      return { success: true, message: null };
                    } else {
                      return {
                        success: false,
                        message: "Nome deve ser composto de nome e sobrenome"
                      };
                    }
                  }
                  return { success: true, message: null };
                }
              },
              {
                label: "Data de Nascimento",
                placeholder: "____/____/______",
                type: "date",
                name: "birthDate",
                value: "",
                size: 4,
                invalidDateErrorMessage: `A data deve ser entre os anos ${minDate.getFullYear()} e ${maxDate.getFullYear()}`,
                maxDate: maxDate,
                minDate: minDate,
                required: true
              },
              {
                label: "CPF",
                type: "text",
                name: "document",
                required: true,
                value: "",
                mask: "***.***.***-**"
              },
              {
                label: "RG",
                type: "text",
                name: "rg",
                required: true,
                value: ""
              },
              {
                label: "E-mail",
                placeholder: "",
                type: "email",
                name: "email",
                value: "",
                required: true
              },
              {
                label: "Cargo",
                type: "text",
                name: "role",
                key: "role",
                value: "",
                required: true
              },
              {
                label: "WhatsApp",
                type: "text",
                name: "whatsapp",
                value: "",
                required: requireWhatsappForLegalRepresentative,
                maskCondition: value => {
                  if (value?.whatsapp) {
                    return "(**) *****-****";
                  }
                  return null;
                }
              }
            ]
          }
        ]
      },
      {
        name: "addresses",
        key: "addressesGroupedFields",
        fields: [
          {
            label: "Endereço",
            type: "addresses",
            name: "addresses",
            minItems: 1,
            newFieldButtonMessage: "Adicionar novo endereço",
            value: initialValues?.addresses ?? [
              {
                zipCode: "",
                city: "",
                state: "",
                street: "",
                neighborhood: "",
                number: "",
                minItems: 1,
                note: ""
              }
            ],
            defaultValues: {
              zipCode: "",
              city: "",
              state: "",
              street: "",
              neighborhood: "",
              number: "",
              minItems: 1,
              note: ""
            },
            fields: [
              {
                label: "Código postal",
                type: "zipCode",
                name: "zipCode",
                key: "zipCode",
                mask: "99999-999",
                value: "",
                required: true,
                minItems: 1
              },
              {
                label: "Cidade",
                type: "text",
                name: "city",
                key: "city",
                value: "",
                required: true,
                minItems: 1,
                disabled: true
              },
              {
                label: "Estado",
                type: "text",
                name: "state",
                key: "state",
                value: "",
                required: true,
                mask: "**",
                minItems: 1,
                disabled: true
              },
              {
                label: "Rua",
                type: "text",
                name: "street",
                key: "street",
                value: "",
                required: true,
                minItems: 1
              },
              {
                label: "Bairro",
                type: "text",
                name: "neighborhood",
                key: "neighborhood",
                value: "",
                required: true,
                minItems: 1
              },
              {
                label: "Número",
                type: "string",
                name: "number",
                key: "number",
                value: "",
                required: true,
                minItems: 1,
                disabled: false
              },
              {
                label: "Complemento",
                type: "text",
                value: "",
                name: "note",
                key: "note",
                required: false,
                minItems: 1,
                disabled: false
              }
            ]
          }
        ]
      },
      {
        name: "checkingAccounts",
        key: "checkingAccountsGroupedFields",
        fields: [
          {
            label: "Dados Bancários",
            type: "arrayField",
            name: "checkingAccounts",
            required: bankAccountRequired,
            minItems: bankAccountRequired ? 1 : 0,
            newFieldButtonEnabled: true,
            deleteButtonEnabled: true,
            newFieldButtonMessage: "Adicionar nova conta",
            value: buildLegalAccountCheckingAccount({
              values: initialValues?.checkingAccounts,
              isRequired: bankAccountRequired,
              object: [
                {
                  bankNumber: bankNumberValue || "",
                  agencyNumber: agencyNumberValue || "",
                  accountNumber: ""
                }
              ]
            }),
            defaultValues: {
              bankNumber: bankNumberValue,
              agencyNumber: agencyNumberValue,
              accountNumber: ""
            },

            fields: [
              {
                label: "Banco",
                type: "number",
                name: "bankNumber",
                value: "",
                required: false,
                disabled: bankNumberValue,
                size: 3
              },
              {
                label: "Agência",
                type: "number",
                name: "agencyNumber",
                value: "",
                required: false,
                disabled: agencyNumberValue,
                size: 2,
                maxLength: 4
              },
              {
                label: "Conta",
                type: "number",
                name: "accountNumber",
                value: "",
                required: false,
                size: 2,
                customValidation: (value: string) => {
                  if (value) {
                    const validated = checkSicoobAccount(value);
                    if (validated) {
                      return { success: true, message: null };
                    } else {
                      return { success: false, message: "Conta Inválida" };
                    }
                  }
                }
              }
            ]
          }
        ]
      }
    ];
    if (
      permissions[PermissionsScope.POS]?.[PermissionsPosModule.ACCOUNTS]?.some(
        contractAction =>
          contractAction === PermissionsAction.OVERWRITE_SALES_TEAM
      )
    ) {
      return [
        ...allFields,
        {
          name: "groupedFields",
          key: "salesTeamField",
          fields: [
            {
              label: "Carteira do Cooperado",
              type: "select",
              name: "accountSalesTeam",
              options: salesTeamList,
              size: 4,
              minItem: 1,
              autoSelectOption: true,
              exclusiveOptions: true,
              value: initialValues?.salesTeamId || ""
            }
          ]
        }
      ];
    }
    return allFields;
  },
  create({
    agencyNumberValue,
    bankNumberValue,
    bankAccountRequired,
    initialValues,
    requireWhatsappForLegalRepresentative,
    salesTeamList,
    permissions
  }: FieldsProps) {
    return [
      ...this.common({
        agencyNumberValue,
        bankNumberValue,
        bankAccountRequired,
        initialValues,
        requireWhatsappForLegalRepresentative,
        salesTeamList,
        permissions
      })
    ];
  },
  edit({
    initialValues,
    isEditField = true,
    bankNumberValue,
    agencyNumberValue,
    bankAccountRequired,
    salesTeamList,
    permissions,
    whatsappRequired,
    requireWhatsappForLegalRepresentative
  }: FieldsProps) {
    return [
      {
        label: "ID",
        type: "hidden",
        name: "id",
        value: initialValues?.id || "",
        disabled: true
      },
      ...this.common({
        initialValues,
        isEditField,
        agencyNumberValue,
        bankNumberValue,
        bankAccountRequired,
        salesTeamList,
        permissions,
        whatsappRequired,
        requireWhatsappForLegalRepresentative
      })
    ];
  }
};
