import { ProductsOffersActionsTypes } from "../types/ProductsOffers.reducer.types";

export function listRequest(filter?) {
  return {
    type: ProductsOffersActionsTypes.LIST_REQUEST,
    payload: { filter }
  };
}

export function listSuccess({ list }) {
  return {
    type: ProductsOffersActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: ProductsOffersActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ProductsOffersActionsTypes.DISMISS_MESSAGE
  };
}
