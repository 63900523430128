import produce from "immer";

import {
  ElectronicSignaturesActionsTypes,
  ElectronicSignaturesReducerActions,
  ElectronicSignaturesState
} from "../types/ElectronicSignatures.reducer.types";

const INITIAL_STATE: ElectronicSignaturesState = {
  list: [],
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ElectronicSignaturesReducerActions
): ElectronicSignaturesState {
  return produce(state, draft => {
    switch (action.type) {
      case ElectronicSignaturesActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ElectronicSignaturesActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ElectronicSignaturesActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ElectronicSignaturesActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ElectronicSignaturesActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ElectronicSignaturesActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ElectronicSignaturesActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ElectronicSignaturesActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = [];
        break;
      }

      case ElectronicSignaturesActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = [];
        break;
      }

      case ElectronicSignaturesActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
