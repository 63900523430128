import { MessageBarType } from "@fluentui/react";
import { StringifiableRecord } from "query-string";
import { createContext, ReactNode, useCallback, useContext } from "react";

import { makeRequest } from "../utils";
import { FetchResponse } from "../utils/fetchData";
import { isHtmlString } from "../utils/isHtmlString";

import { AlertTypes } from "@/common/types/AlertTypes";
import { useAlert } from "@/hooks/useAlert";

interface Messages {
  success?: string;
  error: string;
}

export interface ExecuteData {
  url: string;
  method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  notificationMessage?: string;
  showAlert?: boolean;
  params?: StringifiableRecord;
  messages?: Messages;
  bodyObj?: BodyInit;
}

export interface ApiContextData {
  execute: (data: ExecuteData) => Promise<FetchResponse>;
}

const ApiContext = createContext<ApiContextData>({} as ApiContextData);

const API_URL = `${import.meta.env.VITE_API_URL}`;

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { createAlert } = useAlert();
  // const { createApiAlertsResponse } = UseApiAlerts();

  const execute = useCallback(
    async ({
      url,
      method = "GET",
      showAlert,
      notificationMessage,
      params,
      bodyObj,
      messages
    }: ExecuteData) => {
      const baseUrl = `${API_URL}/${url}`;

      try {
        const response = await makeRequest({
          method,
          baseUrl,
          queryParams: params,
          bodyObj
        });

        if (response.error) throw response;

        const messageFromData =
          messages?.success || notificationMessage || response.data.message;
        const message = messageFromData || `Requisição ${method} em ${url}`;

        const apiResponseObject = {
          expiration: new Date().getTime(),
          createdAt: new Date().getTime(),
          timeout: 10000,
          level: MessageBarType.success,
          code: response.response.status,
          path: url,
          message,
          type: AlertTypes.API,
          method: method
        };
        if (showAlert) {
          createAlert(apiResponseObject);
        } else {
          // createApiAlertsResponse(apiResponseObject);
        }

        return response;
      } catch (err) {
        const errorMessageFromParams = messages?.error || notificationMessage;
        const apiErrorMessage = err?.error?.message;

        let finalErrorMessage =
          errorMessageFromParams ||
          `Tivemos um problema na requisição ${method} em ${url}`;

        if (!isHtmlString(apiErrorMessage)) {
          finalErrorMessage = `${finalErrorMessage} (${apiErrorMessage})`;
        }

        if (showAlert) {
          createAlert({
            timeout: 4000,
            level: MessageBarType.error,
            code: err?.response?.status,
            path: url,
            message: finalErrorMessage,
            type: AlertTypes.API,
            method: method
          });
        }
        return err;
      }
    },
    [createAlert]
  );
  return (
    <ApiContext.Provider value={{ execute }}>{children}</ApiContext.Provider>
  );
};
export const useApi = () => {
  const context = useContext(ApiContext);
  return context;
};
