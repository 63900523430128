import { PricingGroupsActionsTypes } from "../types/PricingGroups.reducer.types";

export function createRequest(values, { setSubmitting }, closePanel, isAdmin?) {
  return {
    type: PricingGroupsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createSuccess(message) {
  return {
    type: PricingGroupsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createFailure(message) {
  return {
    type: PricingGroupsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editRequest(values, { setSubmitting }, closePanel, isAdmin?) {
  return {
    type: PricingGroupsActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editSuccess(message) {
  return {
    type: PricingGroupsActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editFailure(message) {
  return {
    type: PricingGroupsActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listRequest(filter?, isAdmin?) {
  return {
    type: PricingGroupsActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listSuccess({ list }) {
  return {
    type: PricingGroupsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: PricingGroupsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: PricingGroupsActionsTypes.DISMISS_MESSAGE
  };
}
