export enum PaymentMethodType {
  CREDIT_CARD = "CREDIT_CARD",
  INVOICE_DEBIT = "INVOICE_DEBIT",
  PIX = "PIX"
}

export enum TranslatedPaymentMethodType {
  CREDIT_CARD = "Cartão de Crédito",
  INVOICE_DEBIT = "Pagamento em Fatura",
  PIX = "PIX"
}
