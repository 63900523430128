import { DetailsList, IColumn, Icon, Link, Stack } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { FileExtensionIconType } from "./types";

import { ContractsUploadService } from "@/core/libs/api";
import { formatDate } from "@/utils/FormatDate";

const uploadsColumns: IColumn[] = [
  {
    key: "name",
    name: "Nome",
    fieldName: "name",
    data: "string",
    minWidth: 100,
    maxWidth: 500,
    className: "ms-fontSize-16",
    onRender: item => (
      <Link
        key={item.name}
        onClick={async () => {
          window.open(await item.link());
        }}
      >
        {item.name}
      </Link>
    )
  },
  {
    key: "type",
    name: "Tipo",
    fieldName: "type",
    data: "string",
    minWidth: 40,
    maxWidth: 200,
    className: "ms-fontSize-16"
  },
  {
    key: "date",
    name: "Data",
    fieldName: "date",
    minWidth: 85,
    data: "string",
    className: "ms-fontSize-16"
  },
  {
    key: "icon",
    iconName: "page",
    isIconOnly: true,
    name: "iconName",
    fieldName: "iconName",
    minWidth: 30,
    onRender: item => (
      <Link key={item.name + " icon"} href={item.link} target="_blank">
        <Icon
          iconName={FileExtensionIconType[item.type] ?? "Document"}
          style={{ color: "#395ca3", fontSize: 20 }}
        />
      </Link>
    )
  }
];

const getFileExtension = (file: string) => {
  if (file) {
    const [fileExtension] = file.match(/\.[0-9a-z]+$/i);

    return fileExtension.slice(1).toUpperCase();
  }
};

const removeFileExtension = (file: string) => {
  if (file) {
    const extensionRemoved = file.replace(/\.[0-9a-z]+$/i, "");

    return extensionRemoved;
  }
};

interface Uploads {
  id: number;
  fileName: string;
  filePath: string;
  createdAt: string;
}

interface UploadListColumns {
  name: string;
  type: string;
  date: string;
  link: any;
}

interface DocumentUploadTableProp {
  uploads: Uploads[];
}

function DocumentUploadTable({
  uploads
}: DocumentUploadTableProp): JSX.Element {
  const [uploadsFormated, setUploadsFormated] = useState<
    Array<UploadListColumns>
  >([] as Array<UploadListColumns>);

  const getFileDownloadUrl = async (id: number) => {
    const abortController = new AbortController();
    const contractsUploadService = ContractsUploadService();
    const { signal } = abortController;
    const params = {
      id: id
    };

    return (await contractsUploadService.download({ params, signal })).data;
  };

  const formatUploadedFiles = useCallback(async () => {
    const format = await Promise.all(
      uploads.map(async upload => ({
        name: removeFileExtension(upload.fileName),
        type: getFileExtension(upload.fileName),
        date: formatDate(upload.createdAt),
        link: async () => await getFileDownloadUrl(upload.id)
      }))
    );
    setUploadsFormated(format);
  }, [uploads, setUploadsFormated]);

  useEffect(() => {
    formatUploadedFiles();
  }, [uploads]);

  return (
    <Stack>
      <Stack.Item>
        <div>
          <DetailsList
            selectionMode={0}
            items={uploadsFormated}
            columns={uploadsColumns}
            setKey="uploadsItems"
            styles={{
              headerWrapper: {
                selectors: {
                  "& .ms-DetailsHeader": {
                    paddingTop: 0
                  }
                }
              }
            }}
          />
        </div>
      </Stack.Item>
    </Stack>
  );
}

export default DocumentUploadTable;
