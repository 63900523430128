import { ProgressIndicator, Stack } from "@fluentui/react";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PaymentMethod } from "../../enums";

import { CONTRACT_STEPS, ContractStepsEnum } from "./contractSteps";
import { ContractsEditProps } from "./interfaces";
import { calcProgressBar } from "./utils/calcProgressBar";
import { findMonthlyPaymentMethod } from "./utils/findMonthlyPaymentMethod";
import {
  doesCurrentContractHaveItems,
  isAccountFilled
} from "./utils/verifyContractStepsRules";

import { Step4, Step1, Step2, Step3, Step5 } from "@/components/Contracts";
import { Loader } from "@/components/Shared/Loader";
import { MessageBar, messageBarTypes } from "@/core/libs/message-bar";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { getContract } from "@/modules/Contracts/api";
import { VerifyContractStepFunc } from "@/modules/Contracts/types";
import { ServicesCategoriesTypesEnum } from "@/modules/Settings/types/ServicesCategories.types";

export function ContractsEdit(props: ContractsEditProps): JSX.Element {
  const { handleSelectBu, selectedBU } = useSelectedBu();
  const [message, setMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [maxStep, setMaxStep] = useState<number>(0);

  const [contractId] = useState<string | undefined>(
    props.match.params.id || undefined
  );

  const [contract, setContract] = useState<any>(undefined);
  const [monthlyPaymentMethod, setMonthlyPaymentMethod] = useState<any>();

  const history = useHistory();
  const dispatch = useDispatch();
  const cleanMessage = () => {
    setMessage(undefined);
  };
  const fetchData = async () => {
    const response = await getContract(contractId);
    if (response.error) {
      setIsLoading(false);
      setMessage({
        message:
          "Não foi possível localizar o contrato, por favor atualize a página e tente novamente",
        type: messageBarTypes.ERROR
      });
    } else {
      const { data } = response;
      // Can edit if IN_PROGRESS
      if (data.currentStep.internalName !== "IN_PROGRESS") {
        history.push(`/contracts/${data.id}`);
      } else {
        setContract(data);
        await nextStep(data);
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (contract?.businessUnit && selectedBU?.id !== contract.businessUnit.id) {
      setMessage({
        message: `O contrato ${contractId} não pertence a ${selectedBU?.name}.
         Devido a isso, redirecionamos seu perfil para: ${contract.businessUnit.name}, a qual o respectivo contrato pertence.`,
        type: messageBarTypes.WARNING
      });
      handleSelectBu(contract.businessUnit.id);
    }
  }, [selectedBU, contract, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const verifyContractSteps = useCallback(
    async ({
      currentContract,
      setCurrentStep,
      currentStep
    }: VerifyContractStepFunc) => {
      {
        // Passo 1: Checar se toda informação de account está preenchida
        if (!isAccountFilled(currentContract, selectedBU, PaymentMethod)) {
          setCurrentStep && setCurrentStep(ContractStepsEnum.COOPERADO);

          setMaxStep(
            doesCurrentContractHaveItems(currentContract)
              ? ContractStepsEnum.BENEFICIOS
              : ContractStepsEnum.COOPERADO
          );
          return;
        }

        // Passo 2: Checar se contrato não possui frete ou itens
        if (
          !doesCurrentContractHaveItems(currentContract) ||
          !currentContract?.shipping
        ) {
          setCurrentStep && setCurrentStep(ContractStepsEnum.BENEFICIOS);
          setMaxStep(ContractStepsEnum.BENEFICIOS);
          return;
        }

        const contractNeedToAssignPaymentMethod = () => {
          const serviceCategoryIsTag =
            currentContract?.serviceCategory?.type ===
            ServicesCategoriesTypesEnum.TAG;

          if (serviceCategoryIsTag) return false;

          if (
            currentStep === ContractStepsEnum.METODO_DE_PAGAMENTO ||
            currentStep === ContractStepsEnum.CONFIRMACAO
          ) {
            return !currentContract?.paymentMethodId;
          } else {
            return true;
          }
        };

        // Passo 3: Se não existir request de pagamento, fazer pagamento
        if (contractNeedToAssignPaymentMethod() && currentContract) {
          setCurrentStep &&
            setCurrentStep(ContractStepsEnum.METODO_DE_PAGAMENTO);

          setMaxStep(
            currentContract?.account
              ? ContractStepsEnum.METODO_DE_PAGAMENTO
              : ContractStepsEnum.BENEFICIOS
          );
          return;
        }

        // Passo 4: Caso cumpra condições acima, prosseguir para confirmação
        if (
          isAccountFilled(currentContract, selectedBU, PaymentMethod) &&
          doesCurrentContractHaveItems(currentContract) &&
          !contractNeedToAssignPaymentMethod()
        ) {
          setCurrentStep && setCurrentStep(ContractStepsEnum.CONFIRMACAO);
          setMaxStep(ContractStepsEnum.CONFIRMACAO);
          return;
        }
      }
    },
    [selectedBU]
  );

  const nextStep = useCallback(
    async contract => {
      const response = await getContract(contract.id);
      if (response.error) {
        setMessage({
          message: "Por favor atualize a página e tente novamente",
          type: messageBarTypes.ERROR
        });
      } else {
        const { data } = response;
        setContract(data);
        verifyContractSteps({
          currentContract: data,
          setCurrentStep,
          currentStep
        });
        setMonthlyPaymentMethod(findMonthlyPaymentMethod(data));
        return;
      }
    },
    [currentStep, verifyContractSteps]
  );

  useEffect(() => {
    currentStep;
  }, [currentStep]);

  return (
    <>
      <Helmet>
        <title>Confecção de contrato - Libertas</title>
      </Helmet>
      {message && (
        <MessageBar message={message} dismissMessage={cleanMessage} />
      )}
      {isLoading ? (
        <ProgressIndicator
          styles={{
            root: { position: "absolute", left: 0, right: 0, top: 0 },
            itemProgress: { padding: 0 }
          }}
        />
      ) : (
        <Stack tokens={{ childrenGap: 15 }}>
          <h2 className="ms-fontSize-24">Novo contrato</h2>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <ProgressIndicator
                  percentComplete={calcProgressBar(currentStep)}
                  styles={{ root: { width: "75%", margin: "auto" } }}
                />
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Pivot
                  styles={{
                    root: {
                      justifyContent: "space-around",
                      display: "flex"
                    },
                    link: {
                      marginRight: 0
                    },
                    linkIsSelected: {
                      marginRight: 0
                    }
                  }}
                  selectedKey={String(currentStep)}
                  onLinkClick={(i: any, e) => {
                    const nextStep = Number(i.key.slice(-1));
                    if (nextStep <= maxStep) {
                      setCurrentStep(nextStep);
                    }
                  }}
                >
                  {CONTRACT_STEPS.map(step => {
                    const stepLabels = {
                      [ContractStepsEnum.DADOS_BASE]: "Dados Base",
                      [ContractStepsEnum.COOPERADO]: "Cooperado",
                      [ContractStepsEnum.BENEFICIOS]: "Benefícios combos",
                      [ContractStepsEnum.METODO_DE_PAGAMENTO]:
                        "Método de pagamento",
                      [ContractStepsEnum.CONFIRMACAO]: "Confirmação"
                    };

                    const label = stepLabels[step] || "Dados Base";
                    return (
                      <PivotItem
                        key={step}
                        headerText={label}
                        headerButtonProps={{
                          "data-testid": `step-button-${step}`
                        }}
                      >
                        <Stack styles={{ root: { padding: 20 } }}>
                          {isLoading ? (
                            <Loader />
                          ) : (
                            <>
                              {step === ContractStepsEnum.DADOS_BASE && (
                                <Step1
                                  contract={contract}
                                  nextStep={nextStep}
                                  selectedBU={selectedBU}
                                />
                              )}
                              {step === ContractStepsEnum.COOPERADO && (
                                <Step2
                                  contract={contract}
                                  nextStep={nextStep}
                                  setContractMessage={setMessage}
                                />
                              )}
                              {step === ContractStepsEnum.BENEFICIOS && (
                                <Step3
                                  contract={contract}
                                  setContract={setContract}
                                  verifyContractSteps={verifyContractSteps}
                                  nextStep={nextStep}
                                />
                              )}
                              {step ===
                                ContractStepsEnum.METODO_DE_PAGAMENTO && (
                                <Step4
                                  monthlyPaymentMethod={monthlyPaymentMethod}
                                  nextStep={nextStep}
                                  contract={contract}
                                  paymentMethodId={contract?.paymentMethodId}
                                />
                              )}
                              {step === ContractStepsEnum.CONFIRMACAO && (
                                <Step5
                                  contract={contract}
                                  refetchContractData={fetchData}
                                  monthlyPaymentMethod={monthlyPaymentMethod}
                                />
                              )}
                            </>
                          )}
                        </Stack>
                      </PivotItem>
                    );
                  })}
                </Pivot>
              </div>
            </div>
          </div>
        </Stack>
      )}
    </>
  );
}
