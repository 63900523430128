import {
  DefaultButton,
  Dropdown,
  FocusTrapZone,
  FocusZone,
  IDropdownOption,
  MaskedTextField,
  MessageBar as FluentMessageBar,
  PrimaryButton,
  SelectableOptionMenuItemType,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  Link
} from "@fluentui/react";
import { Field, Form, Formik } from "formik";
import { MutableRefObject, useEffect, useState } from "react";
import * as Yup from "yup";

import { ContractCarrier } from "./ContractCarrier";
import { ContractOffers } from "./ContractOffers";
import { ContractOperation } from "./ContractOperation";

import TagConfirmationDialog from "@/components/Offers/TagOffers/TagOfferContract/TagDialog";
import { CustomDrawerFooter } from "@/components/Shared/CustomDrawer";
import { Loader } from "@/components/Shared/Loader";
import { OfferOverrideModal } from "@/components/Shared/OfferOverrideModal";
import {
  ContractsService,
  ProductsService,
  TagsService
} from "@/core/libs/api";
import {
  IMessageBar,
  MessageBar,
  messageBarTypes
} from "@/core/libs/message-bar";
import { SubmittingDiv } from "@/modules/Contracts/styles";

interface InsertItemTagProps {
  contract: any;
  close: (ev, refresh?) => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
}

export interface SubmitPersonalizedOfferProps {
  activationFee?: string;
  fees: Array<{
    context?: string;
    feeTypeId?: number;
    paymentStrategy?: string;
    value?: string;
  }>;
  isChecked: boolean;
  price?: string;
}
export interface PersonalizedOffer
  extends Omit<
    SubmitPersonalizedOfferProps,
    "activationFee" | "price" | "isChecked"
  > {
  activationFee?: string;
  price?: string;
}

const contractsService = ContractsService();
const productsService = ProductsService();
const tagsService = TagsService();

async function listAllOffers(
  service,
  businessActionId,
  setMessage,
  setOperations
): Promise<void> {
  const abortController = new AbortController();
  const { signal } = abortController;

  const params = { query: { service, businessActionId } };

  const response = await contractsService.listOffers({ params, signal });

  if (response.error) {
    setMessage({
      message:
        response.error?.message ??
        "Não foi possível listar as operações, por favor atualize a página",
      type: messageBarTypes.ERROR
    });
    return;
  }

  const operations: IDropdownOption[] = [];

  response.data.forEach(operation => {
    operations.push({
      key: operation.id,
      text: operation.name,
      data: operation
    });
  });

  setOperations(operations);
  return;
}

function searchProductBySerialNumber(
  businessActionId,
  serialNumber,
  operation,
  service
) {
  const abortController = new AbortController();
  const { signal } = abortController;

  const params = {
    query: { businessActionId, serialNumber, operation, service }
  };

  return productsService.searchProduct({ params, signal });
}

async function listVehiclesTypes(
  businessActionId,
  setMessage,
  setVehiclesTypesOptions
): Promise<void> {
  const abortController = new AbortController();
  const { signal } = abortController;

  const params = { query: { businessActionId } };

  const response = await tagsService.listVehiclesGroups({ signal, params });

  if (response.error) {
    setMessage({
      message:
        response.error?.message ??
        "Não foi possível listar os tipos de veículos, por favor atualize a página",
      type: messageBarTypes.ERROR
    });
    return;
  }

  const options: IDropdownOption[] = [];
  for (const group of response.data.items) {
    options.push({
      key: `group-${group.id}`,
      text: group.name,
      itemType: SelectableOptionMenuItemType.Header
    });

    for (const type of group.tagVehicleTypes.sort(
      (a, b) => a.axles - b.axles
    )) {
      options.push({
        key: type.id,
        text: type.description,
        data: type
      });
    }
  }

  setVehiclesTypesOptions(options);
  return;
}

function createContractItem(values, contractId, { businessActionId }) {
  const abortController = new AbortController();
  const { signal } = abortController;
  const params = {
    id: contractId,
    body: values,
    query: { businessActionId }
  };

  return contractsService.saveItem({ params, signal });
}

const schema = Yup.object().shape({
  operation: Yup.number().required("Obrigatório"),
  offerPrice: Yup.number().required("Obrigatório"),
  additionalInfo: Yup.object().shape({
    serialNumber: Yup.string()
      .required("Obrigatório")
      .min(16, "O número de série deve ter 16 digitos"),
    vehicleLicensePlate: Yup.string()
      .required("Obrigatório")
      .matches(
        /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/,
        "O número da placa deve seguir o padrão Mercosul"
      )
  })
});

export function InsertItemTag({
  contract,
  close,
  drawerContainerRef
}: InsertItemTagProps): JSX.Element {
  const formId = "insert-item-tag-form";
  const [isLoadingFirstRequest, setIsLoadingFirstRequest] = useState(true);
  const [message, setMessage] = useState(undefined);
  const [carrier, setCarrier] = useState<string | number>(undefined);
  const [carriers, setCarriers] = useState<IDropdownOption[]>([]);
  const [operations, setOperations] = useState([]);
  const [offers, setOffers] = useState([]);
  const [allPlansAndOffers, setAllPlansAndOffers] = useState([]);
  const [selectedOfferPriceId, setSelectedOfferPriceId] =
    useState<number>(undefined);
  const [vehiclesTypesOptions, setVehiclesTypesOptions] = useState<
    IDropdownOption[]
  >([]);
  const [serialNumberMessage, setSerialNumberMessage] =
    useState<IMessageBar>(null);
  const [isSearchingSerialNumber, setIsSearchingSerialNumber] = useState(false);
  const [inventoryId, setInventoryId] = useState<string>(undefined);

  const [isPersonalizedOffer, setIsPersonalizedOffer] =
    useState<boolean>(false);
  const [personalizedOfferValues, setPersonalizedOfferValues] =
    useState<PersonalizedOffer>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTagModal, setShowTagModal] = useState<boolean>(false);
  const [submittingValues, setSubmittingValues] = useState(null);

  useEffect(() => {
    Promise.all([
      listAllOffers(
        contract.service.id,
        contract.businessUnit.id,
        setMessage,
        setOperations
      ),
      listVehiclesTypes(
        contract.businessUnit.id,
        setMessage,
        setVehiclesTypesOptions
      )
    ]).finally(() => setIsLoadingFirstRequest(false));
  }, [contract.service.id, contract.businessUnit.id]);

  useEffect(() => {
    if (selectedOfferPriceId) {
      setSelectedOfferPriceId(undefined);
    }
  }, [selectedOfferPriceId]);

  const formatAndSetCarriers = carriers => {
    const carriersFormatted: IDropdownOption[] = carriers?.map(carrier => ({
      key: carrier.id,
      text: carrier.name,
      data: carrier.plans
    }));

    setCarriers(carriersFormatted);
  };

  const handleSelectedPriceId = offerPriceId => {
    if (offerPriceId !== selectedOfferPriceId) {
      setSelectedOfferPriceId(offerPriceId);
    } else if (offerPriceId === undefined) {
      setSelectedOfferPriceId(undefined);
    }
  };

  const closePanel = (ev, refresh?) => {
    close(ev, refresh);
  };

  const cleanMessage = () => {
    setMessage(undefined);
  };

  async function handleChangeSerialNumber(serialNumber, operation, service) {
    setIsSearchingSerialNumber(true);

    const response = await searchProductBySerialNumber(
      contract.businessUnit.id,
      serialNumber,
      operation,
      service
    );

    setInventoryId(undefined);
    setIsSearchingSerialNumber(false);
    if (response.data) {
      setInventoryId(response.data.inventory.id);
      setSerialNumberMessage({
        message: `Número de série para ${response.data.name} encontrado.`,
        type: messageBarTypes.SUCCESS
      });
      return response.data;
    } else {
      setSerialNumberMessage({
        message: response.error.message,
        type: messageBarTypes.ERROR
      });
    }
  }

  function handleConfirmModalTag(submittingValues, setSubmitting) {
    createContractItem(submittingValues, contract.id, {
      businessActionId: contract.businessUnit.id
    })
      .then(response => {
        setSubmitting(true);

        if (response.error) {
          setMessage({
            message:
              response.error?.message ??
              "Não foi possível adicionar o benefício combo, por favor tente novamente",
            type: messageBarTypes.ERROR
          });
          setShowTagModal(false);
        } else {
          setShowTagModal(false);
          return closePanel(false);
        }
      })
      .finally(() => {
        setShowTagModal(false);
        setSubmittingValues(null);
        setShowModal(false);
      });
  }

  const onSubmit = values => {
    setShowTagModal(true);
    const submitValues = {
      operation: values.operation,
      offerPrice: values.offerPrice,
      plan: values.plan,
      inventory: inventoryId,
      additionalInfo: {
        serialNumber: values.additionalInfo.serialNumber,
        vehicleLicensePlate: values.additionalInfo.vehicleLicensePlate,
        vehicleType: values.additionalInfo.vehicleType,
        contractId: contract.id
      },
      additionalContractItems: values?.additionalContractItems ?? [],
      offerOverride: personalizedOfferValues
    };
    setSubmittingValues(submitValues);
  };

  const initialValues = {
    operation: "",
    offerPrice: "",
    additionalInfo: {
      serialNumber: "",
      vehicleLicensePlate: ""
    }
  };

  const removePersonalizedOffer = () => {
    setIsPersonalizedOffer(false);
    setPersonalizedOfferValues(null);
  };

  const openPersonalizedOfferModal = () => {
    setShowModal(true);
  };

  const closePersonalizedOfferModal = () => {
    setShowModal(false);
  };

  const submitPersonalizedOffer = (data: SubmitPersonalizedOfferProps) => {
    const formattedOfferOverride = {
      activationFee: data.activationFee,
      fees: data.fees,
      price: data.price
    };
    setPersonalizedOfferValues(formattedOfferOverride);
    closePersonalizedOfferModal();
  };

  return (
    <>
      {isLoadingFirstRequest ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            values,
            isValid
          }) => {
            const operation = operations.find(
              operation => operation.data.id === values.operation
            );

            return (
              <Form id={formId}>
                <Stack tokens={{ childrenGap: 15 }}>
                  <ContractOperation
                    operations={operations}
                    resetCarrierAndOffers={() => {
                      setCarrier("");
                      setAllPlansAndOffers(undefined);
                    }}
                    {...{
                      message,
                      setMessage,
                      formatAndSetCarriers,
                      setFieldValue
                    }}
                  />
                  <ContractCarrier
                    selectedCarrier={carrier}
                    carriers={carriers}
                    setCarrier={setCarrier}
                    isOperationSelected={!!values.operation}
                    {...{
                      message,
                      setMessage,
                      setFieldValue,
                      setAllPlansAndOffers
                    }}
                  />
                  <ContractOffers
                    carrier={carrier}
                    allPlansAndOffers={allPlansAndOffers}
                    offers={offers}
                    setOffers={setOffers}
                    handleSelectedPriceId={handleSelectedPriceId}
                    {...{ message, setMessage, setFieldValue }}
                  />
                  <Field
                    id="input-serial-number"
                    label="Número de série"
                    placeholder="Número de série"
                    name="additionalInfo.serialNumber"
                    key="additionalInfo.serialNumber"
                    required
                    type="text"
                    as={TextField}
                    onChange={(evt, newValue) => {
                      evt.stopPropagation();
                      evt.preventDefault();

                      const valueOnlyNumbers = newValue.replace(/\D/g, "");

                      setFieldValue(
                        "additionalInfo.serialNumber",
                        valueOnlyNumbers
                      );

                      if (valueOnlyNumbers.length === 16) {
                        handleChangeSerialNumber(
                          valueOnlyNumbers,
                          values.operation,
                          contract.service.id
                        ).then(search => {
                          if (
                            search?.additionalInfo &&
                            operation?.data.type === "RENEWAL"
                          ) {
                            setFieldValue(
                              "additionalInfo.vehicleType",
                              search.additionalInfo.vehicleType
                            );
                            setFieldValue(
                              "additionalInfo.vehicleLicensePlate",
                              search.additionalInfo.vehicleLicensePlate
                            );
                          }
                        });
                      }
                    }}
                    onPaste={evt => {
                      evt.preventDefault();

                      const pastedValue = evt.clipboardData.getData("Text");
                      const pastedValueOnlyNumbers = pastedValue.replace(
                        /\D/g,
                        ""
                      );

                      setFieldValue(
                        "additionalInfo.serialNumber",
                        pastedValueOnlyNumbers
                      );

                      if (pastedValueOnlyNumbers.length === 16) {
                        handleChangeSerialNumber(
                          pastedValueOnlyNumbers,
                          values.operation,
                          contract.service.id
                        ).then(search => {
                          if (
                            search?.additionalInfo &&
                            operation?.data.type === "RENEWAL"
                          ) {
                            setFieldValue(
                              "additionalInfo.vehicleType",
                              search.additionalInfo.vehicleType
                            );
                            setFieldValue(
                              "additionalInfo.vehicleLicensePlate",
                              search.additionalInfo.vehicleLicensePlate
                            );
                          }
                        });
                      }
                    }}
                    errorMessage={
                      errors["additionalInfo"]?.["serialNumber"] &&
                      touched["additionalInfo"]?.["serialNumber"]
                        ? errors["additionalInfo"]?.["serialNumber"]
                        : null
                    }
                    maxLength={16}
                  />
                  {serialNumberMessage && (
                    <MessageBar
                      message={serialNumberMessage}
                      dismissMessage={() => setSerialNumberMessage(null)}
                    />
                  )}
                  <Field
                    id="select-vehicle-type"
                    label="Tipo de veículo"
                    placeholder="Tipo de veículo"
                    name="additionalInfo.vehicleType"
                    key="additionalInfo.vehicleType"
                    required
                    disabled={operation?.data.type === "RENEWAL"}
                    readOnly={operation?.data.type === "RENEWAL"}
                    as={Dropdown}
                    options={vehiclesTypesOptions}
                    selectedKey={values.additionalInfo?.vehicleType?.id ?? null}
                    onChange={(_event, { data }) => {
                      setFieldValue("additionalInfo.vehicleType", data);
                    }}
                    errorMessage={
                      errors["additionalInfo"]?.["vehicleType"] &&
                      touched["additionalInfo"]?.["vehicleType"]
                        ? errors["additionalInfo"]?.["vehicleType"]
                        : null
                    }
                  />
                  <Field
                    id="input-vehicle-lincense-plate"
                    label="Placa do veículo"
                    placeholder="Placa do veículo"
                    name="additionalInfo.vehicleLicensePlate"
                    key="additionalInfo.vehicleLicensePlate"
                    required
                    disabled={operation?.data.type === "RENEWAL"}
                    readOnly={operation?.data.type === "RENEWAL"}
                    as={MaskedTextField}
                    onChange={(evt, newValue) => {
                      setFieldValue(
                        "additionalInfo.vehicleLicensePlate",
                        newValue.toUpperCase().replace(/[^A-Z0-9]/g, "")
                      );
                    }}
                    mask="AAA-1*11"
                    maskFormat={{
                      "*": /[a-zA-Z0-9]/,
                      A: /[a-zA-Z]/,
                      "1": /[0-9]/
                    }}
                    maskChar="_"
                    errorMessage={
                      errors["additionalInfo"]?.["vehicleLicensePlate"] &&
                      touched["additionalInfo"]?.["vehicleLicensePlate"]
                        ? errors["additionalInfo"]?.["vehicleLicensePlate"]
                        : null
                    }
                  />
                  {isPersonalizedOffer && personalizedOfferValues ? (
                    <Link
                      id="button-remove-personalized-offer"
                      onClick={removePersonalizedOffer}
                      disabled={!values.offerPrice}
                    >
                      Remover
                    </Link>
                  ) : (
                    <Link
                      id="button-personalized-offer"
                      onClick={openPersonalizedOfferModal}
                      disabled={!values.offerPrice}
                    >
                      Personalizar
                    </Link>
                  )}
                  {operation?.data.type === "RENEWAL" &&
                    values.additionalInfo?.vehicleType && (
                      <FluentMessageBar>
                        Para alterar o tipo e/ou placa do veículo, entre em
                        contato com a central de atendimento.
                      </FluentMessageBar>
                    )}
                  {isSearchingSerialNumber && (
                    <Loader customMessage="Buscando Tag..." />
                  )}
                  {message && (
                    <MessageBar
                      message={message}
                      dismissMessage={cleanMessage}
                    />
                  )}
                  {showModal && (
                    <OfferOverrideModal
                      showModal
                      closeModal={closePersonalizedOfferModal}
                      handleSubmitModal={submitPersonalizedOffer}
                      data={personalizedOfferValues}
                      serviceId={contract.service.id}
                    />
                  )}
                </Stack>

                <CustomDrawerFooter drawerContainerRef={drawerContainerRef}>
                  <Stack horizontal tokens={{ childrenGap: "8px" }}>
                    <DefaultButton id="button-cancel" onClick={closePanel}>
                      Cancelar
                    </DefaultButton>

                    <PrimaryButton
                      id="submit-button"
                      type="primary"
                      form={formId}
                      disabled={!isValid || inventoryId === undefined}
                    >
                      Adicionar
                    </PrimaryButton>
                  </Stack>
                </CustomDrawerFooter>
                {showTagModal && (
                  <TagConfirmationDialog
                    isVisible={true}
                    onConfirm={() =>
                      handleConfirmModalTag(submittingValues, isSubmitting)
                    }
                    onCancel={() => setShowTagModal(false)}
                    serialNumber={values.additionalInfo.serialNumber}
                  />
                )}
                {isSubmitting ? (
                  <FocusTrapZone disabled={!isSubmitting}>
                    <FocusZone>
                      <SubmittingDiv>
                        <Spinner size={SpinnerSize.large} />
                      </SubmittingDiv>
                    </FocusZone>
                  </FocusTrapZone>
                ) : null}
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}
