import {
  ConstrainMode,
  DetailsList,
  getTheme,
  IColumn,
  IconButton,
  IContextualMenuItem,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
  Toggle
} from "@fluentui/react";
import { Form, Formik } from "formik";
import { MouseEvent, useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import AccordionContractDetailRow from "../components/AccordionContractDetailRow";

import { InsertItem } from "./InsertItem/InsertItem";
import { InsertItemTag } from "./InsertItem/InsertItemTag";

import {
  buildColumns,
  copyAndSortProducts
} from "@/components/Contracts/productsColumns";
import { Card } from "@/components/Shared/Card";
import {
  CustomDrawer,
  DrawerActions,
  DrawerModules
} from "@/components/Shared/CustomDrawer";
import { Loader } from "@/components/Shared/Loader";
import { ContractsService } from "@/core/libs/api";
import { FieldsBuilder } from "@/core/libs/form-builder";
import { listStyles } from "@/core/libs/list-data/lib/styles";
import { MessageBar, messageBarTypes } from "@/core/libs/message-bar";
import { useAsyncReference } from "@/hooks/index";
import { StyledStep } from "@/modules/Contracts/styles";
import { VerifyContractStepFunc } from "@/modules/Contracts/types";
import { ServicesCategoriesTypesEnum } from "@/modules/Settings/types/ServicesCategories.types";

const schema = Yup.object().shape({
  shippingPrice: Yup.string().required("Obrigatório")
});

const getLimitParcel = parcels => {
  for (let i = 0; i < parcels?.length; i++) {
    if (parcels[i].additionalInfo) return parcels[i].additionalInfo.parcel;
  }
};

const deleteItem = ({
  itemId,
  setMessage,
  refresh,
  contractId,
  setContract,
  verifyContractSteps,
  setProducts,
  closeDrawer
}) => {
  setMessage(undefined);

  const abortController = new AbortController();
  const { signal } = abortController;
  const contractsService = ContractsService();

  const params = {
    id: itemId
  };

  contractsService.deleteItem({ params, signal }).then(response => {
    if (response.error) {
      setMessage({
        message:
          "Não foi possível remover o benefício combo, por favor tente novamente",
        type: messageBarTypes.ERROR
      });
    } else {
      refresh({
        contractId,
        setMessage,
        setContract,
        verifyContractSteps,
        setProducts,
        closeDrawer
      });
    }
  });
};

const listShippings = async ({
  contractId,
  setShippingPrices,
  setInitialValues
}) => {
  const abortController = new AbortController();
  const { signal } = abortController;
  const service = ContractsService();

  const response = await service.listShippings({
    params: { id: contractId },
    signal
  });

  if (response?.data) {
    const newOption = response.data.map(option => {
      return {
        key: option.id,
        text: `${option.name} ${
          option.deliveryTime !== null && option.deliveryTime !== 0
            ? ` - ${option.deliveryTime} ${
                option.deliveryTime > 1 ? "Dias" : "Dia"
              } - ${
                Number(option.price) === 0
                  ? "Grátis"
                  : toLocaleString(option.price)
              }`
            : ""
        }`,
        id: `option-${option.id}`
      };
    });

    setShippingPrices(newOption);
    setInitialValues({
      shippingPrice: newOption[0].key
    });
  } else {
    setShippingPrices([]);
    setInitialValues({
      shippingPrice: ""
    });
  }
};

const refresh = ({
  contractId,
  setMessage,
  setContract,
  verifyContractSteps,
  setProducts,
  closeDrawer
}) => {
  const abortController = new AbortController();
  const { signal } = abortController;

  const service = ContractsService();
  service.get({ id: contractId.toString(), signal }).then(response => {
    if (response.error) {
      setMessage({
        message:
          "Não foi possível atualizar o contrato, por favor atualize a página e tente novamente",
        type: messageBarTypes.ERROR
      });
    } else {
      setContract(response.data);
      verifyContractSteps({ currentContract: response.data });
      setProducts(response.data.items || []);
    }

    closeDrawer();
  });
};

type Step3Props = {
  nextStep: (contract, step?: number) => void;
  setContract: (contract) => void;
  verifyContractSteps: ({
    currentContract,
    updateCurrentStep
  }: VerifyContractStepFunc) => void;
  contract: any;
};

export function Step3({
  contract,
  nextStep,
  setContract,
  verifyContractSteps
}: Step3Props): JSX.Element {
  const drawerContainerRef = useRef<HTMLDivElement>(null);
  const [drawerState, setDrawerState] = useState({
    action: null,
    isOpen: false
  });
  const [selectedItemId, setSelectedItemId] = useAsyncReference(undefined);
  const [message, setMessage] = useState(undefined);
  const [isCompactMode, setIsCompactMode] = useState(false);
  const [products, setProducts] = useState(contract?.items);
  const [columns, setColumns] = useState(undefined);
  const refProducts = useRef(products);
  const refColumns = useRef(columns);
  const [shippingPrices, setShippingPrices] = useState([]);
  const [initialValues, setInitialValues] = useState({
    shippingPrice: contract?.shippingPrice || ""
  });

  const theme = getTheme();

  const updateProducts = updateProducts => {
    refProducts.current = updateProducts;
  };

  const updateAllProducts = newProducts => {
    updateProducts(newProducts);
    setProducts(newProducts);
  };

  const updateColumns = updateColumns => {
    refColumns.current = updateColumns;
    setColumns(updateColumns);
  };

  const onColumnClick = (
    event: MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    let sortedItems = refProducts.current;
    let isSortedDescending = column.isSortedDescending;

    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Sort the items.
    sortedItems = copyAndSortProducts(
      sortedItems,
      column.fieldName,
      isSortedDescending
    );

    // Reset the items and columns to match the state.
    updateProducts(sortedItems);

    updateColumns(
      refColumns.current.map(col => {
        col.isSorted = col.key === column.key;

        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        } else {
          col.isSortedDescending = false;
        }

        return col;
      })
    );
  };

  useEffect(() => {
    const newColumns = buildColumns({
      isCompactMode,
      onColumnClick,
      hasAction: true,
      serviceCategoryType: contract.serviceCategory.type
    });
    setColumns(newColumns);
    refColumns.current = newColumns;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    listShippings({
      contractId: contract.id,
      setShippingPrices,
      setInitialValues
    });
    //eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    if (columns) {
      const newColumns = buildColumns({
        isCompactMode,
        onColumnClick,
        hasAction: true,
        serviceCategoryType: contract.serviceCategory.type
      });
      setColumns(newColumns);
      refColumns.current = newColumns;
    }
    //eslint-disable-next-line
  }, [isCompactMode]);

  function buildMenuItems() {
    const menuItems: IContextualMenuItem[] = [
      {
        key: "editItem",
        text: "Remover",
        onClick: () =>
          deleteItem({
            itemId: selectedItemId.current,
            setMessage,
            refresh,
            contractId: contract.id,
            setContract,
            verifyContractSteps,
            setProducts: updateAllProducts,
            closeDrawer
          })
      }
    ];

    return menuItems;
  }

  function openPanel(action: DrawerActions) {
    setDrawerState({ action: action, isOpen: true });
  }

  function closeDrawer(_?, refreshCondition?: boolean) {
    setDrawerState({ action: null, isOpen: false });

    if (refreshCondition) {
      refresh({
        contractId: contract.id,
        setMessage,
        setContract,
        verifyContractSteps,
        setProducts: updateAllProducts,
        closeDrawer
      });
    }
  }

  function cleanMessage() {
    setMessage(undefined);
  }

  function edit(values, { setSubmitting }) {
    setSubmitting(true);

    const abortController = new AbortController();
    const { signal } = abortController;
    const service = ContractsService();
    const params = {
      body: values,
      id: contract.id
    };

    if (contract.items.length === 0) {
      setMessage({
        message: "Por favor adicione um benefício combo ao contrato.",
        type: messageBarTypes.WARNING
      });

      setSubmitting(false);
    } else if (contract.deliveryType !== params.body.shippingPrice) {
      service.edit({ params, signal }).then(response => {
        setSubmitting(false);

        if (response.error) {
          refresh({
            contractId: contract.id,
            setMessage,
            setContract,
            verifyContractSteps,
            setProducts: updateAllProducts,
            closeDrawer
          });

          setMessage({
            message:
              response.error.message ??
              "Não foi possível editar o contrato, por favor atualize a página e tente novamente.",
            type: messageBarTypes.ERROR
          });
        } else {
          setContract(response.data);
          nextStep(contract, 3);
        }
      });
    } else {
      nextStep(contract, 3);
    }
  }
  const _buildColumns = (columns, menuProps, selectItem) => {
    if (columns) {
      columns.forEach(column => {
        // On click icon button set selected item
        if (column.key === "actions") {
          column.onRender = item => (
            <IconButton
              id={`actions-${item.id}`}
              onMenuClick={() => selectItem(item.id)}
              menuProps={menuProps}
              iconProps={{ iconName: "MoreVertical" }}
              onRenderMenuIcon={() => null}
            />
          );
        }
      });
    }

    return columns;
  };
  const menuProps = {
    shouldFocusOnMount: true,
    items: [
      {
        key: "editItem",
        text: "Remover",
        onClick: () =>
          deleteItem({
            itemId: selectedItemId.current,
            setMessage,
            refresh,
            contractId: contract.id,
            setContract,
            verifyContractSteps,
            setProducts: updateAllProducts,
            closeDrawer
          })
      }
    ]
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Card>
        <StyledStep>
          {message && (
            <div style={{ marginBottom: 20 }}>
              <MessageBar message={message} dismissMessage={cleanMessage} />
            </div>
          )}

          <Stack horizontal horizontalAlign="space-between">
            <Stack.Item>
              <h2 className="ms-fontSize-20">Benefícios combos</h2>
            </Stack.Item>

            <Stack.Item>
              <PrimaryButton
                id="button-add-product"
                iconProps={{ iconName: "Add" }}
                onClick={() => openPanel(DrawerActions.INCLUDE)}
              />
            </Stack.Item>
          </Stack>

          <Toggle
            id="button-change-view"
            label="Habilitar modo compacto"
            checked={isCompactMode}
            onChange={(_, checked) => setIsCompactMode(checked)}
            onText="Compacto"
            offText="Normal"
          />

          <DetailsList
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.horizontalConstrained}
            items={[...refProducts.current]}
            columns={_buildColumns(columns, menuProps, setSelectedItemId)}
            styles={listStyles}
            onRenderRow={rowProps => (
              <AccordionContractDetailRow
                rowProps={rowProps}
                isCompactMode={isCompactMode}
                items={refProducts.current || []}
                refetchContractData={async () => {
                  return;
                }}
              />
            )}
          />
          {refProducts.current.length < 1 && (
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              tokens={{ padding: "30px 0 42px 0" }}
              style={{
                borderBottom: `1px solid ${theme.palette.neutralLight}`
              }}
            >
              <Text variant="xLarge" style={{ textAlign: "center" }}>
                Adicione algum benefício combo a lista.
              </Text>
            </Stack>
          )}
        </StyledStep>
      </Card>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={edit}
        enableReinitialize
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => {
          return (
            <>
              {isSubmitting ? (
                <Loader />
              ) : (
                <Form>
                  <Stack
                    tokens={{ childrenGap: 15 }}
                    styles={{ root: { marginTop: 20 } }}
                  >
                    <Card>
                      <StyledStep>
                        <div className="ms-Grid" dir="ltr">
                          <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-lg3">
                              <h2 className="ms-fontSize-20">Entrega</h2>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-lg4">
                              <FieldsBuilder
                                {...{
                                  fields: [
                                    {
                                      id: "select-shipping-price",
                                      label: "Selecione um método de entrega",
                                      type: "select",
                                      name: "shippingPrice",
                                      value: values.shippingPrice,
                                      options: shippingPrices,
                                      required: true,
                                      disabled: shippingPrices?.length < 2
                                    }
                                  ],
                                  errors,
                                  touched,
                                  setFieldValue,
                                  values
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </StyledStep>
                    </Card>
                    <Stack.Item align="center">
                      <PrimaryButton
                        id="submit-button-step-3"
                        text={isSubmitting ? "Processando..." : "Avançar"}
                        type="submit"
                        disabled={isSubmitting || products?.length < 1}
                      />
                    </Stack.Item>
                  </Stack>
                </Form>
              )}
            </>
          );
        }}
      </Formik>

      <CustomDrawer
        isOpen={drawerState.isOpen}
        action={drawerState.action}
        module={DrawerModules.PRODUCT}
        onCloseDrawer={closeDrawer}
        size="large"
        drawerContainerRef={drawerContainerRef}
      >
        {drawerState.isOpen &&
          contract.serviceCategory?.type !==
            ServicesCategoriesTypesEnum.TAG && (
            <InsertItem
              parcelLimit={getLimitParcel(products)}
              contract={contract}
              close={closeDrawer}
              drawerContainerRef={drawerContainerRef}
            />
          )}

        {drawerState.isOpen &&
          contract.serviceCategory?.type ===
            ServicesCategoriesTypesEnum.TAG && (
            <InsertItemTag
              contract={contract}
              close={closeDrawer}
              drawerContainerRef={drawerContainerRef}
            />
          )}
      </CustomDrawer>
    </Stack>
  );
}

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}

export default Step3;
