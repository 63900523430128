export const isAccountFilled = (
  currentContract,
  selectedBU,
  PaymentMethod
): boolean => {
  const { account, accountDocument, accountContact, checkingAccount } =
    currentContract || {};

  const isDebitoAutomatico =
    selectedBU.paymentMethod === PaymentMethod.DEBITO_AUTOMATICO;

  return (
    account &&
    accountDocument &&
    accountContact &&
    (!isDebitoAutomatico || checkingAccount)
  );
};

export const doesCurrentContractHaveItems = (currentContract): boolean => {
  return currentContract.items?.length > 0;
};
