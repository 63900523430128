import { CommandBar, Stack } from "@fluentui/react";
import { useCallback } from "react";

import { IStep, ITerm } from "../../interfaces/ITerm.interface";
import { buildCommandBarItems } from "../../utils/buildCommandBarItems";

import { TermInfoContainer } from "./styles";

import { contentStyles } from "@/components/Contracts/ViewMenu/styles";
import { useGetProfile } from "@/core/libs/api/react-query";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { ContractBadgeColors } from "@/modules/Contracts/enums";
import { Badge } from "@/modules/Contracts/styles";

interface ITermsViewMenu {
  term: ITerm;
  currentStep: IStep;
  setOccurrencesDrawerVisible: (state: boolean) => void;
  refetchTerm(): void;
  setIsLoading(boolean): void;
}

export const TermsViewMenu = ({
  term,
  currentStep,
  setOccurrencesDrawerVisible,
  refetchTerm,
  setIsLoading
}: ITermsViewMenu): JSX.Element => {
  const userProfile = useGetProfile();
  const permissions = userProfile.data?.data?.role.permissions;
  const { execute } = useApi();

  const advanceStep = useCallback(async () => {
    setIsLoading(true);
    const bodyObj = Object({
      termId: term?.id,
      interactionAction: term?.currentStep.interactionAction ?? undefined,
      account: {
        id: term?.account.id,
        accountType: term?.account?.accountType,
        fullName: term?.account?.fullName,
        maritalStatusId: term?.account?.maritalStatusId,
        occupation: term?.account?.occupation,
        birthDate: term?.account?.birthDate,
        documents: term?.account?.documents,
        contacts: term?.account?.contacts,
        legalRepresentatives: term?.account?.legalRepresentatives
      }
    });

    const processStep = await execute({
      url: `terms/process`,
      bodyObj,
      method: "POST",
      showAlert: true
    });

    if (processStep.response.status === 200) {
      refetchTerm();
    }

    setIsLoading(false);
  }, [
    setIsLoading,
    term?.id,
    term?.currentStep.interactionAction,
    term?.account,
    execute,
    refetchTerm
  ]);

  const cancelOrRejectTerm = useCallback(
    async (actionName: string) => {
      setIsLoading(true);
      const bodyObj = Object({
        termId: term?.id,
        interactionAction: actionName
      });

      const processStep = await execute({
        url: `terms/process`,
        bodyObj,
        method: "POST"
      });

      if (processStep.response.status === 200) {
        refetchTerm();
      }
      setIsLoading(false);
    },
    [setIsLoading, term?.id, execute, refetchTerm]
  );

  const downloadContract = useCallback(async () => {
    const requestParams = {
      id: term?.id
    };

    const res = await execute({
      url: `terms/${term?.id}/pdf`,
      params: requestParams
    });

    if (res.data) {
      window.open(res.data, "_blank");
    }
  }, [term?.id, execute]);

  const commandBarItems = buildCommandBarItems({
    term,
    downloadContract,
    permissions,
    setOccurrencesDrawerVisible,
    advanceStep,
    cancelOrRejectTerm
  });

  return (
    <Stack
      className={contentStyles.stackContainer}
      tokens={{ childrenGap: 10 }}
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
    >
      <TermInfoContainer>
        <h2 className="ms-fontSize-24">Termo #{term?.id}</h2>
        <Badge bgColor={ContractBadgeColors[currentStep?.internalName]}>
          {currentStep?.friendlyName}
        </Badge>
      </TermInfoContainer>
      <CommandBar
        items={commandBarItems}
        styles={{
          root: {
            padding: 0
          }
        }}
      />
    </Stack>
  );
};
