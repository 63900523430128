import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class Offers extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/offers${subPaths}` : `offers${subPaths}`);
    this.createBanner = this.createBanner.bind(this);
    this.editBanner = this.editBanner.bind(this);
    this.deleteOffer = this.deleteOffer.bind(this);
    this.deleteOfferPricingGroup = this.deleteOfferPricingGroup.bind(this);
    this.deleteOfferProduct = this.deleteOfferProduct.bind(this);
  }

  createBanner({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: this.baseUrl
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  editBanner({ params, signal }: IApi) {
    let id = null;
    if (params.body.id && !params.id) {
      id = params.body.id;
    } else {
      id = params.id;
    }
    const options: any = {
      signal,
      method: "PATCH",
      baseUrl: `${this.baseUrl}/${id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  updateMassOffersValidity({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/offers-prices/massive-validity`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  deleteOffer({ params, signal }: IApi) {
    let id = null;
    if (params.body.id && !params.id) {
      id = params.body.id;
    } else {
      id = params.id;
    }
    const options: any = {
      signal,
      method: "DELETE",
      baseUrl: `${this.baseUrl}/${id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  deleteOfferPricingGroup({ params, signal }: IApi) {
    const { id } = params;

    const options: any = {
      signal,
      method: "DELETE",
      baseUrl: `${this.baseUrl}/offers-prices/${id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }

  deleteOfferProduct({ params, signal }: IApi) {
    const { id } = params;

    const options: any = {
      signal,
      method: "DELETE",
      baseUrl: `${this.baseUrl}/sales-conditions/${id}`
    };

    if (params.body) options.bodyObj = params.body;
    return makeRequest(options);
  }
}

export default Offers;
