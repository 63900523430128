export enum ProfileActionsTypes {
  DISMISS_MESSAGE = "profile/DISMISS_MESSAGE",
  DISMISS_PROFILE = "profile/DISMISS_PROFILE",
  EDIT_PROFILE_REQUEST = "profile/EDIT_PROFILE_REQUEST",
  EDIT_PROFILE_FAIL = "profile/EDIT_PROFILE_FAIL",
  EDIT_PROFILE_SUCCESS = "profile/EDIT_PROFILE_SUCCESS",
  GET_PROFILE_REQUEST = "profile/GET_PROFILE_REQUEST",
  GET_PROFILE_FAIL = "profile/GET_PROFILE_FAIL",
  GET_PROFILE_SUCCESS = "profile/GET_PROFILE_SUCCESS",
  SELECTBU_REQUEST = "profile/SELECTBU_REQUEST",
  SELECTBU_FAIL = "profile/SELECTBU_FAIL",
  SELECTBU_SUCCESS = "profile/SELECTBU_SUCCESS"
}
