import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createOrganizationsFailure,
  createOrganizationsSuccess,
  editOrganizationsFailure,
  editOrganizationsSuccess,
  listOrganizationsFailure,
  listOrganizationsRequest,
  listOrganizationsSuccess
} from "../actions/organizations";
import { OrganizationsActionsTypes } from "../types/Organizations.reducer.types";

import { SagaCustomError } from "@/common/types";
import { OrganizationsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createOrganizations({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = OrganizationsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.create, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createOrganizationsSuccess({
        message: `Organização criada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listOrganizationsRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      createOrganizationsFailure({
        code: error?.statusCode,
        message: "Não foi possível criar a organização com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editOrganizations({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = OrganizationsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.edit, {
      params: { body: { ...values } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editOrganizationsSuccess({
        message: `Organização editada com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listOrganizationsRequest(undefined, { isAdmin }));
  } catch (error) {
    yield put(
      editOrganizationsFailure({
        code: error?.statusCode,
        message:
          "Não foi possível editar a organização com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listOrganizations({ payload }: AnyAction) {
  const { filter, isAdmin } = payload;
  const service = OrganizationsService(undefined, isAdmin);

  try {
    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listOrganizationsSuccess({ list: response.data }));
  } catch (error) {
    yield put(listOrganizationsFailure());
  }
}

export default all([
  takeLatest(OrganizationsActionsTypes.CREATE_REQUEST, createOrganizations),
  takeLatest(OrganizationsActionsTypes.EDIT_REQUEST, editOrganizations),
  takeLatest(OrganizationsActionsTypes.LIST_REQUEST, listOrganizations)
]);
