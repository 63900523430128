import { Icon, Separator, Stack } from "@fluentui/react";

import { renderPrice } from "../../utils/renderPrice";
import { PlanTypeEnum } from "../../utils/renderPrice/enums/planTypeEnum";

import { CarrierLogo, TagOfferContainer, TagPlanName } from "./styles";

import { Plan, PlanOfferItem } from "@/modules/Offers/types/PlansOffers.types";

type PlanOfferProps = {
  selectedPlan: Plan;
  selectedCarrier: PlanOfferItem;
};

export function TagOffer({
  selectedPlan,
  selectedCarrier
}: PlanOfferProps): JSX.Element {
  return (
    <TagOfferContainer
      tokens={{ padding: 20 }}
      verticalAlign="center"
      horizontalAlign="center"
    >
      <div>
        <CarrierLogo
          src={selectedCarrier.logo}
          alt={selectedCarrier.name}
          height="30"
        />

        <TagPlanName>{selectedPlan?.name}</TagPlanName>

        {selectedPlan?.voice && (
          <Stack tokens={{ childrenGap: 15, padding: 20 }}>
            <Stack horizontal verticalAlign="center">
              <Icon
                iconName="Car"
                style={{
                  fontSize: 20,
                  marginRight: 15
                }}
              />
              <span className="ms-fontSize-18">{selectedPlan.voice}</span>
            </Stack>
          </Stack>
        )}

        <Stack.Item styles={{ root: { width: "90%" } }}>
          <Separator style={{ width: "100%" }} />
        </Stack.Item>

        {renderPrice({
          price: selectedPlan?.offer?.offerPrice?.price,
          discount: selectedPlan?.offer?.discount?.value,
          discountNumberOfMonths: selectedPlan?.offer?.discount?.numberOfMonths,
          beginsAt: selectedPlan?.offer?.discount?.beginsAt,
          type: PlanTypeEnum.TAG
        })}
      </div>
    </TagOfferContainer>
  );
}
