import { makeRequest } from "../utils/fetchData";

import Api, { IApi } from "./api";

class Tags extends Api {
  constructor(subPaths: string) {
    super(`tags${subPaths}`);
  }

  listVehiclesGroups({ params, signal }: IApi) {
    const options: any = {
      signal,
      method: "GET",
      baseUrl: `${this.baseUrl}/vehicles/groups`
    };

    return makeRequest(options);
  }
}

export default Tags;
