export enum PermissionsScope {
  ADMIN = "admin",
  POS = "pos"
}

export interface Permission {
  actions: PermissionsAction[];
  id: number;
  module: PermissionsAdminModule | PermissionsPosModule;
  scope: PermissionsScope;
}

export enum PermissionsPosModule {
  ACCOUNTS = "accounts",
  CONTRACTS = "contracts",
  DASHBOARDS = "dashboards",
  LINES = "lines",
  OPERATIONS = "operations",
  USERS = "users",
  DASHBOARD_TAGS = "dashboards-tags",
  MEMBERS = "members",
  TERMS = "terms"
}

export enum PermissionsAdminModule {
  ACCOUNTS = "accounts",
  BUSINESS_UNITS = "business-units",
  CARRIERS = "carriers",
  CONTRACTS_CREDIT_FEES = "contracts-credit-fees",
  CONTRACTS_CREDIT_FEES_GROUPS = "contracts-credit-fees-groups",
  CONTRACTS_MODELS = "contracts-models",
  CONTRACTS_MODELS_ATTACHMENTS = "contracts-models-attachments",
  SERVICE_MODEL_OPTIONS_GROUP = "service-model-options-group",
  INVENTORIES = "inventories",
  INVENTORIES_DISTRIBUTION_CENTERS = "inventories-distribution-centers",
  INVENTORIES_OCCURRENCES = "inventories-occurrences",
  LINES = "lines",
  LINES_OCCURRENCES = "lines-occurrences",
  OFFERS = "offers",
  OFFERS_BANNERS = "offers-banners",
  OFFERS_PRICES = "offers-prices",
  OFFERS_SALES_CONDITIONS = "offers-sales-conditions",
  OPERATIONS = "operations",
  ORGANIZATIONS = "organizations",
  SALES_TEAMS = "sales-teams",
  SERVICE_PROVIDERS = "service-providers",
  PLANS = "plans",
  PERMISSIONS = "permissions",
  PRICING_GROUPS = "pricing-groups",
  PRODUCTS = "products",
  PRODUCTS_BRANDS = "products-brands",
  PRODUCTS_CATEGORIES = "products-categories",
  ROLES = "roles",
  REPORTS = "reports",
  REPORTS_CATEGORIES = "reports-categories",
  SERVICES = "services",
  SERVICES_CATEGORIES = "services-categories",
  SHIPPINGS = "shippings",
  SHIPPINGS_GROUPS = "shippings-groups",
  SHIPPINGS_TYPES = "shippings-types",
  USERS = "users",
  ELECTRONIC_SIGNATURES = "electronic-signatures",
  EMAIL_TEMPLATE_GROUP = "email-template-group",
  EMAIL_TEMPLATE = "email-template",
  EMAIL_TRIGGER = "email-trigger",
  ADDITIONAL = "additional",
  ADDITIONAL_CATEGORY = "additional-category"
}

export enum PermissionsAction {
  ALL = "*",
  APPROVE_COMMERCIALLY = "approve-commercially",
  APPROVE_CREDIT = "approve-credit",
  APPROVE_DOCUMENT = "approve-document",
  APPROVE_REPLACEMENT = "approve-replacement",
  AUDIT = "audit",
  CANCEL = "cancel",
  CONTRACT_DELIVERED = "contract-delivered",
  CONTRACT_OCCURRENCES = "contract-occurrences",
  CREATE = "create",
  CREATE_BULK = "create-bulk",
  DELETE = "delete",
  DELIVER = "deliver",
  DELIVER_PRODUCT = "deliver-product",
  DELIVER_SERVICE = "deliver-service",
  DONE = "done",
  LIST = "list",
  OVERWRITE_CONTRACT = "overwrite-contract",
  OVERWRITE_SALES_TEAM = "overwrite-sales-team",
  PATCH_NOTE = "patch-note",
  READ = "read",
  RECOVERY_PASSWORD = "recovery-password",
  REJECT = "reject",
  RESEND_SIGNATURES = "resend-signatures",
  SEARCH = "search",
  SEND_DOCUMENT = "send-document",
  SYSTEM_ALERT = "system-alert",
  TRANSFER = "transfer",
  UPDATE = "update",
  VIEW_ALL = "view-all",
  WELCOME = "welcome",
  INTEGRATE = "integrate"
}

export enum PermissionsActionTranslated {
  "*" = "Todas",
  APPROVE_COMMERCIALLY = "Aprovar Comercialmente",
  APPROVE_CREDIT = "Aprovar Crédito",
  APPROVE_DOCUMENT = "Aprovar Documento",
  APPROVE_REPLACEMENT = "Aprovar Substituição",
  AUDIT = "Auditar",
  CANCEL = "Cancelar",
  CONTRACT_DELIVERED = "Liberar benefícios para o cooperado",
  CONTRACT_OCCURRENCES = "Ocorrências de Contrato",
  CREATE = "Criar",
  CREATE_BULK = "Criar em massa",
  DELETE = "Deletar",
  DELIVER = "Entregar",
  DELIVER_PRODUCT = "Entregar Benefícios combos",
  DELIVER_SERVICE = "Entregar Liberação de Benefícios",
  DONE = "Concluir",
  LIST = "Listar",
  OVERWRITE_CONTRACT = "Sobrescrever contrato",
  OVERWRITE_SALES_TEAM = "Atualizar times Comerciais",
  PATCH_NOTE = "Emitir alerta de atualização do sistema",
  READ = "Visualizar",
  RECOVERY_PASSWORD = "Recuperar senha",
  REJECT = "Rejeitar",
  RESEND_SIGNATURES = "Reenviar Assinaturas",
  SEARCH = "Buscar",
  SEND_DOCUMENT = "Enviar Documento",
  SYSTEM_ALERT = "Emitir alerta de sistema",
  TRANSFER = "Transferir",
  UPDATE = "Atualizar",
  VIEW_ALL = "Visualizar Todos",
  WELCOME = "Enviar mensagem de Bem-vindo",
  INTEGRATE = "Integralizar"
}

export const selectableScope = [
  { key: PermissionsScope.ADMIN, text: "Painel Administrativo" },
  { key: PermissionsScope.POS, text: "Ponto de escolha de benefícios" }
];

export const selectableActions: { key: PermissionsAction; text: string }[] =
  Object.keys(PermissionsAction).map(key => ({
    key: PermissionsAction[key as keyof typeof PermissionsAction],
    text:
      key === "ALL"
        ? PermissionsActionTranslated["*"]
        : PermissionsActionTranslated[
            key as keyof typeof PermissionsActionTranslated
          ]
  }));
