import { InventoriesOccurrencesActionsTypes } from "../types/InventoriesOccurrences.reducer.types";

export function listInventoriesOccurrencesRequest(filter, { isAdmin = false }) {
  return {
    type: InventoriesOccurrencesActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listInventoriesOccurrencesSuccess({ list }) {
  return {
    type: InventoriesOccurrencesActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listInventoriesOccurrencesFailure() {
  return {
    type: InventoriesOccurrencesActionsTypes.LIST_FAIL
  };
}
