export function convertCurrencyStringToNumber(value) {
  if (typeof value === "number") {
    return value;
  }

  if (typeof value === "string") {
    const numberValue = parseFloat(value.replace(/[^\d.-]/g, ""));
    return isNaN(numberValue)
      ? parseFloat(value.replace(/\./g, "").replace(/,/g, "."))
      : numberValue;
  }

  return 0;
}
