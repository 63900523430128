import { ListOfProductsCategories } from "./ProductsCategories.types";

import { IMessageBar } from "@/core/libs/message-bar";

export enum ProductsCategoriesActionsTypes {
  CREATE_REQUEST = "products-categories/CREATE_REQUEST",
  CREATE_FAIL = "products-categories/CREATE_FAIL",
  CREATE_SUCCESS = "products-categories/CREATE_SUCCESS",
  DISMISS_MESSAGE = "products-categories/DISMISS_MESSAGE",
  EDIT_REQUEST = "products-categories/EDIT_REQUEST",
  EDIT_FAIL = "products-categories/EDIT_FAIL",
  EDIT_SUCCESS = "products-categories/EDIT_SUCCESS",
  LIST_REQUEST = "products-categories/LIST_REQUEST",
  LIST_FAIL = "products-categories/LIST_FAIL",
  LIST_SUCCESS = "products-categories/LIST_SUCCESS"
}

export interface ProductsCategoriesState {
  isLoadingList: boolean;
  list?: ListOfProductsCategories;
  message?: IMessageBar;
}

export interface CreateRequest {
  type: ProductsCategoriesActionsTypes.CREATE_REQUEST;
}

export interface CreateError {
  type: ProductsCategoriesActionsTypes.CREATE_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface CreateSuccess {
  type: ProductsCategoriesActionsTypes.CREATE_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface DismissMessage {
  type: ProductsCategoriesActionsTypes.DISMISS_MESSAGE;
}

export interface EditRequest {
  type: ProductsCategoriesActionsTypes.EDIT_REQUEST;
}

export interface EditError {
  type: ProductsCategoriesActionsTypes.EDIT_FAIL;
  payload: {
    message: IMessageBar;
  };
}

export interface EditSuccess {
  type: ProductsCategoriesActionsTypes.EDIT_SUCCESS;
  payload: {
    message: IMessageBar;
  };
}

export interface ListRequest {
  type: ProductsCategoriesActionsTypes.LIST_REQUEST;
}

export interface ListError {
  type: ProductsCategoriesActionsTypes.LIST_FAIL;
}

export interface ListSuccess {
  type: ProductsCategoriesActionsTypes.LIST_SUCCESS;
  payload: {
    list: ListOfProductsCategories;
  };
}

export type ProductsCategoriesReducerActions =
  | CreateRequest
  | CreateError
  | CreateSuccess
  | DismissMessage
  | EditRequest
  | EditError
  | EditSuccess
  | ListRequest
  | ListError
  | ListSuccess;
