const SICOOB_SEQUENCE = [9, 8, 7, 6, 5, 4, 3, 2];

/** Check if an account number is from Sicoob. */
export function checkSicoobAccount(accountNumber: string) {
  const accountNumberExpanded = Array.from(
    accountNumber.padStart(9, "0"),
    Number
  );
  const [DV] = accountNumberExpanded.splice(-1);

  const operation = accountNumberExpanded
    .map((digit, index) => digit * SICOOB_SEQUENCE[index])
    .reduce((previous, current) => previous + current, 0);

  const calculatedDV = 11 - (operation % 11);

  return DV === (calculatedDV >= 10 ? 0 : calculatedDV);
}
