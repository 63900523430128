import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CardTitle = styled.h4`
  color: rgb(57, 92, 163);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 2rem;
`;

export const LoadIcon = styled.div`
  animation: is-rotating 0.8s infinite;
  width: 30px;
  height: 30px;
  border: 6px solid #ffffff;
  border-top-color: rgb(57, 92, 163);
  border-radius: 50%;
  margin: 20px 0 5px 0;
  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }
`;
