import { DatePicker as FDatePicker } from "@fluentui/react";
import moment from "moment";
import { useEffect, useState } from "react";

import { Space } from "../../../../../components/Space";
import { FilterComponentProps } from "../types";

import { formatDate } from "@/utils/FormatDate";

const onFormatDate = (date?: Date): string => {
  return !date ? "" : formatDate(date.toString());
};

export function DatePicker({
  setSelectedKeys,
  _paginate,
  filterConfigs,
  debouncedTerms,
  selectedKeys,
  itemsPerPage
}: FilterComponentProps): JSX.Element {
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);

  useEffect(() => {
    const filterQuery = selectedKeys[filterConfigs.filterQuery as string];

    if (!filterQuery && selectedDate) setSelectedDate(null);
  }, []);

  useEffect(() => {
    setSelectedDate(selectedDate);
  }, [selectedDate]);

  function handleDateChange(dateValue: Date) {
    const currentKeys = Object.assign({}, selectedKeys);
    const { filterQuery } = filterConfigs;

    if (dateValue) {
      const momentValue = moment(dateValue);
      currentKeys[filterQuery as string] = momentValue.format("DD/MMMM/YYYY");
      setSelectedDate(momentValue);
    } else if (selectedDate) {
      delete currentKeys[filterQuery as string];
      setSelectedDate(null);
    }
    setSelectedKeys(currentKeys);
    _paginate({
      limit: itemsPerPage ?? 10,
      ...debouncedTerms,
      ...currentKeys
    });
  }

  return (
    <Space customMargin="center">
      <FDatePicker
        disabled={filterConfigs.disabled ? true : false}
        label={filterConfigs.label}
        placeholder={filterConfigs.placeholder}
        onSelectDate={handleDateChange as (date?: Date) => void}
        value={selectedDate?.toDate()}
        formatDate={onFormatDate}
        allowTextInput
        styles={{ statusMessage: { display: "none" } }}
        style={{ display: "flex", alignItems: "center" }}
      />
    </Space>
  );
}
