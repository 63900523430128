import { OffersActionsTypes } from "../types/Offers.reducer.types";

export function createRequest(
  values,
  { setSubmitting, isAdmin = false },
  redirectFunction?
) {
  return {
    type: OffersActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, redirectFunction, isAdmin }
  };
}

export function createSuccess(message) {
  return {
    type: OffersActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createFailure(message) {
  return {
    type: OffersActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function createOfferPricingGroupRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel,
  refetchList
) {
  return {
    type: OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_REQUEST,
    payload: { values, setSubmitting, isAdmin, closePanel, refetchList }
  };
}

export function createOfferPricingGroupSuccess(message) {
  return {
    type: OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_SUCCESS,
    payload: { message }
  };
}

export function createOfferPricingGroupFailure(message) {
  return {
    type: OffersActionsTypes.CREATE_OFFER_PRICING_GROUP_FAIL,
    payload: { message }
  };
}

export function editOfferPricingGroupRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel,
  refetchList
) {
  return {
    type: OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_REQUEST,
    payload: { values, setSubmitting, isAdmin, closePanel, refetchList }
  };
}

export function editOfferPricingGroupSuccess(message) {
  return {
    type: OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_SUCCESS,
    payload: { message }
  };
}

export function editOfferPricingGroupFailure(message) {
  return {
    type: OffersActionsTypes.EDIT_OFFER_PRICING_GROUP_FAIL,
    payload: { message }
  };
}

export function deleteOfferPricingGroupRequest({
  pricingGroupId,
  setSubmitting,
  isAdmin = false,
  refetchList
}) {
  return {
    type: OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_REQUEST,
    payload: { pricingGroupId, setSubmitting, isAdmin, refetchList }
  };
}

export function deleteOfferPricingGroupSuccess(message) {
  return {
    type: OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_SUCCESS,
    payload: { message }
  };
}

export function deleteOfferPricingGroupFailure(message) {
  return {
    type: OffersActionsTypes.DELETE_OFFER_PRICING_GROUP_FAIL,
    payload: { message }
  };
}

export function createOfferProductRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel,
  refetchList
) {
  return {
    type: OffersActionsTypes.CREATE_OFFER_PRODUCT_REQUEST,
    payload: { values, setSubmitting, isAdmin, closePanel, refetchList }
  };
}

export function createOfferProductSuccess(message) {
  return {
    type: OffersActionsTypes.CREATE_OFFER_PRODUCT_SUCCESS,
    payload: { message }
  };
}

export function createOfferProductFailure(message) {
  return {
    type: OffersActionsTypes.CREATE_OFFER_PRODUCT_FAIL,
    payload: { message }
  };
}

export function deleteOfferProductRequest({
  offerProductId,
  setSubmitting,
  isAdmin = false,
  refetchList
}) {
  return {
    type: OffersActionsTypes.DELETE_OFFER_PRODUCT_REQUEST,
    payload: { offerProductId, setSubmitting, isAdmin, refetchList }
  };
}

export function deleteOfferProductSuccess(message) {
  return {
    type: OffersActionsTypes.DELETE_OFFER_PRODUCT_SUCCESS,
    payload: { message }
  };
}

export function deleteOfferProductFailure(message) {
  return {
    type: OffersActionsTypes.DELETE_OFFER_PRODUCT_FAIL,
    payload: { message }
  };
}

export function editRequest(values, { setSubmitting, isAdmin = false }) {
  return {
    type: OffersActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, isAdmin }
  };
}

export function editSuccess(message) {
  return {
    type: OffersActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editFailure(message) {
  return {
    type: OffersActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function deleteOfferRequest({
  offerId,
  isAdmin = false,
  redirectFunction,
  setSubmitting
}) {
  return {
    type: OffersActionsTypes.DELETE_OFFER_REQUEST,
    payload: { offerId, setSubmitting, isAdmin, redirectFunction }
  };
}

export function deleteOfferSuccess() {
  return {
    type: OffersActionsTypes.DELETE_OFFER_SUCCESS
  };
}

export function deleteOfferFailure(message) {
  return {
    type: OffersActionsTypes.DELETE_OFFER_FAIL,
    payload: { message }
  };
}

export function listRequest(filter, { isAdmin = false }) {
  return {
    type: OffersActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listSuccess({ list }) {
  return {
    type: OffersActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: OffersActionsTypes.LIST_FAIL
  };
}

export function listOfferPriceRequest(filter, { isAdmin = false, id }) {
  return {
    type: OffersActionsTypes.LIST_OFFER_PRICE_REQUEST,
    payload: { filter, isAdmin, id }
  };
}

export function listOfferPriceSuccess({ list }) {
  return {
    type: OffersActionsTypes.LIST_OFFER_PRICE_SUCCESS,
    payload: { list }
  };
}

export function listOfferPriceFailure() {
  return {
    type: OffersActionsTypes.LIST_OFFER_PRICE_FAIL
  };
}

export function dismissMessage() {
  return {
    type: OffersActionsTypes.DISMISS_MESSAGE
  };
}
