import { PermissionsActionsTypes } from "../types/Permissions.reducer.types";

export function createRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: PermissionsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createSuccess(message) {
  return {
    type: PermissionsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createFailure(message) {
  return {
    type: PermissionsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: PermissionsActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editSuccess(message) {
  return {
    type: PermissionsActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editFailure(message) {
  return {
    type: PermissionsActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listRequest(filter, { isAdmin = false }) {
  return {
    type: PermissionsActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listSuccess({ list }) {
  return {
    type: PermissionsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: PermissionsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: PermissionsActionsTypes.DISMISS_MESSAGE
  };
}
