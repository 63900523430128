import { DefaultButton, IButtonProps } from "@fluentui/react/lib/Button";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";

import { initFreshChat } from "../FreshChat";
import {
  IFcWidget,
  IUserInfo
} from "../FreshChat/interfaces/fresh-chat.interface";

import { StyledHelpButton } from "./styles";

import { FreshChatService } from "@/core/libs/api";
import { useConfirmModal } from "@/hooks/useConfirmModal";
import { useSelectedBu } from "@/hooks/useSelectedBu";

declare global {
  interface Window {
    fcWidget: IFcWidget;
  }
}

interface HelpButtonProps {
  toggleFocusTrapZone: VoidFunction;
  isFocusZoneDisabled: boolean;
  userInfo: IUserInfo;
}
const service = FreshChatService(undefined);
const abortController = new AbortController();
const { signal } = abortController;

function HelpButton({ userInfo }: HelpButtonProps): JSX.Element {
  const { openModalWithSettings } = useConfirmModal();
  const [isBubbleVisible, setIsBubbleVisible] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const { selectedBU } = useSelectedBu();
  useEffect(() => {
    if (!isPageLoaded) {
      setIsPageLoaded(true);
    }
  }, [isPageLoaded]);

  if (!isPageLoaded) {
    const businessUnitSelected = selectedBU?.parent?.name
      ? `${selectedBU?.name} (${selectedBU?.parent.name})`
      : selectedBU?.name;

    // eslint-disable-next-line no-inner-declarations
    async function getUserInfo() {
      const userInfoData = await service.get({
        signal,
        id: userInfo.id
      });

      if (userInfo) {
        initFreshChat({
          ...userInfo,
          businessUnitSelected,
          userInfoDatabase: userInfoData.data
        });
      }
    }

    if (userInfo) {
      getUserInfo();
    }
  }

  const chatIteraction = (): void => {
    window.fcWidget.open();
    setIsBubbleVisible(false);
  };

  const primaryButtonProps: IButtonProps = {
    children: "Chat Online",
    onClick: chatIteraction
  };

  const secondaryButtonProps: IButtonProps = {
    children: "WhatsApp",
    onClick: () => {
      const whatsAppNumberExist = selectedBU?.whatsAppNumber;
      const httpsWaMe = "https://wa.me/55";
      if (!whatsAppNumberExist) {
        return openModalWithSettings(
          "WhatsApp Indisponivel",
          "Esta unidade não possui WhatsApp cadastrado. Por favor, entrar em contato com o suporte.",
          () => {
            return;
          },
          { hidePrimaryButton: true, secondaryButtonText: "Fechar" }
        );
      }

      if (whatsAppNumberExist.includes(httpsWaMe)) {
        window.open(whatsAppNumberExist, "_blank");
      } else {
        window.open(httpsWaMe + whatsAppNumberExist, "_blank");
      }
    }
  };

  const toggleTeachingBubbleVisible = (): void => {
    if (isBubbleVisible) {
      setIsBubbleVisible(false);
    } else {
      setIsBubbleVisible(true);
    }
  };

  return (
    <Draggable>
      <StyledHelpButton>
        <TooltipHost
          content="Atendimento"
          id="tooltip"
          delay={0}
          calloutProps={{ gapSpace: 5 }}
          styles={{
            root: { display: "inline-block" }
          }}
          tooltipProps={{
            styles: {
              subText: { fontSize: 14 }
            }
          }}
        >
          <DefaultButton
            id="targetButton"
            aria-describedby="tooltip"
            onClick={toggleTeachingBubbleVisible}
            iconProps={{
              iconName: "OfficeChatSolid",
              style: {
                color: "#fff",
                fontSize: 30
              }
            }}
            styles={{
              flexContainer: { flexDirection: "column" },
              root: {
                width: 65,
                minWidth: 65,
                minHeight: 65,
                height: 65,
                borderRadius: "50%",
                borderColor: "#395CA3",
                background: "#395CA3"
              },
              rootHovered: {
                background: "#2b467c"
              },
              rootPressed: {
                background: "#395CA3"
              }
            }}
          />
        </TooltipHost>

        {isBubbleVisible && (
          <TeachingBubble
            target="#targetButton"
            primaryButtonProps={primaryButtonProps}
            secondaryButtonProps={secondaryButtonProps}
            onDismiss={toggleTeachingBubbleVisible}
            headline="Atendimento"
          >
            Nosso atendimento está disponível de segunda à sexta-feira, das
            08:30h às 20h e aos sábados das 08:30h às 18h.
          </TeachingBubble>
        )}
      </StyledHelpButton>
    </Draggable>
  );
}

export default HelpButton;
