import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  createFailure,
  createSuccess,
  deleteFailure,
  deleteSuccess,
  editFailure,
  editSuccess,
  listFailure,
  listRequest,
  listSuccess
} from "../actions/products";
import { ProductsActionsTypes } from "../types/Products.reducer.types";

import { SagaCustomError } from "@/common/types";
import { ProductsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";

const abortController = new AbortController();
const { signal } = abortController;

export function* createProduct({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ProductsService(undefined, isAdmin);

  //Deep copy of the object without reference
  const newValues = JSON.parse(JSON.stringify(values));

  if (values?.images) {
    newValues.images = values?.images;
  }

  const data = new FormData();

  if (newValues?.cameras?.length > 0) {
    newValues.specifications.cameras = newValues.cameras;
    delete newValues.cameras;
  }

  // Format sim object
  if (newValues.sims?.length > 0) {
    newValues.specifications.sims = newValues.sims;
    delete newValues.sims;
  }

  // Format connectivies object
  if (newValues?.specifications?.connectivies?.length > 0) {
    newValues.specifications.connectivies =
      newValues.specifications.connectivies.map(type => ({ network: type }));
  }

  if (newValues?.specifications) {
    newValues.specifications = JSON.stringify(newValues.specifications);
  }

  Object.keys(newValues).forEach(itemName => {
    if (newValues[itemName].length > 0 && Array.isArray(newValues[itemName])) {
      newValues[itemName].forEach(file => {
        data.append(itemName, file);
      });
    } else {
      data.append(itemName, newValues[itemName]);
    }
  });

  setSubmitting(true);

  try {
    const response = yield call(service.createProduct, {
      params: { body: data },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      createSuccess({
        message: `Benefício combo criado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, undefined, isAdmin));
  } catch (error) {
    yield put(
      createFailure({
        code: error?.statusCode,
        message: "Não foi possível criar o benefício com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* editProduct({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ProductsService(undefined, isAdmin);

  //Deep copy of the object without reference
  const newValues = JSON.parse(JSON.stringify(values));

  if (values?.images) {
    newValues.images = values?.images;
  }

  const data = new FormData();

  if (newValues?.cameras?.length > 0) {
    newValues.specifications.cameras = newValues.cameras;
    delete newValues.cameras;
  }

  // Format sim object
  if (newValues.sims?.length > 0) {
    newValues.specifications.sims = newValues.sims;
    delete newValues.sims;
  }

  // Format connectivies object
  if (newValues?.specifications?.connectivies?.length > 0) {
    newValues.specifications.connectivies =
      newValues.specifications.connectivies.map(type => ({ network: type }));
  }

  if (newValues?.images) {
    const objectImages = newValues.images.filter(
      image => !(image instanceof File)
    );

    newValues.images = newValues.images.filter(
      element => element instanceof File
    );

    newValues.images.push(JSON.stringify(objectImages));
  }

  if (newValues?.specifications) {
    newValues.specifications = JSON.stringify(newValues.specifications);
  }

  Object.keys(newValues).forEach(itemName => {
    if (newValues[itemName].length > 0 && Array.isArray(newValues[itemName])) {
      newValues[itemName].forEach(file => {
        data.append(itemName, file);
      });
    } else {
      data.append(itemName, newValues[itemName]);
    }
  });

  setSubmitting(true);

  try {
    const response = yield call(service.editProduct, {
      params: { body: data, id: values.id },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      editSuccess({
        message: `Benefício combo editado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, undefined, isAdmin));
  } catch (error) {
    yield put(
      editFailure({
        code: error?.statusCode,
        message: "Não foi possível editar o benefício com os dados informados",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* deleteProduct({ payload }: AnyAction) {
  const { values, setSubmitting, closePanel, isAdmin } = payload;
  const service = ProductsService(undefined, isAdmin);

  setSubmitting(true);

  try {
    const response = yield call(service.deleteProduct, {
      params: { body: {}, id: values.id },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;

      throw error;
    }

    yield put(
      deleteSuccess({
        message: `Benefício combo apagado com sucesso.`,
        type: messageBarTypes.SUCCESS
      })
    );

    if (closePanel) {
      setTimeout(() => {
        closePanel();
      }, 1000);
    }

    // REFRESH LIST
    yield put(listRequest(undefined, undefined, isAdmin));
  } catch (error) {
    yield put(
      deleteFailure({
        code: error?.statusCode,
        message: "Não foi possível apagar o benefício selecionado",
        type: messageBarTypes.ERROR
      })
    );
  }

  setSubmitting(false);
}

export function* listProducts({ payload }: AnyAction) {
  const { filter, hasFilter, isAdmin } = payload;
  const service = ProductsService(undefined, isAdmin);

  try {
    let params = { query: undefined };

    if (hasFilter) {
      params = { query: { page: 1, limit: 10 } };

      if (filter) {
        params.query = { ...params.query, ...filter };
      }
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listSuccess({ list: response.data }));
  } catch (error) {
    yield put(listFailure());
  }
}

export default all([
  takeLatest(ProductsActionsTypes.CREATE_REQUEST, createProduct),
  takeLatest(ProductsActionsTypes.EDIT_REQUEST, editProduct),
  takeLatest(ProductsActionsTypes.DELETE_REQUEST, deleteProduct),
  takeLatest(ProductsActionsTypes.LIST_REQUEST, listProducts)
]);
