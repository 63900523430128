import { PrimaryButton } from "@fluentui/react/lib/Button";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Stack } from "@fluentui/react/lib/Stack";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { SelectCategory } from "./SelectCategory";
import { SelectService } from "./SelectService";

import { Card } from "@/components/Shared/Card";
import { Loader } from "@/components/Shared/Loader";
import { ShowMessageCard } from "@/components/Shared/MessageBar";
import { ContractsService } from "@/core/libs/api";
import { messageBarTypes } from "@/core/libs/message-bar";
import { StyledStep } from "@/modules/Contracts/styles";
import { BusinessUnit, Profile } from "@/modules/User/types";

const schema = Yup.object().shape({
  serviceCategory: Yup.number().required("Obrigatório"),
  service: Yup.number().required("Obrigatório")
});

interface PropsStep1 {
  nextStep?: (data, step) => void;
  onSubmit?: () => void;
  user?: Profile;
  contract?: any;
  selectedBU?: BusinessUnit;
}

export function Step1({
  nextStep,
  onSubmit,
  user,
  selectedBU,
  contract
}: PropsStep1): JSX.Element {
  const [message, setMessage] = useState(undefined);
  const [servicesList, setServicesList] = useState([]);

  const history = useHistory();

  function cleanMessage() {
    setMessage(null);
  }

  const initialValues = {
    serviceCategory: "",
    service: ""
  };

  let defaultSelectedBU: number | null = null;
  const businessUnits: IDropdownOption[] = [];

  // Create
  if (user) {
    defaultSelectedBU = selectedBU?.id ? selectedBU?.id : user.businessUnit.id;

    user.businessUnits.forEach(bu => {
      businessUnits.push({
        key: bu.id,
        text: bu.name
      });
    });

    if (businessUnits.length === 0) {
      initialValues["businessUnit"] = user.businessUnit.id;
    } else {
      initialValues["businessUnit"] = defaultSelectedBU;
    }
  }

  // Edit
  if (contract) {
    initialValues["signatureType"] = contract.signature.type;
    initialValues["businessUnit"] = contract.businessUnit.id;
    businessUnits.push({
      key: contract.businessUnit.id,
      text: contract.businessUnit.name
    });
    initialValues["serviceCategory"] = contract.serviceCategory.id;
    initialValues["service"] = contract.service.id;
  }

  const createContract = (values, setSubmitting) => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const service = ContractsService();
    const params = {
      body: values,
      query: { businessActionId: selectedBU.id }
    };

    service.create({ params, signal }).then(response => {
      if (response.error) {
        setSubmitting(false);
        setMessage({
          message:
            response.error.message ??
            "Não foi possível criar o contrato, por favor atualize a página e tente novamente",
          type: messageBarTypes.ERROR
        });
      } else {
        const contract = response.data;
        history.push(`/contracts/${contract}/edit`);
      }
    });
  };

  const editContract = (values, setSubmitting) => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const service = ContractsService();
    const params = {
      body: values,
      id: contract.id
    };

    service.edit({ params, signal }).then(response => {
      if (response.error) {
        setSubmitting(false);
        setMessage({
          message:
            "Não foi possível editar o contrato, por favor atualize a página e tente novamente",
          type: messageBarTypes.ERROR
        });
      } else {
        setSubmitting(false);
        nextStep(response.data, 1);
      }
    });
  };

  const handleSubmit = (values, { setSubmitting }: FormikHelpers<any>) => {
    setSubmitting(true);
    if (onSubmit) {
      onSubmit();
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
    } else {
      if (contract) {
        editContract(values, setSubmitting);
      } else {
        createContract(values, setSubmitting);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={onSubmit ? null : schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ errors, touched, isSubmitting, setFieldValue, values }) => (
        <>
          {isSubmitting ? (
            <Loader />
          ) : (
            <Form>
              <Stack tokens={{ childrenGap: 20 }}>
                {/* message to user */}
                <ShowMessageCard
                  message={message}
                  cleanMessage={cleanMessage}
                />

                <Card>
                  <StyledStep>
                    <SelectCategory
                      businessUnit={values.businessUnit}
                      error={errors.serviceCategory}
                      touched={touched.serviceCategory}
                      setFieldValue={setFieldValue}
                      setServicesList={setServicesList}
                      selected={contract?.serviceCategory}
                    />
                  </StyledStep>
                </Card>

                <Card>
                  <StyledStep>
                    <SelectService
                      businessUnit={values.businessUnit}
                      serviceCategory={values.serviceCategory}
                      servicesList={servicesList}
                      error={errors.service}
                      touched={touched.service}
                      setFieldValue={setFieldValue}
                      selected={contract?.service}
                    />
                  </StyledStep>
                </Card>

                <Stack.Item align="center">
                  <PrimaryButton
                    id="submit-button"
                    data-testid="submit-button-step-1"
                    text={isSubmitting ? "Processando..." : "Avançar"}
                    type="submit"
                    disabled={isSubmitting}
                  />
                </Stack.Item>
              </Stack>
            </Form>
          )}
        </>
      )}
    </Formik>
  );
}
