import { Helmet } from "react-helmet";

import { useSelectedBu } from "@/hooks/useSelectedBu";

function Promotions(): JSX.Element {
  const { selectedBU } = useSelectedBu();

  return (
    <>
      <Helmet>
        <title>Resultado das Campanhas - Libertas</title>
      </Helmet>
      {selectedBU?.settings?.promotionsLink && (
        <iframe
          title="Resultado das Campanhas"
          src={selectedBU?.settings.promotionsLink}
          style={{ width: `100%`, height: `calc(100% - 10px)` }}
        ></iframe>
      )}
    </>
  );
}

export default Promotions;
