import styled from "styled-components";

export const Wrapper = styled.div<{
  color: string;
  backgroundColor: string;
  borderRadius: string;
  borderColor: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    width: fit-content;
    font-weight: 700;
    padding: 0.1rem 1rem;
    font-size: 0.8rem;
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    border-radius: ${props => props.borderRadius};
    border: ${props => (props.borderColor ? "0.1rem solid" : "")};
    border-color: ${props => props.borderColor};
  }
`;
