import { Redirect, Route } from "react-router-dom";

import { PermissionsAction } from "@/common/types/permissions";

interface PrivateRouteProps {
  children: React.ReactNode;
  permissionScope?: { [module: string]: string[] };
  module: string;
  action: string | string[];
  path?: Route["props"]["path"];
  exact?: Route["props"]["exact"];
}

function PrivateRoute({
  children,
  permissionScope,
  module,
  action,
  ...rest
}: PrivateRouteProps) {
  if (
    permissionScope?.[module]?.some(currentAction => {
      if (currentAction === PermissionsAction.ALL) {
        return true;
      }

      if (Array.isArray(action)) {
        return action.includes(currentAction);
      }

      return action === currentAction;
    })
  ) {
    return <Route {...rest}>{children}</Route>;
  }

  return <Redirect to="/" />;
}

export default PrivateRoute;
