import * as queryString from "query-string";

import { clearAuthKey, retrieveAuthKey, saveAuthKey } from "./auth";
import { MakeRequestOptions } from "./types";

interface CustomError extends Error {
  status?: number;
  statusText?: string;
}

export interface FetchResponse {
  response: {
    ok: boolean;
    status?: number;
  };
  data?: any;
  error?: {
    message: string;
    status: number;
    statusText: string;
  };
}

/** A wrapper to `fetch()` that handles authorization. */
const fetchData = async (
  url: Parameters<typeof fetch>[0],
  options?: Parameters<typeof fetch>[1]
): Promise<FetchResponse> => {
  const authKey = retrieveAuthKey();

  if (authKey) {
    const now = new Date();

    const accessTokenExpirationDate = new Date(
      authKey.accessTokenExpiration * 1000
    );
    const refreshTokenExpirationDate = new Date(
      authKey.refreshTokenExpiration * 1000
    );

    // if it needs a new accessToken
    if (accessTokenExpirationDate <= now) {
      try {
        // if it needs a new refreshToken
        if (refreshTokenExpirationDate <= now) {
          throw new Error("Unauthorized");
        }

        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/auth/refresh`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authKey.refreshToken}`
            }
          }
        );

        if (response.ok) {
          const { token, exp } = await response.json();

          authKey.accessToken = token;
          authKey.accessTokenExpiration = exp;

          saveAuthKey(authKey);
        } else {
          throw new Error("Unauthorized");
        }
      } catch (error) {
        clearTokenAndRedirect();
      }
    }

    // add Authorization header
    const headers = {
      authorization: `Bearer ${authKey.accessToken}`
    };

    if (!options) {
      options = { headers };
    } else if (!options.headers) {
      options.headers = headers;
    } else {
      options.headers = { ...options.headers, ...headers };
    }
  }

  try {
    const response = await fetch(url, options);

    const isJSON = response.headers
      .get("content-type")
      ?.includes("application/json");

    const data = isJSON ? await response.json() : await response.text();

    if (!response.ok) {
      const error: CustomError = new Error();
      error.status = response.status;
      error.message = data.message ?? data;
      error.statusText = response.statusText;

      throw error;
    }

    return {
      response: {
        ok: true,
        status: response.status
      },
      data
    };
  } catch (error) {
    if (error.status === 401) {
      clearTokenAndRedirect();
      return;
    }

    return {
      response: {
        ok: false
      },
      error: {
        message: error.message,
        status: error.status,
        statusText: error.statusText
      }
    };
  }
};

const removeEmpty = obj => {
  const o = JSON.parse(JSON.stringify(obj)); // Clone source object.

  Object.keys(o).forEach(key => {
    // Recursive.
    if (o[key] && typeof o[key] === "object") o[key] = removeEmpty(o[key]);
    // Delete undefined and null.
    else if (o[key] === undefined || o[key] === "") delete o[key];
    // Copy value.
    // eslint-disable-next-line
    else o[key] = o[key];
  });

  return o; // Return new object.
};

export const makeRequest = ({
  baseUrl,
  method,
  signal,
  queryParams = {},
  bodyObj = null,
  headers = null
}: MakeRequestOptions) => {
  const { url, query } = queryString.parseUrl(baseUrl);
  const newURL = queryString.stringifyUrl(
    { url, query: { ...query, ...queryParams } },
    { arrayFormat: "none", encode: false, skipNull: true }
  );
  const organizationLocal = localStorage.getItem("organization");
  const options: Parameters<typeof fetch>[1] = {
    method,
    signal,
    headers: {
      Accept: "application/json"
    }
  };

  if (organizationLocal) {
    options.headers["organization"] = JSON.parse(organizationLocal).id;
  }

  if (headers) {
    options.headers = { ...options.headers, ...headers };
  }
  if (bodyObj) {
    if (bodyObj instanceof FormData) {
      options.body = bodyObj;
    } else {
      options.body = JSON.stringify(removeEmpty(bodyObj));
      options.headers["Content-Type"] = "application/json; charset=UTF-8";
    }
  }

  return fetchData(newURL, options);
};

/** Clear the auth key and redirect to the login page. */
function clearTokenAndRedirect() {
  clearAuthKey();
  localStorage.removeItem("selectedBU");

  const url = new URL("login", window.location.origin);

  if (window.location.pathname !== "/login") {
    url.searchParams.set("origin", window.location.pathname);
  }

  window.location.href = url.toString();
}
