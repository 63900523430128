import { MessageBarType } from "@fluentui/react";
import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  listAccountFailure,
  listAccountRequest,
  listAccountSuccess
} from "../actions/accounts";
import { AccountActionsTypes } from "../types";

import { SagaCustomError } from "@/common/types";
import { AccountsService } from "@/core/libs/api";
import { formatDateStringToISOString } from "@/utils/FormatDateStringToISOString";

export function* createAccount({ payload }: AnyAction) {
  const {
    values,
    setIsSubmitting,
    businessActionId,
    setCreatedAccount,
    onFinish,
    addMessage
  } = payload;

  setIsSubmitting(true);

  if (values.addresses?.length > 0) {
    values.addresses = values.addresses.map(address => ({
      ...address,
      zipCode: address.zipCode.replace(/\D/g, "")
    }));
  }

  if (values.documents?.length > 0) {
    values.documents = values.documents.map(document =>
      document.documentType === "CPF" ||
      document.documentType === "CNPJ" ||
      document.documentType === "PIS_PASEP" ||
      document.documentType === "TITULO_ELEITOR"
        ? {
            ...document,
            documentNumber: document.documentNumber.replace(/\D/g, "")
          }
        : document
    );
  }

  if (values.contacts?.length > 0) {
    values.contacts = values.contacts.map(contact =>
      contact.contactType === "PHONE_NUMBER" ||
      contact.contactType === "WHATSAPP"
        ? {
            ...contact,
            contactType: contact.contactType.replace(/\D/g, "")
          }
        : contact
    );
  }

  if (values.legalRepresentatives?.length > 0) {
    values.legalRepresentatives = values.legalRepresentatives.map(
      representative => ({
        ...representative,
        document: representative.document.replace(/\D/g, ""),
        birthDate: formatDateStringToISOString(representative.birthDate)
      })
    );
  }

  if (values.checkingAccounts?.length > 0) {
    values.checkingAccounts = values.checkingAccounts.map(account => {
      account.accountNumber = account.accountNumber.replace(/\D/g, "");
      return account;
    });
  }

  if (values.birthDate) {
    values.birthDate = formatDateStringToISOString(values.birthDate);
  }

  try {
    const abortController = new AbortController();
    const { signal } = abortController;

    const service = AccountsService();

    const response = yield call(service.create, {
      params: { body: { ...values }, query: { businessActionId } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;
      error.message = response.error.message;
      throw error;
    }

    if (setCreatedAccount) {
      setCreatedAccount(response.data);
    }

    addMessage(MessageBarType.success, "Cooperado cadastrado com sucesso!");

    if (onFinish) {
      onFinish(response.data);
    }

    // REFRESH LIST
    yield put(listAccountRequest({ businessActionId }));
  } catch (error) {
    addMessage(
      MessageBarType.error,
      error?.message ??
        "Erro desconhecido, por favor, tente enviar os dados novamente."
    );
  }

  setIsSubmitting(false);
}

export function* editAccount({ payload }: AnyAction) {
  const { values, setIsSubmitting, businessActionId, onFinish, addMessage } =
    payload;

  setIsSubmitting(true);

  if (values.addresses?.length > 0) {
    values.addresses = values.addresses.map(address => ({
      ...address,
      zipCode: address.zipCode.replace(/\D/g, "")
    }));
  }

  if (values.documents?.length > 0) {
    values.documents = values.documents.map(document =>
      document.documentType === "CPF" ||
      document.documentType === "CNPJ" ||
      document.documentType === "PIS_PASEP" ||
      document.documentType === "TITULO_ELEITOR"
        ? {
            ...document,
            documentNumber: document.documentNumber.replace(/\D/g, "")
          }
        : document
    );
  }

  if (values.contacts?.length > 0) {
    values.contacts = values.contacts.map(contact =>
      contact.contactType === "PHONE_NUMBER" ||
      contact.contactType === "WHATSAPP"
        ? {
            ...contact,
            contactType: contact.contactType.replace(/\D/g, "")
          }
        : contact
    );
  }

  if (values.legalRepresentatives?.length > 0) {
    values.legalRepresentatives = values.legalRepresentatives.map(
      representative => ({
        ...representative,
        document: representative.document.replace(/\D/g, ""),
        birthDate: formatDateStringToISOString(representative.birthDate)
      })
    );
  }

  if (values.checkingAccounts?.length > 0) {
    values.checkingAccounts = values.checkingAccounts.map(account => {
      account.accountNumber = account.accountNumber.replace(/\D/g, "");
      return account;
    });
  }

  if (values.birthDate) {
    values.birthDate = formatDateStringToISOString(values.birthDate);
  }

  try {
    const abortController = new AbortController();
    const { signal } = abortController;

    const service = AccountsService();

    const response = yield call(service.edit, {
      params: { body: { ...values }, query: { businessActionId } },
      signal
    });

    if (response.error) {
      const error: SagaCustomError = new Error();
      error.statusCode = response.error.status;
      error.message = response.error.message;

      throw error;
    }

    addMessage(MessageBarType.success, "Cooperado editado com sucesso!");

    if (onFinish) {
      onFinish(response.data);
    }

    // REFRESH LIST
    yield put(listAccountRequest({ businessActionId }));

    setIsSubmitting(false);
  } catch (error) {
    addMessage(
      MessageBarType.error,
      error?.message ??
        "Erro desconhecido, por favor, tente enviar os dados novamente."
    );

    setIsSubmitting(false);
  }
}

export function* listAccount({ payload }: AnyAction) {
  const { filter } = payload;

  try {
    const abortController = new AbortController();
    const { signal } = abortController;

    const service = AccountsService();

    const params = { query: { page: 1, limit: 10 } };

    if (filter) {
      params.query = { ...params.query, ...filter };
    }

    const response = yield call(service.list, { params, signal });

    if (response.error) {
      throw new Error();
    }

    yield put(listAccountSuccess({ list: response.data }));
  } catch (error) {
    yield put(listAccountFailure());
  }
}

export default all([
  takeLatest(AccountActionsTypes.CREATE_REQUEST, createAccount),
  takeLatest(AccountActionsTypes.EDIT_REQUEST, editAccount),
  takeLatest(AccountActionsTypes.LIST_REQUEST, listAccount)
]);
