import { IDetailsListStyles, mergeStyleSets } from "@fluentui/react";
import styled from "styled-components";

export const classNames = mergeStyleSets({
  wrapperScrollable: {
    position: "relative"
  }
});

export const listStyles: Partial<IDetailsListStyles> = {
  headerWrapper: {
    "& .fixed-column-1": {
      position: "sticky",
      inset: "0",
      backgroundColor: "white",
      zIndex: 1,
      left: "0px"
    },
    "& .fixed-column-2": {
      position: "sticky",
      inset: "0",
      backgroundColor: "white",
      zIndex: 1,
      left: "110px"
    },
    "& .fixed-column-3": {
      position: "sticky",
      inset: "0",
      backgroundColor: "white",
      zIndex: 1,
      left: "300px"
    },
    "& .fixed-column-4": {
      position: "sticky",
      inset: "0",
      backgroundColor: "white",
      zIndex: 1,
      left: "540px"
    },
    selectors: {
      "& .ms-DetailsHeader": {
        paddingTop: 0
      }
    }
  },

  root: {
    "& .fixed-column-1": {
      position: "sticky",
      inset: "0",
      backgroundColor: "white",
      zIndex: 1,
      left: "0px"
    },
    "& .fixed-column-2": {
      position: "sticky",
      inset: "0",
      backgroundColor: "white",
      zIndex: 1,
      left: "110px"
    },
    "& .fixed-column-3": {
      position: "sticky",
      inset: "0",
      backgroundColor: "white",
      zIndex: 1,
      left: "300px"
    },
    "& .fixed-column-4": {
      position: "sticky",
      inset: "0",
      backgroundColor: "white",
      zIndex: 1,
      left: "540px"
    },
    selectors: {
      "& .ms-DetailsRow-cell": {
        alignItems: "center",
        display: "flex"
      }
    }
  }
};

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
