import Api from "./api";

class ShippingsTypes extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(
      isAdmin
        ? `admin/shippings/types${subPaths}`
        : `shippings/types${subPaths}`
    );
  }
}

export default ShippingsTypes;
