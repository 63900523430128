const arrayPrefix = "array_";
export const formatURLParams = (params: object): string => {
  if (!params) return;

  const formattedURL = Object.entries(params)
    .map(([key, value]) => {
      let formattedValue = value;

      if (Array.isArray(value)) {
        formattedValue = arrayPrefix + JSON.stringify(value);
      }

      return `${key}=${encodeURIComponent(formattedValue)}`;
    })
    .join("&");

  return formattedURL;
};

export const parseURLParams = (paramString: string) => {
  const params = new URLSearchParams(paramString);

  return Object.fromEntries(
    [...params.entries()].map(([key, value]) => {
      let parsedValue = value;

      if (value.startsWith(arrayPrefix)) {
        const arrayValue = value.slice(arrayPrefix.length);
        parsedValue = JSON.parse(arrayValue);
      }

      return [key, parsedValue];
    })
  );
};
