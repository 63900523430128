import produce from "immer";

import {
  ContractModelsState,
  ModelsActionsTypes,
  ModelsReducerActions
} from "../types/ContractModels.reducer.types";

const INITIAL_STATE: ContractModelsState = {
  list: [],
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: ModelsReducerActions
): ContractModelsState {
  return produce(state, draft => {
    switch (action.type) {
      case ModelsActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ModelsActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case ModelsActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case ModelsActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case ModelsActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = [];
        break;
      }

      case ModelsActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = [];
        break;
      }

      case ModelsActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
