import { ShippingsGroupsActionsTypes } from "../types/ShippingsGroups.reducer.types";

export function createShippingsGroupsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ShippingsGroupsActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createShippingsGroupsSuccess(message) {
  return {
    type: ShippingsGroupsActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createShippingsGroupsFailure(message) {
  return {
    type: ShippingsGroupsActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editShippingsGroupsRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: ShippingsGroupsActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editShippingsGroupsSuccess(message) {
  return {
    type: ShippingsGroupsActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editShippingsGroupsFailure(message) {
  return {
    type: ShippingsGroupsActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listShippingsGroupsRequest(filter, { isAdmin = false }) {
  return {
    type: ShippingsGroupsActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listShippingsGroupsSuccess({ list }) {
  return {
    type: ShippingsGroupsActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listShippingsGroupsFailure() {
  return {
    type: ShippingsGroupsActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ShippingsGroupsActionsTypes.DISMISS_MESSAGE
  };
}
