import { OffersPricesActionsTypes } from "../types/OffersPrices.reducer.types";

export function createRequest(values, { setSubmitting }, closePanel) {
  return {
    type: OffersPricesActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel }
  };
}

export function createSuccess(message) {
  return {
    type: OffersPricesActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createFailure(message) {
  return {
    type: OffersPricesActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editRequest(values, { setSubmitting }, closePanel) {
  return {
    type: OffersPricesActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel }
  };
}

export function editSuccess(message) {
  return {
    type: OffersPricesActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editFailure(message) {
  return {
    type: OffersPricesActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function listRequest(filter?, hasFilter?) {
  return {
    type: OffersPricesActionsTypes.LIST_REQUEST,
    payload: { filter, hasFilter }
  };
}

export function listSuccess({ list }) {
  return {
    type: OffersPricesActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: OffersPricesActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: OffersPricesActionsTypes.DISMISS_MESSAGE
  };
}
