import { MutableRefObject } from "react";

import { CommomListProps } from "@/common/types/ListFromApi.types";
import { IMessageBar } from "@/core/libs/message-bar";

export enum ServicesCategoriesTypesEnum {
  MOBILE_LINE = "MOBILE_LINE",
  TAG = "TAG",
  MOBILE_LINE_PRE = "MOBILE_LINE_PRE",
  BROADBAND = "BROADBAND",
  TRACKER = "TRACKER",
  LINK_DEDICATED = "LINK_DEDICATED",
  MDM = "MDM",
  PABX = "PABX",
  ADHERENCE = "ADHERENCE"
}

export enum TranslatedServicesCategoriesTypesEnum {
  MOBILE_LINE = "Linha Móvel",
  MOBILE_LINE_PRE = "Linha Móvel Pré",
  TAG = "Tag",
  BROADBAND = "Internet Fixa",
  TRACKER = "Rastreador",
  LINK_DEDICATED = "Link Dedicado",
  MDM = "MDM",
  PABX = "PABX",
  ADHERENCE = "Adesão"
}

export interface ServiceCategoryItem {
  id: number;
  name: string;
  type: ServicesCategoriesTypesEnum;
  services: number;
  billerIntegrationId: string;
}

export interface ListOfServicesCategories extends CommomListProps {
  items?: ServiceCategoryItem[];
}

export type ServicesCategoriesProps = {
  canCreate: boolean;
  canEdit: boolean;
};

export type CreateProps = {
  create: (values, { setSubmitting }, closeDrawer, isAdmin) => void;
  closeDrawer: () => void;
  dismissMessage: () => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  message: IMessageBar;
};

export type EditProps = {
  edit: (values, { setSubmitting }, closeDrawer, isAdmin) => void;
  closeDrawer: () => void;
  dismissMessage: () => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  message: IMessageBar;
  selectedItemId: string;
};
