import { TreeDataValue } from "@/core/libs/tree-select-dropdown/TreeSelectDropdown";

const markAllChildren = (arr: TreeDataValue[]): TreeDataValue[] => {
  return arr.map(object => {
    const children = object.children
      ? markAllChildren(object.children)
      : undefined;

    return { ...object, checked: true, children };
  });
};

export const markRecursive = (
  list: TreeDataValue[],
  buIdList: number[]
): TreeDataValue[] => {
  return list.map(object => {
    const isChecked = buIdList.includes(+object.value);
    const hasChildren = object.children && object.children.length > 0;

    const updatedObject = {
      ...object,
      checked: isChecked
    };

    if (isChecked && hasChildren) {
      updatedObject.children = markAllChildren(object.children);
    } else if (!isChecked && hasChildren) {
      updatedObject.children = markRecursive(object.children, buIdList);
    }

    return updatedObject;
  });
};
