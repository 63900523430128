import produce from "immer";

import {
  OrganizationsActionsTypes,
  OrganizationsReducerActions,
  OrganizationsState
} from "../types/Organizations.reducer.types";

const INITIAL_STATE: OrganizationsState = {
  list: undefined,
  isLoadingList: false,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: OrganizationsReducerActions
): OrganizationsState {
  return produce(state, draft => {
    switch (action.type) {
      case OrganizationsActionsTypes.CREATE_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OrganizationsActionsTypes.CREATE_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OrganizationsActionsTypes.CREATE_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OrganizationsActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case OrganizationsActionsTypes.EDIT_REQUEST: {
        draft.message = undefined;
        break;
      }

      case OrganizationsActionsTypes.EDIT_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case OrganizationsActionsTypes.EDIT_SUCCESS: {
        draft.message = action.payload.message;
        break;
      }

      case OrganizationsActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case OrganizationsActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case OrganizationsActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
