import Api from "./api";

class Permissions extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/permissions${subPaths}` : `permissions${subPaths}`);
  }
}

export default Permissions;
