import { Checkbox, Separator, Stack } from "@fluentui/react";

import { FieldsBuilder } from "@/core/libs/form-builder";

type OfferOverrideProps = {
  values?: any;
  errors?: any;
  touched?: any;
  setFieldValue: (field: string, value: string | number | boolean) => void;
  serviceId: number;
};

export function OfferOverride({
  values,
  errors,
  touched,
  setFieldValue
}: OfferOverrideProps): JSX.Element {
  return (
    <Stack style={{ maxWidth: 450, padding: "0 8px 0 8px" }}>
      <Stack.Item tokens={{ margin: "0 0 20px 0" }}>
        <h1 className="ms-fontSize-28">Personalize o Plano de Benefícios</h1>
      </Stack.Item>

      <h1 className="ms-fontSize-18">Desconto Mensal no Plano de Benefícios</h1>
      <Stack horizontal>
        <Stack.Item grow className="ms-Grid-col ms-sm12 ms-lg4">
          <FieldsBuilder
            {...{
              fields: [
                [
                  {
                    label: "Valor",
                    type: "money",
                    name: "discount",
                    value: values?.discount || 0
                  }
                ]
              ],
              errors,
              touched,
              setFieldValue,
              values
            }}
          />
        </Stack.Item>
      </Stack>

      <Stack.Item styles={{ root: { width: "100%" } }}>
        <Separator />
      </Stack.Item>

      <Stack.Item>
        <Checkbox
          label="A personalização deste benefício estará sujeita à aprovação posterior do contrato."
          checked={values.isChecked}
          onChange={(e, checked) => setFieldValue("isChecked", checked)}
        />
      </Stack.Item>
    </Stack>
  );
}
