import { Shimmer, ShimmerElementType } from "@fluentui/react";
import { useState } from "react";

import { InvoiceInstallment } from "./interfaces";
import {
  AccordionButton,
  AccordionContent,
  Scroll,
  Subtitle,
  Title
} from "./styles";

import { transformToCurrencyString } from "@/utils/TransformToCurrencyString";

interface InvoiceProps {
  installments: InvoiceInstallment[];
  title: string;
  accordionStartsOpen?: boolean;
  installmentText?: string;
  isLoading?: boolean;
}

export function InvoiceMonthly({
  installments,
  title,
  accordionStartsOpen = false,
  installmentText = "º Mês",
  isLoading = false
}: InvoiceProps) {
  const [isAccordionOpen, setIsAccordionOpen] =
    useState<boolean>(accordionStartsOpen);

  const shimmer = Array.from({ length: 6 }).map((_, index) => (
    <Shimmer
      key={index}
      style={{ marginBottom: "0.5rem" }}
      shimmerElements={[
        {
          type: ShimmerElementType.line,
          height: 25
        }
      ]}
    />
  ));

  const installmentsData = installments?.map((installment, index) => (
    <Subtitle key={index}>
      <p>{`${installment.installment}${installmentText}`}</p>
      <p>{transformToCurrencyString(Number(installment.total))}</p>
    </Subtitle>
  ));

  return (
    <Scroll>
      <Title>
        <p>{title}</p>
        <AccordionButton onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
          {isAccordionOpen ? "▲" : "▼"}
        </AccordionButton>
      </Title>

      {isAccordionOpen && (
        <AccordionContent>
          {isLoading ? shimmer : installmentsData}
        </AccordionContent>
      )}
    </Scroll>
  );
}
