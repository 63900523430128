import { MutableRefObject } from "react";

import { CommomListProps } from "@/common/types/ListFromApi.types";
import { IMessageBar } from "@/core/libs/message-bar";

export enum ProductCategoryCodes {
  CELULAR = "CELULAR",
  INTERNET_E_MODEM = "INTERNET_E_MODEM",
  MONITORAMENTO_E_RASTREAMENTO = "MONITORAMENTO_E_RASTREAMENTO",
  CHIP = "CHIP"
}

export type ProductCategoryItem = {
  id?: string;
  name: string;
  code?: string;
};
export interface ListOfProductsCategories extends CommomListProps {
  items?: ProductCategoryItem[];
}

export type ProductsCategoriesProps = {
  canCreate: boolean;
  canEdit: boolean;
};

export type CreateProps = {
  create: (values, { setSubmitting }, closeDrawer) => void;
  dismissMessage: () => void;
  closeDrawer: () => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  message: IMessageBar;
};

export type EditProps = {
  edit: (values, { setSubmitting }, closeDrawer) => void;
  dismissMessage: () => void;
  closeDrawer: () => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  message: IMessageBar;
  selectedItemId: string;
};
