import {
  ISpinnerStyleProps,
  ISpinnerStyles,
  IStyle,
  IStyleFunctionOrObject,
  Spinner
} from "@fluentui/react";
import InfiniteScroll from "react-infinite-scroll-component";

import { GenericInfiniteScrollProps } from "./infinite-scroll.interface";

/**
 * Um componente genérico de rolagem infinita.
 * @param {GenericInfiniteScrollProps} props - As propriedades do componente.
 * @returns {JSX.Element} O componente renderizado.
 */
function GenericInfiniteScroll({
  fetchData,
  items,
  children,
  hasMore,
  spinnerLabel,
  height = "70vh",
  spinnerPosition = "BOTTOM"
}: GenericInfiniteScrollProps): JSX.Element {
  function spinnerPositionStyle(): IStyleFunctionOrObject<
    ISpinnerStyleProps,
    ISpinnerStyles
  > {
    const middlePositionStyles: IStyle = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 999
    };

    const bottomPositionStyles: IStyle = {
      padding: 10,
      left: "50%",
      zIndex: 999,
      backgroundColor: "#ffffff"
    };

    return {
      root:
        spinnerPosition === "MIDDLE"
          ? middlePositionStyles
          : bottomPositionStyles
    };
  }

  return (
    <div
      id="scrollableDiv"
      style={{
        width: "100%",
        height,
        overflow: "auto",
        zIndex: 999,
        backgroundColor: "#ffffff"
      }}
    >
      <InfiniteScroll
        dataLength={items?.length}
        next={fetchData}
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflowX: "hidden"
        }}
        inverse={false}
        hasMore={hasMore}
        hasChildren={true}
        loader={
          <Spinner
            styles={spinnerPositionStyle()}
            label={spinnerLabel ? spinnerLabel : "Carregando..."}
          />
        }
        scrollableTarget="scrollableDiv"
      >
        {children}
      </InfiniteScroll>
    </div>
  );
}

export default GenericInfiniteScroll;
