import { InventoriesActionsTypes } from "../types/Inventories.reducer.types";

export function createRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: InventoriesActionsTypes.CREATE_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function createSuccess(message) {
  return {
    type: InventoriesActionsTypes.CREATE_SUCCESS,
    payload: { message }
  };
}

export function createFailure(message) {
  return {
    type: InventoriesActionsTypes.CREATE_FAIL,
    payload: { message }
  };
}

export function editRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: InventoriesActionsTypes.EDIT_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function editSuccess(message) {
  return {
    type: InventoriesActionsTypes.EDIT_SUCCESS,
    payload: { message }
  };
}

export function editFailure(message) {
  return {
    type: InventoriesActionsTypes.EDIT_FAIL,
    payload: { message }
  };
}

export function transferRequest(
  values,
  { setSubmitting, isAdmin = false },
  closePanel
) {
  return {
    type: InventoriesActionsTypes.TRANSFER_REQUEST,
    payload: { values, setSubmitting, closePanel, isAdmin }
  };
}

export function transferSuccess(message) {
  return {
    type: InventoriesActionsTypes.TRANSFER_SUCCESS,
    payload: { message }
  };
}

export function transferFailure(message) {
  return {
    type: InventoriesActionsTypes.TRANSFER_FAIL,
    payload: { message }
  };
}

export function listRequest(filter, { isAdmin = false }) {
  return {
    type: InventoriesActionsTypes.LIST_REQUEST,
    payload: { filter, isAdmin }
  };
}

export function listSuccess({ list }) {
  return {
    type: InventoriesActionsTypes.LIST_SUCCESS,
    payload: { list }
  };
}

export function listFailure() {
  return {
    type: InventoriesActionsTypes.LIST_FAIL
  };
}

export function dismissMessage() {
  return {
    type: InventoriesActionsTypes.DISMISS_MESSAGE
  };
}
