import { ProfileActionsTypes } from "../types";

export function getProfileRequest() {
  return {
    type: ProfileActionsTypes.GET_PROFILE_REQUEST
  };
}

export function getProfileSuccess({ user, selectedBU }) {
  return {
    type: ProfileActionsTypes.GET_PROFILE_SUCCESS,
    payload: { user, selectedBU }
  };
}

export function getProfileFailure() {
  return {
    type: ProfileActionsTypes.GET_PROFILE_FAIL
  };
}

export function editProfileRequest(values, { setSubmitting }) {
  return {
    type: ProfileActionsTypes.EDIT_PROFILE_REQUEST,
    payload: { values, setSubmitting }
  };
}

export function editProfileSuccess(message) {
  return {
    type: ProfileActionsTypes.EDIT_PROFILE_SUCCESS,
    payload: { message }
  };
}

export function editProfileFailure(message) {
  return {
    type: ProfileActionsTypes.EDIT_PROFILE_FAIL,
    payload: { message }
  };
}

export function selectBURequest(value) {
  return {
    type: ProfileActionsTypes.SELECTBU_REQUEST,
    payload: { value }
  };
}

export function selectBUSuccess({ selectedBU }) {
  return {
    type: ProfileActionsTypes.SELECTBU_SUCCESS,
    payload: { selectedBU }
  };
}

export function selectBUFailure() {
  return {
    type: ProfileActionsTypes.SELECTBU_FAIL
  };
}

export function dismissMessage() {
  return {
    type: ProfileActionsTypes.DISMISS_MESSAGE
  };
}

export function dismissProfile() {
  return {
    type: ProfileActionsTypes.DISMISS_PROFILE
  };
}
