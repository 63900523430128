import { DefaultButton, IconButton } from "@fluentui/react/lib/Button";
import { Label } from "@fluentui/react/lib/Label";
import { Stack } from "@fluentui/react/lib/Stack";
import { getTheme } from "@fluentui/react/lib/Styling";
import { MaskedTextField, TextField } from "@fluentui/react/lib/TextField";
import { Field, FieldArray } from "formik";

interface BusinessUnitSettingsFieldsProps {
  BusinessUnitSettingCfg: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
}

interface BusinessUnitSettingsFieldProps {
  cfg: any;
  currentBusinessUnitSetting: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  index: any;
  arrayHelpers: any;
  value?: any;
}

function BusinessUnitSettingField(props: BusinessUnitSettingsFieldProps) {
  const theme = getTheme();

  const {
    cfg,
    currentBusinessUnitSetting,
    errors,
    touched,
    index,
    arrayHelpers
  } = props;

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 10 }}
      key={currentBusinessUnitSetting}
    >
      <Stack.Item align="center">
        <IconButton
          iconProps={{ iconName: "Remove" }}
          title="Remover Configuração"
          ariaLabel="Remover Configuração"
          styles={{
            root: {
              color: theme.palette.red
            },
            iconHovered: {
              color: theme.palette.redDark
            }
          }}
          onClick={() => arrayHelpers.remove(index)}
        />
      </Stack.Item>
      <Stack tokens={{ childrenGap: 5 }} grow>
        <div className="ms-Grid-row" style={{ marginBottom: 15 }}>
          {cfg.fields.map(field => {
            switch (field.type) {
              default:
                return (
                  <div
                    className={`ms-Grid-col ms-sm12${
                      field.size ? ` ms-lg${field.size}` : ""
                    }`}
                    key={`${field.name}[${index}]`}
                  >
                    <Field
                      name={`${currentBusinessUnitSetting}.${field.name}`}
                      type={field.type}
                      as={field.mask ? MaskedTextField : TextField}
                      placeholder={field.label}
                      key={`${currentBusinessUnitSetting}.${field.name}`}
                      label={field.label}
                      disabled={field.disabled || false}
                      mask={field.mask}
                      required={field.required || false}
                      errorMessage={
                        errors[`${currentBusinessUnitSetting}.${field.name}`] &&
                        touched[`${currentBusinessUnitSetting}.${field.name}`]
                          ? errors[
                              `${currentBusinessUnitSetting}.${field.name}`
                            ]
                          : null
                      }
                    />
                  </div>
                );
            }
          })}
        </div>
      </Stack>
    </Stack>
  );
}

export function BusinessUnitSettingsFields(
  props: BusinessUnitSettingsFieldsProps
): JSX.Element {
  const { BusinessUnitSettingCfg, values, errors, touched, setFieldValue } =
    props;
  return (
    <FieldArray
      name={BusinessUnitSettingCfg.name}
      key={BusinessUnitSettingCfg.name}
      render={arrayHelpers => (
        <div style={{ marginTop: 10 }}>
          <Label required={BusinessUnitSettingCfg.required || false}>
            {BusinessUnitSettingCfg.label}
          </Label>
          <Stack tokens={{ childrenGap: 10 }}>
            {values instanceof Array &&
              values.map((setting, settingIndex) => {
                return (
                  <BusinessUnitSettingField
                    key={settingIndex}
                    cfg={BusinessUnitSettingCfg}
                    currentBusinessUnitSetting={`${BusinessUnitSettingCfg.name}[${settingIndex}]`}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    index={settingIndex}
                    arrayHelpers={arrayHelpers}
                    value={setting}
                  />
                );
              })}
            <div>
              <DefaultButton
                type="button"
                onClick={() => {
                  arrayHelpers.push({ configName: "", value: "" });
                }}
              >
                Adicionar nova configuração
              </DefaultButton>
            </div>
          </Stack>
        </div>
      )}
    />
  );
}
