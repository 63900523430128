import { MutableRefObject } from "react";

export enum LineStatus {
  ACTIVE = "ACTIVE",
  RESERVED = "RESERVED",
  IN_USE = "IN_USE",
  SOLD = "SOLD",
  BACK_TO_CARRIER = "BACK_TO_CARRIER",
  REMOVED = "REMOVED"
}

export enum TranslatedLineStatus {
  ACTIVE = "Ativa",
  RESERVED = "Reservada",
  SOLD = "Vendida",
  IN_USE = "Em Uso",
  BACK_TO_CARRIER = "Devolvido para operadora",
  REMOVED = "Removido"
}

export enum CarrierStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED"
}

export enum TranslatedCarrierStatus {
  ACTIVE = "Ativa",
  SUSPENDED = "Suspensa"
}

export interface DashboardLines {
  id: number;
  name: string;
  logo: string;
  plans: Plan[];
}

export interface Plan {
  id: number;
  name: string;
  service: Service;
  areaCodes: AreaCode[];
}

export interface Service {
  id: number;
  name: string;
}

export interface AreaCode {
  code: string;
  qty: string;
}

export type LinesProps = {
  canCreate: boolean;
  canEdit: boolean;
};

export type CreateProps = {
  message: any;
  dismissMessage: () => void;
  closeDrawer?: () => void;
  custom?: any;
  isTest?: unknown;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
};

export type EditProps = {
  edit: (values, { setSubmitting, isAdmin }, closeDrawer) => void;
  message: any;
  dismissMessage: () => void;
  closeDrawer?: () => void;
  selectedItem: string;
  custom?: any;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
};

export interface ICreateLineValues {
  carrierId: number;
  carrierStatus: CarrierStatus;
  plans: number[];
  aquisitionDate: string;
  lines: string;
  type: string;
  lineStatus: LineStatus;
  isBringYourOwn?: boolean;
  distributionCenters: number[];
}
