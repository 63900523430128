import { Icon, Separator, Stack, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

import { renderPrice } from "../../utils/renderPrice";
import { PlanTypeEnum } from "../../utils/renderPrice/enums/planTypeEnum";
import { PlanAppIconList } from "../PlanAppIconList";

import {
  CarrierLogo,
  PlanName,
  PlanOfferContainer,
  PlanOfferRulesList,
  PlanText
} from "./PlanOffer.styles";

import { Plan, PlanOfferItem } from "@/modules/Offers/types/PlansOffers.types";

type PlanOfferProps = {
  selectedPlan: Plan;
  selectedCarrier: PlanOfferItem;
};

export function PlanOffer({
  selectedPlan,
  selectedCarrier
}: PlanOfferProps): JSX.Element {
  const tooltipId = useId("tooltip-plans");

  return (
    <PlanOfferContainer
      tokens={{ padding: 20 }}
      verticalAlign="center"
      horizontalAlign="center"
    >
      <div>
        <CarrierLogo src={selectedCarrier?.logo} alt={selectedCarrier?.name} />

        <PlanName>{selectedPlan?.name}</PlanName>

        <Stack tokens={{ childrenGap: 15, padding: 20 }}>
          {selectedPlan?.voice && (
            <Stack horizontal verticalAlign="center">
              <Icon
                iconName="Phone"
                style={{
                  fontSize: 20,
                  marginRight: 15
                }}
              />
              <span className="ms-fontSize-18">{selectedPlan.voice}</span>
            </Stack>
          )}

          {selectedPlan?.data && (
            <Stack horizontal verticalAlign="center">
              <Icon
                iconName="Globe"
                style={{
                  fontSize: 20,
                  marginRight: 15
                }}
              />
              <span className="ms-fontSize-18">
                {selectedPlan.data} GB de internet móvel
                {selectedPlan?.offer?.bonusData?.value &&
                  ` + bônus de ${Number(
                    selectedPlan?.offer?.bonusData?.value
                  )}${" "}
                GB por ${Number(
                  selectedPlan?.offer?.bonusData?.numberOfMonths
                )}${" "}
                meses`}
              </span>
            </Stack>
          )}
        </Stack>

        <PlanAppIconList
          appInfo={selectedPlan?.appsInfos}
          appIcon={selectedPlan?.freeDataApps}
          verticalAlign="start"
          height="auto"
        />

        <Stack.Item styles={{ root: { width: "90%" } }}>
          <Separator style={{ width: "100%" }} />
        </Stack.Item>

        {renderPrice({
          price: selectedPlan?.offer?.offerPrice?.price,
          discount: selectedPlan?.offer?.discount?.value,
          discountNumberOfMonths: selectedPlan?.offer?.discount?.numberOfMonths,
          beginsAt: selectedPlan?.offer?.discount?.beginsAt,
          type: PlanTypeEnum.PLAN
        })}

        <Stack.Item styles={{ root: { width: "90%" } }}>
          <Separator style={{ width: "100%" }} />
        </Stack.Item>

        <Stack
          horizontal
          tokens={{ childrenGap: 5 }}
          style={{ margin: "20px 0" }}
        >
          <TooltipHost
            tooltipProps={{
              onRenderContent: () => (
                <PlanOfferRulesList>
                  <li className="ms-fontWeight-semibold">
                    {selectedPlan?.data} GB de Internet móvel
                    {selectedPlan?.offer?.bonusData &&
                      ` + bônus ${selectedPlan?.offer?.bonusData?.value} GB`}
                    {selectedPlan?.offer?.bonusData?.numberOfMonths &&
                      ` por ${selectedPlan?.offer?.bonusData?.numberOfMonths} meses`}
                  </li>

                  <li className="ms-fontWeight-semibold">
                    {selectedPlan?.includedServices?.length > 0 &&
                      selectedPlan?.includedServices?.reduce(
                        (allServices, currentService, currentIndex) => {
                          allServices = `${allServices}${
                            currentIndex > 0 ? "," : ""
                          } ${currentService}`;

                          return allServices;
                        },
                        ""
                      )}
                  </li>
                </PlanOfferRulesList>
              )
            }}
            id={tooltipId}
            styles={{ root: { position: "relative", bottom: "10%" } }}
          >
            <Icon
              iconName="Info"
              aria-describedby={tooltipId}
              style={{
                fontSize: 16,
                cursor: "pointer"
              }}
            />
          </TooltipHost>

          <PlanText fontSize="14px">Regulamento do benefício</PlanText>
        </Stack>
      </div>
    </PlanOfferContainer>
  );
}
