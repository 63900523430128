import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  SearchBox,
  Stack
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { FilterComponentProps } from "../types";

export function SelectFilter({
  _paginate,
  setSelectedKeys,
  filterConfigs,
  debouncedTerms,
  selectedKeys,
  itemsPerPage
}: FilterComponentProps): JSX.Element {
  const [searchText, setSearchText] = useState("");

  const handleDropdownChange = useCallback(
    (_, item) => {
      const filterQuery = filterConfigs.filterQuery as string;
      const currentKeys = { ...selectedKeys };

      if (filterConfigs.multiSelect) {
        currentKeys[filterQuery] = item.selected
          ? [...(currentKeys[filterQuery] || []), item.key]
          : currentKeys[filterQuery].filter(k => k !== item.key);
      } else {
        currentKeys[filterQuery] = item.key;
      }

      setSelectedKeys(currentKeys);
    },
    [filterConfigs, selectedKeys, setSelectedKeys]
  );

  const optionsFormatter: IDropdownOption[] =
    filterConfigs.options && filterConfigs.options[0]
      ? [
          {
            key: "FilterHeader",
            text: "-",
            itemType: DropdownMenuItemType.Header
          },
          {
            key: "divider_filterHeader",
            text: "-",
            itemType: DropdownMenuItemType.Divider
          },

          ...filterConfigs.options
        ]
      : [];

  function renderOption(option: IDropdownOption): JSX.Element {
    return option.itemType === DropdownMenuItemType.Header &&
      option.key === "FilterHeader" ? (
      <SearchBox
        onChange={(ev, newValue) => setSearchText(newValue.trimStart())}
        underlined={true}
        placeholder="Busca"
        value={searchText}
        onClear={() => setSearchText("")}
      />
    ) : (
      <>{option.text}</>
    );
  }

  useEffect(() => {
    filterConfigs.onSelectedKeysChange &&
      filterConfigs.onSelectedKeysChange(selectedKeys);
  }, [selectedKeys]);

  useEffect(() => {
    if (
      filterConfigs.defaultValue &&
      !selectedKeys[filterConfigs.filterQuery as string]
    ) {
      setSelectedKeys(previousSelectedKeys => ({
        ...previousSelectedKeys,
        [filterConfigs.filterQuery as string]: filterConfigs.defaultValue
      }));
    }
  }, [filterConfigs, setSelectedKeys]);

  const selectId = filterConfigs?.placeholder?.replace(/ /g, "-");

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Dropdown
        id={selectId}
        disabled={filterConfigs.disabled ? true : false}
        calloutProps={{ calloutMaxHeight: 500 }}
        onDismiss={() => {
          setSearchText("");
          if (selectedKeys[filterConfigs.filterQuery as string]) {
            _paginate({
              limit: itemsPerPage ?? 10,
              ...debouncedTerms,
              ...selectedKeys
            });
          }
        }}
        multiSelect={filterConfigs.multiSelect ? true : false}
        onChange={handleDropdownChange}
        label={filterConfigs.hideLabel ? null : filterConfigs.label}
        placeholder={filterConfigs?.placeholder}
        options={optionsFormatter.filter(item => {
          if (
            item.key === "FilterHeader" ||
            item.key === "divider_filterHeader"
          ) {
            return true;
          }
          if (searchText != "") {
            return item.text.toLowerCase().includes(searchText.toLowerCase());
          }
          return true;
        })}
        onRenderOption={renderOption}
        selectedKeys={
          selectedKeys[filterConfigs.filterQuery as string] &&
          filterConfigs.multiSelect
            ? selectedKeys[filterConfigs.filterQuery as string]
            : filterConfigs.isTagMode
            ? [selectedKeys[filterConfigs.filterQuery as string]]
            : null
        }
        selectedKey={
          selectedKeys[filterConfigs.filterQuery as string] &&
          !filterConfigs.multiSelect
            ? selectedKeys[filterConfigs.filterQuery as string]
            : null
        }
        dropdownWidth={220}
        notifyOnReselect
        styles={{
          root: {
            minWidth: filterConfigs.minWidth,
            maxWidth: filterConfigs.maxWidth
          },
          callout: {
            minWidth: filterConfigs?.minWidth,
            maxWidth: filterConfigs?.maxWidth,
            minHeight: "fit-content",
            maxHeight: "420px"
          },
          dropdownItem: {
            height: filterConfigs.heightItem,
            marginTop: "8px",
            marginBottom: "8px"
          }
        }}
      />
    </Stack>
  );
}
