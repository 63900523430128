import produce from "immer";

import {
  BannersOffersActionsTypes,
  BannersOffersReducerActions,
  BannersOffersState
} from "../types/BannersOffers.reducer.types";

const INITIAL_STATE: BannersOffersState = {
  list: undefined,
  isLoadingList: true,
  message: undefined
};

export default function reducer(
  state = INITIAL_STATE,
  action: BannersOffersReducerActions
): BannersOffersState {
  return produce(state, draft => {
    switch (action.type) {
      case BannersOffersActionsTypes.DISMISS_MESSAGE: {
        draft.message = undefined;
        break;
      }

      case BannersOffersActionsTypes.LIST_REQUEST: {
        draft.isLoadingList = true;
        draft.list = undefined;
        break;
      }

      case BannersOffersActionsTypes.LIST_FAIL: {
        draft.isLoadingList = false;
        draft.list = undefined;
        break;
      }

      case BannersOffersActionsTypes.LIST_SUCCESS: {
        draft.isLoadingList = false;
        draft.list = action.payload.list;
        break;
      }
      default:
    }
  });
}
