import { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import Step1 from "@/components/Auth/Recover/Step1";
import { AuthService } from "@/core/libs/api";
import { IMessageBar, messageBarTypes } from "@/core/libs/message-bar";

function Recover(): JSX.Element {
  const history = useHistory();
  const [message, setMessage] = useState<IMessageBar>(null);

  function dissmissMessage() {
    setMessage(null);
  }

  function recoverPassword(values, { setSubmitting }) {
    dissmissMessage();

    const abortController = new AbortController();
    const { signal } = abortController;

    const service = AuthService();
    service
      .recover({ params: { body: { ...values } }, signal })
      .then(response => {
        if (response.error) {
          setSubmitting(false);

          setMessage({
            code: response.error.status,
            message:
              "Não foi possível recuperar a senha com o e-mail informado",
            type: messageBarTypes.ERROR
          });
        } else {
          setSubmitting(false);
          history.push("/set-password");
        }
      });
  }

  return (
    <>
      <Helmet>
        <title>Recuperar senha - Libertas</title>
      </Helmet>
      <Step1
        recoverPassword={recoverPassword}
        message={message}
        dismissMessage={dissmissMessage}
      />
    </>
  );
}

export default Recover;
