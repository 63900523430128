import { Stack, getTheme } from "@fluentui/react";
import { ChevronDownIcon } from "@fluentui/react-icons-mdl2";
import { useCallback, useEffect, useState } from "react";

import { AccountType } from "../../DisplayContractInfo/ClientInfo/types";

import {
  ContactType,
  Contacts,
  FormValuesType,
  SelectedContact,
  SelectedSignatureProps
} from "./signature.interface";
import {
  CustomInput,
  CustomSelect,
  CustomWrapper,
  Name,
  SelectContainer
} from "./styles";

import { capitalize } from "@/utils/CapitalizeString";
import { phoneNumberFormatter } from "@/utils/PhoneNumberFormatter";

export interface DynamicContactInputFormProps {
  selectedSignatureProps: SelectedSignatureProps;
  accountType: AccountType;
  contacts: Contacts;
  showNewContactField: boolean;
  emailRefs: React.MutableRefObject<HTMLInputElement[]>;
  whatsappRefs: React.MutableRefObject<HTMLInputElement[]>;
  initialValues: FormValuesType;
  setShowNewContactField: React.Dispatch<boolean>;
  selectedContact: SelectedSignatureProps;
  setSelectedContact: React.Dispatch<SelectedContact>;
}
export function DynamicContactInputForm({
  selectedSignatureProps,
  accountType,
  contacts,
  emailRefs,
  whatsappRefs,
  initialValues,
  selectedContact,
  setSelectedContact
}: DynamicContactInputFormProps) {
  const theme = getTheme();
  const [isOptionsPopulated, setIsOptionsPopulated] = useState(false);
  const handleSelectChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = ev.target.selectedIndex;
    const selectedId = contacts[selectedIndex]?.id;
    const selectedValue = ev.target.value;
    setSelectedContact({ id: selectedId, name: selectedValue });
  };

  useEffect(() => {
    if (contacts && contacts.length > 0 && !isOptionsPopulated) {
      let contactName: string;

      if ("contact" in contacts[0]) {
        contactName = contacts[0].contact;
      }

      setSelectedContact({
        id: contacts[0].id,
        name: contactName
      });

      setIsOptionsPopulated(true);
    }
  }, [contacts, isOptionsPopulated, setSelectedContact]);

  const newContactInputs = useCallback(() => {
    if (selectedSignatureProps.name === "EMAIL") {
      return (
        <CustomWrapper id={`novo-email-wrapper`} key={`novo-email-wrapper`}>
          <CustomInput
            type="email"
            placeholder="Novo endereço de email"
            name="novo-email"
            style={{ marginTop: "0.3rem" }}
            required
            defaultValue={selectedContact?.name ? selectedContact?.name : null}
            ref={el => (emailRefs.current[0] = el)}
          />
        </CustomWrapper>
      );
    } else if (selectedSignatureProps.name === "WHATSAPP") {
      return (
        <CustomWrapper
          id={`novo-whatsapp-wrapper`}
          key={`novo-whatsapp-wrapper`}
        >
          <CustomInput
            type="numeric"
            name={`novo-whatsapp`}
            required
            style={{ marginTop: "0.3rem" }}
            maxLength={15}
            placeholder="Novo número de WhatsApp"
            ref={el => (whatsappRefs.current[0] = el)}
            defaultValue={selectedContact?.name ? selectedContact?.name : null}
            onChange={e => {
              e.target.value = phoneNumberFormatter(e.target.value);
            }}
            onKeyPress={e => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </CustomWrapper>
      );
    } else {
      return null;
    }
  }, [selectedContact, selectedSignatureProps.name]);

  if (!contacts) {
    return newContactInputs();
  }

  if (selectedSignatureProps.name !== ContactType.STANDARD) {
    return (
      <>
        {accountType === AccountType.PHYSICAL && (
          <CustomWrapper>
            <SelectContainer>
              <CustomSelect
                onChange={handleSelectChange}
                value={selectedContact?.name}
              >
                {contacts?.map((value, i) => {
                  return (
                    <option id={i + value?.id} key={i} value={value?.contact}>
                      {selectedSignatureProps.name === "WHATSAPP"
                        ? phoneNumberFormatter(value?.contact)
                        : value?.contact}
                    </option>
                  );
                })}
              </CustomSelect>
              <ChevronDownIcon className="chevronIcon" />
            </SelectContainer>
          </CustomWrapper>
        )}
        {accountType === AccountType.LEGAL &&
          contacts?.map((value, i) => {
            return (
              <div key={i + value?.id}>
                <Stack
                  tokens={{ childrenGap: 15 }}
                  style={{ alignItems: "center" }}
                >
                  <Name>{capitalize(value.fullName)}</Name>
                  <small
                    style={{
                      marginTop: "unset",
                      textAlign: "center",
                      fontSize: "0.7rem",
                      color: theme.palette.blackTranslucent40
                    }}
                  >
                    Representante Legal
                  </small>
                </Stack>

                <Stack
                  tokens={{ childrenGap: 15 }}
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem"
                  }}
                >
                  {(() => {
                    switch (selectedSignatureProps.name) {
                      case "WHATSAPP":
                        return (
                          <CustomWrapper
                            id={`whatsapp-wrapper-${i}`}
                            key={`whatsapp-wrapper-${i}`}
                          >
                            <CustomInput
                              type="numeric"
                              id={value.id}
                              name={`whatsapp-${i}`}
                              required
                              maxLength={15}
                              placeholder="Digite o numero de WhatsApp para assinatura"
                              defaultValue={
                                accountType === AccountType.LEGAL
                                  ? phoneNumberFormatter(value.whatsapp)
                                  : phoneNumberFormatter(initialValues.whatsapp)
                              }
                              ref={el => (whatsappRefs.current[i] = el)}
                              onChange={e => {
                                e.target.value = phoneNumberFormatter(
                                  e.target.value
                                );
                              }}
                              onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </CustomWrapper>
                        );
                      case "EMAIL":
                        return (
                          <CustomWrapper
                            id={`email-wrapper-${i}`}
                            key={`email-wrapper-${i}`}
                          >
                            <CustomInput
                              id={value?.id}
                              placeholder="Digite o e-mail para assinatura"
                              type="email"
                              name={`email-${i}`}
                              required
                              ref={el => (emailRefs.current[i] = el)}
                              defaultValue={
                                accountType === AccountType.LEGAL
                                  ? value?.email
                                  : initialValues?.email
                              }
                            />
                          </CustomWrapper>
                        );

                      default:
                        return null;
                    }
                  })()}
                </Stack>
              </div>
            );
          })}
      </>
    );
  } else {
    return null;
  }
}
