import { Panel, PanelType, Stack } from "@fluentui/react";
import { MutableRefObject, ReactNode } from "react";

import { DrawerSize } from "./enums";
import { DrawerContainer } from "./styles";

import { breakpointSizes } from "@/common/constants";

type CustomDrawerProps = {
  onCloseDrawer: () => void;
  isOpen: boolean;
  children: ReactNode;
  drawerContainerRef?: MutableRefObject<HTMLDivElement>;
  action: string;
  module: string;
  hasTitle?: boolean;
  size?: keyof typeof DrawerSize;
  footerContent?: ReactNode;
};

function isSelectedSizeBiggerThanBreakpoint(
  currentBreakPoint: number,
  selectedSize: number | string
) {
  return Number(selectedSize) >= currentBreakPoint ? "100%" : selectedSize;
}

export function CustomDrawer({
  isOpen,
  hasTitle = true,
  action,
  module,
  children,
  drawerContainerRef,
  onCloseDrawer,
  size = "medium"
}: CustomDrawerProps): JSX.Element {
  const currentSize = size
    ? isSelectedSizeBiggerThanBreakpoint(
        breakpointSizes["default"],
        DrawerSize[size]
      )
    : isSelectedSizeBiggerThanBreakpoint(
        breakpointSizes["default"],
        DrawerSize.small
      );

  const CustomTitle = hasTitle ? `${action} ${module}` : undefined;

  return (
    <DrawerContainer>
      <Panel
        isOpen={isOpen}
        onDismiss={onCloseDrawer}
        headerText={CustomTitle}
        closeButtonAriaLabel="Close"
        type={
          size === "medium"
            ? PanelType.medium
            : size === "large"
            ? PanelType.medium
            : PanelType.smallFixedFar
        }
        styles={{
          main: {
            width: currentSize,
            maxWidth: "100%",
            minWidth: "320px",
            overflow: "hidden",
            paddingBottom: 50
          }
        }}
      >
        <div ref={drawerContainerRef}>
          <div className="ant-drawer-wrapper-body">{children}</div>
        </div>
      </Panel>
    </DrawerContainer>
  );
}
