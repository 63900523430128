import { makeRequest } from "../utils/fetchData";
import { MakeRequestOptions } from "../utils/types";

import Api, { IApi } from "./api";

class Inventories extends Api {
  // TODO: Allow Create Edit Get List
  constructor(subPaths: string, isAdmin?: boolean) {
    super(isAdmin ? `admin/inventories${subPaths}` : `inventories${subPaths}`);
    this.transferInventory = this.transferInventory.bind(this);
    this.listWithPost = this.listWithPost.bind(this);
    this.transferMassItemsInventory =
      this.transferMassItemsInventory.bind(this);
  }

  transferInventory({ params = {}, signal }: IApi) {
    const inventoryId = params.body?.id ?? null;
    const distributionCenterId = params.body?.distributionCenter ?? null;

    const options: MakeRequestOptions = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/${inventoryId}/transfer/${distributionCenterId}`
    };

    return makeRequest(options);
  }

  transferMassItemsInventory({ params = {}, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/massive-update/distribution-center`
    };

    if (params.body) options.bodyObj = params.body;

    return makeRequest(options);
  }

  listWithPost({ params, signal }: IApi) {
    const options: MakeRequestOptions = {
      signal,
      method: "POST",
      baseUrl: `${this.baseUrl}/list`
    };

    if (params) {
      options.queryParams = {
        limit: params.query.limit,
        page: params.query.page
      };
      options.bodyObj = params.query;
    }

    return makeRequest(options);
  }
}

export default Inventories;
