export enum OperationTypeEnum {
  STANDARD = "Padrão",
  RENEWAL = "Renovação",
  BRING_YOUR_OWN = "Permite linha externa"
}

export enum OperationType {
  STANDARD = "STANDARD",
  RENEWAL = "RENEWAL",
  BRING_YOUR_OWN = "BRING_YOUR_OWN"
}

export enum LineStrategyType {
  MANUALLY_SELECTED_LINE = "MANUALLY_SELECTED_LINE",
  MANUALLY_TYPED_LINE = "MANUALLY_TYPED_LINE",
  MANUALLY_TYPED_LINE_AND_SEARCH_SOLD = "MANUALLY_TYPED_LINE_AND_SEARCH_SOLD",
  MANUALLY_TYPED_LINE_AND_SEARCH_REMOVED = "MANUALLY_TYPED_LINE_AND_SEARCH_REMOVED",
  RANDOM_LINE_SEARCH = "RANDOM_LINE_SEARCH",
  VIRTUAL_LINE = "VIRTUAL_LINE",
  NO_LINE = "NO_LINE",
  MANUALLY_SELECTED_LINE_PRE = "MANUALLY_SELECTED_LINE_PRE",
  PRE_LINE_SEARCH_FIFO = "PRE_LINE_SEARCH_FIFO"
}

export enum LineStrategyEnum {
  MANUALLY_SELECTED_LINE = "Seleção",
  MANUALLY_TYPED_LINE = "Inserção Manual",
  MANUALLY_TYPED_LINE_AND_SEARCH_SOLD = "Inserção Manual e Busca linha vendida",
  MANUALLY_TYPED_LINE_AND_SEARCH_REMOVED = "Inserção Manual e Busca linha externa",
  RANDOM_LINE_SEARCH = "Linha Aleatória",
  VIRTUAL_LINE = "Linha Virtual",
  NO_LINE = "Sem Linha",
  MANUALLY_SELECTED_LINE_PRE = "Seleção linhas pré",
  PRE_LINE_SEARCH_FIFO = "Linha Pré FIFO"
}

export enum ServiceCategoryTypeEnum {
  MOBILE_LINE = "MOBILE_LINE",
  BROADBAND = "BROADBAND",
  TRACKER = "TRACKER",
  TAG = "TAG"
}
