interface IFormatDataProps {
  values: Array<unknown>;
  requiredFields: Array<string>;
  fieldKey: string;
  valueKey: string;
  includeAllValues?: boolean;
}

export const formatAccountFields = ({
  values,
  requiredFields,
  fieldKey,
  valueKey,
  includeAllValues = false
}: IFormatDataProps): unknown[] => {
  const fieldsFormatted = [];

  if (includeAllValues) {
    values.forEach(val =>
      fieldsFormatted.push({
        [fieldKey]: val[fieldKey],
        [valueKey]: val[valueKey] || ""
      })
    );
    requiredFields.forEach(field => {
      if (!fieldsFormatted.some(f => f[fieldKey] === field)) {
        fieldsFormatted.push({ [fieldKey]: field, [valueKey]: "" });
      }
    });
  } else {
    for (const field of requiredFields) {
      const hasCurrentField = values.find(val => val[fieldKey] === field);
      if (hasCurrentField) {
        fieldsFormatted.push({
          [fieldKey]: field,
          [valueKey]: hasCurrentField[valueKey]
        });
      } else {
        fieldsFormatted.push({ [fieldKey]: field, [valueKey]: "" });
      }
    }
  }

  return fieldsFormatted;
};
