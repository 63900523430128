import {
  FontIcon,
  ITooltipHostStyles,
  mergeStyles,
  TooltipHost
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

const calloutProps = { gapSpace: 0 };

const hostStyles: Partial<ITooltipHostStyles> = {
  root: {
    display: "flex",
    height: "100%",
    marginTop: "1px"
  }
};

const iconClass = mergeStyles({
  fontSize: 12
});

interface TooltipProps {
  description: string;
}

export const Tooltip = (props: TooltipProps) => {
  const { description } = props;
  const tooltipId = useId("tooltip");

  return (
    <TooltipHost
      content={description}
      calloutProps={calloutProps}
      id={tooltipId}
      styles={hostStyles}
    >
      <FontIcon
        aria-describedby={tooltipId}
        aria-label="Info"
        iconName="Info"
        className={iconClass}
      />
    </TooltipHost>
  );
};
