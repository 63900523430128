import { TermStepEnum } from "../enums/termStep.enum";
import { TermStepTranslatorEnum } from "../enums/termStepTranslator.enum";

interface IColoredLabel {
  backgroundColor: string;
  color: string;
  children: string;
  borderRadius?: string;
}

export const translateTermSteps = (step: string): IColoredLabel => {
  const object = {
    [TermStepEnum.DONE]: {
      children: TermStepTranslatorEnum.DONE,
      borderColor: "#01A020",
      color: "#01A020"
    },
    [TermStepEnum.OPTIONAL_SIGNATURES_COLLECTED]: {
      children: TermStepTranslatorEnum.OPTIONAL_SIGNATURES_COLLECTED,
      color: "#FFBF00",
      borderColor: "#FFBF00"
    },
    [TermStepEnum.REGISTER_CORRECTION]: {
      children: TermStepTranslatorEnum.REGISTER_CORRECTION,
      color: "#335393",
      borderColor: "#335393"
    },
    [TermStepEnum.CANCELED]: {
      children: TermStepTranslatorEnum.CANCELED,
      borderColor: "#ff0000",
      color: " 	#ff0000"
    },
    [TermStepEnum.REJECTED]: {
      children: TermStepTranslatorEnum.REJECTED,
      borderColor: "#F04A00",
      color: "#F04A00"
    },
    [TermStepEnum.TIMED_OUT]: {
      children: TermStepTranslatorEnum.TIMED_OUT,
      color: "#4D4D4D",
      borderColor: "#4D4D4D"
    },
    [TermStepEnum.MEMBER_SIGNED]: {
      children: TermStepTranslatorEnum.MEMBER_SIGNED,
      color: "#FFBF00",
      borderColor: "#FFBF00"
    }
  };
  return object[step];
};
