export enum SidebarMenuActionsTypes {
  OPEN_SIDEBAR_MENU = "menu/OPEN",
  CLOSE_SIDEBAR_MENU = "menu/CLOSE",
  EXPAND_SIDEBAR_MENU = "menu/EXPAND",
  RETRACT_SIDEBAR_MENU = "menu/RETRACT"
}

export interface SidebarMenuState {
  isMenuOpen: boolean;
  isMenuExpanded: boolean;
}

export interface OpenMenu {
  type: SidebarMenuActionsTypes.OPEN_SIDEBAR_MENU;
}

export interface CloseMenu {
  type: SidebarMenuActionsTypes.CLOSE_SIDEBAR_MENU;
}

export interface ExpandMenu {
  type: SidebarMenuActionsTypes.EXPAND_SIDEBAR_MENU;
}

export interface RetractMenu {
  type: SidebarMenuActionsTypes.RETRACT_SIDEBAR_MENU;
}

export type SidebarMenuReducerActions =
  | OpenMenu
  | CloseMenu
  | ExpandMenu
  | RetractMenu;
